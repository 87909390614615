import { createTheme } from './createTheme'

export default (assets) =>
  createTheme({
    name: 'MedPlus',
    assets,
    colors: {
      // brand
      primary: '#5baea8',
      secondary: '#7159a2'
    }
  })
