import { queryUsuariosControllerEu, mutationUsuariosControllerLogar } from 'api/saude'

export function getToken() {
  return window.localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_KEY)
}

export function setToken(token) {
  return window.localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_KEY, token)
}

export function removeToken() {
  return window.localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_KEY)
}

export function getExistPendingTerm() {
  return window.localStorage.getItem('existPendingTerm')
}

export function setExistPendingTerm(data) {
  return window.localStorage.setItem('existPendingTerm', data)
}

export function removeExistPendingTerm() {
  return window.localStorage.removeItem('existPendingTerm')
}

export function getRefreshToken() {
  return window.localStorage.getItem(process.env.REACT_APP_AUTH_REFRESH_TOKEN_KEY)
}

export function setRefreshToken(token) {
  return window.localStorage.setItem(process.env.REACT_APP_AUTH_REFRESH_TOKEN_KEY, token)
}

export function removeRefreshToken() {
  return window.localStorage.removeItem(process.env.REACT_APP_AUTH_REFRESH_TOKEN_KEY)
}

function authenticate(params = {}) {
  const handleLogin = mutationUsuariosControllerLogar()

  return handleLogin(params).then((data) => {
    setToken(data.accessToken)
    setRefreshToken(data.refreshTokenInfo.refreshToken)
    setExistPendingTerm(data.existPendingTerm)

    return data
  })
}

export function refreshToken() {
  const token = getRefreshToken()

  return authenticate({ refreshToken: token, grantType: 'refresh_token' })
}

export function bootstrap() {
  const token = getToken()

  if (!token) {
    return Promise.resolve(null)
  }

  return queryUsuariosControllerEu().catch(() => {
    return refreshToken()
      .then(() => {
        return queryUsuariosControllerEu()
          .then((data) => data)
          .catch(() => Promise.resolve(null))
      })
      .catch(() => Promise.resolve(null))
  })
}

export function login({ email, senha: password }) {
  return authenticate({ email, password, grantType: 'password' }).then(bootstrap)
}

export function logout() {
  removeToken()
  removeRefreshToken()
  removeExistPendingTerm()

  return Promise.resolve(null)
}
