export default {
  geral: {
    atencao: 'Atention!',
    app: 'MedPlus EN',
    planoSaude: 'Health insurance',
    prontuario: 'Medical record',
    paciente: 'Patient',
    pacientes: 'Patients',
    tipoAgendamento: 'Schedule type',
    selecioneUmConvenio: 'Select an agreement',
    selecioneUmPlanoDeSaude: 'Select a health plan',
    selecioneUmOperadoraDeSaude: 'Select a health care provider',
    planosEConvenios: 'Plans and agreements',
    novoOrcamento: 'New budget',
    procedimento: 'Procedure',
    medico: 'Doctor',
    novoAgendamento: 'New schedule',
    upAdministracao: 'UP / Administration',
    atendimento: 'Service',
    atendimento_plural: 'Attendances',
    dadosClinicos: 'Clinical data',
    selecionePaciente: 'Select a patient',
    medicos: 'Doctors',
    chamarWhats: 'Call by WhatsApp',
    enviarMensagemViaWhatsApp: 'Send message via WhatsApp',
    historicoNotificacoes: 'Notification history',
    localEnvio: 'Shipping location',
    meioComunicacaoUtilizado: 'Means of communication used',
    historicoNotificacoesSubTitulo: 'Detailed history with this client',
    contatoAtravesAgenda: 'Contact through the agenda',
    contatoAtravesCliente: 'Contact via customer registration',
    remetenteEnvio: 'Shipping sender',
    agendamento: 'Scheduling',
    dataHoraAgendamento: 'Scheduling date and time',
    semPaciente: 'Without patient',
    countPacientesAgendados: '{{count}} scheduled patients',
    adicionarPaciente: 'Add patient',
    adicionarMedico: 'Add doctor',
    usaCID10: 'Use CID10',
    usaCIAP2: 'Uses CIAP2',
    atendimentosEmAberto: 'Open calls',
    pacientesEmEspera: 'Waiting patients',
    novoAtendimento: 'New service',
    emDilatacao: 'In expansion',
    iniciarAtendimento: 'Start service',
    retomarAtendimento: 'Resume service',
    verExames: 'View exams',
    convenio: 'Health insurance',
    triagem: 'Screening',
    descartarAtendimento: 'Discard service',
    historicoDoPaciente: 'Patient history',
    comercial: 'Relationship',
    motivoComercial: 'Reason for the relationship',
    historicoDeAtendimento: 'Service history',
    laudo: 'Report',
    laudo_plural: 'Reports',
    gerarTiss: 'Generate TISS',
    operadoraPlanoDeSaude: 'Health Plan Operator',
    tabelaDeProcedimentos: 'Procedure table',
    tabelaDeProcedimento: 'Procedure table',
    grupoDeProcedimentos: 'Procedure group',
    grupoDeProcedimento: 'Procedure group',
    posologia: 'Dosage',
    medicamentoPosologia: 'Medicine / Dosage',
    pacientesEmAtendimento: 'Patients in care',
    finalizarAtendimento: 'End service',
    conduta: 'Conduct',
    agendarConsultaApartirDe: 'Schedule appointment from',
    cid: 'CID',
    motivoDesfecho: 'Reason for the outcome',
    queixaPrincipal: 'Main complaint',
    antecedentes: 'Background',
    tipoTempoPrevisto: 'Expected time type',
    atender: 'To meet',
    encaixar: 'To fit in',
    prescricoes: 'prescriptions',
    personalizarReceituario: 'Customize Recipe',
    mostrarNomeDoPaciente: 'Show patient name',
    mostrarNomeDoFabricante: 'Show manufacturer name',
    mostrarUnidades: 'Show units',
    protocoloDeExames: 'Exam protocol',
    tiss: 'TISS',
    guias: 'Guides',
    faturas: 'Invoices',
    tempoMedioAtendimento: 'Average service time',
    adicionarCid: 'Add CID',
    salaDeEspera: 'Waiting room',
    tempoDeAtendimento: 'Service time',
    totalDeAgendamentos: 'Total appointments',
    agendados: 'Scheduled',
    atendidos: 'Attended',
    encaixes: 'Fittings',
    nConsulta: '{{number}}th query',
    retornos: 'Returns',
    cidsRecorrentes: 'recurring CIDs',
    editarFormula: 'Edit formula',
    integracoes: 'Integrations',
    rqe: 'RQE',
    crm: 'CRM',
    convenioPlano: 'Agreement and plan',
    dilatacoes: 'Dilations',
    prescricaoOculos: 'Glasses prescription',
    verProntuario: 'See medical record',
    verAtendimentos: 'See calls',
    dataAtendimento: 'Date of service',
    comentario: 'Comment',
    novoLaudo: 'New report',
    nota: 'Note',
    copiarLink: 'Copy link',
    teleconsulta: 'Teleconsultation',
    teleconsulta_plural: 'Teleconsultations',
    copiarLinkTeleconsulta: 'Copy teleconsultation link',
    linkTeleconsultaEnvioManual:
      'The patient does not have an e-mail or telephone number registered to send the teleconsultation link. You will need to submit it manually.',
    consulta_presencial: 'Face-to-face consultation',
    consulta_presencial_plural: 'Face-to-face consultations',
    aceitoNoDia: 'Accepted on the day',
    termoAceiteTeleconsulta: 'Term of acceptance of teleconsultation',
    situacaoAgendamento: 'Scheduling Status',
    numeroCartaoSUS: 'SUS document',
    tiposAgendamento: 'Scheduling Types',
    templateComercial: 'Business template',
    tipoDePlano: 'Type of plan',
    convenioPadrao: 'Standard agreement',
    numeroDaCarteira: 'Wallet number',
    validadeDaCarteira: 'Portfolio validity',
    modelosProntuarioDisponiveis: 'Available medical record templates',
    modeloProntuarioAtual: 'Current medical record template',
    retornoApartirDe: 'Return from',
    modeloProntuario: 'Medical record template',
    opcoesMultiSelect: 'Multiselect options',
    grupoDeDocumento: 'Document group',
    numeroProntuario: 'Medical record number',
    exibirCarimbo: 'Display stamp',
    carimbo: 'Stamp',
    titulacao: 'Titration',
    receita: 'Revenue',
    receitaMemed: 'Recipe Memed',
    receitasMemed: 'Recipes Memed',
    preAtendimento: 'Pre-service',
    nomeMedico: 'doctor s name',
    horaInicial: 'Start time',
    horaFinal: 'End Time',
    perto: 'Close',
    longe: 'Away',
    tipoVisao: 'Type of view',
    salvarImprimir: 'Save and print',
    imprimirHistorico: 'Print history',
    naoPossuiHistoricoChat: 'No chat history',
    agendamentoMultiplo: 'Multiple scheduling',
    quantidadeAgendamentos: 'Number of appointments',
    ateDia: 'Until the day',
    agendamentoMultiploDataOcupada: 'This time is already occupied on the {{date}} date. do you wish to continue?',
    excluirAgendamentoMultiplo: 'Want to remove multiple schedule?',
    este: 'This one',
    repeticaoSemanal: 'Weekly repetition',
    calculando: 'Calculating',
    crmEspecialidade: 'CRM and Specialty',
    registroEspecialidade: 'Register or Specialty',
    novoRegistroEspecialidade: 'Add new register or specialty',
    novoCrmEspecialidade: 'Add CRM or Specialty',
    considerarDiasUteis: 'Consider business days when installments are due',
    dataExclusao: 'Exclusion date',
    movimentoExclusaoAgendamentos: 'Movement of exclusion of schedule',
    horarioEncerramentoMenorQueHorarioInicio: 'The end time cannot be less than the start time.',
    profissionalSaude: 'Health professional',
    dataPrimeiroVencimento: 'First due date',
    confirmaRemocaoSecaoAtendimento:
      'Attention! Data has been entered in this section that will be lost. Do you want to remove it? ',

    feedbacks: {
      aguardandoChegadaPacientes: 'Awaiting the arrival of patients',
      aguardandoEntradaNovosPacientes: 'Awaiting the entry of new patients',
      nenhumAtendimentoPausa: 'No patient with paused care',
      nenhumPacienteSalaEspera: 'No patients in the waiting room',
      nenhumAtendimentoRegistrado: 'No service registered',
      nenhumAtendimentoEmAberto: 'No open calls',
      cliqueEmIniciarAtendimento: 'Click start service',
      cliqueEmRetomarAtendimento: 'Click on resume service',
      tempoPrevistoRetornoSucesso: 'Estimated time for successful added return',
      nenhumaPrescricaoRegistrada: 'No prescription registered',
      nenhumaFormulaRegistrada: 'No formula registered',
      cidAdicionadoSucesso: 'CID successfully added!',
      cidRemovidoSucesso: 'CID removed successfully!',
      operacaoNaoPermitidaAtendimentos: 'Operation not allowed for appointments!',
      pacienteEmAtendimentoOutroMedico: 'The patient is being seen by another doctor!',
      teleconsultaNaoEncontrada: 'Teleconsultation not found!',
      pacienteSemConsultaAgendada: 'The patient has no scheduled teleconsultation',
      teleconsultaFinalizadaSucesso: 'Successfully completed teleconsultation',
      teleconsultaFinalizadaErro: 'Error when ending the teleconsultation',
      medicoOuClinicaSemHorarioDeAtendimentoNesseDia:
        'The clinic and/or doctor does not have any calendar available for this date.',
      nenhumaAssinaturaPendente: 'No pending subscription',
      obrigatorioParaAdicionarDesconto: 'Required to add discount',
      nenhumPlanoCadastradoNesseConvenio: 'No plan registered in this agreement',
      errorAbrirMemed: 'Error opening Memed',
      nenhumaOportunidadeEncontrada: 'No Opportunities found for this filter.',
      aoFinalizarTeleconsultaLinkSeraInativadoMedicoPaciente:
        'At the end of this call, the teleconsultation link will be inactivated for doctor and patient!',
      conexaoTeleconsultaAindaNaoFoiEstabelecidade: 'The teleconsultation connection has not yet been established!',
      historicoInexistente: 'There is still no history of this patient',
      imprimirHistorico: 'Print history',
      realizeUploadImagemPacienteOuNovoFiltro: 'Upload a patient image or a new filter!',
      linkTeleconsultaCopiadoComSucesso: 'Teleconsultation link copied to clipboard',
      linkTeleconsultaErroAoCopiar: 'Error copying teleconsultation link',
      tipoUsuarioTarifado: 'Billed user type',
      modeloDeImpressaoNaoSelecionado: 'You do not have any print templates selected or configured.',
      teleconsultaAberta: 'Close the open teleconsultation before starting another appointment.'
    },

    navegacao: {
      'locais-de-estoque': 'Stock Locations',
      'central-tiss': 'TISS Center',
      clinicas: 'Clinics',
      procedimentos: 'Procedures',
      posologias: 'Dosages',
      'dados-clinicos': 'Clinical data',
      'situacoes-agendamento': 'Scheduling Situations',
      'categorias-agendamento': 'Scheduling Types',
      'motivos-cancelamento-do-agendamento': 'Reasons for cancellation',
      'fila-de-espera': 'Queue',
      'sala-de-espera': 'Waiting room',
      clinico: 'Clinical',
      tiss: 'TISS',
      'preferencias-do-tiss': 'TISS Preferences',
      convenio: 'Health insurance',
      'modelos-de-prontuario': 'medical record templates',
      'modelos-de-impressos': 'Print Templates',
      'motivos-desfecho-atendimento': 'Ending reason',
      atendimentos: 'Attendances',
      'preferencias-do-comercial': 'Relationship Preferences',
      comercial: 'Relationship',
      'protocolos-e-exames': 'Protocols and exams',
      'tabelas-de-procedimentos': 'Procedure Tables',
      'grupos-de-procedimentos': 'Procedure groups',
      'preferencias-do-clinico': 'Clinician Preferences',
      'classificacoes-de-produtos': 'Product ratings',
      formulas: 'Recipe',
      'dashboard-do-clinico': 'Clinician Dashboard',
      'modelos-de-laudo': 'Report Templates',
      'versoes-do-tiss': 'TISS Versions',
      'dashboard-do-relacionamento': 'Relationship Dashboard',
      'fluxo-automacao-crm': 'Automation',
      'notificacoes-teleconsulta': 'Teleconsultation notifications',
      'templates-comerciais': 'Business templates',
      desfechos: 'Outcomes',
      'pre-atendimento': 'Pre-service and Returns',
      'servicos-contratacoes': 'Services and Contracts',
      'modelos-de-receituario': 'Recipe templates',
      'saidas-servicos-e-itens': 'Outputs - Services and Items',
      'documentos-fiscais': 'Fiscal documents'
    }
  },

  cadastro: {
    clientes: {
      numeroProntuario: 'Medical record number',
      financeiro: {
        atendimento: {
          novo: 'New service'
        }
      },

      dadosClinicos: {
        precaucoes: 'Precautions',
        alergiaMedicamentosa: 'Drug allergy',
        medicamentoUsoContinuo: 'Continuous use medications',
        principioAtivo: 'Active principle',
        informePrecaucao: 'Enter a precaution',
        informeMedicamento: 'Inform a drug',
        buscarPrincipioAtivo: 'Search for an active ingredient',
        medicamentoCadastradoSucesso: 'Drug successfully registered!',
        medicamentoRemovidoSucesso: 'Drug successfully removed!',
        alergiaMedicamentosaNaoEncontrada: 'Drug allergy not found'
      }
    },

    funcionarios: {
      especialidades: {
        titulo: 'Specialty Data',
        deletadoSucesso: 'Successfully removed specialty',
        criadoSucesso: 'Successfully added specialty',
        atualizadoSucesso: 'Successfully updated specialty',
        registro: 'Register number',
        novo: 'New specialty',
        adicionar: 'Add specialty',
        editar: 'Edit specialty',
        especialidadePadrao: 'Standard specialty',
        nenhumaEspecialidadeRegistrada: 'No specialty registered',
        especialidadeJaCadastrada: 'Specialty already registered for this employee',
        especialidadePadraoExistente:
          'The {{especialidadePadrao}} skill is already defaulted. Do you want to change the default for skill {{especialidadeAtual}}?',
        especialidadePadraoNecessaria: 'It is necessary to set another specialty as the default',

        subespecialidades: {
          titulo: 'Subspecialty',
          novo: 'New subspecialty',
          adicionar: 'Add subspecialty',
          adicionadaSucesso: 'Subspecialty successfully added',
          removidaSucesso: 'Subspecialty successfully removed'
        }
      },

      registro: {
        titulo: 'Registration data',
        deletadoSucesso: 'Registration successfully removed',
        criadoSucesso: 'Registration added successfully',
        atualizadoSucesso: 'Registration successfully updated',
        novo: 'New register',
        adicionar: 'Add register',
        editar: 'Update register',
        registroJaCadastrada: 'Record already registered for this employee',
        relacionado: 'Related register'
      },

      crm: {
        titulo: 'CRM data',
        deletadoSucesso: 'CRM successfully removed',
        criadoSucesso: 'CRM successfully added',
        registro: 'CRM number',
        novo: 'New CRM',
        adicionar: 'Add CRM',
        relacionado: 'Related CRM',
        crmPossuiVinculoComEspecialidade: 'This register is linked to one or more specialties of the employee'
      },

      integracoes: {
        integracoes: 'Integrations',
        detalhesDaIntegracao: 'Integration Details',
        sincronizadoSucesso: 'Synchronization performed successfully',
        status: 'Status',
        mensagens: 'Messages',
        nenhumaFalhaRegistrada: 'No failures recorded',
        salvaSucesso: 'Integration saved successfully',

        memed: {
          titulo: 'Memed Integration',
          novo: 'Add Memed Integration',
          naoPossuiCrmParaIntegracao: 'This employee does not have the CRM to use MEMED'
        },

        soluti: {
          titulo: 'Soluti Integration',
          novo: 'Add Soluti integration',
          integracaoAtiva: 'Active Integration'
        }
      },

      licencaAdicional: {
        adicionarLicenca: 'Add license',
        suasLicencasTipo: 'Your licenses of type',
        acabaramContratarMais: 'Are over, I would like to hire more?',
        adicioneLicencaPor: 'Add {{amount}} license for $t(general:currency) {{value, currency|BRL}}',
        observacaoAcesso:
          'OBS.: In order for the new user created to be able to use the calendar and medical records, use the Access tab of the registration to create the login and password.',
        confirmarSalvar: 'Confirm and save'
      }
    },

    preferencias: {
      documentos: {
        modelosDeImpressos: {
          titulo: 'Print Templates',
          novo: 'New print template',
          cadastradoSucesso: 'Successfully registered form template!',
          atualizadoSucesso: 'Print template successfully updated!',
          removidoSucesso: 'Print template successfully removed!',

          imagem: {
            cadastradoSucesso: 'Successfully registered image!',
            atualizadoSucesso: 'Image updated successfully!',
            removidoSucesso: 'Image removed successfully!'
          }
        }
      }
    }
  },

  catalogo: {
    produtos: {
      apresentacao: 'Presentation',
      formula: 'Formula',
      dosagem: 'Dosage',
      fracao: 'Fraction',
      registroAnvisa: 'ANVISA registration',
      codigoAbramge: 'ABRAMGE Code',
      codigoTiss: 'TISS code',
      tabelaTiss: 'TISS table',

      usosDeProdutos: {
        usosDeProduto: 'Product Usage',
        novo: 'New Product Use',
        cadastradoSucesso: 'Successfully using registered product!'
      },

      posologias: {
        titulo: 'Dosages',
        novo: 'New dosage',
        cadastradoSucesso: 'Posology linked successfully!',
        removidoSucesso: 'Posology successfully removed!'
      }
    },

    procedimentos: {
      tributacao: {
        novo: 'New taxation',
        cadastradoSucesso: 'Taxation successfully registered!',
        atualizadoSucesso: 'Taxation updated successfully!',
        removidoSucesso: 'Taxation successfully removed!'
      }
    },

    preferencias: {
      classificacoesDeProdutos: {
        titulo: 'Product ratings',
        novo: 'New classification',
        cadastradoSucesso: 'Classification registered successfully!',
        atualizadoSucesso: 'Rank updated successfully!',
        removidoSucesso: 'Classification successfully removed!'
      },

      gruposDeProcedimentos: {
        titulo: 'Procedure groups',
        novo: 'New Procedure Group',
        cadastradoSucesso: 'Procedures group successfully registered!',
        atualizadoSucesso: 'Procedure group successfully updated!',
        removidoSucesso: 'Procedure group successfully removed!'
      },

      posologias: {
        titulo: 'Dosages',
        novo: 'New dosage',
        cadastradoSucesso: 'Dosage successfully registered!',
        atualizadoSucesso: 'Successfully updated dosage!',
        removidoSucesso: 'Posology successfully removed!'
      },

      tabelasDeProcedimentos: {
        titulo: 'Procedure Tables',
        novo: 'New procedure table',
        cadastradoSucesso: 'Procedures table successfully registered!',
        atualizadoSucesso: 'Procedures table updated successfully!',
        removidoSucesso: 'Procedure table successfully removed!'
      }
    }
  },

  agenda: {
    agendamento: {
      titulo: 'Scheduling',
      novo: 'New schedule',
      cadastradoSucesso: 'Scheduling registered successfully!',
      atualizadoSucesso: 'Schedule updated successfully!',
      removidoSucesso: 'Schedule removed successfully!',

      pacientes: {
        titulo: 'Patients',
        novo: 'Add patient',
        cadastradoSucesso: 'Patient added successfully!',
        atualizadoSucesso: 'Patient updated successfully!',
        removidoSucesso: 'Patient successfully removed!'
      },

      medicos: {
        titulo: 'Doctor',
        novo: 'Add doctor',
        cadastradoSucesso: 'Physician added successfully!',
        atualizadoSucesso: 'Doctor updated successfully!',
        removidoSucesso: 'Physician successfully removed!'
      },

      equipamentos: {
        titulo: 'Equipments',
        novo: 'Add equipment',
        cadastradoSucesso: 'Equipment successfully added!',
        atualizadoSucesso: 'Equipment successfully updated!',
        removidoSucesso: 'Equipment successfully removed!'
      },

      dilatacao: {
        listaDilatacoes: 'List of Dilations',
        proxima: 'Next',
        ultima: 'Last',
        novaDilatacao: 'New Dilation',
        editarDilatacao: 'Edit Dilation',
        colirioDilatador: 'Eye drops used',
        executor: 'User',
        proximaDilatacao: 'Next Dilation',
        olhoEsquerdo: 'OE',
        ambos: 'Both',
        olhoDireito: 'OD',
        adulto: 'Adult',
        infantil: 'Child',
        cadastradaSucesso: 'Dilation successfully registered',
        atualizadaSucesso: 'Dilation successfully updated',
        deletadoSucesso: 'Dilation successfully removed',
        horarioProxima: 'Time / Next',
        colirioExecutor: 'Eye Drops / Executioner',
        olho: 'Eye',
        incluaDilatacao: 'Add new dilations through patient scheduling options'
      },

      financeiro: {
        novoOrcamentoVenda: 'New Sales Budget',
        novoPedidoVenda: 'New Sales Order',
        novoTitulo: 'New Title',
        vendas: {
          orcamentos: {
            novo: 'New budget',
            composicao: {
              cadastroErro: 'Condition Total Amount different from Budget Remaining Amount!',
              condicoes: {
                cadastradoSucesso: 'Condition registered successfully!',
                atualizadoSucesso: 'Condition updated successfully!',
                removidoSucesso: 'Condition removed successfully!'
              }
            }
          },
          contasReceber: {
            novo: 'New account receivable'
          }
        }
      },

      tiss: {
        guias: 'Guides',
        convenioTipoGuia: 'Agreement / Type of Guide',
        valorItens: 'Value / Items',
        gerarGuiasImpressao: 'Generate guides for printing',
        tipoGuia: 'Type of guide',
        cadastrarGuia: 'Register guide',
        necessarioEfetivarPedidoVenda:
          'For the items to be printed on the tab, it is necessary to place the sales order. Do you want to continue and enter manually?',
        pageSadt: {
          atualizadoSucesso: 'Guide updated successfully',
          cadastradoSucesso: 'Guide registered successfully',
          removidoSucesso: 'Guide removed successfully',
          guiaConsulta: 'Reference Guide',
          valorProcedimento: 'Procedure Value',
          dadosContratado: 'Contractor Data',
          dadosAtendimentoProcedimentoRealizado: 'Service Data / Procedures Performed',
          guiasImpressao: '{{number}} print tab(s)',
          nGuiaPrestador: 'Provider Guide No.',
          guiaServicoProfissionalSadt: 'Professional Service Guide / Diagnosis and Therapy Auxiliary Service - SP/SADT',
          registroANS: 'ANS registration',
          numeroGuiaPrincipal: 'Main tab number',
          dataAutorizacao: 'Authorization Date',
          dataValidadeSenha: 'Password Expiration Date',
          numeroGuiaAtribuidoOperadora: 'Guide Number Assigned by Operator',
          dadosBeneficiario: 'Beneficiary Data',
          numeroCarteira: 'Wallet number',
          validadeCarteira: 'Portfolio validity',
          carteiraNacionalSaude: 'National Health Card',
          atendimentoRN: 'RN service',
          dadosSolicitante: 'Applicant Data',
          codigoOperadora: 'Operator Code',
          nomeContratado: 'Name of Contractor',
          nomeProfissionalSolicitante: 'Name of the Requesting Professional',
          nomeProfissionalExecutante: 'Name of the performing professional',
          conselhoProfissional: 'Professional advice',
          numeroConselho: 'Board number',
          UF: 'State',
          codigoCBO: 'CBO code',
          assinaturaProfissionalExecutante:
            'Signature of the Executing ProfessionalAssinatura do Profissional Executante',
          assinaturaProfissionalSolicitante: 'Signature of the Requesting Professional',
          dadosSolicitacaoProcedimentosItensAssistenciaisSolicitados:
            'Request Data / Procedures or Assistance Items Requested',
          caraterAtendimento: 'Service Character',
          dataSolicitacao: 'Request date',
          indicacaoClinica: 'Clinical Indication',
          tabela: 'Table',
          codigoProcedimentoItemAssistencial: 'Procedure Code or Assistance Item',
          qtdeSolic: 'Quantity. Solic.',
          qtdeAut: 'Quantity. Aut.',
          dadosContratoExecutante: 'Performing Contractor Data',
          codigoNaOperadora: 'Operator Code',
          codigoCNES: 'CNES code',
          dadosAtendimento: 'Service Data',
          tipoAtendimento: 'Type of Service',
          indicacaoAcidente: 'Indication of Accident (accident or related illness)',
          tipoConsulta: 'Query Type',
          motivoEncerramentoAtendimento: 'Reason for Termination of Service',
          dadosExecucaoProcedimentoExamesRealizados: 'Execution Data / Procedure and Exams Performed',
          codigoProcedimento: 'Procedure Code',
          qtde: 'Quantity.',
          via: 'Via',
          tec: 'Tec.',
          fatorRedAcres: 'Factor Red/Acresc.',
          valorUnitario: 'Unitary value',
          valorTotal: 'Amount',
          identificacaoProfissionaisExecutantes: 'Identification of the performing professional(s)',
          seqRef: 'Seq.Ref',
          grauPart: 'Degree Part.',
          codigoOperadoraCPF: 'Code in the Operator/CPF',
          nomeProfissional: 'Professional Name',
          dataRealizacaoProcedimentosSerie: 'Serial Procedures Execution Date',
          quantidadeRealizacaoProcedimentosSerie: 'Number of Serial Procedures Performance',
          assinaturaBeneficiarioResponsavel: 'Signature of the Beneficiary or Responsible',
          observacaoJustificativa: 'Observation/Justification',
          totalProcedimentos: 'Total Procedures (R$)',
          totalTaxasAlugueis: 'Total Fees and Rents (R$)',
          totalMateriais: 'Total Materials (R$)',
          totalOPME: 'Total OPME (R$)',
          totalMedicamentos: 'Total Medicines (R$)',
          totalGasesMedicinais: 'Total Medical Gases (R$)',
          totalGeral: 'Grand total (R$)',
          assinaturaResponsavelAutorizacao: 'Signature of Authorization Officer',
          assinaturaContratado: 'Contractor s Signature'
        }
      }
    },

    preferencias: {
      calendarios: {
        exibirProntuarioNaAgenda: 'View medical record in calendar'
      },

      categoriasAgendamento: {
        titulo: 'Scheduling Types',
        novo: 'New Schedule Type',
        cadastradoSucesso: 'Schedule type successfully registered!',
        atualizadoSucesso: 'Schedule type updated successfully!',
        removidoSucesso: 'Schedule type successfully removed!'
      },

      motivosCancelamentoDoAgendamento: {
        titulo: 'Reason for cancellation',
        novo: 'New reason',
        cadastradoSucesso: 'Reason registered successfully!',
        atualizadoSucesso: 'Reason updated successfully!',
        removidoSucesso: 'Reason successfully removed!'
      },

      situacoesAgendamento: {
        titulo: 'Scheduling Status',
        novo: 'New situation',
        cadastradoSucesso: 'Successfully registered situation!',
        atualizadoSucesso: 'Status updated successfully!',
        removidoSucesso: 'Situation removed successfully!'
      },

      templatesComerciais: {
        titulo: 'Business templates',
        novo: 'New business template',
        cadastradoSucesso: 'Business template successfully registered!',
        atualizadoSucesso: 'Business template successfully updated!',
        removidoSucesso: 'Business template successfully removed!'
      }
    }
  },

  comercial: {
    dashboard: {
      portal: 'Patient portal'
    },

    oportunidades: {
      titulo: 'Opportunities',
      novo: 'New opportunity',
      cadastradoSucesso: 'Opportunity successfully registered!',
      atualizadoSucesso: 'Opportunity updated successfully!',
      removidoSucesso: 'Opportunity successfully removed!',
      financeiro: {
        orcamento: {
          novo: 'New budget'
        }
      }
    },

    questionarios: {
      titulo: 'Questionnaires',
      novo: 'New quiz',
      cadastradoSucesso: 'Successfully registered questionnaire!',
      atualizadoSucesso: 'Quiz updated successfully!',
      removidoSucesso: 'Quiz successfully removed!'
    }
  },

  preferencias: {
    motivosComerciais: {
      titulo: 'Commercial reasons',
      novo: 'New business motive',
      cadastradoSucesso: 'Business reason successfully registered!',
      atualizadoSucesso: 'Business motif successfully updated!',
      removidoSucesso: 'Business motif successfully removed!'
    }
  },

  clinico: {
    prontuarios: {
      titulo: 'Medical records',
      novo: 'New medical record',
      cadastradoSucesso: 'Successfully registered medical record!',
      atualizadoSucesso: 'Medical record successfully updated!',
      removidoSucesso: 'Medical record successfully removed!',

      atendimentos: {
        iniciadoSucesso: 'Service started successfully!',
        desejaFinalizar: 'Do you want to finish the service?',
        desejaDescartar: 'For what reason do you want to discard the service?',
        prescricoesExames: 'Prescriptions and exams',
        prescricaoDeOculos: 'Glasses prescription',
        prescricaoMemed: 'Memed prescription',
        prescricao: 'Prescription',
        dilatacoes: 'Dilations',
        adicionarSecao: 'Add section',
        atendimentoRegistradoEm: 'Service registered in:',
        atendimentoRealizadoEm: 'Service performed in:',
        notaCadastradaSucesso: 'Note successfully registered',
        teleconsulta: 'Teleconsultation',
        tempoPrevistoRetorno: 'Estimated time for return',
        calculoTempoAtendimento: 'Calculation of scheduling time in progress',

        layout: {
          atualizadoSucesso: 'Layout updated successfully!',
          removidoSucesso: 'Layout successfully removed!',
          nomePaciente: 'Patient name',
          itemModelo: 'Model item'
        },

        assinaturaDigital: {
          titulo: 'Digital signature',
          expirarTokenEm: 'Expire token on',
          tokenApp: 'Enter the token that appears in your Bird ID app',
          assinar: 'To sign',
          assinarDepois: 'Sign later',
          autenticadaSucesso: 'Token successfully validated!',
          requisicaoAssinatura: 'Subscription request completed successfully!',
          assinatura: 'Signature',
          assinaturas: 'Subscriptions',
          assinaturaPendente: 'Subscription in process',
          falhaAssinar: 'Failed to sign',
          assinaturasPendentes: 'Pending subscriptions',
          numeroProntuario: 'Medical record number',
          nenhumaAssinaturaPendente: 'No pending subscription',
          assinado: 'Signed'
        },

        impressao: {
          outrasInformacoes: 'Other information',
          camposDoAtendimento: 'Service Fields',
          avaliacao: 'Evaluation',
          conduta: 'Conduct',
          queixaPrincipal: 'Main Complaint',
          plano: 'Flat',
          cid: 'CID',
          receitas: 'Revenues',
          laudos: 'Reports',
          adendos: 'Addendums',
          anexos: 'Attachments',
          teleConsultas: 'Teleconsultations',
          nenhumaReceitaCadastrada: 'No recipe registered in this service!',
          nenhumaReceitaMemedRegistradaAtendimento: 'No memed recipe registered in this service!',
          receitasMemed: {
            identificacaoEmitente: 'Issuer Identification',
            cidadeEUf: 'City and State',
            receituarioEspecial: 'Special control prescription',
            primeiraViaFarmacia: '1st via pharmacy',
            segundaViaPaciente: '2nd copy patient',
            assinatura: 'Signature',
            identificacaoComprador: 'Buyer Identification',
            identificacaoFornecedor: 'Supplier Identification',
            assinaturaFarmaceutico: 'Pharmacist s Signature'
          }
        },

        laudos: {
          cadastradoSucesso: 'Report registered successfully',
          atualizadoSucesso: 'Report updated successfully!',
          removidoSucesso: 'Report successfully removed!'
        },

        documentos: {
          cadastradoSucesso: 'Documents registered successfully',
          atualizadoSucesso: 'Documents successfully updated!',
          removidoSucesso: 'Documents removed successfully!'
        }
      }
    },
    exames: {
      examesSolicitadosDia: 'Requested exams day',
      mesmaDataParaTodos: 'Use same date for all',
      uploadImagens: 'Upload images',
      resultados: 'Results',
      solicitarNovosExames: 'Request new exams',
      dataRealizacao: 'Date of realization',
      realizadoEm: 'Held in',
      exames: 'Exams',
      detalhesResultados: 'Details and results',
      adicionarExames: 'Add exams'
    },
    solicitacaoDeExames: {
      solicitacaoDeExames: 'Exam request',
      listagemDeExames: 'List of exams',
      novaSolicitacaoDeExames: 'New exam request ',
      convenio: 'Convênio',
      dataSolicitacao: 'Request date',
      selecioneExame: 'Select exam',
      procurePeloProcedimentoExame: 'Look for procedure/exam',
      exameInterno: 'Internal exam',
      listagemExames: 'Exam list',
      nomeExame: 'Exam name',
      motivoExame: 'Reason for exam',
      status: 'Status',
      olho: 'Eye',
      assinarDigitalmente: 'Digitally sign',
      gerarSadt: 'Generate sadt',
      qtde: 'Amount',
      imprimir: 'Print',
      cadastradoSucesso: 'Exam registered successfully!',
      atualizadoSucesso: 'Exam updated successfully!',
      removidoSucesso: 'Exam removed successfully!',
      cadastarProcedimentoIgual: 'The procedure/exam has already been selected.',
      validacoes: {
        qtdeMin: 'The amount must be greater than or equal to one'
      }
    },

    desfechos: {
      titulo: 'Pre-services and Returns',
      novo: 'New outcome',
      cadastradoSucesso: 'Successfully registered outcome!',
      atualizadoSucesso: 'Outcome updated successfully!',
      removidoSucesso: 'Outcome successfully removed!',
      preAtendimento: 'Pre-service',
      retornos: 'Returns',
      proximosAtendimentos: 'Next appointments',
      historicoDeAtendimentos: 'Service history',
      selecionePreAtendimento: 'Select pre-service template',
      novaSecao: 'New section',
      agendePreAtendimento: 'Schedule an appointment with the pre-service status',
      agendeRetornoReconsulta: 'Schedule a return/re-appointment',

      atendimentos: {
        titulo: 'Attendances',
        novo: 'New service',
        cadastradoSucesso: 'Service successfully registered!',
        atualizadoSucesso: 'Service updated successfully!',
        removidoSucesso: 'Service removed successfully!',

        documentos: {
          titulo: 'Documents',
          novo: 'New document',
          cadastradoSucesso: 'Document registered successfully!',
          atualizadoSucesso: 'Document updated successfully!',
          removidoSucesso: 'Document removed successfully!'
        },

        exames: {
          titulo: 'Exams',
          novo: 'New exam',
          cadastradoSucesso: 'Exam registered successfully!',
          atualizadoSucesso: 'Exam updated successfully!',
          removidoSucesso: 'Exam removed successfully!'
        }
      }
    },

    preferencias: {
      formulas: {
        titulo: 'Formulas',
        novo: 'New formula',
        cadastradoSucesso: 'Formula registered successfully!',
        atualizadoSucesso: 'Formula updated successfully!',
        removidoSucesso: 'Formula removed successfully!'
      },

      modelosDeLaudo: {
        titulo: 'Report Templates',
        novo: 'New report template',
        cadastradoSucesso: 'Report template successfully registered!',
        atualizadoSucesso: 'Report template successfully updated!',
        removidoSucesso: 'Report template successfully removed!',
        laudo: { novo: 'New report template', nenhumCadastro: 'No report model registered' },
        conclusao: { novo: 'New completion template', nenhumCadastro: 'No completion model registered' }
      },

      modelosDeProntuario: {
        titulo: 'Medical record templates',
        novo: 'New medical record template',

        digiteTitulo: 'Enter the title of the record here',
        digiteDescricao: 'Enter the description of the medical record here',
        default: 'Untitled medical record',

        cadastradoSucesso: 'Successfully registered medical record template!',
        atualizadoSucesso: 'Successfully updated medical record template!',
        removidoSucesso: 'Medical record template successfully removed!',

        medicos: {
          titulo: 'Doctors',
          novo: 'Add doctor',
          cadastradoSucesso: 'Physician added successfully!',
          atualizadoSucesso: 'Doctor updated successfully!',
          removidoSucesso: 'Physician successfully removed!'
        },

        especialidades: {
          titulo: 'Specialties',
          novo: 'Add specialty',
          cadastradoSucesso: 'Successfully added specialty!',
          atualizadoSucesso: 'Successfully updated specialty!',
          removidoSucesso: 'Successfully removed specialty!'
        },

        secoes: {
          novo: 'New section',
          cadastradoSucesso: 'Section successfully registered!',
          atualizadoSucesso: 'Section successfully updated!',
          removidoSucesso: 'Section removed successfully!',

          digiteTitulo: 'Enter the section title here',

          campos: {
            novo: 'New field',
            cadastradoSucesso: 'Field successfully registered!',
            atualizadoSucesso: 'Field successfully updated!',
            removidoSucesso: 'Field successfully removed!',
            camposDisponiveis: 'Fields available for composition',
            operacoes: 'Operations',

            termos: {
              titulo: 'Key words',
              novo: 'New term',
              cadastradoSucesso: 'Term registered successfully!',
              removidoSucesso: 'Term removed successfully!'
            },

            opcoesMultiSelect: {
              titulo: 'Multiselect options',
              novo: 'New option',
              cadastradoSucesso: 'Multiselect option successfully registered!',
              removidoSucesso: 'Multiselect option successfully removed!',
              nenhumaOpcaoCadastrado: 'No option registered'
            }
          }
        }
      },

      protocolosExames: {
        titulo: 'Protocols and exams',
        novo: 'New protocol/exam',
        cadastradoSucesso: 'Successfully registered protocol/exam!',
        atualizadoSucesso: 'Protocol/exam updated successfully!',
        removidoSucesso: 'Protocol/exam successfully removed!',

        especialidades: {
          titulo: 'Specialties',
          novo: 'New specialty',
          cadastradoSucesso: 'Successfully registered specialty!',
          removidoSucesso: 'Successfully removed specialty!'
        },

        exames: {
          titulo: 'Exams',
          novo: 'New exam',
          editar: 'Edit exam',
          cadastradoSucesso: 'Successfully registered exam!',
          atualizadoSucesso: 'Exam updated successfully!',
          removidoSucesso: 'Exam successfully removed!'
        },

        medicamentos: {
          titulo: 'Medicines',
          novo: 'New drug',
          editar: 'Edit medicine',
          cadastradoSucesso: 'Drug successfully registered!',
          atualizadoSucesso: 'Drug updated successfully!',
          removidoSucesso: 'Drug successfully removed!'
        },

        profissionais: {
          titulo: 'Professionals',
          novo: 'New professional',
          selecione: 'Select a professional...',
          cadastradoSucesso: 'Successfully registered professional!',
          removidoSucesso: 'Professional successfully removed!'
        }
      },

      motivosDesfechoAtendimento: {
        titulo: 'Resolution reasons',
        novo: 'New reason for outcome',
        cadastradoSucesso: 'Reason for successful registered outcome!',
        atualizadoSucesso: 'Reason for outcome updated successfully!',
        removidoSucesso: 'Ending reason successfully removed!'
      },

      tiposFuncionarios: {
        novo: 'new type',
        cadastradoSucesso: 'Employee type successfully registered!'
      },

      tiposDeSala: {
        titulo: 'Types of room / environment',
        novo: 'New room type',
        cadastradoSucesso: 'Type of room successfully registered!',
        atualizadoSucesso: 'Room type successfully updated!',
        removidoSucesso: 'Room type successfully removed!'
      },

      salas: {
        titulo: 'Rooms / environments',
        novo: 'New room / environment',
        cadastradoSucesso: 'Room / environment successfully registered!',
        atualizadoSucesso: 'Room / environment successfully updated!',
        removidoSucesso: 'Room / environment successfully removed!',
        equipamentos: {
          titulo: 'Equipments',
          selecionar: 'Select Equipment',
          novo: 'New Equipment',
          editar: 'Edit Equipment',
          cadastradoSucesso: 'Equipment successfully registered!',
          atualizadoSucesso: 'Equipment successfully updated!',
          removidoSucesso: 'Equipment successfully removed!'
        }
      }
    }
  },

  tiss: {
    versaoDoTISS: 'TISS version',

    centralTiss: {
      titulo: 'TISS Center',
      faturas: { novo: 'New invoice' },
      glosas: 'Glosses',
      lote: 'Batch',
      qtde: 'Quantity',
      nGuia: 'Guide No.',
      faturado: 'Billed',
      glosa: 'Gloss',
      valorGlosa: 'Gloss value',
      cr: 'CR',
      repasse: 'Transfer',
      pesquisar: 'Search for patients, doctors, guides...',
      pesquisarGuias: 'Search guides',
      buscar: 'Search for patients, doctors, guides...',
      tipoGuia: 'Tab type',
      sequenciaDeTransacao: 'Transaction sequence: - xml batch',
      numeroControleXml: 'XML control number',
      versaoDoArquivoXml: 'xml file version',
      guias: {
        totalFatura: 'Total invoice',
        totalGlosado: 'Total gloss',
        totalRecebido: 'Total received',
        totalTiss: 'Total TISS',
        options: {
          editarGuia: 'Edit tab',
          gerarXml: 'Generate XML',
          transferir: 'Transfer',
          contaReceber: 'Account receivable',
          contaPagar: 'Bill to pay',
          previsaoDeRepasses: 'Provision of transfers'
        }
      }
    },
    preferencias: {
      convenios: {
        convenios: 'Covenants',
        novo: 'New Agreement',
        editar: 'Edit agreement',
        cadastradoSucesso: 'Agreement registered successfully!',
        atualizadoSucesso: 'Agreement updated successfully!',
        removidoSucesso: 'Covenant successfully removed!',
        principaisDados: 'Main data of the agreement',
        nomeCnpj: 'Name / CNPJ',
        registroAns: 'Registration with ANS',
        maximoRegistroAns: 'Maximum 6 characters',
        telefoneEmail: 'Phone / Email',
        tags: 'Tags',
        codigoOperadora: 'Operator code',
        maximoCodigoOperadora: 'Maximum 20 characters',
        inscricaoEstadual: 'State registration',
        dataCredenciamento: 'Accreditation date',
        dataDescredenciamento: 'De-accreditation date',
        retornoConsultaDias: 'Return query',
        diasRecebimento: 'Return query',
        website: 'Web site',
        observacoes: 'Comments',
        tabs: {
          dadosBasicos: 'Basic data',
          trocaDeInformacoes: 'Information exchange',
          regras: 'Rules',
          webservicesTiss: 'TISS Webservices',
          excecoes: 'Exceptions',
          financeiro: 'Financial',
          tributacao: 'Taxation',
          anexos: 'Attachments',
          situacao: 'Situation'
        },
        planos: {
          titulo: 'Plans',
          subtitulo: 'Register the plans of the agreement',
          novo: 'New plan',
          cadastradoSucesso: 'Successfully registered plan!',
          atualizadoSucesso: 'Successfully updated plan!',
          removidoSucesso: 'Plan successfully removed!'
        }
      },

      versoesDoTiss: {
        titulo: 'TISS Versions',
        novo: 'New version of TISS',
        cadastradoSucesso: 'TISS version successfully registered!',
        atualizadoSucesso: 'TISS version successfully updated!',
        removidoSucesso: 'TISS version successfully removed!'
      }
    }
  },

  servicosContratacoes: {
    faturas: {
      historicoContratacoes: {
        titulo: 'Hiring History',
        mesAtual: 'Current month',
        resumo: 'Summary',
        movimentoUsuarios: 'User movement',
        quantidadeUsuarios: 'Number of users',
        valorMensalidade: 'Monthly fee',
        dataProfissionalSaude: 'Date / Health professional',
        emailUsuario: 'Email / User',
        nenhumaMovimentacaoEncontrada: 'No drives found'
      }
    }
  },

  configuracoes: {
    notificacoesTeleconsulta: {
      titulo: 'Teleconsultation notifications',
      notificarPorEmail: 'Email notification',
      notificarPorSMS: 'SMS notification',
      templateSMSAgendado: 'Scheduled SMS Template',
      templateSMSRemarcado: 'Rescheduled SMS template',
      templateSMSCancelado: 'Canceled SMS template',
      templateMailAgendado: 'Scheduled Email Template',
      templateMailRemarcado: 'Rescheduled Email Template',
      templateMailCancelado: 'Canceled Email Template',
      atualizadoSucesso: 'Successfully updated teleconsultation notifications!'
    },
    empresas: {
      impressos: {
        emitenteRecibos: 'Issuer on receipts'
      }
    },
    templates: {
      whatsApp: {
        titulo: 'Templates',
        novo: 'New Model',
        cadastradoSucesso: 'WhatsApp template registered successfully!',
        atualizadoSucesso: 'WhatsApp template successfully updated!',
        removidoSucesso: 'WhatsApp template successfully removed!',
        escrevaMensagem: 'Write the message',
        adicionarTags: 'Add Tags:',
        mensagemDeTexto: 'Text message',
        caracteresRestantes: '{{value}} characters remaining',
        funcionalidade: 'Functionality'
      }
    }
  },

  dashboard: {
    categorias: {
      ultimos12Meses: 'Last 12 months',
      ultimoMes: 'Last month',
      ultimaSemana: 'Last week',
      ultimoDia: 'Last day',
      semAgendamentosPorStatus: 'No appointments in the last 12 months',
      agendamentosPorStatus: 'Schedules by status (last 12 months)'
    },
    resumoFinanceiro: {
      hoje: 'Financial summary (today)'
    }
  },
  financeiro: {
    preferenciasEstoque: {
      localDeEstoque: {
        titulo: 'Stock location',
        novo: 'New stock location',
        cadastradoSucesso: 'Stock location registered successfully!',
        atualizadoSucesso: 'Stock location updated successfully!',
        removidoSucesso: 'Stock location successfully removed!'
      }
    },
    estoque: {
      titulo: 'Stock movement',
      localEstoque: 'Stock location',
      nenhumCadastro: 'No stock registered',
      cadastradoSucesso: 'Stock successfully registered!',
      atualizadoSucesso: 'Stock successfully updated!',
      removidoSucesso: 'Stock removed successfully!',
      totalItens: 'Total items',
      maiorSaidaItem: 'Largest item output',
      semSaidaItem: 'No recent output',
      itemsVencidos: 'Items expired',
      nomeSituacao: 'Name / Stock situation',
      valorCompraVenda: 'Purchase / Sale value',
      localEstoqueFinalidade: 'Stock location / Purpose',
      embCompraVenda: 'Purchase / Sell packaging',
      estoqueMinimo: 'Minimum stock',
      estoqueMaximo: 'Maximum stock',
      tipoProduto: 'Product type',
      buscarPorProduto: 'Search by product',
      finalidade: 'Purpose',
      vencimentoEntreDatas: 'Expiration between data',
      vencimentoInicial: 'Initial expiration',
      vencimentoFinal: 'Final expiration',
      validadeEstoque: 'Validity and stock',
      validade: 'Validity',
      situacaoEstoque: 'In stock situation',
      movimentarLocalEstoque: 'Move stock location',
      quantidadeMovimentar: 'Quantity to move',
      selecionarLocal: 'Select location',
      valorVenda: 'Sale Amount',
      embalagemCompra: 'Packaging Purchase',
      embalagemVenda: 'Packaging Sale',
      removerUnidade: 'Remove units',
      voceEstaRemovendoUnidades: 'You are removing {{unit}} units',
      transferirUnidades: 'Transfer Units',
      voceEstaTransferindoUnidadesDesseLocal: 'You are transferring {{unit}} units from this location',
      burcarPorProduto: 'Search by product',
      abaixoEstoque: 'Below minimum stock',
      acimaEstoque: 'Above Max Stock',
      produtoVencido: 'Expired product!',
      vencido: 'Overdue',
      unidadesTransferidas: 'Units to be transferred',
      unidadesRestantes: 'Units remaining at current location'
    }
  },
  catalago: {
    estoque: {
      titulo: 'Stock',
      localEstoque: 'Location Stock',
      estoqueMinimo: 'Minimum stock',
      estoqueMaximo: 'Maximum stock'
    }
  }
}
