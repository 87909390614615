export default {
  geral: {
    app: 'Sponte',
    currency: 'R$',
    percent: '%',
    currency_code: 'ESP',
    date_mask: 'DD/MM/YYYY',
    date_format_describe: 'dd/mm/aaaa',
    time_mask: 'HH:mm:ss',
    time_format_describe: '0:00h',
    datetime_mask: '$t(geral:date_mask) $t(geral:time_mask)',
    currency_formatter: '$t(geral:currency) {{value, currency|BRL}}',
    percent_formatter: '{{value, percent|BRL}}',
    date_formatter: '{{date, date|DD/MM/YYYY}}',
    time_formatter: '{{date, date|HH:mm}}',
    time_with_seconds_formatter: '{{date, date|HH:mm:ss}}',
    datetime_formatter: '{{date, date|DD/MM/YYYY HH:mm:ss}}',
    numberRange_formatter: '{{start}} de {{end}}',
    atuaisNovas: 'Actual y nuevo',
    apenasNovas: 'Solo nuevo',
    numeroRecibo: 'Número de recibo',
    reabrir: 'Reabrir',
    Administradores: 'Administradores',
    FinanceiroMarketplace: 'Financiero / Marketplace',
    contaBancariaOperacaoRealizada: 'Cuenta bancaria de la transacción realizada',
    nenhumValorInformado: 'Sin valor informado',
    pagamentoCondicaoAlterada: 'Pago con condición modificada',
    salvar: 'Ahorrar',
    salvo: 'Guardado',
    dataRepasse: 'Fecha de transferencia',
    sacado: 'Retirado',
    valorCreditado: 'Monto acreditado',
    valorACreditar: 'Monto a acreditar',
    valorTotalTransacao: 'Importe total de la transacción',
    conciliarManualmente: 'Conciliar manualmente',
    creditarManualmente: 'Acreditar manualmente',
    visualizarDetalhes: 'Ver detalles',
    salvando: 'Guardando',
    salvarIncluir: 'Guardar e incluir',
    salvarLocalEstoquePadrao: 'Realmente desea cambiar la ubicación de stock predeterminada?',
    continuar: 'Continuar',
    cancelar: 'Cancelar',
    cancelarTituloBoleto: 'El título no se puede cancelar. Forma de Pago Boleto',
    cancelarTituloSponte: 'El título no se puede cancelar. Forma de Pago Pago Espontáneo',
    cancelarTituloSemMotivoData: 'El motivo y la fecha son obligatorios',
    cancelamento: 'Cancelación',
    pesquisa: 'Búsqueda',
    situacaoTituloAlterada: 'Estado del título cambiado',
    clienteNumeroContrato: 'Número de cliente o contrato',
    situacaoContratoAlterada: '¡El estado del contrato cambió con éxito!',
    deletar: 'Borrar',
    situacaoContrato: 'Estado del contrato',
    situacaoAlteradaSucesso: '¡El estado cambió con éxito!',
    visualizarMovimentacao: 'Ver movimiento',
    situacaoData: 'Situación / Fecha',
    condicaoPagamento: 'Condicíon de pago',
    filtrarEmissaoRecibos: 'Filtrar emisión de recibos',
    gerarRecibo: 'Generar recibo',
    apenasParcelasQuitadas: 'Seleccionar solo cuotas pagadas',
    apenasParcelasPendentes: 'Seleccionar solo cuotas pendientes',
    configuracaoFiltragemRecibo: 'Configuración de filtrado de recibos',
    emitente: 'Emisor',
    tipoEmitente: 'Tipo de emisor',
    formaPagamento: 'Forma de pago',
    responsavelCondicao: 'Responsable de la condición',
    verMovimentacao: 'Ver movimiento',
    movimentacao: 'Movimiento',
    cancelarParcelas: 'cancelar cuotas',
    cancelarTituloSpontePay: 'El título no se puede cancelar. Forma de Pago Pago Espontáneo',
    historicoAlteradoComSucesso: '¡La historia cambió con éxito!',
    reativarPaciente: '¿Quieres reactivar al paciente?',
    reativarFuncionario: '¿Quieres reactivar al profesional sanitario?',
    remover: 'Para eliminar',
    removerCadastro: 'Eliminar registro',
    desejaRemover: '¿Realmente quieres eliminar?',
    desejaRemoverComAulasRelacionadas:
      'Si hay clases en el diario relacionadas con esta configuración, se eliminarán. ¿Realmente quieres eliminar?',
    desejaEstornar: '¿De verdad quieres dar marcha atrás?',
    estornar: 'Marcha atrás',
    desejaGerarTitulos: '¿De verdad quieres generar títulos??',
    naoReceberSms: 'No quiero recibir SMS',
    naoReceberEmail: 'No quiero recibir correo electrónico',
    tentarNovamente: 'Intentar nuevamente',
    novo: 'Nuevo',
    nova: 'Nuevo',
    cpf: 'CPF',
    cnpj: 'CNPJ',
    rg: 'RG',
    registro: 'Registro',
    registroGeral: 'Registro General',
    certidaoDeNascimento: 'Certificado de nacimiento',
    numeroRegistroCertidaoNascimento: 'Número de registro',
    cartorioRegistroCertidaoNascimento: 'Oficina de Registro',
    livroRegistroCertidaoNascimento: 'Libro de registro',
    folhaRegistroCertidaoNascimento: 'Hoja de registro',
    termoRegistroCertidaoNascimento: 'Plazo de registro',
    dataRegistroCertidaoNascimento: 'Fecha de Registro',
    carteiraTrabalho: 'Cartera de Trabajo',
    nome: 'Nombre',
    nomeAlunoResponsavel: 'Nombre del estudiante o tutor',
    tipo: 'Escribe',
    nomeTipo: '$t(geral:nome) / $t(geral:tipo)',
    nomeCodigo: '$t(geral:nome) / $t(geral:codigo)',
    pessoa: 'Gente',
    sim: 'sí',
    nao: 'No',
    fisica: 'Física',
    juridica: 'Legal',
    pessoaFisica: 'Persona física',
    pessoaJuridica: 'Persona jurídica',
    nomeCompleto: 'Nombre completo',
    razaoSocial: 'Razón social',
    nomeFantasia: 'Nombre de fantasía',
    nomeDependente: 'Nombre del dependiente',
    aposAula: 'Después de clases',
    nomeAtividade: 'Nombre de la actividad',
    apelido: 'Apellido',
    baseNacionalComum: 'Base nacional común',
    baseDiversificada: 'Base diversificada',
    itinerariosFormativos: 'Itinerarios para la formación',
    dataBase: 'Base de datos',
    dataFim: 'Fecha final',
    dataAbertura: 'Fecha de apertura',
    dataNascimento: 'Fecha de nacimiento',
    dataLancamento: 'Fecha de lanzamiento',
    nomeCnpjCpf: '$t(geral:nome) / $t(geral:cnpj) ou $t(geral:cpf)',
    cpfCnpj: '$t(geral:cpf) / $(geral:cnpj)',
    nomeMae: 'Nombre de la madre',
    nomePai: 'Nombre del Padre',
    inscricaoMunicipal: 'Registro Municipal',
    inscricaoEstadual: 'Inscripción Estadual',
    regimeTributario: 'Régimen fiscal',
    emissor: 'Editor',
    orgaoEmissor: 'Órgano emisor',
    dataEmissao: 'Fecha de emisión',
    dataEmissaoInicial: 'Fecha de emisión inicial',
    dataEmissaoFinal: 'Fecha de emisión final',
    dataSaidaInicial: 'Fecha de salida inicial',
    dataSaidaFinal: 'Fecha de salida final',
    dataCompra: 'Fecha de compra',
    dataOrcamento: 'Fecha de presupuesto',
    dataSaida: 'Fecha de salida',
    cidadeNascimento: 'Ciudad de nacimiento',
    nacionalidade: 'Nacionalidad',
    tituloEleitor: 'Título de elector',
    zonaEleitoral: 'Zona electoral',
    secao: 'Sección',
    passaporte: 'Pasaporte',
    dataEntrada: 'Fecha de entrada',
    pisPasep: 'PIS / PASEP',
    ctps: 'CTPS',
    uf: 'Estado',
    sexo: 'Sexo',
    estadoCivil: 'Estado Civil',
    profissao: 'Profesión',
    renda: 'Ingreso',
    etnia: 'Etnia',
    turma: 'Clase',
    turmas: 'Clases',
    escolaridade: 'Educación',
    frequentaEscola: 'ir al colegio?',
    nomeResponsavel: 'Nombre del responsable',
    tipoResponsavel: 'Tipo de responsable',
    tipoResponsavelServico: 'Tipo de servicio responsable',
    tipoResponsavelContaBancaria: 'Tipo de titular de la cuenta bancaria',
    novoResponsavel: 'Nuevo responsable',
    editarResponsavel: 'Editar responsable',
    primeiroAcesso: 'Primer acceso',
    novoDependente: 'Nuevo dependiente',
    editarDependente: 'Editar dependiente',
    responsavel: 'Responsable',
    responsaveis: 'Responsables',
    responsaveisPeloProcedimento: 'Responsable del trámite',
    parentesco: 'Parentesco',
    dependente: 'Dependiente',
    dependentes: 'Dependientes',
    tipoDependente: 'Tipo dependiente',
    telefone: 'Teléfono',
    email: 'E-mail',
    nomeEmail: '$t(geral:nome) / $t(geral:email)',
    emailAdicional: 'E-mail adicional',
    telefoneFixo: 'Teléfono fijo',
    telefoneCelular: 'Teléfono celular',
    telefoneAdicional: 'Teléfono adicional',
    operadora: 'Operador',
    telefoneEmail: '$t(geral:telefone) / $t(geral:email)',
    endereco: 'Dirección',
    celular: 'Teléfono móvil',
    redeSocial: 'Red social',
    linkPerfil: 'Enlace de perfil',
    meuPerfil: 'Mi perfil',
    link: 'Enlace',
    social: 'Social',
    perfil: 'Perfil',
    lead: 'Lead',
    cliente: 'Cliente',
    suspect: 'Suspect',
    situacaoTitulo: 'Situación del título',
    prospect: 'Prospect',
    situacao: 'Situación',
    ativo: 'Activo',
    ativos: 'Activo',
    inativo: 'Inactivo',
    inativos: 'Inactivo',
    bloqueado: 'Obstruido',
    incompleto: 'Incompleto',
    outros: 'Otros',
    necessidadesEspeciais: 'Necesidades especiales',
    necessidade: 'Necesidad',
    autismo: 'Autismo',
    gestacao: 'Gestación',
    obesidade: 'Obesidad',
    idosidade: 'La edad',
    deficienciaVisual: 'Discapacidad visual',
    deficienciaFisica: 'Deficiencia física',
    porteCriancaPequena: 'Pequeña maternidad',
    item: 'Artículo',
    quantidade: 'La cantidad',
    qtdeCartao: 'Cant. tarjeta',
    qtdeBoleto: 'Cant. billete',
    qntd: 'Cant.',
    subTotalItem: 'Subtotal del artículo',
    selecioneTipoCfop: 'Seleccione un tipo de CFOP',
    itens: 'Elementos',
    totalItens: 'Articulos totales',
    detalhes: 'Detalles',
    notasParciais: 'Notas parciales',
    recuperacao: 'Recuperación',
    exameFinal: 'Examen final',
    modelosAvaliacao: 'Modelos de evaluación',
    nomeItem: 'Nombre del árticulo',
    itemQuantidade: 'Cantidad de objetos',
    valorCondicao: 'Valor / Condición',
    valorAcrescimo: 'Valor de Adición',
    valorMultaReal: 'R$ Sanción',
    valorMulta: '% Sanción',
    jurosMulta: 'Interés e Sanción',
    juros: 'Interés',
    jurosDiarios: 'Intereses diarios',
    multaPorAtraso: 'Cargo por demora',
    multa: 'Sanción',
    custos: 'Costos',
    siglaQuantidade: 'Cant.',
    valorUnitario: 'Valor unitario',
    subtotal: 'Total parcial',
    venda: 'Venta',
    custo: 'Costo',
    custoLabel: 'Tipo de costo',
    custoDigitado: 'Costo ingresado',
    custoUltimaCompra: 'Costo de la última compra',
    custoMedio: 'Costo promedio',
    unidadeDeMedida: 'Unidad de medida',
    unidadeDeMedidaPadrao: 'Unidad de medida estándar',
    unidadeTipo: 'Tipo de unidad',
    finalidade: 'Objetivo',
    ncm: 'NCM',
    precificacao: 'Precios',
    localRealizacao: 'Lugar de realización',
    cbo: 'CBO',
    cidade: 'Ciudad',
    estado: 'Estado',
    pais: 'Padres',
    opEntrada: 'Operación de entrada',
    opSaida: 'Operación de salida',
    opEntradaSaida: 'Entrada y salida',
    descricao: 'Descripción',
    nomeDescricao: '$t(geral:nome) / $t(geral:descricao)',
    contaPrincipal: 'Cuenta principal',
    codigo: 'Código',
    empresa: 'Empresa',
    numeroAbreviado: 'N°',
    numeroDeFiliais: '$t(geral:numeroAbreviado) de ramas',
    siteEnderecoDigital: 'Sitio web / Dirección digital',
    complemento: 'Complemento',
    ruaAvenida: 'Avenida de la calle',
    cep: 'CEP',
    optanteSimplesNacional: 'Optando por el Simple Nacional',
    naturalidade: 'Naturalidad',
    preco: 'Precio',
    precoPadrao: 'Precio estándar',
    precoSimplificado: 'Precio simplificado',
    novoPreco: 'Nuevo precio',
    semConsumo: 'Sin consumo',
    categoria: 'Categoría',
    subcategorias: 'Subcategorías',
    precoCategoria: '$t(geral:preco) / $t(geral:categoria)',
    precoTabela: '$t(geral:preco) / $t(geral:tabela)',
    tags: 'Etiquetas',
    vagas: 'Vacantes',
    vaga: 'Vacante',
    bancoTipo: 'Tipo de cuenta / banco',
    bancoConta: 'Cuenta bancaria',
    conta: 'Cuenta',
    agencia: 'Agencia',
    contaAgencia: '$t(geral:conta) / $t(geral:agencia)',
    forma: 'Molde',
    operacao: 'Operación',
    operacao_plural: 'Operaciones',
    tipoOperacao: 'Tipo de operación',
    configuracoesSpontePay: 'Configuración de Sponte Pay',
    configuracoesMedPay: 'Configuración de MedPay',
    opcoesPagamento: 'Opciones de pago',
    canaisEnvioLink: 'Canales de envío de enlaces',
    numMaximoParcelas: 'No. Cuotas máximas',
    acrescimoParcelamento: 'Adición en cuotas',
    percentualAcrescimo: 'Porcentaje de adición',
    confirmarDadosClientePagamento: 'Confirmar los datos del cliente en el pago',
    tempoExpiracaoLinkAposVencimento: 'Tiempo de caducidad del enlace después de la caducidad',
    diasEnvioAntesVencimento: 'Días de envío antes del vencimiento',
    medpay: 'Medpay',
    spontepay: 'Sponte pay',
    gerarLinkPagamento: 'Generar enlace de pago',
    linkPagamentoGerado: 'Enlace de pago generado',
    enviarLinkSms: 'Enviar enlace por SMS',
    enviarLinkEmail: 'Enviar enlace por correo electrónico',
    cancelarLink: 'Cancelar enlace',
    linkCopiadoAreaTransferencia: 'Link copiado al portapapeles',
    textoCopiadoAreaTransferencia: 'Texto copiado al portapapeles',
    desejaCancelarLinkPagamento: '¿Quieres cancelar el enlace de pago?',
    linkCanceladoSucesso: 'Enlace cancelado correctamente',
    linkGeradoSucesso: 'Enlace generado con éxito',
    linkEnviadoCanalPaciente: 'Enlace enviado al {{canalEnvio}} del usuario',
    validade: 'Validez',
    condicoesPagamentoRecorrentes: 'Condiciones de pago recurrentes',
    alterarCartaoCredito: 'Cambiar tarjeta',
    assinarRecorrencia: 'Recurrencia de signos',
    multiempresa: 'Situación',
    fornecedor: 'Proveedor',
    fornecedores: 'Proveedores',
    dadosBasicos: 'Datos básicos',
    dadosParaIntegracao: 'Datos para la integración',
    dadosCriacaoContaIntegracao: 'Datos para crear la cuenta de Integración',
    dadosSeraoEnviadosViaApi: 'Los datos ingresados en esta sección serán enviados vía API',
    liberacao: 'Liberación',
    liberacaoPorEmpresas: 'Lanzamiento por empresas',
    documento: 'Documento',
    documentos: 'Documentos',
    criarDocumento: 'Crear documento',
    contato: 'Contato',
    financeiro: 'Financiero',
    dadosFinanceiro: 'Datos Financieros',
    taxasDadosFinanceiros: 'Tarifas y datos financieros',
    produtosIntegrados: 'Productos integrados',
    integracaoConfigurada: '¡Integración configurada!',
    prosseguimentoCliente: 'Ahora es posible continuar con el cliente',
    integrarCartao: 'Integrar tarjeta',
    integrarBoleto: 'Integrar boleto',
    comercial: 'Comercial',
    personalizado: 'Personalizado',
    acesso: 'Acceso',
    anexo: 'Adjunto',
    anexos: 'Archivos adjuntos',
    orientacoes: 'Pautas',
    dadosClinicos: 'Datos clinicos',
    historico: 'Histórico',
    historicoMovimentacoesComentarios: 'Historial de movimientos y comentarios',
    comentario: 'Comentario',
    dados: 'Datos',
    composicao: 'Composición',
    tributacao: 'Impuestos',
    numeroNFSe: 'Nº NFS-e',
    rps: 'RPS',
    situacaoNF: 'Situacíon da NF',
    dataCompetencia: 'Fecha de competência',
    autorizados: 'Autorizados',
    aguardando: 'Esperando',
    rejeitados: 'Rechazados',
    cfop: 'CFOP',
    cnae: 'CNAE',
    selecioneUmaCnae: 'Seleccione una clasificación nacional de actividades económicas',
    selecioneUmCliente: 'Seleccionar un cliente',
    aliquotas: 'Tarifas',
    outrasAliquotas: 'Otras tarifas',
    codigoFiscalDePrestacaoDeServicos: 'Código fiscal para la prestación de servicios',
    tipoTributacao: 'Tipo de tributación',
    estoque: 'Inventario',
    sigla: 'Iniciales',
    observacoes: 'Comentarios',
    observacao: 'Observación',
    sinteseSistemaAvaliacao: 'Resumen del sistema de evaluación',
    versao: 'Versión',
    cadastradoEm: 'Registrado en',
    editadoEm: 'Editado en',
    salvoEm: 'Guardado en',
    editadoPor: 'Editado por',
    ultimaAlteracao: 'Último cambio de registro',
    anexadoPor: 'Adjunto por',
    preenchido: 'Lleno',
    armazenamento: 'Almacenamiento',
    tamanho: 'Tamaño',
    hoje: 'Hoy dia',
    solteArquivos: 'Suelta tus archivos aquí',
    escolhaArquivo: 'Escoge un archivo',
    escolhaArquivoOuArraste: 'Elija un archivo o arrastre aquí',
    solteArquivosTextoSecundario: 'o usa el botón "$t(geral:escolhaArquivoOuArraste)"',
    solteImagemDeDestaque: 'Suelta la imagen destacada aquí',
    selecioneItem: 'Selecciona un artículo',
    itemPrincipal: 'Artículo principal',
    rateio: 'Prorrateo',
    aplicativos: 'Aplicaciones',
    atendimentoOnline: 'Servicio en línea',
    centroAjuda: 'Centro de ayuda',
    sobre: 'Sobre',
    informacoes: 'Información',
    informacoesAdicionais: 'Informaciones adicionales',
    informeContato: 'Informar al contacto principal',
    tagsCadastro: 'Etiquetas de registro',
    adicionarTags: 'Agregar etiquetas',
    adicionarDocumento: 'Agregar documento',
    horarioInicial: 'Hora de inicio',
    horarioFinal: 'Hora de finalización',
    horarioInicio: 'Hora de inicio',
    horarioFim: 'Hora de cierre',
    horarioContato: 'El mejor tiempo para contactar',
    horarioInicioContato: 'El mejor momento para iniciar el contacto',
    horarioTerminoContato: 'El mejor momento para finalizar el contacto',
    selecioneResponsavel: 'Seleccione una persona responsable para vincular al registro',
    selecioneDependente: 'Seleccione un dependiente para vincularlo al registro',
    definaMultiempresa: 'Definir una situación para este registro y en la que se mostrarán las empresas.',
    possuiNecessidadesEspeciais: '¿Tiene alguna necesidad especial??',
    custoAquisicao: 'Precio de compra',
    custoComposicao: 'Costo de composición',
    custoAdicional: 'Costes adicionales',
    custoTotal: 'Costo total del artículo',
    parcela: 'Parte',
    lucroDesejado: 'Beneficio deseado',
    precoSugerido: 'Precio sugerido',
    precoSugeridoVenda: 'Precio de venta sugerido',
    indisponivel: 'No disponible en el portal',
    semResultados: 'No hay resultados',
    fator: 'Factor',
    fatorConversao: 'Factor de conversión',
    saida: 'Salida',
    saidas: 'Salidas',
    entrada: 'Aporte',
    entradas: 'Aperitivo',
    itemPadrao: 'Artículo estándar',
    controlaEstoque: 'Controla el stock',
    movimentaFinanceiro: 'Movimiento financiero',
    novaEmbalagem: 'Nuevo paquete',
    editarEmbalagem: 'Editar empaque',
    embalagem: 'Embalaje',
    embalagem_plural: 'Embalajes',
    embalagemEntrada: 'Embalaje entrante',
    embalagemSaida: 'Embalaje saliente',
    embalagemControleEstoque: 'Embalaje de control de inventario',
    selecioneGrupoAdicionar: 'Seleccione un grupo para agregar',
    selecioneNovoGrupoContato: 'Seleccionar un nuevo grupo de contactos',
    novoEndereco: 'Nueva dirección',
    editarEndereco: 'Editar dirección',
    informeEndereco: 'Ingrese la dirección principal',
    codigoBarras: 'Código de barras',
    responsaveisCurso: 'Responsable del curso',
    tributacaoEntrada: 'Impuesto soportado',
    tributacaoSaida: 'Impuestos salientes',
    selecioneTributacao: 'Seleccionar impuestos',
    custosFixos: 'Costos fijos',
    custoFixoEmpresa: 'Costo fijo de la empresa',
    custoVariavel: 'Coste variable',
    custosVariaveis: 'Costos variables',
    lucroPercentual: 'Beneficio porcentual',
    pesquisar: 'Buscar',
    appBarPesquisar: 'Investigar algo...',
    pesquisarNomeCpf: 'Búsqueda por nombre y CPF',
    pesquisarNomeCpfCnpj: 'Búsqueda por nombre, CPF o CNPJ',
    pesquisarNomeCpfDataNascimento: 'Búsqueda por nombre, CPF o fecha de nacimiento',
    emDesenvolvimento: 'En desarrollo',
    favoritos: 'Favoritos',
    lucro: 'Lucro',
    lucroEm: 'Beneficio en',
    lucroEmPorcentagem: 'Beneficio en%',
    lucroEmReais: 'Beneficio en R$',
    tipoConta: 'Tipo de cuenta',
    instituicaoBancaria: 'Institución bancaria',
    naoPermiteLancamentos: 'No permite publicaciones',
    nomeContatoBanco: 'Nombre de contacto bancario',
    digito: 'Dígito',
    selecioneExecutor: 'Seleccione un ejecutor para vincular',
    consulta: 'Consulta',
    restricoes: 'Restricciones',
    comissoes: 'Comisiones',
    tipoComissoes: 'Tipo de comisiones',
    origemComissao: 'Origen de la comisión',
    equipePadrao: 'Equipo estándar',
    cargo: 'Oficina',
    horarioAcesso: 'Configuración de tiempos de acceso',
    grupo: 'Grupo',
    cargoGrupo: '$t(geral:cargo) / $t(geral:grupo)',
    percentual: 'Porcentaje',
    chanceConversao: '% Posibilidad de conversión',
    descricaoExemplo: 'Descripción de ejemplo',
    interessado: 'Interesado',
    grauConversao: 'Grado de conversión',
    adicionarItem: 'Añadir artículo',
    adicionarAvaliacao: 'Agregar una opinión',
    periodo: 'Curso del tiempo',
    periodoPedagogico: 'Período Pedagógico',
    de: 'En',
    ate: 'Hasta que',
    para: 'Para',
    geral: 'General',
    automatica: 'Automático',
    automatico: 'Automático',
    manual: 'Manual',
    tipoCampo: 'Tipo de campo',
    status: 'Estado',
    statusOrcamento: 'Estado / Presupuesto',
    statusNumeroOrcamento: 'Estado / Presupuesto No.',
    numeroOrcamento: 'Presupuesto No.',
    codigoTitulo: 'Código de título',
    cicloCalculo: 'Ciclo / Cálculo',
    condicaoComissaoem: 'Condición / Comisión en',
    descontarCancelamentosDevolucoes: 'Descuentos en cancelaciones y devoluciones',
    considerarDescontoCascata: 'Considerar el descuento en cascada',
    cicloDePagamento: 'Ciclo de pago',
    cicloDePagamentoComissao: 'Ciclo de pago de comisiones',
    baseCalculo: 'Base de cálculo',
    basesAtivas: 'Bases activas',
    funcionalidadePadrao: 'Funcionalidad estándar',
    novaMeta: 'Nuevo gol',
    duracao: 'Duración',
    dataInicio: 'Fecha de inicio',
    dataTermino: 'Fecha de finalización',
    dataInicioTermino: 'Fecha de inicio y finalización',
    dataVencimento: 'Fecha de vencimiento',
    dataGeracao: 'fecha de generación',
    dataEncerramento: 'Fecha de cierre',
    dataRecebimento: 'Fecha de recepción',
    dataRecebimentoInicial: 'Fecha de recepción inicial',
    dataRecebimentoFinal: 'Fecha de recibo final',
    numeroFatura: 'Factura no.',
    natureza: 'Naturaleza',
    naturezaParcelas: 'Naturaleza / Parcelas',
    restricaoAcrescimo: 'Restricción / Adición',
    bandeiraCredenciadora: 'Bandera / Acreedor',
    formaCondicao: 'Forma / Condición',
    taxaPrazo: 'Tarifa / Plazo',
    contaCompensacao: 'cuenta bancaria / compensación',
    documentoFornecedor: 'Documento / Proveedor',
    origemDocumentoCliente: 'Origen del documento / Cliente',
    emissaoVencimento: 'Emisión / Vencimiento',
    emissaoEntrada: 'Emisión / Entrada',
    totalQuantidade: 'Cantidad total',
    totalQuantidadeItems: 'Total / Cantidad de artículos',
    operacaoObservacoes: 'Operación / Observaciones',
    AVista: 'A la vista',
    APrazo: 'A plazos',
    restricao: 'Restricción',
    selecioneRestricao: 'Seleccionar restricción',
    selecioneTipoCalculoComissao: 'Seleccione el tipo de cálculo utilizado en comisión.',
    acrescimo: 'Adición',
    emMoeda: 'En $t(geral:currency)',
    emPorcentagem: 'En %',
    porcentagem: 'Porcentaje',
    vencimento: 'Fecha de vencimiento {{number}}',
    porcentagemDaParcela: 'Porcentaje de pago a plazos',
    mensal: 'Mensual',
    semanal: 'Semanalmente',
    quinzenal: 'Quincenal',
    diario: 'Diario',
    parcelas: 'Cuotas',
    numeroParcelas: 'Número de plazos',
    valorMeta: 'Valor objetivo',
    valorComissao: 'Monto de la comisión',
    valorDaParcela: 'Valor a plazos',
    informacaoDePagamento: 'Información del Pago',
    utilizaValorVencimentoFixo: 'Utilice valor fijo y vencimiento fijo',
    valorVencimentoVaiSerDefinido: 'El monto y el vencimiento se definirán al utilizar este plazo de pago.',
    diaVencimento: 'Día de Expiración',
    diasAposVencimento: 'Días después de la Expiración',
    pagamento: 'Pago',
    recebimento: 'Recepción',
    ambos: 'Ambos',
    dia: 'Día',
    porDia: 'Por Dia',
    porMes: 'Por Mes',
    dia_plural: 'Dias',
    enviarFoto: 'Enviar foto',
    disponivelEm: 'Disponible',
    modulosDisponiveis: 'Módulos disponibles',
    moduloPadrao: 'hacer el módulo predeterminado',
    situacaoModuloAtiva: 'Activar el estado del módulo',
    selecioneModuloRelacionar: 'Seleccione un módulo para listar',
    contaDebito: 'Cuenta de debito',
    contaCredito: 'Cuenta de crédito',
    tipoDeContato: 'Tipo de Contacto',
    tipoDeCalculo: 'Tipo de cálculo',
    selecioneContato: 'Seleccionar un contacto',
    produtosEServicos: 'Productos y servicios',
    produtosServicos: 'Productos y servicios',
    origem: 'Fuente',
    origemOportunidade: 'Origen de la oportunidad',
    quemIndicou: 'Quien refirió?',
    novaTarefa: 'Nueva tarea',
    indicadoPor: 'Indicado por',
    equipe: 'Equipo',
    selecioneTarefa: 'Seleccionar una tarea',
    grauDeInteresse: 'Grado de interes',
    tipoDeCompromisso: 'Tipo de cita',
    motivo: 'Razón',
    desconto: 'Descuento',
    descontos: 'Descuentos',
    motivoComercial: 'Motivo comercial',
    descontoConcedido: 'Descuento concedido',
    valorDesconto: 'Importe de descuento',
    valorOriginal: 'Valor original',
    valorBolsa: 'Precio de mercado',
    usuarioOportunidade: 'Usuario: ',
    bolsa: 'Bolso',
    politica: 'Políticas',
    usuariosResponsaveis: 'Usuarios responsables',
    selecioneUsuarios: 'Seleccionar usuarios',
    selecioneUsuario: 'Seleccionar un usuario',
    comentarios: 'Comentarios',
    possibilidades: 'Posibilidades',
    escrevaUmComentario: 'Escribir un comentario',
    gerenciarWorkflow: 'Administrar el embudo',
    novoTipoDeContato: 'Nuevo tipo de contacto',
    restringirA: 'Restringir a',
    restringirAFormaDePagamento: 'Restringir al método de pago',
    novoCadastro: 'Nuevo registro',
    disponivel: 'Disponible',
    disponivelNoPortal: 'Disponible en el portal',
    responsavelOportunidade: 'Responsable de la oportunidad',
    principal: 'Principal',
    selecionarEmpresa: 'Seleccionar empresa',
    selecionarModelo: 'Seleccionar modelo',
    situacaoAtualizadaSucesso: 'Estado actualizado con éxito',
    empresaAdicionadaSucesso: 'Compañía agregada exitosamente',
    sucessoComentario: 'Comentario añadido con éxito',
    editarSituacao: 'Editar situación',
    selecioneEmpresaRelacionar: 'Seleccione una empresa para listar',
    bandeira: 'Bandera',
    bandeiras: 'Banderas',
    credenciadora: 'Acreedor',
    credenciadoras: 'Acreedores',
    cartoes: 'Tarjetas',
    formaDePagamento: 'Forma de pago',
    taxa: 'Índice',
    prazo: 'Fecha límite',
    valorMaximo: 'Valor máximo',
    limitePacientes: 'Limitar pacientes',
    numeroSequencial: 'Numero secuencial',
    compensaVencimento: 'Compensa la madurez',
    selecioneContaBancaria: 'Seleccione una institución bancaria',
    conciliacao: 'Conciliación',
    conciliacaoAutomatica: 'Reconciliación automática',
    orcamento: 'Presupuesto',
    orcamentos: 'Presupuestos',
    valor: 'Valor',
    valorPrimeiraParcela: 'Valor 1ª cuota',
    vencimentoPrimeiraParcela: 'Vencimiento 1ª cuota',
    orcamentoData: '$t(geral:orcamento) / $t(geral:data)',
    ano: 'Año',
    anual: 'Anual',
    serieAno: 'Grado o curso / año',
    valorParcelas: '$t(geral:valor) / $t(geral:parcelas)',
    situacaoTipo: '$t(geral:situacao) / $t(geral:tipo)',
    fornecedorDataVencimento: 'Proveedor / Fecha de vencimiento',
    statusDataPagamento: 'Estado / Fecha de pago',
    caixaEmpresa: 'Cajero / Compañía No.',
    valorParcela: 'Valor / Cuota',
    parcelaVencimento: 'Plazo / Vencimiento',
    nomeCep: 'Nombre / código postal',
    cidadeEstado: 'Ciudad estado',
    tipoEndereco: 'Tipo de dirección',
    logradouro: 'Logradouro',
    logradouroNumero: 'Logradouro / Nº',
    tipoLogradouro: 'Tipo de logradouro',
    bairro: 'Vecindario',
    numero: 'Número',
    operador: 'Operador',
    aberto: 'Abierto',
    consolidado: 'Consolidado',
    naoConsolidado: 'Sin consolidar',
    efetivado: 'Hecho efectivo',
    cancelado: 'Llamado fuera',
    pendente: 'Pendiente',
    processando: 'Processando',
    fechado: 'Cerrado',
    finalizado: 'Finalizado',
    abertura: 'Apertura',
    fechamento: 'Cierre',
    retirar: 'Retirar',
    suprimento: 'Suministro',
    numeroDoContrato: 'Número de contacto',
    codigoDoContrato: 'código de contrato',
    quitado: 'Establecido',
    vencido: 'Atrasado',
    atendimentos: 'Atenciones',
    evolucao: 'Evolución',
    dataEHora: 'Fecha y hora',
    data: 'Fecha',
    hora: 'Hora',
    subTotal: 'Total parcial',
    intervaloVencimentoParcelas: 'Intervalo de vencimiento de las cuotas',
    acrescimoDesconto: 'Adición / Descuento',
    notas: 'Los grados',
    situacaoFinanceira: 'Situación financiera',
    acoes: 'Comportamiento',
    total: 'Total',
    media: 'Promedio',
    frequencia: 'Frecuencia',
    nomeFrequencia: 'Nombre / Frecuencia',
    reposicao: 'Reemplazo',
    horario: 'Horário',
    sala: 'Sala',
    salas: 'Salas',
    horarioSala: '$t(geral:horario) / $t(geral:sala)',
    maisContatos: 'Mas contactos',
    nomeDetalhes: 'Nombre / Detalles',
    inicioTermino: 'Comenzar terminar',
    tipoDeProduto: 'Tipo de producto',
    textoRespostaCurta: 'Texto de respuesta corta',
    textoRespostaLonga: 'Texto de respuesta larga',
    digiteSuaPergunta: 'Ingrese su pregunta aquí',
    tempoAgendamento: 'Hora de programar antes de la cita',
    tempoDuracaoAtividade: 'Tiempo de duración de la actividad',
    intervaloRecreio: 'Romper romper',
    configuracoes: 'Ajustes',
    configuracoesDeVigencia: 'Configuración de efectividad',
    selecaoCanal: 'Selección de canales',
    portalAluno: 'Portal del estudiante',
    aplicativo: 'Solicitud',
    configuracaoDaEmpresa: 'configuración de la empresa',
    tipoPagamento: 'Tipo de pago',
    inicio: 'Comienzo',
    termino: 'Terminación',
    horarios: 'Horarios',
    diarioClasse: 'Diario de clase',
    selecioneFormaDePagamentoVincular: 'Seleccione un método de pago para vincular',
    peso: 'Peso',
    altura: 'Altura',
    pesoOrdem: 'Peso / Orden',
    nomeTemplate: 'Nombre / Plantilla',
    adicionarOpcoes: 'Agregar opciones',
    adicionarEmpresa: 'Agregar empresa',
    visualizar: 'Para ver',
    selecioneCaixa: 'Seleccione una caja',
    condicaoDePagamento: 'Condición de pago',
    condicoesDePagamento: 'Condiciones de pago',
    gerarTitulos: 'Generar títulos',
    estornarLancamentos: 'Entradas inversas',
    funcao: 'Ocupación',
    especialidade: 'Especialidad',
    subEspecialidade: 'Subespecialidad',
    somarHonorario: 'Agregar tarifa',
    honorario: 'Honorario',
    honorarios: 'Tarifa',
    tipoFalta: 'Tipo de avería',
    funcaoTipo: '$t(geral:funcao) / $t(geral:tipo)',
    valorEmpresa: '$t(geral:valor) / $t(geral:empresa)',
    dataAdmissao: 'Fecha de admisión',
    dataDemissao: 'Fecha de renuncia',
    contasBancarias: 'Cuentas bancarias',
    pedidoCompra: 'Orden de compra',
    pedidoCompras: 'Pedido de compras',
    countUnidade: '{{count}} unidad',
    countUnidade_plural: '{{count}} unidades',
    countItem: '{{count}} artículo',
    countItem_plural: '{{count}} elementos',
    valorTotal: 'Valor total',
    valorPorParcela: 'Valor por cuota',
    valorLucro: 'Valor de beneficio',
    repetir: 'Repetir',
    novoItem: 'Nuevo artículo',
    editarItem: 'Editar artículo',
    selecioneFase: 'Seleccione un escenario',
    selecioneFunil: 'Seleccione un Embudo',
    faseDoNegocio: 'Fase empresarial',
    editar: 'Para editar',
    enviar: 'Mandar',
    verRespostas: 'Ver respuestas',
    resposta: 'Respuesta',
    resposta_plural: 'Respuestas',
    compartilhar: 'Para compartir',
    baixarCSV: 'Descargar CSV',
    novoContato: 'Nuevo contacto',
    recorrencia: 'Reaparición',
    tarefas: 'Tareas',
    prestador: 'Proveedor',
    ultimaTarefa: 'Última tarea',
    sistema: 'Sistema',
    clientes: 'Clientes',
    clienteOuFornecedor: 'Cliente o proveedor',
    clienteFornecedorOperador: 'Cliente, proveedor u operador',
    clienteFornecedorFuncionario: 'Cliente, proveedor o empleado',
    contaBancaria: 'Cuenta bancaria',
    adicionar: 'Agregar',
    adicionarValor: 'Añadir valor',
    novaCondicao: 'Nueva condición',
    adicionarCondicao: 'Agregar condición',
    gerenciarCondicoes: 'Gestionar las condiciones',
    novoValor: 'Nuevo valor',
    abrirCaixa: 'Abrir caja',
    fecharCaixa: 'Ferrar caja',
    suprirCaixa: 'Caja de suministro',
    retirarCaixa: 'Retirar do caixa',
    justificativa: 'Justificación',
    origemDocumento: 'Origen del documento',
    vendedor: 'Vendedor',
    vendidoPor: 'Vendido por',
    totalFechamento: 'Total de cierre',
    saldoFechamento: 'Balance de cierre',
    totalSuprimento: 'Suministro total',
    saldoSuprimento: 'Balance de suministro',
    totalRetirada: 'Retiro total',
    saldoRetirada: 'Saldo de retiro',
    saldoAtual: 'Saldo actual',
    valorRetirada: 'Cantidad de retiro',
    valorFechamento: 'Valor de cierre',
    caixa: 'Caja',
    dataPagamento: 'Día de paga',
    profissional: 'Profesional',
    profissionais: 'Profesionales',
    adicionarProfissional: 'Agregar profesional',
    selecioneProfissionalParaRelacionar: 'Seleccione un profesional para listar',
    selecionePrestadorParaRelacionar: 'Seleccione un proveedor para listar',
    equipamentos: 'Equipos',
    servicos: 'Servicios',
    intervalo: 'Rotura',
    diasDaSemana: 'Dias de la semana',
    diaUtil: 'Dia útil',
    feriado: 'Fiesta',
    faixaEtaria: 'Grupo de edad',
    falta: 'Ausencia',
    presente: 'Presente',
    limiteFaltas: 'Sobre el límite',
    limiteRetornos: 'Límite de devolución',
    selecionarSabados: 'Seleccionar sábados',
    selecionarDomingos: 'Seleccionar domingos',
    tipoPeriodo: 'Tipo de período',
    imprimirLegenda: 'Imprimir título',
    mostrarImpresso: 'Mostrar en impresión',
    eventoVoltadoResponsaveis: 'Evento dirigido a personas responsables',
    considerarSabados: 'Considerar sábados',
    considerarDomingos: 'Considerar domingos',
    horarioFuncionamento: 'Horas de oficina',
    acessoOutrosProfissionais: 'Acceso de otros profesionales',
    notificarLimiteFaltas: 'Notificar el límite de ausencias',
    adicionarDependencia: 'Agregar dependencia',
    dadosDaMatricula: 'Datos de inscripción',
    dependencias: 'Dependencias',
    opcoes: 'Opciones',
    calendario: 'Calendario',
    movimento: 'Movimiento',
    'contas-a-pagar': 'Cuentas a pagar',
    'contas-a-receber': 'Cuentas a recibir',
    carregarMais: 'Carga más',
    tempo: 'Tiempo',
    novoIntegrante: 'Nuevo miembro',
    modalidadeTurma: 'Modalidad de clase',
    novaSituacao: 'Nueva situación',
    novoProdutoServico: 'Nuevo producto / servicio',
    especie: 'Espécie',
    cartao: 'Tarjeta',
    cheque: 'Cheque',
    funcionario: 'Empleado',
    respondidoEm: 'Respondido en',
    respondidoPor: 'Respondido por',
    estrela: 'Estrella',
    estrela_plural: 'estrellas',
    pesquisePorResposta: 'Buscar una respuesta...',
    emocao: 'Emoción',
    enviarPesquisa: 'Enviar Encuesta',
    envieLink: 'Enviar el enlace',
    copiarLink: 'Copiar link',
    copiarCodigo: 'Copiar código',
    reenviarSms: 'Reenviar SMS',
    reenviarEmail: 'Reenviar correo electrónico',
    linkCopiado: 'Enlace copiado',
    valorPago: 'Valor pago',
    valorPendente: 'Importe pendiente',
    valorItemServico: 'Valor del artículo/servicio',
    preDefinidos: 'Predefinido',
    novaSecao: 'Nueva sección',
    adicionarCampo: 'Agregue campo',
    novoCampo: 'Nuevo campo',
    editarCampo: 'Editar campo',
    tipoDoCampo: 'Tipo do campo',
    tamanhoDoCampo: 'Tamaño del campo',
    valorPadrao: 'Valor estandar',
    muito_pequeno: 'Muy pequeño',
    pequeno: 'Poco',
    medio: 'Medio',
    grande: 'Grande',
    textoCurto: 'Texto corto',
    termo: 'Término',
    laboratorio: 'Laboratorio',
    estaSalaEUmLaboratorio: 'Esta sala es un laboratorio?',
    locacao: 'Localización',
    permiteLocacao: 'Permite el alquiler?',
    tipoDeSala: 'Tipo de habitación',
    equipamentoFixo: 'Equipo fijo?',
    capacidade: 'Capacidad',
    capacidadeMaxima: 'Maxima capacidad',
    verPerfil: 'Ver perfil',
    assinarDigitalmente: 'Firmar digitalmente',
    nomeSocial: 'Nombre social',
    serChamadoPeloNomeSocial: 'Ser llamado por su nombre social',
    profissaoResponsavel: 'Profesión responsable',
    idadeResponsavel: 'Edad del tutor',
    midia: 'Medios de comunicación',
    plano: 'Plano',
    tipoCusto: 'Tipo de costo',
    valorCusto: 'Valor de coste',
    qtdProfissionais: 'Cantidad profesional',
    necessidadeEspecial: 'Necesidad especial',
    unidade: 'Unidad',
    unidades: 'Unidades',
    subUnidade: 'Subunidad',
    optativo: 'Opcional',
    obrigatorio: 'Obligatorio',
    numeroDocumento: '$t(geral:numeroAbreviado) documento',
    nomeDocumento: 'Nombre del documento',
    nomeDocumentoObrigatorio: 'El nombre del documento es obligatorio',
    indicacao: 'Recomendación',
    modelos: 'Modelos',
    solicitante: 'Solicitante',
    executante: 'Ejecutante',
    imprimir: 'Imprimir',
    imprimirApenasDescricao: 'Imprimir descripción solamente',
    imprimirProfissionais: 'Profesionales de la impresión',
    digiteAqui: 'Digite aquí',
    exame: 'Examen',
    exame_plural: 'Exámenes',
    origemExame: 'Origen del examen',
    selecionarExame: 'Seleccionar examen',
    adicionarExame: 'Agregar examen',
    novoProtocolo: 'Nuevo protocolo',
    tabela: 'Tabla',
    saldoConta: 'Saldo de la cuenta',
    tipoProtocolo: 'Tipo de protocolo',
    selecionarProtocolo: 'Seleccionar protocolo',
    efetivados: 'Eficaz',
    pendentes: 'Sobresaliente',
    finalizados: 'Finalizado',
    tipoDeConversao: 'Tipo de conversión',
    configuracao: 'Ajustes',
    permiteCompra: 'Permite la compra',
    permiteVenda: 'Permite la venta',
    anosFaixa: 'Años / Rangos',
    competenciaEspecifica: 'Competencia específica',
    novoUsuario: 'Nuevo Usuario',
    patrimonio: 'Patrimonio',
    identificacaoPatrimonio: 'Identificación de activos',
    idadeMinima: 'Edad mínima',
    idadeMaxima: 'Edad máxima',
    executor: 'Ejecutor',
    orientacao: 'Guia',
    precaucao: 'Precaución',
    alergia: 'Alergia',
    alergiasGerais: 'Alergias generales',
    tipoSanguineo: 'Tipo de sangre',
    classificacao: 'Clasificación',
    url: 'URL',
    videoUrl: 'URL del vídeo',
    secoesDisponiveis: 'Secciones disponibles',
    finalizarImprimir: 'Terminar e imprimir',
    naoInformado: 'No informado',
    naoInformada: 'no informado',
    emissao: 'Asunto',
    codigoTabelaTuss: 'Código de tabla TUSS',
    codigoTuss: 'Código TUSS',
    filtroAvancado: 'Filtro avanzado',
    alunos: 'Estudiantes',
    valorAtual: 'Valor actual',
    itemQtd: 'Artículo / Cant.',
    valorAnual: 'Valor anual',
    filtros: 'Filtros',
    camposRelatorio: 'Campos de informe',
    limiteCamposRelatorio: 'Permitido seleccionar solo 6 elementos por informe',
    verBoleto: 'Ver palanquilla',
    copiarCodigoDeBarra: 'Copiar código de barras',
    gerarBoleto: 'Generar palanquilla',
    baixarPdf: 'Descargar comprobante de pago en PDF',
    receber: 'Recibir',
    efetivar: 'Efectuar',
    gerado: 'Generado',
    liquidado: 'Liquidado',
    falhou: 'Fallido',
    baixado: 'Descargado',
    maisInformacoes: 'Más información',
    criadoPeloSistema: 'Creado por el sistema',
    criadoPelaConciliacao: 'Creado por conciliación bancaria',
    liquidadoManualmente: 'Liquidado manualmente',
    liquidadoPelaConciliacao: 'Liquidado por conciliación bancaria',
    quitadoPeloSistema: 'Pagado por el sistema',
    falhouLiquidacao: 'No se pudo liquidar',
    rejeitado: 'Rechazado',
    baixadoManualmente: 'Descargado manualmente',
    geradoExternamente: 'Generado externamente',
    cancelamentoSolicitado: 'Cancelación solicitada',
    cancelamentosolicitado: 'Cancelación solicitada',
    solicitacaoCancelamentoFalhou: 'Solicitud de cancelación fallida',
    cancelamentofalhou: 'Cancelación fallida',
    novaEspecialidade: 'Nueva especialidad',
    prazoMaximoAtendimento: 'Período máximo de servicio',
    tributacaoPadrao: 'Tributación estándar',
    selecionar: 'Seleccione',
    pedidoVenda: 'Orden de venta',
    acessarPedido: 'Orden de acceso',
    acessarTitulo: 'Título de acceso',
    fecharCaixaAberto: 'Cerrar caja abierta',
    ultimosNMeses: 'Últimos {{qtdeMeses}} meses',
    contasAPagar: 'Cuentas a pagar',
    contasAReceber: 'Cuentas a recibir',
    movimentacoes: 'Movimientos',
    saldoTotal: 'Balance total',
    recebido: 'Recibió',
    aReceber: 'A recibir',
    pago: 'Pagado',
    aPagar: 'Borrar',
    saldoInicial: 'Saldo inicial',
    saldoDaConta: 'Saldo de la cuenta',
    historicoDeMovimentacoes: 'Historia del movimiento',
    motivoDoDesfecho: 'Razón del resultado',
    tipoMotivoDesfecho: 'Tipo de motivo de resultado',
    situacaoMatricula: 'Estado de registro',
    tipoMatricula: 'Tipo de registro',
    avaliacaoRealizada: 'Evaluación realizada',
    grauNecessidadeEspecial: 'Grado de necesidad especial',
    filial: 'Subsidiaria',
    matriz: 'Sede',
    trocarEmpresa: 'Cambiar de empresa',
    senha: 'Contraseña',
    senhaIncorreta: 'Contraseña incorrecta',
    senhaAtual: 'Contraseña actual',
    novaSenha: 'Nueva contraseña',
    confirmacaoSenha: 'Confirmación de contraseña',
    emailAcesso: 'Acceder al correo electrónico',
    solicitarNovaSenha: 'Solicitar nueva contraseña después',
    permitirAcesso: 'permitir el acceso',
    manha: 'Mañana',
    tarde: 'Noche',
    noite: 'Noche',
    adicionarLocal: 'Añadir lugar',
    enderecoIP: 'Dirección IP',
    recurso: 'Recurso',
    tipoNecessidadeEspecial: 'Tipo de necesidad especial',
    horariosLivres: 'Horas libres',
    chegada: 'Llegada',
    cor: 'Color',
    cor_plural: 'Colores',
    selecioneCor: 'Seleccione un color',
    adicionarRecurso: 'Agregar recurso',
    faixaEtariaInicial: 'Grupo de edad inicial',
    faixaEtariaFinal: 'Grupo de edad final',
    modeloPadrao: 'Modelo estandar',
    modeloPadraoSerie: 'Modelo estandar de la serie',
    modelo: 'Modelo',
    habilitarTodos: 'Permitir a todos',
    limiteReposicoes: 'Límite de reemplazo',
    prazoParaReposicao: 'Fecha límite de reemplazo',
    notificarLimiteFaltasExcedido: 'Notificar límite de ausencia excedido',
    verCadastro: 'Ver registro',
    bloquear: 'Cuadra',
    excluir: 'Borrar',
    personalizarLayout: 'Personalizar el diseño',
    topo: 'Cima',
    rodape: 'Zócalo',
    produto: 'Producto',
    impressao: 'Impresión',
    disponibilizarAssinatura: 'Proporcionar suscripción,',
    iniciar: 'Comienzo',
    escolhaUmModelo: 'Elige una plantilla',
    anexarArquivo: 'Adjuntar archivo',
    exibirCabecalho: 'Encabezado de pantalla',
    alinhamentoDoCabecalho: 'Alineación de encabezado',
    fonte: 'Fuente',
    texto: 'Texto',
    espacamento: 'Espaciado',
    mostrarData: 'Mostrar fecha',
    estornos: 'Contracargos',
    mensagem: 'Mensaje',
    mensagemPadrao: 'Mensaje predeterminado',
    subespecialidade: 'Subespecialidad',
    clienteDesde: 'Cliente desde',
    ticketMedio: 'Billete medio',
    tipoSituacaoCheque: 'Estado',
    tipoConciliacao: 'Tipo de reconciliación',
    compensado: 'Compensar',
    devolvido: 'Regresó',
    em_aberto: 'En abierto',
    dataExpiracao: 'Fecha de expiración',
    ambiente: 'Medio ambiente',
    horaData: 'Hora y fecha',
    ativa: 'Activo',
    inativa: 'Inactivo',
    formaFisica: 'Forma física',
    formula: 'Fórmula',
    diasDisponiveis: 'Días disponibles',
    diasLetivos: 'Dias escolares',
    totalDiasLetivos: 'Días escolares totales',
    xDiasLetivos: '{{value}} dias escolares',
    anoLetivo: 'Año escolar',
    anosLetivos: 'Años académicos',
    xAnosLetivos: '{{value}} años académicos',
    adicionarEvento: 'Agregar evento',
    responsavelPelaCondicao: 'Responsable de la condición',
    clientesAtivos: 'Clientes activos',
    faltosos: 'Morosos',
    modalidades: 'Modalidades',
    verTodos: 'Ver todo',
    verTodas: 'ver todo',
    particular: 'Privado',
    dataAtualizacao: 'Fecha de actualización',
    enviarSms: 'Enviar SMS',
    template: 'Plantilla',
    quantidadeItens: 'Cantidad de artículos',
    quantidadeItensInicial: 'Cantidad inicial de artículos',
    quantidadeItensFinal: 'Cantidad de artículo final',
    mes: 'Mes',
    procedimentos: 'Procedimientos',
    procurarPorAlunoResponsavelEmail: 'Estudiante, Responsable o Email.',
    procurarPorPacienteResponsavelEmail: 'Paciente, Responsable o Email.',
    tempoAgendamentoAntesConsulta: 'Hora de programar antes de la cita',
    verAgenda: 'Ver horario',
    formulas: 'Fórmulas',
    permite: 'Permite',
    prestadoresEmissores: 'Proveedores y emisores',
    exibirRodape: 'Mostrar pie de página',
    rodapeCarimbo: 'Pie de página y sello',
    exibirRodapeCarimbo: 'Mostrar pie de página y sello',
    alinhamentoDoRodape: 'Alineación del pie de página',
    idade: 'La edad',
    secaoRemovidoSucesso: 'Sección eliminada con éxito',
    impressaoReceita: 'Impresión de ingresos',
    removerSecao: 'Eliminar sección',
    adicionarSecao: 'Agregar sección',
    replicarHorarios: 'Replicar tiempos',
    replicar: 'Reproducir exactamente',
    diaSemana: 'Dia de la semana',
    as: 'a',
    integracao: 'Integración',
    novoCheque: 'Nuevo cheque',
    novoLancamentoCartao: 'Lanzamiento de nueva tarjeta',
    nsu: 'NSU',
    diferencaConsolidacao: 'Consolidación de diferencias',
    diferencaFechamento: 'Diferencia de cierre',
    contaContabilCredito: 'Cuenta de mayor para crédito',
    contaContabilDebito: 'Cuenta de mayor para débito',
    contaContabilDebitoHelper:
      'Una cuenta del libro mayor de débito es la cuenta de destino del valor, bien o servicio.',
    contaContabilCreditoHelper:
      'Una cuenta del libro mayor de crédito es la cuenta de origen del valor, el bien o el servicio.',
    tipoOperacaoHelper:
      'Como todo movimiento que resulte en representación financiera en el DRE debe contener una operación asociada a una cuenta de mayor para el origen de los recursos y una cuenta de mayor para el destino de los recursos.',
    funil: 'Embudo',
    calculo: 'Cálculo',
    grupoDeDocumentos: 'Grupo de documentos',
    conteudo: 'Contenido',
    marcarTodos: 'Márcalo todo',
    desmarcarTodos: 'Deseleccionar todo',
    professores: 'Profesores',
    funcionarios: 'Empleados',
    aberturaCaixa: 'Apertura de efectivo',
    condicao: 'Condición',
    condicao_plural: 'Condiciones',
    variavel: 'Variable',
    variavel_plural: 'Variables',
    parametro: 'Parámetro',
    parametro_plural: 'Parámetros',
    tenteNovamente: 'Inténtalo de nuevo',
    notFound: 'No se pudo cargar la página.',
    conclusao: 'Conclusión',
    periodos: 'Periodos',
    listaPeriodos: 'Lista de periodos',
    adicionarFormula: 'Agregar fórmula',
    adicionarPeriodo: 'Agregar período',
    isento: 'Gratis',
    isenta: 'Gratis',
    nomeTurma: 'Nombre de la clase',
    verParcelas: 'Ver paquetes',
    dataContrato: 'Fecha del contrato',
    prezado_prezada: 'querido(a)',
    recomendacao: 'Recomendación',
    recomendacao_plural: 'Recomendaciones',
    anonimo: 'Anónimo',
    camera: 'Cámara',
    tirarFoto: 'Tomar foto',
    tirarOutraFoto: 'Tomar otra foto',
    cameraDesligada: 'Cámara apagada',
    enviarEmail: 'Enviar correo electrónico',
    selecioneClienteOuFornecedor: 'Seleccione un cliente o proveedor',
    condicaoTaxa: 'Condición / Tasa',
    prazoCompensaVencimento: 'Plazo / Compensación al vencimiento',
    quitarConta: 'Saldar la cuenta',
    quitarLote: 'Liquidar facturas por lotes',
    consolidacaoLote: 'Consolidación de cajas en lote',
    desconsolidacaoLote: 'Desconsolidación de la caja de lotes',
    consolidacaoTitulosLote: 'Consolidación de bonos por lotes',
    desconsolidacaoTitulosLote: 'Desconsolidación de valores en lote',
    titulosSomando: 'títulos sumando',
    lancarBaixa: 'Post cancelar',
    tipoAcrescimoDesconto: 'Añadir / tipo de descuento',
    agendadoEm: 'Programado el',
    acessarTeleconsulta: 'Acceder a la teleconsulta',
    notificacoes: 'Notificaciones',
    dataFixa: 'Fecha fijada',
    valorFixo: 'Valor fijo',
    data_hora: 'Fecha / hora',
    aceitarTermos: 'Aceptar los términos',
    dataVencimentoInicial: 'Fecha de vencimiento inicial',
    dataVencimentoFinal: 'Fecha de vencimiento final',
    agrupamento: 'Agrupamiento',
    semAgrupamento: 'Sin agrupamiento',
    agruparVencimento: 'Agrupar por vencimiento',
    novoPeriodo: 'Nuevo período',
    editarPeriodo: 'Período de edición',
    novoRecurso: 'Nueva caracteristica',
    prazoParaRetorno: 'Fecha límite para devolución',
    disponivelAgendamentoOnline: 'Disponible en programación en línea',
    disponivelPortalAluno: 'Disponible en el portal del estudiante',
    diasSemana: 'Dias de la semana',
    categoriaAgendamento: 'Categoría de programación',
    novoFuncionario: 'Nuevo empleado',
    editarFuncionario: 'Editar empleado',
    historicoChat: 'Historial de chat',
    campos: 'Los campos',
    local: 'Local',
    nenhumRecursoCadastrado: 'No hay recursos registrados',
    relatorios: 'Informes',
    gerarOrcamento: 'Generar presupuesto',
    gerarVenda: 'Generar venta',
    padrao: 'Patrón',
    visualizacao: 'visualización',
    visualizacaoEmLista: 'Vista de la lista',
    visualizacaoPorCard: 'Ver por tarjeta',
    download: 'Descargar',
    nomeMantenedora: 'Nombre del proveedor',
    representanteLegal: 'Representante legal',
    limit50MB: '50 MB',
    arquivoRemovido: 'Archivo eliminado correctamente',
    arquivoAtualizado: 'Archivo actualizado con éxito',
    detalhesAnexo: 'Detalles adjuntos',
    visualizarBaixar: 'Ver descarga',
    alergias: 'Alergias',
    alergiasMedicamentosas: 'Alergias a medicamentos',
    medicamentosUsoContinuo: 'Medicamentos de uso continuo',
    precaucoes: 'Precauciones',
    agenda: 'Calendario',
    pesquiseAqui: 'Busca aquí...',
    funcionarioPermitido: 'Empleado permitido',
    publica: 'Público',
    termosDeAceite: 'Condiciones de aceptación',
    aceito: 'Aceptado',
    naoAceito: 'No acepto',
    adendo: 'Apéndice',
    assinar: 'Para firmar',
    planos: 'planes',
    atributoAnatomico: 'Atributo anatómico',
    convenios: 'Convenios',
    tipoSecao: 'Tipo de sección',
    inserirImagem: 'insertar imagen',
    mostrarX: 'Show "{{name}}"',
    titulo: 'Título',
    idTitulo: 'ID do título',
    clientId: 'Cliente ID',
    id: 'ID',
    tamanhoFolha: 'Tamaño de hoja',
    alinhamentoCarimbo: 'Alineación del sello',
    imprimirCarimboSomenteUltimaPagina: 'Imprimir sello solo en la última página',
    modeloImpressao: 'Plantilla de impresión',
    modeloReceituario: 'Plantilla de receta',
    publico: 'Público',
    privado: 'Privado',
    termosUso: 'Terminos de uso',
    portalPrivacidade: 'Portal de privacidad',
    dataAceite: 'Fecha de aceptación',
    documentacao: 'Documentación',
    modeloDeDocumentos: 'Documento ejemplar',
    dataInicioVigencia: 'Fecha efectiva',
    dataTerminoVigencia: 'Fecha efectiva',
    tipoContrato: 'Tipo de contrato',
    tipoAgendamento: 'Tipo de horario',
    retornoExames: 'Devolución de exámenes',
    cabecalho: 'Encabezamiento',
    titulos: 'Títulos',
    vendas: 'Ventas',
    registradoEm: 'Registrado en',
    cargaHorariaTotal: 'Carga de trabajo total',
    adicionarDesconto: 'Agregar descuento',
    utilizarCondicaoPagamentoItem: 'Usar término de pago del artículo',
    dadosDoContrato: 'Datos del contrato',
    referencia: 'Referencia',
    dadosDaParcela: 'Datos de la parcela',
    nossoNumero: 'Nuestro número',
    valorDoDocumento: 'Valor del documento',
    valorLiquido: 'Valor neto',
    valorBruto: 'Valor bruto',
    dadosDoPagamento: 'Detalles del pago',
    dataInicial: 'La fecha de inicio',
    dataFinal: 'Fecha final',
    horaInicial: 'hora de finalización',
    horaFinal: 'hora de finalización',
    considerarDiaLetivo: 'Considéralo como un día escolar',
    mostrarCalendarioImpresso: 'Mostrar en calendario impreso',
    copiarCalendario: 'Copiar calendario',
    criarCopia: 'Crear copia',
    desejaRealmenteCriarCopiaRegistro: '¿Realmente desea crear una copia de este registro?',
    disponiveisAno: 'Disponible en el año',
    letivosAno: 'Escuelas en el año',
    naoLetivos: 'No docente',
    feriados: 'Vacaciones',
    dilatacao: 'Dilatación',
    imprimirReceita: 'Imprimir receta',
    apenasComTitulos: 'Solo títulos',
    totalCondicao: 'Condición total',
    verDetalhes: 'Ver detalles',
    simNao: 'Sí o no',
    inativarLink: 'Inactivar enlace',
    manter: 'Mantener',
    voltarDepois: 'Vuelve mas tarde',
    debugs: 'Debugs',
    falhas: 'Fracasos',
    relacionamento: 'Relación',
    hooks: 'Hooks',
    mensalidade: 'Mensualidad',
    usuarios: 'Usuarios',
    smsEnviados: 'SMS enviado',
    emailsEnviados: 'Correos electrónicos enviados',
    boletosEmitidos: 'Facturas emitidas',
    bemVindo: 'Bienvenido',
    por: 'por',
    totalDoCaixa: 'Total en efectivo',
    abertos: 'Abierto',
    verContrato: 'Ver contrato',
    filtrosAvancados: 'Filtros avanzados',
    limparFiltros: 'Filtros limpios',
    maisFiltros: 'Más filtros',
    dataCadastroInicial: 'Fecha de registro inicial',
    dataCadastroFinal: 'Fecha final de registro',
    dataEdicaoInicial: 'Fecha de edición inicial',
    dataEdicaoFinal: 'Fecha de edición final',
    codigoCliente: 'Código de cliente',
    criado: 'Creado',
    editado: 'Editado',
    ativas: 'Activo',
    inativas: 'Inactivo',
    interno: 'Interno',
    externo: 'Externo',
    todos: 'Todos',
    totalGeral: 'Total general',
    totalExterno: 'Total de externos',
    totalInterno: 'Pasantes totales',
    totalAtivas: 'Los activos totales',
    totalInativas: 'Total inactivo',
    confirmar: 'Confirmar',
    fechados: 'Cerrado',
    consolidar: 'Consolidar',
    desconsolidar: 'Desconsolidar',
    saldo: 'Equilibrio',
    restamXDias: 'Quedan {{dias}} días!',
    ultimoDia: 'Último día!',
    totalFormaPagamento: 'Forma de pago total',
    saldoFormaPagamento: 'Saldo del método de pago',
    operacaoMovimento: 'Operación de movimiento',
    diferenca: 'Diferencia',
    valorDeAbertura: 'Valor de apertura',
    usuarioHabilitado: 'Usuario habilitado',
    valorDeFechamento: 'Valor de cierre',
    valorConsolidado: 'Valor consolidado',
    valorDaDiferenca: 'Cantidad de diferencia',
    situacaoDoCaixa: 'Estado de caja',
    escrevaUmaJustificativa: 'Escribe una justificación',
    excluirBaixa: 'Eliminar cancelación',
    dataDeFechamento: 'Fecha de cierre',
    baixar: 'Descargar',
    filtrar: 'Filtrar',
    comparar: 'Comparar',
    compararImagens: 'Comparar imágenes',
    toolbar: 'Barra de herramientas',
    tipoDeArquivo: 'Tipo de archivo',
    modulos: 'Módulos',
    usuario: 'Usuario',
    selecionarSecao: 'Seleccione una sección de otro modelo para importar',
    titularConta: 'Titular de la cuenta',
    contaCorrente: 'Cuenta corriente',
    contaPoupanca: 'Cuenta de ahorros',
    banco: 'Banco',
    titular: 'Poseedor',
    site: 'Sitio',
    diaDaTransferencia: 'Día de transferencia',
    margens: 'Márgenes',
    previsualizacao: 'Avance',
    alinhamento: 'Alineación',
    adicionarLinhaAcima: 'Agregar línea arriba',
    removerLinha: 'Eliminar línea',
    removerColuna: 'Eliminar columna',
    adicionarLinhaAbaixo: 'Agregar línea a continuación',
    adicionarColunaAEsquerda: 'Agregar columna izquierda',
    adicionarColunaADireita: 'Agregar columna a la derecha',
    adicionarTag: 'Añadir etiqueta',
    esquerda: 'Izquierda',
    direita: 'Derecha',
    visualizarPedido: 'Ver pedido',
    composicoes: 'Composiciones',
    adicionarComposicao: 'Agregar composición',
    quantidadeMinimaItens: 'Cantidad mínima de artículos',
    quantidadeMaximaItens: 'Número máximo de elementos',
    dataEntradaInicial: 'Fecha de entrada inicial',
    dataEntradaFinal: 'Fecha de entrada final',
    totalComposicao: 'Composiciones totales',
    outrosFiltros: 'Otros filtros',
    imprimeNaGuia: 'Imprime en la pestaña',
    terminologiaDeCodigoDaDespesa: 'Terminología del código de gastos',
    utilizarFormulas: 'Usar fórmulas',
    numeroCaixa: 'Número de cajero',
    dataLancamentoInicial: 'Fecha de lanzamiento inicial',
    dataLancamentoFinal: 'Fecha de lanzamiento final ',
    verMais: 'Ver más',
    dataPagamentoInicial: 'Fecha de pago inicial',
    dataPagamentoFinal: 'Fecha de pago final',
    opcaoPagamento: 'Opcion de pago',
    verMenos: 'Ver menos',
    novoPrestadorEmissor: 'Nuevo proveedor o emisor',
    certificado: 'Certificado',
    issRetido: 'ISS Retenido',
    usaNfse: 'Usar NFS-e',
    numeroSerieNfse: 'N° série NFS-e',
    responsavelIss: 'Responsable ISS',
    indicadorLocalServico: 'Indicador de ubicación del servicio',
    regimeEspecialTributacao: 'Régimen fiscal especial',
    naturezaOperacao: 'Naturaleza de la operación',
    operacaoTributacao: 'Operación tributaria (DSFNET)',
    numeroUltimoRps: 'N° de la última RPS',
    codigoUnidadeMedida: 'Código de unidad de medida',
    ocultarPisCofinsExibirApenasObservacoesNota: 'Ocultar PIS y COFINS y mostrar solo en comentarios de notas',
    formatarAliquotaIss: 'Formato de tarifa ISS (Tag ValAliqISS)',
    br: 'BR',
    validarCpfCnpj: 'Validar CPF / CNPJ',
    validarDadosCadastrais: 'Validar datos de registro',
    sempreValorParcela: 'Siempre el valor de la cuota',
    valorParcelaPendenteValorPagoQuitado: 'Monto de la cuota si está pendiente y monto pagado si se paga',
    deduzirJuros: 'Deducir intereses',
    jurosComposto: 'Interés Compuesto',
    jurosSimples: 'Interés Simple',
    discriminarDesconto: 'Descuentos discriminados',
    condicionado: 'Acondicionamiento',
    incondicionado: 'Incondicional',
    paisTomador: 'país del prestatario',
    pagarme: 'Págame',
    taxasAtualizadasSucesso: '¡Tarifas actualizadas con éxito!',
    atualizarTaxas: 'Tasas de actualización',
    ativeIntegracaoAcessarAba: 'Active la integración para acceder a esta pestaña',
    nenhumaTarifaEncontrada: 'No se encontraron tarifas :(',
    tenteAtualizarTarifasAbaixo: 'Intente actualizar las tarifas a continuación',
    arquivos: 'Archivos',
    agendar: 'Para programar',
    ted: 'TED',
    movimentar: 'moverse',
    tomador: 'Tomador',
    situacaoNfsE: 'Situacíon de NFS-E',
    numeroDaNfsE: 'Nº da NFS-e',
    numeroDoRps: 'Nº do RPS',
    selecioneTipoData: 'Seleccione un tipo de fecha',
    selecioneTipoRecebimento: 'Seleccione un tipo de recibo',
    selecioneUmaSituacao: 'Seleccione una situación',
    selecioneUmaOperacao: 'Seleccione una operación',
    selecioneUmResponsavel: 'Seleccione un responsable',
    situacaoDataHora: 'Situación / Fecha y hora',
    selecioneUmTomador: 'Seleccione un prestatario',
    valorPagamento: 'Monto del pago',
    codigoDeBarras: 'Código de barras',
    boletoInavalido: 'Palanquilla no válida',
    vigenciaContrato: 'Vigencia del contrato',
    dataContratacaoInicial: 'Fecha de contrato inicial',
    dataContratacaoFinal: 'Fecha del contrato final',
    padraoNfseChave: 'Clave NFS-e predeterminada',
    chaveAcesso: 'Clave de acceso',
    gerarNf: 'Generar NF',
    emissaoNotaFiscal: 'Emisión de factura',
    prestadorEmissor: 'Proveedor / Emisor',
    movimentacaoProfissionaisNoSistema: 'Movimiento de profesionales en el sistema',
    movimentacaoProfissionaisNoRetaguarda: 'Movimiento de profesionales en la retaguarda',
    aguardando_envio: 'Esperando envío',
    aguardando_contratantes: 'Esperando contratistas',
    aguardando_testemunhas: 'Esperando testigos',
    aguardando_contratados: 'Esperando contratados',
    assinaturaPendente: 'Suscripción pendiente',
    editarDocumento: 'Editar documento',
    solicitarAssinatura: 'Solicitar suscripción',
    contratoMatricula: 'Contrato / Registro',
    intervaloNotificacaoDias: 'Intervalo de notificación en días',
    rsaKey: 'Rsa key',
    chaveIntegracaoExterna: 'Clave de integración externa',
    detalhesContratado: 'Detalles del contratista',
    detalhesTestemunha: 'Detalles del testigo',
    tiposAcesso: 'Tipos de acceso',
    ola: 'Hola',
    tipoAutenticacaoAssinatura: 'Tipo de autenticación para firma',
    confirmarDados: 'Confirmar datos',
    quadroHorarios: 'Hoja de tiempo',
    triggerParaPalavrasChaves: 'Usar {{trigger}} para palabras clave',
    selecioneUmaAvaliacao: 'Selecciona una reseña',
    selecioneUmGrupoAvaliacao: 'Seleccione un grupo/evaluación',
    selecionePeriodoExame: 'Seleccionar periodo/examen',
    avaliacoesExcluidasSucesso: 'Reseñas eliminadas con éxito',
    excluirAvaliacoesConfirmacao:
      'Todas las evaluaciones registradas durante este período serán eliminadas. ¿De verdad quieres continuar?',
    excluirAvaliacoes: 'Eliminar reseñas',
    pesquisePorNome: 'Buscar por Nombre',
    notas0A: 'Calificaciones de 0 a',
    faltas0A: 'Faltas de 0 a',
    notaAlterada: 'Esta nota se ha cambiado manualmente. <br> Día {{data}} a {{horario}} por Usuario {{nome}}.',
    notaRemovida: 'Esta nota ha sido eliminada. <br> Día {{data}} a {{horario}} por Usuario {{nome}}.',
    situacaoLancadaSucesso: 'Estado lanzado con éxito',
    cpfResponsavel: 'CPF del responsable',
    telefoneResidencial: 'Teléfono residencial',
    matriculaInterno: 'Inscripción interna',
    ra: 'RA',
    enderecoNumero: 'Número de calle.',
    rsaPrivateKeyCadastrada: 'Clave rsa registrada con éxito',
    rsaPrivateKeyAtualizada: 'Clave rsa actualizada con éxito',
    urlPostback: 'Url postback',
    parcial: 'Parcial',
    final: 'Final',
    nomeGrupo: 'Nombre del grupo',
    detalhesAvaliacao: 'Detalles de evaluación',
    pesoAvaliacao: 'Peso de evaluación',
    recParcial: 'Recuperación parcial',
    replicarPeriodos: 'Replicar para todos los períodos',
    selecionarGrupo: 'Selecciona grupo',
    replicarConfiguracoesParaTodosPeriodos: 'Replicar la configuración para todos los períodos',
    nAvaliacoes: 'Nº de reseñas',
    nGrupos: 'Nº de grupos',
    grupoRelacionado: 'Grupo relacionado',
    selecioneOPeriodo: 'Seleccione el período!',
    segChamada: '2da llamada',
    formas: 'Formas',
    formasDePagamento: 'Formas de pago',
    definirComoPadrao: 'Establecer como predeterminado',
    primeiraParcelaFixa: 'Primera cuota fija',
    responsavelFinanceiro: 'Responsable financiero',
    jurosMultas: 'Intereses y multas',
    verItens: 'Ver elementos',
    quitar: 'Saldar',
    popUpsBloqueados: '¡Ups! ¡Ventanas emergentes bloqueadas!',
    aPartirContasReceber: 'De cuentas por cobrar',
    aPartirOrcamentos: 'De presupuestos',
    financeiroPadrao: 'Financiero predeterminado',
    responsavelFinanceiroPadrao: 'Responsable financiero predeterminado',
    alterarResponsavelFinanceiro: 'Cambiar responsable financiero',
    jaExisteResponsavelFinanceiroPadrao:
      'Ya tiene un oficial de finanzas por defecto. ¿Realmente quieres continuar con el cambio?',
    comDesconto: 'Con descuento',
    semDesconto: 'Sin descuento',
    descontosAcrescimos: 'Descuentos y adiciones',
    renegociar: 'Renegociar',
    renegociacao: 'Renegociación',
    valorCalculado: 'Valor calculado',
    'R$ou%': 'R$ o %',
    avulso: 'Único',
    itensServiços: 'Artículos y servicios',
    alterarPrecoPadraoParaRemover:
      'Debe cambiar el precio predeterminado antes de que se pueda eliminar el precio seleccionado.',
    removerAnexo: 'Eliminar archivo adjunto',
    confirmacaoRemoverAnexo: '¿Quieres eliminar el archivo adjunto?',
    acompanharStatus: 'Rastrear estado',
    zoop: 'Zoop',
    jaPossuemBoletosGerados: 'Una o más cuotas ya tienen comprobantes generados',
    reenviarBoleto: 'Reenviar boleta',
    cancelarBoleto: 'Cancelar boleta',
    desejaContinuar: '¿Desea continuar?',
    desejaRealmenteCancelar: '¿De verdad quieres cancelar?',
    registroCanceladoSucesso: '¡Registro cancelado con éxito!',

    meses: {
      janeiro: 'Enero',
      janeiro_abrev: 'ENE',
      fevereiro: 'Febrero',
      fevereiro_abrev: 'FEB',
      marco: 'Marzo',
      marco_abrev: 'MAR',
      abril: 'Abril',
      abril_abrev: 'ABR',
      maio: 'Mayo',
      maio_abrev: 'MAYO',
      junho: 'Junio',
      junho_abrev: 'JUN',
      julho: 'JulIo',
      julho_abrev: 'JUL',
      agosto: 'Agosto',
      agosto_abrev: 'AGO',
      setembro: 'Septiembre',
      setembro_abrev: 'SEPT',
      outubro: 'Octubre',
      outubro_abrev: 'OUT',
      novembro: 'Noviembre',
      novembro_abrev: 'NOV',
      dezembro: 'Diciembre',
      dezembro_abrev: 'DEC'
    },

    weekDays: {
      domingo: 'Domingo',
      segundaFeira: 'Lunes',
      tercaFeira: 'Martes',
      quartaFeira: 'Miércoles',
      quintaFeira: 'Jueves',
      sextaFeira: 'Viernes',
      sabado: 'Sábado',
      domingoAbr: 'Dom',
      segundaFeiraAbr: 'Lun',
      tercaFeiraAbr: 'Mar',
      quartaFeiraAbr: 'Mie',
      quintaFeiraAbr: 'Jue',
      sextaFeiraAbr: 'Vie',
      sabadoAbr: 'Sab'
    },

    dateTime: {
      segundo: 'segundo',
      segundo_plural: 'segundos',
      minuto: 'minuto',
      minuto_plural: 'minutos',
      hora: 'hora',
      hora_plural: 'horas',
      dia: 'día',
      dia_plural: 'dias',
      mes: 'mes',
      mes_plural: 'meses',
      ano: 'año',
      ano_plural: 'años'
    },

    feedbacks: {
      desejaContinuar: 'Los cambios realizados no se guardarán, quiero continuar?',
      removerPermanentemente: 'Eliminar este registro de forma permanente',
      algoInesperado: 'sucedió algo inesperado',
      emailAdicionadoSucesso: 'Correo electrónico agregado correctamente!',
      emailRemovidoSucesso: 'Correo electrónico eliminado correctamente!',
      enderecoAdicionadoSucesso: 'Dirección agregada correctamente!',
      enderecoAtualizadoSucesso: 'Dirección actualizada correctamente!',
      enderecoRemovidoSucesso: 'Dirección eliminada correctamente!',
      emailNaoExiste: 'El correo electrónico informado no existe!',
      emailRecuperacaoEnviadoSucesso: 'Correo electrónico de recuperación enviado correctamente!',
      senhaAlteradaSucesso: 'Contraseña alterada con éxito!',
      redeSocialAdicionadaSucesso: 'Red social agregada con éxito!',
      redeSocialRemovidaSucesso: 'Red social eliminada con éxito!',
      telefoneAdicionadoSucesso: 'Teléfono agregado correctamente!',
      telefoneRemovidoSucesso: 'Teléfono eliminado correctamente!',
      documentoAtualizadoSucesso: 'Documentos actualizados con éxito!',
      dependenteAdicionadoSucesso: 'Dependiente actualizado con éxito!',
      dependenteRemovidoSucesso: 'Dependiente eliminado con éxito!',
      responsavelAdicionadoSucesso: 'Responsable agregado exitosamente!',
      responsavelAtualizadoSucesso: 'Responsable actualizado con éxito!',
      responsavelRemovidoSucesso: 'Responsable eliminado con éxito!',
      dependenteAtualizadoSucesso: 'Dependiente actualizado con éxito!',
      itemIncluidoSucesso: 'Elemento agregado exitosamente!',
      itemAdicionadoSucesso: 'Elemento agregado exitosamente!',
      itemAtualizadoSucesso: 'Elemento actualizado correctamente!',
      itemRemovidoSucesso: 'Elemento eliminado con éxito!',
      itemJaCadastrado: 'Artículo ya registrado!',
      cadastroSucesso: 'Registrado exitosamente',
      nenhumRegistro: 'No se encontraron registros',
      primeiroCadastro: 'Realice su primer registro haciendo clic en',
      avatarAdicionadoSucesso: 'Avatar agregado con éxito!',
      avatarRemovidoSucesso: 'Avatar eliminado correctamente!',
      logotipoAdicionadoSucesso: 'Logotipo agregado correctamente!',
      logotipoRemovidoSucesso: 'Logotipo eliminado correctamente!',
      brasaoEstadoAdicionadoSucesso: 'Escudo de armas del estado agregado con éxito!',
      brasaoEstadoRemovidoSucesso: 'Escudo de armas del estado eliminado con éxito!',
      nenhumKanban: 'No hay kanban para mostrar',
      primeiraConfiguracaoWorkflow: 'Configure un embudo y comience a administrar sus oportunidades',
      checklistAtualizadoSucesso: 'Lista de verificación actualizada correctamente!',
      checklistCadastradoSucesso: 'Artículo registrado con éxito en la lista de verificación!',
      checklistRemovidoSucesso: 'Elemento eliminado de la lista de verificación correctamente!',
      adicionadoSucesso: 'Agregado exitosamente!',
      cadastradoSucesso: 'Registrado correctamente!',
      atualizadoSucesso: 'Actualizado exitosamente!',
      removidoSucesso: 'Eliminado con éxito!',
      adicionadaSucesso: 'Agregado exitosamente!',
      cadastradaSucesso: 'Registrado exitosamente!',
      atualizadaSucesso: 'Actualizado con éxito!',
      removidaSucesso: 'Eliminado con éxito!',
      tarefaAdicionadaSucesso: 'Tarea agregada con éxito!',
      tarefaAtualizadaSucesso: 'Tarea actualizada correctamente!',
      tarefaRemovidaSucesso: 'Tarea eliminada con éxito!',
      situacaoCadastradaSucesso: 'Situación registrada con éxito',
      situacaoAtualizadaSucesso: 'Estado actualizado con éxito!',
      situacaoRemovidaSucesso: 'Situación eliminada con éxito!',
      respostaEnviada: 'Tu respuesta ha sido enviada',
      obrigadoContribuirConosco: 'Gracias por contribuir con nosotros',
      alteracoesSalvasSucesso: 'Los cambios se guardaron exitosamente',
      itemSemprePreco: 'Artículo sin precio',
      itemSemPrecoObrigatorio: '¡No se puede guardar un artículo sin precio!',
      itemPrecoSelecioneUmItem: 'Seleccione un artículo para cargar sus precios',
      contaNaoMovimentada: 'Esta cuenta aún no ha sido operada.!',
      semEstornosNoPeriodo: 'No hubo devoluciones de cargo durante este período',
      semContratosVigentesUltimosXMeses: 'No hay contratos vigentes en los últimos {{meses}} meses',
      smsRequisicaoEnvioCadastrada: 'Envío de SMS solicitado con éxito',
      emailRequisicaoEnvioCadastrada: 'Envío de correo electrónico solicitado con éxito',
      usuarioAdicionadoSucesso: 'Usuario agregado exitosamente!',
      baixaLancadaSucesso: 'Cancelación lanzada con éxito!',
      baixaListaLancadaSucesso: 'Cancelación de lotes publicada con éxito!',
      baixaRemovidaSucesso: 'Cancelación eliminada con éxito!',
      cartaoLancadoSucesso: 'Lanzamiento exitoso de la tarjeta!',
      equipamentoAdicionadoSucesso: 'Equipo agregado exitosamente!',
      professorAdicionadoSucesso: 'Maestro agregado exitosamente!',
      salaAdicionadaSucesso: 'Habitación agregada con éxito!',
      funcionarioAdicionadoSucesso: 'Empleado agregado exitosamente!',
      salaRemovidaSucesso: 'habitación eliminada con éxito!',
      equipamentoRemovidoSucesso: 'Equipo eliminado con éxito!',
      professorRemovidoSucesso: 'Maestro eliminado con éxito!',
      funcionarioRemovidoSucesso: 'Empleado eliminado correctamente!',
      procedimentoAdicionadoSucesso: 'Procedimiento agregado exitosamente!',
      procedimentoRemovidoSucesso: 'Procedimiento eliminado con éxito!',
      dataInicialNaoPodeSerMaiorQueDataFinal: 'La fecha de inicio no puede ser mayor que la fecha de finalización',
      agendamentoRemovidoSucesso: 'Programa eliminado correctamente!',
      nenhumArquivoAnexado: 'No hay archivos adjuntos',
      nenhumArquivoAnexadoAoAtendimento: 'No se adjuntaron archivos a este servicio!',
      precisaFecharCaixaAntesAbrirOutro: 'Debe cerrar el cajero antes de abrir otro..',
      caixaAbertoSucesso: '¡Abrir caja con éxito!',
      conteReceberEstornadaSucesso: 'Cuenta por cobrar revertida con éxito',
      periodoAdicionadoSucesso: 'Período agregado con éxito!',
      periodoRemovidoSucesso: 'Período eliminado con éxito!',
      ocorrenciaNaoAutorizadaErro: 'Ocurrencia no autorizada',
      enviarMensagemErro: 'Se produjo un error al enviar el mensaje!',
      baixarMensagemErro: 'Hubo un error al descargar el mensaje.!',
      apenasUmAnexoPorMensagem: 'Elija solo un archivo adjunto para enviar el archivo adjunto al mensaje!',
      semAcrescimoOuDesconto: 'Sin adición ni descuento',
      horarioFimObrigatorioQuandoTemHorarioInicio: 'Hora de finalización obligatoria cuando hay una hora de inicio',
      horarioInicialObrigarioQuandoTemHorarioFim: 'Hora de inicio obligatoria cuando hora de finalización',
      nenhumaNotificacao: 'No se encontraron notificaciones',
      semNotificacoes: 'Aún no tienes notificaciones.',
      nenhumGrupoDeCampoPersonalizadoVinculado: 'No se registraron grupos de campos personalizados',
      cadastreCampoPersonalizado: 'Registre al menos un grupo de campos personalizados',
      falhaAbrirAtendimentoOnline: 'No se pudo abrir el servicio en línea. Inténtalo de nuevo!',
      maximoArquivosUpload: 'Máximo 10 archivos por carga!',
      maximoUmArquivoUpload: '¡Solo puedes adjuntar un archivo!',
      anexadoComSucesso: 'Archivo adjunto correctamente',
      anexoRemovidoSucesso: 'Adjunto eliminado con éxito',
      justificativaSalvaSucesso: 'Justificación guardada con éxito!',
      justificativaRemovidaSucesso: 'Justificativa eliminado con éxito!',
      recursoRemovidoSucesso: 'Recurso eliminado correctamente',
      boletoCanceladoSucesso: 'Boleto cancelado exitosamente',
      nenhumaSecaoDisponivelParaAtendimento: 'No hay sección disponible para este servicio',
      nenhumAnexoEncontrado: 'No se encontraron archivos adjuntos!',
      nenhumResultadoEncontrado: 'Ningún resultado encontrado!',
      nenhumResultadoComAFraseEncontrado: 'No hay resultados con la frase "{{frase}}" fue encontrado!',
      nenhumFuncionarioCadastrado: 'Sin empleados registrados',
      nenhumFuncionarioEncontrado: 'No se encontraron empleados',
      nenhumUsuarioAssociado: 'No hay usuarios asociados',
      nenhumAtendimentoClientePossuiDesfechoPublico:
        'Ninguno de los servicios de este cliente tiene un resultado público!',
      tituloJaQuitado: 'Título ya pagado.',
      voceNaoTemAcessoEsseRecurso: 'No tiene acceso a esta función.!',
      voceDeseja: 'Quieres?',
      voceNaoTemPermissaoParaRealizarEssaAcao: 'No se le permite realizar esta acción.!',
      trocaPCondicaoItem:
        'Al confirmar, utilizará las condiciones de pago del artículo y los descuentos por condición..',
      trocaPCondicaoPedido:
        'Al confirmar, utilizará el plazo de pago del pedido y se aplicarán descuentos al artículo..',
      contaBancariaNaoEncontrada: 'cuenta bancaria no encontrada!',
      erroAoRealizarUploadImagensTenteNovamente: 'Hubo un error al cargar las imágenes, inténtalo de nuevo.!',
      trocaStatusHabilitado: 'Al habilitar todas las funciones del módulo, habilita el módulo para empresas:',
      trocaStatusDesabilitado:
        'Al deshabilitar todas las funciones del módulo, está deshabilitando el módulo para empresas:',
      trocaStatusFeatureHabilitado: 'Al habilitar la función, habilita la función para empresas:',
      trocaStatusFeatureDesabilitado: 'Al deshabilitar la función, está deshabilitando la función para empresas:',
      transacaoAutorizada: 'Transacción autorizada!',
      transacaoNaoAutorizada: 'Transacción no autorizada!',
      porFavorAguarde: 'Espere por favor...',
      casoNaoExistirInformarX: 'Si no, informar {{string}}.',
      vocePossuiCaixaEmAberto: '¿Tiene efectivo abierto de días anteriores?',
      voceNaoPossuiCaixaEmAberto: 'No tienes efectivo abierto',
      valorVerificacaoIncorreto: 'Valor incorrecto en la verificación.',
      contaExistenteInativada: 'Hay un usuario inactivo con este correo electrónico.',
      contaExistenteInativadaMensagem:
        'El usuario {{usuario}} ya tienes este email registrado en tu acceso y está inactivo. Reactivar usuario para acceder.',
      emailJaCadastrado: 'Este correo electrónico ya está registrado',
      agendamentoNaoPodeSerConcluidoPacienteInativo:
        'La programación no se puede completar porque el paciente no se ha reactivado, seleccione otro paciente.',
      atendimentoNaoPodeSerIniciadoPacienteInativo:
        'No se puede iniciar la atención ya que el paciente no ha sido reactivado, seleccione otro paciente.',
      verifiqueItens:
        'Verifica si hay alumnos registrados en el diario de clase o si los alumnos matriculados están dentro del período actual del diario de clase.',
      valorParcelaObrigatorio: 'Se debe informar el valor de la cuota',
      valorMaiorQue: 'El valor debe ser mayor que {{value}}'
    },

    cores: {
      neutral: 'Neutral',
      white: 'Blanco',
      lightGrey: 'Gris claro',
      mediumGrey: 'Gris',
      darkGrey: 'Gris oscuro',
      almostBlack: 'Casi negro',
      black: 'Negro',
      primary: 'Primario',
      primaryLight: 'Primaria por supuesto',
      primaryDark: 'Oscuridad primaria',
      secondary: 'Secundario',
      secondaryLight: 'Secundaria por supuesto',
      secondaryDark: 'Oscuridad secundaria',
      info: 'Informática',
      infoLight: 'Informática por supuesto',
      infoDark: 'Informática oscura',
      success: 'Éxito',
      successLight: 'Claro éxito',
      successDark: 'éxito oscuro',
      warning: 'Alerta',
      warningLight: 'Alerta clara',
      warningDark: 'Alerta oscura',
      error: 'Error',
      errorLight: 'Borrar error',
      errorDark: 'Error oscuro',
      red: 'Rojo',
      redLight: 'Rojo claro',
      redDark: 'Rojo oscuro',
      pink: 'Rosado',
      pinkLight: 'Rosa claro',
      pinkDark: 'Rosa escuro',
      purple: 'Púrpura',
      purpleLight: 'Morado claro',
      purpleDark: 'Púrpura oscuro',
      deepPurple: 'Morado oscuro',
      deepPurpleLight: 'Morado oscuro claro',
      deepPurpleDark: 'Púrpura oscuro profundo',
      indigo: 'Índigo',
      indigoLight: 'índigo claro',
      indigoDark: 'índigo oscuro',
      blue: 'Azul',
      blueLight: 'Azul claro',
      blueDark: 'Azul oscuro',
      cyan: 'Cian',
      cyanLight: 'Cian claro',
      cyanDark: 'Cian oscuro',
      teal: 'Verde azulado',
      tealLight: 'Verde azulado claro',
      tealDark: 'Verde azulado oscuro',
      green: 'Verde',
      greenLight: 'Verde claro',
      greenDark: 'Verde oscuro',
      lime: 'Lima',
      limeLight: 'Archivo vacio',
      limeDark: 'lima oscura',
      yellow: 'Amarillo',
      yellowLight: 'Amarillo claro',
      yellowDark: 'Amarillo oscuro',
      orange: 'Laranja',
      orangeLight: 'Laranja claro',
      orangeDark: 'Naranja oscuro',
      deepOrange: 'Naranja intenso',
      deepOrangeLight: 'Naranja intenso claro',
      deepOrangeDark: 'Naranja oscuro profundo',
      brown: 'Marrón',
      brownLight: 'Marron claro',
      brownDark: 'Marrón oscuro'
    },

    validacoes: {
      cpf: 'CPF no válido',
      cnpj: 'CNPJ no válido',
      email: 'Email inválido',
      numero: 'El campo debe ser numérico',
      separadorDecimal: 'Los separadores decimales deben ser puntos',
      dezCaracteres: 'Límite máximo de 10 caracteres',
      numeroMinimo: 'Debe ser mayor o igual que {{min}}',
      numeroMaximo: 'Debe ser menor o igual que{{max}}',
      numeroCartao: 'El número de tarjeta no es válido',
      valorMinimo: 'Debe ser mayor o igual que $t(geral:currency) {{value, currency|BRL}}',
      valorMaximo: 'Debe ser menor o igual que $t(geral:currency) {{value, currency|BRL}}',
      caracteresMinimos: 'Debe tener al menos {{value}} caracteres',
      caracteresMaximos: 'Debe tener el máximo {{value}} caracteres',
      caracteresFixos: 'Debe tener {{value}} caracteres',
      quantidadeMininaNumeros: 'Debe tener ${min} o más números',
      obrigatorio: 'Campo obligatorio',
      senhaCurta: 'Contraseña demasiado corta',
      senhaLonga: 'Contraseña demasiado larga',
      senhasDiferentes: 'Diferentes contraseñas',
      sim: 'Sí',
      nao: 'No',
      anoInvalido: 'Año no válido',
      dataInvalida: 'Fecha invalida',
      dataMaximaHoje: 'No puede ser mas grande que hoy',
      dataMinimaHoje: 'No puede ser más pequeño que hoy ',
      dataDeveSerMaiorQue: 'La fecha debe ser mayor o igual que {{date, date|DD/MM/YYYY}}',
      dataDeveSerMenorQue: 'La fecha debe ser menor o igual a {{date, date|DD/MM/YYYY}}',
      dataInicialObrigatoria: 'Fecha de inicio obligatoria',
      dataJaCadastrada: 'Fecha ya registrada',
      valueDeveSerMaiorQueEstoqueMinimo: 'El valor debe ser mayor o igual que existencias minimas',
      valueDeveSerMenorQueEstoqueMaximo: 'El valor debe ser menor o igual que existencias maximas',
      urlIncorreta: 'URL incorrecta',
      digito: 'Tipo inválido',
      senhaDeveConter: 'La contraseña debe contener',
      minimoCaracteres: 'Mínimo 8 caracteres',
      conterLetras: 'Al menos una letra minúscula',
      conterLetraMaiuscula: 'Al menos una mayúscula',
      conterNumeros: 'Números',
      conterCaractereEspecial: 'Caracteres especiales',
      valorMaiorQue: 'Debe ser mayor que {{value}}',
      valorMenorQue: 'Debe ser menor que {{value}}',
      senhaFraca: 'La contraseña no cumple con los criterios',
      cargaHorarioSemanalMaiorCargaHorarioTotal: 'Las horas de crédito semanales deben ser inferiores al total',
      horaInvalida: 'Formato de hora no válido',
      minutosInvalidos: 'Minutos en formato no válido',
      documentoSemConteudo: 'Plantilla de documento sin contenido',
      horaDeveSerMaiorQue: 'El tiempo debe ser mayor o igual a {{hour, hour|HH:mm}}',
      registroEspecialidadeObrigatorio: 'Registro de posición obligatoria'
    },
    navegacao: {
      concluir: 'Concluir',
      proximo: 'Próximo',
      anterior: 'Anterior',
      inicio: 'Comienzo',
      dashboard: 'Dashboard',
      cadastro: 'Registro',
      clientes: 'Clientes',
      fornecedores: 'Proveedores',
      funcionarios: 'Empleados',
      'preferencias-do-cadastro': 'Preferencias de registro',
      catalogo: 'Catalogar',
      produtos: 'Productos',
      servicos: 'Servicios',
      categoria: 'Categoría',
      categorias: 'Categorías',
      estoque: 'Inventario',
      precificacoes: 'Precios',
      'tipos-de-produto': 'Tipos de producto',
      'classificacao-de-produtos': 'Clasificación del producto',
      'preferencias-do-catalogo': 'Preferencias de catálogo',
      agenda: 'Calendario',
      agendamento: 'Planificación',
      'preferencias-da-agenda': 'Preferencias de calendario',
      calendario: 'Calendario',
      calendarios: 'Calendarios',
      'lista-de-espera': 'Lista de espera',
      'liberacao-de-modulos': 'Lanzamiento del módulo',
      financeiro: 'Financiero',
      'financeiro-novo': 'Financiero (Nuevo)',
      'bancos-e-contas': 'Bancos y cuentas',
      'caixa-usuario': 'Caja de usuario',
      'formas-de-pagamento': 'Formas de pago',
      'preferencias-do-financeiro': 'Preferencias financieras',
      'planos-financeiros': 'Planes financieros',
      fiscal: 'Supervisor',
      'plano-de-contas': 'Plan de cuentas',
      'centro-de-custos': 'Centro de costos',
      'preferencias-do-fiscal': 'Preferencias fiscales',
      relatorios: 'Informes',
      finalidades: 'Propósitos',
      preferencias: 'Preferencias',
      'locais-de-realizacao': 'Lugares de actuación',
      'servicos-e-procedimentos': 'Servicios y trámites',
      analise: 'Análise',
      oportunidades: 'Oportunidades',
      workflows: 'Embudos',
      'pre-tarefas': 'tareas previas',
      contatos: 'Contactos',
      'preferencias-do-comercial': 'Preferencias comerciales',
      'detalhes-personalizados': 'Detalles personalizados',
      'graus-de-interesse': 'Grados de interés',
      'tipos-de-compromisso': 'Tipos de compromiso',
      campanhas: 'Campañas',
      'configuracoes-de-comissoes': 'Configuración de la comisión',
      'condicoes-de-pagamentos': 'Términos de pago',
      'motivos-comerciais': 'Razones comerciales',
      'origens-do-contato': 'Orígenes de contacto',
      tags: 'Etiquetas',
      'status-de-exames': 'Estado del examen',
      'unidades-de-medida': 'Unidades de medida',
      'usos-de-produtos': 'Usos del producto',
      'tipos-de-contato': 'Tipos de contacto realizado',
      tesouraria: 'Tesorería',
      'controle-de-cartoes-de-credito': 'Control de tarjetas de crédito',
      'controle-de-cheques': 'Verificar el control',
      'controle-de-cartoes': 'Control de tarjetas',
      'configuracoes-de-cartoes': 'Configuración de la tarjeta',
      'configuracoes-de-cheques': 'Comprobar configuración',
      'bandeiras-de-cartoes-de-credito': 'Marcas de tarjetas de crédito',
      'credenciadoras-de-cartoes-de-credito': 'Empresas de acreditación de tarjetas de crédito',
      operacoes: 'Operaciones',
      'configuracoes-de-bolsa': 'Configuración de la bolsa',
      contabil: 'Contabilidad',
      'preferencias-do-contabil': 'Preferencias contables',
      tributacoes: 'Impuestos',
      questionarios: 'Cuestionarios',
      'configuracoes-de-convenios': 'Configuración de seguros',
      'configuracoes-de-bandeiras': 'Configuración de banderas',
      'configuracoes-de-credenciadoras': 'Configuraciones de acreedores',
      'configuracoes-de-descontos': 'Configuración de descuento',
      'orcamentos-compra': 'Presupuestos de compra',
      'orcamentos-venda': 'Presupuestos de ventas',
      entradas: 'Aperitivo',
      compras: 'Compras',
      vendas: 'Ventas',
      saidas: 'Salidas',
      'contas-a-pagar': 'Cuentas a pagar',
      'contas-a-receber': 'Cuentas a recibir',
      configuracoes: 'Ajustes',
      empresas: 'Compañías',
      funcoes: 'Roles',
      cargos: 'Posiciones',
      caixa: 'Efectivo diario',
      'caixa-diario': 'Efectivo diario',
      'caixa-geral': 'Caja general',
      comercial: 'Comercial',
      boletos: 'Control de tickets',
      'controle-de-boletos': 'Control de tickets',
      especialidades: 'Especialidades',
      'dashboard-do-financeiro': 'Tablero financiero',
      'necessidades-especiais': 'Necesidades especiales',
      'configuracoes-de-calendarios': 'Configuración del calendario',
      'configuracao-de-calendario': 'Configuración del calendario',
      templates: 'Plantillas',
      sms: 'SMS',
      'dashboard-comercial': 'Tablero',
      salas: 'Habitaciones',
      'configuracoes-de-pagamentos': 'Configuración de pago',
      'salas-ambientes': 'Salas / Ambientes',
      'politicas-de-acesso': 'Políticas de acceso',
      'painel-de-tarefas': 'Panel de tareas',
      'modelos-de-documentos': 'Plantillas de documentos',
      documentos: 'Documentos',
      'grupos-de-documentos': 'Grupos de documentos',
      'tipos-responsavel': 'Tipos de responsables',
      notificacoes: 'Notificaciones',
      email: 'Correo electrónico',
      'grupos-cadastro-personalizado': 'grupos personalizados',
      'motivos-cancelamento': 'Motivos de cancelación de cuota',
      chat: 'Chat',
      banners: 'Pancartas',
      dre: 'DRE',
      faturas: 'Facturas',
      banking: 'Bancario',
      'dashboard-banking': 'Panel de control bancario',
      movimentos: 'Movimentos',
      extratos: 'Extractos',
      'contatos-bancarios': 'Contactos bancarios',
      'templates-comerciais': 'Plantillas de negocios',
      tipos: 'Tipos',
      gerar: 'Generar'
    }
  },

  testeGratis: {
    estaGostandoMedplus: '¿Estás disfrutando de MedPlus?',
    estaGostandoSponte: '¿Estás disfrutando del Ponte?',
    contrateAgora: '¡Regístrate ahora!',
    restam: 'Permanecer',
    xDias: '{{dias}} dias',
    seuPeriodoTeste: 'De su período de prueba.',
    ligamosPraVoce: 'Te llamamos!',
    ouEntreEmContato: 'o contacta con nosotros',
    ultimoDia: 'Es el ultimo dia',
    seuPeriodoTesteAcabou: 'Tu período de prueba ha llegado a su fin :(',
    paraSeguirUsandoPlano:
      'Para seguir usando MedPlus, es necesario contratar un plan. Para esto, o para más información, contáctenos a través de los canales a continuación:'
  },

  baseInativa: {
    titulo: 'Tu base está inactiva',
    paraReativar:
      'Para reactivar el acceso es necesario ponerse en contacto con nuestro equipo comercial a través de los canales a continuación:'
  },

  chat: {
    abrirChat: 'Conversación abierta',
    canais: 'Canales',
    mensagens: 'Mensajes',
    membros: 'Miembros',
    membro: 'Miembro',
    adicionarMembro: 'Añadir miembro',
    adicionarCanal: 'Añadir canales',
    administrador: 'Administrador',
    entrar: 'Entrar',
    sair: 'Salir',
    sairCanal: 'Deja este canal',
    pesquisarConversas: 'Buscar canales / mensajes',
    pesquisarFuncionarios: 'Buscar empleados',
    pesquisarPessoas: 'Pesquisar personas',
    novoCanal: 'Nuevo canal',
    criarCanal: 'Crear canal',
    canalCriado: 'Canal creado con éxito',
    canalExcluido: 'Canal eliminado correctamente',
    editarCanal: 'Editar canal',
    canaisPublicos: 'Canales públicos',
    publicoDoCanal: 'Audiencia del canal',
    preferenciasDoCanal: 'Preferencias de canal',
    escolhaQuemEnviaMensagem: 'Elige quién puede enviar mensajes a este grupo',
    novasMensagens: 'Nuevos mensajes',
    novaMensagem: 'nuevo mensaje',
    entrouCanal: 'Se unió al canal',
    saiuCanal: 'Dejó el canal',
    erroEntrarCanal: 'Error al unirse al canal',
    papelAtualizado: 'Papel actualizado',
    canalExluido: 'Canal eliminado',
    canalAtualizado: 'Canal actualizado',
    mensagemApagada: 'Este mensaje ha sido eliminado.',
    membroAdicionado: 'Miembro agregado exitosamente',
    membroRemovido: 'Miembro eliminado correctamente',
    verMais: 'Ver más',
    limparTudo: 'Limpiar todo',
    naoExcluirMensagem: 'No se pueden eliminar los mensajes de otro usuario',
    erroIniciarConversa: 'Error al iniciar la conversación',
    usuarioDesativado: 'Discapacitado',
    usuarioRemovido: 'Remoto',
    statusLeitura: 'Situación de la lectura',
    lidaPor: 'Leído por {{readers}} de {{total}} usuarios',
    entreguePara: 'Entregado a {{total}} usuarios',
    desejaRemoverConversaCom: '¿Desea eliminar la conversación con ',
    responsavelPor: 'Responsable de',
    canaisInternos: 'Canales internos',
    aplicativo: 'Aplicación',
    desejaSairDoCanal: '¿Quieres salir de este canal?',
    dadosDaMensagem: 'Datos del mensaje',
    conteudoApagado: 'Todo el contenido del chat ha sido eliminado',
    chatApagado: 'Chat eliminado.',
    canalContatosEscolares: 'Canal de contacto de la escuela',
    contatosEscolares: 'Contactos escolares',

    placeholders: {
      envioMensagem: 'Escribe un mensaje...',
      nomeCanal: 'Nombre del Canal',
      canalPrivado: 'Canal privado'
    }
  },
  catalogo: {
    servicos: {
      titulo: 'Servicios',
      novo: 'Nuevo servicio',
      cadastradoSucesso: 'Servicio registrado con éxito!',
      atualizadoSucesso: 'Servicio actualizado con éxito!',
      removidoSucesso: 'Servicio eliminado con éxito!',
      principaisDados: 'Datos del servicio principal',

      preco: {
        titulo: 'Precio',
        novo: 'Nuevo precio',
        cadastradoSucesso: 'Precio registrado correctamente!',
        atualizadoSucesso: 'Precio actualizado correctamente!',
        removidoSucesso: 'Precio eliminado correctamente!',

        profissionais: {
          titulo: 'Profesionales',
          novo: 'Nuevo profesional',
          edit: 'Editar profesional',
          cadastradoSucesso: 'Profesional registrado con éxito!',
          atualizadoSucesso: 'Profesional actualizado con éxito!',
          removidoSucesso: 'Profesional eliminado con éxito!',
          selecioneProfissional: 'Seleccione un profesional para listar',
          funcaoProfissional: 'Rol profesional'
        },
        condicaoDePagamento: {
          totalPlano: 'Plan total',
          subTotal: 'Subtotal de condición',
          adicionar: 'Agregar plazo de pago',
          relacionar: 'Lista de condiciones de pago',
          cadastradoSucesso: 'Condiciones registradas con éxito!',
          saldo: 'Equilibrio de condiciones',
          totalCondicoes: 'Condiciones totales',
          removidoSucesso: 'Condición de pago eliminada correctamente!',
          precoSujerido: 'Precio sugerido del artículo'
        }
      },

      composicao: {
        novo: 'Nueva composicion',
        edit: 'Editar composición',
        cadastradoSucesso: 'Membresía registrada con éxito!',
        atualizadoSucesso: 'Composición actualizada correctamente!',
        removidoSucesso: 'Composición eliminada correctamente!',
        utilizarItensDiscriminados: 'Utilizar artículos discriminados'
      },

      tributacao: {
        novo: 'Nueva tributación',
        edit: 'Editar impuestos',
        cadastradoSucesso: 'Fiscalidad registrada con éxito!',
        atualizadoSucesso: 'Fiscalidad actualizada con éxito!',
        removidoSucesso: 'Impuestos eliminados con éxito!'
      },

      embalagem: {
        novo: 'Novo controle de embalagem',
        edit: 'Editar controle de embalagem',
        cadastradoSucesso: 'Embalagem cadastrada com sucesso!',
        atualizadoSucesso: 'Embalagem atualizada com sucesso!',
        removidoSucesso: 'Embalagem removida com sucesso!'
      }
    },
    produtos: {
      titulo: 'Productos',
      novo: 'Nuevo producto',
      cadastradoSucesso: 'Producto registrado con éxito!',
      atualizadoSucesso: 'Producto actualizado con éxito!',
      removidoSucesso: 'Producto eliminado con éxito!',
      principaisDados: 'Datos del producto principal',

      preco: {
        titulo: 'Precio',
        novo: 'Nuevo precio',
        cadastradoSucesso: 'Precio registrado correctamente!',
        atualizadoSucesso: 'Precio actualizado correctamente!',
        removidoSucesso: 'Precio eliminado correctamente!',

        profissionais: {
          titulo: 'Profesionales',
          novo: 'Nuevo profesional',
          edit: 'Editar profesional',
          cadastradoSucesso: 'Profesional registrado con éxito!',
          atualizadoSucesso: 'Profesional actualizado con éxito!',
          removidoSucesso: 'Profesional eliminado con éxito!',
          selecioneProfissional: 'Seleccione un profesional para enumerar',
          funcaoProfissional: 'Rol profesional'
        }
      },

      composicao: {
        novo: 'Nueva composicion',
        edit: 'Editar composición',
        cadastradoSucesso: 'Membresía registrada con éxito!',
        atualizadoSucesso: 'Composición actualizada correctamente!',
        removidoSucesso: 'Composición eliminada correctamente!'
      },

      tributacao: {
        novo: 'Nueva tributación',
        edit: 'Editar impuestos',
        cadastradoSucesso: 'Fiscalidad registrada con éxito!',
        atualizadoSucesso: 'Fiscalidad actualizada con éxito!',
        removidoSucesso: 'Impuestos eliminados con éxito!'
      },

      embalagem: {
        novo: 'Nuevo control de envases',
        edit: 'Editar control de empaque',
        cadastradoSucesso: 'Paquete registrado exitosamente!',
        atualizadoSucesso: 'Paquete actualizado correctamente!',
        removidoSucesso: 'Paquete eliminado correctamente!'
      }
    },

    procedimentos: {
      titulo: 'Procedimientos',
      novo: 'Nuevo procedimiento',
      cadastradoSucesso: 'Procedimiento registrado con éxito!',
      atualizadoSucesso: 'Procedimiento actualizado con éxito!',
      removidoSucesso: 'Procedimiento eliminado con éxito!',
      principaisDados: 'Datos principales del procedimiento',

      preco: {
        titulo: 'Precio',
        novo: 'Nuevo precio',
        cadastradoSucesso: 'Precio registrado correctamente!',
        atualizadoSucesso: 'Precio actualizado correctamente!',
        removidoSucesso: 'Precio eliminado correctamente!',

        profissionais: {
          titulo: 'Profesionales',
          novo: 'Nuevo profesional',
          edit: 'Editar profesional',
          cadastradoSucesso: 'Profesional registrado con éxito!',
          atualizadoSucesso: 'Profesional actualizado con éxito!',
          removidoSucesso: 'Profesional eliminado con éxito!',
          selecioneProfissional: 'Seleccione un profesional para listar',
          funcaoProfissional: 'Rol profesional'
        }
      },

      composicao: {
        novo: 'Nueva composicion',
        edit: 'Editar composición',
        cadastradoSucesso: 'Membresía registrada con éxito!',
        atualizadoSucesso: 'Composición actualizada correctamente!',
        removidoSucesso: 'Composición eliminada correctamente!'
      },

      orientacao: {
        novo: 'Nueva orientación',
        edit: 'Editar orientación',
        cadastradoSucesso: 'Orientación registrada con éxito!',
        atualizadoSucesso: 'Orientación actualizada con éxito!',
        removidoSucesso: 'Orientación eliminada correctamente!'
      }
    },

    preferencias: {
      categorias: {
        titulo: 'Categorías',
        novo: 'Nueva categoría',
        cadastradoSucesso: 'Categoría registrada con éxito!',
        atualizadoSucesso: 'Categoría actualizada correctamente!',
        removidoSucesso: 'Categoría eliminada correctamente!',
        principaisDados: 'Datos de la categoría principal',
        nomeDaCategoria: 'Nombre de la categoría',
        categoriaPai: 'Categoría principal',
        nomeDaCategoriaPai: 'Nombre de la categoría principal',

        subcategoria: {
          nome: 'Nombre de la subcategoría',
          cadastradoSucesso: 'Subcategoría registrada con éxito!',
          removidoSucesso: 'Subcategoría eliminada correctamente!'
        }
      },

      classificacaoDeProdutos: {
        titulo: 'Clasificación del producto',
        novo: 'Clasificación de nuevos productos',
        cadastradoSucesso: 'Clasificación de producto registrada con éxito!',
        atualizadoSucesso: 'Clasificación de producto actualizada con éxito!',
        removidoSucesso: 'Clasificación de producto eliminada con éxito!'
      },

      finalidades: {
        titulo: 'Propósitos',
        novo: 'Nuevo propósito',
        nome: 'Nombre del propósito',
        principaisDados: 'Datos de la categoría principal',
        cadastradoSucesso: 'Propósito registrado con éxito!',
        atualizadoSucesso: 'Propósito actualizado con éxito!',
        removidoSucesso: 'Propósito eliminado con éxito!'
      },

      locaisDeRealizacao: {
        titulo: 'Lugares de actuación',
        novo: 'Nuevo lugar',
        cadastradoSucesso: 'Lugar de logro registrado con éxito!',
        atualizadoSucesso: 'Ubicación actualizada correctamente!',
        removidoSucesso: 'Lugar de logro eliminado con éxito!'
      },

      precificacoes: {
        titulo: 'Precios',
        novo: 'Nuevos precios',
        cadastradoSucesso: 'Precios registrados con éxito!',
        atualizadoSucesso: 'Precios actualizados correctamente!',
        removidoSucesso: 'Precio eliminado correctamente!',

        custosVariaveis: {
          titulo: 'Costos variables',
          cadastradoSucesso: 'Costo variable agregado con éxito!',
          atualizadoSucesso: 'Coste variable eliminado correctamente!',
          removidoSucesso: 'Coste variable eliminado correctamente!'
        }
      },

      tiposDeProduto: {
        titulo: 'Tipos de producto',
        novo: 'Nuevo tipo de producto',
        cadastradoSucesso: 'Tipo de producto registrado con éxito!',
        atualizadoSucesso: 'Tipo de producto actualizado correctamente!',
        removidoSucesso: 'Tipo de producto eliminado correctamente!'
      },

      unidadesDeMedida: {
        titulo: 'Unidades de medida',
        novo: 'Nueva unidad de medida',
        cadastradoSucesso: 'Unidad de medida registrada con éxito!',
        atualizadoSucesso: 'Unidad de medida actualizada con éxito!',
        removidoSucesso: 'Unidad de medida eliminada con éxito!',
        editar: 'Editar unidad de medida'
      },

      usosDeProdutos: {
        titulo: 'Usos del producto',
        novo: 'Uso de nuevos productos',
        cadastradoSucesso: 'Uso exitoso de producto registrado!',
        atualizadoSucesso: 'Uso del producto actualizado con éxito!',
        removidoSucesso: 'Uso del producto eliminado con éxito!',
        editar: 'Editar el uso del producto'
      },

      statusDeExames: {
        titulo: 'Estado del examen',
        novo: 'Nuevo estado de examen',
        cadastradoSucesso: 'Estado de examen registrado con éxito!',
        atualizadoSucesso: 'Estado del examen actualizado con éxito!',
        removidoSucesso: 'El estado del examen se eliminó correctamente!',
        editar: 'Editar el estado del examen'
      }
    }
  },

  comercial: {
    dashboard: {
      negociosGanhos: 'Negocio ganado',
      orcamentosGerados: 'Presupuestos generados',
      orcamentosEfetivados: 'Presupuestos efectivos',
      oportunidadesCriadas: 'Oportunidades creadas',
      negociosPerdidos: 'Negocio perdido',
      portal: 'Portal',
      nfse: 'NFS-e',
      nfe: 'NF-e',
      tituloFunil: 'Embudo',
      tituloDistribuicao: 'Distribución',
      feedback: 'No se ha introducido ningún registro para el filtro'
    },

    fluxoAutomacao: {
      novo: 'Nuevo flujo',
      titulo: 'Flujos de automatización',
      nomeFluxo: 'Nombre del flujo',
      blocosFluxo: 'Bloques de flujo',
      arrasteSolteBlocosTela: 'Arrastra y suelta bloques en la pantalla.',
      executarDatasEspecificas: 'Ejecutar en fechas específicas',
      cadastradoSucesso: '¡Flujo de automatización registrado con éxito!',
      atualizadoSucesso: '¡Flujo de automatización actualizado con éxito!',
      novaAcao: 'Nueva acción',
      novaDecisao: 'Nuevo bloque de decisión',
      novaCondicao: 'Nueva condición',
      nomeBloco: 'Nombre del bloque',
      selecioneModuloSistema: 'Seleccione un módulo del sistema',
      selecioneSubModulo: 'Seleccionar submódulo',
      opcoesDecisao: 'Opciones de decisión',
      tipoCondicao: 'Tipo de condición',
      quantoTempo: '¿Cuanto tiempo?',
      tipoAcao: 'Tipo de acción',
      quemNotificar: '¿A quién notificar?',
      adicionarEmailPersonalizado: 'Agregar correo electrónico personalizado',
      mensagemEmail: 'Mensaje de correo electrónico'
    },

    oportunidades: {
      titulo: 'Oportunidades',
      novo: 'Nueva oportunidad',
      cadastradoSucesso: 'Oportunidad registrada con éxito!',
      atualizadoSucesso: 'Oportunidad actualizada con éxito!',
      removidoSucesso: 'Oportunidad eliminada con éxito!',

      financeiro: {
        orcamento: {
          novo: 'Nuevo presupuesto'
        }
      }
    },

    painelDeTarefas: {
      titulo: 'Panel de tareas',
      novo: 'Nueva tarea',
      cadastradoSucesso: 'Tarea registrada correctamente!',
      atualizadoSucesso: 'Tarea actualizada correctamente!',
      removidoSucesso: 'Tarea eliminada con éxito!',
      informacoes: 'Información',
      nome: 'Título',
      selecioneTipoTarefa: 'Seleccionar tipo de tarea',
      faseTarefa: 'Fase de la tarea',
      verMais: 'Ver más',
      horaInicio: 'Hora de inicio',
      horaTermino: 'Hora de finalización',
      descricaoPlaceholder: 'Escribe aquí...',
      checklist: {
        titulo: 'lista de elementos',
        novo: 'Nuevo artículo',
        edit: 'Editar artículo',
        adicionar: 'Añadir artículo',
        cadastradoSucesso: 'Artículo registrado con éxito!',
        atualizadoSucesso: 'Elemento actualizado correctamente!',
        removidoSucesso: 'Elemento eliminado con éxito!',
        nome: 'Nombre',
        comentario: 'Comentario',
        concluido: 'Concluido'
      },
      chart: {
        aFazer: 'A hacer',
        fazendo: 'Haciendo',
        atrasado: 'Tarde',
        feito: 'Hecho'
      },
      filtro: {
        titulo: 'Título',
        tipoTarefa: 'Tipo de tarea',
        situacao: 'Situación',
        outro: 'Otros',
        limpar: 'Limpiar'
      }
    },

    questionarios: {
      titulo: 'Cuestionarios',
      novo: 'Nuevo cuestionario',
      cadastradoSucesso: 'Cuestionario registrado con éxito!',
      atualizadoSucesso: 'Cuestionario actualizado con éxito!',
      removidoSucesso: 'Prueba eliminada correctamente!'
    },

    preferencias: {
      preTarefas: {
        titulo: 'Tareas previas',
        novo: 'Nueva tarea previa',
        dados: 'Datos previos a la tarea',
        tipo: 'Tipo de tarea previa',
        adiar: 'Posponer la tarea previa',
        marcarTarefa: 'Marque la tarea previa sin lista de verificación como completada',
        cadastradoSucesso: 'Tarea previa registrada correctamente!',
        atualizadoSucesso: 'Tarea previa actualizada correctamente!',
        removidoSucesso: 'Tarea previa eliminada con éxito!'
      },
      banners: {
        titulo: 'Pancartas',
        novo: 'Nuevo banner',
        cadastradoSucesso: 'Banner registrado correctamente!',
        atualizadoSucesso: 'Banner actualizado correctamente!',
        removidoSucesso: 'banner eliminado con éxito!',
        imagem: 'Imagen actualizada correctamente!',
        contador: 'Tú tienes {{value}} caracteres restantes'
      },
      campanhas: {
        titulo: 'Campañas',
        novo: 'Nueva campaña',
        cadastradoSucesso: '¡Campaña registrada con éxito!',
        atualizadoSucesso: '¡Campaña actualizada con éxito!',
        removidoSucesso: '¡Campaña eliminada con éxito!'
      },

      tiposDeCompromisso: {
        titulo: 'Tipos de compromiso',
        novo: 'Nuevo tipo de compromiso',
        cadastradoSucesso: 'Tipo de cita registrada con éxito!',
        atualizadoSucesso: 'El tipo de cita se actualizó correctamente!',
        removidoSucesso: 'Tipo de cita eliminado correctamente!'
      },

      motivosComerciais: {
        titulo: 'Razones comerciales',
        novo: 'Nuevo motivo empresarial',
        cadastradoSucesso: 'Razón comercial registrada con éxito!',
        atualizadoSucesso: 'Motivo empresarial actualizado con éxito!',
        removidoSucesso: 'Motivo empresarial eliminado con éxito!'
      },

      tiposDeContato: {
        titulo: 'Tipos de contacto realizado',
        novo: 'Nuevo tipo de contacto realizado',
        cadastradoSucesso: 'Tipo de contacto registrado con éxito!',
        atualizadoSucesso: 'Tipo de contacto actualizado correctamente!',
        removidoSucesso: 'Tipo de contacto eliminado correctamente!'
      },

      grausDeInteresse: {
        titulo: 'Grados de interés',
        novo: 'Nuevo grado de interés',
        cadastradoSucesso: 'Grado de interés registrado con éxito!',
        atualizadoSucesso: 'Grado de interés actualizado con éxito!',
        removidoSucesso: 'Grado de interés eliminado con éxito!'
      },

      origensDoContato: {
        titulo: 'Orígenes de contacto',
        novo: 'Nuevo origen',
        cadastradoSucesso: 'Origen registrado con éxito!',
        atualizadoSucesso: 'Fuente actualizada con éxito!',
        removidoSucesso: 'Origen eliminado correctamente!'
      },

      workflows: {
        titulo: 'Embudos',
        novo: 'Nuevo embudo',
        cadastradoSucesso: 'Embudo registrado correctamente!',
        atualizadoSucesso: 'Embudo actualizado correctamente!',
        removidoSucesso: 'Embudo eliminado correctamente!',

        colunas: {
          novo: 'Nueva columna',
          dados: 'Datos de columna',
          nome: 'Nombre de columna',
          tarefasGeradasAutomaticamente: 'Tareas generadas automáticamente',
          selecionarTarefas: 'Seleccionar tareas',
          concluirTarefasMudancaFase: 'Completar tareas de cambio de fase',
          cadastradoSucesso: 'Columna registrada con éxito!',
          atualizadoSucesso: 'Columna actualizada correctamente!',
          removidoSucesso: 'Columna eliminada correctamente!'
        }
      }
    }
  },

  cadastro: {
    modulos: {
      atualizadoSucesso: 'Módulos actualizados con éxito!'
    },
    fornecedores: {
      titulo: 'Proveedores',
      novo: 'Nuevo proveedor',
      cadastradoSucesso: 'Proveedor registrado con éxito!',
      atualizadoSucesso: 'Proveedor actualizado correctamente!',
      removidoSucesso: 'Proveedor eliminado correctamente!',
      principaisDados: 'Datos del proveedor principal',

      financeiro: {
        orcamentos: {
          novo: 'Nuevo presupuesto'
        },

        pedidos: {
          novo: 'Nuevo pedido'
        },

        contasPagar: {
          novo: 'Nueva cuenta a pagar'
        }
      },

      dadosClinicos: {
        precaucoes: 'Precauciones',
        alergiaMedicamentosa: 'Alergia a un medicamento',
        medicamentoUsoContinuo: 'Medicamentos de uso continuo',
        principioAtivo: 'Principio activo',
        informePrecaucao: 'Informar una precaución',
        informeMedicamento: 'Informar a una droga',
        buscarPrincipioAtivo: 'Busque un ingrediente activo',
        medicamentoCadastradoSucesso: 'Droga registrada con éxito!',
        medicamentoRemovidoSucesso: 'Droga eliminada con éxito!',
        tipoSanguineo: 'Tipo de sangre',
        tipoSanguineoCadastradoSucesso: 'Tipo de sangre registrado con éxito!',
        tipoSanguineoRemovidoSucesso: 'Tipo de sangre eliminado con éxito!'
      }
    },

    clientes: {
      titulo: 'Clientes',
      novo: 'Nuevo cliente',
      cadastradoSucesso: 'Cliente registrado con éxito!',
      atualizadoSucesso: 'Cliente actualizado correctamente!',
      removidoSucesso: 'Cliente eliminado correctamente!',
      principaisDados: 'Datos clave del cliente',

      historico: {
        disciplina: {
          cadastradoSucesso: 'Asunto registrado con éxito!',
          removidoSucesso: 'Asunto eliminado con éxito!'
        }
      },

      necessidadesEspeciais: {
        titulo: 'Necesidades especiales',
        novo: 'Nueva necesidad especial',
        adicionar: 'Agregar una necesidad especial',
        cadastradoSucesso: 'Necesidad especial registrada con éxito!',
        atualizadoSucesso: 'Necesidad especial actualizada con éxito!',
        removidoSucesso: 'Necesidad especial eliminada con éxito!',
        necessidadeTemporaria: 'Necesidad temporal',
        terminoDaNecessidadeTemporaria: 'Fin de la necesidad',
        selecionarNecessidadeEspecial: 'Seleccione o registre una necesidad'
      },

      dadosClinicos: {
        precaucoes: 'Precauciones',
        alergiaMedicamentosa: 'Alergia a un medicamento',
        medicamentoUsoContinuo: 'Medicamentos de uso continuo',
        principioAtivo: 'Principio activo',
        informePrecaucao: 'Ingrese una precaución',
        informeMedicamento: 'Informar a una droga',
        buscarPrincipioAtivo: 'Busque un ingrediente activo',
        medicamentoCadastradoSucesso: 'Droga registrada con éxito!',
        medicamentoRemovidoSucesso: 'Droga eliminada con éxito!',
        tipoSanguineo: 'Tipo de sangre',
        tipoSanguineoCadastradoSucesso: 'Tipo de sangre registrado con éxito!',
        tipoSanguineoRemovidoSucesso: 'Tipo de sangre eliminado con éxito!',

        alergiasMedicamentosas: {
          titulo: 'Alergias a medicamentos',
          novo: 'Alergia a nuevos fármacos',
          adicionar: 'Añadir alergia a medicamentos',
          cadastradoSucesso: 'Alergia a medicamentos registrada con éxito!',
          atualizadoSucesso: 'La alergia a los medicamentos se actualizó con éxito!',
          removidoSucesso: 'La alergia a los medicamentos se eliminó con éxito!'
        }
      },

      financeiro: {
        orcamento: {
          novo: 'Nuevo presupuesto'
        },

        contrato: {
          novo: 'Nuevo contrato'
        }
      },

      filtros: {
        campoPesquisa: 'Búsqueda por nombre, CPF o fecha de nacimiento',

        textoAjudaPesquisa: 'Use una coma para buscar más de un dato, por ejemplo: "Maria da Silva, 01/01/1988"',
        textoAjudaPesquisaCnpj:
          'Utilice una coma para buscar más de un dato, por ejemplo: "Maria da Silva, 578.257.040-70".'
      },

      importacao: {
        importarPlanilha: 'Importar hoja de calculo',
        downloadTemplateAluno: 'Descarga de plantilla de Estudiante',
        downloadTemplatePaciente: 'Download de plantilla de Paciente',
        downloadTemplateResponsavel: 'Descarga de plantilla Responsable',
        importarCadastros: 'Importar registros',
        importarCadastrosInfo:
          'Primero se debe realizar la importación de los responsables, para poder tener el enlace.',
        criadoPor: 'Criado por',
        situacaoCadastros: 'Situación de los catastros',
        importandoResponsaveis: 'Importador responsable',
        importandoAlunos: 'Importando estudiantes',
        importandoPacientes: 'Importando pacientes',
        cadastrosInvalidos: 'Registros inválidos',
        baixarCadastrosInvalidos: 'Descargar registros inválidos',
        desejaFecharCadastrosInvalidos: '¿Realmente desea cerrar las entradas no válidas?',
        templateImportacaoAlunos: 'Plantilla de importación de estudiantes',
        templateImportacaoPacientes: 'Plantilla de importación de pacientes',
        templateImportacaoResponsaveis: 'Plantilla de importación responsable',
        feedbacks: {
          importadosSucesso: '¡Entradas importadas con éxito!',
          importarArquivoErro: '¡Error al importar el archivo!',
          importadosInvalidos: '¡Tienes entradas que muestran inconsistencias!'
        }
      }
    },

    funcionarios: {
      titulo: 'Empleados',
      novo: 'Nuevo empleado',
      cadastradoSucesso: 'Empleado registrado exitosamente!',
      atualizadoSucesso: 'Empleado actualizado correctamente!',
      removidoSucesso: 'Empleado eliminado correctamente!',
      principaisDados: 'Datos clave del empleado',
      especialidades: {
        titulo: 'Datos de especialidad',
        deletadoSucesso: 'Especialidad eliminada con éxito',
        criadoSucesso: 'Especialidad agregada con éxito',
        registro: 'Número de registro',
        novo: 'Nueva especialidad',
        adicionar: 'Agregar especialidad',
        subespecialidades: {
          adicionar: 'Añadir subespecialidad',
          adicionadaSucesso: 'Subespecialidad agregada con éxito',
          removidaSucesso: 'Subespecialidad eliminada con éxito'
        }
      },
      necessidadesEspeciais: {
        titulo: 'Necesidades especiales',
        novo: 'Nueva necesidad especial',
        adicionar: 'Agregar una necesidad especial',
        cadastradoSucesso: 'Necesidad especial registrada con éxito!',
        atualizadoSucesso: 'Necesidad especial actualizada con éxito!',
        removidoSucesso: 'Necesidad especial eliminada con éxito!',
        necessidadeTemporaria: 'necesidad temporal',
        terminoDaNecessidadeTemporaria: 'Fin de la necesidad',
        selecionarNecessidadeEspecial: 'Seleccione o registre una necesidad'
      },

      dadosClinicos: {
        precaucoes: 'Precauciones',
        alergiaMedicamentosa: 'Alergia a un medicamento',
        medicamentoUsoContinuo: 'Medicamentos de uso continuo',
        principioAtivo: 'Principio activo',
        informePrecaucao: 'Ingrese una precaución',
        informeMedicamento: 'Informar a una droga',
        buscarPrincipioAtivo: 'Busque un ingrediente activo',
        medicamentoCadastradoSucesso: 'Droga registrada con éxito!',
        medicamentoRemovidoSucesso: 'Droga eliminada con éxito!',
        tipoSanguineo: 'Tipo de sangre',
        tipoSanguineoCadastradoSucesso: 'Tipo de sangre registrado con éxito!'
      },

      financeiro: {
        honorarios: {
          novo: 'Agregar tarifa'
        },

        contasBancarias: {
          novo: 'Agregar cuenta bancaria'
        },

        comissoes: {
          nomeConfiguracaoComissao: 'Nombre de configuración de la comisión',
          tipoCalculoComissao: 'Tipo de cálculo de comisión',
          tipoPagamentoComissao: 'Tipo de pago de comisión',
          tipoVencimentoComissao: 'Tipo de comisión adeudada',
          nenhumaComissaoParaFuncionario: 'No se calcula ninguna comisión para esta configuración y el empleado',
          configuracaoDeComissao: 'Configuración de la comisión',
          configuracoesDeComissao: 'Configuración de la comisión',
          novaConfiguracaoDeComissao: 'Nueva configuración de comisión',
          comissaoRemovidaSucesso: 'La configuración de la comisión se eliminó correctamente',
          comissaoCadastradaSucesso: 'Se ha configurado correctamente la comisión registrada'
        }
      },

      termosDeUso: {
        titulo: 'Condiciones de uso de Sponte',
        revogarTermo: 'Revocar término',
        motivoRevogacao: 'Razón de revocación',
        conteudoTermoAceite:
          'Al revocar la aceptación del término, será desconectado de Sponte. Para aceptarlo nuevamente, simplemente inicie sesión en el sistema. Desea continuar?',
        concordoTermo: 'Leo y estoy de acuerdo con los términos'
      },

      horarios: {
        novo: 'Nuevo tiempo',
        editar: 'Editar tiempo',
        cadastradoSucesso: '¡Tiempo registrado con éxito!',
        atualizadoSucesso: '¡Horario actualizado con éxito!',
        removidoSucesso: '¡Tiempo eliminado con éxito!',
        diaRemovidoSucesso: '¡La hora del día se eliminó con éxito!'
      }
    },

    preferencias: {
      alergias: {
        titulo: 'Alergias generales',
        novo: 'Alergia nueva',
        adicionar: 'Agregar alergia',
        cadastradoSucesso: 'Alergia registrada con éxito!',
        atualizadoSucesso: 'Alergia actualizada con éxito!',
        removidoSucesso: 'Alergia eliminada con éxito!'
      },

      cargos: {
        titulo: 'Posiciones',
        novo: 'Nueva posición',
        cadastradoSucesso: 'Posición registrada con éxito!',
        atualizadoSucesso: 'Posición actualizada correctamente!',
        removidoSucesso: 'Posición eliminada con éxito!'
      },

      detalhesPersonalizados: {
        titulo: 'Detalles personalizados',
        novo: 'Nuevo detalle personalizado',
        personalize: 'Personaliza tu calendario',
        cadastradoSucesso: 'Tipo de horario registrado correctamente!',
        atualizadoSucesso: 'Tipo de programa actualizado correctamente!',
        removidoSucesso: 'Tipo de horario eliminado correctamente!'
      },

      condicoesDePagamento: {
        assinaturaRealizadaSucesso: 'Suscripción completada con éxito',
        assinaturaAlteradaSucesso: 'Firma cambiada con éxito',
        alterarCartaoCreditoRecorrencia: 'Cambiar tarjeta de crédito en caso de recurrencia',
        relacionar: 'Lista de condiciones de pago',
        campoPesquisa: 'Buscar condición de pago',
        relacionadaSucesso: 'Condición de pago recurrente relacionada con el éxito',
        deletadaSucesso: 'Condición de pago recurrente eliminada correctamente'
      },

      documentos: {
        modelosDeDocumentos: {
          titulo: 'Plantillas de documentos',
          novo: 'Nueva plantilla de documento',
          cadastradoSucesso: 'Plantilla de documento registrada correctamente!',
          cadastradoErro: 'Ocurrió un error al registrar la plantilla de documento!',
          atualizadoSucesso: 'Plantilla de documento actualizada correctamente!',
          atualizadoErro: 'Se produjo un error al actualizar la plantilla del documento.',
          removidoSucesso: 'Plantilla de documento eliminada correctamente!',
          removidoErro: 'Se produjo un error al eliminar la plantilla del documento.!',
          novoDocumento: 'Nuevo documento',
          adicionarCampo: 'Agregue campo',
          adicionarAnexo: 'Añadir un adjunto',
          criadoPor: 'Criado por',
          dataCriacao: 'Fecha de creación',
          alteradoPor: 'Cambiado por',
          dataAlteracao: 'Cambiar fecha',
          normal: 'Normal',
          tituloEditor: 'Título',
          subtitulo: 'Subtítulo',
          citacao: 'Cita',
          desejaSalvarAntesDeSair: '¿Quieres guardar los cambios antes de salir?',
          impressaoDeExames: 'Impresión de examen',
          receitaOculos: 'Prescripción de anteojos'
        },

        gruposDeDocumentos: {
          titulo: 'Grupos de documentos',
          novo: 'Nuevo grupo de documentos',
          cadastradoSucesso: 'Grupo de documentos registrado correctamente!',
          cadastradoErro: 'No se pudo registrar el grupo de documentos!',
          atualizadoSucesso: 'El grupo de documentos se actualizó correctamente!',
          atualizadoErro: 'No se pudo actualizar el grupo de documentos!',
          removidoSucesso: 'Grupo de documentos eliminado correctamente!',
          removidoErro: 'No se pudo eliminar el grupo de documentos!'
        }
      },

      especialidades: {
        titulo: 'Especialidades',
        novo: 'Nueva especialidad',
        nomeDaEspecialidadePai: 'Nombre de la especialidad de los padres',
        nomeDaSubespecialidade: 'Nombre de la subespecialidad',
        cadastradoSucesso: 'Especialidad registrada con éxito!',
        atualizadoSucesso: 'Especialidad actualizada con éxito!',
        removidoSucesso: 'Especialidad eliminada con éxito!',

        subespecialidades: {
          titulo: 'Subespecialidades',
          novo: 'Nueva subespecialidad',
          nomeDaSubespecialidade: 'Nombre de la subespecialidad',
          cadastradoSucesso: 'Subespecialidad registrada con éxito!',
          atualizadoSucesso: 'Subespecialidad actualizada correctamente!',
          removidoSucesso: 'Subespecialidad eliminada con éxito!'
        }
      },

      funcoes: {
        titulo: 'Roles',
        novo: 'Nuevo rol',
        cadastradoSucesso: 'Función registrada con éxito!',
        atualizadoSucesso: 'Función actualizada con éxito!',
        removidoSucesso: 'Función eliminada con éxito!'
      },

      necessidadesEspeciais: {
        titulo: 'Necesidades especiales',
        novo: 'Nueva necesidad especial',
        adicionar: 'Agregar una necesidad especial',
        cadastradoSucesso: 'Necesidad especial registrada con éxito!',
        atualizadoSucesso: 'Necesidad especial actualizada con éxito!',
        removidoSucesso: 'Necesidad especial eliminada con éxito!',
        necessidadeTemporaria: 'Necesidad temporal',
        terminoDaNecessidadeTemporaria: 'Fin de la necesidad',
        selecionarNecessidadeEspecial: 'Seleccione o registre una necesidad',
        tipos: {
          titulo: 'Tipos de necesidades especiales',
          novo: 'Nuevo tipo de necesidad especial',
          cadastradoSucesso: 'Tipo de necesidad especial registrada con éxito!',
          atualizadoSucesso: 'Tipo de discapacidad actualizado correctamente!',
          removidoSucesso: 'Tipo de discapacidad eliminado correctamente!'
        },
        recursos: {
          titulo: 'Recursos para necesidades especiales',
          novo: 'Nueva función de necesidades especiales',
          cadastradoSucesso: 'Recurso para necesidades especiales registrado con éxito!',
          atualizadoSucesso: 'Recurso de necesidades especiales actualizado con éxito!',
          removidoSucesso: 'Recurso de discapacidad eliminado con éxito!'
        }
      },

      precaucoes: {
        titulo: 'Precauciones',
        novo: 'Nueva precaución',
        adicionar: 'Agregar precaución',
        cadastradoSucesso: 'Precaución registrada con éxito!',
        atualizadoSucesso: 'Precaución actualizada con éxito!',
        removidoSucesso: 'Precaución eliminada con éxito!'
      },

      tags: {
        titulo: 'Etiquetas',
        novo: 'Nueva etiqueta',
        cadastradoSucesso: 'Etiqueta registrada correctamente!',
        atualizadoSucesso: 'Etiqueta actualizada correctamente!',
        removidoSucesso: 'Etiqueta eliminada correctamente!',
        modulosRelacionados: {
          titulo: 'Módulos relacionados',
          novo: 'Nuevo módulo relacionado',
          cadastradoSucesso: 'Módulo relacionado registrado con éxito!',
          removidoSucesso: 'Módulo relacionado eliminado correctamente!'
        }
      },

      tiposResponsavel: {
        titulo: 'Tipos de responsables',
        novo: 'Nuevo tipo de responsable',
        cadastradoSucesso: 'Tipo de responsable registrado con éxito!',
        atualizadoSucesso: 'Tipo de responsable actualizado correctamente!',
        removidoSucesso: 'Tipo de responsable eliminado con éxito!'
      },

      gruposCadastroPersonalizado: {
        titulo: 'Grupos personalizados',
        novo: 'Nuevo',
        cadastradoSucesso: 'Grupo personalizado registrado con éxito',
        atualizadoSucesso: 'Grupo personalizado actualizado correctamente',
        removidoSucesso: 'Grupo personalizado eliminado correctamente',
        disponibilidade: 'Disponibilidad',
        moduloRelacionar: 'Seleccione un módulo para listar',
        moduloAdicionadoSucesso: 'Módulo agregado exitosamente',
        moduloRemovidoSucesso: 'Módulo eliminado con éxito',
        adicioneCampos: 'Agregar campos',
        tipoCampo: 'Tipo de campo',
        campoAdicionadoSucesso: 'Campo agregado exitosamente',
        campoRemovidoSucesso: 'campo eliminado con éxito',
        adicionarOpcoes: 'Agregar opciones',
        adicionarItem: 'Añadir artículo',
        itemAdicionadoSucesso: 'Elemento agregado exitosamente',
        itemRemovidoSucesso: 'Elemento eliminado con éxito'
      },
      motivosCancelamento: {
        titulo: 'Razones de la cancelación',
        novo: 'Nueva razón ',
        editar: 'Editar razón',
        disponibilidade: 'Disponibilidad',
        disponivelEm: 'Disponible',
        cadastradoSucesso: 'Razón registrada con éxito!',
        atualizadoSucesso: 'Razón actualizada correctamente!',
        removidoSucesso: 'Razón eliminada correctamente!'
      }
    },

    pessoa: {
      comercial: {
        oportunidades: 'Oportunidades',
        funil: 'Embudo',
        fase: 'Fase',
        proximoContato: 'Siguiente contacto',
        ultimoContato: 'Ultimo contacto',
        novaOportunidade: 'Nueva oportunidad',
        acompanhamentos: 'Acompañamientos',
        novoAcompanhamento: 'Nuevo seguimiento',
        comentario: 'Comentario',
        usuario: 'Usuario',
        data: 'Fecha',
        hora: 'Hora',
        tarefa: 'Asignación',
        tipo: 'Escribe'
      }
    },
    liberacaoDeModulos: {
      titulo: 'Lanzamiento del módulo',
      atualizadoSucesso: 'La versión del módulo se actualizó correctamente!'
    }
  },

  agenda: {
    calendario: {
      titulo: 'Calendarios',
      novo: 'Nuevo calendario',
      cadastradoSucesso: 'Calendario registrado con éxito!',
      atualizadoSucesso: 'Calendario actualizado correctamente!',
      removidoSucesso: 'Calendario eliminado correctamente!',
      selecionarCor: 'Seleccione un color',
      selecionarCorEQuantidadeDeModulos: 'Seleccione un color y la cantidad de módulos',
      corSeraAplicadaEmTodosOsPeriodos: 'El color se aplicará en todos los periodos',
      quantidadeDeModulosASeremGerados: 'Cantidad de módulos a ser generados'
    },

    preferencias: {
      configuracoesDeCalendarios: {
        titulo: 'Configuración del calendario',
        novo: 'Nueva configuración de calendario',
        cadastradoSucesso: 'La configuración del calendario se registró correctamente!',
        atualizadoSucesso: 'La configuración del calendario se actualizó correctamente!',
        removidoSucesso: 'La configuración del calendario se eliminó correctamente!',
        copiadoSucesso: 'La configuración del calendario se copió correctamente!',
        calendario: 'Calendario',
        feriadosEEventos: 'Fiestas y eventos',
        duracaoDeEventos: 'Duración de los eventos',
        personalize: 'Personaliza tu calendario',
        informacoes: 'Información del calendario',
        usarCalendarioPadrao: 'Usar calendario predeterminado',
        informePeriodo: 'Ingrese al menos 1 punto',
        alterarCalendario: 'Cambiar calendario',
        confirmarAlterarCalendario:
          'Cambiar el tipo de período afectará directamente la configuración en la pestaña Periodo, ¿quieres confirmarlo?',
        localEvento: 'Lugar del evento',
        custoEvento: 'Costo del evento',
        atualizarConfiguracoesDeAula: 'Actualizar la configuración de la clase',
        atualizarParaAnoLetivo: 'Actualización para el año escolar.',
        atualizarRegistrosPosterioresADataAtual: 'Actualizar registros después de la fecha actual',
        naoPodeReplicarConfiguracaoCalendario:
          'La configuración del horario no se puede cambiar ya que contiene clases lanzadas con frecuencia y/o clases confirmadas',
        replicarConfiguracaoCalendarioTooltip:
          'Atención: Las clases registradas en el diario de clases se eliminan al actualizar la configuración de tiempo',
        confirmaReplicaoConfiguracaoCalendario:
          'Los cambios realizados en el calendario se aplicarán a todos los calendarios vinculados a las clases. ¿Realmente desea continuar con el cambio?',
        alteracaoNoCalendario: 'Cambio de calendario',

        duracoes: {
          nova: 'Nueva duración',
          cadastradoSucesso: 'Duración registrada con éxito!',
          atualizadoSucesso: 'Duración actualizada correctamente!',
          removidoSucesso: 'Duración eliminada con éxito!'
        },

        feriados: {
          novo: 'Nuevo evento',
          cadastradoSucesso: 'Evento registrado con éxito!',
          atualizadoSucesso: 'Evento actualizado con éxito!',
          removidoSucesso: 'Evento eliminado con éxito!',

          periodos: {
            titulo: 'Curso del tiempo',
            novo: 'Agregar período',
            editar: 'Período de edición',
            cadastradoSucesso: '¡Período registrado con éxito!',
            atualizadoSucesso: '¡Período actualizado con éxito!',
            removidoSucesso: '¡Período eliminado con éxito!'
          }
        },

        horariosDeFuncionamento: {
          titulo: 'Horario de apertura',
          novo: 'Nuevos horarios de apertura',
          cadastradoSucesso: 'Horarios de apertura registrados con éxito!',
          atualizadoSucesso: 'Horarios de apertura actualizados con éxito!',
          removidoSucesso: 'Horarios de apertura eliminados con éxito!',

          periodos: {
            titulo: 'Ajustes de hora',
            novo: 'Nueva configuración de hora',
            editar: 'Editar configuración de tiempo',
            cadastradoSucesso: 'Ajuste de la hora registrado correctamente!',
            atualizadoSucesso: 'La configuración de la hora se actualizó correctamente!',
            removidoSucesso: 'Configuración de hora eliminada correctamente!',
            tipoFaltaDisciplina: 'Tipo de ausencia por disciplina',
            tipoFaltaDia: 'Tipo de ausencia por día',
            intervalos: {
              cadastradoSucesso: 'Intervalo registrado con éxito!',
              atualizadoSucesso: 'Intervalo actualizado correctamente!',
              removidoSucesso: 'Rango eliminado con éxito!',
              titulo: 'Rotura',
              novo: 'Nuevo intervalo',
              adicionar: 'Agregar rango'
            }
          }
        },

        periodos: {
          titulo: 'Configuración de clase',
          tituloTurma: 'Configuración de horario de clase',
          selecionarHorarios: 'Seleccionar configuración de horario',
          novo: 'Nueva configuración de clase',
          editar: 'Editar configuración de clase',
          nomeConfiguracao: 'Nombre de la configuración',
          diasSemanaConfigurados: 'Días de la semana configurados',
          horaInicioFim: 'Hora de inicio y finalización',
          numeroPeriodos: 'Número de períodos',
          vigencia: 'Validez',
          aulaCadastradaSucesso: '¡Clase registrada con éxito!',
          aulaRemovidaSucesso: '¡Clase eliminada con éxito!',
          cadastradoSucesso: 'Configuración de clase registrado correctamente!',
          atualizadoSucesso: 'Configuración de clase se actualizó correctamente!',
          removidoSucesso: 'Configuración de clase eliminada correctamente!',
          configuracaoValidaDe: 'Configuración válida de',
          configuracaoValidaAte: 'Configuración válida hasta',
          configurarAula: 'Configurar clase',
          listaHorarios: 'Lista de horas',
          configDuracaoAula: 'Configuración de duración de clases',
          selecionarTipo: 'Seleccionar tipo'
        },

        recursos: {
          novoRecurso: 'Nueva caracteristica',
          adicionarRecursos: 'Agregar recursos',
          professor: 'Maestro',
          equipamento: 'Equipo'
        }
      }
    }
  },

  contabil: {
    centroDeCustos: {
      titulo: 'Centro de costos',
      novo: 'Nuevo centro de costos',
      cadastradoSucesso: 'Centro de costos registrado con éxito!',
      atualizadoSucesso: 'Centro de costos actualizado con éxito!',
      removidoSucesso: 'Centro de costos eliminado correctamente!',
      editarCentroDeCustos: 'Editar centro de costos',
      selecionePlanoDeContas: 'Seleccione un plan de cuentas',
      nome: 'Nombre del centro de coste'
    },

    dre: {
      receitasVendasBrutas: 'Ingresos por ventas brutas',
      gastosDespesas: 'Gastos y gastos',
      resultadoOperacional: 'Resultado operacional',
      divisaoReceitas: 'División de ingresos',
      divisaoDespesas: 'División de gastos',
      resultadoExercicio: 'Resultado del ejercicio',
      estrutura: 'Estructura',
      planejado: 'Planificado',
      realizado: 'Logrado',
      variacao: 'Variación',
      competencia: 'Competencia',
      caixa: 'Caja',
      regime: 'Régimen',
      preDefinido: 'Predefinido',
      colunas: 'Columnas',
      aplicar: 'Aplicar',
      periodo: 'Curso del tiempo',
      de: 'En',
      ate: 'Hasta que',
      exibeRegimePor: 'Muestra el régimen por:',
      feedbacks: {
        naoTemLancamentoPlanoConta: 'No hay nada publicado en este plan de cuenta..',
        semDadosNessePeriodo: 'No hay datos en este período'
      }
    },

    planoDeContas: {
      titulo: 'Tablas de cuentas',
      novo: 'Nuevo plan de cuentas',
      cadastradoSucesso: 'Plan de cuentas registrado con éxito!',
      atualizadoSucesso: 'Plan de cuentas actualizado correctamente!',
      removidoSucesso: 'Plan de cuentas eliminado con éxito!',
      editarPlanoDeContas: 'Editar plan de cuentas'
    }
  },

  financeiro: {
    descontosEAcrescimos: 'Descuentos y devengos',
    edicaoParcelas: 'Edición a plazos',
    descontosDaFormaDePagamento: 'Descuentos por método de pago',
    aoEditarParcelas: 'Al editarlo, todos los plazos se generarán de nuevo. ¿Realmente quieres seguir editando?',
    configuracaoDescontoAvulso: 'Configuración de descuentos y descuentos individuales',
    acrescimoJurosAposVencimento: 'Devengos e intereses tras el vencimiento',
    valorComJurosEMulta: 'Valor total con intereses y multas',
    renegociarUmaParcela:
      'Está a punto de renegociar una cuota según el importe y la fecha establecidos. ¿Realmente quieres continuar con el cambio?',
    vendaAvulsa: 'Venta única',

    servicos: {
      servicoJaCadastradoSerieInfo:
        'Existe un servicio registrado para la serie "{{value}}". ¿Quieres registrar uno nuevo?'
    },

    caixaUsuario: {
      titulo: 'Caja de usuario',
      novo: 'Nuevo lanzamiento',
      editar: 'Editar lanzamiento',
      cadastradoSucesso: 'Lanzamiento registrado con éxito!',
      atualizadoSucesso: 'Lanzamiento actualizado con éxito!',
      removidoSucesso: 'Liberación eliminada con éxito!',
      reaberturaCaixas: 'Reapertura de cajas',
      novoCaixa: 'Caja nueva',
      fechamentoCaixa: 'Cierre de caja',
      recebimentoDireto: 'Recibo directo',
      lancarRecebimento: 'Recibo de lanzamiento',
      lancarRetirada: 'Retiro de lanzamiento',
      totalRecebimento: 'Recibo total',
      saldoRecebimento: 'Saldo de recibo',
      retiradaCaixa: 'Retiro de efectivo',
      totalRetirada: 'Retiro total',
      saldoRetirada: 'Saldo de retiro',
      valorRecebimento: 'Importe del recibo',
      saldoAbertura: 'Saldo de apertura',
      saldoTotalCaixa: 'Saldo total de efectivo',
      adicoesRetiradas: 'Adiciones o Retiros',
      dataAdicaoRetirada: 'Fecha de adición / retiro',
      horaAdicaoRetirada: 'Tiempo de adición / retiro',
      subtotalMulta: 'Subtotal multa',
      subtotalDesconto: 'Subtotal de descuento',
      dataFechamentoCaixa: 'Fecha de cierre del cajero',
      caixaReabertoSucesso: 'Caja reabierta con éxito!',
      naoPossuiSaldoRetirada: 'No hay saldo para iniciar el retiro',
      naoPossuiSaldo: 'No tiene saldo'
    },

    caixa: {
      titulo: 'Efectivo diario',
      novo: 'Nuevo lanzamiento',
      editar: 'Editar lanzamiento',
      cadastradoSucesso: 'Lanzamiento registrado con éxito!',
      atualizadoSucesso: 'Lanzamiento actualizado con éxito!',
      removidoSucesso: 'Liberación eliminada con éxito!',

      tipoMovimento: {
        SANGRIA: 'Retiro',
        ABERTURA: 'Apertura',
        FECHAMENTO: 'Cierre',
        SUPRIMENTO: 'Suministro',
        TITULO_PAGAR: 'Pago',
        TITULO_RECEBER: 'Recepción',
        ESTORNO_TITULO_PAGAR: 'Reversión de pago',
        ESTORNO_TITULO_RECEBER: 'Anulación de recibo',
        CONSOLIDACAO: 'Consolidación',
        ESTORNO_CONSOLIDACAO: 'Inversión de consolidación'
      }
    },

    caixaGeral: {
      titulo: 'Caja general',
      semRegistros: 'Abra un cuadro para que se muestre aquí.',
      semMovimentos: 'Haz un movimiento para que se muestre aquí.',
      desfazerConsolidacao: 'Deshacer consolidación',
      desfazerConsolidacaoPermanentemente: 'Deshacer esta consolidación de forma permanente',
      desfazer: 'Deshacer',
      desejaDesfazer: 'Realmente quiero deshacer?',
      caixaConsolidadoSucesso: 'Efectivo consolidado con éxito!',
      movimentoConsolidadoSucesso: 'Movimiento consolidado con éxito!',
      consolidacaoDesfeitaSucesso: 'Consolidación deshecha con éxito!',
      contaDestino: 'Cuenta de destino',
      caixaFechadoSucesso: 'Caja cerrada con éxito!',
      caixaRetiradoSucesso: 'Retiro exitoso!',
      caixaSupridoSucesso: 'Caja suministrada con éxito!',
      caixasComFechamentoDe: 'Cajas con cierre de',
      movimentosComValorDe: 'Movimientos que valen',
      formasPagamentoFechamentoCaixa: 'Métodos de pago para el cierre en efectivo.',
      caixaNaoPossuiMovimento: 'El cajero no tiene movimiento!',
      caixasNaoPossuemMovimento: 'Los cajeros no tienen movimiento!',
      numeroDoCaixa: 'Efectivo No.',
      dataMovimentacao: 'Fecha de movimiento',
      fecamentosConsolidados: 'Cierres consolidados',
      fecamentosNaoConsolidados: 'Cierres no consolidados',
      resumoFechamento: 'Resumen de cierre',
      valorInicialCaixa: 'Valor inicial en caja',
      valorTotalCaixa: 'Valor total en caja',
      valorTotalEntradas: 'Valor total de las entradas',
      valorTotalRetiradas: 'alor total de los retiros',
      confirmaDesfazerConsolidacao:
        'Está a punto de deshacer una consolidación de una caja ya cerrada. ¿De verdad quieres deshacer la caja?',
      confirmaDesfazerConsolidacaoJustificativa:
        'Para completar la operación de deshacer del cajero, díganos por qué se deshizo.',
      somenteCaixasMesmoStatus: 'Solo se pueden seleccionar casillas con el mismo estado',
      detalhesConsolidacao: 'Detalles de consolidación',

      feedbacks: {
        somenteTituloPodemSerSelecionados: 'Solo se pueden seleccionar títulos.!',
        somenteTitulosStatusConsolidacaoPodemSerSelecionado:
          'Solo se pueden seleccionar bonos con un estado de consolidación.!',
        somenteTitulosStatusDiferenteConsolidacaoPodemSerSelecionados:
          'Solo se pueden seleccionar bonos con diferente estado de consolidación.!',
        estornosTitulosNaoPodemSerConsolidados: 'Las revocaciones de títulos no se pueden consolidar!'
      }
    },

    compras: {
      orcamentos: {
        titulo: 'Presupuestos de compra',
        novo: 'Nuevo presupuesto de compra',
        editar: 'Editar presupuesto',
        cadastradoSucesso: 'Presupuesto registrado correctamente!',
        atualizadoSucesso: 'Presupuesto actualizado correctamente!',
        removidoSucesso: 'Presupuesto eliminado correctamente!',
        registradoComSucesso: 'Presupuesto ejecutado con éxito!',
        desejaEfetivarOrcamento: '¿Quiere que este presupuesto sea efectivo?',
        efetivarOrcamento: 'Hacer presupuesto',
        efetivados: 'Eficaz',
        pendentes: 'Sobresaliente',
        nenhumCadastro: 'No se encontró ninguna cita!'
      },

      entradas: {
        titulo: 'Aperitivo',
        novo: 'Nueva entrada',
        editar: 'Editar entrada',
        cadastradoSucesso: 'Entrada registrada con éxito!',
        atualizadoSucesso: 'Entrada actualizada con éxito!',
        removidoSucesso: 'Entrada eliminada con éxito!',
        tituloGeradoComSucesso: 'Título generados correctamente!',
        estornadoSucesso: 'Entrada revertida con éxito!',
        nenhumCadastro: 'Entradas no encontradas!'
      },

      contasAPagar: {
        titulo: 'Cuentas a pagar',
        novo: 'Nueva cuenta a pagar',
        editar: 'Editar cuenta por pagar',
        cadastradoSucesso: 'Cuenta registrada correctamente!',
        atualizadoSucesso: '¡Cuenta actualizada con éxito!',
        removidoSucesso: 'Cuenta eliminada correctamente!',
        quitar: 'Saldar la cuenta',
        estornar: 'Cuenta inversa',
        subTotalAdiantamento: 'Subtotal anticipado',
        subTotalAcrescimo: 'Subtotal de la adición',
        subTotalDesconto: 'Subtotal de descuento',
        saldoConta: 'Saldo de la cuenta',
        estornadoSucesso: 'Cuenta por pagar revertida con éxito!',
        nenhumCadastro: 'No se encontraron cuentas por pagar!',
        charts: {
          quitadas: 'Pagado',
          pendentes: 'Sobresaliente',
          vencidas: 'Atrasado',
          canceladas: 'Cancelado'
        }
      }
    },

    vendas: {
      orcamentos: {
        titulo: 'Presupuestos de ventas',
        novo: 'Nuevo presupuesto de ventas',
        editar: 'Editar presupuesto',
        cadastradoSucesso: 'Presupuesto registrado correctamente!',
        atualizadoSucesso: 'Presupuesto actualizado correctamente!',
        removidoSucesso: 'Presupuesto eliminado correctamente!',
        registradoComSucesso: '¡Presupuesto completado con éxito!',
        desejaEfetivarOrcamento: '¿Quiere hacer efectivo este presupuesto?',
        efetivarOrcamento: 'Hacer presupuesto',
        canceladoComSucesso: '¡Presupuesto cancelado con éxito!',
        cancelarOrcamento: 'Cancelar presupuesto',
        nenhumCadastro: 'No se encontró ninguna cita!',
        efetivados: 'Eficaz',
        pendentes: 'Sobresaliente',
        quitarEmLote: 'Pagar en lotes',
        contaPadrao: 'Cuenta estándar',
        formaCobranca: 'Método de facturación',
        acumularParcelas: 'Acumular cuotas',
        finalizarPagamento: 'Finalizar pago',
        jurosDiaAposVencimento: 'Interés del {{value}}% por día después del vencimiento',
        parcelaRenegociadaSucesso: 'Cuota renegociada con éxito',
        detalhesRenegociacao: 'Detalles de la renegociación',
        renegociacaoFeitaPor:
          'Renegociación realizada por {{nome}} el $(geral:date_formatter) a las $(geral:time_formatter)',
        dadosRenegociacao: 'Datos de renegociación',
        parcelaRenegociada: 'Cuota renegociada',
        dadosCartao: 'Datos de la tarjeta',
        numeroCartaoApenasQuatroUltimos: 'Número de tarjeta (solo los últimos 4)',
        codigoAutorizacao: 'Código de Autorización',
        taxaOperadora: 'Tarifa de transporte',

        itens: {
          cadastradoSucesso: '¡Artículo agregado con éxito!',
          atualizadoSucesso: '¡Artículo actualizado con éxito!',
          removidoSucesso: '¡Artículo eliminado con éxito!',
          itensAdicionados: 'Artículos agregados',
          selecionarProdutoServico: 'Seleccionar producto/servicio',
          quantidadeProdutoServico: 'Cantidad de producto/servicio',
          selecionarTipoPreco: 'Seleccionar tipo de precio',
          valorPrimeiraParcelaFixa: 'Importe de la primera cuota fija',
          diaVencimentoPadrao: 'Día de caducidad predeterminado',
          valorTotalSemDesconto: 'Importe total sin descuento',
          valorDesconto: 'Valor de descuento'
        },

        composicao: {
          titulo: 'Composición',
          novo: 'Nueva composicion',
          editar: 'Editar composición',
          cadastradoSucesso: 'Membresía registrada con éxito!',
          atualizadoSucesso: 'Composición actualizada correctamente!',
          removidoSucesso: 'Composición eliminada correctamente!',
          item: 'Artículo',
          subTotalComCondicoes: 'Subtotal con condiciones',
          totalComposicaoComCondicao: 'Composición total con condición',
          totalComposicao: 'Composición total',

          condicoes: {
            titulo: 'Condiciones de pago',
            novo: 'Añadir condiciones',
            editar: 'Editar condición',
            cadastradoSucesso: 'Condición de pago registrada con éxito!',
            atualizadoSucesso: 'Condiciones de pago actualizadas correctamente!',
            removidoSucesso: 'Condiciones de pago eliminadas correctamente',
            descontoDeletadoSucesso: 'Descuento eliminado correctamente',
            semDados: 'Artículo sin condiciones de pago',
            semDadosDescricao: 'Agregue al menos un término de pago para el artículo.',
            descontoAvulso: 'Descuento único',
            tipoDesconto: 'Tipo de descuento',
            saldoDasParcelas: 'Saldo de cuotas',
            saldoDoItem: 'Saldo del artículo',
            saldoTotalParcelas: 'Saldo total de cuotas',
            totalDesconto: 'Descuento total',
            valorDesconto: 'Importe de descuento',
            valorOriginal: 'Valor original',
            valorDescontoAvulso: 'Importe de descuento único',
            totalJuros: 'Interés Total',
            totalMulta: 'Sanción Total',
            totalDeAcrescimoDaCondicaoPagamento: 'Adición total a la condición de pago',
            listaDescontos: 'Lista de descuento',
            descontosAdicionadoSucesso: 'Descuento agregado exitosamente',
            descontoPontualidade: 'Descuento de puntualidad',
            descontoConvenio: 'Descuento de seguro',
            configuracaoConvenio: 'Configuración del acuerdo',
            configuracaoDesconto: 'Configuración de descuento'
          }
        },

        charts: {
          efetivados: 'Efectuados',
          pendentes: 'Pendientes',
          vencidos: 'Expirados',
          cancelados: 'Cancelados'
        }
      },

      saidas: {
        titulo: 'Salidas',
        novo: 'Nueva salida',
        editar: 'Editar salida',
        cadastradoSucesso: 'Salida registrada con éxito!',
        atualizadoSucesso: 'Salida actualizada correctamente!',
        removidoSucesso: 'Salida eliminada con éxito!',
        registradoComSucesso: 'Título (s) generados correctamente!',
        desejaGerarTitulos: 'Quiere generar títulos?',
        gerarTitulos: 'Generar títulos',
        nenhumCadastro: 'No se encontró salida!',
        nfSucesso: '¡Solicitud de generación de facturas realizada con éxito!',
        erroAoGerarTituloSemVendedor: 'Es necesario informar al vendedor para generar fianzas!',

        composicao: {
          titulo: 'Composición',
          novo: 'Nueva composicion',
          editar: 'Editar composición',
          cadastradoSucesso: 'Membresía registrada con éxito!',
          atualizadoSucesso: 'Composición actualizada correctamente!',
          removidoSucesso: 'Composición eliminada correctamente!',
          item: 'Artículo',
          subTotalComCondicoes: 'Subtotal con condiciones',

          condicoes: {
            titulo: 'Condiciones de pago',
            novo: 'Gestionar las condiciones',
            atualizadoSucesso: '¡Condición actualizada con éxito!',
            semDados: 'Artículo sin condiciones de pago',
            semDadosDescricao: 'Agregue al menos un plazo de pago para el artículo.',
            cadastradoSucesso: '¡Condición registrada con éxito!',
            removidoSucesso: '¡Condición eliminada con éxito!'
          }
        }
      },

      contasAReceber: {
        titulo: 'Cuentas a recibir',
        novo: 'Nueva cuenta por cobrar',
        editar: 'Editar cuenta por cobrar',
        cadastradoSucesso: 'Cuenta registrada correctamente!',
        atualizadoSucesso: 'Cuenta actualizada con éxito!',
        removidoSucesso: 'Cuenta eliminada correctamente!',
        quitar: 'Saldar la cuenta',
        estornar: 'Cuenta inversa',
        subTotalAdiantamento: 'Subtotal anticipado',
        subTotalAcrescimo: 'Subtotal de la adición',
        subTotalDesconto: 'Subtotal de descuento',
        saldoConta: 'Saldo de la cuenta',
        quitarSelecionados: 'Pagar seleccionados',
        configuracaoCartao: 'Configuración de la tarjeta',
        nenhumCadastro: 'No se encontraron cuentas por cobrar!',
        gerarNfAviso: '¡La cuenta por cobrar no tiene artículo o no utiliza el término de pago del artículo!',
        nfSucesso: '¡Solicitud de generación de facturas realizada con éxito!',
        quitarEmLote: 'Pagar en lotes',

        itens: {
          cadastradoSucesso: '¡Artículo agregado con éxito!',
          atualizadoSucesso: '¡Artículo actualizado con éxito!',
          removidoSucesso: '¡Artículo eliminado con éxito!',
          itensAdicionados: 'Artículos agregados',
          selecionarProdutoServico: 'Seleccionar producto/servicio',
          quantidadeProdutoServico: 'Cantidad de producto/servicio',
          selecionarTipoPreco: 'Seleccionar tipo de precio',
          valorPrimeiraParcelaFixa: 'Importe de la primera cuota fija',
          diaVencimentoPadrao: 'Día de caducidad predeterminado',
          valorTotalSemDesconto: 'Importe total sin descuento',
          valorDesconto: 'Valor de descuento'
        },

        checkout: {
          ambienteSeguro: 'Ambiente seguro',
          nomeCartaoDeCredito: 'Nombre (el mismo en la tarjeta)',
          cvvCartaoDeCredito: 'CVV',
          medpay: 'Pago con Medpay',
          spontepay: 'Pago de Spontepay',
          totalAPagar: 'Total a pagar'
        },

        charts: {
          quitadas: 'Pagado',
          pendentes: 'Sobresaliente',
          vencidas: 'Atrasado',
          canceladas: 'Cancelado'
        }
      }
    },

    tesouraria: {
      boletos: {
        titulo: 'Control de tickets',
        editar: 'Detalles del boleto',
        reenviarNotificacaoBoleto: 'Reenviar notificación de boleto',
        apresentarItensPagosInstrucao: 'Mostrar artículos pagados en la instrucción',
        escolherTemplateSms: 'Elegir plantilla de SMS',
        escolherTemplateEmail: 'Elegir plantilla de correo electrónico',
        numeroBoleto: 'Número de factura',
        valorBoleto: 'Valor de la factura',
        dataGeracao: 'Fecha de generación',
        nCodigoDeBarras: 'Nº de código de barras',
        linhaDigitavel: 'Línea digital',
        boletoCreditadoSucesso: '¡Boleto acreditado exitosamente!',
        dadosBoleto: 'datos del boleto',

        notificacaoEnviadaSucesso: '¡Notificación enviada con éxito!',
        cadastradoSucesso: 'Billet generado con éxito!',
        atualizadoSucesso: 'Ticket actualizado con éxito!',
        removidoSucesso: 'Billet eliminado con éxito!',
        feedbackSecundario: 'Generar una letra de cambio a partir de una cuenta por cobrar.',

        configuracoes: {
          titulo: 'Configuración del billete',
          atualizadoSucesso: 'La configuración del billete se actualizó correctamente!',
          diasVencimento: 'Días antes del vencimiento',
          diasValor: 'Días para confirmar valor',
          valorTarifa: 'Importe de la cuota',
          lancarValorTarifa: 'Publicar el importe de la tarifa como salida',
          prazoMaximoRecebimento: 'Plazo en días para recepción',
          operacaoPadrao: 'Operación estándar'
        },
        desejaCancelar: '¿De verdad quieres cancelar el resbalón?'
      },
      controleDeCheques: {
        titulo: 'Verificar el control',
        novo: 'Nuevo',
        editar: 'Para editar',
        cadastradoSucesso: 'Cheque registrado exitosamente!',
        atualizadoSucesso: 'Verificar actualizado con éxito!',
        removidoSucesso: 'Comprobar eliminado correctamente!',
        numeroDoCheque: 'Verifique el número',
        dataCompensacao: 'Fecha de compensación',
        emitente: 'Nombre del emisor',
        dadosDoCliente: 'Datos del cliente'
      },
      controleDeCartoes: {
        pagador: 'Pagador',
        desejaEstornarConciliacaoBaixa: '¿Quiere revertir la liquidación de esta cancelación? ',
        conciliacaoSucesso: 'Conciliación realizada con éxito',
        detalhesConciliacao: 'Detalles de reconciliación',
        estornoSucesso: 'Reversión realizada con éxito',
        estornar: 'Marcha atrás',
        pesquisar: 'Búsqueda por nombre y CPF',
        charts: {
          totalTransacoes: 'Transacciones totales',
          conciliados: 'Reconciliado',
          pendentes: 'Sobresaliente',
          estornos: 'Contracargos',
          totalTaxas: 'Tarifas totales',
          saldo: 'Equilibrio'
        }
      }
    },

    preferencias: {
      configuracoesDePagamentos: {
        formasDePagamento: {
          titulo: 'Formas de pago',
          novo: 'Nuevo método de pago',
          editar: 'Editar método de pago',
          cadastradoSucesso: 'Método de pago registrado correctamente!',
          atualizadoSucesso: 'Método de pago actualizado correctamente!',
          removidoSucesso: 'Método de pago eliminado correctamente!'
        },

        condicoesDePagamentos: {
          titulo: 'Términos de pago',
          novo: 'Nuevo plazo de pago',
          cadastradoSucesso: 'Condición de pago registrada correctamente!',
          atualizadoSucesso: 'Condición de pago actualizada correctamente!',
          removidoSucesso: 'Condición de pago eliminada correctamente!',

          restricoes: {
            novo: 'Nueva restricción',
            cadastradoSucesso: 'Restricción registrada correctamente!',
            removidoSucesso: 'Restricción eliminada con éxito!'
          }
        }
      },

      configuracoesDeCartoes: {
        configuracoesDeCartoes: {
          titulo: 'Configuración de la tarjeta',
          novo: 'Nueva configuracion',
          editar: 'Editar configuración',
          cadastradoSucesso: 'Configuración de tarjeta registrada correctamente!',
          atualizadoSucesso: 'La configuración de la tarjeta se actualizó correctamente!',
          removidoSucesso: 'La configuración de la tarjeta se eliminó correctamente!',
          geraContaPagarTaxa: 'Generar cuenta para pagar tarifa',
          compensaVencimento: 'Compensa la madurez',
          naoCompensaVencimento: 'No se amortiza al vencimiento',
          configuracao: {
            titulo: 'Configuración de la tarjeta',
            adicionar: 'Agregar configuración',
            novo: 'Nueva configuración de tarjeta'
          }
        },

        configuracoesDeBandeiras: {
          titulo: 'Configuración de la bandera',
          novo: 'Nueva bandera',
          cadastradoSucesso: 'Bandera registrada con éxito ',
          atualizadoSucesso: 'Bandera actualizada correctamente',
          removidoSucesso: 'Bandera eliminada con éxito'
        },

        configuracoesDeCredenciadoras: {
          titulo: 'Configuración del acreedor',
          novo: 'Nuevo adquirente',
          cadastradoSucesso: 'Acreedor registrado exitosamente',
          atualizadoSucesso: 'Acreedor actualizado con éxito',
          removidoSucesso: 'Acreedor eliminado con éxito'
        }
      },

      configuracoesDeDescontos: {
        configuracoesDeDescontos: {
          titulo: 'Configuración de descuento',
          novo: 'Nueva configuración de descuento',
          cadastradoSucesso: 'Configuración de descuento registrada correctamente!',
          atualizadoSucesso: 'Configuración de descuento actualizada correctamente!',
          removidoSucesso: 'La configuración de descuento se eliminó correctamente!',

          formaDePagamento: {
            titulo: 'Formas de pago',
            novo: 'Nuevo método de pago',
            cadastradoSucesso: 'Método de pago registrado correctamente!',
            removidoSucesso: 'Método de pago eliminado correctamente!'
          },

          descontos: {
            titulo: 'Descuentos.',
            novo: 'Nuevo descuento',
            editar: 'Editar descuento',
            cadastradoSucesso: 'Descuento registrado con éxito!',
            atualizadoSucesso: 'Descuento actualizado con éxito!',
            removidoSucesso: 'Descuento eliminado con éxito!',
            valorDescontoSeraAbatido:
              'El monto del descuento se deducirá del valor de cada cuota y no del valor total del plan.'
          }
        },

        configuracoesDeBolsa: {
          titulo: 'Configuración de la bolsa',
          novo: 'Nueva configuración de bolsa',
          cadastradoSucesso: 'Configuración de un intercambio registrado exitoso!',
          atualizadoSucesso: 'Configuración de bolsa actualizada con éxito!',
          removidoSucesso: 'La configuración de la bolsa se eliminó correctamente!'
        },

        configuracoesDeConvenios: {
          titulo: 'Configuración de seguros',
          novo: 'Configuración de nuevo acuerdo',
          cadastradoSucesso: 'La configuración del acuerdo se registró correctamente!',
          atualizadoSucesso: 'La configuración del acuerdo se actualizó correctamente!',
          removidoSucesso: 'La configuración del acuerdo se eliminó correctamente!',

          formaDePagamento: {
            titulo: 'Formas de pago',
            novo: 'Nuevo método de pago',
            formaDePagamentoUtilizada: 'Este método de pago ya se está utilizando.!',
            cadastradoSucesso: 'Método de pago registrado correctamente!',
            removidoSucesso: 'Método de pago eliminado correctamente!'
          },

          desconto: {
            titulo: 'Descuento',
            novo: 'Nuevo descuento',
            editar: 'Editar descuento',
            cadastradoSucesso: 'Descuento registrado con éxito!',
            atualizadoSucesso: 'Descuento actualizado con éxito!',
            removidoSucesso: 'Descuento eliminado con éxito!'
          }
        }
      },

      operacoes: {
        titulo: 'Operaciones',
        novo: 'Nueva operación',
        editar: 'Editar operación',
        cadastradoSucesso: 'Operación registrada con éxito',
        atualizadoSucesso: 'Operación actualizada con éxito',
        removidoSucesso: 'Operación eliminada con éxito',
        selecioneCentroDeCustos: 'Seleccione un centro de costos',
        centroDeCustosUtilizado: 'Este centro de costes ya se está utilizando!'
      },

      configuracoesDeComissoes: {
        titulo: 'Configuración de la comisión',
        novo: 'Nueva configuración de comisión',
        cadastradoSucesso: 'Se ha configurado correctamente la comisión registrada!',
        atualizadoSucesso: 'Configuración de comisión actualizada con éxito!',
        removidoSucesso: 'La configuración de la comisión se eliminó correctamente!',

        metas: {
          titulo: 'Metas',
          novo: 'Nuevo gol',
          editar: 'Editar objetivo',
          cadastradoSucesso: 'Objetivo registrado con éxito!',
          atualizadoSucesso: 'Objetivo actualizado correctamente!',
          removidoSucesso: 'Destino eliminado correctamente!'
        }
      },

      bancosEContas: {
        titulo: 'Bancos y cuentas',
        novo: 'Nueva cuenta',
        cadastradoSucesso: 'Cuenta bancaria registrada con éxito!',
        atualizadoSucesso: 'Cuenta bancaria actualizada correctamente!',
        removidoSucesso: 'Cuenta bancaria eliminada con éxito!',

        responsaveis: {
          adicionarResponsavel: 'Agregar responsable',
          novoResponsavel: 'Nuevo responsable',
          nome: 'Nombre del responsable',
          tipoResponsavel: 'Tipo responsable',
          cadastradoSucesso: 'Persona responsable registrada con éxito!',
          removidoSucesso: 'Responsable eliminado con éxito!'
        }
      }
    },

    faturas: {
      dadosDaFatura: 'Datos de facturación',
      outrosServicos: 'Otros servicios',
      naoCompoeFatura: 'No compone la factura',
      transacoesEfetuadas: 'transacciones realizadas en el período',
      titulo: 'Facturas',
      codigoCliente: 'Código de cliente',
      situacao: 'Situación',
      mes: 'Mes',
      ano: 'Año',
      valorFatura: 'Monto de la factura',
      servicosUtilizados: 'Servicios utilizados',
      atualizadoSucesso: 'Factura actualizada correctamente!',
      empresa: 'Empresa',
      servicos: 'Servicios',
      servico: 'Servicio',
      quantidade: 'Cantidad usada',
      valorTotal: 'Valor total',
      detalhes: 'Detalles',
      dataEmissao: 'Fecha de emisión',
      numeroBoleto: 'Boleto No.',
      numeroFiscal: 'Factura no.',
      dataQuitacao: 'Fecha de alta',
      valorRecebido: 'Cantidad recibida',
      multa: '% multa',
      tipoRecebimento: 'Tipo de recibo',
      conta: 'Cuenta',
      mensalidade: 'Mensualidad',
      configuradas: 'Licencias configuradas',
      ativas: 'Licencias activas en base',
      valorLicenca: 'Derechos de licencia',
      valorMensalidade: 'Cuota mensual',
      resumo: 'Resumo',
      valorTotalServicos: 'Valor total de los servicios',
      valorTotalMensalidade: 'Cuota mensual total',
      valorTotalFatura: 'Importe total de la factura',
      servicosEmpresa: 'Servicio / Empresa',
      qtdUtilizada: 'Cantidad usada / Importe de exención',
      valorTarifa: 'Importe de la cuota / Valor total',
      detalhesFatura: 'Detalles de la factura',
      dialogTexto: 'Hay una factura abierta anterior para este cliente. ¿Realmente desea cerrar esta factura?',
      naoHaServicosCadastrados: 'No hay servicios registrados para esta factura',
      fechamentoFatura: 'Cierre de facturas',
      vencimentoFatura: 'Factura vencida',
      valorServicos: 'Valor de los servicios'
    },

    mensalidades: {
      titulo: 'Matrícula',
      atualizadoSucesso: 'La matrícula mensual se actualizó correctamente!',
      tarifasMensalidade: 'Cargos mensuales',
      tarifasArmazenamento: 'Tarifas de almacenamiento',
      valorMinimoMensalidade: 'Cuota mensual mínima',
      tarifaPadraoArmazenamento: 'Valor por GB'
    }
  },

  banking: {
    dashboard: {
      contatosBancarios: 'Contactos bancarios',
      paraComecarConfigure: 'Para comenzar, configure su cuenta bancaria',
      concederPermissao: 'Conceder permiso',
      ultimosXDias: 'Últimos {{dias}} días',
      voceNaoTemTransacoesNessePeriodo: 'No tiene transacciones en este período',
      movimentos: 'Movimientos',
      operacaoContato: 'Operación y contacto ',
      codigoAutenticacao: 'Código de autenticación'
    },

    contatosBancarios: {
      titulo: 'Contactos bancarios',
      novo: 'Nuevo contacto'
    },

    movimentos: {
      novoMovimento: 'Nuevo movimiento',
      confirmarMovimento: 'Confirmar movimiento',
      desejaRealmenteMovimentarValor: '¿De verdad quieres mover este valor??',
      selecionarTitulo: 'Seleccionar título'
    }
  },

  fiscal: {
    documentosFiscais: {
      titulo: 'Emisión de Documentos Fiscales',
      baixaManual: 'Descarga manual',
      descricaoItem: 'Descripción del artículo',
      selecionarDocumentoFiscalAutorizado: 'Solo puede seleccionar elementos con el estado "Autorizado".',

      cancelamento: {
        titulo: 'Cancelación',
        motivoCancelamento: 'Motivo de la cancelación',
        selecioneMotivo: 'Seleccione un motivo',
        descrevaMotivo: 'Describa el motivo de la cancelación',
        qtdNotasSelecionadas: 'Número de notas seleccionadas',
        canceladaSucesso: '¡Nota cancelada con éxito!'
      },

      filtros: {}
    },

    preferencias: {
      tributacoes: {
        titulo: 'Impuestos',
        novo: 'Nueva tributación',
        cadastradoSucesso: 'Fiscalidad registrada con éxito!',
        atualizadoSucesso: 'Fiscalidad actualizada con éxito!',
        removidoSucesso: 'Impuestos eliminados con éxito!',

        cnae: {
          novo: 'Nueva CNAE',
          adicionar: 'Agregar CNAE',
          codigoServicoPrestado: 'Cód. Servicio prestado',
          codigoServicoMunicipal: 'Cód. Servicio municipal',
          codigoTributacaoMunicipio: 'Cód. Tributación Municipal',
          codigoServicoFederal: 'Cód. Servicio federal',
          servPrestado: 'Serv. Previsto',
          servMunicipal: 'Serv. Municipal',
          servFederal: 'Serv. Federal',
          cadastradoSucesso: '¡CNAE se registró con éxito!',
          atualizadoSucesso: '¡CNAE actualizado con éxito!',
          removidoSucesso: '¡CNAE eliminado con éxito!'
        },

        aliquota: {
          aliquotaIss: 'Tasa ISS',
          aliquotaPis: 'Tasa de PIS',
          aliquotaCofins: 'Tarifa COFINS',
          aliquotaInss: 'Tasa INSS'
        },

        configuracaoDaEmpresa: {
          aliquotaIr: 'Tasa de infrarrojos',
          aliquotaCsll: 'Tasa de CSLL'
        },

        outrasAliquotas: {
          aliquotasFederais: 'Tasas Federales',
          aliquotasEstaduais: 'Tarifas estatales',
          aliquotasMunicipais: 'Tarifas municipales'
        },

        codigoFiscalDePrestacaoDeServicos: {
          cfpsIntramunicipal: 'CFPS intramunicipales',
          cfpsIntermunicipal: 'CFPS intermunicipales'
        },

        cfop: {
          novo: 'Nuevo CFOP',
          adicionar: 'Agregar CFOP',
          cadastradoSucesso: '¡CFOP se registró con éxito!',
          atualizadoSucesso: 'CFOP actualizado con éxito!',
          removidoSucesso: '¡CFOP eliminado con éxito!'
        }
      }
    }
  },

  assinaturaEletronica: {
    titulo: 'Firma electronica',
    novo: 'Solicitar suscripción',
    confirmarAssinatura: 'Confirmar suscripción',
    desejaAssinarTodos: '¿Realmente desea firmar todos los documentos seleccionados?',
    selecionarAguardandoContratados: 'Solo puede seleccionar elementos con un estado de "En espera de contratación".',
    configuracoes: 'Configuración de suscripción',
    detalhes: 'Detalles de suscripción',
    solicitadaSucesso: '¡Firma electrónica solicitada con éxito!',
    acoes: {
      enviadoSucesso: '¡Firma electrónica enviada con éxito!',
      reenviadoSucesso: '¡Firma electrónica reenviada con éxito!',
      canceladoSucesso: '¡Suscripción electrónica cancelada con éxito!',
      assinadoSucesso: '¡Documento firmado con éxito!'
    },
    configuracao: {
      salvaSucesso: '¡Configuración guardada con éxito!',
      atualizadaSucesso: '¡Configuración actualizada exitosamente!',
      contratadoPadrao: {
        cadastradoSucesso: '¡Contratista estándar registrado con éxito!',
        removidoSucesso: '¡El contratista predeterminado se eliminó con éxito!',
        atualizadoSucesso: '¡Contratista estándar actualizado con éxito!'
      },
      testemunhaPadrao: {
        cadastradoSucesso: 'Testigo estándar registrado con éxito!',
        removidoSucesso: '¡Testigo predeterminado se eliminó con éxito!',
        atualizadoSucesso: '¡El testigo predeterminado se actualizó con éxito!'
      }
    }
  },

  servicosContratacoes: {
    titulo: 'Servicios y Contratos'
  },

  configuracoes: {
    empresas: {
      titulo: 'Compañías',
      novo: 'Nueva compañia',
      cadastradoSucesso: 'Empresa registrada con éxito!',
      atualizadoSucesso: 'Empresa actualizada correctamente!',
      removidoSucesso: 'Empresa eliminada correctamente!',
      autorizacao: {
        titulo: 'Autorizaciones',
        novo: 'Nueva autorización',
        cadastradoSucesso: 'Autorización registrada con éxito!',
        atualizadoSucesso: 'Autorización actualizada correctamente!',
        removidoSucesso: 'Autorización eliminada correctamente!',
        autorizacaoDeFuncionamento: 'Permiso de funcionamiento',
        nucleoRegionalEducacao: 'Centro Educativo Regional',
        fundamentacaoLegal: 'Justificación legal',
        parecerCEE: 'Opinión de la CEE',
        ldben: 'LDBEN',
        dou: 'DOU',
        codigoSEEDCDE: 'Código SEED / CDE'
      },
      configuracao: {
        dataContratacao: 'La fecha del contrato',
        qtdeUsuarios: 'Número de usuarios',
        valorTarifa: 'Importe de la cuota',
        valorPorAluno: 'Valor por alumno',
        valorPorMedico: 'Valor por médico',
        valorTarifaNegociado: 'Valor de tarifa negociado',
        justificativa: 'Razón del cambio de tarifa',
        valorMensalidade: 'Cantidad mensual',
        valorMinimoMensalidade: 'Cuota mensual mínima',
        situacao: 'Situación',
        atributos: 'Atributos',
        endpoints: 'Endpoints',
        key: 'Key',
        value: 'Valor',
        url: 'URL',
        name: 'Name',
        metod: 'Metod',

        integracoes: {
          titulo: 'Integraciones',
          novaIntegracao: 'Nueva integracion',
          tarifa: 'Arancel',
          qtdeInsencao: 'Importe de exención',
          transferenciaAutomatica: 'Importe de exención',
          tarifasStone: 'Tasas de piedra',
          tarifasPraticadas: 'Tarifas practicadas en el período',
          taxaBoleto: 'Tarifa boleto',
          emailBoletosSemCobranca:
            'No se cobrarán los correos electrónicos por envío de boletos, pero sí se cobrará envío de SMS',

          cadastradoSucesso: 'Integración registrada con éxito!',
          atualizadoSucesso: 'Integración actualizada con éxito!',
          removidoSucesso: 'Integración eliminada con éxito!',

          contasBancarias: {
            titulo: 'Configuración del banco de recepción',
            novo: 'Nueva configuración del banco de recepción',
            editar: 'Editar configuración del banco de recepción',
            cadastradoSucesso: 'Configuración del banco de recepción registrada con éxito!',
            atualizadoSucesso: 'Configuración del banco de recepción actualizada correctamente!',
            removidoSucesso: 'Configuración del banco de recepción eliminada con éxito!',
            mensagem: 'Al activar esta cuenta, se desactivará la cuenta anterior.!'
          }
        }
      }
    },

    configuracoesDoFinanceiro: {
      preferenciasGerais: 'Preferencias generales',
      configuracoesFinanceiras: 'Preferencias generales',
      formaDePagamentoPadrao: 'Método de pago estándar',
      contaBancariaPadrao: 'Cuenta bancaria predeterminada',
      apresentarOrcamentoNoContas: 'Mostrar presupuesto en cuentas por cobrar',
      diasVencimentoOrcamentoCompras: 'Días para el vencimiento del presupuesto de compra',
      diasVencimentoOrcamentoVenda: 'Días para el vencimiento del presupuesto de ventas',
      modeloDeDesconto: 'Plantilla de descuento',
      realizarDescontoCascata: 'Realizar un descuento en cascada',
      periodoFechamentoObrigatorioCaixa: 'Periodo de cierre obligatorio del cajero'
    },

    preferencias: {
      titulo: 'Preferencias',
      atualizadoSucesso: 'Preferencia actualizada correctamente!',

      aplicativo: {
        corAplicativo: 'Color de la aplicación',
        corAlteradaSucesso: 'El color cambió con éxito',
        logo: 'Logo',
        logoCadastradoSucesso: 'Logotipo registrado con éxito',
        ativarSincronizacao: 'Activar la sincronización',
        sincronizacaoEmAndamento: 'Sincronización en progreso',
        sincronizadoSucesso: 'Sincronizado con éxito',
        alterarImagem: 'Cambiar imagen'
      },

      integracoes: {
        titulo: 'Integraciones',
        contaBancariaPadrao: 'Cuenta bancaria predeterminada',
        observacaoComplementar: 'Observación complementaria',
        agruparValorParcelas: 'Agrupar el valor de las cuotas seleccionadas'
      }
    },

    templates: {
      sms: {
        titulo: 'Plantillas de SMS',
        novo: 'Nueva plantilla de SMS',
        cadastradoSucesso: 'Plantilla de SMS registrada con éxito!',
        atualizadoSucesso: 'Plantilla de SMS actualizada correctamente!',
        removidoSucesso: 'Plantilla de SMS eliminada correctamente!',
        escrevaMensagem: 'Escribe el mensaje',
        adicionarTags: 'Agregar etiquetas:',
        mensagemDeTexto: 'Mensaje de texto',
        caracteresRestantes: '{{value}} caracteres restantes',
        funcionalidade: 'Funcionalidad'
      },
      email: {
        titulo: 'Plantillas de correo electrónico',
        novo: 'Nueva plantilla',
        novoTitulo: 'Nueva plantilla de correo electrónico',
        cadastradoSucesso: 'Plantilla de correo electrónico registrada correctamente!',
        atualizadoSucesso: 'Plantilla de correo electrónico actualizada correctamente.',
        removidoSucesso: '¡Plantilla de correo electrónico eliminada correctamente!',
        escrevaMensagem: 'Escribe el mensaje',
        adicionarTags: 'Agregar etiquetas:',
        mensagemDeTexto: 'Mensaje de texto',
        caracteresRestantes: '{{value}} caracteres restantes',
        funcionalidade: 'Funcionalidad',
        assunto: 'Tema'
      }
    },

    politicasDeAcesso: {
      titulo: 'Políticas de acceso',
      novo: 'Nuevo perfil',
      cadastradoSucesso: 'Perfil registrado con éxito!',
      atualizadoSucesso: 'Perfil actualizado con éxito!',
      removidoSucesso: 'Perfil eliminado correctamente!',
      acessoAoSistema: 'Acceso al sistema',

      recursos: {
        titulo: 'Recursos'
      },

      usuarios: {
        titulo: 'Usuarios',
        novo: 'Agregar usuario',
        incluirPorCargo: 'Incluir usuarios por puesto de trabajo',
        incluirPorTipo: 'Incluir usuarios por tipo de trabajo',
        cadastradoSucesso: '¡Usuarios vinculados al perfil!',
        removidoSucesso: '¡Usuario eliminado correctamente!',
        atualizadoSucesso: '¡Usuario actualizado correctamente!',
        jaVinculadoAoPerfil: 'El usuario ya está vinculado a este perfil',
        confirmarUsuarioComVinculo:
          'Usuario {{usuarioNome}} ya está vinculado al perfil de acceso {{perfilOrigemNome}}. ¿Quieres cambiarlo al perfil? {{perfilDestinoNome}}?'
      }
    },

    notificacoes: {
      titulo: 'Notificaciones del sistema',
      novo: 'Nueva notificación',
      cadastradoSucesso: 'Notificación registrada correctamente!',
      atualizadoSucesso: 'Notificación actualizada correctamente!',
      removidoSucesso: 'Notificación eliminada correctamente!',
      canaisEnvio: {
        titulo: 'Canales de envío',
        novo: 'Nuevo canal de envío',
        cadastradoSucesso: 'Canal de envío registrado con éxito!',
        removidoSucesso: 'Canal de envío eliminado correctamente!',
        tipoCanal: 'Tipo de canal',
        selecione: 'Seleccione tipo',
        selecioneTemplate: 'Seleccionar plantilla',
        nomeTemplate: 'Nombre de la plantilla'
      },
      condicoesEnvio: {
        titulo: 'Cuando enviar',
        novo: 'Nueva condición de envío',
        cadastradoSucesso: 'Condición de envío registrada con éxito!',
        removidoSucesso: 'Condición de envío eliminada con éxito!',
        selecioneCondicao: 'Seleccione una condición de envío',
        intervalo: {
          hora: 'Romper en horas',
          dia: 'Intervalo en días ',
          semana: 'Romper en semanas',
          mes: 'Intervalo en meses',
          horaEnvio: 'Tiempo de envío'
        },
        helperText: {
          ANTES_DO_EVENTO: 'Le permite seleccionar un período para enviar notificaciones antes del evento',
          DEPOIS_DO_EVENTO: 'Le permite seleccionar un período para enviar notificaciones después del evento',
          DIA_DO_EVENTO: 'Le permite seleccionar la hora para enviar notificaciones el día del evento',
          CLIENTES: 'Las notificaciones se enviarán a todos los clientes activos.',
          FORNECEDORES: 'Se enviarán notificaciones a todos los proveedores activos',
          FUNCIONARIOS: 'Se enviarán notificaciones a todos los empleados activos',
          INDIVIDUAL: 'Seleccione a quién se debe enviar la notificación'
        }
      },
      destinatarios: {
        titulo: 'Destinatarios',
        novo: 'Nuevo destinatario o grupo de destinatarios',
        cadastradoSucesso: 'Destinatario agregado exitosamente!',
        removidoSucesso: 'Destinatario eliminado correctamente!',
        selecione: 'Seleccionar destinatario',
        informeDestinatario: 'Informar al destinatario'
      }
    }
  },

  restricoes: {
    nova: 'Nueva restricción',
    restricaoCadastradaSucesso: 'Restricción registrada correctamente!',
    acessoAoSistema: 'Hola, actualmente su acceso al sistema es',
    somenteLeitura: 'Solo Lectura!',
    consulteAtendimento: 'Ver servicio al cliente para más información.',
    contateNos: 'Contáctenos'
  },

  bandeiras: {
    nova: 'Nueva bandera',
    bandeiraCadastradaSucesso: 'Bandera registrada correctamente!'
  },

  credenciadoras: {
    nova: 'Nuevo adquirente',
    credenciadoraCadastradaSucesso: 'Acreedor registrado exitosamente!'
  },

  auth: {
    login: {
      titulo: 'Bienvenido al',
      subtitulo: 'Iniciar sesión para continuar.',
      login: 'Acceso',
      senha: 'Contraseña',
      button: 'Acceso',
      link: 'Olvide mi contraseña',
      sair: 'Salir',
      usuarioNaoEncontrado: 'Usuario no encontrado',
      usuarioNaoEncontradoDialog: 'Comuníquese con el administrador para obtener más información.'
    },

    domain: {
      titulo: 'Bienvenido al',
      subtitulo: 'Para que podamos identificarlo, ingrese la URL de su empresa.',
      domain: 'suempresa',
      button: 'Próximo',
      link: 'Olvidé mi URL'
    },

    changePassword: {
      titulo: 'Cambiar contraseña',
      tituloPrimeiroAcesso: 'Crea una contraseña',
      subtitulo: 'Piensa en algo realmente fácil de recordar',
      password: 'Nueva contraseña',
      passwordCheck: 'Repite la contraseña',
      button: 'Cambio',
      link: 'Atrás para iniciar sesión',
      senhaAlterada: '¡Contraseña alterada con éxito!',
      linkExpirado: '¡El enlace actual ha caducado o ya no es válido!',
      soliciteUmNovoLink: 'Por favor, solicite un nuevo enlace.',
      redirecionado: 'Será redirigido a la pantalla de inicio de sesión.'
    },

    forgotPassword: {
      titulo: 'Olvide mi contraseña',
      subtitulo: 'Se enviará un código de recuperación a su correo electrónico.',
      subtituloTipoLink:
        'Se enviará un correo electrónico de recuperación de contraseña al correo electrónico proporcionado.',
      email: 'Correo electrónico',
      button: 'Para recuperar',
      link: 'Atrás para iniciar sesión',
      codigoEnviado: 'Código de recuperación enviado al correo electrónico informado.',
      linkEnviado: 'Se ha enviado un enlace de recuperación al correo electrónico proporcionado.'
    }
  },

  setup: {
    nomeCompleto: 'Nombre de la empresa',
    verticalMercado: 'Vertical de mercado',
    url: 'URL del cliente',
    cnpj: 'CNPJ',
    codigoContrato: 'Código de contrato',
    email: 'Correo electrónico',
    senha: 'Contraseña',
    confirmaSenha: 'Confirmar seña',

    licencas: {
      titulo: 'Licencias',
      licenca: 'Tipo de licencia',
      quantidade: 'La cantidad'
    },

    modulos: {
      titulo: 'Módulos'
    },

    recursos: {
      titulo: 'Recursos'
    },

    conclusao: {
      titulo: 'Conclusión',
      modulos: 'Módulos',
      licencas: 'Licencias',
      encaminhar: 'Hacia adelante',
      email: 'Correo electrónico',
      assunto: 'Tema',
      enviarEmail: 'Mándame un correo electrónico',
      voltar: 'Volver a empezar',
      'ir-para-o-sistema': 'Ir al sistema'
    }
  },
  impressao: {
    formatoImpressao: 'Formato de impresión',
    orientacaoRelatorio: 'Orientación del informe',
    tipo: {
      pdf: 'PDF',
      csv: 'CSV',
      html: 'HTML'
    },
    orientacao: {
      retrato: 'RETRATO',
      paisagem: 'PAISAJE'
    }
  }
}
