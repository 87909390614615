import { useState, useEffect, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { queryCache } from 'react-query'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { createContainer } from '@sponte/lib-utils/dist/hooks/useContainer'
import { useSnackbar } from '@sponte/lib-utils/dist/hooks/useSnackbar'

import {
  useQueryContasConfiguracoesControllerListar,
  useMutationContasConfiguracoesControllerAtualizar
} from 'api/saude'

import { useAuth } from 'providers/auth'

function useConfigsHook() {
  const { t } = useTranslation()
  const { createSnackbar } = useSnackbar()

  const { eu } = useAuth()

  const [atualizarConfiguracao] = useMutationContasConfiguracoesControllerAtualizar(
    {},
    {
      onSuccess: () => {
        createSnackbar('success', t('configuracoes:preferencias.atualizadoSucesso'))

        queryCache.invalidateQueries(useQueryContasConfiguracoesControllerListar.queryKey)
      }
    }
  )

  const { data } = useQueryContasConfiguracoesControllerListar(
    {
      itensPorPagina: 9999
    },
    {
      suspense: false,
      enabled: !!eu
    }
  )

  const configs = getSafe(data, 'itens', [])

  useEffect(
    () => {
      if (eu) {
        queryCache.invalidateQueries(useQueryContasConfiguracoesControllerListar.queryKey)
      }
    },
    // eslint-disable-next-line
    [eu]
  )

  useEffect(
    () => {
      if (!eu && window.location.pathname.includes('admin')) {
        window.sessionStorage.setItem(process.env.REACT_APP_AUTH_URL_HISTORY, window.location.pathname)
      }
    },
    // eslint-disable-next-line
    []
  )

  const getConfig = useCallback(
    (key) => {
      return configs.find((config) => config.chave === key)
    },
    [configs]
  )

  const getConfigValue = useCallback(
    (key, fallback = null) => {
      return getSafe(getConfig(key), 'valor', fallback)
    },
    [getConfig]
  )

  const setConfig = useCallback(
    async (key, value) => {
      if (value === null || value === undefined) {
        return
      }

      const config = getConfig(key)

      const novoValor = typeof value === 'function' ? value(config) : value

      if (config === novoValor) {
        return
      }

      atualizarConfiguracao([{ id: config.id }, {}, { novoValor }])
    },
    [getConfig, atualizarConfiguracao]
  )

  return {
    configs,
    getConfig: getConfigValue,
    setConfig
  }
}

export const [ConfigsProvider, useConfigs] = createContainer(useConfigsHook)

export function useConfig(key, fallback) {
  const { getConfig, setConfig } = useConfigs()

  const [storedConfig, setStoredConfig] = useState(getConfig(key, fallback))

  const set = useCallback(
    (value) => {
      setStoredConfig(value)
      setConfig(key, value)
    },
    [key, setConfig, setStoredConfig]
  )

  return [storedConfig, set]
}
