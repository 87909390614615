/* eslint-disable */
/* tslint:disable */
import ky from 'ky'
import { useQuery, useMutation } from 'react-query'

let api = ky.create({
  "prefixUrl": "https://saude-api.staging.sponte-demo.com.br",
  "throwHttpErrors": false,
  "retry": {
    "statusCodes": [
      401
    ]
  }
})

export const getApi = () => api

export const setApi = (newApi) => {
  api = newApi
}

export const extendApi = (options) => {
  api = getApi().extend(options)
}

const requestFn = async ({ url, method, pathParams, queryParams, ...rest }) => {
  const urlPathParams = url.match(/{([^}]+)}/g)

  if (urlPathParams) {
    url = urlPathParams.reduce((acc, param) => acc.replace(param, pathParams[param.replace(/{|}/g, '')]), url)
  } else {
    queryParams = pathParams
  }

  if (url.charAt(0) === '/') {
    url = url.replace('/', '')
  }

  const response = await api(url, {
    method,
    ...rest,
    searchParams: {
      ...(rest.searchParams || {}),
      ...queryParams
    }
  })

  let data

  try {
    const contentType = (response.headers.get('content-type') || '').split('; ')[0]

    const responseType =
      {
        'application/json': 'json',
        'application/pdf': 'blob',
        'application/octet-stream': 'blob'
      }[contentType] || 'text'

    data = await response[responseType]()
  } catch (e) {
    data = e.message
  }

  if (!response.ok) {
    const error = {
      data,
      status: response.status,
      message: `Failed to fetch: ${response.status} ${response.statusText}`
    }

    throw error
  }

  return data
}

const queryFn = (options = {}) => (url, pathParams = {}, queryParams = {}) =>
  requestFn({
    url,
    method: 'get',
    pathParams,
    queryParams,
    ...options
  })

const mutationFn = (
  method,
  url,
  pathParams = {},
  queryParams = {},
  options = {}
) => (body = {}) => {
  if (Array.isArray(body)) {
    pathParams = { ...pathParams, ...(body[0] || {}) }
    queryParams = { ...queryParams, ...(body[1] || {}) }
    options = { ...options, ...(body[3] || {}) }
    body = body[2]
  }

  const request = {
    url,
    method,
    pathParams,
    queryParams,
    ...options
  }

  if (method !== 'delete') {
    try {
      request[body.toString() === '[object FormData]' ? 'body' : 'json'] = body
    } catch(e) {
    }
  }

  return requestFn(request)
}

export const queryAcrescimoParcelaLinkPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/acrescimo-parcela-link-pagamento', queryParams)
export const useQueryAcrescimoParcelaLinkPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/acrescimo-parcela-link-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAcrescimoParcelaLinkPagamentoControllerListar.queryKey = '/api/v1/acrescimo-parcela-link-pagamento'

export const queryAcrescimoParcelaLinkPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/acrescimo-parcela-link-pagamento/{id}', pathParams)
export const useQueryAcrescimoParcelaLinkPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/acrescimo-parcela-link-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAcrescimoParcelaLinkPagamentoControllerObterPorId.queryKey = '/api/v1/acrescimo-parcela-link-pagamento/{id}'

export const queryAcrescimoParcelaLinkPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/acrescimo-parcela-link-pagamento/{chave}', pathParams)
export const useQueryAcrescimoParcelaLinkPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/acrescimo-parcela-link-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryAcrescimoParcelaLinkPagamentoControllerObterPorChave.queryKey = '/api/v1/acrescimo-parcela-link-pagamento/{chave}'

export const queryAgendamentosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/agendamentos', queryParams)
export const useQueryAgendamentosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAgendamentosControllerListar.queryKey = '/api/v1/agendamentos'

export const mutationAgendamentosControllerCadastrar = (options) => mutationFn('post', '/api/v1/agendamentos', {}, {}, options)
export const useMutationAgendamentosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/agendamentos', {}, {}, options), config)

export const queryAgendamentosControllerObterHorariosOcupadosAgendamentoReplicado = (queryParams, options) => queryFn(options)('/api/v1/agendamentos/horarios-ocupados', queryParams)
export const useQueryAgendamentosControllerObterHorariosOcupadosAgendamentoReplicado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/horarios-ocupados', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAgendamentosControllerObterHorariosOcupadosAgendamentoReplicado.queryKey = '/api/v1/agendamentos/horarios-ocupados'

export const queryAgendamentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/agendamentos/{id}', pathParams)
export const useQueryAgendamentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosControllerObterPorId.queryKey = '/api/v1/agendamentos/{id}'

export const mutationAgendamentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/agendamentos/{id}', pathParams, {}, options)
export const useMutationAgendamentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/agendamentos/{id}', pathParams, {}, options), config)

export const mutationAgendamentosControllerDeletar = (pathParams, queryParams, options) => mutationFn('delete', '/api/v1/agendamentos/{id}', pathParams, queryParams, options)
export const useMutationAgendamentosControllerDeletar = (pathParams, queryParams, config, options) => useMutation(mutationFn('delete', '/api/v1/agendamentos/{id}', pathParams, queryParams, options), config)

export const mutationAgendamentosControllerAtualizarAgendamento = (pathParams, options) => mutationFn('patch', '/api/v1/agendamentos/{id}', pathParams, {}, options)
export const useMutationAgendamentosControllerAtualizarAgendamento = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/agendamentos/{id}', pathParams, {}, options), config)

export const queryAgendamentosControllerListarAgendamentoFuncionarios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/agendamentos/{id}/funcionarios', pathParams, queryParams)
export const useQueryAgendamentosControllerListarAgendamentoFuncionarios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/{id}/funcionarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosControllerListarAgendamentoFuncionarios.queryKey = '/api/v1/agendamentos/{id}/funcionarios'

export const mutationAgendamentosControllerCadastrarAgendamentoFuncionario = (pathParams, options) => mutationFn('post', '/api/v1/agendamentos/{id}/funcionarios', pathParams, {}, options)
export const useMutationAgendamentosControllerCadastrarAgendamentoFuncionario = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/agendamentos/{id}/funcionarios', pathParams, {}, options), config)

export const mutationAgendamentosControllerDeletarAgendamentoFuncionario = (pathParams, options) => mutationFn('delete', '/api/v1/agendamentos/{id}/funcionarios/{agendamentoFuncionarioId}', pathParams, {}, options)
export const useMutationAgendamentosControllerDeletarAgendamentoFuncionario = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/agendamentos/{id}/funcionarios/{agendamentoFuncionarioId}', pathParams, {}, options), config)

export const queryAgendamentosControllerListarAgendamentoClientes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/agendamentos/{id}/clientes', pathParams, queryParams)
export const useQueryAgendamentosControllerListarAgendamentoClientes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/{id}/clientes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosControllerListarAgendamentoClientes.queryKey = '/api/v1/agendamentos/{id}/clientes'

export const mutationAgendamentosControllerCadastrarAgendamentoCliente = (pathParams, options) => mutationFn('post', '/api/v1/agendamentos/{id}/clientes', pathParams, {}, options)
export const useMutationAgendamentosControllerCadastrarAgendamentoCliente = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/agendamentos/{id}/clientes', pathParams, {}, options), config)

export const mutationAgendamentosControllerDeletarAgendamentoCliente = (pathParams, options) => mutationFn('delete', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}', pathParams, {}, options)
export const useMutationAgendamentosControllerDeletarAgendamentoCliente = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}', pathParams, {}, options), config)

export const mutationAgendamentosControllerAtualizarAgendamentoCliente = (pathParams, options) => mutationFn('put', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}', pathParams, {}, options)
export const useMutationAgendamentosControllerAtualizarAgendamentoCliente = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}', pathParams, {}, options), config)

export const mutationAgendamentosControllerAtualizarJornadaAtendimento = (pathParams, options) => mutationFn('put', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}/jornada-atendimento', pathParams, {}, options)
export const useMutationAgendamentosControllerAtualizarJornadaAtendimento = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}/jornada-atendimento', pathParams, {}, options), config)

export const mutationAgendamentosControllerAtualizarJornadaAtendimentoTeste = (pathParams, options) => mutationFn('patch', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}/jornada-atendimento', pathParams, {}, options)
export const useMutationAgendamentosControllerAtualizarJornadaAtendimentoTeste = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}/jornada-atendimento', pathParams, {}, options), config)

export const queryAgendamentosControllerObterHistoricoAgendamentoCliente = (pathParams, queryParams, options) => queryFn(options)('/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}/historico', pathParams, queryParams)
export const useQueryAgendamentosControllerObterHistoricoAgendamentoCliente = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}/historico', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.agendamentoClienteId, ...config }
})
useQueryAgendamentosControllerObterHistoricoAgendamentoCliente.queryKey = '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}/historico'

export const queryAgendamentosControllerListarClientesDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/agendamentos/{id}/clientes-disponiveis', pathParams, queryParams)
export const useQueryAgendamentosControllerListarClientesDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/{id}/clientes-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosControllerListarClientesDisponiveis.queryKey = '/api/v1/agendamentos/{id}/clientes-disponiveis'

export const queryAgendamentosControllerListarFuncionariosDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/agendamentos/{id}/funcionarios-disponiveis', pathParams, queryParams)
export const useQueryAgendamentosControllerListarFuncionariosDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/{id}/funcionarios-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosControllerListarFuncionariosDisponiveis.queryKey = '/api/v1/agendamentos/{id}/funcionarios-disponiveis'

export const queryAgendamentosControllerListarAgendamentoEquipamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/agendamentos/{id}/equipamentos', pathParams, queryParams)
export const useQueryAgendamentosControllerListarAgendamentoEquipamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/{id}/equipamentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosControllerListarAgendamentoEquipamentos.queryKey = '/api/v1/agendamentos/{id}/equipamentos'

export const mutationAgendamentosControllerCadastrarAgendamentoEquipamento = (pathParams, options) => mutationFn('post', '/api/v1/agendamentos/{id}/equipamentos', pathParams, {}, options)
export const useMutationAgendamentosControllerCadastrarAgendamentoEquipamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/agendamentos/{id}/equipamentos', pathParams, {}, options), config)

export const mutationAgendamentosControllerDeletarAgendamentoEquipamento = (pathParams, options) => mutationFn('delete', '/api/v1/agendamentos/{id}/equipamentos/{agendamentoEquipamentoId}', pathParams, {}, options)
export const useMutationAgendamentosControllerDeletarAgendamentoEquipamento = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/agendamentos/{id}/equipamentos/{agendamentoEquipamentoId}', pathParams, {}, options), config)

export const queryAgendamentosControllerListarEquipamentosDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/agendamentos/{id}/equipamentos-disponiveis', pathParams, queryParams)
export const useQueryAgendamentosControllerListarEquipamentosDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/{id}/equipamentos-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosControllerListarEquipamentosDisponiveis.queryKey = '/api/v1/agendamentos/{id}/equipamentos-disponiveis'

export const mutationAgendamentosControllerVincularSituacaoTag = (pathParams, options) => mutationFn('put', '/api/v1/agendamentos/{id}/tags/{situacaoAgendamentoTagId}', pathParams, {}, options)
export const useMutationAgendamentosControllerVincularSituacaoTag = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/agendamentos/{id}/tags/{situacaoAgendamentoTagId}', pathParams, {}, options), config)

export const mutationAgendamentosControllerDesvincularSituacaoTag = (pathParams, options) => mutationFn('delete', '/api/v1/agendamentos/{id}/tags/{situacaoAgendamentoTagId}', pathParams, {}, options)
export const useMutationAgendamentosControllerDesvincularSituacaoTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/agendamentos/{id}/tags/{situacaoAgendamentoTagId}', pathParams, {}, options), config)

export const mutationAgendamentosControllerVincularSituacaoTagCliente = (pathParams, options) => mutationFn('put', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}/tags/{situacaoAgendamentoTagId}', pathParams, {}, options)
export const useMutationAgendamentosControllerVincularSituacaoTagCliente = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}/tags/{situacaoAgendamentoTagId}', pathParams, {}, options), config)

export const mutationAgendamentosControllerDesvincularSituacaoTagCliente = (pathParams, options) => mutationFn('delete', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}/tags/{situacaoAgendamentoTagId}', pathParams, {}, options)
export const useMutationAgendamentosControllerDesvincularSituacaoTagCliente = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/agendamentos/{id}/clientes/{agendamentoClienteId}/tags/{situacaoAgendamentoTagId}', pathParams, {}, options), config)

export const queryAgendamentosControllerListarMotivosCancelamento = (queryParams, options) => queryFn(options)('/api/v1/agendamentos/motivos-de-cancelamento', queryParams)
export const useQueryAgendamentosControllerListarMotivosCancelamento = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/motivos-de-cancelamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAgendamentosControllerListarMotivosCancelamento.queryKey = '/api/v1/agendamentos/motivos-de-cancelamento'

export const mutationAgendamentosControllerCadastrarMotivoCancelamento = (options) => mutationFn('post', '/api/v1/agendamentos/motivos-de-cancelamento', {}, {}, options)
export const useMutationAgendamentosControllerCadastrarMotivoCancelamento = (config, options) => useMutation(mutationFn('post', '/api/v1/agendamentos/motivos-de-cancelamento', {}, {}, options), config)

export const queryAgendamentosControllerObterMotivoCancelamentoPorId = (pathParams, options) => queryFn(options)('/api/v1/agendamentos/motivos-de-cancelamento/{id}', pathParams)
export const useQueryAgendamentosControllerObterMotivoCancelamentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/motivos-de-cancelamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosControllerObterMotivoCancelamentoPorId.queryKey = '/api/v1/agendamentos/motivos-de-cancelamento/{id}'

export const mutationAgendamentosControllerAtualizarMotivoCancelamento = (pathParams, options) => mutationFn('put', '/api/v1/agendamentos/motivos-de-cancelamento/{id}', pathParams, {}, options)
export const useMutationAgendamentosControllerAtualizarMotivoCancelamento = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/agendamentos/motivos-de-cancelamento/{id}', pathParams, {}, options), config)

export const mutationAgendamentosControllerDeletarMotivoCancelamento = (pathParams, options) => mutationFn('delete', '/api/v1/agendamentos/motivos-de-cancelamento/{id}', pathParams, {}, options)
export const useMutationAgendamentosControllerDeletarMotivoCancelamento = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/agendamentos/motivos-de-cancelamento/{id}', pathParams, {}, options), config)

export const mutationAgendamentosControllerCadastrarMotivoCancelamentoConta = (pathParams, options) => mutationFn('post', '/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas', pathParams, {}, options)
export const useMutationAgendamentosControllerCadastrarMotivoCancelamentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas', pathParams, {}, options), config)

export const queryAgendamentosControllerListarMotivoCancelamentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas', pathParams, queryParams)
export const useQueryAgendamentosControllerListarMotivoCancelamentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosControllerListarMotivoCancelamentoContas.queryKey = '/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas'

export const queryAgendamentosControllerObterMotivoCancelamentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas/{contaId}', pathParams)
export const useQueryAgendamentosControllerObterMotivoCancelamentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryAgendamentosControllerObterMotivoCancelamentoContaPorId.queryKey = '/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas/{contaId}'

export const mutationAgendamentosControllerAtualizarMotivoCancelamentoConta = (pathParams, options) => mutationFn('put', '/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationAgendamentosControllerAtualizarMotivoCancelamentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryAgendamentosControllerListarMotivoCancelamentoContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryAgendamentosControllerListarMotivoCancelamentoContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosControllerListarMotivoCancelamentoContasDisponiveis.queryKey = '/api/v1/agendamentos/motivos-de-cancelamento/{id}/contas-disponiveis'

export const queryAgendamentosClientesControllerListarClientesPassiveisAtendimento = (queryParams, options) => queryFn(options)('/api/v1/agendamentos-clientes/passiveis-atendimento', queryParams)
export const useQueryAgendamentosClientesControllerListarClientesPassiveisAtendimento = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos-clientes/passiveis-atendimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAgendamentosClientesControllerListarClientesPassiveisAtendimento.queryKey = '/api/v1/agendamentos-clientes/passiveis-atendimento'

export const queryAgendamentosClientesControllerListarClientesAtendidos = (queryParams, options) => queryFn(options)('/api/v1/agendamentos-clientes/atendidos', queryParams)
export const useQueryAgendamentosClientesControllerListarClientesAtendidos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos-clientes/atendidos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAgendamentosClientesControllerListarClientesAtendidos.queryKey = '/api/v1/agendamentos-clientes/atendidos'

export const queryAgendamentosClientesControllerListarClientesPreAtendimentoRetorno = (queryParams, options) => queryFn(options)('/api/v1/agendamentos-clientes/pre-atendimento-retorno', queryParams)
export const useQueryAgendamentosClientesControllerListarClientesPreAtendimentoRetorno = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos-clientes/pre-atendimento-retorno', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAgendamentosClientesControllerListarClientesPreAtendimentoRetorno.queryKey = '/api/v1/agendamentos-clientes/pre-atendimento-retorno'

export const queryAgendamentosClientesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/agendamentos-clientes/{id}', pathParams)
export const useQueryAgendamentosClientesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos-clientes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosClientesControllerObterPorId.queryKey = '/api/v1/agendamentos-clientes/{id}'

export const mutationAgendamentosClientesControllerAtualizarAgendamentoClienteConvenio = (pathParams, options) => mutationFn('put', '/api/v1/agendamentos-clientes/{id}', pathParams, {}, options)
export const useMutationAgendamentosClientesControllerAtualizarAgendamentoClienteConvenio = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/agendamentos-clientes/{id}', pathParams, {}, options), config)

export const mutationAgendamentosClientesControllerCadastrarSecaoRespostaTemporaria = (pathParams, options) => mutationFn('post', '/api/v1/agendamentos-clientes/{id}/secoes-resposta-tmp', pathParams, {}, options)
export const useMutationAgendamentosClientesControllerCadastrarSecaoRespostaTemporaria = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/agendamentos-clientes/{id}/secoes-resposta-tmp', pathParams, {}, options), config)

export const mutationAgendamentosClientesControllerCadastrarRespostaTemporaria = (pathParams, options) => mutationFn('put', '/api/v1/agendamentos-clientes/{id}/secoes-resposta/{secaoId}/campo/{campoModeloId}/resposta', pathParams, {}, options)
export const useMutationAgendamentosClientesControllerCadastrarRespostaTemporaria = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/agendamentos-clientes/{id}/secoes-resposta/{secaoId}/campo/{campoModeloId}/resposta', pathParams, {}, options), config)

export const mutationAgendamentosClientesControllerDeletarSecaoResposta = (pathParams, options) => mutationFn('delete', '/api/v1/agendamentos-clientes/{id}/secoes-resposta/{secaoId}', pathParams, {}, options)
export const useMutationAgendamentosClientesControllerDeletarSecaoResposta = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/agendamentos-clientes/{id}/secoes-resposta/{secaoId}', pathParams, {}, options), config)

export const mutationAgendamentosClientesControllerAtualizarRespostaCampoModeloProntuarioCalculoPreAtendimento = (pathParams, options) => mutationFn('put', '/api/v1/agendamentos-clientes/secoes-resposta/{secaoId}/campo/{campoModeloId}/resposta', pathParams, {}, options)
export const useMutationAgendamentosClientesControllerAtualizarRespostaCampoModeloProntuarioCalculoPreAtendimento = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/agendamentos-clientes/secoes-resposta/{secaoId}/campo/{campoModeloId}/resposta', pathParams, {}, options), config)

export const queryAgendamentosClientesControllerListarSecoesDisponiveisPreAtendimento = (queryParams, options) => queryFn(options)('/api/v1/agendamentos-clientes/secoes-disponiveis-preatendimento-retorno', queryParams)
export const useQueryAgendamentosClientesControllerListarSecoesDisponiveisPreAtendimento = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos-clientes/secoes-disponiveis-preatendimento-retorno', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAgendamentosClientesControllerListarSecoesDisponiveisPreAtendimento.queryKey = '/api/v1/agendamentos-clientes/secoes-disponiveis-preatendimento-retorno'

export const queryAgendamentosClientesControllerObterSecoesRespostasPreAtendimento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/agendamentos-clientes/{id}/preatendimento-respostas', pathParams, queryParams)
export const useQueryAgendamentosClientesControllerObterSecoesRespostasPreAtendimento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos-clientes/{id}/preatendimento-respostas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosClientesControllerObterSecoesRespostasPreAtendimento.queryKey = '/api/v1/agendamentos-clientes/{id}/preatendimento-respostas'

export const queryAgendamentosFuncionariosControllerObterAgendamentoFuncionarioPorId = (pathParams, options) => queryFn(options)('/api/v1/agendamentos-funcionarios/{id}', pathParams)
export const useQueryAgendamentosFuncionariosControllerObterAgendamentoFuncionarioPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/agendamentos-funcionarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAgendamentosFuncionariosControllerObterAgendamentoFuncionarioPorId.queryKey = '/api/v1/agendamentos-funcionarios/{id}'

export const mutationAgendamentosFuncionariosControllerAtualizarAgendamentoFuncionario = (pathParams, options) => mutationFn('put', '/api/v1/agendamentos-funcionarios/{id}', pathParams, {}, options)
export const useMutationAgendamentosFuncionariosControllerAtualizarAgendamentoFuncionario = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/agendamentos-funcionarios/{id}', pathParams, {}, options), config)

export const mutationAlergiaControllerCadastrarAlergia = (options) => mutationFn('post', '/api/v1/alergias', {}, {}, options)
export const useMutationAlergiaControllerCadastrarAlergia = (config, options) => useMutation(mutationFn('post', '/api/v1/alergias', {}, {}, options), config)

export const queryAlergiaControllerListarAlergias = (queryParams, options) => queryFn(options)('/api/v1/alergias', queryParams)
export const useQueryAlergiaControllerListarAlergias = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/alergias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAlergiaControllerListarAlergias.queryKey = '/api/v1/alergias'

export const mutationAlergiaControllerDeletarAlergia = (pathParams, options) => mutationFn('delete', '/api/v1/alergias/{id}', pathParams, {}, options)
export const useMutationAlergiaControllerDeletarAlergia = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/alergias/{id}', pathParams, {}, options), config)

export const mutationAlergiaControllerCadastrarAlergiaConta = (pathParams, options) => mutationFn('post', '/api/v1/alergias/{id}/contas', pathParams, {}, options)
export const useMutationAlergiaControllerCadastrarAlergiaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/alergias/{id}/contas', pathParams, {}, options), config)

export const queryAlergiaControllerListarAlergiaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/alergias/{id}/contas', pathParams, queryParams)
export const useQueryAlergiaControllerListarAlergiaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/alergias/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAlergiaControllerListarAlergiaContas.queryKey = '/api/v1/alergias/{id}/contas'

export const queryAlergiaControllerObterAlergiaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/alergias/{id}/contas/{contaId}', pathParams)
export const useQueryAlergiaControllerObterAlergiaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/alergias/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryAlergiaControllerObterAlergiaContaPorId.queryKey = '/api/v1/alergias/{id}/contas/{contaId}'

export const mutationAlergiaControllerAtualizarAlergiaConta = (pathParams, options) => mutationFn('put', '/api/v1/alergias/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationAlergiaControllerAtualizarAlergiaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/alergias/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryAlergiaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/alergias/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryAlergiaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/alergias/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAlergiaControllerListarContasDisponiveis.queryKey = '/api/v1/alergias/{id}/contas-disponiveis'

export const queryArquivosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/arquivos/{id}', pathParams)
export const useQueryArquivosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryArquivosControllerObterPorId.queryKey = '/api/v1/arquivos/{id}'

export const mutationArquivosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/arquivos/{id}', pathParams, {}, options)
export const useMutationArquivosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/arquivos/{id}', pathParams, {}, options), config)

export const mutationArquivosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/arquivos/{id}', pathParams, {}, options)
export const useMutationArquivosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/arquivos/{id}', pathParams, {}, options), config)

export const queryArquivosControllerDownload = (pathParams, options) => queryFn(options)('/api/v1/arquivos/{id}/download', pathParams)
export const useQueryArquivosControllerDownload = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/{id}/download', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryArquivosControllerDownload.queryKey = '/api/v1/arquivos/{id}/download'

export const queryArquivosControllerDownloadPorChave = (pathParams, options) => queryFn(options)('/api/v1/arquivos/{chave}/download', pathParams)
export const useQueryArquivosControllerDownloadPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/{chave}/download', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryArquivosControllerDownloadPorChave.queryKey = '/api/v1/arquivos/{chave}/download'

export const queryArquivosControllerObterUrlArquivoPadrao = (queryParams, options) => queryFn(options)('/api/v1/arquivos/defaults/url', queryParams)
export const useQueryArquivosControllerObterUrlArquivoPadrao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/defaults/url', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryArquivosControllerObterUrlArquivoPadrao.queryKey = '/api/v1/arquivos/defaults/url'

export const queryArquivosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/arquivos/tipo-arquivo', queryParams)
export const useQueryArquivosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/tipo-arquivo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryArquivosControllerBuscar.queryKey = '/api/v1/arquivos/tipo-arquivo'

export const mutationAssinaturasControllerCadastrar = (options) => mutationFn('post', '/api/v1/assinaturas', {}, {}, options)
export const useMutationAssinaturasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/assinaturas', {}, {}, options), config)

export const queryAssinaturasControllerObterArquivoAssinadoPorEntidadeId = (pathParams, options) => queryFn(options)('/api/v1/assinaturas/assinado/{entidade}/{chave}', pathParams)
export const useQueryAssinaturasControllerObterArquivoAssinadoPorEntidadeId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas/assinado/{entidade}/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.entidade && pathParams.chave, ...config }
})
useQueryAssinaturasControllerObterArquivoAssinadoPorEntidadeId.queryKey = '/api/v1/assinaturas/assinado/{entidade}/{chave}'

export const queryAssinaturasControllerObterArquivoOriginalPorEntidadeId = (pathParams, options) => queryFn(options)('/api/v1/assinaturas/original/{entidade}/{chave}', pathParams)
export const useQueryAssinaturasControllerObterArquivoOriginalPorEntidadeId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas/original/{entidade}/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.entidade && pathParams.chave, ...config }
})
useQueryAssinaturasControllerObterArquivoOriginalPorEntidadeId.queryKey = '/api/v1/assinaturas/original/{entidade}/{chave}'

export const mutationAssinaturasEletronicasConfiguracoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/assinaturas-eletronicas-configuracoes', {}, {}, options)
export const useMutationAssinaturasEletronicasConfiguracoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/assinaturas-eletronicas-configuracoes', {}, {}, options), config)

export const queryAssinaturasEletronicasConfiguracoesControllerObterConfiguracaoGeral = (options) => queryFn(options)('/api/v1/assinaturas-eletronicas-configuracoes')
export const useQueryAssinaturasEletronicasConfiguracoesControllerObterConfiguracaoGeral = (config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas-configuracoes'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAssinaturasEletronicasConfiguracoesControllerObterConfiguracaoGeral.queryKey = '/api/v1/assinaturas-eletronicas-configuracoes'

export const mutationAssinaturasEletronicasConfiguracoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/assinaturas-eletronicas-configuracoes/{id}', pathParams, {}, options)
export const useMutationAssinaturasEletronicasConfiguracoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/assinaturas-eletronicas-configuracoes/{id}', pathParams, {}, options), config)

export const queryAssinaturasEletronicasConfiguracoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas-configuracoes/{id}', pathParams)
export const useQueryAssinaturasEletronicasConfiguracoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas-configuracoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAssinaturasEletronicasConfiguracoesControllerObterPorId.queryKey = '/api/v1/assinaturas-eletronicas-configuracoes/{id}'

export const mutationAssinaturasEletronicasConfiguracoesControllerCadastrarAssinante = (options) => mutationFn('post', '/api/v1/assinaturas-eletronicas-configuracoes/assinante', {}, {}, options)
export const useMutationAssinaturasEletronicasConfiguracoesControllerCadastrarAssinante = (config, options) => useMutation(mutationFn('post', '/api/v1/assinaturas-eletronicas-configuracoes/assinante', {}, {}, options), config)

export const queryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}', pathParams, queryParams)
export const useQueryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoId.queryKey = '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}'

export const mutationAssinaturasEletronicasConfiguracoesControllerDeletarAssinante = (pathParams, options) => mutationFn('delete', '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}', pathParams, {}, options)
export const useMutationAssinaturasEletronicasConfiguracoesControllerDeletarAssinante = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}', pathParams, {}, options), config)

export const queryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoIdETipoSignatario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}/{tipoSignatario}', pathParams, queryParams)
export const useQueryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoIdETipoSignatario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}/{tipoSignatario}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.tipoSignatario, ...config }
})
useQueryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoIdETipoSignatario.queryKey = '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}/{tipoSignatario}'

export const mutationAssinaturasEletronicasConfiguracoesControllerAtribuirRsaPrivateKeyAssinante = (pathParams, options) => mutationFn('patch', '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}/rsakey', pathParams, {}, options)
export const useMutationAssinaturasEletronicasConfiguracoesControllerAtribuirRsaPrivateKeyAssinante = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}/rsakey', pathParams, {}, options), config)

export const queryAtendimentoControllerListarEmAberto = (queryParams, options) => queryFn(options)('/api/v1/atendimentos', queryParams)
export const useQueryAtendimentoControllerListarEmAberto = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAtendimentoControllerListarEmAberto.queryKey = '/api/v1/atendimentos'

export const mutationAtendimentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/atendimentos', {}, {}, options)
export const useMutationAtendimentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos', {}, {}, options), config)

export const queryAtendimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/atendimentos/{id}', pathParams)
export const useQueryAtendimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerObterPorId.queryKey = '/api/v1/atendimentos/{id}'

export const mutationAtendimentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/atendimentos/{id}', pathParams, {}, options)
export const useMutationAtendimentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/atendimentos/{id}', pathParams, {}, options), config)

export const queryAtendimentoControllerObterPreAtendimentoRetornoPublicoPorId = (pathParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/preatendimento-retorno-publico', pathParams)
export const useQueryAtendimentoControllerObterPreAtendimentoRetornoPublicoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/preatendimento-retorno-publico', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerObterPreAtendimentoRetornoPublicoPorId.queryKey = '/api/v1/atendimentos/{id}/preatendimento-retorno-publico'

export const queryAtendimentoControllerObterEmAbertoPorClienteId = (pathParams, options) => queryFn(options)('/api/v1/atendimentos/clientes/{clienteId}/funcionarios/{funcionarioId}', pathParams)
export const useQueryAtendimentoControllerObterEmAbertoPorClienteId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/clientes/{clienteId}/funcionarios/{funcionarioId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.clienteId && pathParams.funcionarioId, ...config }
})
useQueryAtendimentoControllerObterEmAbertoPorClienteId.queryKey = '/api/v1/atendimentos/clientes/{clienteId}/funcionarios/{funcionarioId}'

export const mutationAtendimentoControllerAssinarAtendimento = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/assinar', pathParams, {}, options)
export const useMutationAtendimentoControllerAssinarAtendimento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/assinar', pathParams, {}, options), config)

export const mutationAtendimentoControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/arquivos', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/arquivos', pathParams, {}, options), config)

export const queryAtendimentoControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/arquivos', pathParams, queryParams)
export const useQueryAtendimentoControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerListarArquivos.queryKey = '/api/v1/atendimentos/{id}/arquivos'

export const mutationAtendimentoControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/atendimentos/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationAtendimentoControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/atendimentos/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const queryAtendimentoControllerListarTodosOsAquivosDoCliente = (pathParams, queryParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/arquivos/cliente-imagens', pathParams, queryParams)
export const useQueryAtendimentoControllerListarTodosOsAquivosDoCliente = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/arquivos/cliente-imagens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerListarTodosOsAquivosDoCliente.queryKey = '/api/v1/atendimentos/{id}/arquivos/cliente-imagens'

export const mutationAtendimentoControllerCadastrarSecaoResposta = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/secoes-resposta', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarSecaoResposta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/secoes-resposta', pathParams, {}, options), config)

export const mutationAtendimentoControllerDeletarSecaoResposta = (pathParams, options) => mutationFn('delete', '/api/v1/atendimentos/{id}/secoes-resposta/{secaoId}', pathParams, {}, options)
export const useMutationAtendimentoControllerDeletarSecaoResposta = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/atendimentos/{id}/secoes-resposta/{secaoId}', pathParams, {}, options), config)

export const queryAtendimentoControllerListarSecoesDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/secoes-disponiveis', pathParams, queryParams)
export const useQueryAtendimentoControllerListarSecoesDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/secoes-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerListarSecoesDisponiveis.queryKey = '/api/v1/atendimentos/{id}/secoes-disponiveis'

export const mutationAtendimentoControllerCadastrarResposta = (pathParams, options) => mutationFn('put', '/api/v1/atendimentos/{id}/secoes-resposta/{secaoId}/campo/{campoModeloId}/resposta', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarResposta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/atendimentos/{id}/secoes-resposta/{secaoId}/campo/{campoModeloId}/resposta', pathParams, {}, options), config)

export const mutationAtendimentoControllerAtualizarRespostaCampoModeloProntuarioCalculo = (pathParams, options) => mutationFn('put', '/api/v1/atendimentos/{id}/secoes-resposta/{secaoId}/campo/{campoModeloId}/resposta-campo-calculo', pathParams, {}, options)
export const useMutationAtendimentoControllerAtualizarRespostaCampoModeloProntuarioCalculo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/atendimentos/{id}/secoes-resposta/{secaoId}/campo/{campoModeloId}/resposta-campo-calculo', pathParams, {}, options), config)

export const queryAtendimentoControllerObterPrescricaoPorAtendimentoId = (pathParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/prescricoes', pathParams)
export const useQueryAtendimentoControllerObterPrescricaoPorAtendimentoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/prescricoes', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerObterPrescricaoPorAtendimentoId.queryKey = '/api/v1/atendimentos/{id}/prescricoes'

export const mutationAtendimentoControllerCadastrarPrescricao = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarPrescricao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes', pathParams, {}, options), config)

export const queryAtendimentoControllerObterItemPrescricaoPorId = (pathParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/prescricoes/itens/{itemPrescricaoId}', pathParams)
export const useQueryAtendimentoControllerObterItemPrescricaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/prescricoes/itens/{itemPrescricaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.itemPrescricaoId, ...config }
})
useQueryAtendimentoControllerObterItemPrescricaoPorId.queryKey = '/api/v1/atendimentos/{id}/prescricoes/itens/{itemPrescricaoId}'

export const mutationAtendimentoControllerAtualizarPrescricaoItem = (pathParams, options) => mutationFn('put', '/api/v1/atendimentos/{id}/prescricoes/itens/{itemPrescricaoId}', pathParams, {}, options)
export const useMutationAtendimentoControllerAtualizarPrescricaoItem = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/atendimentos/{id}/prescricoes/itens/{itemPrescricaoId}', pathParams, {}, options), config)

export const mutationAtendimentoControllerDeletarPrescricaoItem = (pathParams, options) => mutationFn('delete', '/api/v1/atendimentos/{id}/prescricoes/itens/{itemPrescricaoId}', pathParams, {}, options)
export const useMutationAtendimentoControllerDeletarPrescricaoItem = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/atendimentos/{id}/prescricoes/itens/{itemPrescricaoId}', pathParams, {}, options), config)

export const mutationAtendimentoControllerCadastrarPrescricaoItem = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/itens', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarPrescricaoItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/itens', pathParams, {}, options), config)

export const queryAtendimentoControllerObterExamePrescricaoPorId = (pathParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/prescricoes/exames/{examePrescricaoId}', pathParams)
export const useQueryAtendimentoControllerObterExamePrescricaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/prescricoes/exames/{examePrescricaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.examePrescricaoId, ...config }
})
useQueryAtendimentoControllerObterExamePrescricaoPorId.queryKey = '/api/v1/atendimentos/{id}/prescricoes/exames/{examePrescricaoId}'

export const mutationAtendimentoControllerAtualizarPrescricaoExame = (pathParams, options) => mutationFn('put', '/api/v1/atendimentos/{id}/prescricoes/exames/{examePrescricaoId}', pathParams, {}, options)
export const useMutationAtendimentoControllerAtualizarPrescricaoExame = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/atendimentos/{id}/prescricoes/exames/{examePrescricaoId}', pathParams, {}, options), config)

export const mutationAtendimentoControllerDeletarPrescricaoExame = (pathParams, options) => mutationFn('delete', '/api/v1/atendimentos/{id}/prescricoes/exames/{examePrescricaoId}', pathParams, {}, options)
export const useMutationAtendimentoControllerDeletarPrescricaoExame = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/atendimentos/{id}/prescricoes/exames/{examePrescricaoId}', pathParams, {}, options), config)

export const mutationAtendimentoControllerCadastrarPrescricaoExame = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/exames', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarPrescricaoExame = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/exames', pathParams, {}, options), config)

export const mutationAtendimentoControllerAdicionarProtocoloPrescricao = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/protocolos', pathParams, {}, options)
export const useMutationAtendimentoControllerAdicionarProtocoloPrescricao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/protocolos', pathParams, {}, options), config)

export const queryAtendimentoControllerObterFormulaPrescricaoPorId = (pathParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/prescricoes/formulas/{formulaPrescricaoId}', pathParams)
export const useQueryAtendimentoControllerObterFormulaPrescricaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/prescricoes/formulas/{formulaPrescricaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.formulaPrescricaoId, ...config }
})
useQueryAtendimentoControllerObterFormulaPrescricaoPorId.queryKey = '/api/v1/atendimentos/{id}/prescricoes/formulas/{formulaPrescricaoId}'

export const mutationAtendimentoControllerAtualizarPrescricaoFormula = (pathParams, options) => mutationFn('put', '/api/v1/atendimentos/{id}/prescricoes/formulas/{formulaPrescricaoId}', pathParams, {}, options)
export const useMutationAtendimentoControllerAtualizarPrescricaoFormula = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/atendimentos/{id}/prescricoes/formulas/{formulaPrescricaoId}', pathParams, {}, options), config)

export const mutationAtendimentoControllerDeletarPrescricaoFormula = (pathParams, options) => mutationFn('delete', '/api/v1/atendimentos/{id}/prescricoes/formulas/{formulaPrescricaoId}', pathParams, {}, options)
export const useMutationAtendimentoControllerDeletarPrescricaoFormula = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/atendimentos/{id}/prescricoes/formulas/{formulaPrescricaoId}', pathParams, {}, options), config)

export const mutationAtendimentoControllerCadastrarPrescricaoFormula = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/formulas', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarPrescricaoFormula = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/formulas', pathParams, {}, options), config)

export const queryAtendimentoControllerObterModeloLaudoPrescricaoPorId = (pathParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/prescricoes/modelo-laudos/{modeloLaudoPrescricaoId}', pathParams)
export const useQueryAtendimentoControllerObterModeloLaudoPrescricaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/prescricoes/modelo-laudos/{modeloLaudoPrescricaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.modeloLaudoPrescricaoId, ...config }
})
useQueryAtendimentoControllerObterModeloLaudoPrescricaoPorId.queryKey = '/api/v1/atendimentos/{id}/prescricoes/modelo-laudos/{modeloLaudoPrescricaoId}'

export const mutationAtendimentoControllerAtualizarPrescricaoModeloLaudo = (pathParams, options) => mutationFn('put', '/api/v1/atendimentos/{id}/prescricoes/modelo-laudos/{modeloLaudoPrescricaoId}', pathParams, {}, options)
export const useMutationAtendimentoControllerAtualizarPrescricaoModeloLaudo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/atendimentos/{id}/prescricoes/modelo-laudos/{modeloLaudoPrescricaoId}', pathParams, {}, options), config)

export const mutationAtendimentoControllerDeletarPrescricaoModeloLaudo = (pathParams, options) => mutationFn('delete', '/api/v1/atendimentos/{id}/prescricoes/modelo-laudos/{modeloLaudoPrescricaoId}', pathParams, {}, options)
export const useMutationAtendimentoControllerDeletarPrescricaoModeloLaudo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/atendimentos/{id}/prescricoes/modelo-laudos/{modeloLaudoPrescricaoId}', pathParams, {}, options), config)

export const mutationAtendimentoControllerCadastrarPrescricaoModeloLaudo = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/modelo-laudos', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarPrescricaoModeloLaudo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/modelo-laudos', pathParams, {}, options), config)

export const mutationAtendimentoControllerCadastrarDocumentoPrescricaoModeloLaudo = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/prescricoes/modelo-laudos/{id}/documento', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarDocumentoPrescricaoModeloLaudo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/prescricoes/modelo-laudos/{id}/documento', pathParams, {}, options), config)

export const queryAtendimentoControllerListarDocumentosPrescricaoDocumentoLaudo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/atendimentos/prescricoes/modelo-laudos/{id}/documento', pathParams, queryParams)
export const useQueryAtendimentoControllerListarDocumentosPrescricaoDocumentoLaudo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/prescricoes/modelo-laudos/{id}/documento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerListarDocumentosPrescricaoDocumentoLaudo.queryKey = '/api/v1/atendimentos/prescricoes/modelo-laudos/{id}/documento'

export const mutationAtendimentoControllerCadastrarDocumentoConclusao = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/documento-conclusao', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarDocumentoConclusao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/documento-conclusao', pathParams, {}, options), config)

export const mutationAtendimentoControllerCadastrarPrescricaoDocumento = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/documentos', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarPrescricaoDocumento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/prescricoes/documentos', pathParams, {}, options), config)

export const queryAtendimentoControllerListarDocumentoPrescricaoDocumento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/prescricoes/documento', pathParams, queryParams)
export const useQueryAtendimentoControllerListarDocumentoPrescricaoDocumento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/prescricoes/documento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerListarDocumentoPrescricaoDocumento.queryKey = '/api/v1/atendimentos/{id}/prescricoes/documento'

export const queryAtendimentoControllerListarMemedPrescricoesPorAtendimentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/memed-prescricoes', pathParams, queryParams)
export const useQueryAtendimentoControllerListarMemedPrescricoesPorAtendimentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/memed-prescricoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerListarMemedPrescricoesPorAtendimentoId.queryKey = '/api/v1/atendimentos/{id}/memed-prescricoes'

export const queryAtendimentoControllerObterAtendimentoAdendosPorAtendimentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/adendos', pathParams, queryParams)
export const useQueryAtendimentoControllerObterAtendimentoAdendosPorAtendimentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/adendos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerObterAtendimentoAdendosPorAtendimentoId.queryKey = '/api/v1/atendimentos/{id}/adendos'

export const mutationAtendimentoControllerCadastrarAtendimentoAdendo = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/adendos', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarAtendimentoAdendo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/adendos', pathParams, {}, options), config)

export const mutationAtendimentoControllerAssinarAdendo = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/adendos/{adendoId}/assinar', pathParams, {}, options)
export const useMutationAtendimentoControllerAssinarAdendo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/adendos/{adendoId}/assinar', pathParams, {}, options), config)

export const queryAtendimentoControllerObterAtendimentosPendentesAssinatura = (queryParams, options) => queryFn(options)('/api/v1/atendimentos/pendentes-assinatura', queryParams)
export const useQueryAtendimentoControllerObterAtendimentosPendentesAssinatura = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/pendentes-assinatura', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAtendimentoControllerObterAtendimentosPendentesAssinatura.queryKey = '/api/v1/atendimentos/pendentes-assinatura'

export const queryAtendimentoControllerObterChatsTeleconsultaPorAtendimentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/chats-teleconsulta', pathParams, queryParams)
export const useQueryAtendimentoControllerObterChatsTeleconsultaPorAtendimentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/chats-teleconsulta', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerObterChatsTeleconsultaPorAtendimentoId.queryKey = '/api/v1/atendimentos/{id}/chats-teleconsulta'

export const queryAtendimentoControllerObterUrlAssinadaArquivoChat = (pathParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/arquivos-teleconsulta/{arquivoTeleconsultaId}', pathParams)
export const useQueryAtendimentoControllerObterUrlAssinadaArquivoChat = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/arquivos-teleconsulta/{arquivoTeleconsultaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.arquivoTeleconsultaId, ...config }
})
useQueryAtendimentoControllerObterUrlAssinadaArquivoChat.queryKey = '/api/v1/atendimentos/{id}/arquivos-teleconsulta/{arquivoTeleconsultaId}'

export const mutationAtendimentoControllerCadastrarImpressoAtendimento = (pathParams, options) => mutationFn('post', '/api/v1/atendimentos/{id}/impressos-atendimento', pathParams, {}, options)
export const useMutationAtendimentoControllerCadastrarImpressoAtendimento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/atendimentos/{id}/impressos-atendimento', pathParams, {}, options), config)

export const queryAtendimentoControllerListarImpressosAtendimento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/atendimentos/{id}/impressos-atendimento', pathParams, queryParams)
export const useQueryAtendimentoControllerListarImpressosAtendimento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atendimentos/{id}/impressos-atendimento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtendimentoControllerListarImpressosAtendimento.queryKey = '/api/v1/atendimentos/{id}/impressos-atendimento'

export const queryAtributosAnatomicosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/atributos-anatomicos', queryParams)
export const useQueryAtributosAnatomicosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atributos-anatomicos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAtributosAnatomicosControllerListar.queryKey = '/api/v1/atributos-anatomicos'

export const queryAtributosAnatomicosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/atributos-anatomicos/{id}', pathParams)
export const useQueryAtributosAnatomicosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atributos-anatomicos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtributosAnatomicosControllerObterPorId.queryKey = '/api/v1/atributos-anatomicos/{id}'

export const queryAtributosAnatomicosControllerObterPorSigla = (pathParams, options) => queryFn(options)('/api/v1/atributos-anatomicos/{sigla}', pathParams)
export const useQueryAtributosAnatomicosControllerObterPorSigla = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atributos-anatomicos/{sigla}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.sigla, ...config }
})
useQueryAtributosAnatomicosControllerObterPorSigla.queryKey = '/api/v1/atributos-anatomicos/{sigla}'

export const mutationAvatarsControllerPost = (options) => mutationFn('post', '/api/v1/avatars', {}, {}, options)
export const useMutationAvatarsControllerPost = (config, options) => useMutation(mutationFn('post', '/api/v1/avatars', {}, {}, options), config)

export const queryAvatarsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/avatars/{id}', pathParams)
export const useQueryAvatarsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/avatars/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAvatarsControllerObterPorId.queryKey = '/api/v1/avatars/{id}'

export const queryBaixasTituloControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/baixas-titulo/{id}', pathParams)
export const useQueryBaixasTituloControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/baixas-titulo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBaixasTituloControllerObterPorId.queryKey = '/api/v1/baixas-titulo/{id}'

export const mutationBaixasTituloControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/baixas-titulo/{id}', pathParams, {}, options)
export const useMutationBaixasTituloControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/baixas-titulo/{id}', pathParams, {}, options), config)

export const queryBaixasTituloControllerListarBaixaTitulosCheques = (pathParams, queryParams, options) => queryFn(options)('/api/v1/baixas-titulo/{id}/cheques', pathParams, queryParams)
export const useQueryBaixasTituloControllerListarBaixaTitulosCheques = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/baixas-titulo/{id}/cheques', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBaixasTituloControllerListarBaixaTitulosCheques.queryKey = '/api/v1/baixas-titulo/{id}/cheques'

export const queryBaixasTituloCartaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/baixas-titulo-cartoes', queryParams)
export const useQueryBaixasTituloCartaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/baixas-titulo-cartoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBaixasTituloCartaoControllerListar.queryKey = '/api/v1/baixas-titulo-cartoes'

export const queryBaixasTituloCartaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/baixas-titulo-cartoes/{id}', pathParams)
export const useQueryBaixasTituloCartaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/baixas-titulo-cartoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBaixasTituloCartaoControllerObterPorId.queryKey = '/api/v1/baixas-titulo-cartoes/{id}'

export const mutationBaixasTituloCartaoControllerDeletarBaixaTituloCartao = (pathParams, options) => mutationFn('delete', '/api/v1/baixas-titulo-cartoes/{id}', pathParams, {}, options)
export const useMutationBaixasTituloCartaoControllerDeletarBaixaTituloCartao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/baixas-titulo-cartoes/{id}', pathParams, {}, options), config)

export const mutationBaixasTituloCartaoControllerConciliar = (pathParams, options) => mutationFn('post', '/api/v1/baixas-titulo-cartoes/{id}/concilia', pathParams, {}, options)
export const useMutationBaixasTituloCartaoControllerConciliar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/baixas-titulo-cartoes/{id}/concilia', pathParams, {}, options), config)

export const mutationBaixasTituloCartaoControllerEstonar = (pathParams, options) => mutationFn('post', '/api/v1/baixas-titulo-cartoes/{id}/estono', pathParams, {}, options)
export const useMutationBaixasTituloCartaoControllerEstonar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/baixas-titulo-cartoes/{id}/estono', pathParams, {}, options), config)

export const queryBaixasTituloChequeControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/baixas-titulo-cheques/{id}', pathParams)
export const useQueryBaixasTituloChequeControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/baixas-titulo-cheques/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBaixasTituloChequeControllerObterPorId.queryKey = '/api/v1/baixas-titulo-cheques/{id}'

export const mutationBaixasTituloChequeControllerDeletarBaixaTituloCheque = (pathParams, options) => mutationFn('delete', '/api/v1/baixas-titulo-cheques/{id}', pathParams, {}, options)
export const useMutationBaixasTituloChequeControllerDeletarBaixaTituloCheque = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/baixas-titulo-cheques/{id}', pathParams, {}, options), config)

export const mutationBandeirasControllerCadastrar = (options) => mutationFn('post', '/api/v1/bandeiras', {}, {}, options)
export const useMutationBandeirasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/bandeiras', {}, {}, options), config)

export const queryBandeirasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/bandeiras', queryParams)
export const useQueryBandeirasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bandeiras', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBandeirasControllerListar.queryKey = '/api/v1/bandeiras'

export const queryBandeirasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/bandeiras/{id}', pathParams)
export const useQueryBandeirasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bandeiras/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBandeirasControllerObterPorId.queryKey = '/api/v1/bandeiras/{id}'

export const mutationBandeirasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/bandeiras/{id}', pathParams, {}, options)
export const useMutationBandeirasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/bandeiras/{id}', pathParams, {}, options), config)

export const mutationBandeirasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/bandeiras/{id}', pathParams, {}, options)
export const useMutationBandeirasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/bandeiras/{id}', pathParams, {}, options), config)

export const mutationBandeirasControllerCadastrarBandeiraConta = (pathParams, options) => mutationFn('post', '/api/v1/bandeiras/{id}/contas', pathParams, {}, options)
export const useMutationBandeirasControllerCadastrarBandeiraConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/bandeiras/{id}/contas', pathParams, {}, options), config)

export const queryBandeirasControllerListarBandeiraContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/bandeiras/{id}/contas', pathParams, queryParams)
export const useQueryBandeirasControllerListarBandeiraContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bandeiras/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBandeirasControllerListarBandeiraContas.queryKey = '/api/v1/bandeiras/{id}/contas'

export const queryBandeirasControllerObterBandeiraContaPorId = (pathParams, options) => queryFn(options)('/api/v1/bandeiras/{id}/contas/{contaId}', pathParams)
export const useQueryBandeirasControllerObterBandeiraContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bandeiras/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryBandeirasControllerObterBandeiraContaPorId.queryKey = '/api/v1/bandeiras/{id}/contas/{contaId}'

export const mutationBandeirasControllerAtualizarBandeiraConta = (pathParams, options) => mutationFn('put', '/api/v1/bandeiras/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationBandeirasControllerAtualizarBandeiraConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/bandeiras/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryBandeirasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/bandeiras/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryBandeirasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bandeiras/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBandeirasControllerListarContasDisponiveis.queryKey = '/api/v1/bandeiras/{id}/contas-disponiveis'

export const queryBoletoConfiguracaoControllerBuscar = (options) => queryFn(options)('/api/v1/boleto-configuracao')
export const useQueryBoletoConfiguracaoControllerBuscar = (config, options) => useQuery({
  queryKey: ['/api/v1/boleto-configuracao'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBoletoConfiguracaoControllerBuscar.queryKey = '/api/v1/boleto-configuracao'

export const mutationBoletoConfiguracaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/boleto-configuracao/{id}', pathParams, {}, options)
export const useMutationBoletoConfiguracaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/boleto-configuracao/{id}', pathParams, {}, options), config)

export const queryBoletoConfiguracaoControllerBuscarTemplatesSms = (queryParams, options) => queryFn(options)('/api/v1/boleto-configuracao/lista-templates-sms', queryParams)
export const useQueryBoletoConfiguracaoControllerBuscarTemplatesSms = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/boleto-configuracao/lista-templates-sms', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBoletoConfiguracaoControllerBuscarTemplatesSms.queryKey = '/api/v1/boleto-configuracao/lista-templates-sms'

export const queryBoletoConfiguracaoControllerBuscarTemplatesEmail = (queryParams, options) => queryFn(options)('/api/v1/boleto-configuracao/lista-templates-email', queryParams)
export const useQueryBoletoConfiguracaoControllerBuscarTemplatesEmail = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/boleto-configuracao/lista-templates-email', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBoletoConfiguracaoControllerBuscarTemplatesEmail.queryKey = '/api/v1/boleto-configuracao/lista-templates-email'

export const mutationCaixasControllerCadastrar = (options) => mutationFn('post', '/api/v1/caixas', {}, {}, options)
export const useMutationCaixasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas', {}, {}, options), config)

export const queryCaixasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/caixas', queryParams)
export const useQueryCaixasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasControllerListar.queryKey = '/api/v1/caixas'

export const mutationCaixasControllerFechar = (pathParams, options) => mutationFn('post', '/api/v1/caixas/{id}/fechamento', pathParams, {}, options)
export const useMutationCaixasControllerFechar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/caixas/{id}/fechamento', pathParams, {}, options), config)

export const mutationCaixasControllerSangrar = (pathParams, options) => mutationFn('post', '/api/v1/caixas/{id}/sangria', pathParams, {}, options)
export const useMutationCaixasControllerSangrar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/caixas/{id}/sangria', pathParams, {}, options), config)

export const mutationCaixasControllerSuprir = (pathParams, options) => mutationFn('post', '/api/v1/caixas/{id}/suprimento', pathParams, {}, options)
export const useMutationCaixasControllerSuprir = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/caixas/{id}/suprimento', pathParams, {}, options), config)

export const mutationCaixasControllerConsolidar = (pathParams, options) => mutationFn('post', '/api/v1/caixas/{id}/consolidar', pathParams, {}, options)
export const useMutationCaixasControllerConsolidar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/caixas/{id}/consolidar', pathParams, {}, options), config)

export const mutationCaixasControllerDesfazerConsolidacao = (pathParams, options) => mutationFn('put', '/api/v1/caixas/{id}/desfazer-consolidacao', pathParams, {}, options)
export const useMutationCaixasControllerDesfazerConsolidacao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/caixas/{id}/desfazer-consolidacao', pathParams, {}, options), config)

export const queryCaixasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/caixas/{id}', pathParams)
export const useQueryCaixasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasControllerObterPorId.queryKey = '/api/v1/caixas/{id}'

export const queryCaixasControllerListarSaldoPorFormaDePagamento = (pathParams, options) => queryFn(options)('/api/v1/caixas/{id}/saldo-por-forma-de-pagamento', pathParams)
export const useQueryCaixasControllerListarSaldoPorFormaDePagamento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/{id}/saldo-por-forma-de-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasControllerListarSaldoPorFormaDePagamento.queryKey = '/api/v1/caixas/{id}/saldo-por-forma-de-pagamento'

export const queryCaixasControllerListarSaldoPorFormaDePagamentoParaConsolidacao = (pathParams, options) => queryFn(options)('/api/v1/caixas/{id}/saldo-por-forma-de-pagamento-para-consolidacao', pathParams)
export const useQueryCaixasControllerListarSaldoPorFormaDePagamentoParaConsolidacao = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/{id}/saldo-por-forma-de-pagamento-para-consolidacao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasControllerListarSaldoPorFormaDePagamentoParaConsolidacao.queryKey = '/api/v1/caixas/{id}/saldo-por-forma-de-pagamento-para-consolidacao'

export const queryCaixasControllerListarMovimentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/caixas/{id}/movimentos-caixa', pathParams, queryParams)
export const useQueryCaixasControllerListarMovimentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/{id}/movimentos-caixa', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasControllerListarMovimentos.queryKey = '/api/v1/caixas/{id}/movimentos-caixa'

export const queryCaixasControllerListarCaixasPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/caixas/listar-caixas-por-periodo', queryParams)
export const useQueryCaixasControllerListarCaixasPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/listar-caixas-por-periodo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasControllerListarCaixasPorPeriodo.queryKey = '/api/v1/caixas/listar-caixas-por-periodo'

export const queryCaixasControllerListarMovimentosCaixaExtrato = (queryParams, options) => queryFn(options)('/api/v1/caixas/extrato', queryParams)
export const useQueryCaixasControllerListarMovimentosCaixaExtrato = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/extrato', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasControllerListarMovimentosCaixaExtrato.queryKey = '/api/v1/caixas/extrato'

export const queryCaixasControllerListarTitulosMovimentosCaixasPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/caixas/listar-titulos-movimentos-por-periodo', queryParams)
export const useQueryCaixasControllerListarTitulosMovimentosCaixasPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/listar-titulos-movimentos-por-periodo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasControllerListarTitulosMovimentosCaixasPorPeriodo.queryKey = '/api/v1/caixas/listar-titulos-movimentos-por-periodo'

export const mutationCaixasGeralControllerConsolidar = (options) => mutationFn('post', '/api/v1/caixas-geral', {}, {}, options)
export const useMutationCaixasGeralControllerConsolidar = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas-geral', {}, {}, options), config)

export const queryCaixasGeralControllerListar = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral', queryParams)
export const useQueryCaixasGeralControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListar.queryKey = '/api/v1/caixas-geral'

export const queryCaixasGeralControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/caixas-geral/{id}', pathParams)
export const useQueryCaixasGeralControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasGeralControllerObterPorId.queryKey = '/api/v1/caixas-geral/{id}'

export const queryCaixasGeralControllerObterCaixaGeralPorPerido = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/indicadores', queryParams)
export const useQueryCaixasGeralControllerObterCaixaGeralPorPerido = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerObterCaixaGeralPorPerido.queryKey = '/api/v1/caixas-geral/indicadores'

export const queryCaixasGeralControllerObterIndicadorContasReceber = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/indicadores-de-contas-a-receber', queryParams)
export const useQueryCaixasGeralControllerObterIndicadorContasReceber = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/indicadores-de-contas-a-receber', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerObterIndicadorContasReceber.queryKey = '/api/v1/caixas-geral/indicadores-de-contas-a-receber'

export const queryCaixasGeralControllerListarCaixasConsolidacao = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/caixas', queryParams)
export const useQueryCaixasGeralControllerListarCaixasConsolidacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/caixas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarCaixasConsolidacao.queryKey = '/api/v1/caixas-geral/caixas'

export const queryCaixasGeralControllerListarSaldoCaixasConsolidacao = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/saldo-caixas-consolidacao', queryParams)
export const useQueryCaixasGeralControllerListarSaldoCaixasConsolidacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/saldo-caixas-consolidacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarSaldoCaixasConsolidacao.queryKey = '/api/v1/caixas-geral/saldo-caixas-consolidacao'

export const mutationCaixasGeralControllerConsolidarCaixas = (options) => mutationFn('post', '/api/v1/caixas-geral/consolidar/caixas', {}, {}, options)
export const useMutationCaixasGeralControllerConsolidarCaixas = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas-geral/consolidar/caixas', {}, {}, options), config)

export const mutationCaixasGeralControllerDesfazerConsolidacaoCaixas = (options) => mutationFn('post', '/api/v1/caixas-geral/desfazer-consolidacao/caixas', {}, {}, options)
export const useMutationCaixasGeralControllerDesfazerConsolidacaoCaixas = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas-geral/desfazer-consolidacao/caixas', {}, {}, options), config)

export const queryCaixasGeralControllerListarSaldoCaixasConsolidado = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/saldo-caixas-consolidado', queryParams)
export const useQueryCaixasGeralControllerListarSaldoCaixasConsolidado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/saldo-caixas-consolidado', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarSaldoCaixasConsolidado.queryKey = '/api/v1/caixas-geral/saldo-caixas-consolidado'

export const queryCaixasGeralControllerListarTitulosMovimentosConsolidacao = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/movimentos', queryParams)
export const useQueryCaixasGeralControllerListarTitulosMovimentosConsolidacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/movimentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarTitulosMovimentosConsolidacao.queryKey = '/api/v1/caixas-geral/movimentos'

export const queryCaixasGeralControllerListarSaldoMovimentosConsolidacao = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/saldo-movimentos-consolidacao', queryParams)
export const useQueryCaixasGeralControllerListarSaldoMovimentosConsolidacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/saldo-movimentos-consolidacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarSaldoMovimentosConsolidacao.queryKey = '/api/v1/caixas-geral/saldo-movimentos-consolidacao'

export const mutationCaixasGeralControllerConsolidarMovimentos = (options) => mutationFn('post', '/api/v1/caixas-geral/consolidar/movimentos', {}, {}, options)
export const useMutationCaixasGeralControllerConsolidarMovimentos = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas-geral/consolidar/movimentos', {}, {}, options), config)

export const mutationCaixasGeralControllerDesfazerConsolidacaoMovimentos = (options) => mutationFn('post', '/api/v1/caixas-geral/desfazer-consolidacao/movimentos', {}, {}, options)
export const useMutationCaixasGeralControllerDesfazerConsolidacaoMovimentos = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas-geral/desfazer-consolidacao/movimentos', {}, {}, options), config)

export const queryCaixasGeralControllerListarSaldoMovimentosConsolidado = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/saldo-movimentos-consolidado', queryParams)
export const useQueryCaixasGeralControllerListarSaldoMovimentosConsolidado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/saldo-movimentos-consolidado', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarSaldoMovimentosConsolidado.queryKey = '/api/v1/caixas-geral/saldo-movimentos-consolidado'

export const queryCalendariosControllerObterCalendarioResumo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/resumo', pathParams, queryParams)
export const useQueryCalendariosControllerObterCalendarioResumo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/resumo', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterCalendarioResumo.queryKey = '/api/v1/calendarios/{id}/resumo'

export const mutationCalendariosControllerCadastrarHorarioFuncionamentoSaude = (pathParams, options) => mutationFn('post', '/api/v1/calendarios/{id}/horarios', pathParams, {}, options)
export const useMutationCalendariosControllerCadastrarHorarioFuncionamentoSaude = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/{id}/horarios', pathParams, {}, options), config)

export const queryCalendariosControllerObterListaHorariosFuncionamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/horarios', pathParams, queryParams)
export const useQueryCalendariosControllerObterListaHorariosFuncionamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/horarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterListaHorariosFuncionamento.queryKey = '/api/v1/calendarios/{id}/horarios'

export const queryCalendariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/calendarios/{id}', pathParams)
export const useQueryCalendariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterPorId.queryKey = '/api/v1/calendarios/{id}'

export const mutationCalendariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/calendarios/{id}', pathParams, {}, options)
export const useMutationCalendariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/calendarios/{id}', pathParams, {}, options), config)

export const mutationCalendariosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/calendarios/{id}', pathParams, {}, options)
export const useMutationCalendariosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/calendarios/{id}', pathParams, {}, options), config)

export const mutationCalendariosControllerCadastrar = (options) => mutationFn('post', '/api/v1/calendarios', {}, {}, options)
export const useMutationCalendariosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/calendarios', {}, {}, options), config)

export const queryCalendariosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/calendarios', queryParams)
export const useQueryCalendariosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCalendariosControllerBuscar.queryKey = '/api/v1/calendarios'

export const queryCalendariosControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/calendarios/buscar-detalhado/{id}', pathParams)
export const useQueryCalendariosControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterPorIdDetalhado.queryKey = '/api/v1/calendarios/buscar-detalhado/{id}'

export const mutationCalendariosControllerCadastrarCalendarioConta = (pathParams, options) => mutationFn('post', '/api/v1/calendarios/{id}/contas', pathParams, {}, options)
export const useMutationCalendariosControllerCadastrarCalendarioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/{id}/contas', pathParams, {}, options), config)

export const queryCalendariosControllerListarCalendarioContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/contas', pathParams, queryParams)
export const useQueryCalendariosControllerListarCalendarioContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerListarCalendarioContas.queryKey = '/api/v1/calendarios/{id}/contas'

export const queryCalendariosControllerObterCalendarioContaPorId = (pathParams, options) => queryFn(options)('/api/v1/calendarios/{id}/contas/{contaId}', pathParams)
export const useQueryCalendariosControllerObterCalendarioContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCalendariosControllerObterCalendarioContaPorId.queryKey = '/api/v1/calendarios/{id}/contas/{contaId}'

export const mutationCalendariosControllerAtualizarCalendarioConta = (pathParams, options) => mutationFn('put', '/api/v1/calendarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCalendariosControllerAtualizarCalendarioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/calendarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCalendariosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCalendariosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerListarContasDisponiveis.queryKey = '/api/v1/calendarios/{id}/contas-disponiveis'

export const queryCalendariosControllerObterFeriadosEventosListagem = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/feriados-e-eventos-listagem', pathParams, queryParams)
export const useQueryCalendariosControllerObterFeriadosEventosListagem = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/feriados-e-eventos-listagem', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterFeriadosEventosListagem.queryKey = '/api/v1/calendarios/{id}/feriados-e-eventos-listagem'

export const queryCalendariosControllerObterFeriadosEventos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/feriados-e-eventos', pathParams, queryParams)
export const useQueryCalendariosControllerObterFeriadosEventos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/feriados-e-eventos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterFeriadosEventos.queryKey = '/api/v1/calendarios/{id}/feriados-e-eventos'

export const mutationCalendariosControllerCadastrarFeriadoEvento = (pathParams, options) => mutationFn('post', '/api/v1/calendarios/{id}/feriados-e-eventos', pathParams, {}, options)
export const useMutationCalendariosControllerCadastrarFeriadoEvento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/{id}/feriados-e-eventos', pathParams, {}, options), config)

export const queryCalendariosControllerObterTempoEventos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/tempo-de-eventos', pathParams, queryParams)
export const useQueryCalendariosControllerObterTempoEventos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/tempo-de-eventos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterTempoEventos.queryKey = '/api/v1/calendarios/{id}/tempo-de-eventos'

export const mutationCalendariosControllerCadastrarTempoEvento = (pathParams, options) => mutationFn('post', '/api/v1/calendarios/{id}/tempo-de-eventos', pathParams, {}, options)
export const useMutationCalendariosControllerCadastrarTempoEvento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/{id}/tempo-de-eventos', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}', pathParams)
export const useQueryCalendariosHorarioFuncionamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerObterPorId.queryKey = '/api/v1/horarios-de-funcionamento/{id}'

export const mutationCalendariosHorarioFuncionamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/horarios-de-funcionamento/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/horarios-de-funcionamento/{id}', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/horarios-de-funcionamento/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/horarios-de-funcionamento/{id}', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoControllerCadastrarProfissional = (pathParams, options) => mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/profissionais', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerCadastrarProfissional = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/profissionais', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerListarProfissionais = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/profissionais', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarProfissionais = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/profissionais', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarProfissionais.queryKey = '/api/v1/horarios-de-funcionamento/{id}/profissionais'

export const mutationCalendariosHorarioFuncionamentoControllerCadastrarEquipamento = (pathParams, options) => mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/equipamentos', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerCadastrarEquipamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/equipamentos', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerListarEquipamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/equipamentos', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarEquipamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/equipamentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarEquipamentos.queryKey = '/api/v1/horarios-de-funcionamento/{id}/equipamentos'

export const mutationCalendariosHorarioFuncionamentoControllerCadastrarItem = (pathParams, options) => mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/itens', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerCadastrarItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/itens', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerListarItens = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/itens', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarItens = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarItens.queryKey = '/api/v1/horarios-de-funcionamento/{id}/itens'

export const mutationCalendariosHorarioFuncionamentoControllerCadastrarSala = (pathParams, options) => mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/salas', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerCadastrarSala = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/salas', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerListarSalas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/salas', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarSalas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/salas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarSalas.queryKey = '/api/v1/horarios-de-funcionamento/{id}/salas'

export const queryCalendariosHorarioFuncionamentoControllerListarRecursosHorario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/recursos', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarRecursosHorario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/recursos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarRecursosHorario.queryKey = '/api/v1/horarios-de-funcionamento/{id}/recursos'

export const mutationCalendariosHorarioFuncionamentoRecursosControllerDeletarEquipamento = (pathParams, options) => mutationFn('delete', '/api/v1/recursos/equipamento/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoRecursosControllerDeletarEquipamento = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/recursos/equipamento/{id}', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoRecursosControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/recursos/profissional/{id}', pathParams)
export const useQueryCalendariosHorarioFuncionamentoRecursosControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos/profissional/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoRecursosControllerObterPorIdDetalhado.queryKey = '/api/v1/recursos/profissional/{id}'

export const mutationCalendariosHorarioFuncionamentoRecursosControllerAtualizarProfissional = (pathParams, options) => mutationFn('put', '/api/v1/recursos/profissional/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoRecursosControllerAtualizarProfissional = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/recursos/profissional/{id}', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoRecursosControllerDeletarProfissional = (pathParams, options) => mutationFn('delete', '/api/v1/recursos/profissional/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoRecursosControllerDeletarProfissional = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/recursos/profissional/{id}', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoRecursosControllerDeletarItem = (pathParams, options) => mutationFn('delete', '/api/v1/recursos/item/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoRecursosControllerDeletarItem = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/recursos/item/{id}', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoRecursosControllerDeletarSala = (pathParams, options) => mutationFn('delete', '/api/v1/recursos/sala/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoRecursosControllerDeletarSala = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/recursos/sala/{id}', pathParams, {}, options), config)

export const queryCalendarioTiposControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-calendario', queryParams)
export const useQueryCalendarioTiposControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calendario', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCalendarioTiposControllerListar.queryKey = '/api/v1/tipos-de-calendario'

export const queryCalendarioTiposControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calendario/{id}', pathParams)
export const useQueryCalendarioTiposControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calendario/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendarioTiposControllerObterPorId.queryKey = '/api/v1/tipos-de-calendario/{id}'

export const queryCalendarioTiposControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calendario/{chave}', pathParams)
export const useQueryCalendarioTiposControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calendario/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryCalendarioTiposControllerObterPorChave.queryKey = '/api/v1/tipos-de-calendario/{chave}'

export const queryCalendarioTiposControllerObterPorCodigo = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calendario/{codigo}', pathParams)
export const useQueryCalendarioTiposControllerObterPorCodigo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calendario/{codigo}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.codigo, ...config }
})
useQueryCalendarioTiposControllerObterPorCodigo.queryKey = '/api/v1/tipos-de-calendario/{codigo}'

export const queryCampanhasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/campanhas', queryParams)
export const useQueryCampanhasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campanhas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCampanhasControllerListar.queryKey = '/api/v1/campanhas'

export const mutationCampanhasControllerCadastrar = (options) => mutationFn('post', '/api/v1/campanhas', {}, {}, options)
export const useMutationCampanhasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/campanhas', {}, {}, options), config)

export const queryCampanhasControllerListarVigentes = (queryParams, options) => queryFn(options)('/api/v1/campanhas/vigentes', queryParams)
export const useQueryCampanhasControllerListarVigentes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campanhas/vigentes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCampanhasControllerListarVigentes.queryKey = '/api/v1/campanhas/vigentes'

export const queryCampanhasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/campanhas/{id}', pathParams)
export const useQueryCampanhasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campanhas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCampanhasControllerObterPorId.queryKey = '/api/v1/campanhas/{id}'

export const mutationCampanhasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/campanhas/{id}', pathParams, {}, options)
export const useMutationCampanhasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/campanhas/{id}', pathParams, {}, options), config)

export const mutationCampanhasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/campanhas/{id}', pathParams, {}, options)
export const useMutationCampanhasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/campanhas/{id}', pathParams, {}, options), config)

export const mutationCamposCadastroPersonalizadoControllerDeletarCampoCadastroPersonalizado = (pathParams, options) => mutationFn('delete', '/api/v1/campos-cadastro-personalizado/{id}', pathParams, {}, options)
export const useMutationCamposCadastroPersonalizadoControllerDeletarCampoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/campos-cadastro-personalizado/{id}', pathParams, {}, options), config)

export const mutationCamposCadastroPersonalizadoControllerCadastrarItemCampoCadastroPersonalizado = (pathParams, options) => mutationFn('post', '/api/v1/campos-cadastro-personalizado/{id}/itens', pathParams, {}, options)
export const useMutationCamposCadastroPersonalizadoControllerCadastrarItemCampoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/campos-cadastro-personalizado/{id}/itens', pathParams, {}, options), config)

export const queryCamposCadastroPersonalizadoControllerListarItensCampoCadastroPersonalizado = (pathParams, queryParams, options) => queryFn(options)('/api/v1/campos-cadastro-personalizado/{id}/itens', pathParams, queryParams)
export const useQueryCamposCadastroPersonalizadoControllerListarItensCampoCadastroPersonalizado = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-cadastro-personalizado/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCamposCadastroPersonalizadoControllerListarItensCampoCadastroPersonalizado.queryKey = '/api/v1/campos-cadastro-personalizado/{id}/itens'

export const mutationCamposCadastroPersonalizadoControllerDeletarItemCampoCadastroPersonalizado = (pathParams, options) => mutationFn('delete', '/api/v1/campos-cadastro-personalizado/itens/{id}', pathParams, {}, options)
export const useMutationCamposCadastroPersonalizadoControllerDeletarItemCampoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/campos-cadastro-personalizado/itens/{id}', pathParams, {}, options), config)

export const queryCanaisEnvioControllerListar = (queryParams, options) => queryFn(options)('/api/v1/canais-envio', queryParams)
export const useQueryCanaisEnvioControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/canais-envio', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCanaisEnvioControllerListar.queryKey = '/api/v1/canais-envio'

export const queryCanaisEnvioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/canais-envio/{id}', pathParams)
export const useQueryCanaisEnvioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/canais-envio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCanaisEnvioControllerObterPorId.queryKey = '/api/v1/canais-envio/{id}'

export const queryCanaisEnvioControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/canais-envio/{chave}', pathParams)
export const useQueryCanaisEnvioControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/canais-envio/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryCanaisEnvioControllerObterPorChave.queryKey = '/api/v1/canais-envio/{chave}'

export const queryCargosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cargos', queryParams)
export const useQueryCargosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCargosControllerListar.queryKey = '/api/v1/cargos'

export const mutationCargosControllerCadastrar = (options) => mutationFn('post', '/api/v1/cargos', {}, {}, options)
export const useMutationCargosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/cargos', {}, {}, options), config)

export const mutationCargosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/cargos/{id}', pathParams, {}, options)
export const useMutationCargosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cargos/{id}', pathParams, {}, options), config)

export const mutationCargosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/cargos/{id}', pathParams, {}, options)
export const useMutationCargosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/cargos/{id}', pathParams, {}, options), config)

export const queryCargosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cargos/{id}', pathParams)
export const useQueryCargosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCargosControllerObterPorId.queryKey = '/api/v1/cargos/{id}'

export const queryCargosControllerObterUsuariosPorCargo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cargos/{id}/usuarios', pathParams, queryParams)
export const useQueryCargosControllerObterUsuariosPorCargo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}/usuarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCargosControllerObterUsuariosPorCargo.queryKey = '/api/v1/cargos/{id}/usuarios'

export const mutationCargosControllerCadastrarCargoConta = (pathParams, options) => mutationFn('post', '/api/v1/cargos/{id}/contas', pathParams, {}, options)
export const useMutationCargosControllerCadastrarCargoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/cargos/{id}/contas', pathParams, {}, options), config)

export const queryCargosControllerListarCargoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cargos/{id}/contas', pathParams, queryParams)
export const useQueryCargosControllerListarCargoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCargosControllerListarCargoContas.queryKey = '/api/v1/cargos/{id}/contas'

export const queryCargosControllerObterCargoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/cargos/{id}/contas/{contaId}', pathParams)
export const useQueryCargosControllerObterCargoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCargosControllerObterCargoContaPorId.queryKey = '/api/v1/cargos/{id}/contas/{contaId}'

export const mutationCargosControllerAtualizarCargoConta = (pathParams, options) => mutationFn('put', '/api/v1/cargos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCargosControllerAtualizarCargoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cargos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCargosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cargos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCargosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCargosControllerListarContasDisponiveis.queryKey = '/api/v1/cargos/{id}/contas-disponiveis'

export const queryCargosControllerListarFuncionarios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cargos/{id}/funcionarios', pathParams, queryParams)
export const useQueryCargosControllerListarFuncionarios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}/funcionarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCargosControllerListarFuncionarios.queryKey = '/api/v1/cargos/{id}/funcionarios'

export const queryCargosControllerListarFuncionariosPorChave = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cargos/{chave}/funcionarios', pathParams, queryParams)
export const useQueryCargosControllerListarFuncionariosPorChave = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{chave}/funcionarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryCargosControllerListarFuncionariosPorChave.queryKey = '/api/v1/cargos/{chave}/funcionarios'

export const mutationCartoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/cartoes', {}, {}, options)
export const useMutationCartoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/cartoes', {}, {}, options), config)

export const queryCartoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cartoes', queryParams)
export const useQueryCartoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCartoesControllerListar.queryKey = '/api/v1/cartoes'

export const queryCartoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cartoes/{id}', pathParams)
export const useQueryCartoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCartoesControllerObterPorId.queryKey = '/api/v1/cartoes/{id}'

export const mutationCartoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/cartoes/{id}', pathParams, {}, options)
export const useMutationCartoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cartoes/{id}', pathParams, {}, options), config)

export const mutationCartoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/cartoes/{id}', pathParams, {}, options)
export const useMutationCartoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/cartoes/{id}', pathParams, {}, options), config)

export const mutationCartoesControllerCadastrarCartaoConta = (pathParams, options) => mutationFn('post', '/api/v1/cartoes/{id}/contas', pathParams, {}, options)
export const useMutationCartoesControllerCadastrarCartaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/cartoes/{id}/contas', pathParams, {}, options), config)

export const queryCartoesControllerListarCartaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cartoes/{id}/contas', pathParams, queryParams)
export const useQueryCartoesControllerListarCartaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCartoesControllerListarCartaoContas.queryKey = '/api/v1/cartoes/{id}/contas'

export const queryCartoesControllerObterCartaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/cartoes/{id}/contas/{contaId}', pathParams)
export const useQueryCartoesControllerObterCartaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCartoesControllerObterCartaoContaPorId.queryKey = '/api/v1/cartoes/{id}/contas/{contaId}'

export const mutationCartoesControllerAtualizarCartaoConta = (pathParams, options) => mutationFn('put', '/api/v1/cartoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCartoesControllerAtualizarCartaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cartoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCartoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cartoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCartoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCartoesControllerListarContasDisponiveis.queryKey = '/api/v1/cartoes/{id}/contas-disponiveis'

export const mutationCartoesControllerCadastrarConfiguracaoCartao = (pathParams, options) => mutationFn('post', '/api/v1/cartoes/{id}/configuracoes', pathParams, {}, options)
export const useMutationCartoesControllerCadastrarConfiguracaoCartao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/cartoes/{id}/configuracoes', pathParams, {}, options), config)

export const queryCartoesControllerListarConfiguracoesCartao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cartoes/{id}/configuracoes', pathParams, queryParams)
export const useQueryCartoesControllerListarConfiguracoesCartao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}/configuracoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCartoesControllerListarConfiguracoesCartao.queryKey = '/api/v1/cartoes/{id}/configuracoes'

export const queryCartoesControllerObterConfiguracaoCartaoPorId = (pathParams, options) => queryFn(options)('/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams)
export const useQueryCartoesControllerObterConfiguracaoCartaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.configuracaoCartaoId, ...config }
})
useQueryCartoesControllerObterConfiguracaoCartaoPorId.queryKey = '/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}'

export const mutationCartoesControllerAtualizarConfiguracaoCartao = (pathParams, options) => mutationFn('patch', '/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams, {}, options)
export const useMutationCartoesControllerAtualizarConfiguracaoCartao = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams, {}, options), config)

export const mutationCartoesControllerDeletarConfiguracaoCartao = (pathParams, options) => mutationFn('delete', '/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams, {}, options)
export const useMutationCartoesControllerDeletarConfiguracaoCartao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams, {}, options), config)

export const mutationCategoriasControllerCadastrar = (options) => mutationFn('post', '/api/v1/categorias', {}, {}, options)
export const useMutationCategoriasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/categorias', {}, {}, options), config)

export const queryCategoriasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/categorias', queryParams)
export const useQueryCategoriasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCategoriasControllerBuscar.queryKey = '/api/v1/categorias'

export const mutationCategoriasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/categorias/{id}', pathParams, {}, options)
export const useMutationCategoriasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/categorias/{id}', pathParams, {}, options), config)

export const mutationCategoriasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/categorias/{id}', pathParams, {}, options)
export const useMutationCategoriasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/categorias/{id}', pathParams, {}, options), config)

export const queryCategoriasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/categorias/{id}', pathParams)
export const useQueryCategoriasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasControllerObterPorId.queryKey = '/api/v1/categorias/{id}'

export const queryCategoriasControllerBuscarSubcategorias = (queryParams, options) => queryFn(options)('/api/v1/categorias/subcategorias', queryParams)
export const useQueryCategoriasControllerBuscarSubcategorias = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/subcategorias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCategoriasControllerBuscarSubcategorias.queryKey = '/api/v1/categorias/subcategorias'

export const queryCategoriasControllerBuscarCategoriasPai = (queryParams, options) => queryFn(options)('/api/v1/categorias/categorias-pai', queryParams)
export const useQueryCategoriasControllerBuscarCategoriasPai = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/categorias-pai', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCategoriasControllerBuscarCategoriasPai.queryKey = '/api/v1/categorias/categorias-pai'

export const queryCategoriasControllerBuscarSubcategoriasPorCategoriaId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/categorias/{id}/subcategorias', pathParams, queryParams)
export const useQueryCategoriasControllerBuscarSubcategoriasPorCategoriaId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/{id}/subcategorias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasControllerBuscarSubcategoriasPorCategoriaId.queryKey = '/api/v1/categorias/{id}/subcategorias'

export const queryCategoriasControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/categorias/buscar-detalhado/{id}', pathParams)
export const useQueryCategoriasControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasControllerObterPorIdDetalhado.queryKey = '/api/v1/categorias/buscar-detalhado/{id}'

export const mutationCategoriasControllerCadastrarCategoriaConta = (pathParams, options) => mutationFn('post', '/api/v1/categorias/{id}/contas', pathParams, {}, options)
export const useMutationCategoriasControllerCadastrarCategoriaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/categorias/{id}/contas', pathParams, {}, options), config)

export const queryCategoriasControllerListarCategoriaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/categorias/{id}/contas', pathParams, queryParams)
export const useQueryCategoriasControllerListarCategoriaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasControllerListarCategoriaContas.queryKey = '/api/v1/categorias/{id}/contas'

export const queryCategoriasControllerObterCategoriaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/categorias/{id}/contas/{contaId}', pathParams)
export const useQueryCategoriasControllerObterCategoriaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCategoriasControllerObterCategoriaContaPorId.queryKey = '/api/v1/categorias/{id}/contas/{contaId}'

export const mutationCategoriasControllerAtualizarCategoriaConta = (pathParams, options) => mutationFn('put', '/api/v1/categorias/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCategoriasControllerAtualizarCategoriaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/categorias/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCategoriasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/categorias/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCategoriasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasControllerListarContasDisponiveis.queryKey = '/api/v1/categorias/{id}/contas-disponiveis'

export const queryCategoriasAgendamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/categorias-de-agendamento', queryParams)
export const useQueryCategoriasAgendamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias-de-agendamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCategoriasAgendamentoControllerListar.queryKey = '/api/v1/categorias-de-agendamento'

export const mutationCategoriasAgendamentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/categorias-de-agendamento', {}, {}, options)
export const useMutationCategoriasAgendamentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/categorias-de-agendamento', {}, {}, options), config)

export const queryCategoriasAgendamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/categorias-de-agendamento/{id}', pathParams)
export const useQueryCategoriasAgendamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias-de-agendamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasAgendamentoControllerObterPorId.queryKey = '/api/v1/categorias-de-agendamento/{id}'

export const mutationCategoriasAgendamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/categorias-de-agendamento/{id}', pathParams, {}, options)
export const useMutationCategoriasAgendamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/categorias-de-agendamento/{id}', pathParams, {}, options), config)

export const mutationCategoriasAgendamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/categorias-de-agendamento/{id}', pathParams, {}, options)
export const useMutationCategoriasAgendamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/categorias-de-agendamento/{id}', pathParams, {}, options), config)

export const queryCategoriasAgendamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/categorias-de-agendamento/{chave}', pathParams)
export const useQueryCategoriasAgendamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias-de-agendamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryCategoriasAgendamentoControllerObterPorChave.queryKey = '/api/v1/categorias-de-agendamento/{chave}'

export const mutationCategoriasAgendamentoControllerCadastrarCategoriaAgendamentoConta = (pathParams, options) => mutationFn('post', '/api/v1/categorias-de-agendamento/{id}/contas', pathParams, {}, options)
export const useMutationCategoriasAgendamentoControllerCadastrarCategoriaAgendamentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/categorias-de-agendamento/{id}/contas', pathParams, {}, options), config)

export const queryCategoriasAgendamentoControllerListarCategoriaAgendamentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/categorias-de-agendamento/{id}/contas', pathParams, queryParams)
export const useQueryCategoriasAgendamentoControllerListarCategoriaAgendamentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias-de-agendamento/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasAgendamentoControllerListarCategoriaAgendamentoContas.queryKey = '/api/v1/categorias-de-agendamento/{id}/contas'

export const queryCategoriasAgendamentoControllerObterCategoriaAgendamentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/categorias-de-agendamento/{id}/contas/{contaId}', pathParams)
export const useQueryCategoriasAgendamentoControllerObterCategoriaAgendamentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias-de-agendamento/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCategoriasAgendamentoControllerObterCategoriaAgendamentoContaPorId.queryKey = '/api/v1/categorias-de-agendamento/{id}/contas/{contaId}'

export const mutationCategoriasAgendamentoControllerAtualizarCategoriaAgendamentoConta = (pathParams, options) => mutationFn('put', '/api/v1/categorias-de-agendamento/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCategoriasAgendamentoControllerAtualizarCategoriaAgendamentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/categorias-de-agendamento/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCategoriasAgendamentoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/categorias-de-agendamento/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCategoriasAgendamentoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias-de-agendamento/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasAgendamentoControllerListarContasDisponiveis.queryKey = '/api/v1/categorias-de-agendamento/{id}/contas-disponiveis'

export const queryCategoriasAgendamentoControllerListarTemplatesComerciais = (pathParams, queryParams, options) => queryFn(options)('/api/v1/categorias-de-agendamento/{id}/templates-comerciais', pathParams, queryParams)
export const useQueryCategoriasAgendamentoControllerListarTemplatesComerciais = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias-de-agendamento/{id}/templates-comerciais', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasAgendamentoControllerListarTemplatesComerciais.queryKey = '/api/v1/categorias-de-agendamento/{id}/templates-comerciais'

export const queryCategoriasTelefoneControllerListar = (queryParams, options) => queryFn(options)('/api/v1/categorias-de-telefone', queryParams)
export const useQueryCategoriasTelefoneControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias-de-telefone', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCategoriasTelefoneControllerListar.queryKey = '/api/v1/categorias-de-telefone'

export const queryCbosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cbos/{id}', pathParams)
export const useQueryCbosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cbos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCbosControllerObterPorId.queryKey = '/api/v1/cbos/{id}'

export const queryCbosControllerObterPorCodigo = (pathParams, options) => queryFn(options)('/api/v1/cbos/{codigo}', pathParams)
export const useQueryCbosControllerObterPorCodigo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cbos/{codigo}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.codigo, ...config }
})
useQueryCbosControllerObterPorCodigo.queryKey = '/api/v1/cbos/{codigo}'

export const queryCbosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/cbos', queryParams)
export const useQueryCbosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cbos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCbosControllerBuscar.queryKey = '/api/v1/cbos'

export const queryCentralDocumentosControllerObter = (queryParams, options) => queryFn(options)('/api/v1/central-de-documentos', queryParams)
export const useQueryCentralDocumentosControllerObter = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-de-documentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCentralDocumentosControllerObter.queryKey = '/api/v1/central-de-documentos'

export const queryCentralDocumentosControllerObterIndicadores = (options) => queryFn(options)('/api/v1/central-de-documentos/indicadores')
export const useQueryCentralDocumentosControllerObterIndicadores = (config, options) => useQuery({
  queryKey: ['/api/v1/central-de-documentos/indicadores'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCentralDocumentosControllerObterIndicadores.queryKey = '/api/v1/central-de-documentos/indicadores'

export const mutationCentralDocumentosControllerCancelarNfse = (pathParams, options) => mutationFn('put', '/api/v1/central-de-documentos/{id}/cancelar-nfse', pathParams, {}, options)
export const useMutationCentralDocumentosControllerCancelarNfse = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/central-de-documentos/{id}/cancelar-nfse', pathParams, {}, options), config)

export const queryCentralDocumentosControllerObterHistoricoDocumentoFiscal = (pathParams, options) => queryFn(options)('/api/v1/central-de-documentos/{id}/historico-documento-fiscal', pathParams)
export const useQueryCentralDocumentosControllerObterHistoricoDocumentoFiscal = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-de-documentos/{id}/historico-documento-fiscal', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCentralDocumentosControllerObterHistoricoDocumentoFiscal.queryKey = '/api/v1/central-de-documentos/{id}/historico-documento-fiscal'

export const queryCentralDocumentosControllerObterLinkPdf = (pathParams, options) => queryFn(options)('/api/v1/central-de-documentos/{rpsId}/link-pdf', pathParams)
export const useQueryCentralDocumentosControllerObterLinkPdf = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-de-documentos/{rpsId}/link-pdf', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.rpsId, ...config }
})
useQueryCentralDocumentosControllerObterLinkPdf.queryKey = '/api/v1/central-de-documentos/{rpsId}/link-pdf'

export const mutationCentralTissControllerCadastrarFatura = (options) => mutationFn('post', '/api/v1/central-tiss/faturas', {}, {}, options)
export const useMutationCentralTissControllerCadastrarFatura = (config, options) => useMutation(mutationFn('post', '/api/v1/central-tiss/faturas', {}, {}, options), config)

export const queryCentralTissControllerListarFaturas = (queryParams, options) => queryFn(options)('/api/v1/central-tiss/faturas', queryParams)
export const useQueryCentralTissControllerListarFaturas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-tiss/faturas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCentralTissControllerListarFaturas.queryKey = '/api/v1/central-tiss/faturas'

export const mutationCentralTissControllerAtualizarTissFatura = (pathParams, options) => mutationFn('put', '/api/v1/central-tiss/faturas/{id}', pathParams, {}, options)
export const useMutationCentralTissControllerAtualizarTissFatura = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/central-tiss/faturas/{id}', pathParams, {}, options), config)

export const queryCentralTissControllerObterFaturaPorId = (pathParams, options) => queryFn(options)('/api/v1/central-tiss/faturas/{id}', pathParams)
export const useQueryCentralTissControllerObterFaturaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-tiss/faturas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCentralTissControllerObterFaturaPorId.queryKey = '/api/v1/central-tiss/faturas/{id}'

export const mutationCentralTissControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/central-tiss/faturas/{id}', pathParams, {}, options)
export const useMutationCentralTissControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/central-tiss/faturas/{id}', pathParams, {}, options), config)

export const mutationCentralTissControllerFecharTissFatura = (pathParams, options) => mutationFn('post', '/api/v1/central-tiss/faturas/{id}/fechar', pathParams, {}, options)
export const useMutationCentralTissControllerFecharTissFatura = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/central-tiss/faturas/{id}/fechar', pathParams, {}, options), config)

export const queryCentralTissControllerListarFaturaGuias = (pathParams, queryParams, options) => queryFn(options)('/api/v1/central-tiss/faturas/{id}/guias', pathParams, queryParams)
export const useQueryCentralTissControllerListarFaturaGuias = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-tiss/faturas/{id}/guias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCentralTissControllerListarFaturaGuias.queryKey = '/api/v1/central-tiss/faturas/{id}/guias'

export const queryCentralTissControllerListarGuias = (queryParams, options) => queryFn(options)('/api/v1/central-tiss/faturas/guias', queryParams)
export const useQueryCentralTissControllerListarGuias = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-tiss/faturas/guias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCentralTissControllerListarGuias.queryKey = '/api/v1/central-tiss/faturas/guias'

export const mutationCentralTissControllerGerarFaturaXml = (pathParams, options) => mutationFn('post', '/api/v1/central-tiss/faturas/{id}/gerar-xml', pathParams, {}, options)
export const useMutationCentralTissControllerGerarFaturaXml = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/central-tiss/faturas/{id}/gerar-xml', pathParams, {}, options), config)

export const queryCentralTissControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/central-tiss/faturas/{id}/arquivos', pathParams, queryParams)
export const useQueryCentralTissControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-tiss/faturas/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCentralTissControllerListarArquivos.queryKey = '/api/v1/central-tiss/faturas/{id}/arquivos'

export const mutationCentralTissControllerTransferirGuias = (pathParams, options) => mutationFn('post', '/api/v1/central-tiss/faturas/{id}/transferir-guias', pathParams, {}, options)
export const useMutationCentralTissControllerTransferirGuias = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/central-tiss/faturas/{id}/transferir-guias', pathParams, {}, options), config)

export const queryCentralTissControllerListarFaturasGuia = (pathParams, queryParams, options) => queryFn(options)('/api/v1/central-tiss/faturas/{id}/convenios/{convenioId}', pathParams, queryParams)
export const useQueryCentralTissControllerListarFaturasGuia = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-tiss/faturas/{id}/convenios/{convenioId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.convenioId, ...config }
})
useQueryCentralTissControllerListarFaturasGuia.queryKey = '/api/v1/central-tiss/faturas/{id}/convenios/{convenioId}'

export const mutationCentrosCustoControllerCadastrar = (options) => mutationFn('post', '/api/v1/centros-de-custo', {}, {}, options)
export const useMutationCentrosCustoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/centros-de-custo', {}, {}, options), config)

export const queryCentrosCustoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/centros-de-custo', queryParams)
export const useQueryCentrosCustoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/centros-de-custo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCentrosCustoControllerBuscar.queryKey = '/api/v1/centros-de-custo'

export const queryCentrosCustoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/centros-de-custo/{id}', pathParams)
export const useQueryCentrosCustoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/centros-de-custo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCentrosCustoControllerObterPorId.queryKey = '/api/v1/centros-de-custo/{id}'

export const mutationCentrosCustoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/centros-de-custo/{id}', pathParams, {}, options)
export const useMutationCentrosCustoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/centros-de-custo/{id}', pathParams, {}, options), config)

export const mutationCentrosCustoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/centros-de-custo/{id}', pathParams, {}, options)
export const useMutationCentrosCustoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/centros-de-custo/{id}', pathParams, {}, options), config)

export const mutationCentrosCustoControllerCadastrarCentroCustoConta = (pathParams, options) => mutationFn('post', '/api/v1/centros-de-custo/{id}/contas', pathParams, {}, options)
export const useMutationCentrosCustoControllerCadastrarCentroCustoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/centros-de-custo/{id}/contas', pathParams, {}, options), config)

export const queryCentrosCustoControllerListarCentroCustoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/centros-de-custo/{id}/contas', pathParams, queryParams)
export const useQueryCentrosCustoControllerListarCentroCustoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/centros-de-custo/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCentrosCustoControllerListarCentroCustoContas.queryKey = '/api/v1/centros-de-custo/{id}/contas'

export const queryCentrosCustoControllerObterCentroCustoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/centros-de-custo/{id}/contas/{contaId}', pathParams)
export const useQueryCentrosCustoControllerObterCentroCustoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/centros-de-custo/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCentrosCustoControllerObterCentroCustoContaPorId.queryKey = '/api/v1/centros-de-custo/{id}/contas/{contaId}'

export const mutationCentrosCustoControllerAtualizarCentroCustoConta = (pathParams, options) => mutationFn('put', '/api/v1/centros-de-custo/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCentrosCustoControllerAtualizarCentroCustoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/centros-de-custo/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCentrosCustoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/centros-de-custo/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCentrosCustoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/centros-de-custo/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCentrosCustoControllerListarContasDisponiveis.queryKey = '/api/v1/centros-de-custo/{id}/contas-disponiveis'

export const queryCfopsControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cfops', queryParams)
export const useQueryCfopsControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cfops', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCfopsControllerListar.queryKey = '/api/v1/cfops'

export const queryCfopsControllerListarEntrada = (queryParams, options) => queryFn(options)('/api/v1/cfops/entrada', queryParams)
export const useQueryCfopsControllerListarEntrada = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cfops/entrada', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCfopsControllerListarEntrada.queryKey = '/api/v1/cfops/entrada'

export const queryCfopsControllerListarSaida = (queryParams, options) => queryFn(options)('/api/v1/cfops/saida', queryParams)
export const useQueryCfopsControllerListarSaida = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cfops/saida', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCfopsControllerListarSaida.queryKey = '/api/v1/cfops/saida'

export const queryCfopsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cfops/{id}', pathParams)
export const useQueryCfopsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cfops/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCfopsControllerObterPorId.queryKey = '/api/v1/cfops/{id}'

export const mutationChequesControllerCadastrar = (options) => mutationFn('post', '/api/v1/cheques', {}, {}, options)
export const useMutationChequesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/cheques', {}, {}, options), config)

export const queryChequesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cheques', queryParams)
export const useQueryChequesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryChequesControllerListar.queryKey = '/api/v1/cheques'

export const queryChequesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cheques/{id}', pathParams)
export const useQueryChequesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChequesControllerObterPorId.queryKey = '/api/v1/cheques/{id}'

export const mutationChequesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/cheques/{id}', pathParams, {}, options)
export const useMutationChequesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cheques/{id}', pathParams, {}, options), config)

export const mutationChequesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/cheques/{id}', pathParams, {}, options)
export const useMutationChequesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/cheques/{id}', pathParams, {}, options), config)

export const queryChequesControllerListarBaixaTitulosCheques = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cheques/{id}/baixas-de-titulos', pathParams, queryParams)
export const useQueryChequesControllerListarBaixaTitulosCheques = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/{id}/baixas-de-titulos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChequesControllerListarBaixaTitulosCheques.queryKey = '/api/v1/cheques/{id}/baixas-de-titulos'

export const mutationChequesControllerCadastrarChequeConta = (pathParams, options) => mutationFn('post', '/api/v1/cheques/{id}/contas', pathParams, {}, options)
export const useMutationChequesControllerCadastrarChequeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/cheques/{id}/contas', pathParams, {}, options), config)

export const queryChequesControllerListarChequeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cheques/{id}/contas', pathParams, queryParams)
export const useQueryChequesControllerListarChequeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChequesControllerListarChequeContas.queryKey = '/api/v1/cheques/{id}/contas'

export const queryChequesControllerObterChequeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/cheques/{id}/contas/{contaId}', pathParams)
export const useQueryChequesControllerObterChequeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryChequesControllerObterChequeContaPorId.queryKey = '/api/v1/cheques/{id}/contas/{contaId}'

export const mutationChequesControllerAtualizarChequeConta = (pathParams, options) => mutationFn('put', '/api/v1/cheques/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationChequesControllerAtualizarChequeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cheques/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryChequesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cheques/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryChequesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChequesControllerListarContasDisponiveis.queryKey = '/api/v1/cheques/{id}/contas-disponiveis'

export const queryChequesControllerListarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/cheques/listar-filtros', queryParams)
export const useQueryChequesControllerListarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryChequesControllerListarPorFiltros.queryKey = '/api/v1/cheques/listar-filtros'

export const queryCIDControllerListarEmAberto = (queryParams, options) => queryFn(options)('/api/v1/cids', queryParams)
export const useQueryCIDControllerListarEmAberto = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cids', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCIDControllerListarEmAberto.queryKey = '/api/v1/cids'

export const queryCIDControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cids/{id}', pathParams)
export const useQueryCIDControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cids/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCIDControllerObterPorId.queryKey = '/api/v1/cids/{id}'

export const mutationClassificacoesProdutoControllerCadastrar = (options) => mutationFn('post', '/api/v1/classificacoes-de-produto', {}, {}, options)
export const useMutationClassificacoesProdutoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/classificacoes-de-produto', {}, {}, options), config)

export const queryClassificacoesProdutoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/classificacoes-de-produto', queryParams)
export const useQueryClassificacoesProdutoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/classificacoes-de-produto', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryClassificacoesProdutoControllerListar.queryKey = '/api/v1/classificacoes-de-produto'

export const queryClassificacoesProdutoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/classificacoes-de-produto/{id}', pathParams)
export const useQueryClassificacoesProdutoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/classificacoes-de-produto/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClassificacoesProdutoControllerObterPorId.queryKey = '/api/v1/classificacoes-de-produto/{id}'

export const mutationClassificacoesProdutoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/classificacoes-de-produto/{id}', pathParams, {}, options)
export const useMutationClassificacoesProdutoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/classificacoes-de-produto/{id}', pathParams, {}, options), config)

export const mutationClassificacoesProdutoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/classificacoes-de-produto/{id}', pathParams, {}, options)
export const useMutationClassificacoesProdutoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/classificacoes-de-produto/{id}', pathParams, {}, options), config)

export const mutationClassificacoesProdutoControllerCadastrarClassificacaoProdutoConta = (pathParams, options) => mutationFn('post', '/api/v1/classificacoes-de-produto/{id}/contas', pathParams, {}, options)
export const useMutationClassificacoesProdutoControllerCadastrarClassificacaoProdutoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/classificacoes-de-produto/{id}/contas', pathParams, {}, options), config)

export const queryClassificacoesProdutoControllerListarClassificacaoProdutoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/classificacoes-de-produto/{id}/contas', pathParams, queryParams)
export const useQueryClassificacoesProdutoControllerListarClassificacaoProdutoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/classificacoes-de-produto/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClassificacoesProdutoControllerListarClassificacaoProdutoContas.queryKey = '/api/v1/classificacoes-de-produto/{id}/contas'

export const queryClassificacoesProdutoControllerObterClassificacaoProdutoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/classificacoes-de-produto/{id}/contas/{contaId}', pathParams)
export const useQueryClassificacoesProdutoControllerObterClassificacaoProdutoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/classificacoes-de-produto/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryClassificacoesProdutoControllerObterClassificacaoProdutoContaPorId.queryKey = '/api/v1/classificacoes-de-produto/{id}/contas/{contaId}'

export const mutationClassificacoesProdutoControllerAtualizarClassificacaoProdutoConta = (pathParams, options) => mutationFn('put', '/api/v1/classificacoes-de-produto/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationClassificacoesProdutoControllerAtualizarClassificacaoProdutoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/classificacoes-de-produto/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryClassificacoesProdutoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/classificacoes-de-produto/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryClassificacoesProdutoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/classificacoes-de-produto/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClassificacoesProdutoControllerListarContasDisponiveis.queryKey = '/api/v1/classificacoes-de-produto/{id}/contas-disponiveis'

export const queryClienteConveniosControllerObterClienteConvenioPorId = (pathParams, options) => queryFn(options)('/api/v1/clientes-convenios/{id}', pathParams)
export const useQueryClienteConveniosControllerObterClienteConvenioPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes-convenios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClienteConveniosControllerObterClienteConvenioPorId.queryKey = '/api/v1/clientes-convenios/{id}'

export const mutationClienteConveniosControllerAtualizarClienteConvenio = (pathParams, options) => mutationFn('put', '/api/v1/clientes-convenios/{id}', pathParams, {}, options)
export const useMutationClienteConveniosControllerAtualizarClienteConvenio = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/clientes-convenios/{id}', pathParams, {}, options), config)

export const mutationClienteConveniosControllerDeletarClienteConvenio = (pathParams, options) => mutationFn('delete', '/api/v1/clientes-convenios/{id}', pathParams, {}, options)
export const useMutationClienteConveniosControllerDeletarClienteConvenio = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/clientes-convenios/{id}', pathParams, {}, options), config)

export const queryClientesControllerListarAgendamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/agendamentos', pathParams, queryParams)
export const useQueryClientesControllerListarAgendamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/agendamentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarAgendamentos.queryKey = '/api/v1/clientes/{id}/agendamentos'

export const queryClientesControllerListarAgendamentosPorFuncionario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/agendamentos/{funcionarioId}', pathParams, queryParams)
export const useQueryClientesControllerListarAgendamentosPorFuncionario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/agendamentos/{funcionarioId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.funcionarioId, ...config }
})
useQueryClientesControllerListarAgendamentosPorFuncionario.queryKey = '/api/v1/clientes/{id}/agendamentos/{funcionarioId}'

export const queryClientesControllerListarAgendamentosDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/agendamentos-disponiveis', pathParams, queryParams)
export const useQueryClientesControllerListarAgendamentosDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/agendamentos-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarAgendamentosDisponiveis.queryKey = '/api/v1/clientes/{id}/agendamentos-disponiveis'

export const queryClientesControllerObterIndicadoresAtendimento = (pathParams, options) => queryFn(options)('/api/v1/clientes/{id}/indicadores-atendimento', pathParams)
export const useQueryClientesControllerObterIndicadoresAtendimento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/indicadores-atendimento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerObterIndicadoresAtendimento.queryKey = '/api/v1/clientes/{id}/indicadores-atendimento'

export const mutationClientesControllerCadastrarClienteConvenio = (pathParams, options) => mutationFn('post', '/api/v1/clientes/{id}/convenios', pathParams, {}, options)
export const useMutationClientesControllerCadastrarClienteConvenio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/{id}/convenios', pathParams, {}, options), config)

export const queryClientesControllerListarClienteConvenios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/convenios', pathParams, queryParams)
export const useQueryClientesControllerListarClienteConvenios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/convenios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarClienteConvenios.queryKey = '/api/v1/clientes/{id}/convenios'

export const queryClientesControllerListarClienteConvenioPadrao = (pathParams, options) => queryFn(options)('/api/v1/clientes/{id}/convenio-padrao', pathParams)
export const useQueryClientesControllerListarClienteConvenioPadrao = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/convenio-padrao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarClienteConvenioPadrao.queryKey = '/api/v1/clientes/{id}/convenio-padrao'

export const queryClientesControllerObterClienteConvenioPorConvenioId = (pathParams, options) => queryFn(options)('/api/v1/clientes/{id}/convenio/{convenioId}', pathParams)
export const useQueryClientesControllerObterClienteConvenioPorConvenioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/convenio/{convenioId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.convenioId, ...config }
})
useQueryClientesControllerObterClienteConvenioPorConvenioId.queryKey = '/api/v1/clientes/{id}/convenio/{convenioId}'

export const queryClientesControllerBuscarProximoNumeroProntuario = (options) => queryFn(options)('/api/v1/clientes/proximo-num-prontuario')
export const useQueryClientesControllerBuscarProximoNumeroProntuario = (config, options) => useQuery({
  queryKey: ['/api/v1/clientes/proximo-num-prontuario'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryClientesControllerBuscarProximoNumeroProntuario.queryKey = '/api/v1/clientes/proximo-num-prontuario'

export const queryClientesControllerBuscarClientesSaude = (queryParams, options) => queryFn(options)('/api/v1/clientes', queryParams)
export const useQueryClientesControllerBuscarClientesSaude = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryClientesControllerBuscarClientesSaude.queryKey = '/api/v1/clientes'

export const mutationClientesControllerCadastrarClienteSaude = (options) => mutationFn('post', '/api/v1/clientes', {}, {}, options)
export const useMutationClientesControllerCadastrarClienteSaude = (config, options) => useMutation(mutationFn('post', '/api/v1/clientes', {}, {}, options), config)

export const queryClientesControllerObterClienteSaudePorId = (pathParams, options) => queryFn(options)('/api/v1/clientes/{id}', pathParams)
export const useQueryClientesControllerObterClienteSaudePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerObterClienteSaudePorId.queryKey = '/api/v1/clientes/{id}'

export const mutationClientesControllerAtualizarClienteSaude = (pathParams, options) => mutationFn('put', '/api/v1/clientes/{id}', pathParams, {}, options)
export const useMutationClientesControllerAtualizarClienteSaude = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/clientes/{id}', pathParams, {}, options), config)

export const mutationClientesControllerDeletarClienteSaude = (pathParams, options) => mutationFn('delete', '/api/v1/clientes/{id}', pathParams, {}, options)
export const useMutationClientesControllerDeletarClienteSaude = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/clientes/{id}', pathParams, {}, options), config)

export const mutationClientesControllerAtualizarClienteSaudeConta = (pathParams, options) => mutationFn('put', '/api/v1/clientes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationClientesControllerAtualizarClienteSaudeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/clientes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryClientesControllerObterClienteContaPorId = (pathParams, options) => queryFn(options)('/api/v1/clientes/{id}/contas/{contaId}', pathParams)
export const useQueryClientesControllerObterClienteContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryClientesControllerObterClienteContaPorId.queryKey = '/api/v1/clientes/{id}/contas/{contaId}'

export const queryClientesControllerObterPacientePorClienteId = (pathParams, options) => queryFn(options)('/api/v1/clientes/{id}/paciente', pathParams)
export const useQueryClientesControllerObterPacientePorClienteId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/paciente', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerObterPacientePorClienteId.queryKey = '/api/v1/clientes/{id}/paciente'

export const queryClientesControllerListarAgendamentosCliente = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/agendamentos/lista', pathParams, queryParams)
export const useQueryClientesControllerListarAgendamentosCliente = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/agendamentos/lista', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarAgendamentosCliente.queryKey = '/api/v1/clientes/{id}/agendamentos/lista'

export const queryClientesControllerListarCamposRelatorio = (queryParams, options) => queryFn(options)('/api/v1/clientes/report/campos-disponiveis', queryParams)
export const useQueryClientesControllerListarCamposRelatorio = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/report/campos-disponiveis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryClientesControllerListarCamposRelatorio.queryKey = '/api/v1/clientes/report/campos-disponiveis'

export const mutationClientesControllerImportarClientes = (queryParams, options) => mutationFn('post', '/api/v1/clientes/importacao', {}, queryParams, options)
export const useMutationClientesControllerImportarClientes = (queryParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/importacao', {}, queryParams, options), config)

export const mutationClientesControllerCadastrarClienteTag = (pathParams, options) => mutationFn('post', '/api/v1/clientes/{id}/tags', pathParams, {}, options)
export const useMutationClientesControllerCadastrarClienteTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/{id}/tags', pathParams, {}, options), config)

export const queryClientesControllerListarTagsCliente = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/tags', pathParams, queryParams)
export const useQueryClientesControllerListarTagsCliente = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarTagsCliente.queryKey = '/api/v1/clientes/{id}/tags'

export const mutationClientesControllerDeletarClienteTag = (pathParams, options) => mutationFn('delete', '/api/v1/clientes/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationClientesControllerDeletarClienteTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/clientes/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryClientesControllerListarClientesTags = (queryParams, options) => queryFn(options)('/api/v1/clientes/tags', queryParams)
export const useQueryClientesControllerListarClientesTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryClientesControllerListarClientesTags.queryKey = '/api/v1/clientes/tags'

export const queryClientesControllerBuscarResponsaveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/responsaveis', pathParams, queryParams)
export const useQueryClientesControllerBuscarResponsaveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerBuscarResponsaveis.queryKey = '/api/v1/clientes/{id}/responsaveis'

export const mutationClientesControllerCadastrarResponsavelCliente = (pathParams, options) => mutationFn('post', '/api/v1/clientes/{id}/responsaveis', pathParams, {}, options)
export const useMutationClientesControllerCadastrarResponsavelCliente = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/{id}/responsaveis', pathParams, {}, options), config)

export const queryClientesControllerObterResponsavelFinanceiroPadrao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/responsaveis/financeiro-padrao', pathParams, queryParams)
export const useQueryClientesControllerObterResponsavelFinanceiroPadrao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/responsaveis/financeiro-padrao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerObterResponsavelFinanceiroPadrao.queryKey = '/api/v1/clientes/{id}/responsaveis/financeiro-padrao'

export const mutationClientesControllerAtualizarResponsavelCliente = (pathParams, options) => mutationFn('put', '/api/v1/clientes/responsaveis/{responsavelClienteId}', pathParams, {}, options)
export const useMutationClientesControllerAtualizarResponsavelCliente = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/clientes/responsaveis/{responsavelClienteId}', pathParams, {}, options), config)

export const mutationClientesControllerAtualizarAcessosCliente = (pathParams, options) => mutationFn('patch', '/api/v1/clientes/{id}/acessos', pathParams, {}, options)
export const useMutationClientesControllerAtualizarAcessosCliente = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/clientes/{id}/acessos', pathParams, {}, options), config)

export const queryClientesControllerObterAcessosCliente = (pathParams, options) => queryFn(options)('/api/v1/clientes/{id}/acessos', pathParams)
export const useQueryClientesControllerObterAcessosCliente = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/acessos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerObterAcessosCliente.queryKey = '/api/v1/clientes/{id}/acessos'

export const mutationClientesControllerAtualizarAcessosMultiplosClientes = (options) => mutationFn('patch', '/api/v1/clientes/acessos', {}, {}, options)
export const useMutationClientesControllerAtualizarAcessosMultiplosClientes = (config, options) => useMutation(mutationFn('patch', '/api/v1/clientes/acessos', {}, {}, options), config)

export const mutationClientesControllerEnviarEmailCadastroClienteApp = (pathParams, options) => mutationFn('post', '/api/v1/clientes/{id}/acessos/email-app', pathParams, {}, options)
export const useMutationClientesControllerEnviarEmailCadastroClienteApp = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/{id}/acessos/email-app', pathParams, {}, options), config)

export const mutationClientesControllerDeletarResponsavelCliente = (pathParams, options) => mutationFn('delete', '/api/v1/clientes/{id}/responsaveis/{responsavelId}', pathParams, {}, options)
export const useMutationClientesControllerDeletarResponsavelCliente = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/clientes/{id}/responsaveis/{responsavelId}', pathParams, {}, options), config)

export const queryClientesControllerBuscarResponsavelTipos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/responsaveis/{responsavelClienteId}/tipos', pathParams, queryParams)
export const useQueryClientesControllerBuscarResponsavelTipos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/responsaveis/{responsavelClienteId}/tipos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.responsavelClienteId, ...config }
})
useQueryClientesControllerBuscarResponsavelTipos.queryKey = '/api/v1/clientes/responsaveis/{responsavelClienteId}/tipos'

export const mutationClientesControllerCadastrarResponsavelClienteTipo = (pathParams, options) => mutationFn('post', '/api/v1/clientes/responsaveis/{responsavelId}/tipos', pathParams, {}, options)
export const useMutationClientesControllerCadastrarResponsavelClienteTipo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/responsaveis/{responsavelId}/tipos', pathParams, {}, options), config)

export const mutationClientesControllerDeletarResponsavelClienteTipo = (pathParams, options) => mutationFn('delete', '/api/v1/clientes/responsaveis/{responsavelId}/tipos/{id}', pathParams, {}, options)
export const useMutationClientesControllerDeletarResponsavelClienteTipo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/clientes/responsaveis/{responsavelId}/tipos/{id}', pathParams, {}, options), config)

export const mutationClientesControllerCadastrarClienteConta = (pathParams, options) => mutationFn('post', '/api/v1/clientes/{id}/contas', pathParams, {}, options)
export const useMutationClientesControllerCadastrarClienteConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/{id}/contas', pathParams, {}, options), config)

export const queryClientesControllerListarClienteContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/contas', pathParams, queryParams)
export const useQueryClientesControllerListarClienteContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarClienteContas.queryKey = '/api/v1/clientes/{id}/contas'

export const queryClientesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryClientesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarContasDisponiveis.queryKey = '/api/v1/clientes/{id}/contas-disponiveis'

export const queryClientesControllerListarTagsDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryClientesControllerListarTagsDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarTagsDisponiveis.queryKey = '/api/v1/clientes/{id}/tags-disponiveis'

export const queryClientesControllerListarIndicadoresClientes = (queryParams, options) => queryFn(options)('/api/v1/clientes/indicadores', queryParams)
export const useQueryClientesControllerListarIndicadoresClientes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryClientesControllerListarIndicadoresClientes.queryKey = '/api/v1/clientes/indicadores'

export const queryClientesControllerListarPessoasResponsaveisPorPessoaId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/pessoas-responsaveis', pathParams, queryParams)
export const useQueryClientesControllerListarPessoasResponsaveisPorPessoaId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/pessoas-responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarPessoasResponsaveisPorPessoaId.queryKey = '/api/v1/clientes/{id}/pessoas-responsaveis'

export const queryClientesControllerListarTarefasPorClienteId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/tarefas-acompanhamento', pathParams, queryParams)
export const useQueryClientesControllerListarTarefasPorClienteId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/tarefas-acompanhamento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarTarefasPorClienteId.queryKey = '/api/v1/clientes/{id}/tarefas-acompanhamento'

export const mutationClientesSaudeControllerCadastrarClienteSaudeConta = (pathParams, options) => mutationFn('post', '/api/v1/clientes-saude/{id}/contas', pathParams, {}, options)
export const useMutationClientesSaudeControllerCadastrarClienteSaudeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes-saude/{id}/contas', pathParams, {}, options), config)

export const queryClientesSaudeControllerListarClienteSaudeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes-saude/{id}/contas', pathParams, queryParams)
export const useQueryClientesSaudeControllerListarClienteSaudeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes-saude/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesSaudeControllerListarClienteSaudeContas.queryKey = '/api/v1/clientes-saude/{id}/contas'

export const queryClientesSaudeControllerObterClienteSaudeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/clientes-saude/{id}/contas/{contaId}', pathParams)
export const useQueryClientesSaudeControllerObterClienteSaudeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes-saude/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryClientesSaudeControllerObterClienteSaudeContaPorId.queryKey = '/api/v1/clientes-saude/{id}/contas/{contaId}'

export const queryClientesSaudeDilatacaoControllerObterDilatacoesPorCliente = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes-saude-dilatacao/{clienteId}/dilatacoes-cliente', pathParams, queryParams)
export const useQueryClientesSaudeDilatacaoControllerObterDilatacoesPorCliente = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes-saude-dilatacao/{clienteId}/dilatacoes-cliente', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.clienteId, ...config }
})
useQueryClientesSaudeDilatacaoControllerObterDilatacoesPorCliente.queryKey = '/api/v1/clientes-saude-dilatacao/{clienteId}/dilatacoes-cliente'

export const queryClientesSaudeDilatacaoControllerListarDilatacoes = (queryParams, options) => queryFn(options)('/api/v1/clientes-saude-dilatacao', queryParams)
export const useQueryClientesSaudeDilatacaoControllerListarDilatacoes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes-saude-dilatacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryClientesSaudeDilatacaoControllerListarDilatacoes.queryKey = '/api/v1/clientes-saude-dilatacao'

export const mutationClientesSaudeDilatacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/clientes-saude-dilatacao', {}, {}, options)
export const useMutationClientesSaudeDilatacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/clientes-saude-dilatacao', {}, {}, options), config)

export const mutationClientesSaudeDilatacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/clientes-saude-dilatacao/{id}', pathParams, {}, options)
export const useMutationClientesSaudeDilatacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/clientes-saude-dilatacao/{id}', pathParams, {}, options), config)

export const mutationClientesSaudeDilatacaoControllerAtualizarDilatacaoCliente = (pathParams, options) => mutationFn('put', '/api/v1/clientes-saude-dilatacao/{id}', pathParams, {}, options)
export const useMutationClientesSaudeDilatacaoControllerAtualizarDilatacaoCliente = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/clientes-saude-dilatacao/{id}', pathParams, {}, options), config)

export const queryClientesSaudeDilatacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/clientes-saude-dilatacao/{id}', pathParams)
export const useQueryClientesSaudeDilatacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes-saude-dilatacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesSaudeDilatacaoControllerObterPorId.queryKey = '/api/v1/clientes-saude-dilatacao/{id}'

export const queryCnaesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cnaes', queryParams)
export const useQueryCnaesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cnaes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCnaesControllerListar.queryKey = '/api/v1/cnaes'

export const queryCnaesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cnaes/{id}', pathParams)
export const useQueryCnaesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cnaes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCnaesControllerObterPorId.queryKey = '/api/v1/cnaes/{id}'

export const queryCodigosServicoPrestadoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/codigos-servicos-prestados', queryParams)
export const useQueryCodigosServicoPrestadoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/codigos-servicos-prestados', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCodigosServicoPrestadoControllerListar.queryKey = '/api/v1/codigos-servicos-prestados'

export const queryCodigosServicoPrestadoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/codigos-servicos-prestados/{id}', pathParams)
export const useQueryCodigosServicoPrestadoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/codigos-servicos-prestados/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCodigosServicoPrestadoControllerObterPorId.queryKey = '/api/v1/codigos-servicos-prestados/{id}'

export const queryCondicoesEnvioControllerListar = (queryParams, options) => queryFn(options)('/api/v1/condicoes-envio', queryParams)
export const useQueryCondicoesEnvioControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-envio', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCondicoesEnvioControllerListar.queryKey = '/api/v1/condicoes-envio'

export const queryCondicoesEnvioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/condicoes-envio/{id}', pathParams)
export const useQueryCondicoesEnvioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-envio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesEnvioControllerObterPorId.queryKey = '/api/v1/condicoes-envio/{id}'

export const queryCondicoesEnvioControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/condicoes-envio/{chave}', pathParams)
export const useQueryCondicoesEnvioControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-envio/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryCondicoesEnvioControllerObterPorChave.queryKey = '/api/v1/condicoes-envio/{chave}'

export const mutationCondicoesPagamentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/condicoes-de-pagamento', {}, {}, options)
export const useMutationCondicoesPagamentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/condicoes-de-pagamento', {}, {}, options), config)

export const queryCondicoesPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento', queryParams)
export const useQueryCondicoesPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCondicoesPagamentoControllerListar.queryKey = '/api/v1/condicoes-de-pagamento'

export const queryCondicoesPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}', pathParams)
export const useQueryCondicoesPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoControllerObterPorId.queryKey = '/api/v1/condicoes-de-pagamento/{id}'

export const mutationCondicoesPagamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/condicoes-de-pagamento/{id}', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/condicoes-de-pagamento/{id}', pathParams, {}, options), config)

export const mutationCondicoesPagamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/condicoes-de-pagamento/{id}', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/condicoes-de-pagamento/{id}', pathParams, {}, options), config)

export const mutationCondicoesPagamentoControllerCadastrarCondicaoPagamentoConta = (pathParams, options) => mutationFn('post', '/api/v1/condicoes-de-pagamento/{id}/contas', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerCadastrarCondicaoPagamentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/condicoes-de-pagamento/{id}/contas', pathParams, {}, options), config)

export const queryCondicoesPagamentoControllerListarCondicaoPagamentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/contas', pathParams, queryParams)
export const useQueryCondicoesPagamentoControllerListarCondicaoPagamentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoControllerListarCondicaoPagamentoContas.queryKey = '/api/v1/condicoes-de-pagamento/{id}/contas'

export const queryCondicoesPagamentoControllerObterCondicaoPagamentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/contas/{contaId}', pathParams)
export const useQueryCondicoesPagamentoControllerObterCondicaoPagamentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCondicoesPagamentoControllerObterCondicaoPagamentoContaPorId.queryKey = '/api/v1/condicoes-de-pagamento/{id}/contas/{contaId}'

export const mutationCondicoesPagamentoControllerAtualizarCondicaoPagamentoConta = (pathParams, options) => mutationFn('put', '/api/v1/condicoes-de-pagamento/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerAtualizarCondicaoPagamentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/condicoes-de-pagamento/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCondicoesPagamentoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCondicoesPagamentoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoControllerListarContasDisponiveis.queryKey = '/api/v1/condicoes-de-pagamento/{id}/contas-disponiveis'

export const mutationCondicoesPagamentoControllerCadastrarCondicaoPagamentoRestricao = (pathParams, options) => mutationFn('post', '/api/v1/condicoes-de-pagamento/{id}/restricoes', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerCadastrarCondicaoPagamentoRestricao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/condicoes-de-pagamento/{id}/restricoes', pathParams, {}, options), config)

export const queryCondicoesPagamentoControllerListarCondicaoPagamentoRestricoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/restricoes', pathParams, queryParams)
export const useQueryCondicoesPagamentoControllerListarCondicaoPagamentoRestricoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/restricoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoControllerListarCondicaoPagamentoRestricoes.queryKey = '/api/v1/condicoes-de-pagamento/{id}/restricoes'

export const queryCondicoesPagamentoControllerObterCondicaoPagamentoRestricaoPorId = (pathParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/restricao/{restricaoId}', pathParams)
export const useQueryCondicoesPagamentoControllerObterCondicaoPagamentoRestricaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/restricao/{restricaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.restricaoId, ...config }
})
useQueryCondicoesPagamentoControllerObterCondicaoPagamentoRestricaoPorId.queryKey = '/api/v1/condicoes-de-pagamento/{id}/restricao/{restricaoId}'

export const mutationCondicoesPagamentoControllerDeletarCondicaoPagamentoRestricao = (pathParams, options) => mutationFn('delete', '/api/v1/condicoes-de-pagamento/{id}/restricao/{restricaoId}', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerDeletarCondicaoPagamentoRestricao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/condicoes-de-pagamento/{id}/restricao/{restricaoId}', pathParams, {}, options), config)

export const queryCondicoesPagamentoControllerCalcularValoresParcelas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/parcelas', pathParams, queryParams)
export const useQueryCondicoesPagamentoControllerCalcularValoresParcelas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/parcelas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoControllerCalcularValoresParcelas.queryKey = '/api/v1/condicoes-de-pagamento/{id}/parcelas'

export const mutationCondicoesPagamentoRecorrentesControllerCadastrar = (options) => mutationFn('post', '/api/v1/condicoes-pagamento-recorrentes', {}, {}, options)
export const useMutationCondicoesPagamentoRecorrentesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/condicoes-pagamento-recorrentes', {}, {}, options), config)

export const queryCondicoesPagamentoRecorrentesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/condicoes-pagamento-recorrentes', queryParams)
export const useQueryCondicoesPagamentoRecorrentesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-pagamento-recorrentes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCondicoesPagamentoRecorrentesControllerListar.queryKey = '/api/v1/condicoes-pagamento-recorrentes'

export const mutationCondicoesPagamentoRecorrentesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams, {}, options)
export const useMutationCondicoesPagamentoRecorrentesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams, {}, options), config)

export const mutationCondicoesPagamentoRecorrentesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams, {}, options)
export const useMutationCondicoesPagamentoRecorrentesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams, {}, options), config)

export const queryCondicoesPagamentoRecorrentesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams)
export const useQueryCondicoesPagamentoRecorrentesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoRecorrentesControllerObterPorId.queryKey = '/api/v1/condicoes-pagamento-recorrentes/{id}'

export const mutationConfiguracaoLinkPagamentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/configuracao-link-pagamento', {}, {}, options)
export const useMutationConfiguracaoLinkPagamentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracao-link-pagamento', {}, {}, options), config)

export const queryConfiguracaoLinkPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/configuracao-link-pagamento', queryParams)
export const useQueryConfiguracaoLinkPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-link-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracaoLinkPagamentoControllerListar.queryKey = '/api/v1/configuracao-link-pagamento'

export const mutationConfiguracaoLinkPagamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracao-link-pagamento/{id}', pathParams, {}, options)
export const useMutationConfiguracaoLinkPagamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracao-link-pagamento/{id}', pathParams, {}, options), config)

export const mutationConfiguracaoLinkPagamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/configuracao-link-pagamento/{id}', pathParams, {}, options)
export const useMutationConfiguracaoLinkPagamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracao-link-pagamento/{id}', pathParams, {}, options), config)

export const queryConfiguracaoLinkPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracao-link-pagamento/{id}', pathParams)
export const useQueryConfiguracaoLinkPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-link-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracaoLinkPagamentoControllerObterPorId.queryKey = '/api/v1/configuracao-link-pagamento/{id}'

export const mutationConfiguracaoLinkPagamentoControllerCriarEnviarLinkPagamento = (options) => mutationFn('post', '/api/v1/configuracao-link-pagamento/criar-enviar-link-pagamento', {}, {}, options)
export const useMutationConfiguracaoLinkPagamentoControllerCriarEnviarLinkPagamento = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracao-link-pagamento/criar-enviar-link-pagamento', {}, {}, options), config)

export const queryConfiguracaoLinkPagamentoControllerObterLinkPagamento = (pathParams, options) => queryFn(options)('/api/v1/configuracao-link-pagamento/obter-link-pagamento/{tituloId}', pathParams)
export const useQueryConfiguracaoLinkPagamentoControllerObterLinkPagamento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-link-pagamento/obter-link-pagamento/{tituloId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.tituloId, ...config }
})
useQueryConfiguracaoLinkPagamentoControllerObterLinkPagamento.queryKey = '/api/v1/configuracao-link-pagamento/obter-link-pagamento/{tituloId}'

export const mutationConfiguracaoLinkPagamentoControllerCancelarLinkPagamento = (pathParams, options) => mutationFn('post', '/api/v1/configuracao-link-pagamento/cancelar-link-pagamento/{tituloId}', pathParams, {}, options)
export const useMutationConfiguracaoLinkPagamentoControllerCancelarLinkPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracao-link-pagamento/cancelar-link-pagamento/{tituloId}', pathParams, {}, options), config)

export const queryConfiguracoesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/configuracoes/{chave}', pathParams)
export const useQueryConfiguracoesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryConfiguracoesControllerObterPorChave.queryKey = '/api/v1/configuracoes/{chave}'

export const queryConfiguracoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/configuracoes', queryParams)
export const useQueryConfiguracoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesControllerListar.queryKey = '/api/v1/configuracoes'

export const queryConfiguracoesControllerListarDetalhado = (queryParams, options) => queryFn(options)('/api/v1/configuracoes/detalhes', queryParams)
export const useQueryConfiguracoesControllerListarDetalhado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes/detalhes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesControllerListarDetalhado.queryKey = '/api/v1/configuracoes/detalhes'

export const queryConfiguracoesAppControllerObterConfiguracoes = (options) => queryFn(options)('/api/v1/configuracoes-app')
export const useQueryConfiguracoesAppControllerObterConfiguracoes = (config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-app'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesAppControllerObterConfiguracoes.queryKey = '/api/v1/configuracoes-app'

export const mutationConfiguracoesAppControllerCadastrarConfiguracaoApp = (options) => mutationFn('post', '/api/v1/configuracoes-app', {}, {}, options)
export const useMutationConfiguracoesAppControllerCadastrarConfiguracaoApp = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-app', {}, {}, options), config)

export const mutationConfiguracoesAppControllerAtivarSincronizacaoConfiguracaoApp = (options) => mutationFn('patch', '/api/v1/configuracoes-app/ativar-sincronizacao', {}, {}, options)
export const useMutationConfiguracoesAppControllerAtivarSincronizacaoConfiguracaoApp = (config, options) => useMutation(mutationFn('patch', '/api/v1/configuracoes-app/ativar-sincronizacao', {}, {}, options), config)

export const mutationConfiguracoesAppControllerCadastrarArquivo = (options) => mutationFn('post', '/api/v1/configuracoes-app/arquivo', {}, {}, options)
export const useMutationConfiguracoesAppControllerCadastrarArquivo = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-app/arquivo', {}, {}, options), config)

export const mutationConfiguracoesBolsaControllerCadastrar = (options) => mutationFn('post', '/api/v1/configuracoes-de-bolsa', {}, {}, options)
export const useMutationConfiguracoesBolsaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-bolsa', {}, {}, options), config)

export const queryConfiguracoesBolsaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-bolsa', queryParams)
export const useQueryConfiguracoesBolsaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-bolsa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesBolsaControllerListar.queryKey = '/api/v1/configuracoes-de-bolsa'

export const queryConfiguracoesBolsaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-bolsa/{id}', pathParams)
export const useQueryConfiguracoesBolsaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-bolsa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesBolsaControllerObterPorId.queryKey = '/api/v1/configuracoes-de-bolsa/{id}'

export const mutationConfiguracoesBolsaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-bolsa/{id}', pathParams, {}, options)
export const useMutationConfiguracoesBolsaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-bolsa/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesBolsaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-bolsa/{id}', pathParams, {}, options)
export const useMutationConfiguracoesBolsaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-bolsa/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesBolsaControllerCadastrarConfiguracaoBolsaConta = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-bolsa/{id}/contas', pathParams, {}, options)
export const useMutationConfiguracoesBolsaControllerCadastrarConfiguracaoBolsaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-bolsa/{id}/contas', pathParams, {}, options), config)

export const queryConfiguracoesBolsaControllerListarConfiguracaoBolsaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-bolsa/{id}/contas', pathParams, queryParams)
export const useQueryConfiguracoesBolsaControllerListarConfiguracaoBolsaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-bolsa/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesBolsaControllerListarConfiguracaoBolsaContas.queryKey = '/api/v1/configuracoes-de-bolsa/{id}/contas'

export const queryConfiguracoesBolsaControllerObterConfiguracaoBolsaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-bolsa/{id}/contas/{contaId}', pathParams)
export const useQueryConfiguracoesBolsaControllerObterConfiguracaoBolsaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-bolsa/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryConfiguracoesBolsaControllerObterConfiguracaoBolsaContaPorId.queryKey = '/api/v1/configuracoes-de-bolsa/{id}/contas/{contaId}'

export const mutationConfiguracoesBolsaControllerAtualizarConfiguracaoBolsaConta = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-bolsa/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationConfiguracoesBolsaControllerAtualizarConfiguracaoBolsaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-bolsa/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryConfiguracoesBolsaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-bolsa/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryConfiguracoesBolsaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-bolsa/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesBolsaControllerListarContasDisponiveis.queryKey = '/api/v1/configuracoes-de-bolsa/{id}/contas-disponiveis'

export const queryConfiguracoesCartoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-cartoes/{id}', pathParams)
export const useQueryConfiguracoesCartoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-cartoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesCartoesControllerObterPorId.queryKey = '/api/v1/configuracoes-de-cartoes/{id}'

export const mutationConfiguracoesComissaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/configuracoes-de-comissao', {}, {}, options)
export const useMutationConfiguracoesComissaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-comissao', {}, {}, options), config)

export const queryConfiguracoesComissaoControllerObterLista = (queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao', queryParams)
export const useQueryConfiguracoesComissaoControllerObterLista = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesComissaoControllerObterLista.queryKey = '/api/v1/configuracoes-de-comissao'

export const mutationConfiguracoesComissaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-comissao/{id}', pathParams, {}, options)
export const useMutationConfiguracoesComissaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-comissao/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesComissaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-comissao/{id}', pathParams, {}, options)
export const useMutationConfiguracoesComissaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-comissao/{id}', pathParams, {}, options), config)

export const queryConfiguracoesComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao/{id}', pathParams)
export const useQueryConfiguracoesComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesComissaoControllerObterPorId.queryKey = '/api/v1/configuracoes-de-comissao/{id}'

export const mutationConfiguracoesComissaoControllerCadastrarMeta = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-comissao/{id}/meta', pathParams, {}, options)
export const useMutationConfiguracoesComissaoControllerCadastrarMeta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-comissao/{id}/meta', pathParams, {}, options), config)

export const queryConfiguracoesComissaoControllerObterListaMeta = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao/{id}/metas', pathParams, queryParams)
export const useQueryConfiguracoesComissaoControllerObterListaMeta = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao/{id}/metas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesComissaoControllerObterListaMeta.queryKey = '/api/v1/configuracoes-de-comissao/{id}/metas'

export const mutationConfiguracoesComissaoControllerCadastrarComissaoConta = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-comissao/{id}/contas', pathParams, {}, options)
export const useMutationConfiguracoesComissaoControllerCadastrarComissaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-comissao/{id}/contas', pathParams, {}, options), config)

export const queryConfiguracoesComissaoControllerListarComissaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao/{id}/contas', pathParams, queryParams)
export const useQueryConfiguracoesComissaoControllerListarComissaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesComissaoControllerListarComissaoContas.queryKey = '/api/v1/configuracoes-de-comissao/{id}/contas'

export const queryConfiguracoesComissaoControllerObterComissaoContaPorComissaoIdContaId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao/{id}/contas/{contaId}', pathParams)
export const useQueryConfiguracoesComissaoControllerObterComissaoContaPorComissaoIdContaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryConfiguracoesComissaoControllerObterComissaoContaPorComissaoIdContaId.queryKey = '/api/v1/configuracoes-de-comissao/{id}/contas/{contaId}'

export const mutationConfiguracoesComissaoControllerAtualizarComissaoConta = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-comissao/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationConfiguracoesComissaoControllerAtualizarComissaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-comissao/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryConfiguracoesComissaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryConfiguracoesComissaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesComissaoControllerListarContasDisponiveis.queryKey = '/api/v1/configuracoes-de-comissao/{id}/contas-disponiveis'

export const mutationConfiguracoesConvenioControllerCadastrar = (options) => mutationFn('post', '/api/v1/configuracoes-de-convenio', {}, {}, options)
export const useMutationConfiguracoesConvenioControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-convenio', {}, {}, options), config)

export const queryConfiguracoesConvenioControllerListar = (queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio', queryParams)
export const useQueryConfiguracoesConvenioControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesConvenioControllerListar.queryKey = '/api/v1/configuracoes-de-convenio'

export const queryConfiguracoesConvenioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}', pathParams)
export const useQueryConfiguracoesConvenioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesConvenioControllerObterPorId.queryKey = '/api/v1/configuracoes-de-convenio/{id}'

export const mutationConfiguracoesConvenioControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesConvenioControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioConta = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/contas', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/contas', pathParams, {}, options), config)

export const queryConfiguracoesConvenioControllerListarConfiguracaoConvenioContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/contas', pathParams, queryParams)
export const useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioContas.queryKey = '/api/v1/configuracoes-de-convenio/{id}/contas'

export const queryConfiguracoesConvenioControllerObterConfiguracaoConvenioContaPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/contas/{contaId}', pathParams)
export const useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioContaPorId.queryKey = '/api/v1/configuracoes-de-convenio/{id}/contas/{contaId}'

export const mutationConfiguracoesConvenioControllerAtualizarConfiguracaoConvenioConta = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerAtualizarConfiguracaoConvenioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryConfiguracoesConvenioControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryConfiguracoesConvenioControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesConvenioControllerListarContasDisponiveis.queryKey = '/api/v1/configuracoes-de-convenio/{id}/contas-disponiveis'

export const mutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioDesconto = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/descontos', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/descontos', pathParams, {}, options), config)

export const queryConfiguracoesConvenioControllerListarConfiguracaoConvenioDescontos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/descontos', pathParams, queryParams)
export const useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioDescontos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/descontos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioDescontos.queryKey = '/api/v1/configuracoes-de-convenio/{id}/descontos'

export const queryConfiguracoesConvenioControllerObterConfiguracaoConvenioDescontoPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams)
export const useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioDescontoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.descontoId, ...config }
})
useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioDescontoPorId.queryKey = '/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}'

export const mutationConfiguracoesConvenioControllerAtualizarConfiguracaoConvenioDesconto = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerAtualizarConfiguracaoConvenioDesconto = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams, {}, options), config)

export const mutationConfiguracoesConvenioControllerDeletarConfiguracaoConvenioDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerDeletarConfiguracaoConvenioDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams, {}, options), config)

export const mutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioPortador = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/portadores', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioPortador = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/portadores', pathParams, {}, options), config)

export const queryConfiguracoesConvenioControllerListarConfiguracaoConvenioportadores = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/portadores', pathParams, queryParams)
export const useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioportadores = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/portadores', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioportadores.queryKey = '/api/v1/configuracoes-de-convenio/{id}/portadores'

export const queryConfiguracoesConvenioControllerObterConfiguracaoConvenioPortadorPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/portador/{formaPagamentoId}', pathParams)
export const useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioPortadorPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/portador/{formaPagamentoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.formaPagamentoId, ...config }
})
useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioPortadorPorId.queryKey = '/api/v1/configuracoes-de-convenio/{id}/portador/{formaPagamentoId}'

export const mutationConfiguracoesConvenioControllerDeletarConfiguracaoConvenioPortador = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}/portador/{formaPagamentoId}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerDeletarConfiguracaoConvenioPortador = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}/portador/{formaPagamentoId}', pathParams, {}, options), config)

export const mutationConfiguracoesDescontoControllerCadastrar = (options) => mutationFn('post', '/api/v1/configuracoes-de-desconto', {}, {}, options)
export const useMutationConfiguracoesDescontoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-desconto', {}, {}, options), config)

export const queryConfiguracoesDescontoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto', queryParams)
export const useQueryConfiguracoesDescontoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesDescontoControllerListar.queryKey = '/api/v1/configuracoes-de-desconto'

export const queryConfiguracoesDescontoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}', pathParams)
export const useQueryConfiguracoesDescontoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesDescontoControllerObterPorId.queryKey = '/api/v1/configuracoes-de-desconto/{id}'

export const mutationConfiguracoesDescontoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesDescontoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoConta = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/contas', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/contas', pathParams, {}, options), config)

export const queryConfiguracoesDescontoControllerListarConfiguracaoDescontoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/contas', pathParams, queryParams)
export const useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoContas.queryKey = '/api/v1/configuracoes-de-desconto/{id}/contas'

export const queryConfiguracoesDescontoControllerObterConfiguracaoDescontoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/contas/{contaId}', pathParams)
export const useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoContaPorId.queryKey = '/api/v1/configuracoes-de-desconto/{id}/contas/{contaId}'

export const mutationConfiguracoesDescontoControllerAtualizarConfiguracaoDescontoConta = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerAtualizarConfiguracaoDescontoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryConfiguracoesDescontoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryConfiguracoesDescontoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesDescontoControllerListarContasDisponiveis.queryKey = '/api/v1/configuracoes-de-desconto/{id}/contas-disponiveis'

export const mutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoDesconto = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/descontos', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/descontos', pathParams, {}, options), config)

export const queryConfiguracoesDescontoControllerListarConfiguracaoDescontoDescontos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/descontos', pathParams, queryParams)
export const useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoDescontos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/descontos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoDescontos.queryKey = '/api/v1/configuracoes-de-desconto/{id}/descontos'

export const queryConfiguracoesDescontoControllerObterConfiguracaoDescontoDescontoPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams)
export const useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoDescontoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.descontoId, ...config }
})
useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoDescontoPorId.queryKey = '/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}'

export const mutationConfiguracoesDescontoControllerAtualizarConfiguracaoDescontoDesconto = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerAtualizarConfiguracaoDescontoDesconto = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams, {}, options), config)

export const mutationConfiguracoesDescontoControllerDeletarConfiguracaoDescontoDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerDeletarConfiguracaoDescontoDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams, {}, options), config)

export const mutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoPortador = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/portadores', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoPortador = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/portadores', pathParams, {}, options), config)

export const queryConfiguracoesDescontoControllerListarConfiguracaoDescontoPortadores = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/portadores', pathParams, queryParams)
export const useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoPortadores = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/portadores', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoPortadores.queryKey = '/api/v1/configuracoes-de-desconto/{id}/portadores'

export const queryConfiguracoesDescontoControllerObterConfiguracaoDescontoPortadorPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/portador/{formaPagamentoId}', pathParams)
export const useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoPortadorPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/portador/{formaPagamentoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.formaPagamentoId, ...config }
})
useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoPortadorPorId.queryKey = '/api/v1/configuracoes-de-desconto/{id}/portador/{formaPagamentoId}'

export const mutationConfiguracoesDescontoControllerDeletarConfiguracaoDescontoPortador = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}/portador/{formaPagamentoId}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerDeletarConfiguracaoDescontoPortador = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}/portador/{formaPagamentoId}', pathParams, {}, options), config)

export const queryContasControllerObterListaHorariosFuncionamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas/{id}/horarios', pathParams, queryParams)
export const useQueryContasControllerObterListaHorariosFuncionamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/horarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterListaHorariosFuncionamento.queryKey = '/api/v1/contas/{id}/horarios'

export const queryContasControllerObterListaDiaEquipamentosHorarioFuncionamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas/{id}/equipamentos-dias', pathParams, queryParams)
export const useQueryContasControllerObterListaDiaEquipamentosHorarioFuncionamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/equipamentos-dias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterListaDiaEquipamentosHorarioFuncionamento.queryKey = '/api/v1/contas/{id}/equipamentos-dias'

export const queryContasControllerObterListaDiaSalasHorarioFuncionamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas/{id}/salas-dias', pathParams, queryParams)
export const useQueryContasControllerObterListaDiaSalasHorarioFuncionamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/salas-dias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterListaDiaSalasHorarioFuncionamento.queryKey = '/api/v1/contas/{id}/salas-dias'

export const mutationContasControllerCadastrar = (options) => mutationFn('post', '/api/v1/contas', {}, {}, options)
export const useMutationContasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/contas', {}, {}, options), config)

export const queryContasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/contas', queryParams)
export const useQueryContasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasControllerListar.queryKey = '/api/v1/contas'

export const queryContasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/contas/{id}', pathParams)
export const useQueryContasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterPorId.queryKey = '/api/v1/contas/{id}'

export const mutationContasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/contas/{id}', pathParams, {}, options)
export const useMutationContasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas/{id}', pathParams, {}, options), config)

export const queryContasControllerObterEmitenteReciboContaPorId = (pathParams, options) => queryFn(options)('/api/v1/contas/{id}/emitente-recibo', pathParams)
export const useQueryContasControllerObterEmitenteReciboContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/emitente-recibo', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterEmitenteReciboContaPorId.queryKey = '/api/v1/contas/{id}/emitente-recibo'

export const mutationContasControllerAtualizarEmitenteRecibo = (pathParams, options) => mutationFn('put', '/api/v1/contas/{id}/emitente-recibo', pathParams, {}, options)
export const useMutationContasControllerAtualizarEmitenteRecibo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas/{id}/emitente-recibo', pathParams, {}, options), config)

export const mutationContasControllerFinalizarCadastro = (pathParams, options) => mutationFn('put', '/api/v1/contas/{id}/finalizar-cadastro', pathParams, {}, options)
export const useMutationContasControllerFinalizarCadastro = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas/{id}/finalizar-cadastro', pathParams, {}, options), config)

export const queryContasControllerObterCalendario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas/{id}/calendario', pathParams, queryParams)
export const useQueryContasControllerObterCalendario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/calendario', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterCalendario.queryKey = '/api/v1/contas/{id}/calendario'

export const mutationContasControllerPersistirLogotipoEmpresa = (pathParams, options) => mutationFn('patch', '/api/v1/contas/{id}/logotipo', pathParams, {}, options)
export const useMutationContasControllerPersistirLogotipoEmpresa = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/contas/{id}/logotipo', pathParams, {}, options), config)

export const mutationContasControllerDeletarLogotipoEmpresa = (pathParams, options) => mutationFn('delete', '/api/v1/contas/{id}/logotipo', pathParams, {}, options)
export const useMutationContasControllerDeletarLogotipoEmpresa = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas/{id}/logotipo', pathParams, {}, options), config)

export const mutationContasControllerPersistirBrasaoEstadoEmpresa = (pathParams, options) => mutationFn('patch', '/api/v1/contas/{id}/brasao', pathParams, {}, options)
export const useMutationContasControllerPersistirBrasaoEstadoEmpresa = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/contas/{id}/brasao', pathParams, {}, options), config)

export const mutationContasControllerDeletarBrasaoEstadoEmpresa = (pathParams, options) => mutationFn('delete', '/api/v1/contas/{id}/brasao', pathParams, {}, options)
export const useMutationContasControllerDeletarBrasaoEstadoEmpresa = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas/{id}/brasao', pathParams, {}, options), config)

export const mutationContasControllerCadastrarContaEmissorPrestador = (pathParams, options) => mutationFn('post', '/api/v1/contas/{id}/emissor', pathParams, {}, options)
export const useMutationContasControllerCadastrarContaEmissorPrestador = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contas/{id}/emissor', pathParams, {}, options), config)

export const queryContasControllerBuscarEmissorPrestador = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas/{id}/emissor', pathParams, queryParams)
export const useQueryContasControllerBuscarEmissorPrestador = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/emissor', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerBuscarEmissorPrestador.queryKey = '/api/v1/contas/{id}/emissor'

export const mutationContasControllerAtualizarContaEmissorPrestador = (pathParams, options) => mutationFn('put', '/api/v1/contas/{id}/emissor/{emissorId}', pathParams, {}, options)
export const useMutationContasControllerAtualizarContaEmissorPrestador = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas/{id}/emissor/{emissorId}', pathParams, {}, options), config)

export const mutationContasControllerDeletarContaEmissorPrestador = (pathParams, options) => mutationFn('delete', '/api/v1/contas/{id}/emissor/{emissorId}', pathParams, {}, options)
export const useMutationContasControllerDeletarContaEmissorPrestador = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas/{id}/emissor/{emissorId}', pathParams, {}, options), config)

export const queryContasControllerObterEmissorPrestadorPorId = (pathParams, options) => queryFn(options)('/api/v1/contas/{id}/emissor/{emissorId}', pathParams)
export const useQueryContasControllerObterEmissorPrestadorPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/emissor/{emissorId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.emissorId && pathParams.id, ...config }
})
useQueryContasControllerObterEmissorPrestadorPorId.queryKey = '/api/v1/contas/{id}/emissor/{emissorId}'

export const mutationContasControllerPersistirCertificadoEmissor = (pathParams, options) => mutationFn('patch', '/api/v1/contas/{id}/emissor/{idEmissor}/certificado', pathParams, {}, options)
export const useMutationContasControllerPersistirCertificadoEmissor = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/contas/{id}/emissor/{idEmissor}/certificado', pathParams, {}, options), config)

export const mutationContasControllerDeletarCertificadoEmissor = (pathParams, options) => mutationFn('delete', '/api/v1/contas/{id}/emissor/{idEmissor}/certificado', pathParams, {}, options)
export const useMutationContasControllerDeletarCertificadoEmissor = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas/{id}/emissor/{idEmissor}/certificado', pathParams, {}, options), config)

export const mutationContasBancariaControllerCadastrar = (options) => mutationFn('post', '/api/v1/contas-bancaria', {}, {}, options)
export const useMutationContasBancariaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/contas-bancaria', {}, {}, options), config)

export const queryContasBancariaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/contas-bancaria', queryParams)
export const useQueryContasBancariaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasBancariaControllerListar.queryKey = '/api/v1/contas-bancaria'

export const queryContasBancariaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}', pathParams)
export const useQueryContasBancariaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerObterPorId.queryKey = '/api/v1/contas-bancaria/{id}'

export const mutationContasBancariaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/contas-bancaria/{id}', pathParams, {}, options)
export const useMutationContasBancariaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas-bancaria/{id}', pathParams, {}, options), config)

export const mutationContasBancariaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/contas-bancaria/{id}', pathParams, {}, options)
export const useMutationContasBancariaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas-bancaria/{id}', pathParams, {}, options), config)

export const mutationContasBancariaControllerCadastrarResponsavelContaBancaria = (pathParams, options) => mutationFn('post', '/api/v1/contas-bancaria/{id}/responsaveis', pathParams, {}, options)
export const useMutationContasBancariaControllerCadastrarResponsavelContaBancaria = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contas-bancaria/{id}/responsaveis', pathParams, {}, options), config)

export const queryContasBancariaControllerListarResponsaveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/responsaveis', pathParams, queryParams)
export const useQueryContasBancariaControllerListarResponsaveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerListarResponsaveis.queryKey = '/api/v1/contas-bancaria/{id}/responsaveis'

export const mutationContasBancariaControllerDeletarResponsavelCliente = (pathParams, options) => mutationFn('delete', '/api/v1/contas-bancaria/{id}/responsaveis/{responsavelId}', pathParams, {}, options)
export const useMutationContasBancariaControllerDeletarResponsavelCliente = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas-bancaria/{id}/responsaveis/{responsavelId}', pathParams, {}, options), config)

export const mutationContasBancariaControllerCadastrarContaBancariaConta = (pathParams, options) => mutationFn('post', '/api/v1/contas-bancaria/{id}/contas', pathParams, {}, options)
export const useMutationContasBancariaControllerCadastrarContaBancariaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contas-bancaria/{id}/contas', pathParams, {}, options), config)

export const queryContasBancariaControllerListarContaBancariaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/contas', pathParams, queryParams)
export const useQueryContasBancariaControllerListarContaBancariaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerListarContaBancariaContas.queryKey = '/api/v1/contas-bancaria/{id}/contas'

export const queryContasBancariaControllerObterContaBancariaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/contas/{contaId}', pathParams)
export const useQueryContasBancariaControllerObterContaBancariaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryContasBancariaControllerObterContaBancariaContaPorId.queryKey = '/api/v1/contas-bancaria/{id}/contas/{contaId}'

export const mutationContasBancariaControllerAtualizarContaBancariaConta = (pathParams, options) => mutationFn('put', '/api/v1/contas-bancaria/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationContasBancariaControllerAtualizarContaBancariaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas-bancaria/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryContasBancariaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryContasBancariaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerListarContasDisponiveis.queryKey = '/api/v1/contas-bancaria/{id}/contas-disponiveis'

export const queryContasBancariaControllerListarContasBancariasPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/listar-contas-bancarias-por-periodo', queryParams)
export const useQueryContasBancariaControllerListarContasBancariasPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/listar-contas-bancarias-por-periodo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasBancariaControllerListarContasBancariasPorPeriodo.queryKey = '/api/v1/contas-bancaria/listar-contas-bancarias-por-periodo'

export const queryContasBancariaControllerObterContaBancariaCaixaPorId = (pathParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/detalhado', pathParams)
export const useQueryContasBancariaControllerObterContaBancariaCaixaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/detalhado', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerObterContaBancariaCaixaPorId.queryKey = '/api/v1/contas-bancaria/{id}/detalhado'

export const queryContasBancariaControllerListarContasBancariaDetalhado = (queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/detalhado', queryParams)
export const useQueryContasBancariaControllerListarContasBancariaDetalhado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/detalhado', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasBancariaControllerListarContasBancariaDetalhado.queryKey = '/api/v1/contas-bancaria/detalhado'

export const queryContasBancariaControllerExtratoContasBancaria = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/extrato', pathParams, queryParams)
export const useQueryContasBancariaControllerExtratoContasBancaria = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/extrato', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerExtratoContasBancaria.queryKey = '/api/v1/contas-bancaria/{id}/extrato'

export const queryContasConfiguracoesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/contas-configuracoes/{chave}', pathParams)
export const useQueryContasConfiguracoesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-configuracoes/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryContasConfiguracoesControllerObterPorChave.queryKey = '/api/v1/contas-configuracoes/{chave}'

export const queryContasConfiguracoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/contas-configuracoes', queryParams)
export const useQueryContasConfiguracoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-configuracoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasConfiguracoesControllerListar.queryKey = '/api/v1/contas-configuracoes'

export const mutationContasConfiguracoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/contas-configuracoes/{id}', pathParams, {}, options)
export const useMutationContasConfiguracoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas-configuracoes/{id}', pathParams, {}, options), config)

export const queryContasConfiguracoesControllerListarDetalhado = (queryParams, options) => queryFn(options)('/api/v1/contas-configuracoes/detalhes', queryParams)
export const useQueryContasConfiguracoesControllerListarDetalhado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-configuracoes/detalhes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasConfiguracoesControllerListarDetalhado.queryKey = '/api/v1/contas-configuracoes/detalhes'

export const mutationContasConfiguracoesOpenBankingControllerCadastrar = (pathParams, options) => mutationFn('post', '/api/v1/contas-configuracoes-open-banking/{contaId}', pathParams, {}, options)
export const useMutationContasConfiguracoesOpenBankingControllerCadastrar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contas-configuracoes-open-banking/{contaId}', pathParams, {}, options), config)

export const queryContasConfiguracoesOpenBankingControllerObterPorContaId = (pathParams, options) => queryFn(options)('/api/v1/contas-configuracoes-open-banking/{contaId}', pathParams)
export const useQueryContasConfiguracoesOpenBankingControllerObterPorContaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-configuracoes-open-banking/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.contaId, ...config }
})
useQueryContasConfiguracoesOpenBankingControllerObterPorContaId.queryKey = '/api/v1/contas-configuracoes-open-banking/{contaId}'

export const mutationContasConfiguracoesOpenBankingControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/contas-configuracoes-open-banking/{contaId}/{id}', pathParams, {}, options)
export const useMutationContasConfiguracoesOpenBankingControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas-configuracoes-open-banking/{contaId}/{id}', pathParams, {}, options), config)

export const queryContasConfiguracoesOpenBankingControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/contas-configuracoes-open-banking/{contaId}/{id}', pathParams)
export const useQueryContasConfiguracoesOpenBankingControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-configuracoes-open-banking/{contaId}/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.contaId && pathParams.id, ...config }
})
useQueryContasConfiguracoesOpenBankingControllerObterPorId.queryKey = '/api/v1/contas-configuracoes-open-banking/{contaId}/{id}'

export const mutationContasConfiguracoesOpenBankingControllerDeletarContaConfiguracaoOpenBanking = (pathParams, options) => mutationFn('delete', '/api/v1/contas-configuracoes-open-banking/{id}', pathParams, {}, options)
export const useMutationContasConfiguracoesOpenBankingControllerDeletarContaConfiguracaoOpenBanking = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas-configuracoes-open-banking/{id}', pathParams, {}, options), config)

export const queryContasEmissoresPrestadoresControllerGetById = (pathParams, options) => queryFn(options)('/api/v1/contas-emissores-prestadores/{id}', pathParams)
export const useQueryContasEmissoresPrestadoresControllerGetById = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-emissores-prestadores/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasEmissoresPrestadoresControllerGetById.queryKey = '/api/v1/contas-emissores-prestadores/{id}'

export const queryContasEmissoresPrestadoresControllerGetLogs = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas-emissores-prestadores/{id}/logs', pathParams, queryParams)
export const useQueryContasEmissoresPrestadoresControllerGetLogs = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-emissores-prestadores/{id}/logs', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasEmissoresPrestadoresControllerGetLogs.queryKey = '/api/v1/contas-emissores-prestadores/{id}/logs'

export const mutationContasEmissoresPrestadoresControllerResend = (pathParams, options) => mutationFn('put', '/api/v1/contas-emissores-prestadores/{id}/reenviar', pathParams, {}, options)
export const useMutationContasEmissoresPrestadoresControllerResend = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas-emissores-prestadores/{id}/reenviar', pathParams, {}, options), config)

export const queryContasEmissoresPrestadoresLogsControllerGetById = (pathParams, options) => queryFn(options)('/api/v1/contas-emissores-prestadores-logs/{id}', pathParams)
export const useQueryContasEmissoresPrestadoresLogsControllerGetById = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-emissores-prestadores-logs/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasEmissoresPrestadoresLogsControllerGetById.queryKey = '/api/v1/contas-emissores-prestadores-logs/{id}'

export const queryContatosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/geral/contatos/{id}', pathParams)
export const useQueryContatosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/contatos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContatosControllerObterPorId.queryKey = '/api/v1/geral/contatos/{id}'

export const mutationContatosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/geral/contatos/{id}', pathParams, {}, options)
export const useMutationContatosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/geral/contatos/{id}', pathParams, {}, options), config)

export const mutationContatosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/geral/contatos/{id}', pathParams, {}, options)
export const useMutationContatosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/geral/contatos/{id}', pathParams, {}, options), config)

export const mutationConvenioContatoControllerAtualizarConvenioContato = (pathParams, options) => mutationFn('put', '/api/v1/convenios/{id}/contatos/{contatoId}', pathParams, {}, options)
export const useMutationConvenioContatoControllerAtualizarConvenioContato = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/convenios/{id}/contatos/{contatoId}', pathParams, {}, options), config)

export const mutationConvenioContatoControllerDeletarContato = (pathParams, options) => mutationFn('delete', '/api/v1/convenios/{id}/contatos/{contatoId}', pathParams, {}, options)
export const useMutationConvenioContatoControllerDeletarContato = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/convenios/{id}/contatos/{contatoId}', pathParams, {}, options), config)

export const queryConveniosControllerObterConvenioContatoPorId = (pathParams, options) => queryFn(options)('/api/v1/convenios/{id}/contatos/{contatoId}', pathParams)
export const useQueryConveniosControllerObterConvenioContatoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios/{id}/contatos/{contatoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contatoId, ...config }
})
useQueryConveniosControllerObterConvenioContatoPorId.queryKey = '/api/v1/convenios/{id}/contatos/{contatoId}'

export const queryConvenioEnderecosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/convenio-enderecos/{id}', pathParams)
export const useQueryConvenioEnderecosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenio-enderecos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConvenioEnderecosControllerObterPorId.queryKey = '/api/v1/convenio-enderecos/{id}'

export const mutationConvenioEnderecosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/convenio-enderecos/{id}', pathParams, {}, options)
export const useMutationConvenioEnderecosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/convenio-enderecos/{id}', pathParams, {}, options), config)

export const mutationConvenioEnderecosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/convenio-enderecos/{id}', pathParams, {}, options)
export const useMutationConvenioEnderecosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/convenio-enderecos/{id}', pathParams, {}, options), config)

export const queryConveniosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/convenios', queryParams)
export const useQueryConveniosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConveniosControllerListar.queryKey = '/api/v1/convenios'

export const mutationConveniosControllerCadastrar = (options) => mutationFn('post', '/api/v1/convenios', {}, {}, options)
export const useMutationConveniosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/convenios', {}, {}, options), config)

export const queryConveniosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/convenios/{id}', pathParams)
export const useQueryConveniosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConveniosControllerObterPorId.queryKey = '/api/v1/convenios/{id}'

export const mutationConveniosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/convenios/{id}', pathParams, {}, options)
export const useMutationConveniosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/convenios/{id}', pathParams, {}, options), config)

export const mutationConveniosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/convenios/{id}', pathParams, {}, options)
export const useMutationConveniosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/convenios/{id}', pathParams, {}, options), config)

export const mutationConveniosControllerCadastrarConvenioConta = (pathParams, options) => mutationFn('post', '/api/v1/convenios/{id}/contas', pathParams, {}, options)
export const useMutationConveniosControllerCadastrarConvenioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/convenios/{id}/contas', pathParams, {}, options), config)

export const queryConveniosControllerListarConvenioContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/convenios/{id}/contas', pathParams, queryParams)
export const useQueryConveniosControllerListarConvenioContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConveniosControllerListarConvenioContas.queryKey = '/api/v1/convenios/{id}/contas'

export const queryConveniosControllerObterConvenioContaPorId = (pathParams, options) => queryFn(options)('/api/v1/convenios/{id}/contas/{contaId}', pathParams)
export const useQueryConveniosControllerObterConvenioContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryConveniosControllerObterConvenioContaPorId.queryKey = '/api/v1/convenios/{id}/contas/{contaId}'

export const mutationConveniosControllerAtualizarConvenioConta = (pathParams, options) => mutationFn('put', '/api/v1/convenios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationConveniosControllerAtualizarConvenioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/convenios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryConveniosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/convenios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryConveniosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConveniosControllerListarContasDisponiveis.queryKey = '/api/v1/convenios/{id}/contas-disponiveis'

export const mutationConveniosControllerCadastrarConvenioContato = (pathParams, options) => mutationFn('post', '/api/v1/convenios/{id}/contatos', pathParams, {}, options)
export const useMutationConveniosControllerCadastrarConvenioContato = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/convenios/{id}/contatos', pathParams, {}, options), config)

export const queryConveniosControllerListarConvenioContatos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/convenios/{id}/contatos', pathParams, queryParams)
export const useQueryConveniosControllerListarConvenioContatos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios/{id}/contatos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConveniosControllerListarConvenioContatos.queryKey = '/api/v1/convenios/{id}/contatos'

export const mutationConveniosControllerCadastrarConvenioPlano = (pathParams, options) => mutationFn('post', '/api/v1/convenios/{id}/planos', pathParams, {}, options)
export const useMutationConveniosControllerCadastrarConvenioPlano = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/convenios/{id}/planos', pathParams, {}, options), config)

export const queryConveniosControllerListarTissPlanos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/convenios/{id}/planos', pathParams, queryParams)
export const useQueryConveniosControllerListarTissPlanos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios/{id}/planos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConveniosControllerListarTissPlanos.queryKey = '/api/v1/convenios/{id}/planos'

export const queryConveniosControllerListarEnderecos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/convenios/{id}/enderecos', pathParams, queryParams)
export const useQueryConveniosControllerListarEnderecos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios/{id}/enderecos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConveniosControllerListarEnderecos.queryKey = '/api/v1/convenios/{id}/enderecos'

export const mutationConveniosControllerCadastrarEndereco = (pathParams, options) => mutationFn('post', '/api/v1/convenios/{id}/enderecos', pathParams, {}, options)
export const useMutationConveniosControllerCadastrarEndereco = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/convenios/{id}/enderecos', pathParams, {}, options), config)

export const mutationConveniosControllerCadastrarGuia = (pathParams, options) => mutationFn('post', '/api/v1/convenios/{id}/guias', pathParams, {}, options)
export const useMutationConveniosControllerCadastrarGuia = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/convenios/{id}/guias', pathParams, {}, options), config)

export const queryConveniosControllerBuscarGuias = (pathParams, queryParams, options) => queryFn(options)('/api/v1/convenios/{id}/guias', pathParams, queryParams)
export const useQueryConveniosControllerBuscarGuias = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios/{id}/guias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConveniosControllerBuscarGuias.queryKey = '/api/v1/convenios/{id}/guias'

export const mutationConveniosOrcamentoSaidaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/convenios-orcamento-saida/{id}', pathParams, {}, options)
export const useMutationConveniosOrcamentoSaidaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/convenios-orcamento-saida/{id}', pathParams, {}, options), config)

export const queryConveniosOrcamentoSaidaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/convenios-orcamento-saida/{id}', pathParams)
export const useQueryConveniosOrcamentoSaidaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios-orcamento-saida/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConveniosOrcamentoSaidaControllerObterPorId.queryKey = '/api/v1/convenios-orcamento-saida/{id}'

export const queryCoresControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cores', queryParams)
export const useQueryCoresControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCoresControllerListar.queryKey = '/api/v1/cores'

export const queryCoresControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cores/{id}', pathParams)
export const useQueryCoresControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cores/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCoresControllerObterPorId.queryKey = '/api/v1/cores/{id}'

export const queryCoresControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/cores/{chave}', pathParams)
export const useQueryCoresControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cores/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryCoresControllerObterPorChave.queryKey = '/api/v1/cores/{chave}'

export const mutationCredenciadorasControllerCadastrar = (options) => mutationFn('post', '/api/v1/credenciadoras', {}, {}, options)
export const useMutationCredenciadorasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/credenciadoras', {}, {}, options), config)

export const queryCredenciadorasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/credenciadoras', queryParams)
export const useQueryCredenciadorasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/credenciadoras', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCredenciadorasControllerListar.queryKey = '/api/v1/credenciadoras'

export const queryCredenciadorasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/credenciadoras/{id}', pathParams)
export const useQueryCredenciadorasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/credenciadoras/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCredenciadorasControllerObterPorId.queryKey = '/api/v1/credenciadoras/{id}'

export const mutationCredenciadorasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/credenciadoras/{id}', pathParams, {}, options)
export const useMutationCredenciadorasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/credenciadoras/{id}', pathParams, {}, options), config)

export const mutationCredenciadorasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/credenciadoras/{id}', pathParams, {}, options)
export const useMutationCredenciadorasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/credenciadoras/{id}', pathParams, {}, options), config)

export const mutationCredenciadorasControllerCadastrarCredenciadoraConta = (pathParams, options) => mutationFn('post', '/api/v1/credenciadoras/{id}/contas', pathParams, {}, options)
export const useMutationCredenciadorasControllerCadastrarCredenciadoraConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/credenciadoras/{id}/contas', pathParams, {}, options), config)

export const queryCredenciadorasControllerListarCredenciadoraContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/credenciadoras/{id}/contas', pathParams, queryParams)
export const useQueryCredenciadorasControllerListarCredenciadoraContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/credenciadoras/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCredenciadorasControllerListarCredenciadoraContas.queryKey = '/api/v1/credenciadoras/{id}/contas'

export const queryCredenciadorasControllerObterCredenciadoraContaPorId = (pathParams, options) => queryFn(options)('/api/v1/credenciadoras/{id}/contas/{contaId}', pathParams)
export const useQueryCredenciadorasControllerObterCredenciadoraContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/credenciadoras/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCredenciadorasControllerObterCredenciadoraContaPorId.queryKey = '/api/v1/credenciadoras/{id}/contas/{contaId}'

export const mutationCredenciadorasControllerAtualizarCredenciadoraConta = (pathParams, options) => mutationFn('put', '/api/v1/credenciadoras/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCredenciadorasControllerAtualizarCredenciadoraConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/credenciadoras/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCredenciadorasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/credenciadoras/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCredenciadorasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/credenciadoras/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCredenciadorasControllerListarContasDisponiveis.queryKey = '/api/v1/credenciadoras/{id}/contas-disponiveis'

export const queryDashboardAgendamentosControllerListarDashboardAgendamentos = (queryParams, options) => queryFn(options)('/api/v1/dashboard-agendamentos/indicadores', queryParams)
export const useQueryDashboardAgendamentosControllerListarDashboardAgendamentos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-agendamentos/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardAgendamentosControllerListarDashboardAgendamentos.queryKey = '/api/v1/dashboard-agendamentos/indicadores'

export const queryDashboardAgendamentosControllerListarDashboardAgendamentoPorStatus = (queryParams, options) => queryFn(options)('/api/v1/dashboard-agendamentos/agendamentos-por-status', queryParams)
export const useQueryDashboardAgendamentosControllerListarDashboardAgendamentoPorStatus = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-agendamentos/agendamentos-por-status', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardAgendamentosControllerListarDashboardAgendamentoPorStatus.queryKey = '/api/v1/dashboard-agendamentos/agendamentos-por-status'

export const queryDashboardClientesControllerListarDashboardClientes = (queryParams, options) => queryFn(options)('/api/v1/dashboard-clientes/indicadores', queryParams)
export const useQueryDashboardClientesControllerListarDashboardClientes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-clientes/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardClientesControllerListarDashboardClientes.queryKey = '/api/v1/dashboard-clientes/indicadores'

export const queryDashboardClinicoControllerListarDashboardClinicoAgendamentoIndicadores = (queryParams, options) => queryFn(options)('/api/v1/dashboard-clinico/agendamentos/indicadores', queryParams)
export const useQueryDashboardClinicoControllerListarDashboardClinicoAgendamentoIndicadores = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-clinico/agendamentos/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardClinicoControllerListarDashboardClinicoAgendamentoIndicadores.queryKey = '/api/v1/dashboard-clinico/agendamentos/indicadores'

export const queryDashboardClinicoControllerListarAtendimentosEmAberto = (queryParams, options) => queryFn(options)('/api/v1/dashboard-clinico/atendimentos-em-aberto', queryParams)
export const useQueryDashboardClinicoControllerListarAtendimentosEmAberto = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-clinico/atendimentos-em-aberto', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardClinicoControllerListarAtendimentosEmAberto.queryKey = '/api/v1/dashboard-clinico/atendimentos-em-aberto'

export const queryDashboardComercialControllerListarDashoboardComercial = (queryParams, options) => queryFn(options)('/api/v1/dashboard-comercial', queryParams)
export const useQueryDashboardComercialControllerListarDashoboardComercial = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-comercial', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardComercialControllerListarDashoboardComercial.queryKey = '/api/v1/dashboard-comercial'

export const queryDashboardContasBancariasControllerListarDashoboardContaBancaria = (queryParams, options) => queryFn(options)('/api/v1/dashboard-movimentacoes-contas-bancarias', queryParams)
export const useQueryDashboardContasBancariasControllerListarDashoboardContaBancaria = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-movimentacoes-contas-bancarias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardContasBancariasControllerListarDashoboardContaBancaria.queryKey = '/api/v1/dashboard-movimentacoes-contas-bancarias'

export const queryDashboardDREsControllerListarDashboardDRE = (queryParams, options) => queryFn(options)('/api/v1/dashboard-dre/dashboard', queryParams)
export const useQueryDashboardDREsControllerListarDashboardDRE = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-dre/dashboard', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardDREsControllerListarDashboardDRE.queryKey = '/api/v1/dashboard-dre/dashboard'

export const queryDashboardDREsControllerListarDashboardResultadoExercicioDRE = (queryParams, options) => queryFn(options)('/api/v1/dashboard-dre/dashboard-resultado-exercicios', queryParams)
export const useQueryDashboardDREsControllerListarDashboardResultadoExercicioDRE = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-dre/dashboard-resultado-exercicios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardDREsControllerListarDashboardResultadoExercicioDRE.queryKey = '/api/v1/dashboard-dre/dashboard-resultado-exercicios'

export const queryDashboardDREsControllerListarIndicadoresDRE = (queryParams, options) => queryFn(options)('/api/v1/dashboard-dre/Indicadores', queryParams)
export const useQueryDashboardDREsControllerListarIndicadoresDRE = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-dre/Indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardDREsControllerListarIndicadoresDRE.queryKey = '/api/v1/dashboard-dre/Indicadores'

export const queryDashboardMovimentacoesCaixaGeralControllerListarIndicadoresMovimentacaoCaixaGeral = (queryParams, options) => queryFn(options)('/api/v1/dashboard-movimentacoes-caixa-geral/Indicadores', queryParams)
export const useQueryDashboardMovimentacoesCaixaGeralControllerListarIndicadoresMovimentacaoCaixaGeral = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-movimentacoes-caixa-geral/Indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardMovimentacoesCaixaGeralControllerListarIndicadoresMovimentacaoCaixaGeral.queryKey = '/api/v1/dashboard-movimentacoes-caixa-geral/Indicadores'

export const queryDashboardMovimentacoesCaixaGeralControllerListarDashboardMovimentacaoCaixaGeral = (queryParams, options) => queryFn(options)('/api/v1/dashboard-movimentacoes-caixa-geral', queryParams)
export const useQueryDashboardMovimentacoesCaixaGeralControllerListarDashboardMovimentacaoCaixaGeral = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-movimentacoes-caixa-geral', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardMovimentacoesCaixaGeralControllerListarDashboardMovimentacaoCaixaGeral.queryKey = '/api/v1/dashboard-movimentacoes-caixa-geral'

export const queryDashboardTarefaControllerObterIndicadores = (queryParams, options) => queryFn(options)('/api/v1/dashboard-tarefas/indicadores', queryParams)
export const useQueryDashboardTarefaControllerObterIndicadores = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-tarefas/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardTarefaControllerObterIndicadores.queryKey = '/api/v1/dashboard-tarefas/indicadores'

export const queryDashboardTarefaControllerObterTarefas = (queryParams, options) => queryFn(options)('/api/v1/dashboard-tarefas/tarefas', queryParams)
export const useQueryDashboardTarefaControllerObterTarefas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-tarefas/tarefas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardTarefaControllerObterTarefas.queryKey = '/api/v1/dashboard-tarefas/tarefas'

export const queryDashboardTipoMovimentacoesCaixaControllerListarDashboardTipoMovimentacaoEstornoCaixa = (queryParams, options) => queryFn(options)('/api/v1/dashboard-tipo-movimentacoes-caixa/Estorno', queryParams)
export const useQueryDashboardTipoMovimentacoesCaixaControllerListarDashboardTipoMovimentacaoEstornoCaixa = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-tipo-movimentacoes-caixa/Estorno', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardTipoMovimentacoesCaixaControllerListarDashboardTipoMovimentacaoEstornoCaixa.queryKey = '/api/v1/dashboard-tipo-movimentacoes-caixa/Estorno'

export const queryDescontosControllerObter = (queryParams, options) => queryFn(options)('/api/v1/descontos', queryParams)
export const useQueryDescontosControllerObter = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/descontos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDescontosControllerObter.queryKey = '/api/v1/descontos'

export const mutationDescontosControllerCadastrar = (options) => mutationFn('post', '/api/v1/descontos', {}, {}, options)
export const useMutationDescontosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/descontos', {}, {}, options), config)

export const mutationDescontosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/descontos/{id}', pathParams, {}, options)
export const useMutationDescontosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/descontos/{id}', pathParams, {}, options), config)

export const queryDetalhesOportunidadesPersonalizadosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/detalhes-oportunidades-personalizados', queryParams)
export const useQueryDetalhesOportunidadesPersonalizadosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/detalhes-oportunidades-personalizados', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDetalhesOportunidadesPersonalizadosControllerBuscar.queryKey = '/api/v1/detalhes-oportunidades-personalizados'

export const mutationDetalhesOportunidadesPersonalizadosControllerCadastrar = (options) => mutationFn('post', '/api/v1/detalhes-oportunidades-personalizados', {}, {}, options)
export const useMutationDetalhesOportunidadesPersonalizadosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/detalhes-oportunidades-personalizados', {}, {}, options), config)

export const queryDetalhesOportunidadesPersonalizadosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams)
export const useQueryDetalhesOportunidadesPersonalizadosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDetalhesOportunidadesPersonalizadosControllerObterPorId.queryKey = '/api/v1/detalhes-oportunidades-personalizados/{id}'

export const mutationDetalhesOportunidadesPersonalizadosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams, {}, options)
export const useMutationDetalhesOportunidadesPersonalizadosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams, {}, options), config)

export const mutationDetalhesOportunidadesPersonalizadosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams, {}, options)
export const useMutationDetalhesOportunidadesPersonalizadosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams, {}, options), config)

export const queryDiasSemanaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/dias-da-semana', queryParams)
export const useQueryDiasSemanaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dias-da-semana', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDiasSemanaControllerListar.queryKey = '/api/v1/dias-da-semana'

export const queryDiasSemanaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/dias-da-semana/{id}', pathParams)
export const useQueryDiasSemanaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dias-da-semana/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDiasSemanaControllerObterPorId.queryKey = '/api/v1/dias-da-semana/{id}'

export const queryDiasSemanaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/dias-da-semana/{chave}', pathParams)
export const useQueryDiasSemanaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dias-da-semana/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryDiasSemanaControllerObterPorChave.queryKey = '/api/v1/dias-da-semana/{chave}'

export const mutationDocumentosControllerObterPdfDocumentoPorId = (pathParams, options) => mutationFn('post', '/api/v1/documentos/{id}/impressao', pathParams, {}, options)
export const useMutationDocumentosControllerObterPdfDocumentoPorId = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/documentos/{id}/impressao', pathParams, {}, options), config)

export const mutationDocumentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/documentos/{id}', pathParams, {}, options)
export const useMutationDocumentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/documentos/{id}', pathParams, {}, options), config)

export const queryDocumentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/documentos/{id}', pathParams)
export const useQueryDocumentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDocumentosControllerObterPorId.queryKey = '/api/v1/documentos/{id}'

export const mutationDocumentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/documentos/{id}', pathParams, {}, options)
export const useMutationDocumentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/documentos/{id}', pathParams, {}, options), config)

export const queryDocumentosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/documentos', queryParams)
export const useQueryDocumentosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDocumentosControllerListar.queryKey = '/api/v1/documentos'

export const mutationDocumentosControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/documentos/{id}/arquivos', pathParams, {}, options)
export const useMutationDocumentosControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/documentos/{id}/arquivos', pathParams, {}, options), config)

export const queryDocumentosControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/documentos/{id}/arquivos', pathParams, queryParams)
export const useQueryDocumentosControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDocumentosControllerListarArquivos.queryKey = '/api/v1/documentos/{id}/arquivos'

export const mutationDocumentosControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/documentos/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationDocumentosControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/documentos/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const mutationDocumentosControllerCadastrarDocumentoConta = (pathParams, options) => mutationFn('post', '/api/v1/documentos/{id}/contas', pathParams, {}, options)
export const useMutationDocumentosControllerCadastrarDocumentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/documentos/{id}/contas', pathParams, {}, options), config)

export const queryDocumentosControllerListarModeloDocumentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/documentos/{id}/contas', pathParams, queryParams)
export const useQueryDocumentosControllerListarModeloDocumentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDocumentosControllerListarModeloDocumentoContas.queryKey = '/api/v1/documentos/{id}/contas'

export const queryDocumentosControllerObterDocumentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/documentos/{id}/contas/{contaId}', pathParams)
export const useQueryDocumentosControllerObterDocumentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryDocumentosControllerObterDocumentoContaPorId.queryKey = '/api/v1/documentos/{id}/contas/{contaId}'

export const mutationDocumentosControllerAtualizarModeloDocumentoConta = (pathParams, options) => mutationFn('put', '/api/v1/documentos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationDocumentosControllerAtualizarModeloDocumentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/documentos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryDocumentosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/documentos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryDocumentosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDocumentosControllerListarContasDisponiveis.queryKey = '/api/v1/documentos/{id}/contas-disponiveis'

export const queryDocumentosTagsControllerListar = (queryParams, options) => queryFn(options)('/api/v1/documentos-tags', queryParams)
export const useQueryDocumentosTagsControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos-tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDocumentosTagsControllerListar.queryKey = '/api/v1/documentos-tags'

export const queryDocumentosTagsControllerListarPorEntidadeOrigemChave = (pathParams, queryParams, options) => queryFn(options)('/api/v1/documentos-tags/{entidadeOrigemChave}', pathParams, queryParams)
export const useQueryDocumentosTagsControllerListarPorEntidadeOrigemChave = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos-tags/{entidadeOrigemChave}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.entidadeOrigemChave, ...config }
})
useQueryDocumentosTagsControllerListarPorEntidadeOrigemChave.queryKey = '/api/v1/documentos-tags/{entidadeOrigemChave}'

export const queryDREControllerBuscarExtratoServicos = (queryParams, options) => queryFn(options)('/api/v1/dre/plano-contas', queryParams)
export const useQueryDREControllerBuscarExtratoServicos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dre/plano-contas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDREControllerBuscarExtratoServicos.queryKey = '/api/v1/dre/plano-contas'

export const mutationEmbalagensControllerAtualizarEmbalagem = (pathParams, options) => mutationFn('put', '/api/v1/embalagens/{id}', pathParams, {}, options)
export const useMutationEmbalagensControllerAtualizarEmbalagem = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/embalagens/{id}', pathParams, {}, options), config)

export const mutationEmbalagensControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/embalagens/{id}', pathParams, {}, options)
export const useMutationEmbalagensControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/embalagens/{id}', pathParams, {}, options), config)

export const queryEmbalagensControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/embalagens/{id}', pathParams)
export const useQueryEmbalagensControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/embalagens/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEmbalagensControllerObterPorId.queryKey = '/api/v1/embalagens/{id}'

export const queryEmitentesRecibosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/emitentes-recibo', queryParams)
export const useQueryEmitentesRecibosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/emitentes-recibo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEmitentesRecibosControllerListar.queryKey = '/api/v1/emitentes-recibo'

export const queryEmitentesRecibosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/emitentes-recibo/{id}', pathParams)
export const useQueryEmitentesRecibosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/emitentes-recibo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEmitentesRecibosControllerObterPorId.queryKey = '/api/v1/emitentes-recibo/{id}'

export const queryEnderecosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/enderecos/{id}', pathParams)
export const useQueryEnderecosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/enderecos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEnderecosControllerObterPorId.queryKey = '/api/v1/enderecos/{id}'

export const mutationEnderecosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/enderecos/{id}', pathParams, {}, options)
export const useMutationEnderecosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/enderecos/{id}', pathParams, {}, options), config)

export const mutationEnderecosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/enderecos/{id}', pathParams, {}, options)
export const useMutationEnderecosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/enderecos/{id}', pathParams, {}, options), config)

export const queryEnumeradoresControllerListar = (pathParams, queryParams, options) => queryFn(options)('/api/v1/enumeradores/{nomeEnumerador}', pathParams, queryParams)
export const useQueryEnumeradoresControllerListar = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/enumeradores/{nomeEnumerador}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.nomeEnumerador, ...config }
})
useQueryEnumeradoresControllerListar.queryKey = '/api/v1/enumeradores/{nomeEnumerador}'

export const queryEquipesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/equipes/{id}', pathParams)
export const useQueryEquipesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/equipes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEquipesControllerObterPorId.queryKey = '/api/v1/equipes/{id}'

export const mutationEquipesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/equipes/{id}', pathParams, {}, options)
export const useMutationEquipesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/equipes/{id}', pathParams, {}, options), config)

export const mutationEquipesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/equipes/{id}', pathParams, {}, options)
export const useMutationEquipesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/equipes/{id}', pathParams, {}, options), config)

export const queryEquipesControllerListarEquipeIntegrantes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/equipes/{id}/integrantes', pathParams, queryParams)
export const useQueryEquipesControllerListarEquipeIntegrantes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/equipes/{id}/integrantes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEquipesControllerListarEquipeIntegrantes.queryKey = '/api/v1/equipes/{id}/integrantes'

export const mutationEquipesControllerCadastrarEquipeIntegrante = (pathParams, options) => mutationFn('post', '/api/v1/equipes/{id}/integrantes', pathParams, {}, options)
export const useMutationEquipesControllerCadastrarEquipeIntegrante = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/equipes/{id}/integrantes', pathParams, {}, options), config)

export const mutationEquipesControllerDeletarEquipeIntegrante = (pathParams, options) => mutationFn('delete', '/api/v1/equipes/{id}/integrantes/{equipeIntegranteId}', pathParams, {}, options)
export const useMutationEquipesControllerDeletarEquipeIntegrante = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/equipes/{id}/integrantes/{equipeIntegranteId}', pathParams, {}, options), config)

export const queryEscolaridadesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/escolaridades', queryParams)
export const useQueryEscolaridadesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/escolaridades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEscolaridadesControllerBuscar.queryKey = '/api/v1/escolaridades'

export const queryEscolaridadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/escolaridades/{id}', pathParams)
export const useQueryEscolaridadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/escolaridades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEscolaridadesControllerObterPorId.queryKey = '/api/v1/escolaridades/{id}'

export const queryEscolaridadesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/escolaridades/{chave}', pathParams)
export const useQueryEscolaridadesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/escolaridades/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryEscolaridadesControllerObterPorChave.queryKey = '/api/v1/escolaridades/{chave}'

export const mutationEspecialidadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/especialidades', {}, {}, options)
export const useMutationEspecialidadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/especialidades', {}, {}, options), config)

export const queryEspecialidadesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/especialidades', queryParams)
export const useQueryEspecialidadesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEspecialidadesControllerListar.queryKey = '/api/v1/especialidades'

export const mutationEspecialidadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/especialidades/{id}', pathParams, {}, options)
export const useMutationEspecialidadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/especialidades/{id}', pathParams, {}, options), config)

export const mutationEspecialidadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/especialidades/{id}', pathParams, {}, options)
export const useMutationEspecialidadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/especialidades/{id}', pathParams, {}, options), config)

export const queryEspecialidadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/especialidades/{id}', pathParams)
export const useQueryEspecialidadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEspecialidadesControllerObterPorId.queryKey = '/api/v1/especialidades/{id}'

export const queryEspecialidadesControllerListarSubespecialidades = (pathParams, queryParams, options) => queryFn(options)('/api/v1/especialidades/{id}/subespecialidades', pathParams, queryParams)
export const useQueryEspecialidadesControllerListarSubespecialidades = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/{id}/subespecialidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEspecialidadesControllerListarSubespecialidades.queryKey = '/api/v1/especialidades/{id}/subespecialidades'

export const mutationEspecialidadesControllerCadastrarCargoConta = (pathParams, options) => mutationFn('post', '/api/v1/especialidades/{id}/contas', pathParams, {}, options)
export const useMutationEspecialidadesControllerCadastrarCargoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/especialidades/{id}/contas', pathParams, {}, options), config)

export const queryEspecialidadesControllerListarEspecialidadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/especialidades/{id}/contas', pathParams, queryParams)
export const useQueryEspecialidadesControllerListarEspecialidadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEspecialidadesControllerListarEspecialidadeContas.queryKey = '/api/v1/especialidades/{id}/contas'

export const queryEspecialidadesControllerObterEspecialidadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/especialidades/{id}/contas/{contaId}', pathParams)
export const useQueryEspecialidadesControllerObterEspecialidadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryEspecialidadesControllerObterEspecialidadeContaPorId.queryKey = '/api/v1/especialidades/{id}/contas/{contaId}'

export const mutationEspecialidadesControllerAtualizarEspecialidadesConta = (pathParams, options) => mutationFn('put', '/api/v1/especialidades/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationEspecialidadesControllerAtualizarEspecialidadesConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/especialidades/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryEspecialidadesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/especialidades/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryEspecialidadesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEspecialidadesControllerListarContasDisponiveis.queryKey = '/api/v1/especialidades/{id}/contas-disponiveis'

export const queryEspecialidadesControllerObterEspecialidadesFuncionarios = (queryParams, options) => queryFn(options)('/api/v1/especialidades/funcionarios', queryParams)
export const useQueryEspecialidadesControllerObterEspecialidadesFuncionarios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/funcionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEspecialidadesControllerObterEspecialidadesFuncionarios.queryKey = '/api/v1/especialidades/funcionarios'

export const queryEstadosCivisControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/estados-civis', queryParams)
export const useQueryEstadosCivisControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estados-civis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEstadosCivisControllerBuscar.queryKey = '/api/v1/estados-civis'

export const queryEstadosCivisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/estados-civis/{id}', pathParams)
export const useQueryEstadosCivisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estados-civis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEstadosCivisControllerObterPorId.queryKey = '/api/v1/estados-civis/{id}'

export const queryEstadosCivisControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/estados-civis/{chave}', pathParams)
export const useQueryEstadosCivisControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estados-civis/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryEstadosCivisControllerObterPorChave.queryKey = '/api/v1/estados-civis/{chave}'

export const queryEstoquesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/estoque', queryParams)
export const useQueryEstoquesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estoque', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEstoquesControllerListar.queryKey = '/api/v1/estoque'

export const queryEstoquesControllerBuscarEstoquePorId = (pathParams, options) => queryFn(options)('/api/v1/estoque/{id}', pathParams)
export const useQueryEstoquesControllerBuscarEstoquePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estoque/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEstoquesControllerBuscarEstoquePorId.queryKey = '/api/v1/estoque/{id}'

export const mutationEstoquesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/estoque/{id}', pathParams, {}, options)
export const useMutationEstoquesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/estoque/{id}', pathParams, {}, options), config)

export const queryEstoquesControllerBuscarLocaisComEstoque = (pathParams, queryParams, options) => queryFn(options)('/api/v1/estoque/{id}/locais-com-estoque', pathParams, queryParams)
export const useQueryEstoquesControllerBuscarLocaisComEstoque = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estoque/{id}/locais-com-estoque', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEstoquesControllerBuscarLocaisComEstoque.queryKey = '/api/v1/estoque/{id}/locais-com-estoque'

export const queryEstoquesControllerObterIndicadoresEstoque = (options) => queryFn(options)('/api/v1/estoque/indicadores')
export const useQueryEstoquesControllerObterIndicadoresEstoque = (config, options) => useQuery({
  queryKey: ['/api/v1/estoque/indicadores'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEstoquesControllerObterIndicadoresEstoque.queryKey = '/api/v1/estoque/indicadores'

export const mutationEstoquesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/estoque/{estoqueId}', pathParams, {}, options)
export const useMutationEstoquesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/estoque/{estoqueId}', pathParams, {}, options), config)

export const queryEtniasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/etnias', queryParams)
export const useQueryEtniasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etnias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEtniasControllerBuscar.queryKey = '/api/v1/etnias'

export const queryEtniasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/etnias/{id}', pathParams)
export const useQueryEtniasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etnias/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEtniasControllerObterPorId.queryKey = '/api/v1/etnias/{id}'

export const queryEtniasControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/etnias/{chave}', pathParams)
export const useQueryEtniasControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etnias/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryEtniasControllerObterPorChave.queryKey = '/api/v1/etnias/{chave}'

export const queryExamesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/exames', queryParams)
export const useQueryExamesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/exames', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryExamesControllerBuscar.queryKey = '/api/v1/exames'

export const mutationExamesControllerCadastrar = (options) => mutationFn('post', '/api/v1/exames', {}, {}, options)
export const useMutationExamesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/exames', {}, {}, options), config)

export const mutationExamesControllerAtualizar = (options) => mutationFn('put', '/api/v1/exames', {}, {}, options)
export const useMutationExamesControllerAtualizar = (config, options) => useMutation(mutationFn('put', '/api/v1/exames', {}, {}, options), config)

export const queryExamesControllerBuscarExamesReconsulta = (queryParams, options) => queryFn(options)('/api/v1/exames/reconsulta', queryParams)
export const useQueryExamesControllerBuscarExamesReconsulta = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/exames/reconsulta', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryExamesControllerBuscarExamesReconsulta.queryKey = '/api/v1/exames/reconsulta'

export const queryExamesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/exames/{id}', pathParams)
export const useQueryExamesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/exames/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryExamesControllerObterPorId.queryKey = '/api/v1/exames/{id}'

export const mutationExamesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/exames/{id}', pathParams, {}, options)
export const useMutationExamesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/exames/{id}', pathParams, {}, options), config)

export const mutationExamesControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/exames/{id}/arquivos', pathParams, {}, options)
export const useMutationExamesControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/exames/{id}/arquivos', pathParams, {}, options), config)

export const queryExamesControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/exames/{id}/arquivos', pathParams, queryParams)
export const useQueryExamesControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/exames/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryExamesControllerListarArquivos.queryKey = '/api/v1/exames/{id}/arquivos'

export const mutationExamesControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/exames/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationExamesControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/exames/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const queryExtratoServicosControllerBuscarExtratoServicos = (queryParams, options) => queryFn(options)('/api/v1/extrato-servicos', queryParams)
export const useQueryExtratoServicosControllerBuscarExtratoServicos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/extrato-servicos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryExtratoServicosControllerBuscarExtratoServicos.queryKey = '/api/v1/extrato-servicos'

export const mutationFabricantesControllerCadastrar = (options) => mutationFn('post', '/api/v1/geral/fabricantes', {}, {}, options)
export const useMutationFabricantesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/geral/fabricantes', {}, {}, options), config)

export const queryFabricantesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/geral/fabricantes', queryParams)
export const useQueryFabricantesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/fabricantes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFabricantesControllerBuscar.queryKey = '/api/v1/geral/fabricantes'

export const mutationFabricantesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/geral/fabricantes/{id}', pathParams, {}, options)
export const useMutationFabricantesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/geral/fabricantes/{id}', pathParams, {}, options), config)

export const mutationFabricantesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/geral/fabricantes/{id}', pathParams, {}, options)
export const useMutationFabricantesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/geral/fabricantes/{id}', pathParams, {}, options), config)

export const queryFabricantesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/geral/fabricantes/{id}', pathParams)
export const useQueryFabricantesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/fabricantes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFabricantesControllerObterPorId.queryKey = '/api/v1/geral/fabricantes/{id}'

export const mutationFabricantesControllerCadastrarFabricanteConta = (pathParams, options) => mutationFn('post', '/api/v1/geral/fabricantes/{id}/contas', pathParams, {}, options)
export const useMutationFabricantesControllerCadastrarFabricanteConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/geral/fabricantes/{id}/contas', pathParams, {}, options), config)

export const queryFabricantesControllerListarFabricanteContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/geral/fabricantes/{id}/contas', pathParams, queryParams)
export const useQueryFabricantesControllerListarFabricanteContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/fabricantes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFabricantesControllerListarFabricanteContas.queryKey = '/api/v1/geral/fabricantes/{id}/contas'

export const queryFabricantesControllerObterFabricanteContaPorId = (pathParams, options) => queryFn(options)('/api/v1/geral/fabricantes/{id}/contas/{contaId}', pathParams)
export const useQueryFabricantesControllerObterFabricanteContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/fabricantes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFabricantesControllerObterFabricanteContaPorId.queryKey = '/api/v1/geral/fabricantes/{id}/contas/{contaId}'

export const mutationFabricantesControllerAtualizarFabricanteConta = (pathParams, options) => mutationFn('put', '/api/v1/geral/fabricantes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFabricantesControllerAtualizarFabricanteConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/geral/fabricantes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFabricantesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/geral/fabricantes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFabricantesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/fabricantes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFabricantesControllerListarContasDisponiveis.queryKey = '/api/v1/geral/fabricantes/{id}/contas-disponiveis'

export const queryFaseFunilControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/fases-funis/{id}', pathParams)
export const useQueryFaseFunilControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fases-funis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaseFunilControllerObterPorId.queryKey = '/api/v1/fases-funis/{id}'

export const mutationFaseFunilControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/fases-funis/{id}', pathParams, {}, options)
export const useMutationFaseFunilControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/fases-funis/{id}', pathParams, {}, options), config)

export const mutationFaseFunilControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/fases-funis/{id}', pathParams, {}, options)
export const useMutationFaseFunilControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/fases-funis/{id}', pathParams, {}, options), config)

export const queryFaseFunilControllerListaPreTarefasPorFaseFunilId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fases-funis/{id}/templates-tarefa', pathParams, queryParams)
export const useQueryFaseFunilControllerListaPreTarefasPorFaseFunilId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fases-funis/{id}/templates-tarefa', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaseFunilControllerListaPreTarefasPorFaseFunilId.queryKey = '/api/v1/fases-funis/{id}/templates-tarefa'

export const mutationFaseFunilControllerCadastrarTemplateTarefa = (pathParams, options) => mutationFn('post', '/api/v1/fases-funis/{id}/templates-tarefa', pathParams, {}, options)
export const useMutationFaseFunilControllerCadastrarTemplateTarefa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/fases-funis/{id}/templates-tarefa', pathParams, {}, options), config)

export const queryFaseFunilTemplatesTarefaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/fase-funil-templates-tarefa', queryParams)
export const useQueryFaseFunilTemplatesTarefaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fase-funil-templates-tarefa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaseFunilTemplatesTarefaControllerBuscar.queryKey = '/api/v1/fase-funil-templates-tarefa'

export const queryFaseFunilTemplatesTarefaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/fase-funil-templates-tarefa/{id}', pathParams)
export const useQueryFaseFunilTemplatesTarefaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fase-funil-templates-tarefa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaseFunilTemplatesTarefaControllerObterPorId.queryKey = '/api/v1/fase-funil-templates-tarefa/{id}'

export const mutationFaseFunilTemplatesTarefaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/fase-funil-templates-tarefa/{id}', pathParams, {}, options)
export const useMutationFaseFunilTemplatesTarefaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/fase-funil-templates-tarefa/{id}', pathParams, {}, options), config)

export const mutationFaseFunilTemplatesTarefaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/fase-funil-templates-tarefa/{id}', pathParams, {}, options)
export const useMutationFaseFunilTemplatesTarefaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/fase-funil-templates-tarefa/{id}', pathParams, {}, options), config)

export const queryFaseTarefaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/fases-tarefa', queryParams)
export const useQueryFaseTarefaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fases-tarefa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaseTarefaControllerListar.queryKey = '/api/v1/fases-tarefa'

export const queryFaseTarefaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/fases-tarefa/{id}', pathParams)
export const useQueryFaseTarefaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fases-tarefa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaseTarefaControllerObterPorId.queryKey = '/api/v1/fases-tarefa/{id}'

export const queryFaseTarefaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/fases-tarefa/{chave}', pathParams)
export const useQueryFaseTarefaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fases-tarefa/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryFaseTarefaControllerObterPorChave.queryKey = '/api/v1/fases-tarefa/{chave}'

export const queryFaturasControllerObterStatus = (queryParams, options) => queryFn(options)('/api/v1/faturas-retaguarda/status', queryParams)
export const useQueryFaturasControllerObterStatus = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda/status', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerObterStatus.queryKey = '/api/v1/faturas-retaguarda/status'

export const queryFaturasControllerObterServicosFiltraveisListagem = (options) => queryFn(options)('/api/v1/faturas-retaguarda/filtros-listagem')
export const useQueryFaturasControllerObterServicosFiltraveisListagem = (config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda/filtros-listagem'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerObterServicosFiltraveisListagem.queryKey = '/api/v1/faturas-retaguarda/filtros-listagem'

export const queryFaturasControllerObterFaturas = (queryParams, options) => queryFn(options)('/api/v1/faturas-retaguarda', queryParams)
export const useQueryFaturasControllerObterFaturas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerObterFaturas.queryKey = '/api/v1/faturas-retaguarda'

export const queryFaturasControllerObterFaturaPorId = (pathParams, options) => queryFn(options)('/api/v1/faturas-retaguarda/{id}', pathParams)
export const useQueryFaturasControllerObterFaturaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaturasControllerObterFaturaPorId.queryKey = '/api/v1/faturas-retaguarda/{id}'

export const queryFaturasControllerListarOutrosServicos = (options) => queryFn(options)('/api/v1/faturas-retaguarda/outros-servicos')
export const useQueryFaturasControllerListarOutrosServicos = (config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda/outros-servicos'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerListarOutrosServicos.queryKey = '/api/v1/faturas-retaguarda/outros-servicos'

export const queryFaturasControllerListarFares = (queryParams, options) => queryFn(options)('/api/v1/faturas-retaguarda/fares', queryParams)
export const useQueryFaturasControllerListarFares = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda/fares', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerListarFares.queryKey = '/api/v1/faturas-retaguarda/fares'

export const mutationFeriadoEventoPeriodosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/feriados-eventos-periodos/{id}', pathParams, {}, options)
export const useMutationFeriadoEventoPeriodosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/feriados-eventos-periodos/{id}', pathParams, {}, options), config)

export const mutationFeriadoEventoPeriodosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/feriados-eventos-periodos/{id}', pathParams, {}, options)
export const useMutationFeriadoEventoPeriodosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/feriados-eventos-periodos/{id}', pathParams, {}, options), config)

export const queryFeriadoEventoPeriodosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/feriados-eventos-periodos/{id}', pathParams)
export const useQueryFeriadoEventoPeriodosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-eventos-periodos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadoEventoPeriodosControllerObterPorId.queryKey = '/api/v1/feriados-eventos-periodos/{id}'

export const mutationFeriadosEventosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/feriados-e-eventos/{id}', pathParams, {}, options)
export const useMutationFeriadosEventosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/feriados-e-eventos/{id}', pathParams, {}, options), config)

export const mutationFeriadosEventosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/feriados-e-eventos/{id}', pathParams, {}, options)
export const useMutationFeriadosEventosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/feriados-e-eventos/{id}', pathParams, {}, options), config)

export const queryFeriadosEventosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/feriados-e-eventos/{id}', pathParams)
export const useQueryFeriadosEventosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadosEventosControllerObterPorId.queryKey = '/api/v1/feriados-e-eventos/{id}'

export const queryFeriadosEventosControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/feriados-e-eventos/buscar-detalhado/{id}', pathParams)
export const useQueryFeriadosEventosControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadosEventosControllerObterPorIdDetalhado.queryKey = '/api/v1/feriados-e-eventos/buscar-detalhado/{id}'

export const queryFeriadosEventosControllerBuscarTodosOsFeriadosEventos = (queryParams, options) => queryFn(options)('/api/v1/feriados-e-eventos', queryParams)
export const useQueryFeriadosEventosControllerBuscarTodosOsFeriadosEventos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFeriadosEventosControllerBuscarTodosOsFeriadosEventos.queryKey = '/api/v1/feriados-e-eventos'

export const mutationFeriadosEventosControllerCadastrarFeriadoEventoPeriodo = (pathParams, options) => mutationFn('post', '/api/v1/feriados-e-eventos/{id}/feriados-e-eventos-periodo', pathParams, {}, options)
export const useMutationFeriadosEventosControllerCadastrarFeriadoEventoPeriodo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/feriados-e-eventos/{id}/feriados-e-eventos-periodo', pathParams, {}, options), config)

export const queryFeriadosEventosControllerBuscarPeriodos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/feriados-e-eventos/{id}/feriados-e-eventos-periodo', pathParams, queryParams)
export const useQueryFeriadosEventosControllerBuscarPeriodos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos/{id}/feriados-e-eventos-periodo', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadosEventosControllerBuscarPeriodos.queryKey = '/api/v1/feriados-e-eventos/{id}/feriados-e-eventos-periodo'

export const mutationFinalidadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/finalidades', {}, {}, options)
export const useMutationFinalidadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/finalidades', {}, {}, options), config)

export const queryFinalidadesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/finalidades', queryParams)
export const useQueryFinalidadesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFinalidadesControllerBuscar.queryKey = '/api/v1/finalidades'

export const mutationFinalidadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/finalidades/{id}', pathParams, {}, options)
export const useMutationFinalidadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/finalidades/{id}', pathParams, {}, options), config)

export const mutationFinalidadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/finalidades/{id}', pathParams, {}, options)
export const useMutationFinalidadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/finalidades/{id}', pathParams, {}, options), config)

export const queryFinalidadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/finalidades/{id}', pathParams)
export const useQueryFinalidadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFinalidadesControllerObterPorId.queryKey = '/api/v1/finalidades/{id}'

export const queryFinalidadesControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/finalidades/buscar-detalhado/{id}', pathParams)
export const useQueryFinalidadesControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFinalidadesControllerObterPorIdDetalhado.queryKey = '/api/v1/finalidades/buscar-detalhado/{id}'

export const mutationFinalidadesControllerCadastrarFinalidadeConta = (pathParams, options) => mutationFn('post', '/api/v1/finalidades/{id}/contas', pathParams, {}, options)
export const useMutationFinalidadesControllerCadastrarFinalidadeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/finalidades/{id}/contas', pathParams, {}, options), config)

export const queryFinalidadesControllerListarFinalidadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/finalidades/{id}/contas', pathParams, queryParams)
export const useQueryFinalidadesControllerListarFinalidadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFinalidadesControllerListarFinalidadeContas.queryKey = '/api/v1/finalidades/{id}/contas'

export const queryFinalidadesControllerObterFinalidadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/finalidades/{id}/contas/{contaId}', pathParams)
export const useQueryFinalidadesControllerObterFinalidadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFinalidadesControllerObterFinalidadeContaPorId.queryKey = '/api/v1/finalidades/{id}/contas/{contaId}'

export const mutationFinalidadesControllerAtualizarFinalidadeConta = (pathParams, options) => mutationFn('put', '/api/v1/finalidades/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFinalidadesControllerAtualizarFinalidadeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/finalidades/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFinalidadesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/finalidades/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFinalidadesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFinalidadesControllerListarContasDisponiveis.queryKey = '/api/v1/finalidades/{id}/contas-disponiveis'

export const queryFluxoAutomacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/fluxoautomacao', queryParams)
export const useQueryFluxoAutomacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fluxoautomacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFluxoAutomacaoControllerBuscar.queryKey = '/api/v1/fluxoautomacao'

export const mutationFluxoAutomacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/fluxoautomacao', {}, {}, options)
export const useMutationFluxoAutomacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/fluxoautomacao', {}, {}, options), config)

export const queryFluxoAutomacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/fluxoautomacao/{id}', pathParams)
export const useQueryFluxoAutomacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fluxoautomacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFluxoAutomacaoControllerObterPorId.queryKey = '/api/v1/fluxoautomacao/{id}'

export const mutationFluxoAutomacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/fluxoautomacao/{id}', pathParams, {}, options)
export const useMutationFluxoAutomacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/fluxoautomacao/{id}', pathParams, {}, options), config)

export const mutationFluxoAutomacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/fluxoautomacao/{id}', pathParams, {}, options)
export const useMutationFluxoAutomacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/fluxoautomacao/{id}', pathParams, {}, options), config)

export const mutationFormasPagamentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/formas-de-pagamento', {}, {}, options)
export const useMutationFormasPagamentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/formas-de-pagamento', {}, {}, options), config)

export const queryFormasPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/formas-de-pagamento', queryParams)
export const useQueryFormasPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFormasPagamentoControllerListar.queryKey = '/api/v1/formas-de-pagamento'

export const queryFormasPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}', pathParams)
export const useQueryFormasPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerObterPorId.queryKey = '/api/v1/formas-de-pagamento/{id}'

export const mutationFormasPagamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/formas-de-pagamento/{id}', pathParams, {}, options)
export const useMutationFormasPagamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/formas-de-pagamento/{id}', pathParams, {}, options), config)

export const mutationFormasPagamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/formas-de-pagamento/{id}', pathParams, {}, options)
export const useMutationFormasPagamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/formas-de-pagamento/{id}', pathParams, {}, options), config)

export const mutationFormasPagamentoControllerCadastrarFormaPagamentoConta = (pathParams, options) => mutationFn('post', '/api/v1/formas-de-pagamento/{id}/contas', pathParams, {}, options)
export const useMutationFormasPagamentoControllerCadastrarFormaPagamentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/formas-de-pagamento/{id}/contas', pathParams, {}, options), config)

export const queryFormasPagamentoControllerListarFormaPagamentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}/contas', pathParams, queryParams)
export const useQueryFormasPagamentoControllerListarFormaPagamentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerListarFormaPagamentoContas.queryKey = '/api/v1/formas-de-pagamento/{id}/contas'

export const queryFormasPagamentoControllerObterFormaPagamentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}/contas/{contaId}', pathParams)
export const useQueryFormasPagamentoControllerObterFormaPagamentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFormasPagamentoControllerObterFormaPagamentoContaPorId.queryKey = '/api/v1/formas-de-pagamento/{id}/contas/{contaId}'

export const mutationFormasPagamentoControllerAtualizarFormaPagamentoConta = (pathParams, options) => mutationFn('put', '/api/v1/formas-de-pagamento/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFormasPagamentoControllerAtualizarFormaPagamentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/formas-de-pagamento/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFormasPagamentoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFormasPagamentoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerListarContasDisponiveis.queryKey = '/api/v1/formas-de-pagamento/{id}/contas-disponiveis'

export const queryFormasPagamentoControllerObterPorFormaPagamentoId = (pathParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}/obter-config-link-pagamento', pathParams)
export const useQueryFormasPagamentoControllerObterPorFormaPagamentoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}/obter-config-link-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerObterPorFormaPagamentoId.queryKey = '/api/v1/formas-de-pagamento/{id}/obter-config-link-pagamento'

export const queryFormasPagamentoControllerListarCondicoesPagamentoRecorrentePorFormaPagamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}/condicao-pagamento-recorrentes', pathParams, queryParams)
export const useQueryFormasPagamentoControllerListarCondicoesPagamentoRecorrentePorFormaPagamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}/condicao-pagamento-recorrentes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerListarCondicoesPagamentoRecorrentePorFormaPagamento.queryKey = '/api/v1/formas-de-pagamento/{id}/condicao-pagamento-recorrentes'

export const queryFormularioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/formularios/{id}', pathParams)
export const useQueryFormularioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formularios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormularioControllerObterPorId.queryKey = '/api/v1/formularios/{id}'

export const queryFormulasMedicamentosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/formulas-medicamentos', queryParams)
export const useQueryFormulasMedicamentosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-medicamentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFormulasMedicamentosControllerListar.queryKey = '/api/v1/formulas-medicamentos'

export const mutationFormulasMedicamentosControllerCadastrar = (options) => mutationFn('post', '/api/v1/formulas-medicamentos', {}, {}, options)
export const useMutationFormulasMedicamentosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/formulas-medicamentos', {}, {}, options), config)

export const queryFormulasMedicamentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/formulas-medicamentos/{id}', pathParams)
export const useQueryFormulasMedicamentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-medicamentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormulasMedicamentosControllerObterPorId.queryKey = '/api/v1/formulas-medicamentos/{id}'

export const mutationFormulasMedicamentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/formulas-medicamentos/{id}', pathParams, {}, options)
export const useMutationFormulasMedicamentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/formulas-medicamentos/{id}', pathParams, {}, options), config)

export const mutationFormulasMedicamentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/formulas-medicamentos/{id}', pathParams, {}, options)
export const useMutationFormulasMedicamentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/formulas-medicamentos/{id}', pathParams, {}, options), config)

export const queryFormulasMedicamentosControllerListarContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/formulas-medicamentos/{id}/contas', pathParams, queryParams)
export const useQueryFormulasMedicamentosControllerListarContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-medicamentos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormulasMedicamentosControllerListarContas.queryKey = '/api/v1/formulas-medicamentos/{id}/contas'

export const mutationFormulasMedicamentosControllerCadastrarConta = (pathParams, options) => mutationFn('post', '/api/v1/formulas-medicamentos/{id}/contas', pathParams, {}, options)
export const useMutationFormulasMedicamentosControllerCadastrarConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/formulas-medicamentos/{id}/contas', pathParams, {}, options), config)

export const queryFormulasMedicamentosControllerObterContaPorId = (pathParams, options) => queryFn(options)('/api/v1/formulas-medicamentos/{id}/contas/{contaId}', pathParams)
export const useQueryFormulasMedicamentosControllerObterContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-medicamentos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFormulasMedicamentosControllerObterContaPorId.queryKey = '/api/v1/formulas-medicamentos/{id}/contas/{contaId}'

export const mutationFormulasMedicamentosControllerAtualizarConta = (pathParams, options) => mutationFn('put', '/api/v1/formulas-medicamentos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFormulasMedicamentosControllerAtualizarConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/formulas-medicamentos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFormulasMedicamentosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/formulas-medicamentos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFormulasMedicamentosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-medicamentos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormulasMedicamentosControllerListarContasDisponiveis.queryKey = '/api/v1/formulas-medicamentos/{id}/contas-disponiveis'

export const mutationFornecedoresControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/fornecedores/{id}', pathParams, {}, options)
export const useMutationFornecedoresControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/fornecedores/{id}', pathParams, {}, options), config)

export const queryFornecedoresControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/fornecedores/{id}', pathParams)
export const useQueryFornecedoresControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerObterPorId.queryKey = '/api/v1/fornecedores/{id}'

export const mutationFornecedoresControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/fornecedores/{id}', pathParams, {}, options)
export const useMutationFornecedoresControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/fornecedores/{id}', pathParams, {}, options), config)

export const queryFornecedoresControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/fornecedores', queryParams)
export const useQueryFornecedoresControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFornecedoresControllerBuscar.queryKey = '/api/v1/fornecedores'

export const mutationFornecedoresControllerCadastrar = (options) => mutationFn('post', '/api/v1/fornecedores', {}, {}, options)
export const useMutationFornecedoresControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/fornecedores', {}, {}, options), config)

export const mutationFornecedoresControllerCadastrarFornecedorTag = (pathParams, options) => mutationFn('post', '/api/v1/fornecedores/{id}/tags', pathParams, {}, options)
export const useMutationFornecedoresControllerCadastrarFornecedorTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/fornecedores/{id}/tags', pathParams, {}, options), config)

export const queryFornecedoresControllerListarTagsFornecedor = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/tags', pathParams, queryParams)
export const useQueryFornecedoresControllerListarTagsFornecedor = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarTagsFornecedor.queryKey = '/api/v1/fornecedores/{id}/tags'

export const mutationFornecedoresControllerDeletarFornecedorTag = (pathParams, options) => mutationFn('delete', '/api/v1/fornecedores/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationFornecedoresControllerDeletarFornecedorTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/fornecedores/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryFornecedoresControllerListarFornecedoresTags = (queryParams, options) => queryFn(options)('/api/v1/fornecedores/tags', queryParams)
export const useQueryFornecedoresControllerListarFornecedoresTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFornecedoresControllerListarFornecedoresTags.queryKey = '/api/v1/fornecedores/tags'

export const mutationFornecedoresControllerCadastrarFornecedorConta = (pathParams, options) => mutationFn('post', '/api/v1/fornecedores/{id}/contas', pathParams, {}, options)
export const useMutationFornecedoresControllerCadastrarFornecedorConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/fornecedores/{id}/contas', pathParams, {}, options), config)

export const queryFornecedoresControllerListarFornecedorContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/contas', pathParams, queryParams)
export const useQueryFornecedoresControllerListarFornecedorContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarFornecedorContas.queryKey = '/api/v1/fornecedores/{id}/contas'

export const queryFornecedoresControllerObterFornecedorContaPorId = (pathParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/contas/{contaId}', pathParams)
export const useQueryFornecedoresControllerObterFornecedorContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFornecedoresControllerObterFornecedorContaPorId.queryKey = '/api/v1/fornecedores/{id}/contas/{contaId}'

export const mutationFornecedoresControllerAtualizarFornecedorConta = (pathParams, options) => mutationFn('put', '/api/v1/fornecedores/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFornecedoresControllerAtualizarFornecedorConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/fornecedores/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFornecedoresControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFornecedoresControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarContasDisponiveis.queryKey = '/api/v1/fornecedores/{id}/contas-disponiveis'

export const queryFornecedoresControllerListarTagsDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryFornecedoresControllerListarTagsDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarTagsDisponiveis.queryKey = '/api/v1/fornecedores/{id}/tags-disponiveis'

export const queryFornecedoresControllerListarOrcamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/orcamentos-entrada', pathParams, queryParams)
export const useQueryFornecedoresControllerListarOrcamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/orcamentos-entrada', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarOrcamentos.queryKey = '/api/v1/fornecedores/{id}/orcamentos-entrada'

export const queryFornecedoresControllerListarPedidosCompra = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/pedidos-compra', pathParams, queryParams)
export const useQueryFornecedoresControllerListarPedidosCompra = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/pedidos-compra', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarPedidosCompra.queryKey = '/api/v1/fornecedores/{id}/pedidos-compra'

export const queryFornecedoresControllerListarTitulos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/titulos', pathParams, queryParams)
export const useQueryFornecedoresControllerListarTitulos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/titulos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarTitulos.queryKey = '/api/v1/fornecedores/{id}/titulos'

export const queryFuncionalidadeControllerListar = (queryParams, options) => queryFn(options)('/api/v1/funcionalidade', queryParams)
export const useQueryFuncionalidadeControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionalidade', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFuncionalidadeControllerListar.queryKey = '/api/v1/funcionalidade'

export const queryFuncionalidadeTagControllerListar = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionalidade-tag/{funcionalidadeId}', pathParams, queryParams)
export const useQueryFuncionalidadeTagControllerListar = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionalidade-tag/{funcionalidadeId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.funcionalidadeId, ...config }
})
useQueryFuncionalidadeTagControllerListar.queryKey = '/api/v1/funcionalidade-tag/{funcionalidadeId}'

export const queryFuncionarioEspecialidadesControllerObterFuncionarioEspecialidadePorId = (pathParams, options) => queryFn(options)('/api/v1/funcionario-especialidades/{id}', pathParams)
export const useQueryFuncionarioEspecialidadesControllerObterFuncionarioEspecialidadePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionario-especialidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionarioEspecialidadesControllerObterFuncionarioEspecialidadePorId.queryKey = '/api/v1/funcionario-especialidades/{id}'

export const mutationFuncionarioEspecialidadesControllerAtualizarFuncionarioEspecialidade = (pathParams, options) => mutationFn('put', '/api/v1/funcionario-especialidades/{id}', pathParams, {}, options)
export const useMutationFuncionarioEspecialidadesControllerAtualizarFuncionarioEspecialidade = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcionario-especialidades/{id}', pathParams, {}, options), config)

export const mutationFuncionarioEspecialidadesControllerDeletarFuncionarioEspecialidade = (pathParams, options) => mutationFn('delete', '/api/v1/funcionario-especialidades/{id}', pathParams, {}, options)
export const useMutationFuncionarioEspecialidadesControllerDeletarFuncionarioEspecialidade = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionario-especialidades/{id}', pathParams, {}, options), config)

export const queryFuncionarioEspecialidadesControllerListarEspecialidadesVinculadas = (queryParams, options) => queryFn(options)('/api/v1/funcionario-especialidades/especialidades-vinculadas', queryParams)
export const useQueryFuncionarioEspecialidadesControllerListarEspecialidadesVinculadas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionario-especialidades/especialidades-vinculadas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFuncionarioEspecialidadesControllerListarEspecialidadesVinculadas.queryKey = '/api/v1/funcionario-especialidades/especialidades-vinculadas'

export const mutationFuncionarioEspecialidadesControllerCadastrarFuncionarioSubEspecialidade = (pathParams, options) => mutationFn('post', '/api/v1/funcionario-especialidades/{id}/sub-especialidades', pathParams, {}, options)
export const useMutationFuncionarioEspecialidadesControllerCadastrarFuncionarioSubEspecialidade = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionario-especialidades/{id}/sub-especialidades', pathParams, {}, options), config)

export const queryFuncionarioEspecialidadesControllerListarFuncionarioSubEspecialidades = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionario-especialidades/{id}/sub-especialidades', pathParams, queryParams)
export const useQueryFuncionarioEspecialidadesControllerListarFuncionarioSubEspecialidades = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionario-especialidades/{id}/sub-especialidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionarioEspecialidadesControllerListarFuncionarioSubEspecialidades.queryKey = '/api/v1/funcionario-especialidades/{id}/sub-especialidades'

export const queryFuncionarioRegistrosControllerObterFuncionarioRegistroPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionario-registro/{id}', pathParams)
export const useQueryFuncionarioRegistrosControllerObterFuncionarioRegistroPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionario-registro/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionarioRegistrosControllerObterFuncionarioRegistroPorId.queryKey = '/api/v1/funcionario-registro/{id}'

export const mutationFuncionarioRegistrosControllerDeletarFuncionarioRegistro = (pathParams, options) => mutationFn('delete', '/api/v1/funcionario-registro/{id}', pathParams, {}, options)
export const useMutationFuncionarioRegistrosControllerDeletarFuncionarioRegistro = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionario-registro/{id}', pathParams, {}, options), config)

export const mutationFuncionariosControllerCadastrarFuncionarioEspecialidade = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/especialidades', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioEspecialidade = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/especialidades', pathParams, {}, options), config)

export const queryFuncionariosControllerListarFuncionarioEspecialidades = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/especialidades', pathParams, queryParams)
export const useQueryFuncionariosControllerListarFuncionarioEspecialidades = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/especialidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarFuncionarioEspecialidades.queryKey = '/api/v1/funcionarios/{id}/especialidades'

export const queryFuncionariosControllerListarFuncionarioEspecialidadesRegistros = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/especialidades-registros', pathParams, queryParams)
export const useQueryFuncionariosControllerListarFuncionarioEspecialidadesRegistros = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/especialidades-registros', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarFuncionarioEspecialidadesRegistros.queryKey = '/api/v1/funcionarios/{id}/especialidades-registros'

export const queryFuncionariosControllerObterFuncionarioEspecialidadePadraoPorFuncionarioId = (pathParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/especialidade-padrao', pathParams)
export const useQueryFuncionariosControllerObterFuncionarioEspecialidadePadraoPorFuncionarioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/especialidade-padrao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerObterFuncionarioEspecialidadePadraoPorFuncionarioId.queryKey = '/api/v1/funcionarios/{id}/especialidade-padrao'

export const queryFuncionariosControllerListarAgendamentosDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/agendamentos-disponiveis', pathParams, queryParams)
export const useQueryFuncionariosControllerListarAgendamentosDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/agendamentos-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarAgendamentosDisponiveis.queryKey = '/api/v1/funcionarios/{id}/agendamentos-disponiveis'

export const mutationFuncionariosControllerCadastrar = (options) => mutationFn('post', '/api/v1/funcionarios', {}, {}, options)
export const useMutationFuncionariosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios', {}, {}, options), config)

export const queryFuncionariosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/funcionarios', queryParams)
export const useQueryFuncionariosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFuncionariosControllerBuscar.queryKey = '/api/v1/funcionarios'

export const mutationFuncionariosControllerCadastrarFuncionarioRegistro = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/registro', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioRegistro = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/registro', pathParams, {}, options), config)

export const queryFuncionariosControllerListarFuncionarioRegistros = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/registros', pathParams, queryParams)
export const useQueryFuncionariosControllerListarFuncionarioRegistros = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/registros', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarFuncionarioRegistros.queryKey = '/api/v1/funcionarios/{id}/registros'

export const mutationFuncionariosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/funcionarios/{id}', pathParams, {}, options)
export const useMutationFuncionariosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionarios/{id}', pathParams, {}, options), config)

export const mutationFuncionariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/funcionarios/{id}', pathParams, {}, options)
export const useMutationFuncionariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcionarios/{id}', pathParams, {}, options), config)

export const queryFuncionariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionarios/{id}', pathParams)
export const useQueryFuncionariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerObterPorId.queryKey = '/api/v1/funcionarios/{id}'

export const queryFuncionariosControllerListarTiposFuncionariosSaude = (queryParams, options) => queryFn(options)('/api/v1/funcionarios/tipos', queryParams)
export const useQueryFuncionariosControllerListarTiposFuncionariosSaude = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/tipos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFuncionariosControllerListarTiposFuncionariosSaude.queryKey = '/api/v1/funcionarios/tipos'

export const mutationFuncionariosControllerCadastrarFuncionarioTag = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/tags', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/tags', pathParams, {}, options), config)

export const queryFuncionariosControllerListarTagsFuncionario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/tags', pathParams, queryParams)
export const useQueryFuncionariosControllerListarTagsFuncionario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarTagsFuncionario.queryKey = '/api/v1/funcionarios/{id}/tags'

export const mutationFuncionariosControllerDeletarFuncionarioTag = (pathParams, options) => mutationFn('delete', '/api/v1/funcionarios/{id}/tag/{tagId}', pathParams, {}, options)
export const useMutationFuncionariosControllerDeletarFuncionarioTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionarios/{id}/tag/{tagId}', pathParams, {}, options), config)

export const queryFuncionariosControllerListarFuncionariosTags = (queryParams, options) => queryFn(options)('/api/v1/funcionarios/tags', queryParams)
export const useQueryFuncionariosControllerListarFuncionariosTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFuncionariosControllerListarFuncionariosTags.queryKey = '/api/v1/funcionarios/tags'

export const queryFuncionariosControllerListarTagsDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryFuncionariosControllerListarTagsDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarTagsDisponiveis.queryKey = '/api/v1/funcionarios/{id}/tags-disponiveis'

export const mutationFuncionariosControllerCadastrarFuncionarioConta = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/contas', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/contas', pathParams, {}, options), config)

export const queryFuncionariosControllerListarFuncionarioContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/contas', pathParams, queryParams)
export const useQueryFuncionariosControllerListarFuncionarioContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarFuncionarioContas.queryKey = '/api/v1/funcionarios/{id}/contas'

export const queryFuncionariosControllerObterFuncionarioContaPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/contas/{contaId}', pathParams)
export const useQueryFuncionariosControllerObterFuncionarioContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFuncionariosControllerObterFuncionarioContaPorId.queryKey = '/api/v1/funcionarios/{id}/contas/{contaId}'

export const mutationFuncionariosControllerAtualizarFuncionarioConta = (pathParams, options) => mutationFn('put', '/api/v1/funcionarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFuncionariosControllerAtualizarFuncionarioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcionarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFuncionariosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFuncionariosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarContasDisponiveis.queryKey = '/api/v1/funcionarios/{id}/contas-disponiveis'

export const mutationFuncionariosControllerCadastrarFuncionarioFuncaoConta = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/funcoes', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioFuncaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/funcoes', pathParams, {}, options), config)

export const queryFuncionariosControllerListarFuncionarioFuncoesConta = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/funcoes', pathParams, queryParams)
export const useQueryFuncionariosControllerListarFuncionarioFuncoesConta = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/funcoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarFuncionarioFuncoesConta.queryKey = '/api/v1/funcionarios/{id}/funcoes'

export const queryFuncionariosControllerObterFuncionarioFuncaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/funcoes/{funcaoId}', pathParams)
export const useQueryFuncionariosControllerObterFuncionarioFuncaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/funcoes/{funcaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.funcaoId, ...config }
})
useQueryFuncionariosControllerObterFuncionarioFuncaoContaPorId.queryKey = '/api/v1/funcionarios/{id}/funcoes/{funcaoId}'

export const mutationFuncionariosControllerDeletarFuncionarioFuncaoConta = (pathParams, options) => mutationFn('delete', '/api/v1/funcionarios/{id}/funcoes/{funcaoId}', pathParams, {}, options)
export const useMutationFuncionariosControllerDeletarFuncionarioFuncaoConta = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionarios/{id}/funcoes/{funcaoId}', pathParams, {}, options), config)

export const queryFuncionariosControllerObterCalendarioPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/calendario', pathParams)
export const useQueryFuncionariosControllerObterCalendarioPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/calendario', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerObterCalendarioPorId.queryKey = '/api/v1/funcionarios/{id}/calendario'

export const mutationFuncionariosControllerCadastrarFuncionarioComissao = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/comissoes', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioComissao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/comissoes', pathParams, {}, options), config)

export const queryFuncionariosControllerListarFuncionarioComissoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/comissoes', pathParams, queryParams)
export const useQueryFuncionariosControllerListarFuncionarioComissoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/comissoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarFuncionarioComissoes.queryKey = '/api/v1/funcionarios/{id}/comissoes'

export const mutationFuncionariosControllerDeletarFuncionarioComissao = (pathParams, options) => mutationFn('delete', '/api/v1/funcionarios/{id}/comissoes/{comissaoId}', pathParams, {}, options)
export const useMutationFuncionariosControllerDeletarFuncionarioComissao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionarios/{id}/comissoes/{comissaoId}', pathParams, {}, options), config)

export const queryFuncionarioSubEspecialidadesControllerObterFuncionarioSubEspecialidadePorId = (pathParams, options) => queryFn(options)('/api/v1/funcionario-sub-especialidades/{id}', pathParams)
export const useQueryFuncionarioSubEspecialidadesControllerObterFuncionarioSubEspecialidadePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionario-sub-especialidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionarioSubEspecialidadesControllerObterFuncionarioSubEspecialidadePorId.queryKey = '/api/v1/funcionario-sub-especialidades/{id}'

export const mutationFuncionarioSubEspecialidadesControllerDeletarFuncionarioSubEspecialidade = (pathParams, queryParams, options) => mutationFn('delete', '/api/v1/funcionario-sub-especialidades/{id}', pathParams, queryParams, options)
export const useMutationFuncionarioSubEspecialidadesControllerDeletarFuncionarioSubEspecialidade = (pathParams, queryParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionario-sub-especialidades/{id}', pathParams, queryParams, options), config)

export const mutationFuncoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/funcoes', {}, {}, options)
export const useMutationFuncoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/funcoes', {}, {}, options), config)

export const queryFuncoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/funcoes', queryParams)
export const useQueryFuncoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFuncoesControllerListar.queryKey = '/api/v1/funcoes'

export const queryFuncoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/funcoes/{id}', pathParams)
export const useQueryFuncoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncoesControllerObterPorId.queryKey = '/api/v1/funcoes/{id}'

export const mutationFuncoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/funcoes/{id}', pathParams, {}, options)
export const useMutationFuncoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcoes/{id}', pathParams, {}, options), config)

export const mutationFuncoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/funcoes/{id}', pathParams, {}, options)
export const useMutationFuncoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcoes/{id}', pathParams, {}, options), config)

export const mutationFuncoesControllerCadastrarFuncaoConta = (pathParams, options) => mutationFn('post', '/api/v1/funcoes/{id}/contas', pathParams, {}, options)
export const useMutationFuncoesControllerCadastrarFuncaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcoes/{id}/contas', pathParams, {}, options), config)

export const queryFuncoesControllerListarFuncaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcoes/{id}/contas', pathParams, queryParams)
export const useQueryFuncoesControllerListarFuncaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncoesControllerListarFuncaoContas.queryKey = '/api/v1/funcoes/{id}/contas'

export const queryFuncoesControllerObterFuncaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/funcoes/{id}/contas/{contaId}', pathParams)
export const useQueryFuncoesControllerObterFuncaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFuncoesControllerObterFuncaoContaPorId.queryKey = '/api/v1/funcoes/{id}/contas/{contaId}'

export const mutationFuncoesControllerAtualizarFuncaoConta = (pathParams, options) => mutationFn('put', '/api/v1/funcoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFuncoesControllerAtualizarFuncaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFuncoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFuncoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncoesControllerListarContasDisponiveis.queryKey = '/api/v1/funcoes/{id}/contas-disponiveis'

export const queryFusosHorariosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/fusos-horarios', queryParams)
export const useQueryFusosHorariosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fusos-horarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFusosHorariosControllerBuscar.queryKey = '/api/v1/fusos-horarios'

export const queryGrauInteressesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/grau-interesses', queryParams)
export const useQueryGrauInteressesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-interesses', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGrauInteressesControllerBuscar.queryKey = '/api/v1/grau-interesses'

export const mutationGrauInteressesControllerCadastrar = (options) => mutationFn('post', '/api/v1/grau-interesses', {}, {}, options)
export const useMutationGrauInteressesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grau-interesses', {}, {}, options), config)

export const queryGrauInteressesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grau-interesses/{id}', pathParams)
export const useQueryGrauInteressesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-interesses/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGrauInteressesControllerObterPorId.queryKey = '/api/v1/grau-interesses/{id}'

export const mutationGrauInteressesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grau-interesses/{id}', pathParams, {}, options)
export const useMutationGrauInteressesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grau-interesses/{id}', pathParams, {}, options), config)

export const mutationGrauInteressesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/grau-interesses/{id}', pathParams, {}, options)
export const useMutationGrauInteressesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grau-interesses/{id}', pathParams, {}, options), config)

export const mutationGrauInteressesControllerCadastrarGrauInteresseConta = (pathParams, options) => mutationFn('post', '/api/v1/grau-interesses/{id}/contas', pathParams, {}, options)
export const useMutationGrauInteressesControllerCadastrarGrauInteresseConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grau-interesses/{id}/contas', pathParams, {}, options), config)

export const queryGrauInteressesControllerListarGrauInteresseContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grau-interesses/{id}/contas', pathParams, queryParams)
export const useQueryGrauInteressesControllerListarGrauInteresseContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-interesses/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGrauInteressesControllerListarGrauInteresseContas.queryKey = '/api/v1/grau-interesses/{id}/contas'

export const queryGrauInteressesControllerObterGrauInteresseContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grau-interesses/{id}/contas/{contaId}', pathParams)
export const useQueryGrauInteressesControllerObterGrauInteresseContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-interesses/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGrauInteressesControllerObterGrauInteresseContaPorId.queryKey = '/api/v1/grau-interesses/{id}/contas/{contaId}'

export const mutationGrauInteressesControllerAtualizarGrauInteresseConta = (pathParams, options) => mutationFn('put', '/api/v1/grau-interesses/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGrauInteressesControllerAtualizarGrauInteresseConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grau-interesses/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGrauInteressesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grau-interesses/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGrauInteressesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-interesses/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGrauInteressesControllerListarContasDisponiveis.queryKey = '/api/v1/grau-interesses/{id}/contas-disponiveis'

export const queryGrauNecessidadeEspecialControllerObterListadeGrausNecessidadeEspecial = (options) => queryFn(options)('/api/v1/grau-de-necessidades-especiais')
export const useQueryGrauNecessidadeEspecialControllerObterListadeGrausNecessidadeEspecial = (config, options) => useQuery({
  queryKey: ['/api/v1/grau-de-necessidades-especiais'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGrauNecessidadeEspecialControllerObterListadeGrausNecessidadeEspecial.queryKey = '/api/v1/grau-de-necessidades-especiais'

export const queryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorId = (pathParams, options) => queryFn(options)('/api/v1/grau-de-necessidades-especiais/{id}', pathParams)
export const useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-de-necessidades-especiais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorId.queryKey = '/api/v1/grau-de-necessidades-especiais/{id}'

export const queryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorChave = (pathParams, options) => queryFn(options)('/api/v1/grau-de-necessidades-especiais/{chave}', pathParams)
export const useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-de-necessidades-especiais/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorChave.queryKey = '/api/v1/grau-de-necessidades-especiais/{chave}'

export const queryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizado = (queryParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado', queryParams)
export const useQueryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizado.queryKey = '/api/v1/grupos-cadastro-personalizado'

export const mutationGruposCadastroPersonalizadoControllerCadastrar = (options) => mutationFn('post', '/api/v1/grupos-cadastro-personalizado', {}, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grupos-cadastro-personalizado', {}, {}, options), config)

export const queryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}', pathParams)
export const useQueryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoPorId.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}'

export const mutationGruposCadastroPersonalizadoControllerAtualizarGrupoCadastroPersonalizado = (pathParams, options) => mutationFn('put', '/api/v1/grupos-cadastro-personalizado/{id}', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerAtualizarGrupoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-cadastro-personalizado/{id}', pathParams, {}, options), config)

export const mutationGruposCadastroPersonalizadoControllerDeletarGrupoCadastroPersonalizado = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-cadastro-personalizado/{id}', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerDeletarGrupoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-cadastro-personalizado/{id}', pathParams, {}, options), config)

export const mutationGruposCadastroPersonalizadoControllerCadastrarCampoCadastroPersonalizado = (pathParams, options) => mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/campos', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerCadastrarCampoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/campos', pathParams, {}, options), config)

export const queryGruposCadastroPersonalizadoControllerListarCamposGrupoCadastroPersonalizado = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}/campos', pathParams, queryParams)
export const useQueryGruposCadastroPersonalizadoControllerListarCamposGrupoCadastroPersonalizado = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}/campos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposCadastroPersonalizadoControllerListarCamposGrupoCadastroPersonalizado.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}/campos'

export const mutationGruposCadastroPersonalizadoControllerAtualizarOrdemCampos = (pathParams, options) => mutationFn('patch', '/api/v1/grupos-cadastro-personalizado/{id}/campos/ordenacao', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerAtualizarOrdemCampos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/grupos-cadastro-personalizado/{id}/campos/ordenacao', pathParams, {}, options), config)

export const mutationGruposCadastroPersonalizadoControllerCadastrarGrupoCadastroPersonalizadoConta = (pathParams, options) => mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/contas', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerCadastrarGrupoCadastroPersonalizadoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/contas', pathParams, {}, options), config)

export const queryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizadoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}/contas', pathParams, queryParams)
export const useQueryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizadoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizadoContas.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}/contas'

export const queryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}/contas/{contaId}', pathParams)
export const useQueryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoContaPorId.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}/contas/{contaId}'

export const mutationGruposCadastroPersonalizadoControllerAtualizarGrupoCadastroPersonalizadoConta = (pathParams, options) => mutationFn('put', '/api/v1/grupos-cadastro-personalizado/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerAtualizarGrupoCadastroPersonalizadoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-cadastro-personalizado/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGruposCadastroPersonalizadoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGruposCadastroPersonalizadoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposCadastroPersonalizadoControllerListarContasDisponiveis.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}/contas-disponiveis'

export const mutationGruposCadastroPersonalizadoControllerCadastrarEntidade = (pathParams, options) => mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/entidades', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerCadastrarEntidade = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/entidades', pathParams, {}, options), config)

export const queryGruposCadastroPersonalizadoControllerListarGruposEntidadeCadastroPersonalizado = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}/entidades', pathParams, queryParams)
export const useQueryGruposCadastroPersonalizadoControllerListarGruposEntidadeCadastroPersonalizado = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}/entidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposCadastroPersonalizadoControllerListarGruposEntidadeCadastroPersonalizado.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}/entidades'

export const mutationGruposCadastroPersonalizadoControllerDeletarGrupoEntidadeCadastroPersonalizado = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-cadastro-personalizado/{id}/entidades/{grupoEntidadeCadastroPersonalizadoId}', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerDeletarGrupoEntidadeCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-cadastro-personalizado/{id}/entidades/{grupoEntidadeCadastroPersonalizadoId}', pathParams, {}, options), config)

export const mutationGruposDocumentosControllerCadastrar = (options) => mutationFn('post', '/api/v1/grupos-de-documentos', {}, {}, options)
export const useMutationGruposDocumentosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-documentos', {}, {}, options), config)

export const queryGruposDocumentosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/grupos-de-documentos', queryParams)
export const useQueryGruposDocumentosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGruposDocumentosControllerBuscar.queryKey = '/api/v1/grupos-de-documentos'

export const mutationGruposDocumentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-documentos/{id}', pathParams, {}, options)
export const useMutationGruposDocumentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-documentos/{id}', pathParams, {}, options), config)

export const queryGruposDocumentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/{id}', pathParams)
export const useQueryGruposDocumentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposDocumentosControllerObterPorId.queryKey = '/api/v1/grupos-de-documentos/{id}'

export const mutationGruposDocumentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-de-documentos/{id}', pathParams, {}, options)
export const useMutationGruposDocumentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-de-documentos/{id}', pathParams, {}, options), config)

export const mutationGruposDocumentosControllerCadastrarGrupoDocumentoConta = (pathParams, options) => mutationFn('post', '/api/v1/grupos-de-documentos/{id}/contas', pathParams, {}, options)
export const useMutationGruposDocumentosControllerCadastrarGrupoDocumentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-documentos/{id}/contas', pathParams, {}, options), config)

export const queryGruposDocumentosControllerListarGrupoDocumentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/{id}/contas', pathParams, queryParams)
export const useQueryGruposDocumentosControllerListarGrupoDocumentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposDocumentosControllerListarGrupoDocumentoContas.queryKey = '/api/v1/grupos-de-documentos/{id}/contas'

export const queryGruposDocumentosControllerObterGrupoDocumentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/{id}/contas/{contaId}', pathParams)
export const useQueryGruposDocumentosControllerObterGrupoDocumentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGruposDocumentosControllerObterGrupoDocumentoContaPorId.queryKey = '/api/v1/grupos-de-documentos/{id}/contas/{contaId}'

export const mutationGruposDocumentosControllerAtualizarGrupoDocumentoConta = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-documentos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGruposDocumentosControllerAtualizarGrupoDocumentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-documentos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGruposDocumentosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGruposDocumentosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposDocumentosControllerListarContasDisponiveis.queryKey = '/api/v1/grupos-de-documentos/{id}/contas-disponiveis'

export const mutationGruposDocumentosControllerCadastrarDocumento = (pathParams, options) => mutationFn('post', '/api/v1/grupos-de-documentos/{id}/documentos', pathParams, {}, options)
export const useMutationGruposDocumentosControllerCadastrarDocumento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-documentos/{id}/documentos', pathParams, {}, options), config)

export const queryGruposDocumentosControllerListarDocumentoPorGrupoDocumento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/{id}/documentos', pathParams, queryParams)
export const useQueryGruposDocumentosControllerListarDocumentoPorGrupoDocumento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/{id}/documentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposDocumentosControllerListarDocumentoPorGrupoDocumento.queryKey = '/api/v1/grupos-de-documentos/{id}/documentos'

export const queryGruposDocumentosControllerObterDocumentoPorIdGrupoDocumento = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/documentos/{docId}', pathParams)
export const useQueryGruposDocumentosControllerObterDocumentoPorIdGrupoDocumento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/documentos/{docId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.docId, ...config }
})
useQueryGruposDocumentosControllerObterDocumentoPorIdGrupoDocumento.queryKey = '/api/v1/grupos-de-documentos/documentos/{docId}'

export const mutationGruposDocumentosControllerDeletarDocumentoGrupoDocumentos = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-de-documentos/{id}/documentos/{docId}', pathParams, {}, options)
export const useMutationGruposDocumentosControllerDeletarDocumentoGrupoDocumentos = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-de-documentos/{id}/documentos/{docId}', pathParams, {}, options), config)

export const mutationGruposExameControllerCadastrar = (options) => mutationFn('post', '/api/v1/grupos-de-exame', {}, {}, options)
export const useMutationGruposExameControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-exame', {}, {}, options), config)

export const queryGruposExameControllerListar = (queryParams, options) => queryFn(options)('/api/v1/grupos-de-exame', queryParams)
export const useQueryGruposExameControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-exame', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGruposExameControllerListar.queryKey = '/api/v1/grupos-de-exame'

export const queryGruposExameControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-exame/{id}', pathParams)
export const useQueryGruposExameControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-exame/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposExameControllerObterPorId.queryKey = '/api/v1/grupos-de-exame/{id}'

export const mutationGruposExameControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-exame/{id}', pathParams, {}, options)
export const useMutationGruposExameControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-exame/{id}', pathParams, {}, options), config)

export const mutationGruposExameControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-de-exame/{id}', pathParams, {}, options)
export const useMutationGruposExameControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-de-exame/{id}', pathParams, {}, options), config)

export const mutationGruposExameControllerCadastrarGrupoExameConta = (pathParams, options) => mutationFn('post', '/api/v1/grupos-de-exame/{id}/contas', pathParams, {}, options)
export const useMutationGruposExameControllerCadastrarGrupoExameConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-exame/{id}/contas', pathParams, {}, options), config)

export const queryGruposExameControllerListarGrupoExameContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-exame/{id}/contas', pathParams, queryParams)
export const useQueryGruposExameControllerListarGrupoExameContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-exame/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposExameControllerListarGrupoExameContas.queryKey = '/api/v1/grupos-de-exame/{id}/contas'

export const queryGruposExameControllerObterGrupoExameContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-exame/{id}/contas/{contaId}', pathParams)
export const useQueryGruposExameControllerObterGrupoExameContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-exame/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGruposExameControllerObterGrupoExameContaPorId.queryKey = '/api/v1/grupos-de-exame/{id}/contas/{contaId}'

export const mutationGruposExameControllerAtualizarGrupoExameConta = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-exame/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGruposExameControllerAtualizarGrupoExameConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-exame/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGruposExameControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-exame/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGruposExameControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-exame/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposExameControllerListarContasDisponiveis.queryKey = '/api/v1/grupos-de-exame/{id}/contas-disponiveis'

export const queryGruposProcedimentoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/grupos-de-procedimentos', queryParams)
export const useQueryGruposProcedimentoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-procedimentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGruposProcedimentoControllerBuscar.queryKey = '/api/v1/grupos-de-procedimentos'

export const mutationGruposProcedimentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/grupos-de-procedimentos', {}, {}, options)
export const useMutationGruposProcedimentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-procedimentos', {}, {}, options), config)

export const queryGruposProcedimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-procedimentos/{id}', pathParams)
export const useQueryGruposProcedimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-procedimentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposProcedimentoControllerObterPorId.queryKey = '/api/v1/grupos-de-procedimentos/{id}'

export const mutationGruposProcedimentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-procedimentos/{id}', pathParams, {}, options)
export const useMutationGruposProcedimentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-procedimentos/{id}', pathParams, {}, options), config)

export const mutationGruposProcedimentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-de-procedimentos/{id}', pathParams, {}, options)
export const useMutationGruposProcedimentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-de-procedimentos/{id}', pathParams, {}, options), config)

export const mutationGruposProcedimentoControllerCadastrarGrupoProcedimentoConta = (pathParams, options) => mutationFn('post', '/api/v1/grupos-de-procedimentos/{id}/contas', pathParams, {}, options)
export const useMutationGruposProcedimentoControllerCadastrarGrupoProcedimentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-procedimentos/{id}/contas', pathParams, {}, options), config)

export const queryGruposProcedimentoControllerListarGrupoProcedimentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-procedimentos/{id}/contas', pathParams, queryParams)
export const useQueryGruposProcedimentoControllerListarGrupoProcedimentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-procedimentos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposProcedimentoControllerListarGrupoProcedimentoContas.queryKey = '/api/v1/grupos-de-procedimentos/{id}/contas'

export const queryGruposProcedimentoControllerObterGrupoProcedimentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-procedimentos/{id}/contas/{contaId}', pathParams)
export const useQueryGruposProcedimentoControllerObterGrupoProcedimentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-procedimentos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGruposProcedimentoControllerObterGrupoProcedimentoContaPorId.queryKey = '/api/v1/grupos-de-procedimentos/{id}/contas/{contaId}'

export const mutationGruposProcedimentoControllerAtualizarGrupoProcedimentoConta = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-procedimentos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGruposProcedimentoControllerAtualizarGrupoProcedimentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-procedimentos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGruposProcedimentoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-procedimentos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGruposProcedimentoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-procedimentos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposProcedimentoControllerListarContasDisponiveis.queryKey = '/api/v1/grupos-de-procedimentos/{id}/contas-disponiveis'

export const mutationGruposProfissionalControllerCadastrar = (options) => mutationFn('post', '/api/v1/grupos-de-profissional', {}, {}, options)
export const useMutationGruposProfissionalControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-profissional', {}, {}, options), config)

export const queryGruposProfissionalControllerListar = (queryParams, options) => queryFn(options)('/api/v1/grupos-de-profissional', queryParams)
export const useQueryGruposProfissionalControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-profissional', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGruposProfissionalControllerListar.queryKey = '/api/v1/grupos-de-profissional'

export const queryGruposProfissionalControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-profissional/{id}', pathParams)
export const useQueryGruposProfissionalControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-profissional/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposProfissionalControllerObterPorId.queryKey = '/api/v1/grupos-de-profissional/{id}'

export const mutationGruposProfissionalControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-profissional/{id}', pathParams, {}, options)
export const useMutationGruposProfissionalControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-profissional/{id}', pathParams, {}, options), config)

export const mutationGruposProfissionalControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-de-profissional/{id}', pathParams, {}, options)
export const useMutationGruposProfissionalControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-de-profissional/{id}', pathParams, {}, options), config)

export const mutationGruposProfissionalControllerCadastrarGrupoProfissionalConta = (pathParams, options) => mutationFn('post', '/api/v1/grupos-de-profissional/{id}/contas', pathParams, {}, options)
export const useMutationGruposProfissionalControllerCadastrarGrupoProfissionalConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-profissional/{id}/contas', pathParams, {}, options), config)

export const queryGruposProfissionalControllerListarGrupoProfissionalContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-profissional/{id}/contas', pathParams, queryParams)
export const useQueryGruposProfissionalControllerListarGrupoProfissionalContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-profissional/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposProfissionalControllerListarGrupoProfissionalContas.queryKey = '/api/v1/grupos-de-profissional/{id}/contas'

export const queryGruposProfissionalControllerObterGrupoProfissionalContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-profissional/{id}/contas/{contaId}', pathParams)
export const useQueryGruposProfissionalControllerObterGrupoProfissionalContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-profissional/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGruposProfissionalControllerObterGrupoProfissionalContaPorId.queryKey = '/api/v1/grupos-de-profissional/{id}/contas/{contaId}'

export const mutationGruposProfissionalControllerAtualizarGrupoProfissionalConta = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-profissional/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGruposProfissionalControllerAtualizarGrupoProfissionalConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-profissional/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGruposProfissionalControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-profissional/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGruposProfissionalControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-profissional/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposProfissionalControllerListarContasDisponiveis.queryKey = '/api/v1/grupos-de-profissional/{id}/contas-disponiveis'

export const queryGuiasControllerObterPorId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/guias/{id}', pathParams, queryParams)
export const useQueryGuiasControllerObterPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/guias/{id}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGuiasControllerObterPorId.queryKey = '/api/v1/guias/{id}'

export const mutationGuiasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/guias/{id}', pathParams, {}, options)
export const useMutationGuiasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/guias/{id}', pathParams, {}, options), config)

export const mutationGuiasControllerAtualizarTipoAvaliacaoNotaEquivalente = (pathParams, options) => mutationFn('put', '/api/v1/guias/{id}', pathParams, {}, options)
export const useMutationGuiasControllerAtualizarTipoAvaliacaoNotaEquivalente = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/guias/{id}', pathParams, {}, options), config)

export const queryHistoricoContatosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/historico-contatos/{id}', pathParams)
export const useQueryHistoricoContatosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/historico-contatos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHistoricoContatosControllerObterPorId.queryKey = '/api/v1/historico-contatos/{id}'

export const queryInstituicoesBancariaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/instituicoes-bancaria', queryParams)
export const useQueryInstituicoesBancariaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/instituicoes-bancaria', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryInstituicoesBancariaControllerListar.queryKey = '/api/v1/instituicoes-bancaria'

export const queryInstituicoesBancariaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/instituicoes-bancaria/{id}', pathParams)
export const useQueryInstituicoesBancariaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/instituicoes-bancaria/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryInstituicoesBancariaControllerObterPorId.queryKey = '/api/v1/instituicoes-bancaria/{id}'

export const queryInstituicoesBancariaControllerListarInstituicoesBancariasMovimentacoes = (queryParams, options) => queryFn(options)('/api/v1/instituicoes-bancaria/movimentacoes', queryParams)
export const useQueryInstituicoesBancariaControllerListarInstituicoesBancariasMovimentacoes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/instituicoes-bancaria/movimentacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryInstituicoesBancariaControllerListarInstituicoesBancariasMovimentacoes.queryKey = '/api/v1/instituicoes-bancaria/movimentacoes'

export const queryIntegracaoTiposControllerListar = (queryParams, options) => queryFn(options)('/api/v1/integracao-tipos', queryParams)
export const useQueryIntegracaoTiposControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracao-tipos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryIntegracaoTiposControllerListar.queryKey = '/api/v1/integracao-tipos'

export const queryIntegracaoTiposControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/integracao-tipos/{id}', pathParams)
export const useQueryIntegracaoTiposControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracao-tipos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryIntegracaoTiposControllerObterPorId.queryKey = '/api/v1/integracao-tipos/{id}'

export const queryIntegracaoTiposControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/integracao-tipos/{chave}', pathParams)
export const useQueryIntegracaoTiposControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracao-tipos/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryIntegracaoTiposControllerObterPorChave.queryKey = '/api/v1/integracao-tipos/{chave}'

export const queryIntegracoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/integracoes', queryParams)
export const useQueryIntegracoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryIntegracoesControllerListar.queryKey = '/api/v1/integracoes'

export const queryIntegracoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/integracoes/{id}', pathParams)
export const useQueryIntegracoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryIntegracoesControllerObterPorId.queryKey = '/api/v1/integracoes/{id}'

export const queryIntegracoesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/integracoes/{chave}', pathParams)
export const useQueryIntegracoesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracoes/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryIntegracoesControllerObterPorChave.queryKey = '/api/v1/integracoes/{chave}'

export const queryItensControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/itens', queryParams)
export const useQueryItensControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryItensControllerBuscar.queryKey = '/api/v1/itens'

export const queryItensControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens/{id}', pathParams)
export const useQueryItensControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterPorId.queryKey = '/api/v1/itens/{id}'

export const queryItensControllerObterPorEan = (pathParams, options) => queryFn(options)('/api/v1/itens/{ean}', pathParams)
export const useQueryItensControllerObterPorEan = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{ean}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.ean, ...config }
})
useQueryItensControllerObterPorEan.queryKey = '/api/v1/itens/{ean}'

export const queryItensControllerObterPorCodigo = (pathParams, options) => queryFn(options)('/api/v1/itens/{codigo}/listar', pathParams)
export const useQueryItensControllerObterPorCodigo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{codigo}/listar', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.codigo, ...config }
})
useQueryItensControllerObterPorCodigo.queryKey = '/api/v1/itens/{codigo}/listar'

export const queryItensControllerObterSimplificadoPorId = (pathParams, options) => queryFn(options)('/api/v1/itens/{id}/simplificado', pathParams)
export const useQueryItensControllerObterSimplificadoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/simplificado', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterSimplificadoPorId.queryKey = '/api/v1/itens/{id}/simplificado'

export const queryItensControllerBuscarHistoricoDePrecos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/historico-precos', pathParams, queryParams)
export const useQueryItensControllerBuscarHistoricoDePrecos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/historico-precos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerBuscarHistoricoDePrecos.queryKey = '/api/v1/itens/{id}/historico-precos'

export const queryItensControllerCalcularPrecoTotalItem = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/calcular-preco-total-itens', pathParams, queryParams)
export const useQueryItensControllerCalcularPrecoTotalItem = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/calcular-preco-total-itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerCalcularPrecoTotalItem.queryKey = '/api/v1/itens/{id}/calcular-preco-total-itens'

export const queryItensControllerObterPreco = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/obter-preco', pathParams, queryParams)
export const useQueryItensControllerObterPreco = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/obter-preco', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterPreco.queryKey = '/api/v1/itens/{id}/obter-preco'

export const queryItensControllerListarItemPrecos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/precos', pathParams, queryParams)
export const useQueryItensControllerListarItemPrecos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/precos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarItemPrecos.queryKey = '/api/v1/itens/{id}/precos'

export const mutationItensControllerCadastrarItemPreco = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/precos', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemPreco = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/precos', pathParams, {}, options), config)

export const mutationItensControllerAtualizarPrecoDeVenda = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/atualizar-preco', pathParams, {}, options)
export const useMutationItensControllerAtualizarPrecoDeVenda = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/atualizar-preco', pathParams, {}, options), config)

export const mutationItensControllerCadastrarEmbalagem = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/embalagem', pathParams, {}, options)
export const useMutationItensControllerCadastrarEmbalagem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/embalagem', pathParams, {}, options), config)

export const queryItensControllerListarEmbalagens = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/embalagens', pathParams, queryParams)
export const useQueryItensControllerListarEmbalagens = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/embalagens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarEmbalagens.queryKey = '/api/v1/itens/{id}/embalagens'

export const mutationItensControllerCadastrarItemComposicao = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/item-composicao', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemComposicao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/item-composicao', pathParams, {}, options), config)

export const queryItensControllerObterItensComposicaoPorItemId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/itens-composicao', pathParams, queryParams)
export const useQueryItensControllerObterItensComposicaoPorItemId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/itens-composicao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterItensComposicaoPorItemId.queryKey = '/api/v1/itens/{id}/itens-composicao'

export const mutationItensControllerCadastrarItemConta = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/contas', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/contas', pathParams, {}, options), config)

export const queryItensControllerListarItemContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/contas', pathParams, queryParams)
export const useQueryItensControllerListarItemContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarItemContas.queryKey = '/api/v1/itens/{id}/contas'

export const queryItensControllerObterItemContaPorId = (pathParams, options) => queryFn(options)('/api/v1/itens/{id}/contas/{contaId}', pathParams)
export const useQueryItensControllerObterItemContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryItensControllerObterItemContaPorId.queryKey = '/api/v1/itens/{id}/contas/{contaId}'

export const mutationItensControllerAtualizarItemConta = (pathParams, options) => mutationFn('put', '/api/v1/itens/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationItensControllerAtualizarItemConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryItensControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryItensControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarContasDisponiveis.queryKey = '/api/v1/itens/{id}/contas-disponiveis'

export const mutationItensControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/arquivos', pathParams, {}, options)
export const useMutationItensControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/arquivos', pathParams, {}, options), config)

export const queryItensControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/arquivos', pathParams, queryParams)
export const useQueryItensControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarArquivos.queryKey = '/api/v1/itens/{id}/arquivos'

export const mutationItensControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/itens/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationItensControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const mutationItensControllerCadastrarAvatar = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/avatar', pathParams, {}, options)
export const useMutationItensControllerCadastrarAvatar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/avatar', pathParams, {}, options), config)

export const mutationItensControllerDeletarAvatar = (pathParams, options) => mutationFn('delete', '/api/v1/itens/{id}/avatar', pathParams, {}, options)
export const useMutationItensControllerDeletarAvatar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens/{id}/avatar', pathParams, {}, options), config)

export const mutationItensControllerCadastrarItemTag = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/tags', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/tags', pathParams, {}, options), config)

export const queryItensControllerListarTagsItem = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/tags', pathParams, queryParams)
export const useQueryItensControllerListarTagsItem = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarTagsItem.queryKey = '/api/v1/itens/{id}/tags'

export const mutationItensControllerDeletarItemTag = (pathParams, options) => mutationFn('delete', '/api/v1/itens/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationItensControllerDeletarItemTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryItensControllerListarItensTags = (queryParams, options) => queryFn(options)('/api/v1/itens/tags', queryParams)
export const useQueryItensControllerListarItensTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryItensControllerListarItensTags.queryKey = '/api/v1/itens/tags'

export const queryItensControllerListarTagsDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryItensControllerListarTagsDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarTagsDisponiveis.queryKey = '/api/v1/itens/{id}/tags-disponiveis'

export const queryItensControllerListarItemTributacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/tributacoes', pathParams, queryParams)
export const useQueryItensControllerListarItemTributacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/tributacoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarItemTributacoes.queryKey = '/api/v1/itens/{id}/tributacoes'

export const mutationItensControllerCadastrarItemTributacao = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/tributacoes', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemTributacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/tributacoes', pathParams, {}, options), config)

export const queryItensControllerObterTributacaoPadraoPorItemId = (pathParams, options) => queryFn(options)('/api/v1/itens/{id}/tributacao-padrao', pathParams)
export const useQueryItensControllerObterTributacaoPadraoPorItemId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/tributacao-padrao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterTributacaoPadraoPorItemId.queryKey = '/api/v1/itens/{id}/tributacao-padrao'

export const queryItensControllerListarEquipes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/equipes', pathParams, queryParams)
export const useQueryItensControllerListarEquipes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/equipes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarEquipes.queryKey = '/api/v1/itens/{id}/equipes'

export const mutationItensControllerCadastrarEquipe = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/equipes', pathParams, {}, options)
export const useMutationItensControllerCadastrarEquipe = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/equipes', pathParams, {}, options), config)

export const queryItensControllerObterItensFuncionarios = (queryParams, options) => queryFn(options)('/api/v1/itens/funcionarios', queryParams)
export const useQueryItensControllerObterItensFuncionarios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/funcionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryItensControllerObterItensFuncionarios.queryKey = '/api/v1/itens/funcionarios'

export const queryItensControllerObterEquipamentosFuncionarios = (queryParams, options) => queryFn(options)('/api/v1/itens/equipamentos/funcionarios', queryParams)
export const useQueryItensControllerObterEquipamentosFuncionarios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/equipamentos/funcionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryItensControllerObterEquipamentosFuncionarios.queryKey = '/api/v1/itens/equipamentos/funcionarios'

export const queryItensComposicaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-composicao/{id}', pathParams)
export const useQueryItensComposicaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-composicao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensComposicaoControllerObterPorId.queryKey = '/api/v1/itens-composicao/{id}'

export const mutationItensComposicaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-composicao/{id}', pathParams, {}, options)
export const useMutationItensComposicaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-composicao/{id}', pathParams, {}, options), config)

export const mutationItensComposicaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-composicao/{id}', pathParams, {}, options)
export const useMutationItensComposicaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-composicao/{id}', pathParams, {}, options), config)

export const mutationItensOrcamentoEntradaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-orcamento-entrada/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoEntradaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-orcamento-entrada/{id}', pathParams, {}, options), config)

export const mutationItensOrcamentoEntradaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-entrada/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoEntradaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-entrada/{id}', pathParams, {}, options), config)

export const queryItensOrcamentoEntradaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-entrada/{id}', pathParams)
export const useQueryItensOrcamentoEntradaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-entrada/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoEntradaControllerObterPorId.queryKey = '/api/v1/itens-orcamento-entrada/{id}'

export const mutationItensOrcamentoSaidaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-orcamento-saida/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-orcamento-saida/{id}', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaSaudeControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/{id}', pathParams)
export const useQueryItensOrcamentoSaidaSaudeControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaSaudeControllerObterPorId.queryKey = '/api/v1/itens-orcamento-saida/{id}'

export const mutationItensOrcamentoSaidaControllerPrecificar = (pathParams, options) => mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/precificar', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerPrecificar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/precificar', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaControllerObterCondicaoPagamentoItemPorItemOrcamentoId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento', pathParams)
export const useQueryItensOrcamentoSaidaControllerObterCondicaoPagamentoItemPorItemOrcamentoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaControllerObterCondicaoPagamentoItemPorItemOrcamentoId.queryKey = '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento'

export const mutationItensOrcamentoSaidaControllerCadastrarCondicaoPagamentoItem = (pathParams, options) => mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerCadastrarCondicaoPagamentoItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaControllerObterCondicaoItemPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams)
export const useQueryItensOrcamentoSaidaControllerObterCondicaoItemPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.condicaoPagamentoItemId, ...config }
})
useQueryItensOrcamentoSaidaControllerObterCondicaoItemPorId.queryKey = '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}'

export const mutationItensOrcamentoSaidaControllerAtualizarCondicaoPagamentoItem = (pathParams, options) => mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerAtualizarCondicaoPagamentoItem = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaControllerDeletarCondicaoItem = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerDeletarCondicaoItem = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaControllerObterPossiveisResponsaveisCondicaoPorClienteId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/condicoes-pagamento/possiveis-responsaveis/{clienteId}', pathParams, queryParams)
export const useQueryItensOrcamentoSaidaControllerObterPossiveisResponsaveisCondicaoPorClienteId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/condicoes-pagamento/possiveis-responsaveis/{clienteId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.clienteId, ...config }
})
useQueryItensOrcamentoSaidaControllerObterPossiveisResponsaveisCondicaoPorClienteId.queryKey = '/api/v1/itens-orcamento-saida/condicoes-pagamento/possiveis-responsaveis/{clienteId}'

export const queryItensOrcamentoSaidaControllerCalcularParcelas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas', pathParams, queryParams)
export const useQueryItensOrcamentoSaidaControllerCalcularParcelas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.condicaoPagamentoItemId, ...config }
})
useQueryItensOrcamentoSaidaControllerCalcularParcelas.queryKey = '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas'

export const mutationItensOrcamentoSaidaControllerAtualizarParcela = (pathParams, options) => mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/parcelas', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerAtualizarParcela = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/parcelas', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaControllerCadastrarCondicaoPagamentoItemDesconto = (pathParams, options) => mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerCadastrarCondicaoPagamentoItemDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaControllerDeletarCondicaoPagamentoItemDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos/{tipoDescontoChave}/{configuracaoDescontoId}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerDeletarCondicaoPagamentoItemDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos/{tipoDescontoChave}/{configuracaoDescontoId}', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaControllerCadastrarConvenio = (pathParams, options) => mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/convenio', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerCadastrarConvenio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/convenio', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaControllerListarConveniosPorItemOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/{id}/convenios', pathParams, queryParams)
export const useQueryItensOrcamentoSaidaControllerListarConveniosPorItemOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/{id}/convenios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaControllerListarConveniosPorItemOrcamentoId.queryKey = '/api/v1/itens-orcamento-saida/{id}/convenios'

export const mutationItensOrcamentoSaidaControllerCadastrarDesconto = (pathParams, options) => mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/desconto', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerCadastrarDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/desconto', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaControllerListarDescontosPorItemOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/{id}/descontos', pathParams, queryParams)
export const useQueryItensOrcamentoSaidaControllerListarDescontosPorItemOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/{id}/descontos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaControllerListarDescontosPorItemOrcamentoId.queryKey = '/api/v1/itens-orcamento-saida/{id}/descontos'

export const queryItensOrcamentoSaidaCondicaoParcelaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida-condicao-parcela/{id}', pathParams)
export const useQueryItensOrcamentoSaidaCondicaoParcelaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida-condicao-parcela/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaCondicaoParcelaControllerObterPorId.queryKey = '/api/v1/itens-orcamento-saida-condicao-parcela/{id}'

export const queryItensOrcamentoSaidaConvenioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida-convenio/{id}', pathParams)
export const useQueryItensOrcamentoSaidaConvenioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida-convenio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaConvenioControllerObterPorId.queryKey = '/api/v1/itens-orcamento-saida-convenio/{id}'

export const mutationItensOrcamentoSaidaConvenioControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-saida-convenio/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaConvenioControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-saida-convenio/{id}', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaDescontoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida-desconto/{id}', pathParams)
export const useQueryItensOrcamentoSaidaDescontoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida-desconto/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaDescontoControllerObterPorId.queryKey = '/api/v1/itens-orcamento-saida-desconto/{id}'

export const mutationItensOrcamentoSaidaDescontoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-saida-desconto/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaDescontoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-saida-desconto/{id}', pathParams, {}, options), config)

export const mutationItensPedidoCompraControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-pedido-compra/{id}', pathParams, {}, options)
export const useMutationItensPedidoCompraControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-pedido-compra/{id}', pathParams, {}, options), config)

export const mutationItensPedidoCompraControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-pedido-compra/{id}', pathParams, {}, options)
export const useMutationItensPedidoCompraControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-pedido-compra/{id}', pathParams, {}, options), config)

export const queryItensPedidoCompraControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-pedido-compra/{id}', pathParams)
export const useQueryItensPedidoCompraControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-pedido-compra/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPedidoCompraControllerObterPorId.queryKey = '/api/v1/itens-pedido-compra/{id}'

export const mutationItensPedidoVendaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-pedido-venda/{id}', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-pedido-venda/{id}', pathParams, {}, options), config)

export const mutationItensPedidoVendaSaudeControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-pedido-venda/{id}', pathParams, {}, options)
export const useMutationItensPedidoVendaSaudeControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-pedido-venda/{id}', pathParams, {}, options), config)

export const queryItensPedidoVendaSaudeControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-pedido-venda/{id}', pathParams)
export const useQueryItensPedidoVendaSaudeControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-pedido-venda/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPedidoVendaSaudeControllerObterPorId.queryKey = '/api/v1/itens-pedido-venda/{id}'

export const queryItensPedidoVendaControllerObterCondicaoItemPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams)
export const useQueryItensPedidoVendaControllerObterCondicaoItemPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.condicaoPagamentoItemId, ...config }
})
useQueryItensPedidoVendaControllerObterCondicaoItemPorId.queryKey = '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}'

export const mutationItensPedidoVendaControllerAtualizarCondicaoPagamentoItem = (pathParams, options) => mutationFn('put', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerAtualizarCondicaoPagamentoItem = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options), config)

export const mutationItensPedidoVendaControllerDeletarCondicaoItem = (pathParams, options) => mutationFn('delete', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerDeletarCondicaoItem = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options), config)

export const mutationItensPedidoVendaControllerCadastrarCondicaoPagamentoItem = (pathParams, options) => mutationFn('post', '/api/v1/itens-pedido-venda/{id}/condicao-pagamento', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerCadastrarCondicaoPagamentoItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-pedido-venda/{id}/condicao-pagamento', pathParams, {}, options), config)

export const queryItensPedidoVendaControllerCalcularParcelas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas', pathParams, queryParams)
export const useQueryItensPedidoVendaControllerCalcularParcelas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.condicaoPagamentoItemId, ...config }
})
useQueryItensPedidoVendaControllerCalcularParcelas.queryKey = '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas'

export const queryItensPedidoVendaControllerObterCondicaoPagamentoItemPorItemPedidoVendaId = (pathParams, options) => queryFn(options)('/api/v1/itens-pedido-venda/{id}/condicoes-pagamento', pathParams)
export const useQueryItensPedidoVendaControllerObterCondicaoPagamentoItemPorItemPedidoVendaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-pedido-venda/{id}/condicoes-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPedidoVendaControllerObterCondicaoPagamentoItemPorItemPedidoVendaId.queryKey = '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento'

export const mutationItensPedidoVendaControllerCadastrarCondicaoPagamentoItemDesconto = (pathParams, options) => mutationFn('post', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerCadastrarCondicaoPagamentoItemDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos', pathParams, {}, options), config)

export const mutationItensPedidoVendaControllerDeletarCondicaoPagamentoItemDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos/{tipoDescontoChave}/{configuracaoDescontoId}', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerDeletarCondicaoPagamentoItemDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos/{tipoDescontoChave}/{configuracaoDescontoId}', pathParams, {}, options), config)

export const queryItensPrecosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-precos/{id}', pathParams)
export const useQueryItensPrecosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-precos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerObterPorId.queryKey = '/api/v1/itens-precos/{id}'

export const mutationItensPrecosControllerAtualizarPreco = (pathParams, options) => mutationFn('put', '/api/v1/itens-precos/{id}', pathParams, {}, options)
export const useMutationItensPrecosControllerAtualizarPreco = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-precos/{id}', pathParams, {}, options), config)

export const mutationItensPrecosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-precos/{id}', pathParams, {}, options)
export const useMutationItensPrecosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-precos/{id}', pathParams, {}, options), config)

export const mutationItensPrecosControllerCadastrarItemPrecoConta = (pathParams, options) => mutationFn('post', '/api/v1/itens-precos/{id}/contas', pathParams, {}, options)
export const useMutationItensPrecosControllerCadastrarItemPrecoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-precos/{id}/contas', pathParams, {}, options), config)

export const queryItensPrecosControllerListarItemPrecoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-precos/{id}/contas', pathParams, queryParams)
export const useQueryItensPrecosControllerListarItemPrecoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-precos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerListarItemPrecoContas.queryKey = '/api/v1/itens-precos/{id}/contas'

export const queryItensPrecosControllerObterItemPrecoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-precos/{id}/contas/{contaId}', pathParams)
export const useQueryItensPrecosControllerObterItemPrecoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-precos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryItensPrecosControllerObterItemPrecoContaPorId.queryKey = '/api/v1/itens-precos/{id}/contas/{contaId}'

export const mutationItensPrecosControllerAtualizarItemPrecoConta = (pathParams, options) => mutationFn('put', '/api/v1/itens-precos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationItensPrecosControllerAtualizarItemPrecoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-precos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryItensPrecosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-precos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryItensPrecosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-precos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerListarContasDisponiveis.queryKey = '/api/v1/itens-precos/{id}/contas-disponiveis'

export const mutationItensPrecosControllerCadastrarItemPrecoCondicaoPagamento = (pathParams, options) => mutationFn('post', '/api/v1/itens-precos/{id}/condicao-pagamentos', pathParams, {}, options)
export const useMutationItensPrecosControllerCadastrarItemPrecoCondicaoPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-precos/{id}/condicao-pagamentos', pathParams, {}, options), config)

export const queryItensPrecosControllerListarItemPrecosCondicaoPagamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-precos/{id}/condicao-pagamentos', pathParams, queryParams)
export const useQueryItensPrecosControllerListarItemPrecosCondicaoPagamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-precos/{id}/condicao-pagamentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerListarItemPrecosCondicaoPagamentos.queryKey = '/api/v1/itens-precos/{id}/condicao-pagamentos'

export const mutationItensPrecosCondicaoPagamentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-precos-condicao-pagamentos/{id}', pathParams, {}, options)
export const useMutationItensPrecosCondicaoPagamentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-precos-condicao-pagamentos/{id}', pathParams, {}, options), config)

export const mutationItensTributacoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-tributacoes/{id}', pathParams, {}, options)
export const useMutationItensTributacoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-tributacoes/{id}', pathParams, {}, options), config)

export const mutationItensTributacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-tributacoes/{id}', pathParams, {}, options)
export const useMutationItensTributacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-tributacoes/{id}', pathParams, {}, options), config)

export const queryJornadasAtendimentosControllerListarSituacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/jornadas-dos-atendimentos/{id}', pathParams, queryParams)
export const useQueryJornadasAtendimentosControllerListarSituacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/jornadas-dos-atendimentos/{id}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryJornadasAtendimentosControllerListarSituacoes.queryKey = '/api/v1/jornadas-dos-atendimentos/{id}'

export const queryJornadasAtendimentosControllerListarSituacoesPreAtendimentoRetornoPorCliente = (queryParams, options) => queryFn(options)('/api/v1/jornadas-dos-atendimentos/atendimentosclientepreretorno', queryParams)
export const useQueryJornadasAtendimentosControllerListarSituacoesPreAtendimentoRetornoPorCliente = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/jornadas-dos-atendimentos/atendimentosclientepreretorno', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryJornadasAtendimentosControllerListarSituacoesPreAtendimentoRetornoPorCliente.queryKey = '/api/v1/jornadas-dos-atendimentos/atendimentosclientepreretorno'

export const queryJornadasAtendimentosControllerKanbanAtendimento = (queryParams, options) => queryFn(options)('/api/v1/jornadas-dos-atendimentos/kanban', queryParams)
export const useQueryJornadasAtendimentosControllerKanbanAtendimento = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/jornadas-dos-atendimentos/kanban', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryJornadasAtendimentosControllerKanbanAtendimento.queryKey = '/api/v1/jornadas-dos-atendimentos/kanban'

export const queryJornadasAtendimentosControllerListar = (options) => queryFn(options)('/api/v1/jornadas-dos-atendimentos')
export const useQueryJornadasAtendimentosControllerListar = (config, options) => useQuery({
  queryKey: ['/api/v1/jornadas-dos-atendimentos'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryJornadasAtendimentosControllerListar.queryKey = '/api/v1/jornadas-dos-atendimentos'

export const mutationLocaisEstoqueControllerCadastrar = (options) => mutationFn('post', '/api/v1/locais-de-estoque', {}, {}, options)
export const useMutationLocaisEstoqueControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/locais-de-estoque', {}, {}, options), config)

export const queryLocaisEstoqueControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/locais-de-estoque', queryParams)
export const useQueryLocaisEstoqueControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocaisEstoqueControllerBuscar.queryKey = '/api/v1/locais-de-estoque'

export const mutationLocaisEstoqueControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/locais-de-estoque/{id}', pathParams, {}, options)
export const useMutationLocaisEstoqueControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/locais-de-estoque/{id}', pathParams, {}, options), config)

export const mutationLocaisEstoqueControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/locais-de-estoque/{id}', pathParams, {}, options)
export const useMutationLocaisEstoqueControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/locais-de-estoque/{id}', pathParams, {}, options), config)

export const queryLocaisEstoqueControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/locais-de-estoque/{id}', pathParams)
export const useQueryLocaisEstoqueControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisEstoqueControllerObterPorId.queryKey = '/api/v1/locais-de-estoque/{id}'

export const queryLocaisEstoqueControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/locais-de-estoque/{id}/detalhes', pathParams)
export const useQueryLocaisEstoqueControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque/{id}/detalhes', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisEstoqueControllerObterPorIdDetalhado.queryKey = '/api/v1/locais-de-estoque/{id}/detalhes'

export const mutationLocaisEstoqueControllerCadastrarLocalEstoqueConta = (pathParams, options) => mutationFn('post', '/api/v1/locais-de-estoque/{id}/contas', pathParams, {}, options)
export const useMutationLocaisEstoqueControllerCadastrarLocalEstoqueConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/locais-de-estoque/{id}/contas', pathParams, {}, options), config)

export const queryLocaisEstoqueControllerListarLocalEstoqueContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/locais-de-estoque/{id}/contas', pathParams, queryParams)
export const useQueryLocaisEstoqueControllerListarLocalEstoqueContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisEstoqueControllerListarLocalEstoqueContas.queryKey = '/api/v1/locais-de-estoque/{id}/contas'

export const queryLocaisEstoqueControllerObterLocalEstoqueContaPorId = (pathParams, options) => queryFn(options)('/api/v1/locais-de-estoque/{id}/contas/{contaId}', pathParams)
export const useQueryLocaisEstoqueControllerObterLocalEstoqueContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryLocaisEstoqueControllerObterLocalEstoqueContaPorId.queryKey = '/api/v1/locais-de-estoque/{id}/contas/{contaId}'

export const mutationLocaisEstoqueControllerAtualizarLocalEstoqueConta = (pathParams, options) => mutationFn('put', '/api/v1/locais-de-estoque/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationLocaisEstoqueControllerAtualizarLocalEstoqueConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/locais-de-estoque/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryLocaisEstoqueControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/locais-de-estoque/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryLocaisEstoqueControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisEstoqueControllerListarContasDisponiveis.queryKey = '/api/v1/locais-de-estoque/{id}/contas-disponiveis'

export const queryLocaisInternacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/locais-de-internacao', queryParams)
export const useQueryLocaisInternacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-internacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocaisInternacaoControllerBuscar.queryKey = '/api/v1/locais-de-internacao'

export const mutationLocaisInternacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/locais-de-internacao', {}, {}, options)
export const useMutationLocaisInternacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/locais-de-internacao', {}, {}, options), config)

export const queryLocaisInternacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/locais-de-internacao/{id}', pathParams)
export const useQueryLocaisInternacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-internacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisInternacaoControllerObterPorId.queryKey = '/api/v1/locais-de-internacao/{id}'

export const mutationLocaisInternacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/locais-de-internacao/{id}', pathParams, {}, options)
export const useMutationLocaisInternacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/locais-de-internacao/{id}', pathParams, {}, options), config)

export const mutationLocaisInternacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/locais-de-internacao/{id}', pathParams, {}, options)
export const useMutationLocaisInternacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/locais-de-internacao/{id}', pathParams, {}, options), config)

export const queryLocaisRealizacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/locais-realizacao', queryParams)
export const useQueryLocaisRealizacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-realizacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocaisRealizacaoControllerBuscar.queryKey = '/api/v1/locais-realizacao'

export const mutationLocaisRealizacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/locais-realizacao', {}, {}, options)
export const useMutationLocaisRealizacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/locais-realizacao', {}, {}, options), config)

export const queryLocaisRealizacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/locais-realizacao/{id}', pathParams)
export const useQueryLocaisRealizacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-realizacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisRealizacaoControllerObterPorId.queryKey = '/api/v1/locais-realizacao/{id}'

export const mutationLocaisRealizacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/locais-realizacao/{id}', pathParams, {}, options)
export const useMutationLocaisRealizacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/locais-realizacao/{id}', pathParams, {}, options), config)

export const mutationLocaisRealizacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/locais-realizacao/{id}', pathParams, {}, options)
export const useMutationLocaisRealizacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/locais-realizacao/{id}', pathParams, {}, options), config)

export const mutationLocaisRealizacaoControllerCadastrarLocalRealizacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/locais-realizacao/{id}/contas', pathParams, {}, options)
export const useMutationLocaisRealizacaoControllerCadastrarLocalRealizacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/locais-realizacao/{id}/contas', pathParams, {}, options), config)

export const queryLocaisRealizacaoControllerListarLocalRealizacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/locais-realizacao/{id}/contas', pathParams, queryParams)
export const useQueryLocaisRealizacaoControllerListarLocalRealizacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-realizacao/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisRealizacaoControllerListarLocalRealizacaoContas.queryKey = '/api/v1/locais-realizacao/{id}/contas'

export const queryLocaisRealizacaoControllerObterLocalRealizacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/locais-realizacao/{id}/contas/{contaId}', pathParams)
export const useQueryLocaisRealizacaoControllerObterLocalRealizacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-realizacao/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryLocaisRealizacaoControllerObterLocalRealizacaoContaPorId.queryKey = '/api/v1/locais-realizacao/{id}/contas/{contaId}'

export const mutationLocaisRealizacaoControllerAtualizarLocalRealizacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/locais-realizacao/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationLocaisRealizacaoControllerAtualizarLocalRealizacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/locais-realizacao/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryLocaisRealizacaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/locais-realizacao/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryLocaisRealizacaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-realizacao/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisRealizacaoControllerListarContasDisponiveis.queryKey = '/api/v1/locais-realizacao/{id}/contas-disponiveis'

export const queryLocalidadesControllerBuscarPaises = (queryParams, options) => queryFn(options)('/api/v1/localidades/paises', queryParams)
export const useQueryLocalidadesControllerBuscarPaises = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/paises', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocalidadesControllerBuscarPaises.queryKey = '/api/v1/localidades/paises'

export const queryLocalidadesControllerBuscarEstados = (queryParams, options) => queryFn(options)('/api/v1/localidades/estados', queryParams)
export const useQueryLocalidadesControllerBuscarEstados = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/estados', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocalidadesControllerBuscarEstados.queryKey = '/api/v1/localidades/estados'

export const queryLocalidadesControllerBuscarCidades = (queryParams, options) => queryFn(options)('/api/v1/localidades/cidades', queryParams)
export const useQueryLocalidadesControllerBuscarCidades = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/cidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocalidadesControllerBuscarCidades.queryKey = '/api/v1/localidades/cidades'

export const queryLocalidadesControllerBuscarEstadosPorPais = (pathParams, queryParams, options) => queryFn(options)('/api/v1/localidades/paises/{id}/estados', pathParams, queryParams)
export const useQueryLocalidadesControllerBuscarEstadosPorPais = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/paises/{id}/estados', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocalidadesControllerBuscarEstadosPorPais.queryKey = '/api/v1/localidades/paises/{id}/estados'

export const queryLocalidadesControllerBuscarCidadesPorEstado = (pathParams, queryParams, options) => queryFn(options)('/api/v1/localidades/estados/{id}/cidades', pathParams, queryParams)
export const useQueryLocalidadesControllerBuscarCidadesPorEstado = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/estados/{id}/cidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocalidadesControllerBuscarCidadesPorEstado.queryKey = '/api/v1/localidades/estados/{id}/cidades'

export const queryLotesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/lotes/{id}', pathParams)
export const useQueryLotesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/lotes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLotesControllerObterPorId.queryKey = '/api/v1/lotes/{id}'

export const mutationLotesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/lotes/{id}', pathParams, {}, options)
export const useMutationLotesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/lotes/{id}', pathParams, {}, options), config)

export const mutationLotesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/lotes/{id}', pathParams, {}, options)
export const useMutationLotesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/lotes/{id}', pathParams, {}, options), config)

export const queryMailControllerListar = (queryParams, options) => queryFn(options)('/api/v1/mail', queryParams)
export const useQueryMailControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/mail', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMailControllerListar.queryKey = '/api/v1/mail'

export const mutationMailControllerCadastrar = (options) => mutationFn('post', '/api/v1/mail', {}, {}, options)
export const useMutationMailControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/mail', {}, {}, options), config)

export const queryMailControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/mail/{id}', pathParams)
export const useQueryMailControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/mail/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMailControllerObterPorId.queryKey = '/api/v1/mail/{id}'

export const queryMailTemplatesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/mail-templates', queryParams)
export const useQueryMailTemplatesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/mail-templates', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMailTemplatesControllerListar.queryKey = '/api/v1/mail-templates'

export const mutationMailTemplatesControllerCadastrar = (options) => mutationFn('post', '/api/v1/mail-templates', {}, {}, options)
export const useMutationMailTemplatesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/mail-templates', {}, {}, options), config)

export const mutationMailTemplatesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/mail-templates/{id}', pathParams, {}, options)
export const useMutationMailTemplatesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/mail-templates/{id}', pathParams, {}, options), config)

export const mutationMailTemplatesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/mail-templates/{id}', pathParams, {}, options)
export const useMutationMailTemplatesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/mail-templates/{id}', pathParams, {}, options), config)

export const queryMailTemplatesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/mail-templates/{id}', pathParams)
export const useQueryMailTemplatesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/mail-templates/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMailTemplatesControllerObterPorId.queryKey = '/api/v1/mail-templates/{id}'

export const mutationMemedControllerCadastrarMedico = (options) => mutationFn('post', '/api/v1/memed/medico', {}, {}, options)
export const useMutationMemedControllerCadastrarMedico = (config, options) => useMutation(mutationFn('post', '/api/v1/memed/medico', {}, {}, options), config)

export const queryMemedControllerObterDadosMedicoPorPessoaId = (pathParams, options) => queryFn(options)('/api/v1/memed/medico/{id}', pathParams)
export const useQueryMemedControllerObterDadosMedicoPorPessoaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/memed/medico/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMemedControllerObterDadosMedicoPorPessoaId.queryKey = '/api/v1/memed/medico/{id}'

export const mutationMemedControllerAtualizarMedico = (pathParams, options) => mutationFn('patch', '/api/v1/memed/medico/{id}', pathParams, {}, options)
export const useMutationMemedControllerAtualizarMedico = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/memed/medico/{id}', pathParams, {}, options), config)

export const queryMemedControllerVerificarAceitacaoTermo = (pathParams, options) => queryFn(options)('/api/v1/memed/medico/{pessoaId}/aceite', pathParams)
export const useQueryMemedControllerVerificarAceitacaoTermo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/memed/medico/{pessoaId}/aceite', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.pessoaId, ...config }
})
useQueryMemedControllerVerificarAceitacaoTermo.queryKey = '/api/v1/memed/medico/{pessoaId}/aceite'

export const mutationMemedControllerAceitarTermo = (options) => mutationFn('patch', '/api/v1/memed/medico/aceite', {}, {}, options)
export const useMutationMemedControllerAceitarTermo = (config, options) => useMutation(mutationFn('patch', '/api/v1/memed/medico/aceite', {}, {}, options), config)

export const mutationMemedControllerCadastrarPrescricao = (options) => mutationFn('post', '/api/v1/memed/prescricoes', {}, {}, options)
export const useMutationMemedControllerCadastrarPrescricao = (config, options) => useMutation(mutationFn('post', '/api/v1/memed/prescricoes', {}, {}, options), config)

export const queryMemedControllerObterDadosPrescricaoPorPrescricaoId = (pathParams, options) => queryFn(options)('/api/v1/memed/prescricoes/{prescricaoId}', pathParams)
export const useQueryMemedControllerObterDadosPrescricaoPorPrescricaoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/memed/prescricoes/{prescricaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.prescricaoId, ...config }
})
useQueryMemedControllerObterDadosPrescricaoPorPrescricaoId.queryKey = '/api/v1/memed/prescricoes/{prescricaoId}'

export const queryMemedControllerObterDadosPrescricaoPorId = (pathParams, options) => queryFn(options)('/api/v1/memed/memed-prescricoes/{id}', pathParams)
export const useQueryMemedControllerObterDadosPrescricaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/memed/memed-prescricoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMemedControllerObterDadosPrescricaoPorId.queryKey = '/api/v1/memed/memed-prescricoes/{id}'

export const mutationMemedControllerAtualizarUltimaImpressao = (pathParams, options) => mutationFn('patch', '/api/v1/memed/memed-prescricoes/{id}/ultima-impressao', pathParams, {}, options)
export const useMutationMemedControllerAtualizarUltimaImpressao = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/memed/memed-prescricoes/{id}/ultima-impressao', pathParams, {}, options), config)

export const queryMemedControllerObterMemedPrescricaoMedicamentoPorId = (pathParams, options) => queryFn(options)('/api/v1/memed/memed-prescricoes/prescricao-medicamento/{id}', pathParams)
export const useQueryMemedControllerObterMemedPrescricaoMedicamentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/memed/memed-prescricoes/prescricao-medicamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMemedControllerObterMemedPrescricaoMedicamentoPorId.queryKey = '/api/v1/memed/memed-prescricoes/prescricao-medicamento/{id}'

export const mutationMetasConfiguracaoComissaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/metas-configuracao-de-comissao/{id}', pathParams, {}, options)
export const useMutationMetasConfiguracaoComissaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/metas-configuracao-de-comissao/{id}', pathParams, {}, options), config)

export const queryMetasConfiguracaoComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/metas-configuracao-de-comissao/{id}', pathParams)
export const useQueryMetasConfiguracaoComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/metas-configuracao-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMetasConfiguracaoComissaoControllerObterPorId.queryKey = '/api/v1/metas-configuracao-de-comissao/{id}'

export const mutationMetasConfiguracaoComissaoControllerDeletarMeta = (pathParams, options) => mutationFn('delete', '/api/v1/metas-configuracao-de-comissao/{id}', pathParams, {}, options)
export const useMutationMetasConfiguracaoComissaoControllerDeletarMeta = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/metas-configuracao-de-comissao/{id}', pathParams, {}, options), config)

export const queryModelosLaudoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/modelos-de-laudos', queryParams)
export const useQueryModelosLaudoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelos-de-laudos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryModelosLaudoControllerListar.queryKey = '/api/v1/modelos-de-laudos'

export const mutationModelosLaudoControllerCadastrar = (options) => mutationFn('post', '/api/v1/modelos-de-laudos', {}, {}, options)
export const useMutationModelosLaudoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/modelos-de-laudos', {}, {}, options), config)

export const queryModelosLaudoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/modelos-de-laudos/{id}', pathParams)
export const useQueryModelosLaudoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelos-de-laudos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosLaudoControllerObterPorId.queryKey = '/api/v1/modelos-de-laudos/{id}'

export const mutationModelosLaudoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/modelos-de-laudos/{id}', pathParams, {}, options)
export const useMutationModelosLaudoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modelos-de-laudos/{id}', pathParams, {}, options), config)

export const mutationModelosLaudoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/modelos-de-laudos/{id}', pathParams, {}, options)
export const useMutationModelosLaudoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelos-de-laudos/{id}', pathParams, {}, options), config)

export const mutationModelosLaudoControllerCadastrarDocumentoLaudo = (pathParams, options) => mutationFn('post', '/api/v1/modelos-de-laudos/{id}/documento', pathParams, {}, options)
export const useMutationModelosLaudoControllerCadastrarDocumentoLaudo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelos-de-laudos/{id}/documento', pathParams, {}, options), config)

export const queryModelosLaudoControllerListarDocumentoLaudo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelos-de-laudos/{id}/documento', pathParams, queryParams)
export const useQueryModelosLaudoControllerListarDocumentoLaudo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelos-de-laudos/{id}/documento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosLaudoControllerListarDocumentoLaudo.queryKey = '/api/v1/modelos-de-laudos/{id}/documento'

export const mutationModelosLaudoControllerCadastrarDocumentoConclusao = (pathParams, options) => mutationFn('post', '/api/v1/modelos-de-laudos/{id}/documento-conclusao', pathParams, {}, options)
export const useMutationModelosLaudoControllerCadastrarDocumentoConclusao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelos-de-laudos/{id}/documento-conclusao', pathParams, {}, options), config)

export const queryModelosLaudoControllerListarModeloLaudoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelos-de-laudos/{id}/contas', pathParams, queryParams)
export const useQueryModelosLaudoControllerListarModeloLaudoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelos-de-laudos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosLaudoControllerListarModeloLaudoContas.queryKey = '/api/v1/modelos-de-laudos/{id}/contas'

export const mutationModelosLaudoControllerCadastrarConta = (pathParams, options) => mutationFn('post', '/api/v1/modelos-de-laudos/{id}/contas', pathParams, {}, options)
export const useMutationModelosLaudoControllerCadastrarConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelos-de-laudos/{id}/contas', pathParams, {}, options), config)

export const queryModelosLaudoControllerObterContaPorId = (pathParams, options) => queryFn(options)('/api/v1/modelos-de-laudos/{id}/contas/{contaId}', pathParams)
export const useQueryModelosLaudoControllerObterContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelos-de-laudos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryModelosLaudoControllerObterContaPorId.queryKey = '/api/v1/modelos-de-laudos/{id}/contas/{contaId}'

export const mutationModelosLaudoControllerAtualizarConta = (pathParams, options) => mutationFn('put', '/api/v1/modelos-de-laudos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationModelosLaudoControllerAtualizarConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modelos-de-laudos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryModelosLaudoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelos-de-laudos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryModelosLaudoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelos-de-laudos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosLaudoControllerListarContasDisponiveis.queryKey = '/api/v1/modelos-de-laudos/{id}/contas-disponiveis'

export const queryModelosProntuarioControllerListar = (queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario', queryParams)
export const useQueryModelosProntuarioControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryModelosProntuarioControllerListar.queryKey = '/api/v1/modelo-de-prontuario'

export const mutationModelosProntuarioControllerCadastrar = (options) => mutationFn('post', '/api/v1/modelo-de-prontuario', {}, {}, options)
export const useMutationModelosProntuarioControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/modelo-de-prontuario', {}, {}, options), config)

export const queryModelosProntuarioControllerListarDisponiveisAtendimento = (queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/disponiveis-atendimento', queryParams)
export const useQueryModelosProntuarioControllerListarDisponiveisAtendimento = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/disponiveis-atendimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryModelosProntuarioControllerListarDisponiveisAtendimento.queryKey = '/api/v1/modelo-de-prontuario/disponiveis-atendimento'

export const queryModelosProntuarioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}', pathParams)
export const useQueryModelosProntuarioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosProntuarioControllerObterPorId.queryKey = '/api/v1/modelo-de-prontuario/{id}'

export const mutationModelosProntuarioControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/modelo-de-prontuario/{id}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modelo-de-prontuario/{id}', pathParams, {}, options), config)

export const mutationModelosProntuarioControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}', pathParams, {}, options), config)

export const mutationModelosProntuarioControllerAtualizarOrdemSecoes = (pathParams, options) => mutationFn('put', '/api/v1/modelo-de-prontuario/{id}/ordenar-secoes', pathParams, {}, options)
export const useMutationModelosProntuarioControllerAtualizarOrdemSecoes = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modelo-de-prontuario/{id}/ordenar-secoes', pathParams, {}, options), config)

export const mutationModelosProntuarioControllerCadastrarModeloProntuarioFuncionario = (pathParams, options) => mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/funcionarios', pathParams, {}, options)
export const useMutationModelosProntuarioControllerCadastrarModeloProntuarioFuncionario = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/funcionarios', pathParams, {}, options), config)

export const queryModelosProntuarioControllerListarModeloProntuarioFuncionarios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/funcionarios', pathParams, queryParams)
export const useQueryModelosProntuarioControllerListarModeloProntuarioFuncionarios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/funcionarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosProntuarioControllerListarModeloProntuarioFuncionarios.queryKey = '/api/v1/modelo-de-prontuario/{id}/funcionarios'

export const mutationModelosProntuarioControllerDeletarModeloProntuarioFuncionario = (pathParams, options) => mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/funcionarios/{funcionarioId}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerDeletarModeloProntuarioFuncionario = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/funcionarios/{funcionarioId}', pathParams, {}, options), config)

export const mutationModelosProntuarioControllerCadastrarModeloProntuarioEspecialidade = (pathParams, options) => mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/especialidades', pathParams, {}, options)
export const useMutationModelosProntuarioControllerCadastrarModeloProntuarioEspecialidade = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/especialidades', pathParams, {}, options), config)

export const queryModelosProntuarioControllerListarModeloProntuarioEspecialidades = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/especialidades', pathParams, queryParams)
export const useQueryModelosProntuarioControllerListarModeloProntuarioEspecialidades = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/especialidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosProntuarioControllerListarModeloProntuarioEspecialidades.queryKey = '/api/v1/modelo-de-prontuario/{id}/especialidades'

export const mutationModelosProntuarioControllerDeletarModeloProntuarioEspecialidade = (pathParams, options) => mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/especialidades/{especialidadeId}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerDeletarModeloProntuarioEspecialidade = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/especialidades/{especialidadeId}', pathParams, {}, options), config)

export const queryModelosProntuarioControllerListarContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/contas', pathParams, queryParams)
export const useQueryModelosProntuarioControllerListarContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosProntuarioControllerListarContas.queryKey = '/api/v1/modelo-de-prontuario/{id}/contas'

export const mutationModelosProntuarioControllerCadastrarConta = (pathParams, options) => mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/contas', pathParams, {}, options)
export const useMutationModelosProntuarioControllerCadastrarConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/contas', pathParams, {}, options), config)

export const queryModelosProntuarioControllerObterContaPorId = (pathParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/contas/{contaId}', pathParams)
export const useQueryModelosProntuarioControllerObterContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryModelosProntuarioControllerObterContaPorId.queryKey = '/api/v1/modelo-de-prontuario/{id}/contas/{contaId}'

export const mutationModelosProntuarioControllerAtualizarConta = (pathParams, options) => mutationFn('put', '/api/v1/modelo-de-prontuario/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerAtualizarConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modelo-de-prontuario/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryModelosProntuarioControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryModelosProntuarioControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosProntuarioControllerListarContasDisponiveis.queryKey = '/api/v1/modelo-de-prontuario/{id}/contas-disponiveis'

export const queryModelosProntuarioControllerObterSecaoPorId = (pathParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}', pathParams)
export const useQueryModelosProntuarioControllerObterSecaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.secaoId, ...config }
})
useQueryModelosProntuarioControllerObterSecaoPorId.queryKey = '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}'

export const mutationModelosProntuarioControllerAtualizarSecao = (pathParams, options) => mutationFn('put', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerAtualizarSecao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}', pathParams, {}, options), config)

export const mutationModelosProntuarioControllerDeletarSecao = (pathParams, options) => mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerDeletarSecao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}', pathParams, {}, options), config)

export const mutationModelosProntuarioControllerCadastrarSecao = (pathParams, options) => mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/secoes', pathParams, {}, options)
export const useMutationModelosProntuarioControllerCadastrarSecao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/secoes', pathParams, {}, options), config)

export const mutationModelosProntuarioControllerAtualizarOrdemCampos = (pathParams, options) => mutationFn('put', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/ordenar-campos', pathParams, {}, options)
export const useMutationModelosProntuarioControllerAtualizarOrdemCampos = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/ordenar-campos', pathParams, {}, options), config)

export const queryModelosProntuarioControllerListarSecoesDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{moduloProntuarioId}/secoes-disponiveis', pathParams, queryParams)
export const useQueryModelosProntuarioControllerListarSecoesDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{moduloProntuarioId}/secoes-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.moduloProntuarioId, ...config }
})
useQueryModelosProntuarioControllerListarSecoesDisponiveis.queryKey = '/api/v1/modelo-de-prontuario/{moduloProntuarioId}/secoes-disponiveis'

export const mutationModelosProntuarioControllerCadastrarSecaoExistente = (pathParams, options) => mutationFn('post', '/api/v1/modelo-de-prontuario/{modeloProntuarioId}/secoes/{secaoModeloProntuarioId}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerCadastrarSecaoExistente = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelo-de-prontuario/{modeloProntuarioId}/secoes/{secaoModeloProntuarioId}', pathParams, {}, options), config)

export const mutationModelosProntuarioControllerCadastrarSecaoFuncionario = (pathParams, options) => mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/funcionarios', pathParams, {}, options)
export const useMutationModelosProntuarioControllerCadastrarSecaoFuncionario = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/funcionarios', pathParams, {}, options), config)

export const queryModelosProntuarioControllerListarSecaoFuncionario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/funcionarios', pathParams, queryParams)
export const useQueryModelosProntuarioControllerListarSecaoFuncionario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/funcionarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.secaoId, ...config }
})
useQueryModelosProntuarioControllerListarSecaoFuncionario.queryKey = '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/funcionarios'

export const mutationModelosProntuarioControllerDeletarSecaoFuncionario = (pathParams, options) => mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/funcionarios/{funcionarioId}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerDeletarSecaoFuncionario = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/funcionarios/{funcionarioId}', pathParams, {}, options), config)

export const queryModelosProntuarioControllerObterCampoPorId = (pathParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}', pathParams)
export const useQueryModelosProntuarioControllerObterCampoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.secaoId && pathParams.campoId, ...config }
})
useQueryModelosProntuarioControllerObterCampoPorId.queryKey = '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}'

export const mutationModelosProntuarioControllerAtualizarCampo = (pathParams, options) => mutationFn('put', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerAtualizarCampo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}', pathParams, {}, options), config)

export const mutationModelosProntuarioControllerDeletarCampo = (pathParams, options) => mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerDeletarCampo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}', pathParams, {}, options), config)

export const mutationModelosProntuarioControllerCadastrarCampo = (pathParams, options) => mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos', pathParams, {}, options)
export const useMutationModelosProntuarioControllerCadastrarCampo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos', pathParams, {}, options), config)

export const queryModelosProntuarioControllerListarCamposDisponiveisCalculo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos-disponiveis-calculo', pathParams, queryParams)
export const useQueryModelosProntuarioControllerListarCamposDisponiveisCalculo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos-disponiveis-calculo', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.secaoId, ...config }
})
useQueryModelosProntuarioControllerListarCamposDisponiveisCalculo.queryKey = '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos-disponiveis-calculo'

export const queryModelosProntuarioControllerObterOpcaoCampoPorId = (pathParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/opcoes/{opcaoId}', pathParams)
export const useQueryModelosProntuarioControllerObterOpcaoCampoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/opcoes/{opcaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.secaoId && pathParams.campoId && pathParams.opcaoId, ...config }
})
useQueryModelosProntuarioControllerObterOpcaoCampoPorId.queryKey = '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/opcoes/{opcaoId}'

export const mutationModelosProntuarioControllerDeletarOpcaoCampo = (pathParams, options) => mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/opcoes/{opcaoId}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerDeletarOpcaoCampo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/opcoes/{opcaoId}', pathParams, {}, options), config)

export const queryModelosProntuarioControllerObterCampos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{CampoModeloProntuarioId}/opcoes', pathParams, queryParams)
export const useQueryModelosProntuarioControllerObterCampos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{CampoModeloProntuarioId}/opcoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.secaoId && pathParams.CampoModeloProntuarioId, ...config }
})
useQueryModelosProntuarioControllerObterCampos.queryKey = '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{CampoModeloProntuarioId}/opcoes'

export const mutationModelosProntuarioControllerCadastrarOpcaoCampo = (pathParams, options) => mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/opcoes', pathParams, {}, options)
export const useMutationModelosProntuarioControllerCadastrarOpcaoCampo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/opcoes', pathParams, {}, options), config)

export const queryModelosProntuarioControllerListarOpcoesPreenchimentoAutomatico = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/preenchimento-automatico', pathParams, queryParams)
export const useQueryModelosProntuarioControllerListarOpcoesPreenchimentoAutomatico = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/preenchimento-automatico', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.secaoId && pathParams.campoId, ...config }
})
useQueryModelosProntuarioControllerListarOpcoesPreenchimentoAutomatico.queryKey = '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/preenchimento-automatico'

export const queryModelosProntuarioControllerListarTextosPreenchimentoAutomatico = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/textos-preenchimento-automatico', pathParams, queryParams)
export const useQueryModelosProntuarioControllerListarTextosPreenchimentoAutomatico = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/textos-preenchimento-automatico', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.secaoId && pathParams.campoId, ...config }
})
useQueryModelosProntuarioControllerListarTextosPreenchimentoAutomatico.queryKey = '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/textos-preenchimento-automatico'

export const mutationModelosProntuarioControllerCadastrarTextoPreenchimentoAutomatico = (pathParams, options) => mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/textos-preenchimento-automatico', pathParams, {}, options)
export const useMutationModelosProntuarioControllerCadastrarTextoPreenchimentoAutomatico = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelo-de-prontuario/{id}/secoes/{secaoId}/campos/{campoId}/textos-preenchimento-automatico', pathParams, {}, options), config)

export const mutationModelosProntuarioControllerDeletarTextoPreenchimentoAutomatico = (pathParams, options) => mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/Secoes/{secaoId}/Campos/{campoId}/textos-preenchimento-automatico/{textoId}', pathParams, {}, options)
export const useMutationModelosProntuarioControllerDeletarTextoPreenchimentoAutomatico = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelo-de-prontuario/{id}/Secoes/{secaoId}/Campos/{campoId}/textos-preenchimento-automatico/{textoId}', pathParams, {}, options), config)

export const queryModelosReceituariosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/modelos-receituarios', queryParams)
export const useQueryModelosReceituariosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelos-receituarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryModelosReceituariosControllerListar.queryKey = '/api/v1/modelos-receituarios'

export const mutationModelosReceituariosControllerCadastrar = (options) => mutationFn('post', '/api/v1/modelos-receituarios', {}, {}, options)
export const useMutationModelosReceituariosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/modelos-receituarios', {}, {}, options), config)

export const queryModelosReceituariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/modelos-receituarios/{id}', pathParams)
export const useQueryModelosReceituariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelos-receituarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosReceituariosControllerObterPorId.queryKey = '/api/v1/modelos-receituarios/{id}'

export const mutationModelosReceituariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/modelos-receituarios/{id}', pathParams, {}, options)
export const useMutationModelosReceituariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modelos-receituarios/{id}', pathParams, {}, options), config)

export const mutationModelosReceituariosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/modelos-receituarios/{id}', pathParams, {}, options)
export const useMutationModelosReceituariosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelos-receituarios/{id}', pathParams, {}, options), config)

export const mutationModelosReceituariosControllerCadastrarArquivoCabecalho = (pathParams, options) => mutationFn('post', '/api/v1/modelos-receituarios/{id}/logo-cabecalho', pathParams, {}, options)
export const useMutationModelosReceituariosControllerCadastrarArquivoCabecalho = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelos-receituarios/{id}/logo-cabecalho', pathParams, {}, options), config)

export const mutationModelosReceituariosControllerDeletarArquivoCabecalho = (pathParams, options) => mutationFn('delete', '/api/v1/modelos-receituarios/{id}/logo-cabecalho/{arquivoId}', pathParams, {}, options)
export const useMutationModelosReceituariosControllerDeletarArquivoCabecalho = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelos-receituarios/{id}/logo-cabecalho/{arquivoId}', pathParams, {}, options), config)

export const mutationModelosReceituariosControllerCadastrarArquivoRodaPe = (pathParams, options) => mutationFn('post', '/api/v1/modelos-receituarios/{id}/logo-rodape', pathParams, {}, options)
export const useMutationModelosReceituariosControllerCadastrarArquivoRodaPe = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelos-receituarios/{id}/logo-rodape', pathParams, {}, options), config)

export const mutationModelosReceituariosControllerDeletarArquivoRodaPe = (pathParams, options) => mutationFn('delete', '/api/v1/modelos-receituarios/{id}/logo-rodape/{arquivoId}', pathParams, {}, options)
export const useMutationModelosReceituariosControllerDeletarArquivoRodaPe = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelos-receituarios/{id}/logo-rodape/{arquivoId}', pathParams, {}, options), config)

export const queryModelosReceituariosControllerListarContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelos-receituarios/{id}/contas', pathParams, queryParams)
export const useQueryModelosReceituariosControllerListarContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelos-receituarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosReceituariosControllerListarContas.queryKey = '/api/v1/modelos-receituarios/{id}/contas'

export const mutationModelosReceituariosControllerCadastrarConta = (pathParams, options) => mutationFn('post', '/api/v1/modelos-receituarios/{id}/contas', pathParams, {}, options)
export const useMutationModelosReceituariosControllerCadastrarConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelos-receituarios/{id}/contas', pathParams, {}, options), config)

export const queryModelosReceituariosControllerObterContaPorId = (pathParams, options) => queryFn(options)('/api/v1/modelos-receituarios/{id}/contas/{contaId}', pathParams)
export const useQueryModelosReceituariosControllerObterContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelos-receituarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryModelosReceituariosControllerObterContaPorId.queryKey = '/api/v1/modelos-receituarios/{id}/contas/{contaId}'

export const mutationModelosReceituariosControllerAtualizarConta = (pathParams, options) => mutationFn('put', '/api/v1/modelos-receituarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationModelosReceituariosControllerAtualizarConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modelos-receituarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryModelosReceituariosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modelos-receituarios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryModelosReceituariosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelos-receituarios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModelosReceituariosControllerListarContasDisponiveis.queryKey = '/api/v1/modelos-receituarios/{id}/contas-disponiveis'

export const queryMotivosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/motivos', queryParams)
export const useQueryMotivosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosControllerBuscar.queryKey = '/api/v1/motivos'

export const mutationMotivosControllerCadastrar = (options) => mutationFn('post', '/api/v1/motivos', {}, {}, options)
export const useMutationMotivosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/motivos', {}, {}, options), config)

export const queryMotivosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos/{id}', pathParams)
export const useQueryMotivosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosControllerObterPorId.queryKey = '/api/v1/motivos/{id}'

export const mutationMotivosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/motivos/{id}', pathParams, {}, options)
export const useMutationMotivosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/motivos/{id}', pathParams, {}, options), config)

export const mutationMotivosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/motivos/{id}', pathParams, {}, options)
export const useMutationMotivosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/motivos/{id}', pathParams, {}, options), config)

export const mutationMotivosControllerCadastrarMotivoConta = (pathParams, options) => mutationFn('post', '/api/v1/motivos/{id}/contas', pathParams, {}, options)
export const useMutationMotivosControllerCadastrarMotivoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/motivos/{id}/contas', pathParams, {}, options), config)

export const queryMotivosControllerListarMotivoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/motivos/{id}/contas', pathParams, queryParams)
export const useQueryMotivosControllerListarMotivoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosControllerListarMotivoContas.queryKey = '/api/v1/motivos/{id}/contas'

export const queryMotivosControllerObterMotivoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos/{id}/contas/{contaId}', pathParams)
export const useQueryMotivosControllerObterMotivoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryMotivosControllerObterMotivoContaPorId.queryKey = '/api/v1/motivos/{id}/contas/{contaId}'

export const mutationMotivosControllerAtualizarMotivoConta = (pathParams, options) => mutationFn('put', '/api/v1/motivos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationMotivosControllerAtualizarMotivoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/motivos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryMotivosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/motivos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryMotivosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosControllerListarContasDisponiveis.queryKey = '/api/v1/motivos/{id}/contas-disponiveis'

export const queryMotivosCancelamentoControllerListarModulos = (queryParams, options) => queryFn(options)('/api/v1/motivos-cancelamento/modulos', queryParams)
export const useQueryMotivosCancelamentoControllerListarModulos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-cancelamento/modulos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosCancelamentoControllerListarModulos.queryKey = '/api/v1/motivos-cancelamento/modulos'

export const queryMotivosCancelamentoControllerObterModuloPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos-cancelamento/modulos/{id}', pathParams)
export const useQueryMotivosCancelamentoControllerObterModuloPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-cancelamento/modulos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosCancelamentoControllerObterModuloPorId.queryKey = '/api/v1/motivos-cancelamento/modulos/{id}'

export const queryMotivosCancelamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/motivos-cancelamento', queryParams)
export const useQueryMotivosCancelamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-cancelamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosCancelamentoControllerListar.queryKey = '/api/v1/motivos-cancelamento'

export const mutationMotivosCancelamentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/motivos-cancelamento', {}, {}, options)
export const useMutationMotivosCancelamentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/motivos-cancelamento', {}, {}, options), config)

export const queryMotivosCancelamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos-cancelamento/{id}', pathParams)
export const useQueryMotivosCancelamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-cancelamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosCancelamentoControllerObterPorId.queryKey = '/api/v1/motivos-cancelamento/{id}'

export const mutationMotivosCancelamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/motivos-cancelamento/{id}', pathParams, {}, options)
export const useMutationMotivosCancelamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/motivos-cancelamento/{id}', pathParams, {}, options), config)

export const mutationMotivosCancelamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/motivos-cancelamento/{id}', pathParams, {}, options)
export const useMutationMotivosCancelamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/motivos-cancelamento/{id}', pathParams, {}, options), config)

export const queryMotivosCancelamentoControllerObterPorModulo = (queryParams, options) => queryFn(options)('/api/v1/motivos-cancelamento/listar-por-modulo', queryParams)
export const useQueryMotivosCancelamentoControllerObterPorModulo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-cancelamento/listar-por-modulo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosCancelamentoControllerObterPorModulo.queryKey = '/api/v1/motivos-cancelamento/listar-por-modulo'

export const queryMotivosDesfechosAtendimentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/motivos-de-desfecho-de-atendimento', queryParams)
export const useQueryMotivosDesfechosAtendimentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-de-desfecho-de-atendimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosDesfechosAtendimentoControllerListar.queryKey = '/api/v1/motivos-de-desfecho-de-atendimento'

export const mutationMotivosDesfechosAtendimentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/motivos-de-desfecho-de-atendimento', {}, {}, options)
export const useMutationMotivosDesfechosAtendimentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/motivos-de-desfecho-de-atendimento', {}, {}, options), config)

export const queryMotivosDesfechosAtendimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos-de-desfecho-de-atendimento/{id}', pathParams)
export const useQueryMotivosDesfechosAtendimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-de-desfecho-de-atendimento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosDesfechosAtendimentoControllerObterPorId.queryKey = '/api/v1/motivos-de-desfecho-de-atendimento/{id}'

export const mutationMotivosDesfechosAtendimentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/motivos-de-desfecho-de-atendimento/{id}', pathParams, {}, options)
export const useMutationMotivosDesfechosAtendimentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/motivos-de-desfecho-de-atendimento/{id}', pathParams, {}, options), config)

export const mutationMotivosDesfechosAtendimentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/motivos-de-desfecho-de-atendimento/{id}', pathParams, {}, options)
export const useMutationMotivosDesfechosAtendimentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/motivos-de-desfecho-de-atendimento/{id}', pathParams, {}, options), config)

export const queryMotivosNotificacaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/motivos-notificacao', queryParams)
export const useQueryMotivosNotificacaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-notificacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosNotificacaoControllerListar.queryKey = '/api/v1/motivos-notificacao'

export const queryMotivosNotificacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos-notificacao/{id}', pathParams)
export const useQueryMotivosNotificacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-notificacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosNotificacaoControllerObterPorId.queryKey = '/api/v1/motivos-notificacao/{id}'

export const queryMotivosNotificacaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/motivos-notificacao/{chave}', pathParams)
export const useQueryMotivosNotificacaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-notificacao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryMotivosNotificacaoControllerObterPorChave.queryKey = '/api/v1/motivos-notificacao/{chave}'

export const queryMotivosRevogacaoTermoAceiteLgpdControlerControllerListar = (queryParams, options) => queryFn(options)('/api/v1/motivos-revogacao-termo-lgpd', queryParams)
export const useQueryMotivosRevogacaoTermoAceiteLgpdControlerControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-revogacao-termo-lgpd', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosRevogacaoTermoAceiteLgpdControlerControllerListar.queryKey = '/api/v1/motivos-revogacao-termo-lgpd'

export const queryMovimentosCaixaControllerObterMovimentoPorId = (pathParams, options) => queryFn(options)('/api/v1/movimentos-de-caixa/{id}', pathParams)
export const useQueryMovimentosCaixaControllerObterMovimentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/movimentos-de-caixa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMovimentosCaixaControllerObterMovimentoPorId.queryKey = '/api/v1/movimentos-de-caixa/{id}'

export const queryMovimentosCaixaControllerObterMovimentoTituloPorId = (pathParams, options) => queryFn(options)('/api/v1/movimentos-de-caixa/{id}/movimento-titulo', pathParams)
export const useQueryMovimentosCaixaControllerObterMovimentoTituloPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/movimentos-de-caixa/{id}/movimento-titulo', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMovimentosCaixaControllerObterMovimentoTituloPorId.queryKey = '/api/v1/movimentos-de-caixa/{id}/movimento-titulo'

export const queryMovimentosCaixaControllerObterBaixaPorMovimento = (pathParams, options) => queryFn(options)('/api/v1/movimentos-de-caixa/{id}/baixa', pathParams)
export const useQueryMovimentosCaixaControllerObterBaixaPorMovimento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/movimentos-de-caixa/{id}/baixa', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMovimentosCaixaControllerObterBaixaPorMovimento.queryKey = '/api/v1/movimentos-de-caixa/{id}/baixa'

export const queryNacionalidadesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/nacionalidades', queryParams)
export const useQueryNacionalidadesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/nacionalidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNacionalidadesControllerBuscar.queryKey = '/api/v1/nacionalidades'

export const mutationNacionalidadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/nacionalidades', {}, {}, options)
export const useMutationNacionalidadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/nacionalidades', {}, {}, options), config)

export const queryNacionalidadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/nacionalidades/{id}', pathParams)
export const useQueryNacionalidadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/nacionalidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNacionalidadesControllerObterPorId.queryKey = '/api/v1/nacionalidades/{id}'

export const mutationNacionalidadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/nacionalidades/{id}', pathParams, {}, options)
export const useMutationNacionalidadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/nacionalidades/{id}', pathParams, {}, options), config)

export const mutationNacionalidadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/nacionalidades/{id}', pathParams, {}, options)
export const useMutationNacionalidadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/nacionalidades/{id}', pathParams, {}, options), config)

export const mutationNacionalidadesControllerCadastrarNacionalidadeConta = (pathParams, options) => mutationFn('post', '/api/v1/nacionalidades/{id}/contas', pathParams, {}, options)
export const useMutationNacionalidadesControllerCadastrarNacionalidadeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/nacionalidades/{id}/contas', pathParams, {}, options), config)

export const queryNacionalidadesControllerListarNacionalidadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/nacionalidades/{id}/contas', pathParams, queryParams)
export const useQueryNacionalidadesControllerListarNacionalidadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/nacionalidades/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNacionalidadesControllerListarNacionalidadeContas.queryKey = '/api/v1/nacionalidades/{id}/contas'

export const queryNacionalidadesControllerObterNacionalidadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/nacionalidades/{id}/contas/{contaId}', pathParams)
export const useQueryNacionalidadesControllerObterNacionalidadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/nacionalidades/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryNacionalidadesControllerObterNacionalidadeContaPorId.queryKey = '/api/v1/nacionalidades/{id}/contas/{contaId}'

export const mutationNacionalidadesControllerAtualizarNacionalidadeConta = (pathParams, options) => mutationFn('put', '/api/v1/nacionalidades/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationNacionalidadesControllerAtualizarNacionalidadeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/nacionalidades/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryNacionalidadesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/nacionalidades/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryNacionalidadesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/nacionalidades/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNacionalidadesControllerListarContasDisponiveis.queryKey = '/api/v1/nacionalidades/{id}/contas-disponiveis'

export const queryNaturezasCondicaoPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/naturezas-condicao-de-pagamento', queryParams)
export const useQueryNaturezasCondicaoPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/naturezas-condicao-de-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNaturezasCondicaoPagamentoControllerListar.queryKey = '/api/v1/naturezas-condicao-de-pagamento'

export const queryNaturezasCondicaoPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/naturezas-condicao-de-pagamento/{id}', pathParams)
export const useQueryNaturezasCondicaoPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/naturezas-condicao-de-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNaturezasCondicaoPagamentoControllerObterPorId.queryKey = '/api/v1/naturezas-condicao-de-pagamento/{id}'

export const queryNaturezasCondicaoPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/naturezas-condicao-de-pagamento/{chave}', pathParams)
export const useQueryNaturezasCondicaoPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/naturezas-condicao-de-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryNaturezasCondicaoPagamentoControllerObterPorChave.queryKey = '/api/v1/naturezas-condicao-de-pagamento/{chave}'

export const queryNaturezasPlanoContaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/naturezas-plano-conta', queryParams)
export const useQueryNaturezasPlanoContaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/naturezas-plano-conta', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNaturezasPlanoContaControllerListar.queryKey = '/api/v1/naturezas-plano-conta'

export const mutationNCMsControllerCadastrar = (options) => mutationFn('post', '/api/v1/ncms', {}, {}, options)
export const useMutationNCMsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/ncms', {}, {}, options), config)

export const queryNCMsControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/ncms', queryParams)
export const useQueryNCMsControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ncms', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNCMsControllerBuscar.queryKey = '/api/v1/ncms'

export const mutationNCMsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/ncms/{id}', pathParams, {}, options)
export const useMutationNCMsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/ncms/{id}', pathParams, {}, options), config)

export const mutationNCMsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/ncms/{id}', pathParams, {}, options)
export const useMutationNCMsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/ncms/{id}', pathParams, {}, options), config)

export const queryNCMsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/ncms/{id}', pathParams)
export const useQueryNCMsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ncms/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNCMsControllerObterPorId.queryKey = '/api/v1/ncms/{id}'

export const queryNCMsControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/ncms/{id}/detalhes', pathParams)
export const useQueryNCMsControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ncms/{id}/detalhes', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNCMsControllerObterPorIdDetalhado.queryKey = '/api/v1/ncms/{id}/detalhes'

export const queryNecessidadesEspeciaisControllerListarNecessidadesEspeciais = (queryParams, options) => queryFn(options)('/api/v1/necessidades-especiais', queryParams)
export const useQueryNecessidadesEspeciaisControllerListarNecessidadesEspeciais = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNecessidadesEspeciaisControllerListarNecessidadesEspeciais.queryKey = '/api/v1/necessidades-especiais'

export const mutationNecessidadesEspeciaisControllerCadastrar = (options) => mutationFn('post', '/api/v1/necessidades-especiais', {}, {}, options)
export const useMutationNecessidadesEspeciaisControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/necessidades-especiais', {}, {}, options), config)

export const queryNecessidadesEspeciaisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}', pathParams)
export const useQueryNecessidadesEspeciaisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerObterPorId.queryKey = '/api/v1/necessidades-especiais/{id}'

export const mutationNecessidadesEspeciaisControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options), config)

export const mutationNecessidadesEspeciaisControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options), config)

export const mutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialTipo = (pathParams, options) => mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-tipos', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialTipo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-tipos', pathParams, {}, options), config)

export const queryNecessidadesEspeciaisControllerObterTiposNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/necessidade-especial-tipos', pathParams)
export const useQueryNecessidadesEspeciaisControllerObterTiposNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/necessidade-especial-tipos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerObterTiposNecessidadesEspeciaisPorNecessidadeEspecialId.queryKey = '/api/v1/necessidades-especiais/{id}/necessidade-especial-tipos'

export const mutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialTipo = (pathParams, options) => mutationFn('delete', '/api/v1/necessidades-especiais/{id}/tipo-necessidade-especial/{tipoNecessidadeEspecialId}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialTipo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/necessidades-especiais/{id}/tipo-necessidade-especial/{tipoNecessidadeEspecialId}', pathParams, {}, options), config)

export const mutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialrecurso = (pathParams, options) => mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-recursos', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialrecurso = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-recursos', pathParams, {}, options), config)

export const queryNecessidadesEspeciaisControllerObterRecursosNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/necessidade-especial-recursos', pathParams, queryParams)
export const useQueryNecessidadesEspeciaisControllerObterRecursosNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/necessidade-especial-recursos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerObterRecursosNecessidadesEspeciaisPorNecessidadeEspecialId.queryKey = '/api/v1/necessidades-especiais/{id}/necessidade-especial-recursos'

export const mutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialRecurso = (pathParams, options) => mutationFn('delete', '/api/v1/necessidades-especiais/{id}/recurso-necessidade-especial/{recursoNecessidadeEspecialId}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialRecurso = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/necessidades-especiais/{id}/recurso-necessidade-especial/{recursoNecessidadeEspecialId}', pathParams, {}, options), config)

export const mutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialConta = (pathParams, options) => mutationFn('post', '/api/v1/necessidades-especiais/{id}/contas', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/necessidades-especiais/{id}/contas', pathParams, {}, options), config)

export const queryNecessidadesEspeciaisControllerListarNecessidadeEspecialContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/contas', pathParams, queryParams)
export const useQueryNecessidadesEspeciaisControllerListarNecessidadeEspecialContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerListarNecessidadeEspecialContas.queryKey = '/api/v1/necessidades-especiais/{id}/contas'

export const queryNecessidadesEspeciaisControllerObterNecessidadeEspecialContaPorId = (pathParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/contas/{contaId}', pathParams)
export const useQueryNecessidadesEspeciaisControllerObterNecessidadeEspecialContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryNecessidadesEspeciaisControllerObterNecessidadeEspecialContaPorId.queryKey = '/api/v1/necessidades-especiais/{id}/contas/{contaId}'

export const mutationNecessidadesEspeciaisControllerAtualizarNecessidadeEspecialConta = (pathParams, options) => mutationFn('put', '/api/v1/necessidades-especiais/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerAtualizarNecessidadeEspecialConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/necessidades-especiais/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryNecessidadesEspeciaisControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryNecessidadesEspeciaisControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerListarContasDisponiveis.queryKey = '/api/v1/necessidades-especiais/{id}/contas-disponiveis'

export const queryNumeroMaxParcelaLinkPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/numero-max-parcela-link-pagamento', queryParams)
export const useQueryNumeroMaxParcelaLinkPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/numero-max-parcela-link-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNumeroMaxParcelaLinkPagamentoControllerListar.queryKey = '/api/v1/numero-max-parcela-link-pagamento'

export const queryNumeroMaxParcelaLinkPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/numero-max-parcela-link-pagamento/{id}', pathParams)
export const useQueryNumeroMaxParcelaLinkPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/numero-max-parcela-link-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNumeroMaxParcelaLinkPagamentoControllerObterPorId.queryKey = '/api/v1/numero-max-parcela-link-pagamento/{id}'

export const queryNumeroMaxParcelaLinkPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/numero-max-parcela-link-pagamento/{chave}', pathParams)
export const useQueryNumeroMaxParcelaLinkPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/numero-max-parcela-link-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryNumeroMaxParcelaLinkPagamentoControllerObterPorChave.queryKey = '/api/v1/numero-max-parcela-link-pagamento/{chave}'

export const queryOpcaoPagamentoLinkPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/opcao-pagamento-link-pagamento', queryParams)
export const useQueryOpcaoPagamentoLinkPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/opcao-pagamento-link-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOpcaoPagamentoLinkPagamentoControllerListar.queryKey = '/api/v1/opcao-pagamento-link-pagamento'

export const queryOpcaoPagamentoLinkPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/opcao-pagamento-link-pagamento/{id}', pathParams)
export const useQueryOpcaoPagamentoLinkPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/opcao-pagamento-link-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpcaoPagamentoLinkPagamentoControllerObterPorId.queryKey = '/api/v1/opcao-pagamento-link-pagamento/{id}'

export const queryOpcaoPagamentoLinkPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/opcao-pagamento-link-pagamento/{chave}', pathParams)
export const useQueryOpcaoPagamentoLinkPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/opcao-pagamento-link-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryOpcaoPagamentoLinkPagamentoControllerObterPorChave.queryKey = '/api/v1/opcao-pagamento-link-pagamento/{chave}'

export const mutationOpenBankingControllerCriarLinkConsentimentoOpenBanking = (options) => mutationFn('post', '/api/v1/open-banking/criar-link-consentimento', {}, {}, options)
export const useMutationOpenBankingControllerCriarLinkConsentimentoOpenBanking = (config, options) => useMutation(mutationFn('post', '/api/v1/open-banking/criar-link-consentimento', {}, {}, options), config)

export const queryOpenBankingControllerObterExtratosBancariosOpenBanking = (pathParams, queryParams, options) => queryFn(options)('/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios', pathParams, queryParams)
export const useQueryOpenBankingControllerObterExtratosBancariosOpenBanking = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpenBankingControllerObterExtratosBancariosOpenBanking.queryKey = '/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios'

export const queryOpenBankingControllerObterExtratoPorId = (pathParams, options) => queryFn(options)('/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios/{extratoId}', pathParams)
export const useQueryOpenBankingControllerObterExtratoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios/{extratoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.extratoId, ...config }
})
useQueryOpenBankingControllerObterExtratoPorId.queryKey = '/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios/{extratoId}'

export const queryOpenBankingControllerObterSaldosBancariosOpenBanking = (queryParams, options) => queryFn(options)('/api/v1/open-banking/saldo-bancarios', queryParams)
export const useQueryOpenBankingControllerObterSaldosBancariosOpenBanking = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/saldo-bancarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOpenBankingControllerObterSaldosBancariosOpenBanking.queryKey = '/api/v1/open-banking/saldo-bancarios'

export const queryOpenBankingControllerObterMovimentacoesBancariasOpenBanking = (queryParams, options) => queryFn(options)('/api/v1/open-banking/movimentacao-bancaria', queryParams)
export const useQueryOpenBankingControllerObterMovimentacoesBancariasOpenBanking = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/movimentacao-bancaria', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOpenBankingControllerObterMovimentacoesBancariasOpenBanking.queryKey = '/api/v1/open-banking/movimentacao-bancaria'

export const queryOpenBankingControllerEmitirComprovantesBancariosOpenBanking = (pathParams, options) => queryFn(options)('/api/v1/open-banking/comprovante-bancario/{id}', pathParams)
export const useQueryOpenBankingControllerEmitirComprovantesBancariosOpenBanking = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/comprovante-bancario/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.Id, ...config }
})
useQueryOpenBankingControllerEmitirComprovantesBancariosOpenBanking.queryKey = '/api/v1/open-banking/comprovante-bancario/{id}'

export const mutationOpenBankingControllerCadastrarContatoOpenBanking = (options) => mutationFn('post', '/api/v1/open-banking/contato', {}, {}, options)
export const useMutationOpenBankingControllerCadastrarContatoOpenBanking = (config, options) => useMutation(mutationFn('post', '/api/v1/open-banking/contato', {}, {}, options), config)

export const queryOpenBankingControllerObterContatosBancariosOpenBanking = (pathParams, queryParams, options) => queryFn(options)('/api/v1/open-banking/contas-bancarias/{id}/contatos', pathParams, queryParams)
export const useQueryOpenBankingControllerObterContatosBancariosOpenBanking = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contas-bancarias/{id}/contatos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpenBankingControllerObterContatosBancariosOpenBanking.queryKey = '/api/v1/open-banking/contas-bancarias/{id}/contatos'

export const queryOpenBankingControllerObterContatosBancariosOpenBankingPorId = (pathParams, options) => queryFn(options)('/api/v1/open-banking/contatos/{id}', pathParams)
export const useQueryOpenBankingControllerObterContatosBancariosOpenBankingPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contatos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpenBankingControllerObterContatosBancariosOpenBankingPorId.queryKey = '/api/v1/open-banking/contatos/{id}'

export const queryOpenBankingControllerObterContatosContasBancariasOpenBanking = (pathParams, queryParams, options) => queryFn(options)('/api/v1/open-banking/contatos/{id}/conta', pathParams, queryParams)
export const useQueryOpenBankingControllerObterContatosContasBancariasOpenBanking = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contatos/{id}/conta', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpenBankingControllerObterContatosContasBancariasOpenBanking.queryKey = '/api/v1/open-banking/contatos/{id}/conta'

export const mutationOpenBankingControllerCadastrarContatosContasBancariasOpenBanking = (pathParams, options) => mutationFn('post', '/api/v1/open-banking/contatos/{id}/conta', pathParams, {}, options)
export const useMutationOpenBankingControllerCadastrarContatosContasBancariasOpenBanking = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/open-banking/contatos/{id}/conta', pathParams, {}, options), config)

export const mutationOpenBankingControllerSolicitarTransferencia = (options) => mutationFn('post', '/api/v1/open-banking/transferencia', {}, {}, options)
export const useMutationOpenBankingControllerSolicitarTransferencia = (config, options) => useMutation(mutationFn('post', '/api/v1/open-banking/transferencia', {}, {}, options), config)

export const mutationOpenBankingControllerSolicitarPagamento = (options) => mutationFn('post', '/api/v1/open-banking/pagamento', {}, {}, options)
export const useMutationOpenBankingControllerSolicitarPagamento = (config, options) => useMutation(mutationFn('post', '/api/v1/open-banking/pagamento', {}, {}, options), config)

export const queryOpenBankingControllerListarTransacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/open-banking/contas-bancarias/{id}/transacoes', pathParams, queryParams)
export const useQueryOpenBankingControllerListarTransacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contas-bancarias/{id}/transacoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpenBankingControllerListarTransacoes.queryKey = '/api/v1/open-banking/contas-bancarias/{id}/transacoes'

export const queryOpenBankingControllerObterTransacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/open-banking/contas-bancarias/{id}/transacoes/{transacaoId}', pathParams)
export const useQueryOpenBankingControllerObterTransacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contas-bancarias/{id}/transacoes/{transacaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.transacaoId, ...config }
})
useQueryOpenBankingControllerObterTransacaoPorId.queryKey = '/api/v1/open-banking/contas-bancarias/{id}/transacoes/{transacaoId}'

export const mutationOperacoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/operacoes', {}, {}, options)
export const useMutationOperacoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/operacoes', {}, {}, options), config)

export const queryOperacoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/operacoes', queryParams)
export const useQueryOperacoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOperacoesControllerListar.queryKey = '/api/v1/operacoes'

export const queryOperacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/operacoes/{id}', pathParams)
export const useQueryOperacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOperacoesControllerObterPorId.queryKey = '/api/v1/operacoes/{id}'

export const mutationOperacoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/operacoes/{id}', pathParams, {}, options)
export const useMutationOperacoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/operacoes/{id}', pathParams, {}, options), config)

export const mutationOperacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/operacoes/{id}', pathParams, {}, options)
export const useMutationOperacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/operacoes/{id}', pathParams, {}, options), config)

export const mutationOperacoesControllerCopiar = (pathParams, options) => mutationFn('post', '/api/v1/operacoes/{operacaoId}', pathParams, {}, options)
export const useMutationOperacoesControllerCopiar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/operacoes/{operacaoId}', pathParams, {}, options), config)

export const mutationOperacoesControllerCadastrarOperacaoTag = (pathParams, options) => mutationFn('post', '/api/v1/operacoes/{id}/tags', pathParams, {}, options)
export const useMutationOperacoesControllerCadastrarOperacaoTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/operacoes/{id}/tags', pathParams, {}, options), config)

export const queryOperacoesControllerListarTagsOperacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/operacoes/{id}/tags', pathParams, queryParams)
export const useQueryOperacoesControllerListarTagsOperacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOperacoesControllerListarTagsOperacao.queryKey = '/api/v1/operacoes/{id}/tags'

export const mutationOperacoesControllerDeletarOperacaoTag = (pathParams, options) => mutationFn('delete', '/api/v1/operacoes/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationOperacoesControllerDeletarOperacaoTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/operacoes/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryOperacoesControllerListarOperacoesTags = (queryParams, options) => queryFn(options)('/api/v1/operacoes/tags', queryParams)
export const useQueryOperacoesControllerListarOperacoesTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOperacoesControllerListarOperacoesTags.queryKey = '/api/v1/operacoes/tags'

export const mutationOperacoesControllerCadastrarOperacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/operacoes/{id}/contas', pathParams, {}, options)
export const useMutationOperacoesControllerCadastrarOperacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/operacoes/{id}/contas', pathParams, {}, options), config)

export const queryOperacoesControllerListarOperacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/operacoes/{id}/contas', pathParams, queryParams)
export const useQueryOperacoesControllerListarOperacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOperacoesControllerListarOperacaoContas.queryKey = '/api/v1/operacoes/{id}/contas'

export const queryOperacoesControllerObterOperacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/operacoes/{id}/contas/{contaId}', pathParams)
export const useQueryOperacoesControllerObterOperacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryOperacoesControllerObterOperacaoContaPorId.queryKey = '/api/v1/operacoes/{id}/contas/{contaId}'

export const mutationOperacoesControllerAtualizarOperacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/operacoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationOperacoesControllerAtualizarOperacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/operacoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryOperacoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/operacoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryOperacoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOperacoesControllerListarContasDisponiveis.queryKey = '/api/v1/operacoes/{id}/contas-disponiveis'

export const queryOperacoesControllerListarOperacoesCaixa = (queryParams, options) => queryFn(options)('/api/v1/operacoes/caixa', queryParams)
export const useQueryOperacoesControllerListarOperacoesCaixa = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/caixa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOperacoesControllerListarOperacoesCaixa.queryKey = '/api/v1/operacoes/caixa'

export const queryOperacoesCalculoControllerObterOperacoesCampoCalculo = (options) => queryFn(options)('/api/v1/operacoes-calculo')
export const useQueryOperacoesCalculoControllerObterOperacoesCampoCalculo = (config, options) => useQuery({
  queryKey: ['/api/v1/operacoes-calculo'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOperacoesCalculoControllerObterOperacoesCampoCalculo.queryKey = '/api/v1/operacoes-calculo'

export const queryOperadorasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/operadoras', queryParams)
export const useQueryOperadorasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operadoras', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOperadorasControllerBuscar.queryKey = '/api/v1/operadoras'

export const mutationOperadorasControllerCadastrar = (options) => mutationFn('post', '/api/v1/operadoras', {}, {}, options)
export const useMutationOperadorasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/operadoras', {}, {}, options), config)

export const queryOperadorasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/operadoras/{id}', pathParams)
export const useQueryOperadorasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operadoras/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOperadorasControllerObterPorId.queryKey = '/api/v1/operadoras/{id}'

export const mutationOperadorasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/operadoras/{id}', pathParams, {}, options)
export const useMutationOperadorasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/operadoras/{id}', pathParams, {}, options), config)

export const mutationOperadorasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/operadoras/{id}', pathParams, {}, options)
export const useMutationOperadorasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/operadoras/{id}', pathParams, {}, options), config)

export const queryOportunidadeItensControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidade-itens/{id}', pathParams)
export const useQueryOportunidadeItensControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidade-itens/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadeItensControllerObterPorId.queryKey = '/api/v1/oportunidade-itens/{id}'

export const mutationOportunidadeItensControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidade-itens/{id}', pathParams, {}, options)
export const useMutationOportunidadeItensControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidade-itens/{id}', pathParams, {}, options), config)

export const mutationOportunidadeItensControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidade-itens/{id}', pathParams, {}, options)
export const useMutationOportunidadeItensControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidade-itens/{id}', pathParams, {}, options), config)

export const queryOportunidadeResponsaveisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidades-responsaveis/{id}', pathParams)
export const useQueryOportunidadeResponsaveisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades-responsaveis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadeResponsaveisControllerObterPorId.queryKey = '/api/v1/oportunidades-responsaveis/{id}'

export const mutationOportunidadeResponsaveisControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidades-responsaveis/{id}', pathParams, {}, options)
export const useMutationOportunidadeResponsaveisControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidades-responsaveis/{id}', pathParams, {}, options), config)

export const mutationOportunidadeResponsaveisControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades-responsaveis/{id}', pathParams, {}, options)
export const useMutationOportunidadeResponsaveisControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades-responsaveis/{id}', pathParams, {}, options), config)

export const queryOportunidadesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/oportunidades', queryParams)
export const useQueryOportunidadesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOportunidadesControllerListar.queryKey = '/api/v1/oportunidades'

export const mutationOportunidadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/oportunidades', {}, {}, options)
export const useMutationOportunidadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades', {}, {}, options), config)

export const queryOportunidadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidades/{id}', pathParams)
export const useQueryOportunidadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerObterPorId.queryKey = '/api/v1/oportunidades/{id}'

export const mutationOportunidadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidades/{id}', pathParams, {}, options)
export const useMutationOportunidadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidades/{id}', pathParams, {}, options), config)

export const mutationOportunidadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades/{id}', pathParams, {}, options)
export const useMutationOportunidadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades/{id}', pathParams, {}, options), config)

export const mutationOportunidadesControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/arquivos', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/arquivos', pathParams, {}, options), config)

export const queryOportunidadesControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/arquivos', pathParams, queryParams)
export const useQueryOportunidadesControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarArquivos.queryKey = '/api/v1/oportunidades/{id}/arquivos'

export const mutationOportunidadesControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationOportunidadesControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const queryOportunidadesControllerListarResponsaveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/oportunidade-responsaveis', pathParams, queryParams)
export const useQueryOportunidadesControllerListarResponsaveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/oportunidade-responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarResponsaveis.queryKey = '/api/v1/oportunidades/{id}/oportunidade-responsaveis'

export const mutationOportunidadesControllerCadastrarResponsavel = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/oportunidade-responsaveis', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarResponsavel = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/oportunidade-responsaveis', pathParams, {}, options), config)

export const queryOportunidadesControllerListarTags = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/tags', pathParams, queryParams)
export const useQueryOportunidadesControllerListarTags = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarTags.queryKey = '/api/v1/oportunidades/{id}/tags'

export const mutationOportunidadesControllerCadastrarTag = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/tags', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/tags', pathParams, {}, options), config)

export const mutationOportunidadesControllerDeletarTag = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationOportunidadesControllerDeletarTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryOportunidadesControllerListarTagsDispoiniveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryOportunidadesControllerListarTagsDispoiniveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarTagsDispoiniveis.queryKey = '/api/v1/oportunidades/{id}/tags-disponiveis'

export const queryOportunidadesControllerListarTarefas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/tarefas', pathParams, queryParams)
export const useQueryOportunidadesControllerListarTarefas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/tarefas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarTarefas.queryKey = '/api/v1/oportunidades/{id}/tarefas'

export const mutationOportunidadesControllerCadastrarTarefa = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/tarefas', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarTarefa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/tarefas', pathParams, {}, options), config)

export const queryOportunidadesControllerListarItens = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/itens', pathParams, queryParams)
export const useQueryOportunidadesControllerListarItens = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarItens.queryKey = '/api/v1/oportunidades/{id}/itens'

export const mutationOportunidadesControllerCadastrarItens = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/itens', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarItens = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/itens', pathParams, {}, options), config)

export const queryOportunidadesControllerListarComentarios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/comentarios', pathParams, queryParams)
export const useQueryOportunidadesControllerListarComentarios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/comentarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarComentarios.queryKey = '/api/v1/oportunidades/{id}/comentarios'

export const mutationOportunidadesControllerCadastrarComentarios = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/comentarios', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarComentarios = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/comentarios', pathParams, {}, options), config)

export const queryOportunidadesControllerListarDescontos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/descontos', pathParams, queryParams)
export const useQueryOportunidadesControllerListarDescontos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/descontos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarDescontos.queryKey = '/api/v1/oportunidades/{id}/descontos'

export const mutationOportunidadesControllerCadastrarDesconto = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/descontos', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/descontos', pathParams, {}, options), config)

export const mutationOportunidadesControllerAtualizarFaseFunil = (pathParams, options) => mutationFn('put', '/api/v1/oportunidades/{id}/mudar-fase', pathParams, {}, options)
export const useMutationOportunidadesControllerAtualizarFaseFunil = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidades/{id}/mudar-fase', pathParams, {}, options), config)

export const queryOportunidadesControllerListarHistorico = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/historico', pathParams, queryParams)
export const useQueryOportunidadesControllerListarHistorico = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/historico', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarHistorico.queryKey = '/api/v1/oportunidades/{id}/historico'

export const queryOportunidadesComentariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidade-comentarios/{id}', pathParams)
export const useQueryOportunidadesComentariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidade-comentarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesComentariosControllerObterPorId.queryKey = '/api/v1/oportunidade-comentarios/{id}'

export const mutationOportunidadesComentariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidade-comentarios/{id}', pathParams, {}, options)
export const useMutationOportunidadesComentariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidade-comentarios/{id}', pathParams, {}, options), config)

export const mutationOportunidadesComentariosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidade-comentarios/{id}', pathParams, {}, options)
export const useMutationOportunidadesComentariosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidade-comentarios/{id}', pathParams, {}, options), config)

export const queryOportunidadesDescontosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidades-descontos/{id}', pathParams)
export const useQueryOportunidadesDescontosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades-descontos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesDescontosControllerObterPorId.queryKey = '/api/v1/oportunidades-descontos/{id}'

export const mutationOportunidadesDescontosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades-descontos/{id}', pathParams, {}, options)
export const useMutationOportunidadesDescontosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades-descontos/{id}', pathParams, {}, options), config)

export const queryOportunidadesTarefasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidades-tarefas/{id}', pathParams)
export const useQueryOportunidadesTarefasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades-tarefas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesTarefasControllerObterPorId.queryKey = '/api/v1/oportunidades-tarefas/{id}'

export const mutationOportunidadesTarefasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidades-tarefas/{id}', pathParams, {}, options)
export const useMutationOportunidadesTarefasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidades-tarefas/{id}', pathParams, {}, options), config)

export const mutationOportunidadesTarefasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades-tarefas/{id}', pathParams, {}, options)
export const useMutationOportunidadesTarefasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades-tarefas/{id}', pathParams, {}, options), config)

export const mutationOportunidadesTarefasControllerAtualizarConclusao = (pathParams, options) => mutationFn('put', '/api/v1/oportunidades-tarefas/{id}/conclusao', pathParams, {}, options)
export const useMutationOportunidadesTarefasControllerAtualizarConclusao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidades-tarefas/{id}/conclusao', pathParams, {}, options), config)

export const queryOportunidadesTarefasControllerBuscarCheckList = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades-tarefas/{id}/check-lists', pathParams, queryParams)
export const useQueryOportunidadesTarefasControllerBuscarCheckList = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades-tarefas/{id}/check-lists', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesTarefasControllerBuscarCheckList.queryKey = '/api/v1/oportunidades-tarefas/{id}/check-lists'

export const mutationOportunidadesTarefasControllerCadastrarCheckList = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades-tarefas/{id}/check-lists', pathParams, {}, options)
export const useMutationOportunidadesTarefasControllerCadastrarCheckList = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades-tarefas/{id}/check-lists', pathParams, {}, options), config)

export const queryOportunidadeTarefaCheckListsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams)
export const useQueryOportunidadeTarefaCheckListsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadeTarefaCheckListsControllerObterPorId.queryKey = '/api/v1/oportunidade-tarefa-check-lists/{id}'

export const mutationOportunidadeTarefaCheckListsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationOportunidadeTarefaCheckListsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams, {}, options), config)

export const mutationOportunidadeTarefaCheckListsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationOportunidadeTarefaCheckListsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams, {}, options), config)

export const mutationOrcamentoPagamentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/orcamento-pagamentos/{id}', pathParams, {}, options)
export const useMutationOrcamentoPagamentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orcamento-pagamentos/{id}', pathParams, {}, options), config)

export const mutationOrcamentoPagamentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/orcamento-pagamentos/{id}', pathParams, {}, options)
export const useMutationOrcamentoPagamentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/orcamento-pagamentos/{id}', pathParams, {}, options), config)

export const queryOrcamentoPagamentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/orcamento-pagamentos/{id}', pathParams)
export const useQueryOrcamentoPagamentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamento-pagamentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentoPagamentosControllerObterPorId.queryKey = '/api/v1/orcamento-pagamentos/{id}'

export const queryOrcamentoPagamentosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/orcamento-pagamentos', queryParams)
export const useQueryOrcamentoPagamentosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamento-pagamentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentoPagamentosControllerBuscar.queryKey = '/api/v1/orcamento-pagamentos'

export const mutationOrcamentosEntradaControllerCadastrar = (options) => mutationFn('post', '/api/v1/orcamentos-entrada', {}, {}, options)
export const useMutationOrcamentosEntradaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-entrada', {}, {}, options), config)

export const queryOrcamentosEntradaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/orcamentos-entrada', queryParams)
export const useQueryOrcamentosEntradaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-entrada', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosEntradaControllerBuscar.queryKey = '/api/v1/orcamentos-entrada'

export const mutationOrcamentosEntradaControllerCadastrarPedidoCompraPorOrcamentoEntradaId = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-entrada/{id}/gerar-pedido', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerCadastrarPedidoCompraPorOrcamentoEntradaId = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-entrada/{id}/gerar-pedido', pathParams, {}, options), config)

export const mutationOrcamentosEntradaControllerCadastrarItem = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-entrada/{id}/itens', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerCadastrarItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-entrada/{id}/itens', pathParams, {}, options), config)

export const queryOrcamentosEntradaControllerBuscarItensPorOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/orcamentos-entrada/{id}/itens', pathParams, queryParams)
export const useQueryOrcamentosEntradaControllerBuscarItensPorOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-entrada/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosEntradaControllerBuscarItensPorOrcamentoId.queryKey = '/api/v1/orcamentos-entrada/{id}/itens'

export const mutationOrcamentosEntradaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/orcamentos-entrada/{id}', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orcamentos-entrada/{id}', pathParams, {}, options), config)

export const mutationOrcamentosEntradaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/orcamentos-entrada/{id}', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/orcamentos-entrada/{id}', pathParams, {}, options), config)

export const queryOrcamentosEntradaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/orcamentos-entrada/{id}', pathParams)
export const useQueryOrcamentosEntradaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-entrada/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosEntradaControllerObterPorId.queryKey = '/api/v1/orcamentos-entrada/{id}'

export const mutationOrcamentosEntradaControllerReprovar = (pathParams, options) => mutationFn('put', '/api/v1/orcamentos-entrada/{id}/reprovar', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerReprovar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orcamentos-entrada/{id}/reprovar', pathParams, {}, options), config)

export const queryOrcamentosEntradaControllerListarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/orcamentos-entrada/listar-filtros', queryParams)
export const useQueryOrcamentosEntradaControllerListarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-entrada/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosEntradaControllerListarPorFiltros.queryKey = '/api/v1/orcamentos-entrada/listar-filtros'

export const mutationOrcamentosEntradaControllerCadastrarOrcamentoPagamento = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-entrada/{id}/pagamento', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerCadastrarOrcamentoPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-entrada/{id}/pagamento', pathParams, {}, options), config)

export const mutationOrcamentosSaidaControllerCadastrarConvenio = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-saida/{id}/convenio', pathParams, {}, options)
export const useMutationOrcamentosSaidaControllerCadastrarConvenio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-saida/{id}/convenio', pathParams, {}, options), config)

export const queryOrcamentosSaidaControllerListarConvenios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}/convenios', pathParams, queryParams)
export const useQueryOrcamentosSaidaControllerListarConvenios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}/convenios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosSaidaControllerListarConvenios.queryKey = '/api/v1/orcamentos-saida/{id}/convenios'

export const mutationOrcamentosSaidaControllerReprovar = (pathParams, options) => mutationFn('put', '/api/v1/orcamentos-saida/{id}/reprovar', pathParams, {}, options)
export const useMutationOrcamentosSaidaControllerReprovar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orcamentos-saida/{id}/reprovar', pathParams, {}, options), config)

export const queryOrcamentosSaidaControllerObterValorTotalParcelamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}/valor-total-parcelamento', pathParams, queryParams)
export const useQueryOrcamentosSaidaControllerObterValorTotalParcelamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}/valor-total-parcelamento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosSaidaControllerObterValorTotalParcelamento.queryKey = '/api/v1/orcamentos-saida/{id}/valor-total-parcelamento'

export const mutationOrcamentosSaidaControllerCadastrarOrcamentoPagamento = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-saida/{id}/pagamento', pathParams, {}, options)
export const useMutationOrcamentosSaidaControllerCadastrarOrcamentoPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-saida/{id}/pagamento', pathParams, {}, options), config)

export const mutationOrcamentosSaidaSaudeControllerCadastrar = (options) => mutationFn('post', '/api/v1/orcamentos-saida', {}, {}, options)
export const useMutationOrcamentosSaidaSaudeControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-saida', {}, {}, options), config)

export const queryOrcamentosSaidaSaudeControllerListar = (queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida', queryParams)
export const useQueryOrcamentosSaidaSaudeControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosSaidaSaudeControllerListar.queryKey = '/api/v1/orcamentos-saida'

export const mutationOrcamentosSaidaSaudeControllerCadastrarItemOrcamentoSaida = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-saida/{id}/item', pathParams, {}, options)
export const useMutationOrcamentosSaidaSaudeControllerCadastrarItemOrcamentoSaida = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-saida/{id}/item', pathParams, {}, options), config)

export const mutationOrcamentosSaidaSaudeControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/orcamentos-saida/{id}', pathParams, {}, options)
export const useMutationOrcamentosSaidaSaudeControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orcamentos-saida/{id}', pathParams, {}, options), config)

export const queryOrcamentosSaidaSaudeControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}', pathParams)
export const useQueryOrcamentosSaidaSaudeControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosSaidaSaudeControllerObterPorId.queryKey = '/api/v1/orcamentos-saida/{id}'

export const mutationOrcamentosSaidaSaudeControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/orcamentos-saida/{id}', pathParams, {}, options)
export const useMutationOrcamentosSaidaSaudeControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/orcamentos-saida/{id}', pathParams, {}, options), config)

export const queryOrcamentosSaidaSaudeControllerListarPorFiltrosComExtras = (queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida/filtros-extras', queryParams)
export const useQueryOrcamentosSaidaSaudeControllerListarPorFiltrosComExtras = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/filtros-extras', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosSaidaSaudeControllerListarPorFiltrosComExtras.queryKey = '/api/v1/orcamentos-saida/filtros-extras'

export const queryOrcamentosSaidaSaudeControllerBuscarItensPorOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}/itens', pathParams, queryParams)
export const useQueryOrcamentosSaidaSaudeControllerBuscarItensPorOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosSaidaSaudeControllerBuscarItensPorOrcamentoId.queryKey = '/api/v1/orcamentos-saida/{id}/itens'

export const mutationOrcamentosSaidaSaudeControllerCadastrarPedidoVendaSaudePorOrcamentoSaidaId = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-saida/{id}/gerar-pedido', pathParams, {}, options)
export const useMutationOrcamentosSaidaSaudeControllerCadastrarPedidoVendaSaudePorOrcamentoSaidaId = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-saida/{id}/gerar-pedido', pathParams, {}, options), config)

export const queryOrcamentosSaidaSaudeControllerObterPedidoVendaSaudePorId = (pathParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}/pedidos/{pedidoVendaSaudeId}', pathParams)
export const useQueryOrcamentosSaidaSaudeControllerObterPedidoVendaSaudePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}/pedidos/{pedidoVendaSaudeId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.pedidoVendaSaudeId, ...config }
})
useQueryOrcamentosSaidaSaudeControllerObterPedidoVendaSaudePorId.queryKey = '/api/v1/orcamentos-saida/{id}/pedidos/{pedidoVendaSaudeId}'

export const queryOrcamentosSaidaSaudeControllerObterPedidoVendaSaudePorOrcamentoId = (pathParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}/pedido', pathParams)
export const useQueryOrcamentosSaidaSaudeControllerObterPedidoVendaSaudePorOrcamentoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}/pedido', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosSaidaSaudeControllerObterPedidoVendaSaudePorOrcamentoId.queryKey = '/api/v1/orcamentos-saida/{id}/pedido'

export const queryOrcamentosSaidaSaudeControllerListarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida/listar-filtros', queryParams)
export const useQueryOrcamentosSaidaSaudeControllerListarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosSaidaSaudeControllerListarPorFiltros.queryKey = '/api/v1/orcamentos-saida/listar-filtros'

export const mutationOrientacoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/orientacoes/{id}', pathParams, {}, options)
export const useMutationOrientacoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orientacoes/{id}', pathParams, {}, options), config)

export const mutationOrientacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/orientacoes/{id}', pathParams, {}, options)
export const useMutationOrientacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/orientacoes/{id}', pathParams, {}, options), config)

export const queryOrientacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/orientacoes/{id}', pathParams)
export const useQueryOrientacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orientacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrientacoesControllerObterPorId.queryKey = '/api/v1/orientacoes/{id}'

export const mutationOrientacoesControllerCadastrarOrientacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/orientacoes/{id}/contas', pathParams, {}, options)
export const useMutationOrientacoesControllerCadastrarOrientacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orientacoes/{id}/contas', pathParams, {}, options), config)

export const queryOrientacoesControllerListarOrientacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/orientacoes/{id}/contas', pathParams, queryParams)
export const useQueryOrientacoesControllerListarOrientacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orientacoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrientacoesControllerListarOrientacaoContas.queryKey = '/api/v1/orientacoes/{id}/contas'

export const queryOrientacoesControllerObterOrientacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/orientacoes/{id}/contas/{contaId}', pathParams)
export const useQueryOrientacoesControllerObterOrientacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orientacoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryOrientacoesControllerObterOrientacaoContaPorId.queryKey = '/api/v1/orientacoes/{id}/contas/{contaId}'

export const mutationOrientacoesControllerAtualizarOrientacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/orientacoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationOrientacoesControllerAtualizarOrientacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orientacoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryOrientacoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/orientacoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryOrientacoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orientacoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrientacoesControllerListarContasDisponiveis.queryKey = '/api/v1/orientacoes/{id}/contas-disponiveis'

export const queryOrigemOportunidadesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/origem-oportunidades', queryParams)
export const useQueryOrigemOportunidadesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origem-oportunidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrigemOportunidadesControllerBuscar.queryKey = '/api/v1/origem-oportunidades'

export const mutationOrigemOportunidadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/origem-oportunidades', {}, {}, options)
export const useMutationOrigemOportunidadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/origem-oportunidades', {}, {}, options), config)

export const queryOrigemOportunidadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/origem-oportunidades/{id}', pathParams)
export const useQueryOrigemOportunidadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origem-oportunidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrigemOportunidadesControllerObterPorId.queryKey = '/api/v1/origem-oportunidades/{id}'

export const mutationOrigemOportunidadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/origem-oportunidades/{id}', pathParams, {}, options)
export const useMutationOrigemOportunidadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/origem-oportunidades/{id}', pathParams, {}, options), config)

export const mutationOrigemOportunidadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/origem-oportunidades/{id}', pathParams, {}, options)
export const useMutationOrigemOportunidadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/origem-oportunidades/{id}', pathParams, {}, options), config)

export const mutationOrigemOportunidadesControllerCadastrarOrigemOportunidadeConta = (pathParams, options) => mutationFn('post', '/api/v1/origem-oportunidades/{id}/contas', pathParams, {}, options)
export const useMutationOrigemOportunidadesControllerCadastrarOrigemOportunidadeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/origem-oportunidades/{id}/contas', pathParams, {}, options), config)

export const queryOrigemOportunidadesControllerListarOrigemOportunidadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/origem-oportunidades/{id}/contas', pathParams, queryParams)
export const useQueryOrigemOportunidadesControllerListarOrigemOportunidadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origem-oportunidades/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrigemOportunidadesControllerListarOrigemOportunidadeContas.queryKey = '/api/v1/origem-oportunidades/{id}/contas'

export const queryOrigemOportunidadesControllerObterOrigemOportunidadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/origem-oportunidades/{id}/contas/{contaId}', pathParams)
export const useQueryOrigemOportunidadesControllerObterOrigemOportunidadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origem-oportunidades/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryOrigemOportunidadesControllerObterOrigemOportunidadeContaPorId.queryKey = '/api/v1/origem-oportunidades/{id}/contas/{contaId}'

export const mutationOrigemOportunidadesControllerAtualizarOrigemOportunidadeConta = (pathParams, options) => mutationFn('put', '/api/v1/origem-oportunidades/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationOrigemOportunidadesControllerAtualizarOrigemOportunidadeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/origem-oportunidades/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryOrigemOportunidadesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/origem-oportunidades/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryOrigemOportunidadesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origem-oportunidades/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrigemOportunidadesControllerListarContasDisponiveis.queryKey = '/api/v1/origem-oportunidades/{id}/contas-disponiveis'

export const queryOrigensDocumentoSaidaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/origens-de-documento-de-saida', queryParams)
export const useQueryOrigensDocumentoSaidaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origens-de-documento-de-saida', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrigensDocumentoSaidaControllerBuscar.queryKey = '/api/v1/origens-de-documento-de-saida'

export const queryOrigensDocumentoSaidaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/origens-de-documento-de-saida/{id}', pathParams)
export const useQueryOrigensDocumentoSaidaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origens-de-documento-de-saida/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrigensDocumentoSaidaControllerObterPorId.queryKey = '/api/v1/origens-de-documento-de-saida/{id}'

export const queryOrigensDocumentoSaidaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/origens-de-documento-de-saida/{chave}', pathParams)
export const useQueryOrigensDocumentoSaidaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origens-de-documento-de-saida/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryOrigensDocumentoSaidaControllerObterPorChave.queryKey = '/api/v1/origens-de-documento-de-saida/{chave}'

export const queryPacientesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/pacientes', queryParams)
export const useQueryPacientesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pacientes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPacientesControllerListar.queryKey = '/api/v1/pacientes'

export const mutationPacientesControllerCadastrar = (options) => mutationFn('post', '/api/v1/pacientes', {}, {}, options)
export const useMutationPacientesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/pacientes', {}, {}, options), config)

export const queryPacientesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/pacientes/{id}', pathParams)
export const useQueryPacientesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pacientes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPacientesControllerObterPorId.queryKey = '/api/v1/pacientes/{id}'

export const mutationPacientesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/pacientes/{id}', pathParams, {}, options)
export const useMutationPacientesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pacientes/{id}', pathParams, {}, options), config)

export const mutationPacientesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/pacientes/{id}', pathParams, {}, options)
export const useMutationPacientesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pacientes/{id}', pathParams, {}, options), config)

export const mutationPedidoPagamentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/pedido-pagamentos/{id}', pathParams, {}, options)
export const useMutationPedidoPagamentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedido-pagamentos/{id}', pathParams, {}, options), config)

export const mutationPedidoPagamentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/pedido-pagamentos/{id}', pathParams, {}, options)
export const useMutationPedidoPagamentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pedido-pagamentos/{id}', pathParams, {}, options), config)

export const queryPedidoPagamentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/pedido-pagamentos/{id}', pathParams)
export const useQueryPedidoPagamentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedido-pagamentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidoPagamentosControllerObterPorId.queryKey = '/api/v1/pedido-pagamentos/{id}'

export const queryPedidoPagamentosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/pedido-pagamentos', queryParams)
export const useQueryPedidoPagamentosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedido-pagamentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidoPagamentosControllerBuscar.queryKey = '/api/v1/pedido-pagamentos'

export const mutationPedidosCompraControllerCadastrar = (options) => mutationFn('post', '/api/v1/pedidos-compra', {}, {}, options)
export const useMutationPedidosCompraControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-compra', {}, {}, options), config)

export const queryPedidosCompraControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/pedidos-compra', queryParams)
export const useQueryPedidosCompraControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-compra', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidosCompraControllerBuscar.queryKey = '/api/v1/pedidos-compra'

export const mutationPedidosCompraControllerCadastrarItem = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-compra/{id}/itens', pathParams, {}, options)
export const useMutationPedidosCompraControllerCadastrarItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-compra/{id}/itens', pathParams, {}, options), config)

export const queryPedidosCompraControllerBuscarItensPorOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pedidos-compra/{id}/itens', pathParams, queryParams)
export const useQueryPedidosCompraControllerBuscarItensPorOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-compra/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidosCompraControllerBuscarItensPorOrcamentoId.queryKey = '/api/v1/pedidos-compra/{id}/itens'

export const mutationPedidosCompraControllerCadastrarTitulo = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-compra/{id}/titulos', pathParams, {}, options)
export const useMutationPedidosCompraControllerCadastrarTitulo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-compra/{id}/titulos', pathParams, {}, options), config)

export const mutationPedidosCompraControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-compra/{id}', pathParams, {}, options)
export const useMutationPedidosCompraControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-compra/{id}', pathParams, {}, options), config)

export const mutationPedidosCompraControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/pedidos-compra/{id}', pathParams, {}, options)
export const useMutationPedidosCompraControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pedidos-compra/{id}', pathParams, {}, options), config)

export const queryPedidosCompraControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/pedidos-compra/{id}', pathParams)
export const useQueryPedidosCompraControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-compra/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidosCompraControllerObterPorId.queryKey = '/api/v1/pedidos-compra/{id}'

export const mutationPedidosCompraControllerCancelar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-compra/{id}/cancelar', pathParams, {}, options)
export const useMutationPedidosCompraControllerCancelar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-compra/{id}/cancelar', pathParams, {}, options), config)

export const mutationPedidosCompraControllerEstornar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-compra/{id}/estornar', pathParams, {}, options)
export const useMutationPedidosCompraControllerEstornar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-compra/{id}/estornar', pathParams, {}, options), config)

export const queryPedidosCompraControllerBuscarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/pedidos-compra/listar-filtros', queryParams)
export const useQueryPedidosCompraControllerBuscarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-compra/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidosCompraControllerBuscarPorFiltros.queryKey = '/api/v1/pedidos-compra/listar-filtros'

export const mutationPedidosCompraControllerCadastrarPedidoPagamento = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-compra/{id}/pagamento', pathParams, {}, options)
export const useMutationPedidosCompraControllerCadastrarPedidoPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-compra/{id}/pagamento', pathParams, {}, options), config)

export const mutationPedidosVendaControllerEmitirNfse = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-venda/{id}/emitir-nfse', pathParams, {}, options)
export const useMutationPedidosVendaControllerEmitirNfse = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-venda/{id}/emitir-nfse', pathParams, {}, options), config)

export const mutationPedidosVendaControllerEmitirNfseTitulo = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-venda/{id}/emitir-nfse-titulo', pathParams, {}, options)
export const useMutationPedidosVendaControllerEmitirNfseTitulo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-venda/{id}/emitir-nfse-titulo', pathParams, {}, options), config)

export const mutationPedidosVendaControllerCancelar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-venda/{id}/cancelar', pathParams, {}, options)
export const useMutationPedidosVendaControllerCancelar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-venda/{id}/cancelar', pathParams, {}, options), config)

export const mutationPedidosVendaControllerEstornar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-venda/{id}/estornar', pathParams, {}, options)
export const useMutationPedidosVendaControllerEstornar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-venda/{id}/estornar', pathParams, {}, options), config)

export const mutationPedidosVendaControllerCadastrarPedidoPagamento = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-venda/{id}/pagamento', pathParams, {}, options)
export const useMutationPedidosVendaControllerCadastrarPedidoPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda/{id}/pagamento', pathParams, {}, options), config)

export const queryPedidosVendaControllerObterValorTotalParcelamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pedidos-venda/{id}/valor-total-parcelamento', pathParams, queryParams)
export const useQueryPedidosVendaControllerObterValorTotalParcelamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda/{id}/valor-total-parcelamento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidosVendaControllerObterValorTotalParcelamento.queryKey = '/api/v1/pedidos-venda/{id}/valor-total-parcelamento'

export const mutationPedidosVendaSaudeControllerCadastrar = (options) => mutationFn('post', '/api/v1/pedidos-venda', {}, {}, options)
export const useMutationPedidosVendaSaudeControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda', {}, {}, options), config)

export const queryPedidosVendaSaudeControllerListar = (queryParams, options) => queryFn(options)('/api/v1/pedidos-venda', queryParams)
export const useQueryPedidosVendaSaudeControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidosVendaSaudeControllerListar.queryKey = '/api/v1/pedidos-venda'

export const mutationPedidosVendaSaudeControllerCadastrarItemPedidoVenda = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-venda/{id}/item', pathParams, {}, options)
export const useMutationPedidosVendaSaudeControllerCadastrarItemPedidoVenda = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda/{id}/item', pathParams, {}, options), config)

export const mutationPedidosVendaSaudeControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-venda/{id}', pathParams, {}, options)
export const useMutationPedidosVendaSaudeControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-venda/{id}', pathParams, {}, options), config)

export const queryPedidosVendaSaudeControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/pedidos-venda/{id}', pathParams)
export const useQueryPedidosVendaSaudeControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidosVendaSaudeControllerObterPorId.queryKey = '/api/v1/pedidos-venda/{id}'

export const mutationPedidosVendaSaudeControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/pedidos-venda/{id}', pathParams, {}, options)
export const useMutationPedidosVendaSaudeControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pedidos-venda/{id}', pathParams, {}, options), config)

export const queryPedidosVendaSaudeControllerListarPorFiltrosComExtras = (queryParams, options) => queryFn(options)('/api/v1/pedidos-venda/filtros-extras', queryParams)
export const useQueryPedidosVendaSaudeControllerListarPorFiltrosComExtras = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda/filtros-extras', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidosVendaSaudeControllerListarPorFiltrosComExtras.queryKey = '/api/v1/pedidos-venda/filtros-extras'

export const queryPedidosVendaSaudeControllerBuscarItensPorOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pedidos-venda/{id}/itens', pathParams, queryParams)
export const useQueryPedidosVendaSaudeControllerBuscarItensPorOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidosVendaSaudeControllerBuscarItensPorOrcamentoId.queryKey = '/api/v1/pedidos-venda/{id}/itens'

export const queryPedidosVendaSaudeControllerListarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/pedidos-venda/listar-filtros', queryParams)
export const useQueryPedidosVendaSaudeControllerListarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidosVendaSaudeControllerListarPorFiltros.queryKey = '/api/v1/pedidos-venda/listar-filtros'

export const mutationPedidosVendaSaudeControllerCadastrarTitulo = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-venda/{id}/titulos', pathParams, {}, options)
export const useMutationPedidosVendaSaudeControllerCadastrarTitulo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda/{id}/titulos', pathParams, {}, options), config)

export const mutationPedidosVendaSaudeControllerCadastrarAssinaturaRecorrenciaTitulo = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-venda/{id}/titulos-recorrencia', pathParams, {}, options)
export const useMutationPedidosVendaSaudeControllerCadastrarAssinaturaRecorrenciaTitulo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda/{id}/titulos-recorrencia', pathParams, {}, options), config)

export const mutationPedidosVendaSaudeControllerCancelarAssinaturaPlano = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-venda/{id}/cancelar-assinatura', pathParams, {}, options)
export const useMutationPedidosVendaSaudeControllerCancelarAssinaturaPlano = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda/{id}/cancelar-assinatura', pathParams, {}, options), config)

export const queryPerfisClienteControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/perfis-cliente/{id}', pathParams)
export const useQueryPerfisClienteControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/perfis-cliente/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPerfisClienteControllerObterPorId.queryKey = '/api/v1/perfis-cliente/{id}'

export const queryPerfisClienteControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/perfis-cliente/{chave}', pathParams)
export const useQueryPerfisClienteControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/perfis-cliente/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryPerfisClienteControllerObterPorChave.queryKey = '/api/v1/perfis-cliente/{chave}'

export const queryPerfisClienteControllerListar = (options) => queryFn(options)('/api/v1/perfis-cliente')
export const useQueryPerfisClienteControllerListar = (config, options) => useQuery({
  queryKey: ['/api/v1/perfis-cliente'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPerfisClienteControllerListar.queryKey = '/api/v1/perfis-cliente'

export const queryPeriodosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/periodos', queryParams)
export const useQueryPeriodosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPeriodosControllerListar.queryKey = '/api/v1/periodos'

export const queryPeriodosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/periodos/{id}', pathParams)
export const useQueryPeriodosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/periodos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPeriodosControllerObterPorId.queryKey = '/api/v1/periodos/{id}'

export const queryPeriodosControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/periodos/{chave}', pathParams)
export const useQueryPeriodosControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/periodos/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryPeriodosControllerObterPorChave.queryKey = '/api/v1/periodos/{chave}'

export const queryPeriodosControllerObterPorCodigo = (pathParams, options) => queryFn(options)('/api/v1/periodos/{codigo}', pathParams)
export const useQueryPeriodosControllerObterPorCodigo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/periodos/{codigo}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.codigo, ...config }
})
useQueryPeriodosControllerObterPorCodigo.queryKey = '/api/v1/periodos/{codigo}'

export const queryPermissoesControllerListarRecursos = (queryParams, options) => queryFn(options)('/api/v1/permissoes/recursos', queryParams)
export const useQueryPermissoesControllerListarRecursos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/recursos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPermissoesControllerListarRecursos.queryKey = '/api/v1/permissoes/recursos'

export const queryPermissoesControllerListarPerfis = (queryParams, options) => queryFn(options)('/api/v1/permissoes/perfis', queryParams)
export const useQueryPermissoesControllerListarPerfis = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPermissoesControllerListarPerfis.queryKey = '/api/v1/permissoes/perfis'

export const mutationPermissoesControllerCadastrarPerfil = (options) => mutationFn('post', '/api/v1/permissoes/perfis', {}, {}, options)
export const useMutationPermissoesControllerCadastrarPerfil = (config, options) => useMutation(mutationFn('post', '/api/v1/permissoes/perfis', {}, {}, options), config)

export const queryPermissoesControllerObterPerfilPorId = (pathParams, options) => queryFn(options)('/api/v1/permissoes/perfis/{id}', pathParams)
export const useQueryPermissoesControllerObterPerfilPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPermissoesControllerObterPerfilPorId.queryKey = '/api/v1/permissoes/perfis/{id}'

export const mutationPermissoesControllerAtualizarPerfil = (pathParams, options) => mutationFn('put', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options)
export const useMutationPermissoesControllerAtualizarPerfil = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options), config)

export const mutationPermissoesControllerDeletarPerfil = (pathParams, options) => mutationFn('delete', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options)
export const useMutationPermissoesControllerDeletarPerfil = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options), config)

export const queryPermissoesControllerListarUsuarioPerfil = (pathParams, queryParams, options) => queryFn(options)('/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams)
export const useQueryPermissoesControllerListarUsuarioPerfil = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPermissoesControllerListarUsuarioPerfil.queryKey = '/api/v1/permissoes/perfis/{id}/usuarios'

export const mutationPermissoesControllerCadastrarUsuarioPerfil = (pathParams, queryParams, options) => mutationFn('post', '/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams, options)
export const useMutationPermissoesControllerCadastrarUsuarioPerfil = (pathParams, queryParams, config, options) => useMutation(mutationFn('post', '/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams, options), config)

export const queryPermissoesControllerObterUsuarioPerfilPorUsuarioId = (pathParams, options) => queryFn(options)('/api/v1/permissoes/perfis/usuarios/{usuarioId}', pathParams)
export const useQueryPermissoesControllerObterUsuarioPerfilPorUsuarioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis/usuarios/{usuarioId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.usuarioId, ...config }
})
useQueryPermissoesControllerObterUsuarioPerfilPorUsuarioId.queryKey = '/api/v1/permissoes/perfis/usuarios/{usuarioId}'

export const mutationPermissoesControllerRemoverUsuarioPerfil = (pathParams, options) => mutationFn('delete', '/api/v1/permissoes/perfis/{id}/usuarios/{usuarioId}', pathParams, {}, options)
export const useMutationPermissoesControllerRemoverUsuarioPerfil = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/permissoes/perfis/{id}/usuarios/{usuarioId}', pathParams, {}, options), config)

export const queryPessoasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/pessoas', queryParams)
export const useQueryPessoasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPessoasControllerBuscar.queryKey = '/api/v1/pessoas'

export const queryPessoasControllerBuscarContatos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/contatos', pathParams, queryParams)
export const useQueryPessoasControllerBuscarContatos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/contatos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerBuscarContatos.queryKey = '/api/v1/pessoas/{id}/contatos'

export const mutationPessoasControllerCadastrarContato = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/contatos', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarContato = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/contatos', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarUsuario = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/usuario', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarUsuario = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/usuario', pathParams, {}, options), config)

export const queryPessoasControllerBuscarEnderecos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/enderecos', pathParams, queryParams)
export const useQueryPessoasControllerBuscarEnderecos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/enderecos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerBuscarEnderecos.queryKey = '/api/v1/pessoas/{id}/enderecos'

export const mutationPessoasControllerCadastrarEndereco = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/enderecos', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarEndereco = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/enderecos', pathParams, {}, options), config)

export const queryPessoasControllerBuscarDependentes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/dependentes', pathParams, queryParams)
export const useQueryPessoasControllerBuscarDependentes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/dependentes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerBuscarDependentes.queryKey = '/api/v1/pessoas/{id}/dependentes'

export const mutationPessoasControllerCadastrarDependentePessoa = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/dependentes', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarDependentePessoa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/dependentes', pathParams, {}, options), config)

export const mutationPessoasControllerDeletarDependente = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/dependentes/{dependenteId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarDependente = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/dependentes/{dependenteId}', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarEmail = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/emails', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarEmail = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/emails', pathParams, {}, options), config)

export const queryPessoasControllerListarEmails = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/emails', pathParams, queryParams)
export const useQueryPessoasControllerListarEmails = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/emails', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarEmails.queryKey = '/api/v1/pessoas/{id}/emails'

export const mutationPessoasControllerDeletarEmail = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/email/{emailId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarEmail = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/email/{emailId}', pathParams, {}, options), config)

export const queryPessoasControllerObterEmailPorId = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/email/{emailId}', pathParams)
export const useQueryPessoasControllerObterEmailPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/email/{emailId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.emailId, ...config }
})
useQueryPessoasControllerObterEmailPorId.queryKey = '/api/v1/pessoas/{id}/email/{emailId}'

export const mutationPessoasControllerCadastrarRedeSocial = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/redes-sociais', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarRedeSocial = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/redes-sociais', pathParams, {}, options), config)

export const queryPessoasControllerListarRedesSocial = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/redes-sociais', pathParams, queryParams)
export const useQueryPessoasControllerListarRedesSocial = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/redes-sociais', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarRedesSocial.queryKey = '/api/v1/pessoas/{id}/redes-sociais'

export const mutationPessoasControllerDeletarRedeSocial = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/rede-social/{redeSocialId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarRedeSocial = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/rede-social/{redeSocialId}', pathParams, {}, options), config)

export const queryPessoasControllerObterRedeSocialPorId = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/redeSocial/{redeSocialId}', pathParams)
export const useQueryPessoasControllerObterRedeSocialPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/redeSocial/{redeSocialId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.redeSocialId, ...config }
})
useQueryPessoasControllerObterRedeSocialPorId.queryKey = '/api/v1/pessoas/{id}/redeSocial/{redeSocialId}'

export const mutationPessoasControllerCadastrarTelefone = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/telefones', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarTelefone = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/telefones', pathParams, {}, options), config)

export const queryPessoasControllerListarTelefones = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/telefones', pathParams, queryParams)
export const useQueryPessoasControllerListarTelefones = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/telefones', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarTelefones.queryKey = '/api/v1/pessoas/{id}/telefones'

export const mutationPessoasControllerDeletarTelefone = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/telefone/{telefoneId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarTelefone = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/telefone/{telefoneId}', pathParams, {}, options), config)

export const queryPessoasControllerObterTelefonePorId = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/telefone/{telefoneId}', pathParams)
export const useQueryPessoasControllerObterTelefonePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/telefone/{telefoneId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.telefoneId, ...config }
})
useQueryPessoasControllerObterTelefonePorId.queryKey = '/api/v1/pessoas/{id}/telefone/{telefoneId}'

export const mutationPessoasControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/arquivos', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/arquivos', pathParams, {}, options), config)

export const queryPessoasControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/arquivos', pathParams, queryParams)
export const useQueryPessoasControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarArquivos.queryKey = '/api/v1/pessoas/{id}/arquivos'

export const mutationPessoasControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarAvatar = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/avatar', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarAvatar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/avatar', pathParams, {}, options), config)

export const mutationPessoasControllerDeletarAvatar = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/avatar', pathParams, {}, options)
export const useMutationPessoasControllerDeletarAvatar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/avatar', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarPessoaPrecaucao = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/precaucao', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaPrecaucao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/precaucao', pathParams, {}, options), config)

export const queryPessoasControllerListarPessoaPrecaucoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/precaucoes', pathParams, queryParams)
export const useQueryPessoasControllerListarPessoaPrecaucoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/precaucoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarPessoaPrecaucoes.queryKey = '/api/v1/pessoas/{id}/precaucoes'

export const mutationPessoasControllerDeletarPessoaPrecaucao = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/precaucao/{precaucaoId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaPrecaucao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/precaucao/{precaucaoId}', pathParams, {}, options), config)

export const queryPessoasControllerListarAlergiaMedicamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/alergia-medicamentos', pathParams, queryParams)
export const useQueryPessoasControllerListarAlergiaMedicamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/alergia-medicamentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarAlergiaMedicamentos.queryKey = '/api/v1/pessoas/{id}/alergia-medicamentos'

export const mutationPessoasControllerCadastrarPessoaAlergia = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/alergia', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaAlergia = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/alergia', pathParams, {}, options), config)

export const queryPessoasControllerListarPessoaAlergias = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/alergias', pathParams, queryParams)
export const useQueryPessoasControllerListarPessoaAlergias = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/alergias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarPessoaAlergias.queryKey = '/api/v1/pessoas/{id}/alergias'

export const mutationPessoasControllerDeletarPessoaAlergia = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/alergia/{alergiaId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaAlergia = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/alergia/{alergiaId}', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarPessoaMedicamentoContinuo = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/medicamento-continuo', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaMedicamentoContinuo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/medicamento-continuo', pathParams, {}, options), config)

export const queryPessoasControllerListarPessoaMedicamentoContinuos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/medicamento-continuos', pathParams, queryParams)
export const useQueryPessoasControllerListarPessoaMedicamentoContinuos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/medicamento-continuos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarPessoaMedicamentoContinuos.queryKey = '/api/v1/pessoas/{id}/medicamento-continuos'

export const mutationPessoasControllerDeletarPessoaMedicamentoContinuo = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/medicamento-continuo/{itemId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaMedicamentoContinuo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/medicamento-continuo/{itemId}', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarPessoaNecessidadeEspecial = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/necessidade-especial', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaNecessidadeEspecial = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/necessidade-especial', pathParams, {}, options), config)

export const queryPessoasControllerListarPessoaNecessidadesEspeciais = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/necessidades-especiais', pathParams, queryParams)
export const useQueryPessoasControllerListarPessoaNecessidadesEspeciais = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/necessidades-especiais', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarPessoaNecessidadesEspeciais.queryKey = '/api/v1/pessoas/{id}/necessidades-especiais'

export const mutationPessoasControllerDeletarPessoaNecessidadeEspecial = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/necessidades-especiais/{necessidadeespecialId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaNecessidadeEspecial = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/necessidades-especiais/{necessidadeespecialId}', pathParams, {}, options), config)

export const queryPessoasControllerListarTitulos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/titulos', pathParams, queryParams)
export const useQueryPessoasControllerListarTitulos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/titulos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarTitulos.queryKey = '/api/v1/pessoas/{id}/titulos'

export const queryPessoasControllerListarOrcamentosSaida = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/orcamentos-saida', pathParams, queryParams)
export const useQueryPessoasControllerListarOrcamentosSaida = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/orcamentos-saida', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarOrcamentosSaida.queryKey = '/api/v1/pessoas/{id}/orcamentos-saida'

export const queryPessoasControllerListarPedidosVenda = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/pedidos-venda', pathParams, queryParams)
export const useQueryPessoasControllerListarPedidosVenda = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/pedidos-venda', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarPedidosVenda.queryKey = '/api/v1/pessoas/{id}/pedidos-venda'

export const mutationPessoasControllerCadastrarPessoaTipoSanguineo = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/tipo-sanguineo', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaTipoSanguineo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/tipo-sanguineo', pathParams, {}, options), config)

export const mutationPessoasControllerDeletarPessoaTipoSanguineo = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/tipo-sanguineo', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaTipoSanguineo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/tipo-sanguineo', pathParams, {}, options), config)

export const queryPessoasControllerObterTipoSanguineo = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/tipo-sanguineo', pathParams)
export const useQueryPessoasControllerObterTipoSanguineo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/tipo-sanguineo', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerObterTipoSanguineo.queryKey = '/api/v1/pessoas/{id}/tipo-sanguineo'

export const queryPessoasControllerObterIdadePessoa = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/idade', pathParams)
export const useQueryPessoasControllerObterIdadePessoa = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/idade', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerObterIdadePessoa.queryKey = '/api/v1/pessoas/{id}/idade'

export const queryPessoasControllerListarOportunidade = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/oportunidades', pathParams, queryParams)
export const useQueryPessoasControllerListarOportunidade = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/oportunidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarOportunidade.queryKey = '/api/v1/pessoas/{id}/oportunidades'

export const queryPessoasControllerListarTarefas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/tarefas', pathParams, queryParams)
export const useQueryPessoasControllerListarTarefas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/tarefas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarTarefas.queryKey = '/api/v1/pessoas/{id}/tarefas'

export const queryPessoasControllerListarResponsavelTarefaOportunidade = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/taferas-oportunidades', pathParams, queryParams)
export const useQueryPessoasControllerListarResponsavelTarefaOportunidade = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/taferas-oportunidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarResponsavelTarefaOportunidade.queryKey = '/api/v1/pessoas/{id}/taferas-oportunidades'

export const queryPessoasControllerListarPessoasResponsaveis = (queryParams, options) => queryFn(options)('/api/v1/pessoas/responsaveis', queryParams)
export const useQueryPessoasControllerListarPessoasResponsaveis = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/responsaveis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPessoasControllerListarPessoasResponsaveis.queryKey = '/api/v1/pessoas/responsaveis'

export const mutationPessoasControllerCadastrarPessoaIntegracao = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/integracoes', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaIntegracao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/integracoes', pathParams, {}, options), config)

export const queryPessoasControllerObterIntegracoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/integracoes', pathParams, queryParams)
export const useQueryPessoasControllerObterIntegracoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/integracoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerObterIntegracoes.queryKey = '/api/v1/pessoas/{id}/integracoes'

export const queryPessoasControllerBuscarPessoaIntegracao = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams)
export const useQueryPessoasControllerBuscarPessoaIntegracao = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.pessoaIntegracaoId, ...config }
})
useQueryPessoasControllerBuscarPessoaIntegracao.queryKey = '/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}'

export const mutationPessoasControllerAtualizarPessoaIntegracao = (pathParams, options) => mutationFn('put', '/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams, {}, options)
export const useMutationPessoasControllerAtualizarPessoaIntegracao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams, {}, options), config)

export const mutationPessoasControllerDeletarPessoaIntegracao = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaIntegracao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams, {}, options), config)

export const queryPessoasControllerBuscarPessoaIntegracaoPorChave = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/integracoes/{chave}', pathParams)
export const useQueryPessoasControllerBuscarPessoaIntegracaoPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/integracoes/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.chave, ...config }
})
useQueryPessoasControllerBuscarPessoaIntegracaoPorChave.queryKey = '/api/v1/pessoas/{id}/integracoes/{chave}'

export const mutationPessoasControllerAtualizarPessoaIntegracaoAtributo = (pathParams, options) => mutationFn('put', '/api/v1/pessoas/{pessoaId}/integracoes/{pessoaIntegracaoId}/atributo/{atributoId}', pathParams, {}, options)
export const useMutationPessoasControllerAtualizarPessoaIntegracaoAtributo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pessoas/{pessoaId}/integracoes/{pessoaIntegracaoId}/atributo/{atributoId}', pathParams, {}, options), config)

export const queryPessoasControllerListarHistoricoPorPessoaId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{pessoaId}/historico-contato', pathParams, queryParams)
export const useQueryPessoasControllerListarHistoricoPorPessoaId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{pessoaId}/historico-contato', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.pessoaId, ...config }
})
useQueryPessoasControllerListarHistoricoPorPessoaId.queryKey = '/api/v1/pessoas/{pessoaId}/historico-contato'

export const mutationPessoasControllerRetornaLinkWhatsapp = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{pessoaId}/whatsapp', pathParams, {}, options)
export const useMutationPessoasControllerRetornaLinkWhatsapp = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{pessoaId}/whatsapp', pathParams, {}, options), config)

export const mutationPlanosContaControllerCadastrar = (options) => mutationFn('post', '/api/v1/planos-de-conta', {}, {}, options)
export const useMutationPlanosContaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/planos-de-conta', {}, {}, options), config)

export const queryPlanosContaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/planos-de-conta', queryParams)
export const useQueryPlanosContaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPlanosContaControllerListar.queryKey = '/api/v1/planos-de-conta'

export const queryPlanosContaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/planos-de-conta/{id}', pathParams)
export const useQueryPlanosContaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosContaControllerObterPorId.queryKey = '/api/v1/planos-de-conta/{id}'

export const mutationPlanosContaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/planos-de-conta/{id}', pathParams, {}, options)
export const useMutationPlanosContaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/planos-de-conta/{id}', pathParams, {}, options), config)

export const mutationPlanosContaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/planos-de-conta/{id}', pathParams, {}, options)
export const useMutationPlanosContaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/planos-de-conta/{id}', pathParams, {}, options), config)

export const queryPlanosContaControllerListarContasContabeisCredito = (queryParams, options) => queryFn(options)('/api/v1/planos-de-conta/contas-contabeis-credito', queryParams)
export const useQueryPlanosContaControllerListarContasContabeisCredito = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/contas-contabeis-credito', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPlanosContaControllerListarContasContabeisCredito.queryKey = '/api/v1/planos-de-conta/contas-contabeis-credito'

export const queryPlanosContaControllerListarContasContabeisDebito = (queryParams, options) => queryFn(options)('/api/v1/planos-de-conta/contas-contabeis-debito', queryParams)
export const useQueryPlanosContaControllerListarContasContabeisDebito = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/contas-contabeis-debito', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPlanosContaControllerListarContasContabeisDebito.queryKey = '/api/v1/planos-de-conta/contas-contabeis-debito'

export const queryPlanosContaControllerObterResultadosContaCaixa = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-de-conta/{contaId}/DRE', pathParams, queryParams)
export const useQueryPlanosContaControllerObterResultadosContaCaixa = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/{contaId}/DRE', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.contaId, ...config }
})
useQueryPlanosContaControllerObterResultadosContaCaixa.queryKey = '/api/v1/planos-de-conta/{contaId}/DRE'

export const mutationPlanosContaControllerCadastrarPlanoContaConta = (pathParams, options) => mutationFn('post', '/api/v1/planos-de-conta/{id}/contas', pathParams, {}, options)
export const useMutationPlanosContaControllerCadastrarPlanoContaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/planos-de-conta/{id}/contas', pathParams, {}, options), config)

export const queryPlanosContaControllerListarPlanoContaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-de-conta/{id}/contas', pathParams, queryParams)
export const useQueryPlanosContaControllerListarPlanoContaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosContaControllerListarPlanoContaContas.queryKey = '/api/v1/planos-de-conta/{id}/contas'

export const queryPlanosContaControllerObterPlanoContaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/planos-de-conta/{id}/contas/{contaId}', pathParams)
export const useQueryPlanosContaControllerObterPlanoContaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryPlanosContaControllerObterPlanoContaContaPorId.queryKey = '/api/v1/planos-de-conta/{id}/contas/{contaId}'

export const mutationPlanosContaControllerAtualizarPlanoContaConta = (pathParams, options) => mutationFn('put', '/api/v1/planos-de-conta/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationPlanosContaControllerAtualizarPlanoContaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/planos-de-conta/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryPlanosContaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-de-conta/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryPlanosContaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosContaControllerListarContasDisponiveis.queryKey = '/api/v1/planos-de-conta/{id}/contas-disponiveis'

export const mutationPosologiasControllerCadastrar = (options) => mutationFn('post', '/api/v1/posologias', {}, {}, options)
export const useMutationPosologiasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/posologias', {}, {}, options), config)

export const queryPosologiasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/posologias', queryParams)
export const useQueryPosologiasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/posologias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPosologiasControllerListar.queryKey = '/api/v1/posologias'

export const queryPosologiasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/posologias/{id}', pathParams)
export const useQueryPosologiasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/posologias/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPosologiasControllerObterPorId.queryKey = '/api/v1/posologias/{id}'

export const mutationPosologiasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/posologias/{id}', pathParams, {}, options)
export const useMutationPosologiasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/posologias/{id}', pathParams, {}, options), config)

export const mutationPosologiasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/posologias/{id}', pathParams, {}, options)
export const useMutationPosologiasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/posologias/{id}', pathParams, {}, options), config)

export const mutationPosologiasControllerCadastrarPosologiaConta = (pathParams, options) => mutationFn('post', '/api/v1/posologias/{id}/contas', pathParams, {}, options)
export const useMutationPosologiasControllerCadastrarPosologiaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/posologias/{id}/contas', pathParams, {}, options), config)

export const queryPosologiasControllerListarPosologiaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/posologias/{id}/contas', pathParams, queryParams)
export const useQueryPosologiasControllerListarPosologiaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/posologias/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPosologiasControllerListarPosologiaContas.queryKey = '/api/v1/posologias/{id}/contas'

export const queryPosologiasControllerObterPosologiaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/posologias/{id}/contas/{contaId}', pathParams)
export const useQueryPosologiasControllerObterPosologiaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/posologias/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryPosologiasControllerObterPosologiaContaPorId.queryKey = '/api/v1/posologias/{id}/contas/{contaId}'

export const mutationPosologiasControllerAtualizarPosologiaConta = (pathParams, options) => mutationFn('put', '/api/v1/posologias/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationPosologiasControllerAtualizarPosologiaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/posologias/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryPosologiasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/posologias/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryPosologiasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/posologias/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPosologiasControllerListarContasDisponiveis.queryKey = '/api/v1/posologias/{id}/contas-disponiveis'

export const mutationPrecaucaoControllerCadastrarPrecaucao = (options) => mutationFn('post', '/api/v1/precaucoes', {}, {}, options)
export const useMutationPrecaucaoControllerCadastrarPrecaucao = (config, options) => useMutation(mutationFn('post', '/api/v1/precaucoes', {}, {}, options), config)

export const queryPrecaucaoControllerListarPrecaucoes = (queryParams, options) => queryFn(options)('/api/v1/precaucoes', queryParams)
export const useQueryPrecaucaoControllerListarPrecaucoes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precaucoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPrecaucaoControllerListarPrecaucoes.queryKey = '/api/v1/precaucoes'

export const mutationPrecaucaoControllerDeletarPrecaucao = (pathParams, options) => mutationFn('delete', '/api/v1/precaucoes/{id}', pathParams, {}, options)
export const useMutationPrecaucaoControllerDeletarPrecaucao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/precaucoes/{id}', pathParams, {}, options), config)

export const mutationPrecaucaoControllerCadastrarPrecaucaoConta = (pathParams, options) => mutationFn('post', '/api/v1/precaucoes/{id}/contas', pathParams, {}, options)
export const useMutationPrecaucaoControllerCadastrarPrecaucaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/precaucoes/{id}/contas', pathParams, {}, options), config)

export const queryPrecaucaoControllerListarPrecaucaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precaucoes/{id}/contas', pathParams, queryParams)
export const useQueryPrecaucaoControllerListarPrecaucaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precaucoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecaucaoControllerListarPrecaucaoContas.queryKey = '/api/v1/precaucoes/{id}/contas'

export const queryPrecaucaoControllerObterPrecaucaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/precaucoes/{id}/contas/{contaId}', pathParams)
export const useQueryPrecaucaoControllerObterPrecaucaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precaucoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryPrecaucaoControllerObterPrecaucaoContaPorId.queryKey = '/api/v1/precaucoes/{id}/contas/{contaId}'

export const mutationPrecaucaoControllerAtualizarPrecaucaoConta = (pathParams, options) => mutationFn('put', '/api/v1/precaucoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationPrecaucaoControllerAtualizarPrecaucaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/precaucoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryPrecaucaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precaucoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryPrecaucaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precaucoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecaucaoControllerListarContasDisponiveis.queryKey = '/api/v1/precaucoes/{id}/contas-disponiveis'

export const mutationPrecificacoesControllerCadastrarCustoVariavelPrecificacao = (pathParams, options) => mutationFn('post', '/api/v1/precificacoes/{id}/custo-variavel', pathParams, {}, options)
export const useMutationPrecificacoesControllerCadastrarCustoVariavelPrecificacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/precificacoes/{id}/custo-variavel', pathParams, {}, options), config)

export const mutationPrecificacoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/precificacoes', {}, {}, options)
export const useMutationPrecificacoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/precificacoes', {}, {}, options), config)

export const queryPrecificacoesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/precificacoes', queryParams)
export const useQueryPrecificacoesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPrecificacoesControllerBuscar.queryKey = '/api/v1/precificacoes'

export const mutationPrecificacoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/precificacoes/{id}', pathParams, {}, options)
export const useMutationPrecificacoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/precificacoes/{id}', pathParams, {}, options), config)

export const mutationPrecificacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/precificacoes/{id}', pathParams, {}, options)
export const useMutationPrecificacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/precificacoes/{id}', pathParams, {}, options), config)

export const queryPrecificacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/precificacoes/{id}', pathParams)
export const useQueryPrecificacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerObterPorId.queryKey = '/api/v1/precificacoes/{id}'

export const mutationPrecificacoesControllerDeletarCustoVariavelPrecificacao = (pathParams, options) => mutationFn('delete', '/api/v1/precificacoes/custo-variavel/{id}', pathParams, {}, options)
export const useMutationPrecificacoesControllerDeletarCustoVariavelPrecificacao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/precificacoes/custo-variavel/{id}', pathParams, {}, options), config)

export const queryPrecificacoesControllerObterCustoVariavelPrecificacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/precificacoes/custo-variavel/{id}', pathParams)
export const useQueryPrecificacoesControllerObterCustoVariavelPrecificacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/custo-variavel/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerObterCustoVariavelPrecificacaoPorId.queryKey = '/api/v1/precificacoes/custo-variavel/{id}'

export const queryPrecificacoesControllerBuscarCustosVariaveisPrecificacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/custos-variaveis', pathParams, queryParams)
export const useQueryPrecificacoesControllerBuscarCustosVariaveisPrecificacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/custos-variaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerBuscarCustosVariaveisPrecificacao.queryKey = '/api/v1/precificacoes/{id}/custos-variaveis'

export const queryPrecificacoesControllerBuscarCustosVariaveisDisponiveisPrecificacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/custos-variaveis-disponiveis', pathParams, queryParams)
export const useQueryPrecificacoesControllerBuscarCustosVariaveisDisponiveisPrecificacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/custos-variaveis-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerBuscarCustosVariaveisDisponiveisPrecificacao.queryKey = '/api/v1/precificacoes/{id}/custos-variaveis-disponiveis'

export const queryPrecificacoesControllerObterPorIdSimplififiado = (pathParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/simplificado', pathParams)
export const useQueryPrecificacoesControllerObterPorIdSimplififiado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/simplificado', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerObterPorIdSimplififiado.queryKey = '/api/v1/precificacoes/{id}/simplificado'

export const queryPrecificacoesControllerObterHistoricoPrecoItemPorId = (pathParams, options) => queryFn(options)('/api/v1/precificacoes/historico-preco/{id}', pathParams)
export const useQueryPrecificacoesControllerObterHistoricoPrecoItemPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/historico-preco/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerObterHistoricoPrecoItemPorId.queryKey = '/api/v1/precificacoes/historico-preco/{id}'

export const mutationPrecificacoesControllerAtualizarPrecos = (options) => mutationFn('post', '/api/v1/precificacoes/atualizar-precos', {}, {}, options)
export const useMutationPrecificacoesControllerAtualizarPrecos = (config, options) => useMutation(mutationFn('post', '/api/v1/precificacoes/atualizar-precos', {}, {}, options), config)

export const mutationPrecificacoesControllerCadastrarPrecificacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/precificacoes/{id}/contas', pathParams, {}, options)
export const useMutationPrecificacoesControllerCadastrarPrecificacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/precificacoes/{id}/contas', pathParams, {}, options), config)

export const queryPrecificacoesControllerListarPrecificacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/contas', pathParams, queryParams)
export const useQueryPrecificacoesControllerListarPrecificacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerListarPrecificacaoContas.queryKey = '/api/v1/precificacoes/{id}/contas'

export const queryPrecificacoesControllerObterPrecificacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/contas/{contaId}', pathParams)
export const useQueryPrecificacoesControllerObterPrecificacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryPrecificacoesControllerObterPrecificacaoContaPorId.queryKey = '/api/v1/precificacoes/{id}/contas/{contaId}'

export const mutationPrecificacoesControllerAtualizarPrecificacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/precificacoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationPrecificacoesControllerAtualizarPrecificacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/precificacoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryPrecificacoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryPrecificacoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerListarContasDisponiveis.queryKey = '/api/v1/precificacoes/{id}/contas-disponiveis'

export const queryPrescricoesDocumentosControllerObterModeloDocumentoPrescricaoPorId = (pathParams, options) => queryFn(options)('/api/v1/prescricoes-documentos/{id}', pathParams)
export const useQueryPrescricoesDocumentosControllerObterModeloDocumentoPrescricaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/prescricoes-documentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrescricoesDocumentosControllerObterModeloDocumentoPrescricaoPorId.queryKey = '/api/v1/prescricoes-documentos/{id}'

export const mutationPrescricoesDocumentosControllerDeletarPrescricaoModeloDocumento = (pathParams, options) => mutationFn('delete', '/api/v1/prescricoes-documentos/{id}', pathParams, {}, options)
export const useMutationPrescricoesDocumentosControllerDeletarPrescricaoModeloDocumento = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/prescricoes-documentos/{id}', pathParams, {}, options), config)

export const mutationPrescricoesDocumentosControllerAtualizarUltimaImpressao = (pathParams, options) => mutationFn('patch', '/api/v1/prescricoes-documentos/{id}/ultima-impressao', pathParams, {}, options)
export const useMutationPrescricoesDocumentosControllerAtualizarUltimaImpressao = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/prescricoes-documentos/{id}/ultima-impressao', pathParams, {}, options), config)

export const mutationPrescricoesModeloLaudosControllerAtualizarUltimaImpressao = (pathParams, options) => mutationFn('patch', '/api/v1/prescricoes-modelo-laudos/{id}/ultima-impressao', pathParams, {}, options)
export const useMutationPrescricoesModeloLaudosControllerAtualizarUltimaImpressao = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/prescricoes-modelo-laudos/{id}/ultima-impressao', pathParams, {}, options), config)

export const queryPrescricoesModeloLaudosControllerListarDocumentoLaudo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/prescricoes-modelo-laudos/{id}/documento', pathParams, queryParams)
export const useQueryPrescricoesModeloLaudosControllerListarDocumentoLaudo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/prescricoes-modelo-laudos/{id}/documento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrescricoesModeloLaudosControllerListarDocumentoLaudo.queryKey = '/api/v1/prescricoes-modelo-laudos/{id}/documento'

export const queryProdutosControllerListarAgendamentosDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/produtos/{id}/agendamentos-disponiveis', pathParams, queryParams)
export const useQueryProdutosControllerListarAgendamentosDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/produtos/{id}/agendamentos-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProdutosControllerListarAgendamentosDisponiveis.queryKey = '/api/v1/produtos/{id}/agendamentos-disponiveis'

export const mutationProdutosControllerAtualizarPrecoDeVenda = (pathParams, options) => mutationFn('post', '/api/v1/produtos/{id}/atualizar-preco', pathParams, {}, options)
export const useMutationProdutosControllerAtualizarPrecoDeVenda = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/produtos/{id}/atualizar-preco', pathParams, {}, options), config)

export const mutationProdutosControllerCadastrar = (options) => mutationFn('post', '/api/v1/produtos', {}, {}, options)
export const useMutationProdutosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/produtos', {}, {}, options), config)

export const queryProdutosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/produtos', queryParams)
export const useQueryProdutosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/produtos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryProdutosControllerBuscar.queryKey = '/api/v1/produtos'

export const mutationProdutosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/produtos/{id}', pathParams, {}, options)
export const useMutationProdutosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/produtos/{id}', pathParams, {}, options), config)

export const mutationProdutosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/produtos/{id}', pathParams, {}, options)
export const useMutationProdutosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/produtos/{id}', pathParams, {}, options), config)

export const queryProdutosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/produtos/{id}', pathParams)
export const useQueryProdutosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/produtos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProdutosControllerObterPorId.queryKey = '/api/v1/produtos/{id}'

export const queryProdutosControllerListarProdutoSaudePosologias = (pathParams, queryParams, options) => queryFn(options)('/api/v1/produtos/{id}/posologias', pathParams, queryParams)
export const useQueryProdutosControllerListarProdutoSaudePosologias = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/produtos/{id}/posologias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProdutosControllerListarProdutoSaudePosologias.queryKey = '/api/v1/produtos/{id}/posologias'

export const mutationProdutosControllerCadastrarProdutoSaudePosologia = (pathParams, options) => mutationFn('post', '/api/v1/produtos/{id}/posologias', pathParams, {}, options)
export const useMutationProdutosControllerCadastrarProdutoSaudePosologia = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/produtos/{id}/posologias', pathParams, {}, options), config)

export const mutationProdutosControllerDeletarProdutoSaudePosologia = (pathParams, options) => mutationFn('delete', '/api/v1/produtos/{id}/posologias/{produtoSaudePosologiaId}', pathParams, {}, options)
export const useMutationProdutosControllerDeletarProdutoSaudePosologia = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/produtos/{id}/posologias/{produtoSaudePosologiaId}', pathParams, {}, options), config)

export const queryProntuarioControllerObterClientePorCodigo = (pathParams, options) => queryFn(options)('/api/v1/prontuarios/{NumeroProntuario}/cliente', pathParams)
export const useQueryProntuarioControllerObterClientePorCodigo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/prontuarios/{NumeroProntuario}/cliente', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.NumeroProntuario, ...config }
})
useQueryProntuarioControllerObterClientePorCodigo.queryKey = '/api/v1/prontuarios/{NumeroProntuario}/cliente'

export const queryProntuarioControllerListarHistoricoPorCliente = (pathParams, queryParams, options) => queryFn(options)('/api/v1/prontuarios/clientes/{clienteId}', pathParams, queryParams)
export const useQueryProntuarioControllerListarHistoricoPorCliente = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/prontuarios/clientes/{clienteId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.clienteId, ...config }
})
useQueryProntuarioControllerListarHistoricoPorCliente.queryKey = '/api/v1/prontuarios/clientes/{clienteId}'

export const queryProntuarioControllerListarTotaisPorCliente = (pathParams, options) => queryFn(options)('/api/v1/prontuarios/cliente/{clienteId}/totais', pathParams)
export const useQueryProntuarioControllerListarTotaisPorCliente = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/prontuarios/cliente/{clienteId}/totais', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.clienteId, ...config }
})
useQueryProntuarioControllerListarTotaisPorCliente.queryKey = '/api/v1/prontuarios/cliente/{clienteId}/totais'

export const queryProntuarioControllerListarAnosMesesComAtendimento = (pathParams, options) => queryFn(options)('/api/v1/prontuarios/cliente/{clienteId}/anos-meses', pathParams)
export const useQueryProntuarioControllerListarAnosMesesComAtendimento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/prontuarios/cliente/{clienteId}/anos-meses', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.clienteId, ...config }
})
useQueryProntuarioControllerListarAnosMesesComAtendimento.queryKey = '/api/v1/prontuarios/cliente/{clienteId}/anos-meses'

export const queryProtocoloExamesControllerObterProtocoloExamePorId = (pathParams, options) => queryFn(options)('/api/v1/protocolo-exames/{id}', pathParams)
export const useQueryProtocoloExamesControllerObterProtocoloExamePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolo-exames/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProtocoloExamesControllerObterProtocoloExamePorId.queryKey = '/api/v1/protocolo-exames/{id}'

export const mutationProtocoloExamesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/protocolo-exames/{id}', pathParams, {}, options)
export const useMutationProtocoloExamesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/protocolo-exames/{id}', pathParams, {}, options), config)

export const mutationProtocoloExamesControllerDeletarProtocoloExame = (pathParams, options) => mutationFn('delete', '/api/v1/protocolo-exames/{id}', pathParams, {}, options)
export const useMutationProtocoloExamesControllerDeletarProtocoloExame = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/protocolo-exames/{id}', pathParams, {}, options), config)

export const mutationProtocoloExamesControllerCadastrarProtocoloExameAnatomia = (pathParams, options) => mutationFn('post', '/api/v1/protocolo-exames/{id}/anatomias', pathParams, {}, options)
export const useMutationProtocoloExamesControllerCadastrarProtocoloExameAnatomia = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/protocolo-exames/{id}/anatomias', pathParams, {}, options), config)

export const queryProtocoloExamesControllerListarProtocoloExameAnatomias = (pathParams, queryParams, options) => queryFn(options)('/api/v1/protocolo-exames/{id}/anatomias', pathParams, queryParams)
export const useQueryProtocoloExamesControllerListarProtocoloExameAnatomias = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolo-exames/{id}/anatomias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProtocoloExamesControllerListarProtocoloExameAnatomias.queryKey = '/api/v1/protocolo-exames/{id}/anatomias'

export const mutationProtocoloExamesControllerDeletarProtocoloExameAnatomia = (pathParams, options) => mutationFn('delete', '/api/v1/protocolo-exames/{id}/anatomias/{anatomiasId}', pathParams, {}, options)
export const useMutationProtocoloExamesControllerDeletarProtocoloExameAnatomia = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/protocolo-exames/{id}/anatomias/{anatomiasId}', pathParams, {}, options), config)

export const queryProtocoloMedicamentosControllerObterProtocoloExamePorId = (pathParams, options) => queryFn(options)('/api/v1/protocolo-medicamentos/{id}', pathParams)
export const useQueryProtocoloMedicamentosControllerObterProtocoloExamePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolo-medicamentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProtocoloMedicamentosControllerObterProtocoloExamePorId.queryKey = '/api/v1/protocolo-medicamentos/{id}'

export const mutationProtocoloMedicamentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/protocolo-medicamentos/{id}', pathParams, {}, options)
export const useMutationProtocoloMedicamentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/protocolo-medicamentos/{id}', pathParams, {}, options), config)

export const mutationProtocoloMedicamentosControllerDeletarProtocoloMedicamento = (pathParams, options) => mutationFn('delete', '/api/v1/protocolo-medicamentos/{id}', pathParams, {}, options)
export const useMutationProtocoloMedicamentosControllerDeletarProtocoloMedicamento = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/protocolo-medicamentos/{id}', pathParams, {}, options), config)

export const queryProtocolosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/protocolos', queryParams)
export const useQueryProtocolosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryProtocolosControllerBuscar.queryKey = '/api/v1/protocolos'

export const mutationProtocolosControllerCadastrar = (options) => mutationFn('post', '/api/v1/protocolos', {}, {}, options)
export const useMutationProtocolosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/protocolos', {}, {}, options), config)

export const queryProtocolosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/protocolos/{id}', pathParams)
export const useQueryProtocolosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProtocolosControllerObterPorId.queryKey = '/api/v1/protocolos/{id}'

export const mutationProtocolosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/protocolos/{id}', pathParams, {}, options)
export const useMutationProtocolosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/protocolos/{id}', pathParams, {}, options), config)

export const mutationProtocolosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/protocolos/{id}', pathParams, {}, options)
export const useMutationProtocolosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/protocolos/{id}', pathParams, {}, options), config)

export const mutationProtocolosControllerCadastrarProtocoloConta = (pathParams, options) => mutationFn('post', '/api/v1/protocolos/{id}/contas', pathParams, {}, options)
export const useMutationProtocolosControllerCadastrarProtocoloConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/protocolos/{id}/contas', pathParams, {}, options), config)

export const queryProtocolosControllerListarProtocoloContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/protocolos/{id}/contas', pathParams, queryParams)
export const useQueryProtocolosControllerListarProtocoloContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProtocolosControllerListarProtocoloContas.queryKey = '/api/v1/protocolos/{id}/contas'

export const queryProtocolosControllerObterProtocoloContaPorId = (pathParams, options) => queryFn(options)('/api/v1/protocolos/{id}/contas/{contaId}', pathParams)
export const useQueryProtocolosControllerObterProtocoloContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryProtocolosControllerObterProtocoloContaPorId.queryKey = '/api/v1/protocolos/{id}/contas/{contaId}'

export const mutationProtocolosControllerAtualizarProtocoloConta = (pathParams, options) => mutationFn('put', '/api/v1/protocolos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationProtocolosControllerAtualizarProtocoloConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/protocolos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryProtocolosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/protocolos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryProtocolosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProtocolosControllerListarContasDisponiveis.queryKey = '/api/v1/protocolos/{id}/contas-disponiveis'

export const mutationProtocolosControllerCadastrarProtocoloExame = (pathParams, options) => mutationFn('post', '/api/v1/protocolos/{id}/exames', pathParams, {}, options)
export const useMutationProtocolosControllerCadastrarProtocoloExame = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/protocolos/{id}/exames', pathParams, {}, options), config)

export const queryProtocolosControllerListarProtocoloExames = (pathParams, queryParams, options) => queryFn(options)('/api/v1/protocolos/{id}/exames', pathParams, queryParams)
export const useQueryProtocolosControllerListarProtocoloExames = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolos/{id}/exames', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProtocolosControllerListarProtocoloExames.queryKey = '/api/v1/protocolos/{id}/exames'

export const mutationProtocolosControllerCadastrarProtocoloMedicamento = (pathParams, options) => mutationFn('post', '/api/v1/protocolos/{id}/medicamentos', pathParams, {}, options)
export const useMutationProtocolosControllerCadastrarProtocoloMedicamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/protocolos/{id}/medicamentos', pathParams, {}, options), config)

export const queryProtocolosControllerListarProtocoloMedicamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/protocolos/{id}/medicamentos', pathParams, queryParams)
export const useQueryProtocolosControllerListarProtocoloMedicamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolos/{id}/medicamentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProtocolosControllerListarProtocoloMedicamentos.queryKey = '/api/v1/protocolos/{id}/medicamentos'

export const mutationProtocolosControllerCadastrarProtocoloEspecialidade = (pathParams, options) => mutationFn('post', '/api/v1/protocolos/{id}/especialidades', pathParams, {}, options)
export const useMutationProtocolosControllerCadastrarProtocoloEspecialidade = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/protocolos/{id}/especialidades', pathParams, {}, options), config)

export const queryProtocolosControllerListarProtocoloEspecialidades = (pathParams, queryParams, options) => queryFn(options)('/api/v1/protocolos/{id}/especialidades', pathParams, queryParams)
export const useQueryProtocolosControllerListarProtocoloEspecialidades = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolos/{id}/especialidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProtocolosControllerListarProtocoloEspecialidades.queryKey = '/api/v1/protocolos/{id}/especialidades'

export const mutationProtocolosControllerDeletarProtocoloEspecialidade = (pathParams, options) => mutationFn('delete', '/api/v1/protocolos/{id}/especialidades/{especialidadeId}', pathParams, {}, options)
export const useMutationProtocolosControllerDeletarProtocoloEspecialidade = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/protocolos/{id}/especialidades/{especialidadeId}', pathParams, {}, options), config)

export const mutationProtocolosControllerCadastrarProtocoloPessoa = (pathParams, options) => mutationFn('post', '/api/v1/protocolos/{id}/pessoas', pathParams, {}, options)
export const useMutationProtocolosControllerCadastrarProtocoloPessoa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/protocolos/{id}/pessoas', pathParams, {}, options), config)

export const queryProtocolosControllerListarProtocoloPessoa = (pathParams, queryParams, options) => queryFn(options)('/api/v1/protocolos/{id}/pessoas', pathParams, queryParams)
export const useQueryProtocolosControllerListarProtocoloPessoa = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/protocolos/{id}/pessoas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProtocolosControllerListarProtocoloPessoa.queryKey = '/api/v1/protocolos/{id}/pessoas'

export const mutationProtocolosControllerDeletarProtocoloPessoa = (pathParams, options) => mutationFn('delete', '/api/v1/protocolos/{id}/pessoas/{pessoaId}', pathParams, {}, options)
export const useMutationProtocolosControllerDeletarProtocoloPessoa = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/protocolos/{id}/pessoas/{pessoaId}', pathParams, {}, options), config)

export const queryQuestionariosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/questionarios', queryParams)
export const useQueryQuestionariosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryQuestionariosControllerListar.queryKey = '/api/v1/questionarios'

export const mutationQuestionariosControllerCadastrar = (options) => mutationFn('post', '/api/v1/questionarios', {}, {}, options)
export const useMutationQuestionariosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/questionarios', {}, {}, options), config)

export const queryQuestionariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/questionarios/{id}', pathParams)
export const useQueryQuestionariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryQuestionariosControllerObterPorId.queryKey = '/api/v1/questionarios/{id}'

export const mutationQuestionariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/questionarios/{id}', pathParams, {}, options)
export const useMutationQuestionariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/questionarios/{id}', pathParams, {}, options), config)

export const mutationQuestionariosControllerDeletarQuestionario = (pathParams, options) => mutationFn('delete', '/api/v1/questionarios/{id}', pathParams, {}, options)
export const useMutationQuestionariosControllerDeletarQuestionario = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/questionarios/{id}', pathParams, {}, options), config)

export const queryQuestionariosControllerObterCadernoPorQuestionarioId = (pathParams, options) => queryFn(options)('/api/v1/questionarios/{id}/caderno', pathParams)
export const useQueryQuestionariosControllerObterCadernoPorQuestionarioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{id}/caderno', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryQuestionariosControllerObterCadernoPorQuestionarioId.queryKey = '/api/v1/questionarios/{id}/caderno'

export const queryQuestionariosControllerListarFormularios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/questionarios/{questionarioId}/formularios', pathParams, queryParams)
export const useQueryQuestionariosControllerListarFormularios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{questionarioId}/formularios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.questionarioId, ...config }
})
useQueryQuestionariosControllerListarFormularios.queryKey = '/api/v1/questionarios/{questionarioId}/formularios'

export const mutationQuestionariosControllerCadastrarFormularioResposta = (pathParams, options) => mutationFn('post', '/api/v1/questionarios/{questionarioId}/formularios', pathParams, {}, options)
export const useMutationQuestionariosControllerCadastrarFormularioResposta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/questionarios/{questionarioId}/formularios', pathParams, {}, options), config)

export const queryQuestionariosControllerListarBoardFormularios = (pathParams, options) => queryFn(options)('/api/v1/questionarios/{questionarioId}/formularios-dashboard', pathParams)
export const useQueryQuestionariosControllerListarBoardFormularios = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{questionarioId}/formularios-dashboard', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.questionarioId, ...config }
})
useQueryQuestionariosControllerListarBoardFormularios.queryKey = '/api/v1/questionarios/{questionarioId}/formularios-dashboard'

export const queryQuestionariosControllerListarTextosRespostas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/questionarios/{questionarioId}/textos-respostas/{perguntaId}', pathParams, queryParams)
export const useQueryQuestionariosControllerListarTextosRespostas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{questionarioId}/textos-respostas/{perguntaId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.questionarioId && pathParams.perguntaId, ...config }
})
useQueryQuestionariosControllerListarTextosRespostas.queryKey = '/api/v1/questionarios/{questionarioId}/textos-respostas/{perguntaId}'

export const queryQuestionariosControllerListarQuestionarioContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/questionarios/{id}/contas', pathParams, queryParams)
export const useQueryQuestionariosControllerListarQuestionarioContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryQuestionariosControllerListarQuestionarioContas.queryKey = '/api/v1/questionarios/{id}/contas'

export const mutationQuestionariosControllerCadastrarQuestionarioConta = (pathParams, options) => mutationFn('post', '/api/v1/questionarios/{id}/contas', pathParams, {}, options)
export const useMutationQuestionariosControllerCadastrarQuestionarioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/questionarios/{id}/contas', pathParams, {}, options), config)

export const queryQuestionariosControllerObterQuestionarioContaPorId = (pathParams, options) => queryFn(options)('/api/v1/questionarios/{id}/contas/{contaId}', pathParams)
export const useQueryQuestionariosControllerObterQuestionarioContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryQuestionariosControllerObterQuestionarioContaPorId.queryKey = '/api/v1/questionarios/{id}/contas/{contaId}'

export const mutationQuestionariosControllerAtualizarQuestionarioConta = (pathParams, options) => mutationFn('put', '/api/v1/questionarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationQuestionariosControllerAtualizarQuestionarioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/questionarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryQuestionariosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/questionarios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryQuestionariosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryQuestionariosControllerListarContasDisponiveis.queryKey = '/api/v1/questionarios/{id}/contas-disponiveis'

export const queryRamosAtividadeControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/ramos-atividade', queryParams)
export const useQueryRamosAtividadeControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ramos-atividade', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryRamosAtividadeControllerBuscar.queryKey = '/api/v1/ramos-atividade'

export const mutationRamosAtividadeControllerCadastrar = (options) => mutationFn('post', '/api/v1/ramos-atividade', {}, {}, options)
export const useMutationRamosAtividadeControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/ramos-atividade', {}, {}, options), config)

export const queryRamosAtividadeControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/ramos-atividade/{id}', pathParams)
export const useQueryRamosAtividadeControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ramos-atividade/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRamosAtividadeControllerObterPorId.queryKey = '/api/v1/ramos-atividade/{id}'

export const mutationRamosAtividadeControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/ramos-atividade/{id}', pathParams, {}, options)
export const useMutationRamosAtividadeControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/ramos-atividade/{id}', pathParams, {}, options), config)

export const mutationRamosAtividadeControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/ramos-atividade/{id}', pathParams, {}, options)
export const useMutationRamosAtividadeControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/ramos-atividade/{id}', pathParams, {}, options), config)

export const mutationRamosAtividadeControllerCadastrarRamoAtividadeConta = (pathParams, options) => mutationFn('post', '/api/v1/ramos-atividade/{id}/contas', pathParams, {}, options)
export const useMutationRamosAtividadeControllerCadastrarRamoAtividadeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/ramos-atividade/{id}/contas', pathParams, {}, options), config)

export const queryRamosAtividadeControllerListarRamoAtividadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/ramos-atividade/{id}/contas', pathParams, queryParams)
export const useQueryRamosAtividadeControllerListarRamoAtividadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ramos-atividade/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRamosAtividadeControllerListarRamoAtividadeContas.queryKey = '/api/v1/ramos-atividade/{id}/contas'

export const queryRamosAtividadeControllerObterRamoAtividadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/ramos-atividade/{id}/contas/{contaId}', pathParams)
export const useQueryRamosAtividadeControllerObterRamoAtividadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ramos-atividade/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryRamosAtividadeControllerObterRamoAtividadeContaPorId.queryKey = '/api/v1/ramos-atividade/{id}/contas/{contaId}'

export const mutationRamosAtividadeControllerAtualizarRamoAtividadeConta = (pathParams, options) => mutationFn('put', '/api/v1/ramos-atividade/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationRamosAtividadeControllerAtualizarRamoAtividadeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/ramos-atividade/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryRamosAtividadeControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/ramos-atividade/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryRamosAtividadeControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ramos-atividade/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRamosAtividadeControllerListarContasDisponiveis.queryKey = '/api/v1/ramos-atividade/{id}/contas-disponiveis'

export const queryRecursosNecessidadeEspecialControllerListarRecursoNecessidadesEspeciais = (queryParams, options) => queryFn(options)('/api/v1/recursos-necessidade-especial', queryParams)
export const useQueryRecursosNecessidadeEspecialControllerListarRecursoNecessidadesEspeciais = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos-necessidade-especial', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryRecursosNecessidadeEspecialControllerListarRecursoNecessidadesEspeciais.queryKey = '/api/v1/recursos-necessidade-especial'

export const mutationRecursosNecessidadeEspecialControllerCadastrar = (options) => mutationFn('post', '/api/v1/recursos-necessidade-especial', {}, {}, options)
export const useMutationRecursosNecessidadeEspecialControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/recursos-necessidade-especial', {}, {}, options), config)

export const queryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialPorId = (pathParams, options) => queryFn(options)('/api/v1/recursos-necessidade-especial/{id}', pathParams)
export const useQueryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos-necessidade-especial/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialPorId.queryKey = '/api/v1/recursos-necessidade-especial/{id}'

export const mutationRecursosNecessidadeEspecialControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/recursos-necessidade-especial/{id}', pathParams, {}, options)
export const useMutationRecursosNecessidadeEspecialControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/recursos-necessidade-especial/{id}', pathParams, {}, options), config)

export const mutationRecursosNecessidadeEspecialControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/recursos-necessidade-especial/{id}', pathParams, {}, options)
export const useMutationRecursosNecessidadeEspecialControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/recursos-necessidade-especial/{id}', pathParams, {}, options), config)

export const mutationRecursosNecessidadeEspecialControllerCadastrarRecursoNecessidadeEspecialConta = (pathParams, options) => mutationFn('post', '/api/v1/recursos-necessidade-especial/{id}/contas', pathParams, {}, options)
export const useMutationRecursosNecessidadeEspecialControllerCadastrarRecursoNecessidadeEspecialConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/recursos-necessidade-especial/{id}/contas', pathParams, {}, options), config)

export const queryRecursosNecessidadeEspecialControllerListarRecursoNecessidadeEspecialContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/recursos-necessidade-especial/{id}/contas', pathParams, queryParams)
export const useQueryRecursosNecessidadeEspecialControllerListarRecursoNecessidadeEspecialContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos-necessidade-especial/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRecursosNecessidadeEspecialControllerListarRecursoNecessidadeEspecialContas.queryKey = '/api/v1/recursos-necessidade-especial/{id}/contas'

export const queryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialContaPorId = (pathParams, options) => queryFn(options)('/api/v1/recursos-necessidade-especial/{id}/contas/{contaId}', pathParams)
export const useQueryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos-necessidade-especial/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialContaPorId.queryKey = '/api/v1/recursos-necessidade-especial/{id}/contas/{contaId}'

export const mutationRecursosNecessidadeEspecialControllerAtualizarRecursoNecessidadeEspecialConta = (pathParams, options) => mutationFn('put', '/api/v1/recursos-necessidade-especial/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationRecursosNecessidadeEspecialControllerAtualizarRecursoNecessidadeEspecialConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/recursos-necessidade-especial/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryRecursosNecessidadeEspecialControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/recursos-necessidade-especial/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryRecursosNecessidadeEspecialControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos-necessidade-especial/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRecursosNecessidadeEspecialControllerListarContasDisponiveis.queryKey = '/api/v1/recursos-necessidade-especial/{id}/contas-disponiveis'

export const queryResponsaveisServicoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/responsaveis-servico/{id}', pathParams)
export const useQueryResponsaveisServicoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/responsaveis-servico/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryResponsaveisServicoControllerObterPorId.queryKey = '/api/v1/responsaveis-servico/{id}'

export const mutationResponsaveisServicoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/responsaveis-servico/{id}', pathParams, {}, options)
export const useMutationResponsaveisServicoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/responsaveis-servico/{id}', pathParams, {}, options), config)

export const querySalasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/salas', queryParams)
export const useQuerySalasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySalasControllerBuscar.queryKey = '/api/v1/salas'

export const mutationSalasControllerCadastrar = (options) => mutationFn('post', '/api/v1/salas', {}, {}, options)
export const useMutationSalasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/salas', {}, {}, options), config)

export const querySalasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/salas/{id}', pathParams)
export const useQuerySalasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySalasControllerObterPorId.queryKey = '/api/v1/salas/{id}'

export const mutationSalasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/salas/{id}', pathParams, {}, options)
export const useMutationSalasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/salas/{id}', pathParams, {}, options), config)

export const mutationSalasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/salas/{id}', pathParams, {}, options)
export const useMutationSalasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/salas/{id}', pathParams, {}, options), config)

export const mutationSalasControllerCadastrarSalaConta = (pathParams, options) => mutationFn('post', '/api/v1/salas/{id}/contas', pathParams, {}, options)
export const useMutationSalasControllerCadastrarSalaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/salas/{id}/contas', pathParams, {}, options), config)

export const querySalasControllerListarSalaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/salas/{id}/contas', pathParams, queryParams)
export const useQuerySalasControllerListarSalaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySalasControllerListarSalaContas.queryKey = '/api/v1/salas/{id}/contas'

export const querySalasControllerObterSalaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/salas/{id}/contas/{contaId}', pathParams)
export const useQuerySalasControllerObterSalaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQuerySalasControllerObterSalaContaPorId.queryKey = '/api/v1/salas/{id}/contas/{contaId}'

export const mutationSalasControllerAtualizarSalaConta = (pathParams, options) => mutationFn('put', '/api/v1/salas/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationSalasControllerAtualizarSalaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/salas/{id}/contas/{contaId}', pathParams, {}, options), config)

export const querySalasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/salas/{id}/contas-disponiveis', pathParams, queryParams)
export const useQuerySalasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySalasControllerListarContasDisponiveis.queryKey = '/api/v1/salas/{id}/contas-disponiveis'

export const mutationSalasControllerCadastrarSalaEquipamento = (pathParams, options) => mutationFn('post', '/api/v1/salas/{id}/equipamentos', pathParams, {}, options)
export const useMutationSalasControllerCadastrarSalaEquipamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/salas/{id}/equipamentos', pathParams, {}, options), config)

export const querySalasControllerListarSalaEquipamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/salas/{id}/equipamentos', pathParams, queryParams)
export const useQuerySalasControllerListarSalaEquipamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}/equipamentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySalasControllerListarSalaEquipamentos.queryKey = '/api/v1/salas/{id}/equipamentos'

export const querySalasControllerObterSalaEquipamentoPorId = (pathParams, options) => queryFn(options)('/api/v1/salas/{id}/equipamento/{equipamentoId}', pathParams)
export const useQuerySalasControllerObterSalaEquipamentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}/equipamento/{equipamentoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.equipamentoId, ...config }
})
useQuerySalasControllerObterSalaEquipamentoPorId.queryKey = '/api/v1/salas/{id}/equipamento/{equipamentoId}'

export const mutationSalasControllerAtualizarSalaEquipamento = (pathParams, options) => mutationFn('put', '/api/v1/salas/{id}/equipamentos/{equipamentoId}', pathParams, {}, options)
export const useMutationSalasControllerAtualizarSalaEquipamento = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/salas/{id}/equipamentos/{equipamentoId}', pathParams, {}, options), config)

export const mutationSalasControllerDeletarSalaEquipamento = (pathParams, options) => mutationFn('delete', '/api/v1/salas/{id}equipamento/{equipamentoId}', pathParams, {}, options)
export const useMutationSalasControllerDeletarSalaEquipamento = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/salas/{id}equipamento/{equipamentoId}', pathParams, {}, options), config)

export const querySalasControllerObterSalasFuncionarios = (queryParams, options) => queryFn(options)('/api/v1/salas/funcionarios', queryParams)
export const useQuerySalasControllerObterSalasFuncionarios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/funcionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySalasControllerObterSalasFuncionarios.queryKey = '/api/v1/salas/funcionarios'

export const querySegmentacoesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/segmentacoes', queryParams)
export const useQuerySegmentacoesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySegmentacoesControllerBuscar.queryKey = '/api/v1/segmentacoes'

export const mutationSegmentacoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/segmentacoes', {}, {}, options)
export const useMutationSegmentacoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/segmentacoes', {}, {}, options), config)

export const querySegmentacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/segmentacoes/{id}', pathParams)
export const useQuerySegmentacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySegmentacoesControllerObterPorId.queryKey = '/api/v1/segmentacoes/{id}'

export const mutationSegmentacoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/segmentacoes/{id}', pathParams, {}, options)
export const useMutationSegmentacoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/segmentacoes/{id}', pathParams, {}, options), config)

export const mutationSegmentacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/segmentacoes/{id}', pathParams, {}, options)
export const useMutationSegmentacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/segmentacoes/{id}', pathParams, {}, options), config)

export const mutationSegmentacoesControllerCadastrarSegmentacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/segmentacoes/{id}/contas', pathParams, {}, options)
export const useMutationSegmentacoesControllerCadastrarSegmentacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/segmentacoes/{id}/contas', pathParams, {}, options), config)

export const querySegmentacoesControllerListarSegmentacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/segmentacoes/{id}/contas', pathParams, queryParams)
export const useQuerySegmentacoesControllerListarSegmentacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySegmentacoesControllerListarSegmentacaoContas.queryKey = '/api/v1/segmentacoes/{id}/contas'

export const querySegmentacoesControllerObterSegmentacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/segmentacoes/{id}/contas/{contaId}', pathParams)
export const useQuerySegmentacoesControllerObterSegmentacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQuerySegmentacoesControllerObterSegmentacaoContaPorId.queryKey = '/api/v1/segmentacoes/{id}/contas/{contaId}'

export const mutationSegmentacoesControllerAtualizarSegmentacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/segmentacoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationSegmentacoesControllerAtualizarSegmentacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/segmentacoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const querySegmentacoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/segmentacoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQuerySegmentacoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySegmentacoesControllerListarContasDisponiveis.queryKey = '/api/v1/segmentacoes/{id}/contas-disponiveis'

export const querySegmentacoesContatosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/segmentacoes-contatos', queryParams)
export const useQuerySegmentacoesContatosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes-contatos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySegmentacoesContatosControllerBuscar.queryKey = '/api/v1/segmentacoes-contatos'

export const mutationSegmentacoesContatosControllerCadastrar = (options) => mutationFn('post', '/api/v1/segmentacoes-contatos', {}, {}, options)
export const useMutationSegmentacoesContatosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/segmentacoes-contatos', {}, {}, options), config)

export const querySegmentacoesContatosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/segmentacoes-contatos/{id}', pathParams)
export const useQuerySegmentacoesContatosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes-contatos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySegmentacoesContatosControllerObterPorId.queryKey = '/api/v1/segmentacoes-contatos/{id}'

export const mutationSegmentacoesContatosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/segmentacoes-contatos/{id}', pathParams, {}, options)
export const useMutationSegmentacoesContatosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/segmentacoes-contatos/{id}', pathParams, {}, options), config)

export const mutationSegmentacoesContatosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/segmentacoes-contatos/{id}', pathParams, {}, options)
export const useMutationSegmentacoesContatosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/segmentacoes-contatos/{id}', pathParams, {}, options), config)

export const mutationServicosControllerAtualizarPrecoDeVenda = (pathParams, options) => mutationFn('post', '/api/v1/servicos/{id}/atualizar-preco', pathParams, {}, options)
export const useMutationServicosControllerAtualizarPrecoDeVenda = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/servicos/{id}/atualizar-preco', pathParams, {}, options), config)

export const mutationServicosControllerCadastrar = (options) => mutationFn('post', '/api/v1/servicos', {}, {}, options)
export const useMutationServicosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/servicos', {}, {}, options), config)

export const queryServicosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/servicos', queryParams)
export const useQueryServicosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/servicos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryServicosControllerBuscar.queryKey = '/api/v1/servicos'

export const mutationServicosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/servicos/{id}', pathParams, {}, options)
export const useMutationServicosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/servicos/{id}', pathParams, {}, options), config)

export const mutationServicosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/servicos/{id}', pathParams, {}, options)
export const useMutationServicosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/servicos/{id}', pathParams, {}, options), config)

export const queryServicosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/servicos/{id}', pathParams)
export const useQueryServicosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/servicos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryServicosControllerObterPorId.queryKey = '/api/v1/servicos/{id}'

export const mutationServicosControllerCadastrarResponsavelServico = (pathParams, options) => mutationFn('post', '/api/v1/servicos/{id}/responsaveis', pathParams, {}, options)
export const useMutationServicosControllerCadastrarResponsavelServico = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/servicos/{id}/responsaveis', pathParams, {}, options), config)

export const queryServicosControllerObterResponsavelServicoPorServicoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/servicos/{id}/responsavel', pathParams, queryParams)
export const useQueryServicosControllerObterResponsavelServicoPorServicoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/servicos/{id}/responsavel', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryServicosControllerObterResponsavelServicoPorServicoId.queryKey = '/api/v1/servicos/{id}/responsavel'

export const queryServicosControllerObterPorSigla = (pathParams, options) => queryFn(options)('/api/v1/servicos/{sigla}', pathParams)
export const useQueryServicosControllerObterPorSigla = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/servicos/{sigla}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.sigla, ...config }
})
useQueryServicosControllerObterPorSigla.queryKey = '/api/v1/servicos/{sigla}'

export const mutationServicosControllerCadastrarOrientacao = (pathParams, options) => mutationFn('post', '/api/v1/servicos/{id}/orientacoes', pathParams, {}, options)
export const useMutationServicosControllerCadastrarOrientacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/servicos/{id}/orientacoes', pathParams, {}, options), config)

export const queryServicosControllerListarOrientacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/servicos/{id}/orientacoes', pathParams, queryParams)
export const useQueryServicosControllerListarOrientacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/servicos/{id}/orientacoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryServicosControllerListarOrientacoes.queryKey = '/api/v1/servicos/{id}/orientacoes'

export const querySituacoesAgendaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-da-agenda', queryParams)
export const useQuerySituacoesAgendaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-da-agenda', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesAgendaControllerBuscar.queryKey = '/api/v1/situacoes-da-agenda'

export const mutationSituacoesAgendaControllerCadastrar = (options) => mutationFn('post', '/api/v1/situacoes-da-agenda', {}, {}, options)
export const useMutationSituacoesAgendaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/situacoes-da-agenda', {}, {}, options), config)

export const querySituacoesAgendaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-da-agenda/{id}', pathParams)
export const useQuerySituacoesAgendaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-da-agenda/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesAgendaControllerObterPorId.queryKey = '/api/v1/situacoes-da-agenda/{id}'

export const mutationSituacoesAgendaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/situacoes-da-agenda/{id}', pathParams, {}, options)
export const useMutationSituacoesAgendaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/situacoes-da-agenda/{id}', pathParams, {}, options), config)

export const mutationSituacoesAgendaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/situacoes-da-agenda/{id}', pathParams, {}, options)
export const useMutationSituacoesAgendaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/situacoes-da-agenda/{id}', pathParams, {}, options), config)

export const querySituacoesAgendamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-de-agendamento', queryParams)
export const useQuerySituacoesAgendamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-de-agendamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesAgendamentoControllerListar.queryKey = '/api/v1/situacoes-de-agendamento'

export const querySituacoesAgendamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-de-agendamento/{id}', pathParams)
export const useQuerySituacoesAgendamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-de-agendamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesAgendamentoControllerObterPorId.queryKey = '/api/v1/situacoes-de-agendamento/{id}'

export const mutationSituacoesAgendamentosTagsControllerCadastrar = (options) => mutationFn('post', '/api/v1/situacoes-agendamentos-tags', {}, {}, options)
export const useMutationSituacoesAgendamentosTagsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/situacoes-agendamentos-tags', {}, {}, options), config)

export const querySituacoesAgendamentosTagsControllerListar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-agendamentos-tags', queryParams)
export const useQuerySituacoesAgendamentosTagsControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-agendamentos-tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesAgendamentosTagsControllerListar.queryKey = '/api/v1/situacoes-agendamentos-tags'

export const querySituacoesAgendamentosTagsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-agendamentos-tags/{id}', pathParams)
export const useQuerySituacoesAgendamentosTagsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-agendamentos-tags/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesAgendamentosTagsControllerObterPorId.queryKey = '/api/v1/situacoes-agendamentos-tags/{id}'

export const mutationSituacoesAgendamentosTagsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/situacoes-agendamentos-tags/{id}', pathParams, {}, options)
export const useMutationSituacoesAgendamentosTagsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/situacoes-agendamentos-tags/{id}', pathParams, {}, options), config)

export const mutationSituacoesAgendamentosTagsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/situacoes-agendamentos-tags/{id}', pathParams, {}, options)
export const useMutationSituacoesAgendamentosTagsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/situacoes-agendamentos-tags/{id}', pathParams, {}, options), config)

export const querySituacoesCadastraisControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-cadastrais', queryParams)
export const useQuerySituacoesCadastraisControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-cadastrais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesCadastraisControllerBuscar.queryKey = '/api/v1/situacoes-cadastrais'

export const querySituacoesCadastraisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-cadastrais/{id}', pathParams)
export const useQuerySituacoesCadastraisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-cadastrais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesCadastraisControllerObterPorId.queryKey = '/api/v1/situacoes-cadastrais/{id}'

export const querySituacoesCadastraisControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-cadastrais/{chave}', pathParams)
export const useQuerySituacoesCadastraisControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-cadastrais/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesCadastraisControllerObterPorChave.queryKey = '/api/v1/situacoes-cadastrais/{chave}'

export const querySituacoesDocumentoEntradaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-documento-entrada', queryParams)
export const useQuerySituacoesDocumentoEntradaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-entrada', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesDocumentoEntradaControllerBuscar.queryKey = '/api/v1/situacoes-documento-entrada'

export const querySituacoesDocumentoEntradaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-documento-entrada/{id}', pathParams)
export const useQuerySituacoesDocumentoEntradaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-entrada/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesDocumentoEntradaControllerObterPorId.queryKey = '/api/v1/situacoes-documento-entrada/{id}'

export const querySituacoesDocumentoEntradaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-documento-entrada/{chave}', pathParams)
export const useQuerySituacoesDocumentoEntradaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-entrada/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesDocumentoEntradaControllerObterPorChave.queryKey = '/api/v1/situacoes-documento-entrada/{chave}'

export const querySituacoesDocumentoSaidaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-documento-saida', queryParams)
export const useQuerySituacoesDocumentoSaidaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-saida', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesDocumentoSaidaControllerBuscar.queryKey = '/api/v1/situacoes-documento-saida'

export const querySituacoesDocumentoSaidaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-documento-saida/{id}', pathParams)
export const useQuerySituacoesDocumentoSaidaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-saida/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesDocumentoSaidaControllerObterPorId.queryKey = '/api/v1/situacoes-documento-saida/{id}'

export const querySituacoesDocumentoSaidaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-documento-saida/{chave}', pathParams)
export const useQuerySituacoesDocumentoSaidaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-saida/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesDocumentoSaidaControllerObterPorChave.queryKey = '/api/v1/situacoes-documento-saida/{chave}'

export const querySituacoesMovimentoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-movimento', queryParams)
export const useQuerySituacoesMovimentoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-movimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesMovimentoControllerBuscar.queryKey = '/api/v1/situacoes-movimento'

export const querySituacoesMovimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-movimento/{id}', pathParams)
export const useQuerySituacoesMovimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-movimento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesMovimentoControllerObterPorId.queryKey = '/api/v1/situacoes-movimento/{id}'

export const querySituacoesMovimentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-movimento/{chave}', pathParams)
export const useQuerySituacoesMovimentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-movimento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesMovimentoControllerObterPorChave.queryKey = '/api/v1/situacoes-movimento/{chave}'

export const querySituacoesNfseControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-nfse', queryParams)
export const useQuerySituacoesNfseControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-nfse', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesNfseControllerBuscar.queryKey = '/api/v1/situacoes-nfse'

export const querySituacoesNfseControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-nfse/{id}', pathParams)
export const useQuerySituacoesNfseControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-nfse/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesNfseControllerObterPorId.queryKey = '/api/v1/situacoes-nfse/{id}'

export const querySituacoesNfseControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-nfse/{chave}', pathParams)
export const useQuerySituacoesNfseControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-nfse/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesNfseControllerObterPorChave.queryKey = '/api/v1/situacoes-nfse/{chave}'

export const querySmsControllerListar = (queryParams, options) => queryFn(options)('/api/v1/sms', queryParams)
export const useQuerySmsControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sms', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySmsControllerListar.queryKey = '/api/v1/sms'

export const mutationSmsControllerCadastrar = (options) => mutationFn('post', '/api/v1/sms', {}, {}, options)
export const useMutationSmsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/sms', {}, {}, options), config)

export const querySmsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/sms/{id}', pathParams)
export const useQuerySmsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sms/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySmsControllerObterPorId.queryKey = '/api/v1/sms/{id}'

export const querySmsTemplatesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/sms-templates', queryParams)
export const useQuerySmsTemplatesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sms-templates', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySmsTemplatesControllerListar.queryKey = '/api/v1/sms-templates'

export const mutationSmsTemplatesControllerCadastrar = (options) => mutationFn('post', '/api/v1/sms-templates', {}, {}, options)
export const useMutationSmsTemplatesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/sms-templates', {}, {}, options), config)

export const mutationSmsTemplatesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/sms-templates/{id}', pathParams, {}, options)
export const useMutationSmsTemplatesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sms-templates/{id}', pathParams, {}, options), config)

export const mutationSmsTemplatesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/sms-templates/{id}', pathParams, {}, options)
export const useMutationSmsTemplatesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/sms-templates/{id}', pathParams, {}, options), config)

export const querySmsTemplatesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/sms-templates/{id}', pathParams)
export const useQuerySmsTemplatesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sms-templates/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySmsTemplatesControllerObterPorId.queryKey = '/api/v1/sms-templates/{id}'

export const queryStatusBaixaTituloCartaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/status-baixa-titulo-cartao', queryParams)
export const useQueryStatusBaixaTituloCartaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-baixa-titulo-cartao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryStatusBaixaTituloCartaoControllerBuscar.queryKey = '/api/v1/status-baixa-titulo-cartao'

export const queryStatusBaixaTituloCartaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/status-baixa-titulo-cartao/{id}', pathParams)
export const useQueryStatusBaixaTituloCartaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-baixa-titulo-cartao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryStatusBaixaTituloCartaoControllerObterPorId.queryKey = '/api/v1/status-baixa-titulo-cartao/{id}'

export const queryStatusBaixaTituloCartaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/status-baixa-titulo-cartao/{chave}', pathParams)
export const useQueryStatusBaixaTituloCartaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-baixa-titulo-cartao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryStatusBaixaTituloCartaoControllerObterPorChave.queryKey = '/api/v1/status-baixa-titulo-cartao/{chave}'

export const mutationStatusExameControllerCadastrar = (options) => mutationFn('post', '/api/v1/status-de-exame', {}, {}, options)
export const useMutationStatusExameControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/status-de-exame', {}, {}, options), config)

export const queryStatusExameControllerListar = (queryParams, options) => queryFn(options)('/api/v1/status-de-exame', queryParams)
export const useQueryStatusExameControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-de-exame', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryStatusExameControllerListar.queryKey = '/api/v1/status-de-exame'

export const queryStatusExameControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/status-de-exame/{id}', pathParams)
export const useQueryStatusExameControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-de-exame/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryStatusExameControllerObterPorId.queryKey = '/api/v1/status-de-exame/{id}'

export const mutationStatusExameControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/status-de-exame/{id}', pathParams, {}, options)
export const useMutationStatusExameControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/status-de-exame/{id}', pathParams, {}, options), config)

export const mutationStatusExameControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/status-de-exame/{id}', pathParams, {}, options)
export const useMutationStatusExameControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/status-de-exame/{id}', pathParams, {}, options), config)

export const mutationStatusExameControllerCadastrarStatusExameConta = (pathParams, options) => mutationFn('post', '/api/v1/status-de-exame/{id}/contas', pathParams, {}, options)
export const useMutationStatusExameControllerCadastrarStatusExameConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/status-de-exame/{id}/contas', pathParams, {}, options), config)

export const queryStatusExameControllerListarStatusExameContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/status-de-exame/{id}/contas', pathParams, queryParams)
export const useQueryStatusExameControllerListarStatusExameContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-de-exame/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryStatusExameControllerListarStatusExameContas.queryKey = '/api/v1/status-de-exame/{id}/contas'

export const queryStatusExameControllerObterStatusExameContaPorId = (pathParams, options) => queryFn(options)('/api/v1/status-de-exame/{id}/contas/{contaId}', pathParams)
export const useQueryStatusExameControllerObterStatusExameContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-de-exame/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryStatusExameControllerObterStatusExameContaPorId.queryKey = '/api/v1/status-de-exame/{id}/contas/{contaId}'

export const mutationStatusExameControllerAtualizarStatusExameConta = (pathParams, options) => mutationFn('put', '/api/v1/status-de-exame/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationStatusExameControllerAtualizarStatusExameConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/status-de-exame/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryStatusExameControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/status-de-exame/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryStatusExameControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-de-exame/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryStatusExameControllerListarContasDisponiveis.queryKey = '/api/v1/status-de-exame/{id}/contas-disponiveis'

export const queryStatusTissFaturaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/status-tiss-faturas', queryParams)
export const useQueryStatusTissFaturaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-tiss-faturas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryStatusTissFaturaControllerListar.queryKey = '/api/v1/status-tiss-faturas'

export const queryStatusTissFaturaGuiaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/status-tiss-faturas-guia', queryParams)
export const useQueryStatusTissFaturaGuiaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-tiss-faturas-guia', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryStatusTissFaturaGuiaControllerListar.queryKey = '/api/v1/status-tiss-faturas-guia'

export const mutationTabelasProcedimentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/tabelas-de-procedimento', {}, {}, options)
export const useMutationTabelasProcedimentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tabelas-de-procedimento', {}, {}, options), config)

export const queryTabelasProcedimentoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tabelas-de-procedimento', queryParams)
export const useQueryTabelasProcedimentoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tabelas-de-procedimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTabelasProcedimentoControllerBuscar.queryKey = '/api/v1/tabelas-de-procedimento'

export const mutationTabelasProcedimentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tabelas-de-procedimento/{id}', pathParams, {}, options)
export const useMutationTabelasProcedimentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tabelas-de-procedimento/{id}', pathParams, {}, options), config)

export const mutationTabelasProcedimentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tabelas-de-procedimento/{id}', pathParams, {}, options)
export const useMutationTabelasProcedimentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tabelas-de-procedimento/{id}', pathParams, {}, options), config)

export const queryTabelasProcedimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tabelas-de-procedimento/{id}', pathParams)
export const useQueryTabelasProcedimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tabelas-de-procedimento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTabelasProcedimentoControllerObterPorId.queryKey = '/api/v1/tabelas-de-procedimento/{id}'

export const queryTabelasProcedimentoControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/tabelas-de-procedimento/buscar-detalhado/{id}', pathParams)
export const useQueryTabelasProcedimentoControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tabelas-de-procedimento/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTabelasProcedimentoControllerObterPorIdDetalhado.queryKey = '/api/v1/tabelas-de-procedimento/buscar-detalhado/{id}'

export const mutationTabelasProcedimentoControllerCadastrarTabelaProcedimentoConta = (pathParams, options) => mutationFn('post', '/api/v1/tabelas-de-procedimento/{id}/contas', pathParams, {}, options)
export const useMutationTabelasProcedimentoControllerCadastrarTabelaProcedimentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tabelas-de-procedimento/{id}/contas', pathParams, {}, options), config)

export const queryTabelasProcedimentoControllerListarTabelaProcedimentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tabelas-de-procedimento/{id}/contas', pathParams, queryParams)
export const useQueryTabelasProcedimentoControllerListarTabelaProcedimentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tabelas-de-procedimento/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTabelasProcedimentoControllerListarTabelaProcedimentoContas.queryKey = '/api/v1/tabelas-de-procedimento/{id}/contas'

export const queryTabelasProcedimentoControllerObterTabelaProcedimentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tabelas-de-procedimento/{id}/contas/{contaId}', pathParams)
export const useQueryTabelasProcedimentoControllerObterTabelaProcedimentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tabelas-de-procedimento/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTabelasProcedimentoControllerObterTabelaProcedimentoContaPorId.queryKey = '/api/v1/tabelas-de-procedimento/{id}/contas/{contaId}'

export const mutationTabelasProcedimentoControllerAtualizarTabelaProcedimentoConta = (pathParams, options) => mutationFn('put', '/api/v1/tabelas-de-procedimento/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTabelasProcedimentoControllerAtualizarTabelaProcedimentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tabelas-de-procedimento/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTabelasProcedimentoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tabelas-de-procedimento/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTabelasProcedimentoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tabelas-de-procedimento/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTabelasProcedimentoControllerListarContasDisponiveis.queryKey = '/api/v1/tabelas-de-procedimento/{id}/contas-disponiveis'

export const mutationTagsControllerCadastrar = (options) => mutationFn('post', '/api/v1/tags', {}, {}, options)
export const useMutationTagsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tags', {}, {}, options), config)

export const queryTagsControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tags', queryParams)
export const useQueryTagsControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTagsControllerListar.queryKey = '/api/v1/tags'

export const queryTagsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tags/{id}', pathParams)
export const useQueryTagsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTagsControllerObterPorId.queryKey = '/api/v1/tags/{id}'

export const mutationTagsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tags/{id}', pathParams, {}, options)
export const useMutationTagsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tags/{id}', pathParams, {}, options), config)

export const mutationTagsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tags/{id}', pathParams, {}, options)
export const useMutationTagsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tags/{id}', pathParams, {}, options), config)

export const mutationTagsControllerCadastrarTipoTagTag = (pathParams, options) => mutationFn('post', '/api/v1/tags/{id}/tipos-tag', pathParams, {}, options)
export const useMutationTagsControllerCadastrarTipoTagTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tags/{id}/tipos-tag', pathParams, {}, options), config)

export const queryTagsControllerListarTiposTag = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tags/{id}/tipos-tag', pathParams, queryParams)
export const useQueryTagsControllerListarTiposTag = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags/{id}/tipos-tag', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTagsControllerListarTiposTag.queryKey = '/api/v1/tags/{id}/tipos-tag'

export const mutationTagsControllerDeletarTipoTagTag = (pathParams, options) => mutationFn('delete', '/api/v1/tags/{id}/tipos-tag/{tipoTagId}', pathParams, {}, options)
export const useMutationTagsControllerDeletarTipoTagTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tags/{id}/tipos-tag/{tipoTagId}', pathParams, {}, options), config)

export const mutationTagsControllerCadastrarTagConta = (pathParams, options) => mutationFn('post', '/api/v1/tags/{id}/contas', pathParams, {}, options)
export const useMutationTagsControllerCadastrarTagConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tags/{id}/contas', pathParams, {}, options), config)

export const queryTagsControllerListarTagContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tags/{id}/contas', pathParams, queryParams)
export const useQueryTagsControllerListarTagContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTagsControllerListarTagContas.queryKey = '/api/v1/tags/{id}/contas'

export const queryTagsControllerObterTagContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tags/{id}/contas/{contaId}', pathParams)
export const useQueryTagsControllerObterTagContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTagsControllerObterTagContaPorId.queryKey = '/api/v1/tags/{id}/contas/{contaId}'

export const mutationTagsControllerAtualizarTagConta = (pathParams, options) => mutationFn('put', '/api/v1/tags/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTagsControllerAtualizarTagConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tags/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTagsControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tags/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTagsControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTagsControllerListarContasDisponiveis.queryKey = '/api/v1/tags/{id}/contas-disponiveis'

export const queryTarefaCheckListsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tarefa-check-lists/{id}', pathParams)
export const useQueryTarefaCheckListsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefa-check-lists/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTarefaCheckListsControllerObterPorId.queryKey = '/api/v1/tarefa-check-lists/{id}'

export const mutationTarefaCheckListsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationTarefaCheckListsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tarefa-check-lists/{id}', pathParams, {}, options), config)

export const mutationTarefaCheckListsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationTarefaCheckListsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tarefa-check-lists/{id}', pathParams, {}, options), config)

export const queryTarefasControllerObterTarefas = (queryParams, options) => queryFn(options)('/api/v1/tarefas', queryParams)
export const useQueryTarefasControllerObterTarefas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTarefasControllerObterTarefas.queryKey = '/api/v1/tarefas'

export const mutationTarefasControllerCadastrarTarefa = (options) => mutationFn('post', '/api/v1/tarefas', {}, {}, options)
export const useMutationTarefasControllerCadastrarTarefa = (config, options) => useMutation(mutationFn('post', '/api/v1/tarefas', {}, {}, options), config)

export const queryTarefasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tarefas/{id}', pathParams)
export const useQueryTarefasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTarefasControllerObterPorId.queryKey = '/api/v1/tarefas/{id}'

export const mutationTarefasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tarefas/{id}', pathParams, {}, options)
export const useMutationTarefasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tarefas/{id}', pathParams, {}, options), config)

export const mutationTarefasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tarefas/{id}', pathParams, {}, options)
export const useMutationTarefasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tarefas/{id}', pathParams, {}, options), config)

export const mutationTarefasControllerAtualizarConclusao = (pathParams, options) => mutationFn('put', '/api/v1/tarefas/{id}/conclusao', pathParams, {}, options)
export const useMutationTarefasControllerAtualizarConclusao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tarefas/{id}/conclusao', pathParams, {}, options), config)

export const mutationTarefasControllerAtualizarFaseFunil = (pathParams, options) => mutationFn('put', '/api/v1/tarefas/{id}/mudar-fase', pathParams, {}, options)
export const useMutationTarefasControllerAtualizarFaseFunil = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tarefas/{id}/mudar-fase', pathParams, {}, options), config)

export const queryTarefasControllerBuscarCheckList = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tarefas/{id}/check-lists', pathParams, queryParams)
export const useQueryTarefasControllerBuscarCheckList = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas/{id}/check-lists', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTarefasControllerBuscarCheckList.queryKey = '/api/v1/tarefas/{id}/check-lists'

export const mutationTarefasControllerCadastrarCheckList = (pathParams, options) => mutationFn('post', '/api/v1/tarefas/{id}/check-lists', pathParams, {}, options)
export const useMutationTarefasControllerCadastrarCheckList = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tarefas/{id}/check-lists', pathParams, {}, options), config)

export const mutationTarefasControllerCadastrarTarefaTag = (pathParams, options) => mutationFn('post', '/api/v1/tarefas/{id}/tags', pathParams, {}, options)
export const useMutationTarefasControllerCadastrarTarefaTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tarefas/{id}/tags', pathParams, {}, options), config)

export const queryTarefasControllerListarTagsTarefa = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tarefas/{id}/tags', pathParams, queryParams)
export const useQueryTarefasControllerListarTagsTarefa = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTarefasControllerListarTagsTarefa.queryKey = '/api/v1/tarefas/{id}/tags'

export const mutationTarefasControllerDeletarTarefaTag = (pathParams, options) => mutationFn('delete', '/api/v1/tarefas/{id}/tag/{tagId}', pathParams, {}, options)
export const useMutationTarefasControllerDeletarTarefaTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tarefas/{id}/tag/{tagId}', pathParams, {}, options), config)

export const queryTarefasControllerListarTarefasTags = (queryParams, options) => queryFn(options)('/api/v1/tarefas/tags', queryParams)
export const useQueryTarefasControllerListarTarefasTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTarefasControllerListarTarefasTags.queryKey = '/api/v1/tarefas/tags'

export const queryTarefasControllerListarTagsDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tarefas/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryTarefasControllerListarTagsDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTarefasControllerListarTagsDisponiveis.queryKey = '/api/v1/tarefas/{id}/tags-disponiveis'

export const queryTeleConsultaConfiguracaoControllerBuscar = (options) => queryFn(options)('/api/v1/teleconsulta-configuracao')
export const useQueryTeleConsultaConfiguracaoControllerBuscar = (config, options) => useQuery({
  queryKey: ['/api/v1/teleconsulta-configuracao'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTeleConsultaConfiguracaoControllerBuscar.queryKey = '/api/v1/teleconsulta-configuracao'

export const mutationTeleConsultaConfiguracaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/teleconsulta-configuracao/{id}', pathParams, {}, options)
export const useMutationTeleConsultaConfiguracaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/teleconsulta-configuracao/{id}', pathParams, {}, options), config)

export const queryTemplateNotificacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/templates-notificacao', queryParams)
export const useQueryTemplateNotificacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTemplateNotificacaoControllerBuscar.queryKey = '/api/v1/templates-notificacao'

export const mutationTemplateNotificacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/templates-notificacao', {}, {}, options)
export const useMutationTemplateNotificacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/templates-notificacao', {}, {}, options), config)

export const queryTemplateNotificacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}', pathParams)
export const useQueryTemplateNotificacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerObterPorId.queryKey = '/api/v1/templates-notificacao/{id}'

export const mutationTemplateNotificacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/templates-notificacao/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-notificacao/{id}', pathParams, {}, options), config)

export const mutationTemplateNotificacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/templates-notificacao/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/templates-notificacao/{id}', pathParams, {}, options), config)

export const mutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/templates-notificacao/{id}/contas', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-notificacao/{id}/contas', pathParams, {}, options), config)

export const queryTemplateNotificacaoControllerListarTemplateNotificacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/contas', pathParams, queryParams)
export const useQueryTemplateNotificacaoControllerListarTemplateNotificacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerListarTemplateNotificacaoContas.queryKey = '/api/v1/templates-notificacao/{id}/contas'

export const queryTemplateNotificacaoControllerObterTemplateNotificacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/contas/{contaId}', pathParams)
export const useQueryTemplateNotificacaoControllerObterTemplateNotificacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTemplateNotificacaoControllerObterTemplateNotificacaoContaPorId.queryKey = '/api/v1/templates-notificacao/{id}/contas/{contaId}'

export const mutationTemplateNotificacaoControllerAtualizarTemplateNotificacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/templates-notificacao/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerAtualizarTemplateNotificacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-notificacao/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTemplateNotificacaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTemplateNotificacaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerListarContasDisponiveis.queryKey = '/api/v1/templates-notificacao/{id}/contas-disponiveis'

export const queryTemplateNotificacaoControllerListarTemplateNotificacaoCanaisEnvio = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/canais-envio', pathParams, queryParams)
export const useQueryTemplateNotificacaoControllerListarTemplateNotificacaoCanaisEnvio = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/canais-envio', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerListarTemplateNotificacaoCanaisEnvio.queryKey = '/api/v1/templates-notificacao/{id}/canais-envio'

export const mutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoCanalEnvio = (pathParams, options) => mutationFn('post', '/api/v1/templates-notificacao/{id}/canais-envio', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoCanalEnvio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-notificacao/{id}/canais-envio', pathParams, {}, options), config)

export const queryTemplateNotificacaoControllerListarTemplateNotificacaoCondicoesEnvio = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/condicoes-envio', pathParams, queryParams)
export const useQueryTemplateNotificacaoControllerListarTemplateNotificacaoCondicoesEnvio = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/condicoes-envio', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerListarTemplateNotificacaoCondicoesEnvio.queryKey = '/api/v1/templates-notificacao/{id}/condicoes-envio'

export const mutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoCondicaoEnvio = (pathParams, options) => mutationFn('post', '/api/v1/templates-notificacao/{id}/condicoes-envio', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoCondicaoEnvio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-notificacao/{id}/condicoes-envio', pathParams, {}, options), config)

export const queryTemplateNotificacaoControllerListarTemplateNotificacaoDestinatarios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/destinatarios', pathParams, queryParams)
export const useQueryTemplateNotificacaoControllerListarTemplateNotificacaoDestinatarios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/destinatarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerListarTemplateNotificacaoDestinatarios.queryKey = '/api/v1/templates-notificacao/{id}/destinatarios'

export const mutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoDestinatario = (pathParams, options) => mutationFn('post', '/api/v1/templates-notificacao/{id}/destinatarios', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoDestinatario = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-notificacao/{id}/destinatarios', pathParams, {}, options), config)

export const queryTemplateNotificacaoCanaisEnvioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/template-notificacao-canais-envio/{id}', pathParams)
export const useQueryTemplateNotificacaoCanaisEnvioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/template-notificacao-canais-envio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoCanaisEnvioControllerObterPorId.queryKey = '/api/v1/template-notificacao-canais-envio/{id}'

export const mutationTemplateNotificacaoCanaisEnvioControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/template-notificacao-canais-envio/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoCanaisEnvioControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/template-notificacao-canais-envio/{id}', pathParams, {}, options), config)

export const mutationTemplateNotificacaoCanaisEnvioControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/template-notificacao-canais-envio/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoCanaisEnvioControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/template-notificacao-canais-envio/{id}', pathParams, {}, options), config)

export const queryTemplateNotificacaoCondicoesEnvioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/template-notificacao-condicoes-envio/{id}', pathParams)
export const useQueryTemplateNotificacaoCondicoesEnvioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/template-notificacao-condicoes-envio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoCondicoesEnvioControllerObterPorId.queryKey = '/api/v1/template-notificacao-condicoes-envio/{id}'

export const mutationTemplateNotificacaoCondicoesEnvioControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/template-notificacao-condicoes-envio/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoCondicoesEnvioControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/template-notificacao-condicoes-envio/{id}', pathParams, {}, options), config)

export const mutationTemplateNotificacaoCondicoesEnvioControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/template-notificacao-condicoes-envio/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoCondicoesEnvioControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/template-notificacao-condicoes-envio/{id}', pathParams, {}, options), config)

export const queryTemplateNotificacaoDestinatariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/template-notificacao-destinatarios/{id}', pathParams)
export const useQueryTemplateNotificacaoDestinatariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/template-notificacao-destinatarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoDestinatariosControllerObterPorId.queryKey = '/api/v1/template-notificacao-destinatarios/{id}'

export const mutationTemplateNotificacaoDestinatariosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/template-notificacao-destinatarios/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoDestinatariosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/template-notificacao-destinatarios/{id}', pathParams, {}, options), config)

export const mutationTemplatesComerciaisControllerCadastrarTemplateComercialConta = (pathParams, options) => mutationFn('post', '/api/v1/templates-comerciais/{id}/contas', pathParams, {}, options)
export const useMutationTemplatesComerciaisControllerCadastrarTemplateComercialConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-comerciais/{id}/contas', pathParams, {}, options), config)

export const queryTemplatesComerciaisControllerListarTemplateComercialContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-comerciais/{id}/contas', pathParams, queryParams)
export const useQueryTemplatesComerciaisControllerListarTemplateComercialContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesComerciaisControllerListarTemplateComercialContas.queryKey = '/api/v1/templates-comerciais/{id}/contas'

export const queryTemplatesComerciaisControllerObterTemplateComercialContaPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-comerciais/{id}/contas/{contaId}', pathParams)
export const useQueryTemplatesComerciaisControllerObterTemplateComercialContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTemplatesComerciaisControllerObterTemplateComercialContaPorId.queryKey = '/api/v1/templates-comerciais/{id}/contas/{contaId}'

export const mutationTemplatesComerciaisControllerAtualizarTemplateComercialConta = (pathParams, options) => mutationFn('put', '/api/v1/templates-comerciais/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTemplatesComerciaisControllerAtualizarTemplateComercialConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-comerciais/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTemplatesComerciaisControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-comerciais/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTemplatesComerciaisControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesComerciaisControllerListarContasDisponiveis.queryKey = '/api/v1/templates-comerciais/{id}/contas-disponiveis'

export const mutationTemplatesComerciaisSaudeControllerCadastrar = (options) => mutationFn('post', '/api/v1/templates-comerciais', {}, {}, options)
export const useMutationTemplatesComerciaisSaudeControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/templates-comerciais', {}, {}, options), config)

export const queryTemplatesComerciaisSaudeControllerListar = (queryParams, options) => queryFn(options)('/api/v1/templates-comerciais', queryParams)
export const useQueryTemplatesComerciaisSaudeControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTemplatesComerciaisSaudeControllerListar.queryKey = '/api/v1/templates-comerciais'

export const mutationTemplatesComerciaisSaudeControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/templates-comerciais/{id}', pathParams, {}, options)
export const useMutationTemplatesComerciaisSaudeControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-comerciais/{id}', pathParams, {}, options), config)

export const mutationTemplatesComerciaisSaudeControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/templates-comerciais/{id}', pathParams, {}, options)
export const useMutationTemplatesComerciaisSaudeControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/templates-comerciais/{id}', pathParams, {}, options), config)

export const queryTemplatesComerciaisSaudeControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-comerciais/{id}', pathParams)
export const useQueryTemplatesComerciaisSaudeControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesComerciaisSaudeControllerObterPorId.queryKey = '/api/v1/templates-comerciais/{id}'

export const queryTemplatesComerciaisSaudeControllerBuscarTemplateComercimalItem = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-comerciais/{id}/itens', pathParams, queryParams)
export const useQueryTemplatesComerciaisSaudeControllerBuscarTemplateComercimalItem = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesComerciaisSaudeControllerBuscarTemplateComercimalItem.queryKey = '/api/v1/templates-comerciais/{id}/itens'

export const mutationTemplatesComerciaisSaudeControllerCadastrarTemplateComercimalItem = (pathParams, options) => mutationFn('post', '/api/v1/templates-comerciais/{id}/itens', pathParams, {}, options)
export const useMutationTemplatesComerciaisSaudeControllerCadastrarTemplateComercimalItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-comerciais/{id}/itens', pathParams, {}, options), config)

export const queryTemplatesComercialItensControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-comerciais-itens/{id}', pathParams)
export const useQueryTemplatesComercialItensControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais-itens/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesComercialItensControllerObterPorId.queryKey = '/api/v1/templates-comerciais-itens/{id}'

export const mutationTemplatesComercialItensControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/templates-comerciais-itens/{id}', pathParams, {}, options)
export const useMutationTemplatesComercialItensControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/templates-comerciais-itens/{id}', pathParams, {}, options), config)

export const queryTemplateTarefaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/templates-tarefa', queryParams)
export const useQueryTemplateTarefaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTemplateTarefaControllerBuscar.queryKey = '/api/v1/templates-tarefa'

export const mutationTemplateTarefaControllerCadastrar = (options) => mutationFn('post', '/api/v1/templates-tarefa', {}, {}, options)
export const useMutationTemplateTarefaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/templates-tarefa', {}, {}, options), config)

export const queryTemplateTarefaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-tarefa/{id}', pathParams)
export const useQueryTemplateTarefaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateTarefaControllerObterPorId.queryKey = '/api/v1/templates-tarefa/{id}'

export const mutationTemplateTarefaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/templates-tarefa/{id}', pathParams, {}, options)
export const useMutationTemplateTarefaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-tarefa/{id}', pathParams, {}, options), config)

export const mutationTemplateTarefaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/templates-tarefa/{id}', pathParams, {}, options)
export const useMutationTemplateTarefaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/templates-tarefa/{id}', pathParams, {}, options), config)

export const queryTemplateTarefaControllerListarCheckListsTarefa = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-tarefa/{id}/checklists', pathParams, queryParams)
export const useQueryTemplateTarefaControllerListarCheckListsTarefa = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa/{id}/checklists', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateTarefaControllerListarCheckListsTarefa.queryKey = '/api/v1/templates-tarefa/{id}/checklists'

export const mutationTemplateTarefaControllerCadastrarTemplateTarefaConta = (pathParams, options) => mutationFn('post', '/api/v1/templates-tarefa/{id}/contas', pathParams, {}, options)
export const useMutationTemplateTarefaControllerCadastrarTemplateTarefaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-tarefa/{id}/contas', pathParams, {}, options), config)

export const queryTemplateTarefaControllerListarTemplateTarefaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-tarefa/{id}/contas', pathParams, queryParams)
export const useQueryTemplateTarefaControllerListarTemplateTarefaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateTarefaControllerListarTemplateTarefaContas.queryKey = '/api/v1/templates-tarefa/{id}/contas'

export const queryTemplateTarefaControllerObterTemplateTarefaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-tarefa/{id}/contas/{contaId}', pathParams)
export const useQueryTemplateTarefaControllerObterTemplateTarefaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTemplateTarefaControllerObterTemplateTarefaContaPorId.queryKey = '/api/v1/templates-tarefa/{id}/contas/{contaId}'

export const mutationTemplateTarefaControllerAtualizarTemplateTarefaConta = (pathParams, options) => mutationFn('put', '/api/v1/templates-tarefa/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTemplateTarefaControllerAtualizarTemplateTarefaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-tarefa/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTemplateTarefaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-tarefa/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTemplateTarefaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateTarefaControllerListarContasDisponiveis.queryKey = '/api/v1/templates-tarefa/{id}/contas-disponiveis'

export const queryTemplateTarefaCheckListsControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/template-tarefa-check-lists', queryParams)
export const useQueryTemplateTarefaCheckListsControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/template-tarefa-check-lists', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTemplateTarefaCheckListsControllerBuscar.queryKey = '/api/v1/template-tarefa-check-lists'

export const mutationTemplateTarefaCheckListsControllerCadastrar = (options) => mutationFn('post', '/api/v1/template-tarefa-check-lists', {}, {}, options)
export const useMutationTemplateTarefaCheckListsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/template-tarefa-check-lists', {}, {}, options), config)

export const queryTemplateTarefaCheckListsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/template-tarefa-check-lists/{id}', pathParams)
export const useQueryTemplateTarefaCheckListsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/template-tarefa-check-lists/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateTarefaCheckListsControllerObterPorId.queryKey = '/api/v1/template-tarefa-check-lists/{id}'

export const mutationTemplateTarefaCheckListsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/template-tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationTemplateTarefaCheckListsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/template-tarefa-check-lists/{id}', pathParams, {}, options), config)

export const mutationTemplateTarefaCheckListsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/template-tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationTemplateTarefaCheckListsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/template-tarefa-check-lists/{id}', pathParams, {}, options), config)

export const mutationTempoDuracaoAtividadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/tempo-de-duracao-de-atividades', {}, {}, options)
export const useMutationTempoDuracaoAtividadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tempo-de-duracao-de-atividades', {}, {}, options), config)

export const queryTempoDuracaoAtividadesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades', queryParams)
export const useQueryTempoDuracaoAtividadesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTempoDuracaoAtividadesControllerBuscar.queryKey = '/api/v1/tempo-de-duracao-de-atividades'

export const mutationTempoDuracaoAtividadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams, {}, options)
export const useMutationTempoDuracaoAtividadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams, {}, options), config)

export const mutationTempoDuracaoAtividadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams, {}, options)
export const useMutationTempoDuracaoAtividadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams, {}, options), config)

export const queryTempoDuracaoAtividadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams)
export const useQueryTempoDuracaoAtividadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoDuracaoAtividadesControllerObterPorId.queryKey = '/api/v1/tempo-de-duracao-de-atividades/{id}'

export const queryTempoDuracaoAtividadesControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades/buscar-detalhado/{id}', pathParams)
export const useQueryTempoDuracaoAtividadesControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoDuracaoAtividadesControllerObterPorIdDetalhado.queryKey = '/api/v1/tempo-de-duracao-de-atividades/buscar-detalhado/{id}'

export const mutationTempoDuracaoAtividadesControllerCadastrarTempoDuracaoAtividadeConta = (pathParams, options) => mutationFn('post', '/api/v1/tempo-de-duracao-de-atividades/{id}/contas', pathParams, {}, options)
export const useMutationTempoDuracaoAtividadesControllerCadastrarTempoDuracaoAtividadeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tempo-de-duracao-de-atividades/{id}/contas', pathParams, {}, options), config)

export const queryTempoDuracaoAtividadesControllerListarTempoDuracaoAtividadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades/{id}/contas', pathParams, queryParams)
export const useQueryTempoDuracaoAtividadesControllerListarTempoDuracaoAtividadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoDuracaoAtividadesControllerListarTempoDuracaoAtividadeContas.queryKey = '/api/v1/tempo-de-duracao-de-atividades/{id}/contas'

export const queryTempoDuracaoAtividadesControllerObterTempoDuracaoAtividadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades/{id}/contas/{contaId}', pathParams)
export const useQueryTempoDuracaoAtividadesControllerObterTempoDuracaoAtividadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTempoDuracaoAtividadesControllerObterTempoDuracaoAtividadeContaPorId.queryKey = '/api/v1/tempo-de-duracao-de-atividades/{id}/contas/{contaId}'

export const mutationTempoDuracaoAtividadesControllerAtualizarTempoDuracaoAtividadeConta = (pathParams, options) => mutationFn('put', '/api/v1/tempo-de-duracao-de-atividades/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTempoDuracaoAtividadesControllerAtualizarTempoDuracaoAtividadeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tempo-de-duracao-de-atividades/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTempoDuracaoAtividadesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTempoDuracaoAtividadesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoDuracaoAtividadesControllerListarContasDisponiveis.queryKey = '/api/v1/tempo-de-duracao-de-atividades/{id}/contas-disponiveis'

export const mutationTempoEventosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tempo-de-eventos/{id}', pathParams, {}, options)
export const useMutationTempoEventosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tempo-de-eventos/{id}', pathParams, {}, options), config)

export const mutationTempoEventosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tempo-de-eventos/{id}', pathParams, {}, options)
export const useMutationTempoEventosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tempo-de-eventos/{id}', pathParams, {}, options), config)

export const queryTempoEventosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tempo-de-eventos/{id}', pathParams)
export const useQueryTempoEventosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-eventos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoEventosControllerObterPorId.queryKey = '/api/v1/tempo-de-eventos/{id}'

export const queryTempoEventosControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/tempo-de-eventos/buscar-detalhado/{id}', pathParams)
export const useQueryTempoEventosControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-eventos/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoEventosControllerObterPorIdDetalhado.queryKey = '/api/v1/tempo-de-eventos/buscar-detalhado/{id}'

export const queryTempoExpiracaoLinkPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tempo-expiracao-link-pagamento', queryParams)
export const useQueryTempoExpiracaoLinkPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-expiracao-link-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTempoExpiracaoLinkPagamentoControllerListar.queryKey = '/api/v1/tempo-expiracao-link-pagamento'

export const queryTempoExpiracaoLinkPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tempo-expiracao-link-pagamento/{id}', pathParams)
export const useQueryTempoExpiracaoLinkPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-expiracao-link-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoExpiracaoLinkPagamentoControllerObterPorId.queryKey = '/api/v1/tempo-expiracao-link-pagamento/{id}'

export const queryTempoExpiracaoLinkPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tempo-expiracao-link-pagamento/{chave}', pathParams)
export const useQueryTempoExpiracaoLinkPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-expiracao-link-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTempoExpiracaoLinkPagamentoControllerObterPorChave.queryKey = '/api/v1/tempo-expiracao-link-pagamento/{chave}'

export const queryTemposPrevistosRetornoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tempos-previstos-de-retorno', queryParams)
export const useQueryTemposPrevistosRetornoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempos-previstos-de-retorno', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTemposPrevistosRetornoControllerListar.queryKey = '/api/v1/tempos-previstos-de-retorno'

export const mutationTemposPrevistosRetornoControllerCadastrar = (options) => mutationFn('post', '/api/v1/tempos-previstos-de-retorno', {}, {}, options)
export const useMutationTemposPrevistosRetornoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tempos-previstos-de-retorno', {}, {}, options), config)

export const queryTemposPrevistosRetornoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tempos-previstos-de-retorno/{id}', pathParams)
export const useQueryTemposPrevistosRetornoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempos-previstos-de-retorno/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemposPrevistosRetornoControllerObterPorId.queryKey = '/api/v1/tempos-previstos-de-retorno/{id}'

export const mutationTemposPrevistosRetornoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tempos-previstos-de-retorno/{id}', pathParams, {}, options)
export const useMutationTemposPrevistosRetornoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tempos-previstos-de-retorno/{id}', pathParams, {}, options), config)

export const mutationTemposPrevistosRetornoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tempos-previstos-de-retorno/{id}', pathParams, {}, options)
export const useMutationTemposPrevistosRetornoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tempos-previstos-de-retorno/{id}', pathParams, {}, options), config)

export const queryTenantsControllerObterNecessitaLicencaAdicional = (queryParams, options) => queryFn(options)('/api/v1/tenants/necessita-licenca-adicional', queryParams)
export const useQueryTenantsControllerObterNecessitaLicencaAdicional = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/necessita-licenca-adicional', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTenantsControllerObterNecessitaLicencaAdicional.queryKey = '/api/v1/tenants/necessita-licenca-adicional'

export const queryTenantsControllerBuscarMovimentacoesLicenca = (queryParams, options) => queryFn(options)('/api/v1/tenants/movimentacoes-licenca', queryParams)
export const useQueryTenantsControllerBuscarMovimentacoesLicenca = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/movimentacoes-licenca', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTenantsControllerBuscarMovimentacoesLicenca.queryKey = '/api/v1/tenants/movimentacoes-licenca'

export const queryTenantsControllerMeusModulosFeatures = (options) => queryFn(options)('/api/v1/tenants/eu/modulos-features')
export const useQueryTenantsControllerMeusModulosFeatures = (config, options) => useQuery({
  queryKey: ['/api/v1/tenants/eu/modulos-features'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTenantsControllerMeusModulosFeatures.queryKey = '/api/v1/tenants/eu/modulos-features'

export const queryTenantsControllerObterAppLiberado = (options) => queryFn(options)('/api/v1/tenants/eu/app-liberado')
export const useQueryTenantsControllerObterAppLiberado = (config, options) => useQuery({
  queryKey: ['/api/v1/tenants/eu/app-liberado'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTenantsControllerObterAppLiberado.queryKey = '/api/v1/tenants/eu/app-liberado'

export const queryTerminologiasCodigoDespesaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/terminologias-codigo-despesa', queryParams)
export const useQueryTerminologiasCodigoDespesaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/terminologias-codigo-despesa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTerminologiasCodigoDespesaControllerListar.queryKey = '/api/v1/terminologias-codigo-despesa'

export const queryTerminologiasCodigoDespesaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/terminologias-codigo-despesa/{id}', pathParams)
export const useQueryTerminologiasCodigoDespesaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/terminologias-codigo-despesa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTerminologiasCodigoDespesaControllerObterPorId.queryKey = '/api/v1/terminologias-codigo-despesa/{id}'

export const queryTerminologiasCodigoDespesaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/terminologias-codigo-despesa/{chave}', pathParams)
export const useQueryTerminologiasCodigoDespesaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/terminologias-codigo-despesa/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTerminologiasCodigoDespesaControllerObterPorChave.queryKey = '/api/v1/terminologias-codigo-despesa/{chave}'

export const queryTipoCampoModeloProntuarioControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-campos-modelo-prontuario', queryParams)
export const useQueryTipoCampoModeloProntuarioControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-campos-modelo-prontuario', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTipoCampoModeloProntuarioControllerListar.queryKey = '/api/v1/tipos-de-campos-modelo-prontuario'

export const queryTipoCampoModeloProntuarioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-campos-modelo-prontuario/{id}', pathParams)
export const useQueryTipoCampoModeloProntuarioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-campos-modelo-prontuario/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTipoCampoModeloProntuarioControllerObterPorId.queryKey = '/api/v1/tipos-de-campos-modelo-prontuario/{id}'

export const queryTipoCampoModeloProntuarioControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-campos-modelo-prontuario/{chave}', pathParams)
export const useQueryTipoCampoModeloProntuarioControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-campos-modelo-prontuario/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTipoCampoModeloProntuarioControllerObterPorChave.queryKey = '/api/v1/tipos-de-campos-modelo-prontuario/{chave}'

export const queryTiposAcrescimoDescontoTituloTituloControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-acrescimo-desconto-titulo', queryParams)
export const useQueryTiposAcrescimoDescontoTituloTituloControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-acrescimo-desconto-titulo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposAcrescimoDescontoTituloTituloControllerListar.queryKey = '/api/v1/tipos-de-acrescimo-desconto-titulo'

export const queryTiposAcrescimoDescontoTituloTituloControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-acrescimo-desconto-titulo/{id}', pathParams)
export const useQueryTiposAcrescimoDescontoTituloTituloControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-acrescimo-desconto-titulo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposAcrescimoDescontoTituloTituloControllerObterPorId.queryKey = '/api/v1/tipos-de-acrescimo-desconto-titulo/{id}'

export const queryTiposAcrescimoDescontoTituloTituloControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-acrescimo-desconto-titulo/{chave}', pathParams)
export const useQueryTiposAcrescimoDescontoTituloTituloControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-acrescimo-desconto-titulo/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposAcrescimoDescontoTituloTituloControllerObterPorChave.queryKey = '/api/v1/tipos-de-acrescimo-desconto-titulo/{chave}'

export const queryTiposAgendamentosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-agendamentos', queryParams)
export const useQueryTiposAgendamentosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-agendamentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposAgendamentosControllerBuscar.queryKey = '/api/v1/tipos-agendamentos'

export const mutationTiposAgendamentosControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-agendamentos', {}, {}, options)
export const useMutationTiposAgendamentosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-agendamentos', {}, {}, options), config)

export const queryTiposAgendamentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-agendamentos/{id}', pathParams)
export const useQueryTiposAgendamentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-agendamentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposAgendamentosControllerObterPorId.queryKey = '/api/v1/tipos-agendamentos/{id}'

export const mutationTiposAgendamentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-agendamentos/{id}', pathParams, {}, options)
export const useMutationTiposAgendamentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-agendamentos/{id}', pathParams, {}, options), config)

export const mutationTiposAgendamentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-agendamentos/{id}', pathParams, {}, options)
export const useMutationTiposAgendamentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-agendamentos/{id}', pathParams, {}, options), config)

export const mutationTiposAgendamentosControllerCadastrarTipoAgendamentoConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-agendamentos/{id}/contas', pathParams, {}, options)
export const useMutationTiposAgendamentosControllerCadastrarTipoAgendamentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-agendamentos/{id}/contas', pathParams, {}, options), config)

export const queryTiposAgendamentosControllerListarTipoAgendamentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-agendamentos/{id}/contas', pathParams, queryParams)
export const useQueryTiposAgendamentosControllerListarTipoAgendamentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-agendamentos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposAgendamentosControllerListarTipoAgendamentoContas.queryKey = '/api/v1/tipos-agendamentos/{id}/contas'

export const queryTiposAgendamentosControllerObterTipoAgendamentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-agendamentos/{id}/contas/{contaId}', pathParams)
export const useQueryTiposAgendamentosControllerObterTipoAgendamentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-agendamentos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposAgendamentosControllerObterTipoAgendamentoContaPorId.queryKey = '/api/v1/tipos-agendamentos/{id}/contas/{contaId}'

export const mutationTiposAgendamentosControllerAtualizarTipoAgendamentoConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-agendamentos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposAgendamentosControllerAtualizarTipoAgendamentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-agendamentos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposAgendamentosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-agendamentos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposAgendamentosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-agendamentos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposAgendamentosControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-agendamentos/{id}/contas-disponiveis'

export const queryTiposCalculoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-calculo', queryParams)
export const useQueryTiposCalculoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCalculoControllerListar.queryKey = '/api/v1/tipos-de-calculo'

export const queryTiposCalculoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo/{id}', pathParams)
export const useQueryTiposCalculoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCalculoControllerObterPorId.queryKey = '/api/v1/tipos-de-calculo/{id}'

export const queryTiposCalculoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo/{chave}', pathParams)
export const useQueryTiposCalculoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposCalculoControllerObterPorChave.queryKey = '/api/v1/tipos-de-calculo/{chave}'

export const queryTiposCalculoComissaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-de-comissao', queryParams)
export const useQueryTiposCalculoComissaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-de-comissao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCalculoComissaoControllerBuscar.queryKey = '/api/v1/tipos-de-calculo-de-comissao'

export const queryTiposCalculoComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-de-comissao/{id}', pathParams)
export const useQueryTiposCalculoComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCalculoComissaoControllerObterPorId.queryKey = '/api/v1/tipos-de-calculo-de-comissao/{id}'

export const queryTiposCalculoComissaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-de-comissao/{chave}', pathParams)
export const useQueryTiposCalculoComissaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-de-comissao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposCalculoComissaoControllerObterPorChave.queryKey = '/api/v1/tipos-de-calculo-de-comissao/{chave}'

export const queryTiposCalculoCustoComposicaoItemControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item', queryParams)
export const useQueryTiposCalculoCustoComposicaoItemControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCalculoCustoComposicaoItemControllerBuscar.queryKey = '/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item'

export const queryTiposCalculoCustoComposicaoItemControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{id}', pathParams)
export const useQueryTiposCalculoCustoComposicaoItemControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCalculoCustoComposicaoItemControllerObterPorId.queryKey = '/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{id}'

export const queryTiposCalculoCustoComposicaoItemControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{chave}', pathParams)
export const useQueryTiposCalculoCustoComposicaoItemControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposCalculoCustoComposicaoItemControllerObterPorChave.queryKey = '/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{chave}'

export const queryTiposCamposControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipo-campo', queryParams)
export const useQueryTiposCamposControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipo-campo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCamposControllerBuscar.queryKey = '/api/v1/tipo-campo'

export const queryTiposClienteControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-cliente', queryParams)
export const useQueryTiposClienteControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-cliente', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposClienteControllerBuscar.queryKey = '/api/v1/tipos-de-cliente'

export const queryTiposClienteControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-cliente/{id}', pathParams)
export const useQueryTiposClienteControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-cliente/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposClienteControllerObterPorId.queryKey = '/api/v1/tipos-de-cliente/{id}'

export const queryTiposClienteControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-cliente/{chave}', pathParams)
export const useQueryTiposClienteControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-cliente/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposClienteControllerObterPorChave.queryKey = '/api/v1/tipos-de-cliente/{chave}'

export const queryTiposComissaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-comissao', queryParams)
export const useQueryTiposComissaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-comissao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposComissaoControllerBuscar.queryKey = '/api/v1/tipos-de-comissao'

export const queryTiposComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-comissao/{id}', pathParams)
export const useQueryTiposComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposComissaoControllerObterPorId.queryKey = '/api/v1/tipos-de-comissao/{id}'

export const queryTiposComissaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-comissao/{chave}', pathParams)
export const useQueryTiposComissaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-comissao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposComissaoControllerObterPorChave.queryKey = '/api/v1/tipos-de-comissao/{chave}'

export const queryTiposCondicaoPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-condicao-pagamento', queryParams)
export const useQueryTiposCondicaoPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-condicao-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCondicaoPagamentoControllerListar.queryKey = '/api/v1/tipos-de-condicao-pagamento'

export const queryTiposCondicaoPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-condicao-pagamento/{id}', pathParams)
export const useQueryTiposCondicaoPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-condicao-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCondicaoPagamentoControllerObterPorId.queryKey = '/api/v1/tipos-de-condicao-pagamento/{id}'

export const queryTiposCondicaoPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-condicao-pagamento/{chave}', pathParams)
export const useQueryTiposCondicaoPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-condicao-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposCondicaoPagamentoControllerObterPorChave.queryKey = '/api/v1/tipos-de-condicao-pagamento/{chave}'

export const queryTiposContaBancariaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-conta-bancaria', queryParams)
export const useQueryTiposContaBancariaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-conta-bancaria', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposContaBancariaControllerListar.queryKey = '/api/v1/tipos-conta-bancaria'

export const mutationTiposContatoControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-de-contato', {}, {}, options)
export const useMutationTiposContatoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-contato', {}, {}, options), config)

export const queryTiposContatoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-contato', queryParams)
export const useQueryTiposContatoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposContatoControllerListar.queryKey = '/api/v1/tipos-de-contato'

export const queryTiposContatoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-contato/{id}', pathParams)
export const useQueryTiposContatoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposContatoControllerObterPorId.queryKey = '/api/v1/tipos-de-contato/{id}'

export const mutationTiposContatoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options)
export const useMutationTiposContatoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options), config)

export const mutationTiposContatoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options)
export const useMutationTiposContatoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options), config)

export const mutationTiposContatoControllerCadastrarTipoContatoConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-contato/{id}/contas', pathParams, {}, options)
export const useMutationTiposContatoControllerCadastrarTipoContatoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-contato/{id}/contas', pathParams, {}, options), config)

export const queryTiposContatoControllerListarTipoContatoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-contato/{id}/contas', pathParams, queryParams)
export const useQueryTiposContatoControllerListarTipoContatoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposContatoControllerListarTipoContatoContas.queryKey = '/api/v1/tipos-de-contato/{id}/contas'

export const queryTiposContatoControllerObterTipoContatoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-contato/{id}/contas/{contaId}', pathParams)
export const useQueryTiposContatoControllerObterTipoContatoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposContatoControllerObterTipoContatoContaPorId.queryKey = '/api/v1/tipos-de-contato/{id}/contas/{contaId}'

export const mutationTiposContatoControllerAtualizarTipoContatoConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-contato/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposContatoControllerAtualizarTipoContatoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-contato/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposContatoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-contato/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposContatoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposContatoControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-de-contato/{id}/contas-disponiveis'

export const queryTiposControleFormaPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-controle-da-forma-de-pagamento', queryParams)
export const useQueryTiposControleFormaPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-controle-da-forma-de-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposControleFormaPagamentoControllerListar.queryKey = '/api/v1/tipos-de-controle-da-forma-de-pagamento'

export const queryTiposControleFormaPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-controle-da-forma-de-pagamento/{id}', pathParams)
export const useQueryTiposControleFormaPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-controle-da-forma-de-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposControleFormaPagamentoControllerObterPorId.queryKey = '/api/v1/tipos-de-controle-da-forma-de-pagamento/{id}'

export const queryTiposControleFormaPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-controle-da-forma-de-pagamento/{chave}', pathParams)
export const useQueryTiposControleFormaPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-controle-da-forma-de-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposControleFormaPagamentoControllerObterPorChave.queryKey = '/api/v1/tipos-de-controle-da-forma-de-pagamento/{chave}'

export const queryTiposConversaoEmbalagemControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-conversao-de-embalagem', queryParams)
export const useQueryTiposConversaoEmbalagemControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-conversao-de-embalagem', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposConversaoEmbalagemControllerBuscar.queryKey = '/api/v1/tipos-de-conversao-de-embalagem'

export const queryTiposConversaoEmbalagemControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-conversao-de-embalagem/{id}', pathParams)
export const useQueryTiposConversaoEmbalagemControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-conversao-de-embalagem/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposConversaoEmbalagemControllerObterPorId.queryKey = '/api/v1/tipos-de-conversao-de-embalagem/{id}'

export const queryTiposConversaoEmbalagemControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-conversao-de-embalagem/{chave}', pathParams)
export const useQueryTiposConversaoEmbalagemControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-conversao-de-embalagem/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposConversaoEmbalagemControllerObterPorChave.queryKey = '/api/v1/tipos-de-conversao-de-embalagem/{chave}'

export const queryTiposCustoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-custo', queryParams)
export const useQueryTiposCustoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-custo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCustoControllerBuscar.queryKey = '/api/v1/tipos-de-custo'

export const queryTiposCustoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-custo/{id}', pathParams)
export const useQueryTiposCustoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-custo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCustoControllerObterPorId.queryKey = '/api/v1/tipos-de-custo/{id}'

export const queryTiposCustoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-custo/{chave}', pathParams)
export const useQueryTiposCustoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-custo/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposCustoControllerObterPorChave.queryKey = '/api/v1/tipos-de-custo/{chave}'

export const queryTiposDependenteControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-dependente', queryParams)
export const useQueryTiposDependenteControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-dependente', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposDependenteControllerBuscar.queryKey = '/api/v1/tipos-de-dependente'

export const queryTiposDependenteControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-dependente/{id}', pathParams)
export const useQueryTiposDependenteControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-dependente/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDependenteControllerObterPorId.queryKey = '/api/v1/tipos-de-dependente/{id}'

export const queryTiposDependenteControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-dependente/{chave}', pathParams)
export const useQueryTiposDependenteControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-dependente/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposDependenteControllerObterPorChave.queryKey = '/api/v1/tipos-de-dependente/{chave}'

export const queryTiposDescontoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-desconto', queryParams)
export const useQueryTiposDescontoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-desconto', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposDescontoControllerListar.queryKey = '/api/v1/tipos-de-desconto'

export const queryTiposDescontoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-desconto/{id}', pathParams)
export const useQueryTiposDescontoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-desconto/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDescontoControllerObterPorId.queryKey = '/api/v1/tipos-de-desconto/{id}'

export const queryTiposDescontoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-desconto/{chave}', pathParams)
export const useQueryTiposDescontoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-desconto/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposDescontoControllerObterPorChave.queryKey = '/api/v1/tipos-de-desconto/{chave}'

export const queryTiposDocumentoControllerObterListaDeTipoDocumento = (options) => queryFn(options)('/api/v1/tipos-de-documento')
export const useQueryTiposDocumentoControllerObterListaDeTipoDocumento = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documento'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposDocumentoControllerObterListaDeTipoDocumento.queryKey = '/api/v1/tipos-de-documento'

export const queryTiposDocumentoControllerObterTipoDocumentoPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-documento/{id}', pathParams)
export const useQueryTiposDocumentoControllerObterTipoDocumentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDocumentoControllerObterTipoDocumentoPorId.queryKey = '/api/v1/tipos-de-documento/{id}'

export const queryTiposDocumentosFiscaisControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-documentos-fiscais', queryParams)
export const useQueryTiposDocumentosFiscaisControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documentos-fiscais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposDocumentosFiscaisControllerListar.queryKey = '/api/v1/tipos-de-documentos-fiscais'

export const queryTiposDocumentosFiscaisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-documentos-fiscais/{id}', pathParams)
export const useQueryTiposDocumentosFiscaisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documentos-fiscais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDocumentosFiscaisControllerObterPorId.queryKey = '/api/v1/tipos-de-documentos-fiscais/{id}'

export const queryTiposDocumentosFiscaisControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-documentos-fiscais/{chave}', pathParams)
export const useQueryTiposDocumentosFiscaisControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documentos-fiscais/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposDocumentosFiscaisControllerObterPorChave.queryKey = '/api/v1/tipos-de-documentos-fiscais/{chave}'

export const queryTiposEmailControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-email', queryParams)
export const useQueryTiposEmailControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-email', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposEmailControllerListar.queryKey = '/api/v1/tipos-de-email'

export const queryTiposEnderecoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-endereco', queryParams)
export const useQueryTiposEnderecoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-endereco', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposEnderecoControllerBuscar.queryKey = '/api/v1/tipos-de-endereco'

export const queryTiposEntidadeControllerObterListaTiposEntidades = (options) => queryFn(options)('/api/v1/tipos-entidades')
export const useQueryTiposEntidadeControllerObterListaTiposEntidades = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-entidades'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposEntidadeControllerObterListaTiposEntidades.queryKey = '/api/v1/tipos-entidades'

export const queryTiposEntidadeControllerObterTipoEntidadesPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-entidades/{id}', pathParams)
export const useQueryTiposEntidadeControllerObterTipoEntidadesPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-entidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposEntidadeControllerObterTipoEntidadesPorId.queryKey = '/api/v1/tipos-entidades/{id}'

export const queryTiposEntidadeControllerObterTipoEntidadesPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-entidades/{chave}', pathParams)
export const useQueryTiposEntidadeControllerObterTipoEntidadesPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-entidades/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposEntidadeControllerObterTipoEntidadesPorChave.queryKey = '/api/v1/tipos-entidades/{chave}'

export const queryTiposEntidadeControllerObterCamposPorChaveTipoEntidade = (pathParams, options) => queryFn(options)('/api/v1/tipos-entidades/{chave}/campos', pathParams)
export const useQueryTiposEntidadeControllerObterCamposPorChaveTipoEntidade = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-entidades/{chave}/campos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposEntidadeControllerObterCamposPorChaveTipoEntidade.queryKey = '/api/v1/tipos-entidades/{chave}/campos'

export const queryTiposEntidadeControllerListarValoresPorTipoEntidadeChaveGrupoCadastroPersonalizadoId = (pathParams, options) => queryFn(options)('/api/v1/tipos-entidades/{chave}/{id}', pathParams)
export const useQueryTiposEntidadeControllerListarValoresPorTipoEntidadeChaveGrupoCadastroPersonalizadoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-entidades/{chave}/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave && pathParams.id, ...config }
})
useQueryTiposEntidadeControllerListarValoresPorTipoEntidadeChaveGrupoCadastroPersonalizadoId.queryKey = '/api/v1/tipos-entidades/{chave}/{id}'

export const mutationTiposEntidadeControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-entidades/{chave}/{id}', pathParams, {}, options)
export const useMutationTiposEntidadeControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-entidades/{chave}/{id}', pathParams, {}, options), config)

export const queryTiposFuncionariosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-funcionarios', queryParams)
export const useQueryTiposFuncionariosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-funcionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposFuncionariosControllerListar.queryKey = '/api/v1/tipos-funcionarios'

export const mutationTiposFuncionariosControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-funcionarios', {}, {}, options)
export const useMutationTiposFuncionariosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-funcionarios', {}, {}, options), config)

export const queryTiposFuncionariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-funcionarios/{id}', pathParams)
export const useQueryTiposFuncionariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-funcionarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposFuncionariosControllerObterPorId.queryKey = '/api/v1/tipos-funcionarios/{id}'

export const mutationTiposFuncionariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-funcionarios/{id}', pathParams, {}, options)
export const useMutationTiposFuncionariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-funcionarios/{id}', pathParams, {}, options), config)

export const mutationTiposFuncionariosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-funcionarios/{id}', pathParams, {}, options)
export const useMutationTiposFuncionariosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-funcionarios/{id}', pathParams, {}, options), config)

export const queryTiposFuncionariosControllerListarTipoFuncionarioContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-funcionarios/{id}/contas', pathParams, queryParams)
export const useQueryTiposFuncionariosControllerListarTipoFuncionarioContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-funcionarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposFuncionariosControllerListarTipoFuncionarioContas.queryKey = '/api/v1/tipos-funcionarios/{id}/contas'

export const mutationTiposFuncionariosControllerAtualizarTipoFuncionarioConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-funcionarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposFuncionariosControllerAtualizarTipoFuncionarioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-funcionarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposFuncionariosControllerObterTipoFuncionarioContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-funcionarios/{id}/contas/{contaId}', pathParams)
export const useQueryTiposFuncionariosControllerObterTipoFuncionarioContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-funcionarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposFuncionariosControllerObterTipoFuncionarioContaPorId.queryKey = '/api/v1/tipos-funcionarios/{id}/contas/{contaId}'

export const queryTiposFuncionariosControllerListarFuncionariosPorTipoFuncionarioChave = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-funcionarios/{chave}/funcionarios', pathParams, queryParams)
export const useQueryTiposFuncionariosControllerListarFuncionariosPorTipoFuncionarioChave = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-funcionarios/{chave}/funcionarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposFuncionariosControllerListarFuncionariosPorTipoFuncionarioChave.queryKey = '/api/v1/tipos-funcionarios/{chave}/funcionarios'

export const queryTiposFuncionariosControllerObterUsuariosPorTipoFuncionario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-funcionarios/{id}/usuarios', pathParams, queryParams)
export const useQueryTiposFuncionariosControllerObterUsuariosPorTipoFuncionario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-funcionarios/{id}/usuarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposFuncionariosControllerObterUsuariosPorTipoFuncionario.queryKey = '/api/v1/tipos-funcionarios/{id}/usuarios'

export const queryTiposGrupoExameControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-grupo-de-exame', queryParams)
export const useQueryTiposGrupoExameControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-grupo-de-exame', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposGrupoExameControllerListar.queryKey = '/api/v1/tipos-de-grupo-de-exame'

export const queryTiposGrupoExameControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-grupo-de-exame/{id}', pathParams)
export const useQueryTiposGrupoExameControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-grupo-de-exame/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposGrupoExameControllerObterPorId.queryKey = '/api/v1/tipos-de-grupo-de-exame/{id}'

export const queryTiposGrupoExameControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-grupo-de-exame/{chave}', pathParams)
export const useQueryTiposGrupoExameControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-grupo-de-exame/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposGrupoExameControllerObterPorChave.queryKey = '/api/v1/tipos-de-grupo-de-exame/{chave}'

export const queryTiposGruposDestinatarioNotificacoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-grupos-destinatarios', queryParams)
export const useQueryTiposGruposDestinatarioNotificacoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-grupos-destinatarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposGruposDestinatarioNotificacoesControllerListar.queryKey = '/api/v1/tipos-grupos-destinatarios'

export const queryTiposGruposDestinatarioNotificacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-grupos-destinatarios/{id}', pathParams)
export const useQueryTiposGruposDestinatarioNotificacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-grupos-destinatarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposGruposDestinatarioNotificacoesControllerObterPorId.queryKey = '/api/v1/tipos-grupos-destinatarios/{id}'

export const queryTiposGruposDestinatarioNotificacoesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-grupos-destinatarios/{chave}', pathParams)
export const useQueryTiposGruposDestinatarioNotificacoesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-grupos-destinatarios/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposGruposDestinatarioNotificacoesControllerObterPorChave.queryKey = '/api/v1/tipos-grupos-destinatarios/{chave}'

export const queryTiposGuiaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-guia', queryParams)
export const useQueryTiposGuiaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-guia', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposGuiaControllerBuscar.queryKey = '/api/v1/tipos-guia'

export const queryTiposGuiaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-guia/{id}', pathParams)
export const useQueryTiposGuiaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-guia/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposGuiaControllerObterPorId.queryKey = '/api/v1/tipos-guia/{id}'

export const queryTiposGuiaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-guia/{chave}', pathParams)
export const useQueryTiposGuiaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-guia/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposGuiaControllerObterPorChave.queryKey = '/api/v1/tipos-guia/{chave}'

export const queryTiposLogradouroControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-logradouro', queryParams)
export const useQueryTiposLogradouroControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-logradouro', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposLogradouroControllerBuscar.queryKey = '/api/v1/tipos-de-logradouro'

export const queryTiposLogradouroControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-logradouro/{id}', pathParams)
export const useQueryTiposLogradouroControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-logradouro/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposLogradouroControllerObterPorId.queryKey = '/api/v1/tipos-de-logradouro/{id}'

export const queryTiposLogradouroControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-logradouro/{chave}', pathParams)
export const useQueryTiposLogradouroControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-logradouro/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposLogradouroControllerObterPorChave.queryKey = '/api/v1/tipos-de-logradouro/{chave}'

export const queryTiposMotivoDesfechoAtendimentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-motivo-de-desfecho-de-atendimento', queryParams)
export const useQueryTiposMotivoDesfechoAtendimentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-motivo-de-desfecho-de-atendimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposMotivoDesfechoAtendimentoControllerListar.queryKey = '/api/v1/tipos-de-motivo-de-desfecho-de-atendimento'

export const queryTiposMotivoDesfechoAtendimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-motivo-de-desfecho-de-atendimento/{id}', pathParams)
export const useQueryTiposMotivoDesfechoAtendimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-motivo-de-desfecho-de-atendimento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposMotivoDesfechoAtendimentoControllerObterPorId.queryKey = '/api/v1/tipos-de-motivo-de-desfecho-de-atendimento/{id}'

export const queryTiposMotivoDesfechoAtendimentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-motivo-de-desfecho-de-atendimento/{chave}', pathParams)
export const useQueryTiposMotivoDesfechoAtendimentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-motivo-de-desfecho-de-atendimento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposMotivoDesfechoAtendimentoControllerObterPorChave.queryKey = '/api/v1/tipos-de-motivo-de-desfecho-de-atendimento/{chave}'

export const queryTiposNecessidadesEspeciaisControllerObterListaDeTiposNecessidadesEspeciais = (options) => queryFn(options)('/api/v1/tipos-de-necessidades-especiais')
export const useQueryTiposNecessidadesEspeciaisControllerObterListaDeTiposNecessidadesEspeciais = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-necessidades-especiais'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposNecessidadesEspeciaisControllerObterListaDeTiposNecessidadesEspeciais.queryKey = '/api/v1/tipos-de-necessidades-especiais'

export const queryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-necessidades-especiais/{id}', pathParams)
export const useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-necessidades-especiais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorId.queryKey = '/api/v1/tipos-de-necessidades-especiais/{id}'

export const queryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-necessidades-especiais/{chave}', pathParams)
export const useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-necessidades-especiais/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorChave.queryKey = '/api/v1/tipos-de-necessidades-especiais/{chave}'

export const queryTiposOperacaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-operacao', queryParams)
export const useQueryTiposOperacaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-operacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposOperacaoControllerListar.queryKey = '/api/v1/tipos-operacao'

export const queryTiposPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento', queryParams)
export const useQueryTiposPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPagamentoControllerListar.queryKey = '/api/v1/tipos-de-pagamento'

export const queryTiposPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento/{id}', pathParams)
export const useQueryTiposPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPagamentoControllerObterPorId.queryKey = '/api/v1/tipos-de-pagamento/{id}'

export const queryTiposPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento/{chave}', pathParams)
export const useQueryTiposPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposPagamentoControllerObterPorChave.queryKey = '/api/v1/tipos-de-pagamento/{chave}'

export const queryTiposPagamentoComissaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento-de-comissao', queryParams)
export const useQueryTiposPagamentoComissaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento-de-comissao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPagamentoComissaoControllerBuscar.queryKey = '/api/v1/tipos-de-pagamento-de-comissao'

export const queryTiposPagamentoComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento-de-comissao/{id}', pathParams)
export const useQueryTiposPagamentoComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPagamentoComissaoControllerObterPorId.queryKey = '/api/v1/tipos-de-pagamento-de-comissao/{id}'

export const queryTiposPagamentoComissaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento-de-comissao/{chave}', pathParams)
export const useQueryTiposPagamentoComissaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento-de-comissao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposPagamentoComissaoControllerObterPorChave.queryKey = '/api/v1/tipos-de-pagamento-de-comissao/{chave}'

export const queryTiposPerguntaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-pergunta', queryParams)
export const useQueryTiposPerguntaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pergunta', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPerguntaControllerListar.queryKey = '/api/v1/tipos-de-pergunta'

export const queryTiposPerguntaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pergunta/{id}', pathParams)
export const useQueryTiposPerguntaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pergunta/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPerguntaControllerObterPorId.queryKey = '/api/v1/tipos-de-pergunta/{id}'

export const queryTiposPerguntaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pergunta/{chave}', pathParams)
export const useQueryTiposPerguntaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pergunta/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposPerguntaControllerObterPorChave.queryKey = '/api/v1/tipos-de-pergunta/{chave}'

export const queryTiposPessoaControllerObterListaDeTipoPessoa = (options) => queryFn(options)('/api/v1/tipos-de-pessoa')
export const useQueryTiposPessoaControllerObterListaDeTipoPessoa = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pessoa'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPessoaControllerObterListaDeTipoPessoa.queryKey = '/api/v1/tipos-de-pessoa'

export const queryTiposPessoaControllerObterTipoPessoaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pessoa/{id}', pathParams)
export const useQueryTiposPessoaControllerObterTipoPessoaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pessoa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPessoaControllerObterTipoPessoaPorId.queryKey = '/api/v1/tipos-de-pessoa/{id}'

export const queryTiposPlanosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-planos/{id}', pathParams)
export const useQueryTiposPlanosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-planos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPlanosControllerObterPorId.queryKey = '/api/v1/tipos-planos/{id}'

export const queryTiposPlanosControllerObterPorChave = (queryParams, options) => queryFn(options)('/api/v1/tipos-planos/chave', queryParams)
export const useQueryTiposPlanosControllerObterPorChave = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-planos/chave', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPlanosControllerObterPorChave.queryKey = '/api/v1/tipos-planos/chave'

export const queryTiposPlanosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-planos', queryParams)
export const useQueryTiposPlanosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-planos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPlanosControllerListar.queryKey = '/api/v1/tipos-planos'

export const mutationTiposProdutoControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-de-produto', {}, {}, options)
export const useMutationTiposProdutoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-produto', {}, {}, options), config)

export const queryTiposProdutoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-produto', queryParams)
export const useQueryTiposProdutoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposProdutoControllerBuscar.queryKey = '/api/v1/tipos-de-produto'

export const mutationTiposProdutoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-produto/{id}', pathParams, {}, options)
export const useMutationTiposProdutoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-produto/{id}', pathParams, {}, options), config)

export const mutationTiposProdutoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-produto/{id}', pathParams, {}, options)
export const useMutationTiposProdutoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-produto/{id}', pathParams, {}, options), config)

export const queryTiposProdutoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-produto/{id}', pathParams)
export const useQueryTiposProdutoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposProdutoControllerObterPorId.queryKey = '/api/v1/tipos-de-produto/{id}'

export const queryTiposProdutoControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-produto/buscar-detalhado/{id}', pathParams)
export const useQueryTiposProdutoControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposProdutoControllerObterPorIdDetalhado.queryKey = '/api/v1/tipos-de-produto/buscar-detalhado/{id}'

export const mutationTiposProdutoControllerCadastrarTipoProdutoConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-produto/{id}/contas', pathParams, {}, options)
export const useMutationTiposProdutoControllerCadastrarTipoProdutoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-produto/{id}/contas', pathParams, {}, options), config)

export const queryTiposProdutoControllerListarTipoProdutoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-produto/{id}/contas', pathParams, queryParams)
export const useQueryTiposProdutoControllerListarTipoProdutoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposProdutoControllerListarTipoProdutoContas.queryKey = '/api/v1/tipos-de-produto/{id}/contas'

export const queryTiposProdutoControllerObterTipoProdutoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-produto/{id}/contas/{contaId}', pathParams)
export const useQueryTiposProdutoControllerObterTipoProdutoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposProdutoControllerObterTipoProdutoContaPorId.queryKey = '/api/v1/tipos-de-produto/{id}/contas/{contaId}'

export const mutationTiposProdutoControllerAtualizarTipoProdutoConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-produto/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposProdutoControllerAtualizarTipoProdutoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-produto/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposProdutoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-produto/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposProdutoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposProdutoControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-de-produto/{id}/contas-disponiveis'

export const queryTiposProdutoControllerListarProdutoPorTiposProduto = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-produto/{chave}', pathParams, queryParams)
export const useQueryTiposProdutoControllerListarProdutoPorTiposProduto = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/{chave}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposProdutoControllerListarProdutoPorTiposProduto.queryKey = '/api/v1/tipos-de-produto/{chave}'

export const queryTiposQuestionarioControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-questionario', queryParams)
export const useQueryTiposQuestionarioControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-questionario', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposQuestionarioControllerListar.queryKey = '/api/v1/tipos-de-questionario'

export const queryTiposQuestionarioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-questionario/{id}', pathParams)
export const useQueryTiposQuestionarioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-questionario/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposQuestionarioControllerObterPorId.queryKey = '/api/v1/tipos-de-questionario/{id}'

export const queryTiposQuestionarioControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-questionario/{chave}', pathParams)
export const useQueryTiposQuestionarioControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-questionario/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposQuestionarioControllerObterPorChave.queryKey = '/api/v1/tipos-de-questionario/{chave}'

export const queryTiposRedeSocialControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-rede-social', queryParams)
export const useQueryTiposRedeSocialControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-rede-social', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposRedeSocialControllerListar.queryKey = '/api/v1/tipos-de-rede-social'

export const queryTiposRegimeTributarioControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-regime-tributario', queryParams)
export const useQueryTiposRegimeTributarioControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-regime-tributario', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposRegimeTributarioControllerBuscar.queryKey = '/api/v1/tipos-de-regime-tributario'

export const queryTiposRegimeTributarioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-regime-tributario/{id}', pathParams)
export const useQueryTiposRegimeTributarioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-regime-tributario/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposRegimeTributarioControllerObterPorId.queryKey = '/api/v1/tipos-de-regime-tributario/{id}'

export const queryTiposResponsavelControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel', queryParams)
export const useQueryTiposResponsavelControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposResponsavelControllerBuscar.queryKey = '/api/v1/tipos-de-responsavel'

export const mutationTiposResponsavelControllerCadastrarTipoResponsavel = (options) => mutationFn('post', '/api/v1/tipos-de-responsavel', {}, {}, options)
export const useMutationTiposResponsavelControllerCadastrarTipoResponsavel = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-responsavel', {}, {}, options), config)

export const queryTiposResponsavelControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel/{id}', pathParams)
export const useQueryTiposResponsavelControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposResponsavelControllerObterPorId.queryKey = '/api/v1/tipos-de-responsavel/{id}'

export const mutationTiposResponsavelControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-responsavel/{id}', pathParams, {}, options)
export const useMutationTiposResponsavelControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-responsavel/{id}', pathParams, {}, options), config)

export const mutationTiposResponsavelControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-responsavel/{id}', pathParams, {}, options)
export const useMutationTiposResponsavelControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-responsavel/{id}', pathParams, {}, options), config)

export const mutationTiposResponsavelControllerCadastrarTipoResponsavelTipoTag = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel', pathParams, {}, options)
export const useMutationTiposResponsavelControllerCadastrarTipoResponsavelTipoTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel', pathParams, {}, options), config)

export const queryTiposResponsavelControllerListarTiposTag = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel', pathParams, queryParams)
export const useQueryTiposResponsavelControllerListarTiposTag = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposResponsavelControllerListarTiposTag.queryKey = '/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel'

export const mutationTiposResponsavelControllerDeletarTipoTagTag = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel/{tipoTagTipoResponsavelChave}', pathParams, {}, options)
export const useMutationTiposResponsavelControllerDeletarTipoTagTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel/{tipoTagTipoResponsavelChave}', pathParams, {}, options), config)

export const mutationTiposResponsavelControllerCadastrarTipoResponsavelConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-responsavel/{id}/contas', pathParams, {}, options)
export const useMutationTiposResponsavelControllerCadastrarTipoResponsavelConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-responsavel/{id}/contas', pathParams, {}, options), config)

export const queryTiposResponsavelControllerListarTipoResponsavelContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel/{id}/contas', pathParams, queryParams)
export const useQueryTiposResponsavelControllerListarTipoResponsavelContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposResponsavelControllerListarTipoResponsavelContas.queryKey = '/api/v1/tipos-de-responsavel/{id}/contas'

export const queryTiposResponsavelControllerObterTipoResponsavelContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel/{id}/contas/{contaId}', pathParams)
export const useQueryTiposResponsavelControllerObterTipoResponsavelContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposResponsavelControllerObterTipoResponsavelContaPorId.queryKey = '/api/v1/tipos-de-responsavel/{id}/contas/{contaId}'

export const mutationTiposResponsavelControllerAtualizarTipoResponsavelConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-responsavel/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposResponsavelControllerAtualizarTipoResponsavelConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-responsavel/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposResponsavelControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposResponsavelControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposResponsavelControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-de-responsavel/{id}/contas-disponiveis'

export const queryTiposSalaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-sala', queryParams)
export const useQueryTiposSalaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sala', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposSalaControllerBuscar.queryKey = '/api/v1/tipos-de-sala'

export const mutationTiposSalaControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-de-sala', {}, {}, options)
export const useMutationTiposSalaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-sala', {}, {}, options), config)

export const queryTiposSalaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-sala/{id}', pathParams)
export const useQueryTiposSalaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sala/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSalaControllerObterPorId.queryKey = '/api/v1/tipos-de-sala/{id}'

export const mutationTiposSalaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-sala/{id}', pathParams, {}, options)
export const useMutationTiposSalaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-sala/{id}', pathParams, {}, options), config)

export const mutationTiposSalaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-sala/{id}', pathParams, {}, options)
export const useMutationTiposSalaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-sala/{id}', pathParams, {}, options), config)

export const mutationTiposSalaControllerCadastrarTipoSalaConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-sala/{id}/contas', pathParams, {}, options)
export const useMutationTiposSalaControllerCadastrarTipoSalaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-sala/{id}/contas', pathParams, {}, options), config)

export const queryTiposSalaControllerListarTipoSalaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-sala/{id}/contas', pathParams, queryParams)
export const useQueryTiposSalaControllerListarTipoSalaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sala/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSalaControllerListarTipoSalaContas.queryKey = '/api/v1/tipos-de-sala/{id}/contas'

export const queryTiposSalaControllerObterTipoSalaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-sala/{id}/contas/{contaId}', pathParams)
export const useQueryTiposSalaControllerObterTipoSalaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sala/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposSalaControllerObterTipoSalaContaPorId.queryKey = '/api/v1/tipos-de-sala/{id}/contas/{contaId}'

export const mutationTiposSalaControllerAtualizarTipoSalaConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-sala/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposSalaControllerAtualizarTipoSalaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-sala/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposSalaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-sala/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposSalaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sala/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSalaControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-de-sala/{id}/contas-disponiveis'

export const queryTiposSanguineosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-sanguineos', queryParams)
export const useQueryTiposSanguineosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-sanguineos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposSanguineosControllerBuscar.queryKey = '/api/v1/tipos-sanguineos'

export const queryTiposSanguineosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-sanguineos/{id}', pathParams)
export const useQueryTiposSanguineosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-sanguineos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSanguineosControllerObterPorId.queryKey = '/api/v1/tipos-sanguineos/{id}'

export const queryTiposSanguineosControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-sanguineos/{chave}', pathParams)
export const useQueryTiposSanguineosControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-sanguineos/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposSanguineosControllerObterPorChave.queryKey = '/api/v1/tipos-sanguineos/{chave}'

export const queryTiposSecoesModeloProntuariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-secoes-modelo-prontuarios/{id}', pathParams)
export const useQueryTiposSecoesModeloProntuariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-secoes-modelo-prontuarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSecoesModeloProntuariosControllerObterPorId.queryKey = '/api/v1/tipos-de-secoes-modelo-prontuarios/{id}'

export const queryTiposSecoesModeloProntuariosControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-secoes-modelo-prontuarios/{chave}', pathParams)
export const useQueryTiposSecoesModeloProntuariosControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-secoes-modelo-prontuarios/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposSecoesModeloProntuariosControllerObterPorChave.queryKey = '/api/v1/tipos-de-secoes-modelo-prontuarios/{chave}'

export const queryTiposSecoesModeloProntuariosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-secoes-modelo-prontuarios', queryParams)
export const useQueryTiposSecoesModeloProntuariosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-secoes-modelo-prontuarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposSecoesModeloProntuariosControllerListar.queryKey = '/api/v1/tipos-de-secoes-modelo-prontuarios'

export const queryTiposSexoControllerObterListaDeTipoSexo = (options) => queryFn(options)('/api/v1/tipos-de-sexo')
export const useQueryTiposSexoControllerObterListaDeTipoSexo = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sexo'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposSexoControllerObterListaDeTipoSexo.queryKey = '/api/v1/tipos-de-sexo'

export const queryTiposSexoControllerObterTipoSexoPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-sexo/{id}', pathParams)
export const useQueryTiposSexoControllerObterTipoSexoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sexo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSexoControllerObterTipoSexoPorId.queryKey = '/api/v1/tipos-de-sexo/{id}'

export const queryTiposStatusChequeControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-cheque', queryParams)
export const useQueryTiposStatusChequeControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-cheque', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposStatusChequeControllerListar.queryKey = '/api/v1/tipos-de-status-de-cheque'

export const queryTiposStatusChequeControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-cheque/{id}', pathParams)
export const useQueryTiposStatusChequeControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-cheque/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposStatusChequeControllerObterPorId.queryKey = '/api/v1/tipos-de-status-de-cheque/{id}'

export const queryTiposStatusChequeControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-cheque/{chave}', pathParams)
export const useQueryTiposStatusChequeControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-cheque/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposStatusChequeControllerObterPorChave.queryKey = '/api/v1/tipos-de-status-de-cheque/{chave}'

export const queryTiposStatusTituloControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-titulo', queryParams)
export const useQueryTiposStatusTituloControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-titulo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposStatusTituloControllerListar.queryKey = '/api/v1/tipos-de-status-de-titulo'

export const queryTiposStatusTituloControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-titulo/{id}', pathParams)
export const useQueryTiposStatusTituloControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-titulo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposStatusTituloControllerObterPorId.queryKey = '/api/v1/tipos-de-status-de-titulo/{id}'

export const queryTiposStatusTituloControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-titulo/{chave}', pathParams)
export const useQueryTiposStatusTituloControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-titulo/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposStatusTituloControllerObterPorChave.queryKey = '/api/v1/tipos-de-status-de-titulo/{chave}'

export const queryTiposTagControllerBuscarTagsPorTipoTagId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-tag/{id}/tags', pathParams, queryParams)
export const useQueryTiposTagControllerBuscarTagsPorTipoTagId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTagControllerBuscarTagsPorTipoTagId.queryKey = '/api/v1/tipos-de-tag/{id}/tags'

export const queryTiposTagControllerBuscarTagsPorTipoTagChave = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-tag/{chave}/tags', pathParams, queryParams)
export const useQueryTiposTagControllerBuscarTagsPorTipoTagChave = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag/{chave}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTagControllerBuscarTagsPorTipoTagChave.queryKey = '/api/v1/tipos-de-tag/{chave}/tags'

export const queryTiposTagControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-tag', queryParams)
export const useQueryTiposTagControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTagControllerBuscar.queryKey = '/api/v1/tipos-de-tag'

export const queryTiposTagControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tag/{id}', pathParams)
export const useQueryTiposTagControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTagControllerObterPorId.queryKey = '/api/v1/tipos-de-tag/{id}'

export const queryTiposTagControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tag/{chave}', pathParams)
export const useQueryTiposTagControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTagControllerObterPorChave.queryKey = '/api/v1/tipos-de-tag/{chave}'

export const queryTiposTagsTipoResponsavelControllerObterListadeTipoTagTipoResponsavel = (options) => queryFn(options)('/api/v1/tipos-de-tag-tipo-responsavel')
export const useQueryTiposTagsTipoResponsavelControllerObterListadeTipoTagTipoResponsavel = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag-tipo-responsavel'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTagsTipoResponsavelControllerObterListadeTipoTagTipoResponsavel.queryKey = '/api/v1/tipos-de-tag-tipo-responsavel'

export const queryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tag-tipo-responsavel/{id}', pathParams)
export const useQueryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag-tipo-responsavel/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorId.queryKey = '/api/v1/tipos-de-tag-tipo-responsavel/{id}'

export const queryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tag-tipo-responsavel/{chave}', pathParams)
export const useQueryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag-tipo-responsavel/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorChave.queryKey = '/api/v1/tipos-de-tag-tipo-responsavel/{chave}'

export const queryTiposTagsTipoResponsavelControllerObterTipoResponsavelTipoTagTipoResponsavelPorChave = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-tag-tipo-responsavel/{chave}/tipos-responsaveis', pathParams, queryParams)
export const useQueryTiposTagsTipoResponsavelControllerObterTipoResponsavelTipoTagTipoResponsavelPorChave = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag-tipo-responsavel/{chave}/tipos-responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTagsTipoResponsavelControllerObterTipoResponsavelTipoTagTipoResponsavelPorChave.queryKey = '/api/v1/tipos-de-tag-tipo-responsavel/{chave}/tipos-responsaveis'

export const queryTiposTarefaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-tarefa', queryParams)
export const useQueryTiposTarefaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tarefa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTarefaControllerListar.queryKey = '/api/v1/tipos-de-tarefa'

export const queryTiposTarefaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tarefa/{id}', pathParams)
export const useQueryTiposTarefaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tarefa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTarefaControllerObterPorId.queryKey = '/api/v1/tipos-de-tarefa/{id}'

export const queryTiposTarefaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tarefa/{chave}', pathParams)
export const useQueryTiposTarefaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tarefa/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTarefaControllerObterPorChave.queryKey = '/api/v1/tipos-de-tarefa/{chave}'

export const queryTiposTelefoneControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-telefone', queryParams)
export const useQueryTiposTelefoneControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-telefone', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTelefoneControllerListar.queryKey = '/api/v1/tipos-de-telefone'

export const queryTiposTempoPrevistoRetornoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-tempo-previsto-de-retorno', queryParams)
export const useQueryTiposTempoPrevistoRetornoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tempo-previsto-de-retorno', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTempoPrevistoRetornoControllerListar.queryKey = '/api/v1/tipos-de-tempo-previsto-de-retorno'

export const queryTiposTempoPrevistoRetornoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tempo-previsto-de-retorno/{id}', pathParams)
export const useQueryTiposTempoPrevistoRetornoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tempo-previsto-de-retorno/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTempoPrevistoRetornoControllerObterPorId.queryKey = '/api/v1/tipos-de-tempo-previsto-de-retorno/{id}'

export const queryTiposTempoPrevistoRetornoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tempo-previsto-de-retorno/{chave}', pathParams)
export const useQueryTiposTempoPrevistoRetornoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tempo-previsto-de-retorno/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTempoPrevistoRetornoControllerObterPorChave.queryKey = '/api/v1/tipos-de-tempo-previsto-de-retorno/{chave}'

export const queryTiposTituloControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-titulo', queryParams)
export const useQueryTiposTituloControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-titulo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTituloControllerListar.queryKey = '/api/v1/tipos-de-titulo'

export const queryTiposTituloControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-titulo/{id}', pathParams)
export const useQueryTiposTituloControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-titulo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTituloControllerObterPorId.queryKey = '/api/v1/tipos-de-titulo/{id}'

export const queryTiposTituloControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-titulo/{chave}', pathParams)
export const useQueryTiposTituloControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-titulo/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTituloControllerObterPorChave.queryKey = '/api/v1/tipos-de-titulo/{chave}'

export const queryTiposTributacaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-tributacao', queryParams)
export const useQueryTiposTributacaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-tributacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTributacaoControllerListar.queryKey = '/api/v1/tipos-tributacao'

export const queryTiposValorCompensadoVencimentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-valor-compensado-vencimento', queryParams)
export const useQueryTiposValorCompensadoVencimentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-valor-compensado-vencimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposValorCompensadoVencimentoControllerListar.queryKey = '/api/v1/tipos-valor-compensado-vencimento'

export const queryTiposVencimentoComissaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-vencimento-de-comissao', queryParams)
export const useQueryTiposVencimentoComissaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-vencimento-de-comissao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposVencimentoComissaoControllerBuscar.queryKey = '/api/v1/tipos-de-vencimento-de-comissao'

export const queryTiposVencimentoComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-vencimento-de-comissao/{id}', pathParams)
export const useQueryTiposVencimentoComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-vencimento-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposVencimentoComissaoControllerObterPorId.queryKey = '/api/v1/tipos-de-vencimento-de-comissao/{id}'

export const queryTiposVencimentoComissaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-vencimento-de-comissao/{chave}', pathParams)
export const useQueryTiposVencimentoComissaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-vencimento-de-comissao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposVencimentoComissaoControllerObterPorChave.queryKey = '/api/v1/tipos-de-vencimento-de-comissao/{chave}'

export const queryTiposVisaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-visao', queryParams)
export const useQueryTiposVisaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-visao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposVisaoControllerListar.queryKey = '/api/v1/tipos-visao'

export const queryTissPlanosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tiss-planos/{id}', pathParams)
export const useQueryTissPlanosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tiss-planos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTissPlanosControllerObterPorId.queryKey = '/api/v1/tiss-planos/{id}'

export const mutationTissPlanosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tiss-planos/{id}', pathParams, {}, options)
export const useMutationTissPlanosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tiss-planos/{id}', pathParams, {}, options), config)

export const mutationTissPlanosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tiss-planos/{id}', pathParams, {}, options)
export const useMutationTissPlanosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tiss-planos/{id}', pathParams, {}, options), config)

export const mutationTissPlanosControllerCadastrarTissPlanoConta = (pathParams, options) => mutationFn('post', '/api/v1/tiss-planos/{id}/contas', pathParams, {}, options)
export const useMutationTissPlanosControllerCadastrarTissPlanoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tiss-planos/{id}/contas', pathParams, {}, options), config)

export const queryTissPlanosControllerListarTissPlanoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tiss-planos/{id}/contas', pathParams, queryParams)
export const useQueryTissPlanosControllerListarTissPlanoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tiss-planos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTissPlanosControllerListarTissPlanoContas.queryKey = '/api/v1/tiss-planos/{id}/contas'

export const queryTissPlanosControllerObterTissPlanoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tiss-planos/{id}/contas/{contaId}', pathParams)
export const useQueryTissPlanosControllerObterTissPlanoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tiss-planos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTissPlanosControllerObterTissPlanoContaPorId.queryKey = '/api/v1/tiss-planos/{id}/contas/{contaId}'

export const mutationTissPlanosControllerAtualizarTissPlanoConta = (pathParams, options) => mutationFn('put', '/api/v1/tiss-planos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTissPlanosControllerAtualizarTissPlanoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tiss-planos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTissPlanosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tiss-planos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTissPlanosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tiss-planos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTissPlanosControllerListarContasDisponiveis.queryKey = '/api/v1/tiss-planos/{id}/contas-disponiveis'

export const mutationTitulosControllerCadastrar = (options) => mutationFn('post', '/api/v1/titulos', {}, {}, options)
export const useMutationTitulosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos', {}, {}, options), config)

export const queryTitulosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/titulos', queryParams)
export const useQueryTitulosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTitulosControllerListar.queryKey = '/api/v1/titulos'

export const queryTitulosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}', pathParams)
export const useQueryTitulosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterPorId.queryKey = '/api/v1/titulos/{id}'

export const mutationTitulosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/titulos/{id}', pathParams, {}, options)
export const useMutationTitulosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/titulos/{id}', pathParams, {}, options), config)

export const mutationTitulosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/titulos/{id}', pathParams, {}, options)
export const useMutationTitulosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/titulos/{id}', pathParams, {}, options), config)

export const mutationTitulosControllerCadastrarTituloConfiguracaoDesconto = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/desconto-pontualidade', pathParams, {}, options)
export const useMutationTitulosControllerCadastrarTituloConfiguracaoDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/desconto-pontualidade', pathParams, {}, options), config)

export const queryTitulosControllerObterTituloConfiguracaoDescontoPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/desconto-pontualidade/{descontoId}', pathParams)
export const useQueryTitulosControllerObterTituloConfiguracaoDescontoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/desconto-pontualidade/{descontoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.descontoId, ...config }
})
useQueryTitulosControllerObterTituloConfiguracaoDescontoPorId.queryKey = '/api/v1/titulos/{id}/desconto-pontualidade/{descontoId}'

export const queryTitulosControllerListarTituloConfiguracaoDescontos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/descontos-pontualidade', pathParams, queryParams)
export const useQueryTitulosControllerListarTituloConfiguracaoDescontos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/descontos-pontualidade', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarTituloConfiguracaoDescontos.queryKey = '/api/v1/titulos/{id}/descontos-pontualidade'

export const mutationTitulosControllerDeletarTituloConfiguracaoDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/titulos/{id}/descontos-pontualidade/{descontoId}', pathParams, {}, options)
export const useMutationTitulosControllerDeletarTituloConfiguracaoDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/titulos/{id}/descontos-pontualidade/{descontoId}', pathParams, {}, options), config)

export const mutationTitulosControllerCadastrarTituloConfiguracaoConvenio = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/convenios', pathParams, {}, options)
export const useMutationTitulosControllerCadastrarTituloConfiguracaoConvenio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/convenios', pathParams, {}, options), config)

export const queryTitulosControllerListarTituloConfiguracaoConvenios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/convenios', pathParams, queryParams)
export const useQueryTitulosControllerListarTituloConfiguracaoConvenios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/convenios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarTituloConfiguracaoConvenios.queryKey = '/api/v1/titulos/{id}/convenios'

export const queryTitulosControllerObterTituloConfiguracaoConvenioPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/convenios/{convenioId}', pathParams)
export const useQueryTitulosControllerObterTituloConfiguracaoConvenioPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/convenios/{convenioId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.convenioId, ...config }
})
useQueryTitulosControllerObterTituloConfiguracaoConvenioPorId.queryKey = '/api/v1/titulos/{id}/convenios/{convenioId}'

export const mutationTitulosControllerDeletarTituloConfiguracaoConvenio = (pathParams, options) => mutationFn('delete', '/api/v1/titulos/{id}/convenios/{convenioId}', pathParams, {}, options)
export const useMutationTitulosControllerDeletarTituloConfiguracaoConvenio = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/titulos/{id}/convenios/{convenioId}', pathParams, {}, options), config)

export const mutationTitulosControllerGerarBaixaLote = (options) => mutationFn('post', '/api/v1/titulos/baixa-lote', {}, {}, options)
export const useMutationTitulosControllerGerarBaixaLote = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/baixa-lote', {}, {}, options), config)

export const mutationTitulosControllerEstornar = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/estorno', pathParams, {}, options)
export const useMutationTitulosControllerEstornar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/estorno', pathParams, {}, options), config)

export const mutationTitulosControllerEstornarLote = (options) => mutationFn('post', '/api/v1/titulos/estorno-lote', {}, {}, options)
export const useMutationTitulosControllerEstornarLote = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/estorno-lote', {}, {}, options), config)

export const mutationTitulosControllerCadastrarBaixa = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/baixas', pathParams, {}, options)
export const useMutationTitulosControllerCadastrarBaixa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/baixas', pathParams, {}, options), config)

export const queryTitulosControllerListarBaixasPorTitulo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/baixas', pathParams, queryParams)
export const useQueryTitulosControllerListarBaixasPorTitulo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/baixas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarBaixasPorTitulo.queryKey = '/api/v1/titulos/{id}/baixas'

export const mutationTitulosControllerCadastrarBaixaEmLista = (options) => mutationFn('post', '/api/v1/titulos/baixas-lista', {}, {}, options)
export const useMutationTitulosControllerCadastrarBaixaEmLista = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/baixas-lista', {}, {}, options), config)

export const queryTitulosControllerListarBaixa = (queryParams, options) => queryFn(options)('/api/v1/titulos/baixas', queryParams)
export const useQueryTitulosControllerListarBaixa = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/baixas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTitulosControllerListarBaixa.queryKey = '/api/v1/titulos/baixas'

export const mutationTitulosControllerCadastrarDesconto = (options) => mutationFn('post', '/api/v1/titulos/baixas/descontos', {}, {}, options)
export const useMutationTitulosControllerCadastrarDesconto = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/baixas/descontos', {}, {}, options), config)

export const queryTitulosControllerListarDescontos = (queryParams, options) => queryFn(options)('/api/v1/titulos/baixas/descontos', queryParams)
export const useQueryTitulosControllerListarDescontos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/baixas/descontos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTitulosControllerListarDescontos.queryKey = '/api/v1/titulos/baixas/descontos'

export const queryTitulosControllerObterDescontoPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/baixas/descontos/{id}', pathParams)
export const useQueryTitulosControllerObterDescontoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/baixas/descontos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterDescontoPorId.queryKey = '/api/v1/titulos/baixas/descontos/{id}'

export const mutationTitulosControllerDeletarDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/titulos/baixas/descontos/{id}', pathParams, {}, options)
export const useMutationTitulosControllerDeletarDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/titulos/baixas/descontos/{id}', pathParams, {}, options), config)

export const queryTitulosControllerListarDescontosPorBaixaTitulo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/baixas/{id}/descontos', pathParams, queryParams)
export const useQueryTitulosControllerListarDescontosPorBaixaTitulo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/baixas/{id}/descontos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarDescontosPorBaixaTitulo.queryKey = '/api/v1/titulos/baixas/{id}/descontos'

export const queryTitulosControllerObterTituloConfiguracaoBolsaPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/bolsas/{bolsaId}', pathParams)
export const useQueryTitulosControllerObterTituloConfiguracaoBolsaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/bolsas/{bolsaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.bolsaId, ...config }
})
useQueryTitulosControllerObterTituloConfiguracaoBolsaPorId.queryKey = '/api/v1/titulos/{id}/bolsas/{bolsaId}'

export const queryTitulosControllerListarTituloConfiguracaoBolsas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/bolsas', pathParams, queryParams)
export const useQueryTitulosControllerListarTituloConfiguracaoBolsas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/bolsas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarTituloConfiguracaoBolsas.queryKey = '/api/v1/titulos/{id}/bolsas'

export const queryTitulosControllerObterBoletoPorTituloId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/boletos', pathParams)
export const useQueryTitulosControllerObterBoletoPorTituloId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/boletos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterBoletoPorTituloId.queryKey = '/api/v1/titulos/{id}/boletos'

export const mutationTitulosControllerCadastrarBoleto = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/boletos', pathParams, {}, options)
export const useMutationTitulosControllerCadastrarBoleto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/boletos', pathParams, {}, options), config)

export const queryTitulosControllerListarTitulosPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/titulos/filtros', queryParams)
export const useQueryTitulosControllerListarTitulosPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTitulosControllerListarTitulosPorFiltros.queryKey = '/api/v1/titulos/filtros'

export const mutationTitulosControllerCadastrarTituloJustificativaMovimento = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/justificativas-movimento', pathParams, {}, options)
export const useMutationTitulosControllerCadastrarTituloJustificativaMovimento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/justificativas-movimento', pathParams, {}, options), config)

export const queryTitulosControllerListarTituloJustificativasMovimento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/justificativas-movimento', pathParams, queryParams)
export const useQueryTitulosControllerListarTituloJustificativasMovimento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/justificativas-movimento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarTituloJustificativasMovimento.queryKey = '/api/v1/titulos/{id}/justificativas-movimento'

export const queryTitulosControllerCadastrarTituloJustificativaMovimentoPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/movimentos/{movimentoCaixaId}', pathParams)
export const useQueryTitulosControllerCadastrarTituloJustificativaMovimentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/movimentos/{movimentoCaixaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.movimentoCaixaId, ...config }
})
useQueryTitulosControllerCadastrarTituloJustificativaMovimentoPorId.queryKey = '/api/v1/titulos/{id}/movimentos/{movimentoCaixaId}'

export const queryTitulosControllerObterPorTituloId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/obter-config-link-pagamento', pathParams)
export const useQueryTitulosControllerObterPorTituloId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/obter-config-link-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterPorTituloId.queryKey = '/api/v1/titulos/{id}/obter-config-link-pagamento'

export const mutationTitulosControllerAtualizaAssinaturaPlano = (pathParams, options) => mutationFn('put', '/api/v1/titulos/{id}/atualizar-assinatura', pathParams, {}, options)
export const useMutationTitulosControllerAtualizaAssinaturaPlano = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/titulos/{id}/atualizar-assinatura', pathParams, {}, options), config)

export const mutationTitulosControllerCancelarLote = (options) => mutationFn('post', '/api/v1/titulos/cancelar-lote', {}, {}, options)
export const useMutationTitulosControllerCancelarLote = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/cancelar-lote', {}, {}, options), config)

export const queryTitulosControllerObterParcelasPorTransacaoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/parcelas', pathParams, queryParams)
export const useQueryTitulosControllerObterParcelasPorTransacaoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/parcelas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterParcelasPorTransacaoId.queryKey = '/api/v1/titulos/{id}/parcelas'

export const queryTitulosControllerObterTituloDescontoPorId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/descontos/{id}', pathParams, queryParams)
export const useQueryTitulosControllerObterTituloDescontoPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/descontos/{id}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterTituloDescontoPorId.queryKey = '/api/v1/titulos/descontos/{id}'

export const mutationTitulosControllerDeletarTituloDesconto = (pathParams, queryParams, options) => mutationFn('delete', '/api/v1/titulos/descontos/{id}', pathParams, queryParams, options)
export const useMutationTitulosControllerDeletarTituloDesconto = (pathParams, queryParams, config, options) => useMutation(mutationFn('delete', '/api/v1/titulos/descontos/{id}', pathParams, queryParams, options), config)

export const mutationTitulosControllerCadastrarTituloDesconto = (options) => mutationFn('post', '/api/v1/titulos/descontos', {}, {}, options)
export const useMutationTitulosControllerCadastrarTituloDesconto = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/descontos', {}, {}, options), config)

export const queryTitulosJustificativaMovimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos-justificativas-movimento/{id}', pathParams)
export const useQueryTitulosJustificativaMovimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos-justificativas-movimento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosJustificativaMovimentoControllerObterPorId.queryKey = '/api/v1/titulos-justificativas-movimento/{id}'

export const mutationTitulosJustificativaMovimentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/titulos-justificativas-movimento/{id}', pathParams, {}, options)
export const useMutationTitulosJustificativaMovimentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/titulos-justificativas-movimento/{id}', pathParams, {}, options), config)

export const mutationTributacoesControllerCadastrarDadosBasicos = (options) => mutationFn('post', '/api/v1/tributacoes', {}, {}, options)
export const useMutationTributacoesControllerCadastrarDadosBasicos = (config, options) => useMutation(mutationFn('post', '/api/v1/tributacoes', {}, {}, options), config)

export const queryTributacoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tributacoes', queryParams)
export const useQueryTributacoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTributacoesControllerListar.queryKey = '/api/v1/tributacoes'

export const queryTributacoesControllerObterDadosBasicosPorId = (pathParams, options) => queryFn(options)('/api/v1/tributacoes/{id}', pathParams)
export const useQueryTributacoesControllerObterDadosBasicosPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesControllerObterDadosBasicosPorId.queryKey = '/api/v1/tributacoes/{id}'

export const mutationTributacoesControllerAtualizarDadosBasicos = (pathParams, options) => mutationFn('put', '/api/v1/tributacoes/{id}', pathParams, {}, options)
export const useMutationTributacoesControllerAtualizarDadosBasicos = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tributacoes/{id}', pathParams, {}, options), config)

export const mutationTributacoesControllerCadastrarTributacaoCnae = (pathParams, options) => mutationFn('post', '/api/v1/tributacoes/{id}/cnaes', pathParams, {}, options)
export const useMutationTributacoesControllerCadastrarTributacaoCnae = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tributacoes/{id}/cnaes', pathParams, {}, options), config)

export const queryTributacoesControllerListarTributacaoCnaes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/cnaes', pathParams, queryParams)
export const useQueryTributacoesControllerListarTributacaoCnaes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/cnaes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesControllerListarTributacaoCnaes.queryKey = '/api/v1/tributacoes/{id}/cnaes'

export const queryTributacoesControllerObterTributacaoCnaePorId = (pathParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/cnaes/{cnaeId}', pathParams)
export const useQueryTributacoesControllerObterTributacaoCnaePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/cnaes/{cnaeId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.cnaeId, ...config }
})
useQueryTributacoesControllerObterTributacaoCnaePorId.queryKey = '/api/v1/tributacoes/{id}/cnaes/{cnaeId}'

export const mutationTributacoesControllerCadastrarTributacaoCfop = (pathParams, options) => mutationFn('post', '/api/v1/tributacoes/{id}/cfops', pathParams, {}, options)
export const useMutationTributacoesControllerCadastrarTributacaoCfop = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tributacoes/{id}/cfops', pathParams, {}, options), config)

export const queryTributacoesControllerListarTributacaoCfops = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/cfops', pathParams, queryParams)
export const useQueryTributacoesControllerListarTributacaoCfops = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/cfops', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesControllerListarTributacaoCfops.queryKey = '/api/v1/tributacoes/{id}/cfops'

export const queryTributacoesControllerObterTributacaoCfopPorId = (pathParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/cfops/{cfopId}', pathParams)
export const useQueryTributacoesControllerObterTributacaoCfopPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/cfops/{cfopId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.cfopId, ...config }
})
useQueryTributacoesControllerObterTributacaoCfopPorId.queryKey = '/api/v1/tributacoes/{id}/cfops/{cfopId}'

export const mutationTributacoesControllerDeletarTributacaoCfop = (pathParams, options) => mutationFn('delete', '/api/v1/tributacoes/{id}/cfops/{cfopId}', pathParams, {}, options)
export const useMutationTributacoesControllerDeletarTributacaoCfop = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tributacoes/{id}/cfops/{cfopId}', pathParams, {}, options), config)

export const mutationTributacoesControllerCadastrarTributacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/tributacoes/{id}/contas', pathParams, {}, options)
export const useMutationTributacoesControllerCadastrarTributacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tributacoes/{id}/contas', pathParams, {}, options), config)

export const queryTributacoesControllerListarTributacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/contas', pathParams, queryParams)
export const useQueryTributacoesControllerListarTributacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesControllerListarTributacaoContas.queryKey = '/api/v1/tributacoes/{id}/contas'

export const queryTributacoesControllerObterTributacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/contas/{contaId}', pathParams)
export const useQueryTributacoesControllerObterTributacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTributacoesControllerObterTributacaoContaPorId.queryKey = '/api/v1/tributacoes/{id}/contas/{contaId}'

export const mutationTributacoesControllerAtualizarTributacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/tributacoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTributacoesControllerAtualizarTributacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tributacoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTributacoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTributacoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesControllerListarContasDisponiveis.queryKey = '/api/v1/tributacoes/{id}/contas-disponiveis'

export const queryTributacoesCnaeControllerGetById = (pathParams, options) => queryFn(options)('/api/v1/tributacoes-cnae/{id}', pathParams)
export const useQueryTributacoesCnaeControllerGetById = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes-cnae/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesCnaeControllerGetById.queryKey = '/api/v1/tributacoes-cnae/{id}'

export const mutationTributacoesCnaeControllerRemove = (pathParams, options) => mutationFn('delete', '/api/v1/tributacoes-cnae/{id}', pathParams, {}, options)
export const useMutationTributacoesCnaeControllerRemove = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tributacoes-cnae/{id}', pathParams, {}, options), config)

export const mutationUnidadesMedidaControllerCadastrar = (options) => mutationFn('post', '/api/v1/unidades-de-medida', {}, {}, options)
export const useMutationUnidadesMedidaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/unidades-de-medida', {}, {}, options), config)

export const queryUnidadesMedidaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/unidades-de-medida', queryParams)
export const useQueryUnidadesMedidaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUnidadesMedidaControllerBuscar.queryKey = '/api/v1/unidades-de-medida'

export const mutationUnidadesMedidaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/unidades-de-medida/{id}', pathParams, {}, options)
export const useMutationUnidadesMedidaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/unidades-de-medida/{id}', pathParams, {}, options), config)

export const mutationUnidadesMedidaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/unidades-de-medida/{id}', pathParams, {}, options)
export const useMutationUnidadesMedidaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/unidades-de-medida/{id}', pathParams, {}, options), config)

export const queryUnidadesMedidaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/unidades-de-medida/{id}', pathParams)
export const useQueryUnidadesMedidaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesMedidaControllerObterPorId.queryKey = '/api/v1/unidades-de-medida/{id}'

export const queryUnidadesMedidaControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/unidades-de-medida/buscar-detalhado/{id}', pathParams)
export const useQueryUnidadesMedidaControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesMedidaControllerObterPorIdDetalhado.queryKey = '/api/v1/unidades-de-medida/buscar-detalhado/{id}'

export const mutationUnidadesMedidaControllerCadastrarUnidadeMedidaConta = (pathParams, options) => mutationFn('post', '/api/v1/unidades-de-medida/{id}/contas', pathParams, {}, options)
export const useMutationUnidadesMedidaControllerCadastrarUnidadeMedidaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/unidades-de-medida/{id}/contas', pathParams, {}, options), config)

export const queryUnidadesMedidaControllerListarUnidadeMedidaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/unidades-de-medida/{id}/contas', pathParams, queryParams)
export const useQueryUnidadesMedidaControllerListarUnidadeMedidaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesMedidaControllerListarUnidadeMedidaContas.queryKey = '/api/v1/unidades-de-medida/{id}/contas'

export const queryUnidadesMedidaControllerObterUnidadeMedidaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/unidades-de-medida/{id}/contas/{contaId}', pathParams)
export const useQueryUnidadesMedidaControllerObterUnidadeMedidaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryUnidadesMedidaControllerObterUnidadeMedidaContaPorId.queryKey = '/api/v1/unidades-de-medida/{id}/contas/{contaId}'

export const mutationUnidadesMedidaControllerAtualizarUnidadeMedidaConta = (pathParams, options) => mutationFn('put', '/api/v1/unidades-de-medida/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUnidadesMedidaControllerAtualizarUnidadeMedidaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/unidades-de-medida/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryUnidadesMedidaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/unidades-de-medida/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryUnidadesMedidaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesMedidaControllerListarContasDisponiveis.queryKey = '/api/v1/unidades-de-medida/{id}/contas-disponiveis'

export const mutationUsosProdutoControllerCadastrar = (options) => mutationFn('post', '/api/v1/usos-de-produto', {}, {}, options)
export const useMutationUsosProdutoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/usos-de-produto', {}, {}, options), config)

export const queryUsosProdutoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/usos-de-produto', queryParams)
export const useQueryUsosProdutoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usos-de-produto', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsosProdutoControllerListar.queryKey = '/api/v1/usos-de-produto'

export const queryUsosProdutoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/usos-de-produto/{id}', pathParams)
export const useQueryUsosProdutoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usos-de-produto/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsosProdutoControllerObterPorId.queryKey = '/api/v1/usos-de-produto/{id}'

export const mutationUsosProdutoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/usos-de-produto/{id}', pathParams, {}, options)
export const useMutationUsosProdutoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usos-de-produto/{id}', pathParams, {}, options), config)

export const mutationUsosProdutoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/usos-de-produto/{id}', pathParams, {}, options)
export const useMutationUsosProdutoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/usos-de-produto/{id}', pathParams, {}, options), config)

export const mutationUsosProdutoControllerCadastrarUsoProdutoConta = (pathParams, options) => mutationFn('post', '/api/v1/usos-de-produto/{id}/contas', pathParams, {}, options)
export const useMutationUsosProdutoControllerCadastrarUsoProdutoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/usos-de-produto/{id}/contas', pathParams, {}, options), config)

export const queryUsosProdutoControllerListarUsoProdutoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usos-de-produto/{id}/contas', pathParams, queryParams)
export const useQueryUsosProdutoControllerListarUsoProdutoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usos-de-produto/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsosProdutoControllerListarUsoProdutoContas.queryKey = '/api/v1/usos-de-produto/{id}/contas'

export const queryUsosProdutoControllerObterUsoProdutoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/usos-de-produto/{id}/contas/{contaId}', pathParams)
export const useQueryUsosProdutoControllerObterUsoProdutoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usos-de-produto/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryUsosProdutoControllerObterUsoProdutoContaPorId.queryKey = '/api/v1/usos-de-produto/{id}/contas/{contaId}'

export const mutationUsosProdutoControllerAtualizarUsoProdutoConta = (pathParams, options) => mutationFn('put', '/api/v1/usos-de-produto/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUsosProdutoControllerAtualizarUsoProdutoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usos-de-produto/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryUsosProdutoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usos-de-produto/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryUsosProdutoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usos-de-produto/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsosProdutoControllerListarContasDisponiveis.queryKey = '/api/v1/usos-de-produto/{id}/contas-disponiveis'

export const mutationUsuariosControllerLogar = (options) => mutationFn('post', '/api/v1/usuarios/login', {}, {}, options)
export const useMutationUsuariosControllerLogar = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/login', {}, {}, options), config)

export const queryUsuariosControllerEu = (options) => queryFn(options)('/api/v1/usuarios/eu')
export const useQueryUsuariosControllerEu = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerEu.queryKey = '/api/v1/usuarios/eu'

export const queryUsuariosControllerMinhasPermissoes = (options) => queryFn(options)('/api/v1/usuarios/eu/permissoes')
export const useQueryUsuariosControllerMinhasPermissoes = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/permissoes'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerMinhasPermissoes.queryKey = '/api/v1/usuarios/eu/permissoes'

export const queryUsuariosControllerObterContasUsuarioLogado = (queryParams, options) => queryFn(options)('/api/v1/usuarios/eu/contas', queryParams)
export const useQueryUsuariosControllerObterContasUsuarioLogado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/contas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterContasUsuarioLogado.queryKey = '/api/v1/usuarios/eu/contas'

export const queryUsuariosControllerObterMinhasNotificacoes = (queryParams, options) => queryFn(options)('/api/v1/usuarios/eu/notificacoes', queryParams)
export const useQueryUsuariosControllerObterMinhasNotificacoes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/notificacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterMinhasNotificacoes.queryKey = '/api/v1/usuarios/eu/notificacoes'

export const mutationUsuariosControllerAtualizarStatusNotificacoes = (options) => mutationFn('put', '/api/v1/usuarios/eu/notificacoes', {}, {}, options)
export const useMutationUsuariosControllerAtualizarStatusNotificacoes = (config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/notificacoes', {}, {}, options), config)

export const mutationUsuariosControllerRemoverVisualizacaoMinhasNotificacoes = (options) => mutationFn('patch', '/api/v1/usuarios/eu/notificacoes/remover-visualizacao', {}, {}, options)
export const useMutationUsuariosControllerRemoverVisualizacaoMinhasNotificacoes = (config, options) => useMutation(mutationFn('patch', '/api/v1/usuarios/eu/notificacoes/remover-visualizacao', {}, {}, options), config)

export const queryUsuariosControllerObterNotificacoesChannel = (queryParams, options) => queryFn(options)('/api/v1/usuarios/eu/channel/notificacoes', queryParams)
export const useQueryUsuariosControllerObterNotificacoesChannel = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/channel/notificacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterNotificacoesChannel.queryKey = '/api/v1/usuarios/eu/channel/notificacoes'

export const mutationUsuariosControllerDeletarNotificacoesChannel = (options) => mutationFn('delete', '/api/v1/usuarios/eu/channel/notificacoes', {}, {}, options)
export const useMutationUsuariosControllerDeletarNotificacoesChannel = (config, options) => useMutation(mutationFn('delete', '/api/v1/usuarios/eu/channel/notificacoes', {}, {}, options), config)

export const queryUsuariosControllerObterNotificacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/eu/notificacoes/{id}', pathParams)
export const useQueryUsuariosControllerObterNotificacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/notificacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterNotificacaoPorId.queryKey = '/api/v1/usuarios/eu/notificacoes/{id}'

export const mutationUsuariosControllerAtualizarNotificacaoUsuarioRecebido = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/recebido', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarNotificacaoUsuarioRecebido = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/recebido', pathParams, {}, options), config)

export const mutationUsuariosControllerAtualizarNotificacaoUsuarioLido = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/lido', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarNotificacaoUsuarioLido = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/lido', pathParams, {}, options), config)

export const mutationUsuariosControllerAtualizarNotificacaoUsuarioClicado = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/clicado', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarNotificacaoUsuarioClicado = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/clicado', pathParams, {}, options), config)

export const queryUsuariosControllerObterCaixaAtual = (options) => queryFn(options)('/api/v1/usuarios/eu/caixa-atual')
export const useQueryUsuariosControllerObterCaixaAtual = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/caixa-atual'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterCaixaAtual.queryKey = '/api/v1/usuarios/eu/caixa-atual'

export const mutationUsuariosControllerTrocarConta = (options) => mutationFn('put', '/api/v1/usuarios/eu/trocar-conta', {}, {}, options)
export const useMutationUsuariosControllerTrocarConta = (config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/trocar-conta', {}, {}, options), config)

export const queryUsuariosControllerObterTermoPendente = (options) => queryFn(options)('/api/v1/usuarios/eu/termos/pendente')
export const useQueryUsuariosControllerObterTermoPendente = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/termos/pendente'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterTermoPendente.queryKey = '/api/v1/usuarios/eu/termos/pendente'

export const queryUsuariosControllerObterTermoAceitoAtivoUsuarioLogado = (options) => queryFn(options)('/api/v1/usuarios/eu/termos/ativo')
export const useQueryUsuariosControllerObterTermoAceitoAtivoUsuarioLogado = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/termos/ativo'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterTermoAceitoAtivoUsuarioLogado.queryKey = '/api/v1/usuarios/eu/termos/ativo'

export const mutationUsuariosControllerAceitarTermo = (options) => mutationFn('post', '/api/v1/usuarios/eu/termos/aceite', {}, {}, options)
export const useMutationUsuariosControllerAceitarTermo = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/eu/termos/aceite', {}, {}, options), config)

export const queryUsuariosControllerObterTermoPorId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/eu/termos/{termoAceiteLgpdId}', pathParams)
export const useQueryUsuariosControllerObterTermoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/termos/{termoAceiteLgpdId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.termoAceiteLgpdId, ...config }
})
useQueryUsuariosControllerObterTermoPorId.queryKey = '/api/v1/usuarios/eu/termos/{termoAceiteLgpdId}'

export const mutationUsuariosControllerRevogarTermo = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/eu/termos/revogacao/{id}', pathParams, {}, options)
export const useMutationUsuariosControllerRevogarTermo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/termos/revogacao/{id}', pathParams, {}, options), config)

export const queryUsuariosControllerObterTermosAceitosPorUsuarioId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usuarios/termos/{id}', pathParams, queryParams)
export const useQueryUsuariosControllerObterTermosAceitosPorUsuarioId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/termos/{id}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterTermosAceitosPorUsuarioId.queryKey = '/api/v1/usuarios/termos/{id}'

export const queryUsuariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/{id}', pathParams)
export const useQueryUsuariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterPorId.queryKey = '/api/v1/usuarios/{id}'

export const mutationUsuariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}', pathParams, {}, options), config)

export const mutationUsuariosControllerAtualizarContaAtual = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}/eu-conta-atual', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarContaAtual = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}/eu-conta-atual', pathParams, {}, options), config)

export const mutationUsuariosControllerEnviarRecuperacaoSenha = (options) => mutationFn('post', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options)
export const useMutationUsuariosControllerEnviarRecuperacaoSenha = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options), config)

export const mutationUsuariosControllerVerificarCodigoRecuperacaoSenha = (options) => mutationFn('put', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options)
export const useMutationUsuariosControllerVerificarCodigoRecuperacaoSenha = (config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options), config)

export const mutationUsuariosControllerConfirmarRecuperacaoSenha = (options) => mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options)
export const useMutationUsuariosControllerConfirmarRecuperacaoSenha = (config, options) => useMutation(mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options), config)

export const mutationUsuariosControllerConfirmarRecuperacaoSenhaPortal = (options) => mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options)
export const useMutationUsuariosControllerConfirmarRecuperacaoSenhaPortal = (config, options) => useMutation(mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options), config)

export const mutationUsuariosControllerVerificarCodigoRecuperacaoSenhaPortal = (options) => mutationFn('put', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options)
export const useMutationUsuariosControllerVerificarCodigoRecuperacaoSenhaPortal = (config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options), config)

export const mutationUsuariosControllerEnviarRecuperacaoSenhaPortal = (options) => mutationFn('post', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options)
export const useMutationUsuariosControllerEnviarRecuperacaoSenhaPortal = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options), config)

export const mutationUsuariosControllerEnviarCriacaoSenhaPortal = (options) => mutationFn('post', '/api/v1/usuarios/senhas/criacao-portal', {}, {}, options)
export const useMutationUsuariosControllerEnviarCriacaoSenhaPortal = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/senhas/criacao-portal', {}, {}, options), config)

export const queryUsuariosControllerObterContasUsuario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas', pathParams, queryParams)
export const useQueryUsuariosControllerObterContasUsuario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterContasUsuario.queryKey = '/api/v1/usuarios/{id}/contas'

export const mutationUsuariosControllerCadastrarUsuarioConta = (pathParams, options) => mutationFn('post', '/api/v1/usuarios/{id}/contas', pathParams, {}, options)
export const useMutationUsuariosControllerCadastrarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/{id}/contas', pathParams, {}, options), config)

export const queryUsuariosControllerObterContasDisponiveisUsuario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryUsuariosControllerObterContasDisponiveisUsuario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterContasDisponiveisUsuario.queryKey = '/api/v1/usuarios/{id}/contas-disponiveis'

export const queryUsuariosControllerObterUsuarioContaPorUsuarioId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas/{contaId}', pathParams)
export const useQueryUsuariosControllerObterUsuarioContaPorUsuarioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryUsuariosControllerObterUsuarioContaPorUsuarioId.queryKey = '/api/v1/usuarios/{id}/contas/{contaId}'

export const mutationUsuariosControllerAtualizarUsuarioConta = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const mutationUsuariosControllerDeletarUsuarioConta = (pathParams, options) => mutationFn('delete', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUsuariosControllerDeletarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryVersoesTissControllerListar = (queryParams, options) => queryFn(options)('/api/v1/versoes-do-tiss', queryParams)
export const useQueryVersoesTissControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/versoes-do-tiss', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryVersoesTissControllerListar.queryKey = '/api/v1/versoes-do-tiss'

export const mutationVersoesTissControllerCadastrar = (options) => mutationFn('post', '/api/v1/versoes-do-tiss', {}, {}, options)
export const useMutationVersoesTissControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/versoes-do-tiss', {}, {}, options), config)

export const queryVersoesTissControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/versoes-do-tiss/{id}', pathParams)
export const useQueryVersoesTissControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/versoes-do-tiss/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryVersoesTissControllerObterPorId.queryKey = '/api/v1/versoes-do-tiss/{id}'

export const mutationVersoesTissControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/versoes-do-tiss/{id}', pathParams, {}, options)
export const useMutationVersoesTissControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/versoes-do-tiss/{id}', pathParams, {}, options), config)

export const mutationVersoesTissControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/versoes-do-tiss/{id}', pathParams, {}, options)
export const useMutationVersoesTissControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/versoes-do-tiss/{id}', pathParams, {}, options), config)

export const queryVersoesTissControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/versoes-do-tiss/{chave}', pathParams)
export const useQueryVersoesTissControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/versoes-do-tiss/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryVersoesTissControllerObterPorChave.queryKey = '/api/v1/versoes-do-tiss/{chave}'

export const mutationVersoesTissControllerCadastrarVersaoTissConta = (pathParams, options) => mutationFn('post', '/api/v1/versoes-do-tiss/{id}/contas', pathParams, {}, options)
export const useMutationVersoesTissControllerCadastrarVersaoTissConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/versoes-do-tiss/{id}/contas', pathParams, {}, options), config)

export const queryVersoesTissControllerListarVersaoTissContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/versoes-do-tiss/{id}/contas', pathParams, queryParams)
export const useQueryVersoesTissControllerListarVersaoTissContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/versoes-do-tiss/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryVersoesTissControllerListarVersaoTissContas.queryKey = '/api/v1/versoes-do-tiss/{id}/contas'

export const queryVersoesTissControllerObterVersaoTissContaPorId = (pathParams, options) => queryFn(options)('/api/v1/versoes-do-tiss/{id}/contas/{contaId}', pathParams)
export const useQueryVersoesTissControllerObterVersaoTissContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/versoes-do-tiss/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryVersoesTissControllerObterVersaoTissContaPorId.queryKey = '/api/v1/versoes-do-tiss/{id}/contas/{contaId}'

export const mutationVersoesTissControllerAtualizarVersaoTissConta = (pathParams, options) => mutationFn('put', '/api/v1/versoes-do-tiss/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationVersoesTissControllerAtualizarVersaoTissConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/versoes-do-tiss/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryVersoesTissControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/versoes-do-tiss/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryVersoesTissControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/versoes-do-tiss/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryVersoesTissControllerListarContasDisponiveis.queryKey = '/api/v1/versoes-do-tiss/{id}/contas-disponiveis'

export const queryWhastappTemplatesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/whatsapp-templates', queryParams)
export const useQueryWhastappTemplatesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/whatsapp-templates', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryWhastappTemplatesControllerListar.queryKey = '/api/v1/whatsapp-templates'

export const mutationWhastappTemplatesControllerCadastrar = (options) => mutationFn('post', '/api/v1/whatsapp-templates', {}, {}, options)
export const useMutationWhastappTemplatesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/whatsapp-templates', {}, {}, options), config)

export const mutationWhastappTemplatesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/whatsapp-templates/{id}', pathParams, {}, options)
export const useMutationWhastappTemplatesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/whatsapp-templates/{id}', pathParams, {}, options), config)

export const mutationWhastappTemplatesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/whatsapp-templates/{id}', pathParams, {}, options)
export const useMutationWhastappTemplatesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/whatsapp-templates/{id}', pathParams, {}, options), config)

export const queryWhastappTemplatesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/whatsapp-templates/{id}', pathParams)
export const useQueryWhastappTemplatesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/whatsapp-templates/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWhastappTemplatesControllerObterPorId.queryKey = '/api/v1/whatsapp-templates/{id}'

export const queryWorkFlowsControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/work-flows', queryParams)
export const useQueryWorkFlowsControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryWorkFlowsControllerBuscar.queryKey = '/api/v1/work-flows'

export const mutationWorkFlowsControllerCadastrar = (options) => mutationFn('post', '/api/v1/work-flows', {}, {}, options)
export const useMutationWorkFlowsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/work-flows', {}, {}, options), config)

export const queryWorkFlowsControllerObterCrudPorId = (pathParams, options) => queryFn(options)('/api/v1/work-flows/{id}/crud', pathParams)
export const useQueryWorkFlowsControllerObterCrudPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/crud', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerObterCrudPorId.queryKey = '/api/v1/work-flows/{id}/crud'

export const queryWorkFlowsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/work-flows/{id}', pathParams)
export const useQueryWorkFlowsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerObterPorId.queryKey = '/api/v1/work-flows/{id}'

export const mutationWorkFlowsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/work-flows/{id}', pathParams, {}, options)
export const useMutationWorkFlowsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/work-flows/{id}', pathParams, {}, options), config)

export const mutationWorkFlowsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/work-flows/{id}', pathParams, {}, options)
export const useMutationWorkFlowsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/work-flows/{id}', pathParams, {}, options), config)

export const queryWorkFlowsControllerListarFiltrosPorId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/work-flows/{id}/filtros', pathParams, queryParams)
export const useQueryWorkFlowsControllerListarFiltrosPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/filtros', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerListarFiltrosPorId.queryKey = '/api/v1/work-flows/{id}/filtros'

export const mutationWorkFlowsControllerCadastrarWorkFlowConta = (pathParams, options) => mutationFn('post', '/api/v1/work-flows/{id}/contas', pathParams, {}, options)
export const useMutationWorkFlowsControllerCadastrarWorkFlowConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/work-flows/{id}/contas', pathParams, {}, options), config)

export const queryWorkFlowsControllerListarWorkFlowContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/work-flows/{id}/contas', pathParams, queryParams)
export const useQueryWorkFlowsControllerListarWorkFlowContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerListarWorkFlowContas.queryKey = '/api/v1/work-flows/{id}/contas'

export const queryWorkFlowsControllerObterWorkFlowContaPorWorkFlowIdContaId = (pathParams, options) => queryFn(options)('/api/v1/work-flows/{id}/contas/{contaId}', pathParams)
export const useQueryWorkFlowsControllerObterWorkFlowContaPorWorkFlowIdContaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryWorkFlowsControllerObterWorkFlowContaPorWorkFlowIdContaId.queryKey = '/api/v1/work-flows/{id}/contas/{contaId}'

export const mutationWorkFlowsControllerAtualizarWorkFlowConta = (pathParams, options) => mutationFn('put', '/api/v1/work-flows/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationWorkFlowsControllerAtualizarWorkFlowConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/work-flows/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryWorkFlowsControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/work-flows/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryWorkFlowsControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerListarContasDisponiveis.queryKey = '/api/v1/work-flows/{id}/contas-disponiveis'

export const queryWorkFlowsControllerBuscarFasesFunis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/work-flows/{id}/fases-funis', pathParams, queryParams)
export const useQueryWorkFlowsControllerBuscarFasesFunis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/fases-funis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerBuscarFasesFunis.queryKey = '/api/v1/work-flows/{id}/fases-funis'

export const mutationWorkFlowsControllerCadastrarFaseFunil = (pathParams, options) => mutationFn('post', '/api/v1/work-flows/{id}/fases-funis', pathParams, {}, options)
export const useMutationWorkFlowsControllerCadastrarFaseFunil = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/work-flows/{id}/fases-funis', pathParams, {}, options), config)

export const mutationWorkFlowsControllerAtualizarWorkFlowFaseFunilOrdem = (pathParams, options) => mutationFn('put', '/api/v1/work-flows/{id}/fases-funis-ordens', pathParams, {}, options)
export const useMutationWorkFlowsControllerAtualizarWorkFlowFaseFunilOrdem = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/work-flows/{id}/fases-funis-ordens', pathParams, {}, options), config)

