import { useState, useCallback } from 'react'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { createContainer } from '@sponte/lib-utils/dist/hooks/useContainer'
import { useLocalStorage } from '@sponte/lib-utils/dist/hooks/useLocalStorage'

function usePreferencesHook() {
  const [preferences, setPreferences] = useLocalStorage(process.env.REACT_APP_USER_PREFERENCES_KEY, {})

  const getPreference = useCallback(
    (key, fallback) => {
      return getSafe(preferences, key, fallback)
    },
    [preferences]
  )

  const setPreference = useCallback(
    (key, value) => {
      setPreferences((state) => {
        return {
          ...state,
          [key]: value
        }
      })
    },
    [setPreferences]
  )

  return {
    preferences,
    getPreference,
    setPreference
  }
}

export const [PreferencesProvider, usePreferences] = createContainer(usePreferencesHook)

export function usePreference(key, fallback) {
  const { getPreference, setPreference } = usePreferencesHook()

  const [storedPreference, setStoredPreference] = useState(getPreference(key, fallback))

  const set = useCallback(
    (value) => {
      setStoredPreference(value)
      setPreference(key, value)
    },
    [key, setPreference, setStoredPreference]
  )

  return [storedPreference, set]
}
