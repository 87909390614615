export default {
  geral: {
    app: 'MedPlus',
    atencao: 'Atenção!',
    planoSaude: 'Plano de Saúde',
    prontuario: 'Prontuário',
    paciente: 'Paciente',
    pacientes: 'Pacientes',
    pacienteResponsavel: 'Paciente/Responsável',
    tipoAgendamento: 'Tipo do agendamento',
    selecioneUmConvenio: 'Selecione um convênio',
    selecioneUmPlanoDeSaude: 'Selecione um plano de saúde',
    selecioneUmOperadoraDeSaude: 'Selecione uma operadora de saúde',
    planosEConvenios: 'Planos e convênios',
    novoOrcamento: 'Novo orçamento',
    procedimento: 'Procedimento',
    medico: 'Médico',
    novoAgendamento: 'Novo agendamento',
    upAdministracao: 'UP / Administração',
    atendimento: 'Atendimento',
    atendimento_plural: 'Atendimentos',
    dadosClinicos: 'Dados clínicos',
    selecionePaciente: 'Selecione um paciente',
    medicos: 'Médicos',
    agendamento: 'Agendamento',
    chamarWhats: 'Chamar no WhatsApp',
    enviarMensagemViaWhatsApp: 'Enviar mensagem via WhatsApp',
    historicoNotificacoes: 'Histórico de notificações',
    localEnvio: 'Local do envio',
    meioComunicacaoUtilizado: 'Meio de comunicação utilizado',
    historicoNotificacoesSubTitulo: 'Histórico detalhado de interações com este cliente',
    contatoAtravesAgenda: 'Contato através da agenda',
    contatoAtravesCliente: 'Contato através do cadastro do cliente',
    remetenteEnvio: 'Remetente do envio',
    dataHoraAgendamento: 'Data e hora do agendamento',
    semPaciente: 'Sem paciente',
    countPacientesAgendados: '{{count}} pacientes agendados',
    adicionarPaciente: 'Adicionar paciente',
    adicionarMedico: 'Adicionar médico',
    usaCID10: 'Usa CID10',
    usaCIAP2: 'Usa CIAP2',
    atendimentosEmAberto: 'Atendimentos em aberto',
    pacientesEmEspera: 'Pacientes em espera',
    novoAtendimento: 'Novo atendimento',
    emDilatacao: 'Em dilatação',
    iniciarAtendimento: 'Iniciar atendimento',
    retomarAtendimento: 'Retomar atendimento',
    verExames: 'Ver exames',
    convenio: 'Convênio',
    triagem: 'Triagem',
    descartarAtendimento: 'Descartar atendimento',
    historicoDoPaciente: 'Histórico do paciente',
    comercial: 'Relacionamento',
    motivoComercial: 'Motivo do Relacionamento',
    historicoDeAtendimento: 'Histórico de atendimento',
    laudo: 'Laudo',
    laudo_plural: 'Laudos',
    gerarTiss: 'Gerar TISS',
    operadoraPlanoDeSaude: 'Operadora de Plano de Saúde',
    tabelaDeProcedimentos: 'Tabela de procedimentos',
    tabelaDeProcedimento: 'Tabela de procedimento',
    grupoDeProcedimentos: 'Grupo de procedimentos',
    grupoDeProcedimento: 'Grupo de procedimento',
    posologia: 'Posologia',
    medicamentoPosologia: 'Medicamento / Posologia',
    pacientesEmAtendimento: 'Pacientes em atendimento',
    finalizarAtendimento: 'Finalizar atendimento',
    conduta: 'Conduta',
    agendarConsultaApartirDe: 'Agendar consulta apartir de',
    cid: 'CID',
    motivoDesfecho: 'Motivo do desfecho',
    queixaPrincipal: 'Queixa principal',
    antecedentes: 'Antecedentes',
    tipoTempoPrevisto: 'Tipo de tempo previsto',
    atender: 'Atender',
    encaixar: 'Encaixar',
    prescricoes: 'Prescrições',
    personalizarReceituario: 'Personalizar Receituário',
    mostrarNomeDoPaciente: 'Mostrar nome do paciente',
    mostrarNomeDoFabricante: 'Mostrar nome do fabricante',
    mostrarUnidades: 'Mostrar unidades',
    protocoloDeExames: 'Protocolo de exames',
    tiss: 'TISS',
    guias: 'Guias',
    faturas: 'Faturas',
    tempoMedioAtendimento: 'Tempo médio de atendimento',
    adicionarCid: 'Adicionar CID',
    salaDeEspera: 'Sala de espera',
    tempoDeAtendimento: 'Tempo de atendimento',
    totalDeAgendamentos: 'Total de agendamentos',
    agendados: 'Agendados',
    atendidos: 'Atendidos',
    encaixes: 'Encaixes',
    nConsulta: '{{number}}ª consulta',
    retornos: 'Retornos',
    cidsRecorrentes: 'CIDs recorrentes',
    editarFormula: 'Editar fórmula',
    integracoes: 'Integrações',
    rqe: 'RQE',
    crm: 'CRM',
    convenioPlano: 'Convênio e plano',
    dilatacoes: 'Dilatações',
    prescricaoOculos: 'Prescrição de óculos',
    verProntuario: 'Ver prontuário',
    verAtendimentos: 'Ver atendimentos',
    dataAtendimento: 'Data do atendimento',
    comentario: 'Comentário',
    novoLaudo: 'Novo laudo',
    nota: 'Nota',
    copiarLink: 'Copiar link',
    teleconsulta: 'Teleconsulta',
    teleconsulta_plural: 'Teleconsultas',
    copiarLinkTeleconsulta: 'Copiar link da teleconsulta',
    linkTeleconsultaEnvioManual:
      'O paciente não possui e-mail ou telefone cadastrados para envio do link da teleconsulta. Será necessário enviá-lo manualmente.',
    consulta_presencial: 'Consulta presencial',
    consulta_presencial_plural: 'Consultas presenciais',
    aceitoNoDia: 'Aceito no dia',
    termoAceiteTeleconsulta: 'Termo de aceite da teleconsulta',
    situacaoAgendamento: 'Situação Agendamento',
    numeroCartaoSUS: 'Documento SUS',
    tiposAgendamento: 'Tipos de agendamento',
    templateComercial: 'Template comercial',
    tipoDePlano: 'Tipo de plano',
    convenioPadrao: 'Convênio padrão',
    numeroDaCarteira: 'Número da carteira',
    validadeDaCarteira: 'Validade da carteira',
    modelosProntuarioDisponiveis: 'Modelos de prontuário disponíveis',
    modeloProntuarioAtual: 'Modelo de prontuário atual',
    retornoApartirDe: 'Retorno apartir de',
    modeloProntuario: 'Modelo de prontuário',
    opcoesMultiSelect: 'Opções multiselect',
    grupoDeDocumento: 'Grupo de documento',
    numeroProntuario: 'Número do prontuário',
    exibirCarimbo: 'Exibir carimbo',
    carimbo: 'Carimbo',
    titulacao: 'Titulação',
    receita: 'Receita',
    receitaMemed: 'Receita Memed',
    receitasMemed: 'Receitas Memed',
    preAtendimento: 'Pré atendimento',
    nomeMedico: 'Nome do médico',
    horaInicial: 'Hora Inicial',
    horaFinal: 'Hora Final',
    perto: 'Perto',
    longe: 'Longe',
    tipoVisao: 'Tipo de visão',
    salvarImprimir: 'Salvar e imprimir',
    imprimirHistorico: 'Imprimir histórico',
    naoPossuiHistoricoChat: 'Não possui histórico de chat',
    agendamentoMultiplo: 'Agendamento múltiplo',
    quantidadeAgendamentos: 'Quantidade de agendamentos',
    ateDia: 'Até o dia',
    agendamentoMultiploDataOcupada: 'Este horário já está ocupado na data de {{date}}. Deseja continuar?',
    excluirAgendamentoMultiplo: 'Deseja remover agendamento múltiplo?',
    este: 'Este',
    repeticaoSemanal: 'Repetição semanal',
    calculando: 'Calculando',
    crmEspecialidade: 'CRM e Especialidade',
    registroEspecialidade: 'Registro ou especialidade',
    novoRegistroEspecialidade: 'Adicionar registro ou especialidade',
    novoCrmEspecialidade: 'Adicionar CRM ou Especialidade',
    considerarDiasUteis: 'Considerar dias úteis no vencimento das parcelas',
    dataExclusao: 'Data Exclusão',
    movimentoExclusaoAgendamentos: 'Movimento de exclusão de agendamentos',
    horarioEncerramentoMenorQueHorarioInicio: 'Horário de encerramento não pode ser menor que o horário do início.',
    profissionalSaude: 'Profissional de saúde',
    dataPrimeiroVencimento: 'Data primeiro vencimento',
    confirmaRemocaoSecaoAtendimento:
      'Atenção! Foram inseridos dados nesta seção que serão perdidos. Deseja removê-la? ',

    feedbacks: {
      aguardandoChegadaPacientes: 'Aguardando a chegada dos pacientes',
      aguardandoEntradaNovosPacientes: 'Aguardando a entrada de novos pacientes',
      nenhumAtendimentoPausa: 'Nenhum paciente com atendimento em pausa',
      nenhumPacienteSalaEspera: 'Nenhum paciente na sala de espera',
      nenhumAtendimentoRegistrado: 'Nenhum atendimento registrado',
      nenhumAtendimentoEmAberto: 'Nenhum atendimento em aberto',
      cliqueEmIniciarAtendimento: 'Clique em iniciar atendimento',
      cliqueEmRetomarAtendimento: 'Clique em retomar atendimento',
      tempoPrevistoRetornoSucesso: 'Tempo previsto para retorno adicionado com sucesso',
      nenhumaPrescricaoRegistrada: 'Nenhuma prescrição registrada',
      nenhumaFormulaRegistrada: 'Nenhuma fórmula registrada',
      cidAdicionadoSucesso: 'CID adicionado com sucesso!',
      cidRemovidoSucesso: 'CID removido com sucesso!',
      operacaoNaoPermitidaAtendimentos: 'Operação não permitida para atendimentos!',
      pacienteEmAtendimentoOutroMedico: 'O paciente está em atendimento com outro médico!',
      teleconsultaNaoEncontrada: 'Teleconsulta não encontrada!',
      pacienteSemConsultaAgendada: 'O paciente não tem nenhuma teleconsulta agendada',
      teleconsultaFinalizadaSucesso: 'Teleconsulta finalizada com sucesso',
      teleconsultaFinalizadaErro: 'Erro ao finalizar a teleconsulta',
      medicoOuClinicaSemHorarioDeAtendimentoNesseDia:
        'A clínica e/ou médico não tem nenhum calendário disponível para essa data',
      nenhumaAssinaturaPendente: 'Nenhuma assinatura pendente',
      obrigatorioParaAdicionarDesconto: 'Obrigatório para adicionar desconto',
      nenhumPlanoCadastradoNesseConvenio: 'Nenhum plano cadastrado nesse convênio',
      errorAbrirMemed: 'Erro ao abrir a Memed',
      nenhumaOportunidadeEncontrada: 'Nenhuma Oportunidade encontrada para este filtro.',
      aoFinalizarTeleconsultaLinkSeraInativadoMedicoPaciente:
        'Ao finalizar esta chamada o link da teleconsulta será inativado para médico e paciente!',
      conexaoTeleconsultaAindaNaoFoiEstabelecidade: 'A conexão da teleconsulta ainda não foi estabelecida!',
      historicoInexistente: 'Ainda não existe histórico deste paciente',
      imprimirHistorico: 'Imprimir histórico',
      realizeUploadImagemPacienteOuNovoFiltro: 'Realize o upload de uma imagem do paciente ou um novo filtro!',
      linkTeleconsultaCopiadoComSucesso: 'Link da teleconsulta copiado para a área de transferência',
      linkTeleconsultaErroAoCopiar: 'Erro ao copiar link da teleconsulta',
      tipoUsuarioTarifado: 'Tipo de usuário tarifado',
      modeloDeImpressaoNaoSelecionado: 'Você não possui nenhum modelo de impressão selecionado ou configurado.',
      teleconsultaAberta: 'Feche a teleconsulta aberta antes de iniciar outro atendimento.'
    },

    navegacao: {
      'locais-de-estoque': 'Locais de estoque',
      'central-tiss': 'Central TISS',
      clinicas: 'Clinicas',
      procedimentos: 'Procedimentos',
      posologias: 'Posologias',
      'dados-clinicos': 'Dados clínicos',
      'situacoes-agendamento': 'Situações de agendamento',
      'categorias-agendamento': 'Tipos de agendamento',
      'motivos-cancelamento-do-agendamento': 'Motivos de cancelamento',
      'fila-de-espera': 'Fila de espera',
      'sala-de-espera': 'Sala de espera',
      clinico: 'Clínico',
      tiss: 'TISS',
      'preferencias-do-tiss': 'Preferências do TISS',
      convenio: 'Convênio',
      'modelos-de-prontuario': 'Modelos de prontuário',
      'modelos-de-impressos': 'Modelos de impressos',
      'motivos-desfecho-atendimento': 'Motivo de desfecho',
      atendimentos: 'Atendimentos',
      'preferencias-do-comercial': 'Preferências do Relacionamento',
      comercial: 'Relacionamento',
      'protocolos-e-exames': 'Protocolos e exames',
      'tabelas-de-procedimentos': 'Tabelas de procedimentos',
      'grupos-de-procedimentos': 'Grupos de procedimentos',
      'preferencias-do-clinico': 'Preferências do clínico',
      'classificacoes-de-produtos': 'Classificações de produtos',
      formulas: 'Fórmulas',
      'dashboard-do-clinico': 'Dashboard do clínico',
      'modelos-de-laudo': 'Modelos de laudo',
      'versoes-do-tiss': 'Versões do TISS',
      'dashboard-do-relacionamento': 'Dashboard do relacionamento',
      'fluxo-automacao-crm': 'Automação',
      'notificacoes-teleconsulta': 'Notificações de teleconsulta',
      'templates-comerciais': 'Templates comerciais',
      desfechos: 'Desfechos',
      'pre-atendimento': 'Pré-atendimento e Retornos',
      'servicos-contratacoes': 'Serviços e Contratações',
      'modelos-de-receituario': 'Modelos de receituário',
      'saidas-servicos-e-itens': 'Saídas - Serviços e itens',
      'documentos-fiscais': 'Documentos Fiscais',
      whatsApp: 'WhatsApp'
    }
  },

  cadastro: {
    clientes: {
      numeroProntuario: 'Número do prontuário',
      financeiro: {
        atendimento: {
          novo: 'Novo atendimento'
        }
      },

      dadosClinicos: {
        precaucoes: 'Precauções',
        alergiaMedicamentosa: 'Alergia medicamentosa',
        medicamentoUsoContinuo: 'Medicamentos de uso contínuo',
        principioAtivo: 'Princípio ativo',
        informePrecaucao: 'Informe uma precaução',
        informeMedicamento: 'Informe um medicamento',
        buscarPrincipioAtivo: 'Buscar um princípio ativo',
        medicamentoCadastradoSucesso: 'Medicamento cadastrado com sucesso!',
        medicamentoRemovidoSucesso: 'Medicamento removido com sucesso!',
        alergiaMedicamentosaNaoEncontrada: 'Alergia medicamentosa não encontrada'
      }
    },

    funcionarios: {
      especialidades: {
        titulo: 'Dados da Especialidade',
        deletadoSucesso: 'Especialidade removida com sucesso',
        criadoSucesso: 'Especialidade adicionada com sucesso',
        atualizadoSucesso: 'Especialidade atualizada com sucesso',
        registro: 'Número do registro',
        novo: 'Nova especialidade',
        adicionar: 'Adicionar especialidade',
        editar: 'Editar especialidade',
        especialidadePadrao: 'Especialidade padrão',
        nenhumaEspecialidadeRegistrada: 'Nenhuma especialidade registrada',
        especialidadeJaCadastrada: 'Especialidade já cadastrada para esse funcionário',
        especialidadePadraoExistente:
          'A especialidade {{especialidadePadrao}} já está como padrão. Deseja alterar o padrão para a especialidade {{especialidadeAtual}}?',
        especialidadePadraoNecessaria: 'É necessário definir outra especialidade como padrão',

        subespecialidades: {
          titulo: 'Subespecialidade',
          novo: 'Nova subespecialidade',
          adicionar: 'Adicionar subespecialidade',
          adicionadaSucesso: 'Subespecialidade adicionada com sucesso',
          removidaSucesso: 'Subespecialidade removida com sucesso'
        }
      },

      registro: {
        titulo: 'Dados do registro',
        deletadoSucesso: 'Registro removido com sucesso',
        criadoSucesso: 'Registro adicionado com sucesso',
        atualizadoSucesso: 'Registro atualizado com sucesso',
        novo: 'Novo registro',
        adicionar: 'Adicionar registro',
        editar: 'Editar registro',
        registroJaCadastrada: 'Registro já cadastrado para esse funcionário',
        relacionado: 'Registro relacionado'
      },

      crm: {
        titulo: 'Dados do CRM',
        deletadoSucesso: 'CRM removida com sucesso',
        criadoSucesso: 'CRM adicionada com sucesso',
        registro: 'Número do CRM',
        novo: 'Nova CRM',
        adicionar: 'Adicionar CRM',
        relacionado: 'CRM relacionado',
        crmPossuiVinculoComEspecialidade: 'Esse registro possui vínculo com uma ou mais especialidades do funcionário'
      },

      integracoes: {
        integracoes: 'Integrações',
        detalhesDaIntegracao: 'Detalhes da Integração',
        sincronizadoSucesso: 'Sincronização realizada com sucesso',
        status: 'Status',
        mensagens: 'Mensagens',
        nenhumaFalhaRegistrada: 'Nenhuma falha registrada',
        salvaSucesso: 'Integração salva com sucesso',

        memed: {
          titulo: 'Integração Memed',
          novo: 'Adicionar integração Memed',
          naoPossuiCrmParaIntegracao: 'Esse funcionário não possui CRM para a utilização da MEMED'
        },

        soluti: {
          titulo: 'Integração Soluti',
          novo: 'Adicionar integração Soluti',
          integracaoAtiva: 'Integração Ativa'
        }
      },

      licencaAdicional: {
        adicionarLicenca: 'Adicionar licença',
        suasLicencasTipo: 'Suas licenças do tipo',
        acabaramContratarMais: 'acabaram, gostaria de contratar mais?',
        adicioneLicencaPor: 'Adicione {{amount}} licença por $t(geral:currency) {{value, currency|BRL}}',
        emailConfirmacao: 'Ao adicionar uma licença você receberá um e-mail de confirmação',
        observacaoAcesso:
          'OBS.: Para que o novo usuário criado possa utilizar agenda e prontuário, utilize a aba Acesso do cadastro para criar o login e senha.',
        confirmarSalvar: 'Confirmar e salvar'
      }
    },

    preferencias: {
      documentos: {
        modelosDeImpressos: {
          titulo: 'Modelos de impressos',
          novo: 'Novo modelo de impresso',
          cadastradoSucesso: 'Modelo de impresso cadastrado com sucesso!',
          atualizadoSucesso: 'Modelo de impresso atualizado com sucesso!',
          removidoSucesso: 'Modelo de impresso removido com sucesso!',

          imagem: {
            cadastradoSucesso: 'Imagem cadastrada com sucesso!',
            atualizadoSucesso: 'Imagem atualizada com sucesso!',
            removidoSucesso: 'Imagem removida com sucesso!'
          }
        }
      }
    }
  },

  catalogo: {
    produtos: {
      apresentacao: 'Apresentação',
      formula: 'Fórmula',
      dosagem: 'Dosagem',
      fracao: 'Fração',
      registroAnvisa: 'Registro ANVISA',
      codigoAbramge: 'Código ABRAMGE',
      codigoTiss: 'Código TISS',
      tabelaTiss: 'Tabela TISS',

      usosDeProdutos: {
        usosDeProduto: 'Uso de Produto',
        novo: 'Novo Uso de Produto',
        cadastradoSucesso: 'Uso de produto cadastrado com sucesso!'
      },

      posologias: {
        titulo: 'Posologias',
        novo: 'Nova posologia',
        cadastradoSucesso: 'Posologia vinculada com sucesso!',
        removidoSucesso: 'Posologia removida com sucesso!'
      }
    },

    procedimentos: {
      tributacao: {
        novo: 'Nova tributação',
        cadastradoSucesso: 'Tributação cadastrada com sucesso!',
        atualizadoSucesso: 'Tributação atualizada com sucesso!',
        removidoSucesso: 'Tributação removida com sucesso!'
      }
    },

    preferencias: {
      classificacoesDeProdutos: {
        titulo: 'Classificações de produtos',
        novo: 'Nova classificação',
        cadastradoSucesso: 'Classificação cadastrada com sucesso!',
        atualizadoSucesso: 'Classificação atualizada com sucesso!',
        removidoSucesso: 'Classificação removida com sucesso!'
      },

      gruposDeProcedimentos: {
        titulo: 'Grupos de procedimentos',
        novo: 'Novo grupo de procedimentos',
        cadastradoSucesso: 'Grupo de procedimentos cadastrado com sucesso!',
        atualizadoSucesso: 'Grupo de procedimentos atualizado com sucesso!',
        removidoSucesso: 'Grupo de procedimentos removido com sucesso!'
      },

      posologias: {
        titulo: 'Posologias',
        novo: 'Nova posologia',
        cadastradoSucesso: 'Posologia cadastrada com sucesso!',
        atualizadoSucesso: 'Posologia atualizada com sucesso!',
        removidoSucesso: 'Posologia removida com sucesso!'
      },

      tabelasDeProcedimentos: {
        titulo: 'Tabelas de procedimentos',
        novo: 'Nova tabela de procedimentos',
        cadastradoSucesso: 'Tabela de procedimentos cadastrada com sucesso!',
        atualizadoSucesso: 'Tabela de procedimentos atualizada com sucesso!',
        removidoSucesso: 'Tabela de procedimentos removida com sucesso!'
      }
    }
  },

  agenda: {
    agendamento: {
      titulo: 'Agendamento',
      novo: 'Novo agendamento',
      cadastradoSucesso: 'Agendamento cadastrado com sucesso!',
      atualizadoSucesso: 'Agendamento atualizado com sucesso!',
      removidoSucesso: 'Agendamento removido com sucesso!',

      pacientes: {
        titulo: 'Pacientes',
        novo: 'Adicionar paciente',
        cadastradoSucesso: 'Paciente adicionado com sucesso!',
        atualizadoSucesso: 'Paciente atualizado com sucesso!',
        removidoSucesso: 'Paciente removido com sucesso!'
      },

      medicos: {
        titulo: 'Médico',
        novo: 'Adicionar médico',
        cadastradoSucesso: 'Médico adicionado com sucesso!',
        atualizadoSucesso: 'Médico atualizado com sucesso!',
        removidoSucesso: 'Médico removido com sucesso!'
      },

      equipamentos: {
        titulo: 'Equipamentos',
        novo: 'Adicionar equipamento',
        cadastradoSucesso: 'Equipamento adicionado com sucesso!',
        atualizadoSucesso: 'Equipamento atualizado com sucesso!',
        removidoSucesso: 'Equipamento removido com sucesso!'
      },

      dilatacao: {
        listaDilatacoes: 'Lista de Dilatações',
        proxima: 'Próxima',
        ultima: 'Última',
        novaDilatacao: 'Nova Dilatação',
        editarDilatacao: 'Editar Dilatação',
        colirioDilatador: 'Colírio utilizado',
        executor: 'Usuário',
        proximaDilatacao: 'Próxima Dilatação',
        olhoEsquerdo: 'OE',
        ambos: 'Ambos',
        olhoDireito: 'OD',
        adulto: 'Adulto',
        infantil: 'Infantil',
        cadastradaSucesso: 'Dilatação cadastrada com sucesso',
        atualizadaSucesso: 'Dilatação atualizada com sucesso',
        deletadoSucesso: 'Dilatação removida com sucesso',
        horarioProxima: 'Horário / Próxima',
        colirioExecutor: 'Colírio / Executor',
        olho: 'Olho',
        incluaDilatacao: 'Inclua novas dilatações através das opções do agendamento do paciente'
      },

      financeiro: {
        novoOrcamentoVenda: 'Novo Orçamento de Venda',
        novoPedidoVenda: 'Novo Pedido de Venda',
        novoTitulo: 'Novo Título',
        vendas: {
          orcamentos: {
            novo: 'Novo orçamento',
            composicao: {
              cadastroErro: 'Valor Total da condição diferente do Valor restante do Orçamento!',
              condicoes: {
                cadastradoSucesso: 'Condição cadastrada com sucesso!',
                atualizadoSucesso: 'Condição atualizada com sucesso!',
                removidoSucesso: 'Condição removida com sucesso!'
              }
            }
          },
          contasReceber: {
            novo: 'Novo contas a receber'
          }
        }
      },

      tiss: {
        guias: 'Guias',
        convenioTipoGuia: 'Convênio / Tipo da Guia',
        valorItens: 'Valor / Itens',
        gerarGuiasImpressao: 'Gerar guias para impressão',
        tipoGuia: 'Tipo de guia',
        cadastrarGuia: 'Cadastrar guia',
        necessarioEfetivarPedidoVenda:
          'Para que os itens sejam impressos na guia é necessário efetivar o pedido de venda. Deseja continuar e informar manualmente?',
        pageSadt: {
          atualizadoSucesso: 'Guia atualizada com sucesso',
          cadastradoSucesso: 'Guia cadastrada com sucesso',
          removidoSucesso: 'Guia removida com sucesso',
          guiaConsulta: 'Guia de Consulta',
          valorProcedimento: 'Valor do Procedimento',
          dadosContratado: 'Dados do Contratado',
          dadosAtendimentoProcedimentoRealizado: 'Dados do Atendimento / Procedimentos Realizado',
          guiasImpressao: '{{number}} guia(s) para impressão',
          nGuiaPrestador: 'N° Guia no Prestador',
          guiaServicoProfissionalSadt:
            'Guia de Serviço Profissional / Serviço Auxiliar de Diagnóstico e Terapia - SP/SADT',
          registroANS: 'Registro ANS',
          numeroGuiaPrincipal: 'Número da Guia Principal',
          dataAutorizacao: 'Data da Autorização',
          dataValidadeSenha: 'Data de Validade da Senha',
          numeroGuiaAtribuidoOperadora: 'Número da Guia Atribuído pela Operadora',
          dadosBeneficiario: 'Dados do Beneficiário',
          numeroCarteira: 'Número da Carteira',
          validadeCarteira: 'Validade da Carteira',
          carteiraNacionalSaude: 'Carteira Nacional da Saúde',
          atendimentoRN: 'Atendimento RN',
          dadosSolicitante: 'Dados do Solicitante',
          codigoOperadora: 'Código na Operadora',
          nomeContratado: 'Nome do Contratado',
          nomeProfissionalSolicitante: 'Nome do Profissional Solicitante',
          nomeProfissionalExecutante: 'Nome do profissional executante',
          conselhoProfissional: 'Conselho Profissional',
          numeroConselho: 'Número do Conselho',
          UF: 'UF',
          codigoCBO: 'Código CBO',
          assinaturaProfissionalExecutante: 'Assinatura do Profissional Executante',
          assinaturaProfissionalSolicitante: 'Assinatura do Profissional Solicitante',
          dadosSolicitacaoProcedimentosItensAssistenciaisSolicitados:
            'Dados da Solicitação / Procedimentos ou Itens Assistenciais Solicitados',
          caraterAtendimento: 'Caráter do Atendimento',
          dataSolicitacao: 'Data da Solicitação',
          indicacaoClinica: 'Indicação Clínica',
          tabela: 'Tabela',
          codigoProcedimentoItemAssistencial: 'Código do Procedimento ou Item Assistencial',
          qtdeSolic: 'Qtde. Solic.',
          qtdeAut: 'Qtde. Aut.',
          dadosContratoExecutante: 'Dados Contratado Executante',
          codigoNaOperadora: 'Código na Operadora',
          codigoCNES: 'Código CNES',
          dadosAtendimento: 'Dados do Atendimento',
          tipoAtendimento: 'Tipo de Atendimento',
          indicacaoAcidente: 'Indicação de Acidente (acidente ou doença relacionada)',
          tipoConsulta: 'Tipo de Consulta',
          motivoEncerramentoAtendimento: 'Motivo do Encerramento do Atendimento',
          dadosExecucaoProcedimentoExamesRealizados: 'Dados da Execução / Procedimento e Exames Realizados',
          codigoProcedimento: 'Código do Procedimento',
          qtde: 'Qtde.',
          via: 'Via',
          tec: 'Tec.',
          fatorRedAcres: 'Fator Red/Acresc.',
          valorUnitario: 'Valor Unitário',
          valorTotal: 'Valor Total',
          identificacaoProfissionaisExecutantes: 'Identificação do(s) profissional(is) Executante(s)',
          seqRef: 'Seq.Ref',
          grauPart: 'Grau Part.',
          codigoOperadoraCPF: 'Código na Operadora/CPF',
          nomeProfissional: 'Nome do Profissional',
          dataRealizacaoProcedimentosSerie: 'Data de Realização de Procedimentos em Série',
          quantidadeRealizacaoProcedimentosSerie: 'Quantidade de Realização de Procedimentos em Série',
          assinaturaBeneficiarioResponsavel: 'Assinatura do Beneficiário ou Responsável',
          observacaoJustificativa: 'Observação/Justificativa',
          totalProcedimentos: 'Total de Procedimentos (R$)',
          totalTaxasAlugueis: 'Total de Taxas e Aluguéis (R$)',
          totalMateriais: 'Total de Materiais (R$)',
          totalOPME: 'Total de OPME (R$)',
          totalMedicamentos: 'Total de Medicamentos (R$)',
          totalGasesMedicinais: 'Total Gases Medicinais (R$)',
          totalGeral: 'Total Geral (R$)',
          assinaturaResponsavelAutorizacao: 'Assinatura do Responsável pela Autorização',
          assinaturaContratado: 'Assinatura do Contratado'
        }
      }
    },

    preferencias: {
      calendarios: {
        exibirProntuarioNaAgenda: 'Exibir prontuário na agenda'
      },

      categoriasAgendamento: {
        titulo: 'Tipos de agendamento',
        novo: 'Novo tipo de agendamento',
        cadastradoSucesso: 'Tipo de agendamento cadastrado com sucesso!',
        atualizadoSucesso: 'Tipo de agendamento atualizado com sucesso!',
        removidoSucesso: 'Tipo de agendamento removido com sucesso!'
      },

      motivosCancelamentoDoAgendamento: {
        titulo: 'Motivos de cancelamento',
        novo: 'Novo motivo',
        cadastradoSucesso: 'Motivo cadastrado com sucesso!',
        atualizadoSucesso: 'Motivo atualizado com sucesso!',
        removidoSucesso: 'Motivo removido com sucesso!'
      },

      situacoesAgendamento: {
        titulo: 'Situação de Agendamentos',
        novo: 'Nova situação',
        cadastradoSucesso: 'Situação cadastrada com sucesso!',
        atualizadoSucesso: 'Situação atualizada com sucesso!',
        removidoSucesso: 'Situação removida com sucesso!'
      },

      templatesComerciais: {
        titulo: 'Templates comerciais',
        novo: 'Novo template comercial',
        cadastradoSucesso: 'Template comercial cadastrado com sucesso!',
        atualizadoSucesso: 'Template comercial atualizado com sucesso!',
        removidoSucesso: 'Template comercial removido com sucesso!'
      }
    }
  },

  comercial: {
    dashboard: {
      portal: 'Portal do paciente'
    },

    painelDeTarefas: {
      titulo: 'Painel de tarefas',
      novo: 'Nova tarefa',
      cadastradoSucesso: 'Tarefa cadastrada com sucesso!',
      atualizadoSucesso: 'Tarefa atualizada com sucesso!',
      removidoSucesso: 'Tarefa removida com sucesso!',
      informacoes: 'Informações',
      nome: 'Título',
      selecioneTipoTarefa: 'Selecione o tipo da tarefa',
      faseTarefa: 'Fase da tarefa',
      verMais: 'Ver mais',
      horaInicio: 'Hora de início',
      horaTermino: 'Hora de Término',
      descricaoPlaceholder: 'Escreva aqui...',
      checklist: {
        titulo: 'Itens da lista',
        novo: 'Novo item',
        edit: 'Editar item',
        adicionar: 'Adicionar item',
        cadastradoSucesso: 'Item cadastrado com sucesso!',
        atualizadoSucesso: 'Item atualizado com sucesso!',
        removidoSucesso: 'Item removido com sucesso!',
        nome: 'Nome',
        comentario: 'Comentário',
        concluido: 'Concluído'
      },
      chart: {
        aFazer: 'A fazer',
        fazendo: 'Fazendo',
        atrasado: 'Atrasado',
        feito: 'Feito'
      },
      filtro: {
        titulo: 'Título',
        tipoTarefa: 'Tipo de tarefa',
        situacao: 'Situação',
        outro: 'Outros',
        limpar: 'Limpar'
      }
    },

    oportunidades: {
      titulo: 'Oportunidades',
      novo: 'Nova oportunidade',
      cadastradoSucesso: 'Oportunidade cadastrada com sucesso!',
      atualizadoSucesso: 'Oportunidade atualizada com sucesso!',
      removidoSucesso: 'Oportunidade removida com sucesso!'
    },

    questionarios: {
      titulo: 'Questionários',
      novo: 'Novo questionário',
      cadastradoSucesso: 'Questionário cadastrado com sucesso!',
      atualizadoSucesso: 'Questionário atualizado com sucesso!',
      removidoSucesso: 'Questionário removido com sucesso!'
    }
  },

  preferencias: {
    motivosComerciais: {
      titulo: 'Motivos Comerciais',
      novo: 'Novo motivo comercial',
      cadastradoSucesso: 'Motivo comercial cadastrado com sucesso!',
      atualizadoSucesso: 'Motivo comercial atualizado com sucesso!',
      removidoSucesso: 'Motivo comercial removido com sucesso!'
    }
  },

  clinico: {
    prontuarios: {
      titulo: 'Prontuários',
      novo: 'Novo prontuário',
      prontuario: 'prontuário',
      cadastradoSucesso: 'Prontuário cadastrado com sucesso!',
      atualizadoSucesso: 'Prontuário atualizado com sucesso!',
      removidoSucesso: 'Prontuário removido com sucesso!',

      atendimentos: {
        iniciadoSucesso: 'Atendimento iniciado com sucesso!',
        desejaFinalizar: 'Deseja finalizar do atendimento?',
        desejaDescartar: 'Por qual motivo você deseja descartar o atendimento?',
        prescricoesExames: 'Prescrições e exames',
        prescricaoDeOculos: 'Prescrição de óculos',
        prescricaoMemed: 'Prescrição Memed',
        prescricao: 'Prescrição',
        dilatacoes: 'Dilatações',
        adicionarSecao: 'Adicionar seção',
        atendimentoRegistradoEm: 'Atendimento registrado em:',
        atendimentoRealizadoEm: 'Atendimento realizado em:',
        notaCadastradaSucesso: 'Nota cadastrada com sucesso',
        teleconsulta: 'Teleconsulta',
        tempoPrevistoRetorno: 'Tempo previsto para retorno',
        tipoAtendimento: 'Tipo de atendimento',
        calculoTempoAtendimento: 'Cálculo do tempo de agendamento em andamento',

        layout: {
          atualizadoSucesso: 'Layout atualizado com sucesso!',
          removidoSucesso: 'Layout removido com sucesso!',
          nomePaciente: 'Nome do paciente',
          itemModelo: 'Item modelo'
        },

        assinaturaDigital: {
          titulo: 'Assinatura digital',
          expirarTokenEm: 'Expirar token em',
          tokenApp: 'Insira o token que aparece em seu app Bird ID',
          assinar: 'Assinar',
          assinarDepois: 'Assinar depois',
          autenticadaSucesso: 'Token validado com sucesso!',
          requisicaoAssinatura: 'Requisição da assinatura efetuada com sucesso!',
          assinatura: 'Assinatura',
          assinaturas: 'Assinaturas',
          assinaturaPendente: 'Assinatura em processamento',
          falhaAssinar: 'Ocorreu uma falha ao assinar',
          assinaturasPendentes: 'Assinaturas pendentes',
          numeroProntuario: 'N° do prontuário',
          nenhumaAssinaturaPendente: 'Nenhuma assinatura pendente',
          assinado: 'Assinado'
        },

        impressao: {
          outrasInformacoes: 'Outras Informações',
          camposDoAtendimento: 'Campos do Atendimento',
          avaliacao: 'Avaliacao',
          conduta: 'Conduta',
          queixaPrincipal: 'Queixa Principal',
          plano: 'Plano',
          cid: 'CID',
          receitas: 'Receitas',
          laudos: 'Laudos',
          adendos: 'Adendos',
          anexos: 'Anexos',
          teleConsultas: 'Teleconsultas',
          nenhumaReceitaCadastrada: 'Nenhuma receita cadastrada nesse atendimento!',
          nenhumaReceitaMemedRegistradaAtendimento: 'Nenhuma receita memed cadastrada nesse atendimento!',
          receitasMemed: {
            identificacaoEmitente: 'Identificação do Emitente',
            cidadeEUf: 'Cidade e UF',
            receituarioEspecial: 'Receituário de controle especial',
            primeiraViaFarmacia: '1ª via farmácia',
            segundaViaPaciente: '2ª via paciente',
            assinatura: 'Assinatura',
            identificacaoComprador: 'Identificação do Comprador',
            identificacaoFornecedor: 'Identificação do Fornecedor',
            assinaturaFarmaceutico: 'Assinatura do Farmacêutico'
          }
        },

        laudos: {
          cadastradoSucesso: 'Laudo cadastrado com sucesso',
          atualizadoSucesso: 'Laudo atualizado com sucesso!',
          removidoSucesso: 'Laudo removido com sucesso!'
        },

        documentos: {
          cadastradoSucesso: 'Documentos cadastrado com sucesso',
          atualizadoSucesso: 'Documentos atualizado com sucesso!',
          removidoSucesso: 'Documentos removido com sucesso!'
        }
      }
    },
    exames: {
      examesSolicitadosDia: 'Exames solicitados dia',
      mesmaDataParaTodos: 'Usar mesma data para todos',
      uploadImagens: 'Upload de imagens',
      resultados: 'Resultados',
      solicitarNovosExames: 'Solicitar novos exames',
      dataRealizacao: 'Data de realizacao',
      realizadoEm: 'Realizado em',
      exames: 'Exames',
      detalhesResultados: 'Detalhes e resultados',
      adicionarExames: 'Adicionar exames'
    },
    solicitacaoDeExames: {
      solicitacaoDeExames: 'Solicitação de exames',
      listagemDeExames: 'Listagem de exames',
      novaSolicitacaoDeExames: 'Nova solicitação de exames',
      convenio: 'Convênio',
      dataSolicitacao: 'Data da Solicitação',
      selecioneExame: 'Selecione o exame',
      procurePeloProcedimentoExame: 'Procure pelo procedimento/exame',
      exameInterno: 'Exame interno',
      listagemExames: 'listagem dos exames',
      nomeExame: 'Nome do exame',
      motivoExame: 'Motivo do exame',
      status: 'Status',
      olho: 'olho',
      assinarDigitalmente: 'assinar digitalmente',
      gerarSadt: 'gerar sadt',
      qtde: 'Qtde',
      imprimir: 'Imprimir',
      cadastradoSucesso: 'Exame cadastrado com sucesso',
      atualizadoSucesso: 'Exames atualizados com sucesso!',
      removidoSucesso: 'Exame removido com sucesso!',
      cadastarProcedimentoIgual: 'O procedimento/exame já foi selecionado.',
      validacoes: {
        qtdeMin: 'A quantidade deve ser maior ou igual a um'
      }
    },

    desfechos: {
      titulo: 'Pré-atendimentos e Retornos',
      novo: 'Novo desfecho',
      cadastradoSucesso: 'Desfecho cadastrado com sucesso!',
      atualizadoSucesso: 'Desfecho atualizado com sucesso!',
      removidoSucesso: 'Desfecho removido com sucesso!',
      preAtendimento: 'Pré-atendimento',
      retornos: 'Retornos',
      proximosAtendimentos: 'Próximos atendimentos',
      historicoDeAtendimentos: 'Histórico de atendimentos',
      selecionePreAtendimento: 'Selecione o modelo de pré-atendimento',
      novaSecao: 'Nova seção',
      agendePreAtendimento: 'Agende uma consulta com a situação de pré-atendimento',
      agendeRetornoReconsulta: 'Agende um retorno/reconsulta',

      atendimentos: {
        titulo: 'Atendimentos',
        novo: 'Novo atendimento',
        cadastradoSucesso: 'Atendimento cadastrado com sucesso!',
        atualizadoSucesso: 'Atendimento atualizado com sucesso!',
        removidoSucesso: 'Atendimento removido com sucesso!',

        documentos: {
          titulo: 'Documentos',
          novo: 'Novo documento',
          cadastradoSucesso: 'Documento cadastrado com sucesso!',
          atualizadoSucesso: 'Documento atualizado com sucesso!',
          removidoSucesso: 'Documento removido com sucesso!'
        },

        exames: {
          titulo: 'Exames',
          novo: 'Novo exame',
          cadastradoSucesso: 'Exame cadastrado com sucesso!',
          atualizadoSucesso: 'Exame atualizado com sucesso!',
          removidoSucesso: 'Exame removido com sucesso!'
        }
      }
    },

    preferencias: {
      formulas: {
        titulo: 'Fórmulas',
        novo: 'Nova fórmula',
        cadastradoSucesso: 'Fórmula cadastrada com sucesso!',
        atualizadoSucesso: 'Fórmula atualizada com sucesso!',
        removidoSucesso: 'Fórmula removida com sucesso!'
      },

      modelosDeLaudo: {
        titulo: 'Modelos de laudo',
        novo: 'Novo modelo de laudo',
        cadastradoSucesso: 'Modelo de laudo cadastrado com sucesso!',
        atualizadoSucesso: 'Modelo de laudo atualizado com sucesso!',
        removidoSucesso: 'Modelo de laudo removido com sucesso!',
        laudo: { novo: 'Novo modelo de laudo', nenhumCadastro: 'Nenhum modelo de laudo cadastrado' },
        conclusao: { novo: 'Novo modelo de conclusão', nenhumCadastro: 'Nenhum modelo de conclusao cadastrado' }
      },

      modelosDeProntuario: {
        titulo: 'Modelos de prontuário',
        novo: 'Novo modelo de prontuário',

        digiteTitulo: 'Digite aqui o título do prontuário',
        digiteDescricao: 'Digite aqui a descrição do prontuário',
        default: 'Prontuário sem título',

        cadastradoSucesso: 'Modelo de prontuário cadastrado com sucesso!',
        atualizadoSucesso: 'Modelo de prontuário atualizado com sucesso!',
        removidoSucesso: 'Modelo de prontuário removido com sucesso!',

        medicos: {
          titulo: 'Médicos',
          novo: 'Adicionar médico',
          cadastradoSucesso: 'Médico adicionado com sucesso!',
          atualizadoSucesso: 'Médico atualizado com sucesso!',
          removidoSucesso: 'Médico removido com sucesso!'
        },

        especialidades: {
          titulo: 'Especialidades',
          novo: 'Adicionar especialidade',
          cadastradoSucesso: 'Especialidade adicionada com sucesso!',
          atualizadoSucesso: 'Especialidade atualizada com sucesso!',
          removidoSucesso: 'Especialidade removida com sucesso!'
        },

        secoes: {
          novo: 'Nova seção',
          cadastradoSucesso: 'Seção cadastrada com sucesso!',
          atualizadoSucesso: 'Seção atualizada com sucesso!',
          removidoSucesso: 'Seção removida com sucesso!',

          digiteTitulo: 'Digite aqui o título da seção',

          campos: {
            novo: 'Novo campo',
            cadastradoSucesso: 'Campo cadastrado com sucesso!',
            atualizadoSucesso: 'Campo atualizado com sucesso!',
            removidoSucesso: 'Campo removido com sucesso!',
            camposDisponiveis: 'Campos disponíveis para composição',
            operacoes: 'Operações',

            termos: {
              titulo: 'Palavras-chave',
              novo: 'Novo termo',
              cadastradoSucesso: 'Termo cadastrado com sucesso!',
              removidoSucesso: 'Termo removido com sucesso!'
            },

            opcoesMultiSelect: {
              titulo: 'Opções multiselect',
              novo: 'Nova opção',
              cadastradoSucesso: 'Opção multiselect cadastrado com sucesso!',
              removidoSucesso: 'Opção multiselect removido com sucesso!',
              nenhumaOpcaoCadastrado: 'Nenhuma opção cadastrada'
            }
          }
        }
      },

      protocolosExames: {
        titulo: 'Protocolos e exames',
        novo: 'Novo protocolo/exame',
        cadastradoSucesso: 'Protocolo/exame cadastrado com sucesso!',
        atualizadoSucesso: 'Protocolo/exame atualizado com sucesso!',
        removidoSucesso: 'Protocolo/exame removido com sucesso!',

        especialidades: {
          titulo: 'Especialidades',
          novo: 'Nova especialidade',
          cadastradoSucesso: 'Especialidade cadastrada com sucesso!',
          removidoSucesso: 'Especialidade removida com sucesso!'
        },

        exames: {
          titulo: 'Exames',
          novo: 'Novo exame',
          editar: 'Editar exame',
          cadastradoSucesso: 'Exame cadastrado com sucesso!',
          atualizadoSucesso: 'Exame atualizado com sucesso!',
          removidoSucesso: 'Exame removido com sucesso!'
        },

        medicamentos: {
          titulo: 'Medicamentos',
          novo: 'Novo medicamento',
          editar: 'Editar medicamento',
          cadastradoSucesso: 'Medicamento cadastrado com sucesso!',
          atualizadoSucesso: 'Medicamento atualizado com sucesso!',
          removidoSucesso: 'Medicamento removido com sucesso!'
        },

        profissionais: {
          titulo: 'Profissionais',
          novo: 'Novo profissional',
          selecione: 'Selecione um profissional...',
          cadastradoSucesso: 'Profissional cadastrado com sucesso!',
          removidoSucesso: 'Profissional removido com sucesso!'
        }
      },

      motivosDesfechoAtendimento: {
        titulo: 'Motivos de desfecho',
        novo: 'Novo motivo de desfecho',
        cadastradoSucesso: 'Motivo de desfecho cadastrado com sucesso!',
        atualizadoSucesso: 'Motivo de desfecho atualizado com sucesso!',
        removidoSucesso: 'Motivo de desfecho removido com sucesso!'
      },

      tiposFuncionarios: {
        novo: 'Novo tipo',
        cadastradoSucesso: 'Tipo de funcionário cadastrado com sucesso!'
      },

      tiposDeSala: {
        titulo: 'Tipos de sala / ambiente',
        novo: 'Novo tipo de sala',
        cadastradoSucesso: 'Tipo de sala cadastrado com sucesso!',
        atualizadoSucesso: 'Tipo de sala atualizado com sucesso!',
        removidoSucesso: 'Tipo de sala removido com sucesso!'
      },

      salas: {
        titulo: 'Salas / ambientes',
        novo: 'Novo sala / ambiente',
        cadastradoSucesso: 'Sala / ambiente cadastrado com sucesso!',
        atualizadoSucesso: 'Sala / ambiente atualizado com sucesso!',
        removidoSucesso: 'Sala / ambiente removido com sucesso!',
        equipamentos: {
          titulo: 'Equipamentos',
          selecionar: 'Selecionar Equipamento',
          novo: 'Novo Equipamento',
          editar: 'Editar Equipamento',
          cadastradoSucesso: 'Equipamento cadastrado com sucesso!',
          atualizadoSucesso: 'Equipamento atualizado com sucesso!',
          removidoSucesso: 'Equipamento removido com sucesso!'
        }
      }
    }
  },

  tiss: {
    versaoDoTISS: 'Versão do TISS',

    centralTiss: {
      titulo: 'Central TISS',
      faturas: { novo: 'Nova fatura', selecioneUma: 'Selecione uma fatura' },
      cadastradoSucesso: 'Fatura cadastrada com sucesso!',
      atualizadoSucesso: 'Fatura atualizada com sucesso!',
      removidoSucesso: 'Fatura removida com sucesso',
      fecharFatura: 'Fechar fatura',
      fecharFaturaManualmente: 'Deseja fechar essa fatura manualmente?',
      glosas: 'Glosas',
      lote: 'Lote',
      qtde: 'Qtde',
      nGuia: 'Nº Guia',
      faturado: 'Faturado',
      glosa: 'Glosa',
      valorGlosa: 'Valor glosa',
      cr: 'CR',
      repasse: 'Repasse',
      pesquisar: 'Pesquisar pacientes, médicos, guias...',
      pesquisarGuias: 'Pesquisar guias',
      buscar: 'Buscar pacientes, médicos, guias...',
      tipoGuia: 'Tipo da guia',
      numeroGuia: 'Número da guia',
      sequenciaDeTransacao: 'Sequência de transação: - lote de xml',
      numeroControleXml: 'Numero controle XML',
      versaoDoArquivoXml: 'Versão do arquivo xml',
      xmlGerado: 'XML gerado com sucesso',
      feedbacks: {
        impossivelDeletarFaturasComGuias: 'Não é possível deletar faturas que já possuem guias',
        impossivelEditarFaturasComGuias: 'Não é possível editar faturas que já possuem guias.',
        jaExisteFaturaComEsseNome: 'Já existe uma fatura cadastrada com esse nome'
      },
      guias: {
        totalFatura: 'Total fatura',
        totalGlosado: 'Total glosado',
        totalRecebido: 'Total recebido',
        totalTiss: 'Total TISS',
        transferidaSucesso: 'Guia transferida com sucesso!',
        transferidasSucesso: 'Guias transferidas com sucesso!',
        transferir: 'Transferir guia',
        options: {
          editarGuia: 'Editar guia',
          gerarXml: 'Gerar XML',
          transferir: 'Transferir',
          contaReceber: 'Conta a receber',
          contaPagar: 'Conta a pagar',
          previsaoDeRepasses: 'Previsão de repasses'
        }
      }
    },
    preferencias: {
      convenios: {
        convenios: 'Convênios',
        novo: 'Novo Convênio',
        editar: 'Editar convênio',
        cadastradoSucesso: 'Convênio cadastrado com sucesso!',
        atualizadoSucesso: 'Convênio atualizado com sucesso!',
        removidoSucesso: 'Convênio removido com sucesso!',
        principaisDados: 'Principais dados do convênio',
        nomeCnpj: 'Nome / CNPJ',
        registroAns: 'Registro na ANS',
        maximoRegistroAns: 'Máximo de 6 caracteres',
        telefoneEmail: 'Telefone / E-mail',
        tags: 'Tags',
        codigoOperadora: 'Código na operadora',
        maximoCodigoOperadora: 'Máximo de 20 caracteres',
        inscricaoEstadual: 'Inscrição estadual',
        dataCredenciamento: 'Data de credenciamento',
        dataDescredenciamento: 'Data de descredenciamento',
        retornoConsultaDias: 'Retorno consulta',
        diasRecebimento: 'Dias para recebimento',
        website: 'Website',
        observacoes: 'Observações',
        tabs: {
          dadosBasicos: 'Dados básicos',
          trocaDeInformacoes: 'Troca de informações',
          regras: 'Regras',
          webservicesTiss: 'Webservices TISS',
          excecoes: 'Exceções',
          financeiro: 'Financeiro',
          tributacao: 'Tributação',
          anexos: 'Anexos',
          situacao: 'Situação'
        },
        planos: {
          titulo: 'Planos',
          subtitulo: 'Cadastre os planos do convênio',
          novo: 'Novo plano',
          cadastradoSucesso: 'Plano cadastrado com sucesso!',
          atualizadoSucesso: 'Plano atualizado com sucesso!',
          removidoSucesso: 'Plano removido com sucesso!'
        }
      },

      versoesDoTiss: {
        titulo: 'Versões do TISS',
        novo: 'Nova versão do TISS',
        cadastradoSucesso: 'Versão do TISS cadastrada com sucesso!',
        atualizadoSucesso: 'Versão do TISS atualizada com sucesso!',
        removidoSucesso: 'Versão do TISS removida com sucesso!'
      }
    }
  },

  servicosContratacoes: {
    faturas: {
      historicoContratacoes: {
        titulo: 'Histórico de contratações',
        mesAtual: 'Mês atual',
        resumo: 'Resumo',
        movimentoUsuarios: 'Movimento de usuários',
        quantidadeUsuarios: 'Quantidade de usuários',
        valorMensalidade: 'Valor da mensalidade',
        dataProfissionalSaude: 'Data / Profissional de saúde',
        emailUsuario: 'E-mail / Usuário',
        nenhumaMovimentacaoEncontrada: 'Nenhuma movimentação encontrada'
      }
    }
  },

  configuracoes: {
    notificacoesTeleconsulta: {
      titulo: 'Notificações de teleconsulta',
      notificarPorEmail: 'Notificação por email',
      notificarPorSMS: 'Notificação por SMS',
      templateSMSAgendado: 'Template de SMS agendado',
      templateSMSRemarcado: 'Template de SMS remarcado',
      templateSMSCancelado: 'Template de SMS cancelado',
      templateMailAgendado: 'Template de Email agendado',
      templateMailRemarcado: 'Template de Email remarcado',
      templateMailCancelado: 'Template de Email cancelado',
      atualizadoSucesso: 'Notificações de teleconsulta atualizadas com sucesso!'
    },
    empresas: {
      impressos: {
        emitenteRecibos: 'Emitente em recibos'
      }
    },
    templates: {
      whatsApp: {
        titulo: 'Templates',
        novo: 'Novo Modelo',
        cadastradoSucesso: 'Modelo de WhatsApp cadastrado com sucesso!',
        atualizadoSucesso: 'Modelo de WhatsApp atualizado com sucesso!',
        removidoSucesso: 'Modelo de WhatsApp removido com sucesso!',
        escrevaMensagem: 'Escreva a mensagem',
        adicionarTags: 'Adicionar Tags:',
        mensagemDeTexto: 'Mensagem de Texto',
        caracteresRestantes: '{{value}} caracteres restantes',
        funcionalidade: 'Funcionalidade'
      }
    }
  },

  dashboard: {
    categorias: {
      ultimos12Meses: 'Últimos 12 meses',
      ultimoMes: 'Último mês',
      ultimaSemana: 'Última semana',
      ultimoDia: 'Último dia',
      semAgendamentosPorStatus: 'Sem agendamentos nos últimos 12 meses',
      agendamentosPorStatus: 'Agendamentos por status (últimos 12 meses)'
    },
    resumoFinanceiro: {
      hoje: 'Resumo financeiro (hoje)'
    }
  },
  financeiro: {
    estoque: {
      titulo: 'Movimentação de estoque',
      localEstoque: 'Local de estoque',
      nenhumCadastro: 'Nenhum estoque cadastrado',
      cadastradoSucesso: 'Estoque cadastrado com sucesso!',
      atualizadoSucesso: 'Estoque atualizado com sucesso!',
      removidoSucesso: 'Estoque removido com sucesso!',
      totalItens: 'Total de itens',
      maiorSaidaItem: 'Maior saída de item',
      semSaidaItem: 'Sem saída recente',
      itemsVencidos: 'Itens vencidos',
      nomeSituacao: 'Nome / Situação em estoque',
      valorCompraVenda: 'Valor de compra / Venda',
      localEstoqueFinalidade: 'Local de estoque / Finalidade',
      embCompraVenda: 'Emb. de compra / Venda',
      estoqueMinimo: 'Estoque mínimo',
      estoqueMaximo: 'Estoque máximo',
      tipoProduto: 'Tipo produto',
      buscarPorProduto: 'Buscar por produto',
      finalidade: 'Finalidade',
      vencimentoEntreDatas: 'Vencimento entre datas',
      vencimentoInicial: 'Vencimento inicial',
      vencimentoFinal: 'Vencimento final',
      validadeEstoque: 'Validade e estoque',
      validade: 'Validade',
      situacaoEstoque: 'Situação em estoque',
      movimentarLocalEstoque: 'Movimentar local de estoque',
      quantidadeMovimentar: 'Quantidade a movimentar',
      selecionarLocal: 'Selecionar local',
      valorVenda: 'Valor de venda',
      embalagemCompra: 'Embalagem de compra',
      embalagemVenda: 'Embalagem de venda',
      removerUnidade: 'Remover unidades',
      voceEstaRemovendoUnidades: 'Você está removendo {{unidade}} unidades',
      transferirUnidades: 'Transferir unidades',
      voceEstaTransferindoUnidadesDesseLocal: 'Você está transferindo {{unidade}} unidades desse local',
      burcarPorProduto: 'Buscar por produto',
      abaixoEstoque: 'Abaixo do estoque mínimo',
      acimaEstoque: 'Acima do estoque máximo',
      produtoVencido: 'Produto vencido!',
      vencido: 'Vencido',
      unidadesTransferidas: 'Unidades a serem transferidas',
      unidadesRestantes: 'Unidades restantes no local atual'
    },
    preferenciasEstoque: {
      localDeEstoque: {
        titulo: 'Local de estoque',
        novo: 'Novo local de estoque',
        cadastradoSucesso: 'Local de estoque cadastrado com sucesso!',
        atualizadoSucesso: 'Local de estoque atualizado com sucesso!',
        removidoSucesso: 'Local de estoque removido com sucesso!'
      }
    }
  },
  catalago: {
    estoque: {
      titulo: 'Estoque',
      localEstoque: 'Local de estoque',
      estoqueMinimo: 'Estoque mínimo',
      estoqueMaximo: 'Estoque máximo'
    }
  }
}
