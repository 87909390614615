import React, { useMemo, Suspense, lazy } from 'react'

import { SptLoading } from '@sponte/lib-components/dist/atoms/Loading'

import { getExistPendingTerm } from 'services/auth'
import { getSubdominioWeb } from 'services/tenants'

import { useAuth } from 'providers/auth'
import { useTenant } from 'providers/tenant'

const Auth = lazy(() => import('./pages/auth'))
const Admin = lazy(() => import('./pages/admin'))
const Tenant = lazy(() => import('./pages/tenant'))
const Errors = lazy(() => import('./pages/errors'))

const App = () => {
  const { eu } = useAuth()
  const { tenant } = useTenant()

  const Component = useMemo(() => {
    if (getSubdominioWeb() && !tenant) {
      return Errors
    }

    if (!tenant) {
      return Tenant
    }

    if (!eu) {
      return Auth
    }

    if (getExistPendingTerm() && getExistPendingTerm() === 'true') {
      return Auth
    }

    return Admin
  }, [tenant, eu])

  return (
    <Suspense fallback={<SptLoading full size="xl" position="absolute" />}>
      <Component />
    </Suspense>
  )
}

export default App
