import React from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

export const SptAssetsImages = {
  notFound: 'notFound',
  illustration: 'illustration',
  logo: 'logo',
  login: 'login',
  confirmacaoAgendamento: 'confirmacaoAgendamento'
}

export const SptImagemFeedbackStyled = styled(SptFlex)`
  img {
    margin-left: auto;
    margin-right: auto;
  }
`

export const SptImagemFeedbackImg = styled.img`
  max-width: 200px;
`

SptImagemFeedbackStyled.displayName = 'SptImagemFeedbackStyled'

export const SptImagemFeedback = ({
  imageAssetsPath = 'notFound',
  imageWidth,
  information,
  instruction,
  informationColor,
  ...props
}) => {
  const { assets } = useTheme()

  return (
    <SptImagemFeedbackStyled
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <SptFlex width={1} mb={10}>
        <SptImagemFeedbackImg src={assets[imageAssetsPath]} alt={information} />
      </SptFlex>

      <SptFlex width={1}>
        <SptText fontSize={3} fontWeight={2} mx="auto" mb={10} textAlign="center" color={informationColor}>
          {information}
        </SptText>
      </SptFlex>

      <SptFlex width={1}>
        <SptText variant="label" fontSize={2} mx="auto" textAlign="center">
          {instruction}
        </SptText>
      </SptFlex>
    </SptImagemFeedbackStyled>
  )
}

SptImagemFeedback.displayName = 'SptImagemFeedback'

SptImagemFeedback.propTypes = {
  imageAssetsPath: PropTypes.oneOf(Object.keys(SptAssetsImages)),
  information: PropTypes.string,
  informationColor: PropTypes.string,
  instruction: PropTypes.string
}

SptImagemFeedback.defaultProps = {
  imageAssetsPath: 'notFound',
  informationColor: 'almostBlack'
}
