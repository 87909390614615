/* eslint-disable */
/* tslint:disable */
import ky from 'ky'
import { useQuery, useMutation } from 'react-query'

let api = ky.create({
  "prefixUrl": "https://saude-api.staging.sponte-demo.com.br",
  "throwHttpErrors": false,
  "retry": {
    "statusCodes": [
      401
    ]
  }
})

export const getApi = () => api

export const setApi = (newApi) => {
  api = newApi
}

export const extendApi = (options) => {
  api = getApi().extend(options)
}

const requestFn = async ({ url, method, pathParams, queryParams, ...rest }) => {
  const urlPathParams = url.match(/{([^}]+)}/g)

  if (urlPathParams) {
    url = urlPathParams.reduce((acc, param) => acc.replace(param, pathParams[param.replace(/{|}/g, '')]), url)
  } else {
    queryParams = pathParams
  }

  if (url.charAt(0) === '/') {
    url = url.replace('/', '')
  }

  const response = await api(url, {
    method,
    ...rest,
    searchParams: {
      ...(rest.searchParams || {}),
      ...queryParams
    }
  })

  let data

  try {
    const contentType = (response.headers.get('content-type') || '').split('; ')[0]

    const responseType =
      {
        'application/json': 'json',
        'application/pdf': 'blob',
        'application/octet-stream': 'blob'
      }[contentType] || 'text'

    data = await response[responseType]()
  } catch (e) {
    data = e.message
  }

  if (!response.ok) {
    const error = {
      data,
      status: response.status,
      message: `Failed to fetch: ${response.status} ${response.statusText}`
    }

    throw error
  }

  return data
}

const queryFn = (options = {}) => (url, pathParams = {}, queryParams = {}) =>
  requestFn({
    url,
    method: 'get',
    pathParams,
    queryParams,
    ...options
  })

const mutationFn = (
  method,
  url,
  pathParams = {},
  queryParams = {},
  options = {}
) => (body = {}) => {
  if (Array.isArray(body)) {
    pathParams = { ...pathParams, ...(body[0] || {}) }
    queryParams = { ...queryParams, ...(body[1] || {}) }
    options = { ...options, ...(body[3] || {}) }
    body = body[2]
  }

  const request = {
    url,
    method,
    pathParams,
    queryParams,
    ...options
  }

  if (method !== 'delete') {
    try {
      request[body.toString() === '[object FormData]' ? 'body' : 'json'] = body
    } catch(e) {
    }
  }

  return requestFn(request)
}

export const mutationBoletosControllerConciliar = (pathParams, options) => mutationFn('post', '/api/v2/boletos/{id}/conciliar', pathParams, {}, options)
export const useMutationBoletosControllerConciliar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/boletos/{id}/conciliar', pathParams, {}, options), config)

export const queryBoletosControllerListarHistoricoStatus = (pathParams, queryParams, options) => queryFn(options)('/api/v2/boletos/{id}/historico-status', pathParams, queryParams)
export const useQueryBoletosControllerListarHistoricoStatus = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/boletos/{id}/historico-status', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBoletosControllerListarHistoricoStatus.queryKey = '/api/v2/boletos/{id}/historico-status'

export const mutationBoletosControllerEnviarEmailBoletoZoop = (pathParams, options) => mutationFn('post', '/api/v2/boletos/{id}/enviar-email', pathParams, {}, options)
export const useMutationBoletosControllerEnviarEmailBoletoZoop = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/boletos/{id}/enviar-email', pathParams, {}, options), config)

export const mutationCaixasFuncionariosControllerAbrirCaixa = (options) => mutationFn('post', '/api/v2/caixas-funcionarios', {}, {}, options)
export const useMutationCaixasFuncionariosControllerAbrirCaixa = (config, options) => useMutation(mutationFn('post', '/api/v2/caixas-funcionarios', {}, {}, options), config)

export const mutationCaixasFuncionariosControllerCadastrarRecebimentoDireto = (pathParams, options) => mutationFn('post', '/api/v2/caixas-funcionarios/{id}/recebimento-direto', pathParams, {}, options)
export const useMutationCaixasFuncionariosControllerCadastrarRecebimentoDireto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/caixas-funcionarios/{id}/recebimento-direto', pathParams, {}, options), config)

export const mutationCaixasFuncionariosControllerCadastrarRetirada = (pathParams, options) => mutationFn('post', '/api/v2/caixas-funcionarios/{id}/retirada', pathParams, {}, options)
export const useMutationCaixasFuncionariosControllerCadastrarRetirada = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/caixas-funcionarios/{id}/retirada', pathParams, {}, options), config)

export const mutationCaixasFuncionariosControllerFecharCaixa = (pathParams, options) => mutationFn('post', '/api/v2/caixas-funcionarios/{id}/fechar-caixa', pathParams, {}, options)
export const useMutationCaixasFuncionariosControllerFecharCaixa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/caixas-funcionarios/{id}/fechar-caixa', pathParams, {}, options), config)

export const mutationCaixasFuncionariosControllerReabrirCaixa = (pathParams, options) => mutationFn('patch', '/api/v2/caixas-funcionarios/{id}/reabrir-caixa', pathParams, {}, options)
export const useMutationCaixasFuncionariosControllerReabrirCaixa = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v2/caixas-funcionarios/{id}/reabrir-caixa', pathParams, {}, options), config)

export const queryCaixasFuncionariosControllerObterCaixaAbertoUsuarioLogado = (options) => queryFn(options)('/api/v2/caixas-funcionarios/caixa-aberto')
export const useQueryCaixasFuncionariosControllerObterCaixaAbertoUsuarioLogado = (config, options) => useQuery({
  queryKey: ['/api/v2/caixas-funcionarios/caixa-aberto'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasFuncionariosControllerObterCaixaAbertoUsuarioLogado.queryKey = '/api/v2/caixas-funcionarios/caixa-aberto'

export const queryCaixasFuncionariosControllerListarCaixasFechadosUsuarioLogado = (queryParams, options) => queryFn(options)('/api/v2/caixas-funcionarios/caixas-fechados', queryParams)
export const useQueryCaixasFuncionariosControllerListarCaixasFechadosUsuarioLogado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/caixas-funcionarios/caixas-fechados', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasFuncionariosControllerListarCaixasFechadosUsuarioLogado.queryKey = '/api/v2/caixas-funcionarios/caixas-fechados'

export const queryCaixasFuncionariosControllerListarMovimentos = (pathParams, queryParams, options) => queryFn(options)('/api/v2/caixas-funcionarios/{id}/movimentos-caixa', pathParams, queryParams)
export const useQueryCaixasFuncionariosControllerListarMovimentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/caixas-funcionarios/{id}/movimentos-caixa', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasFuncionariosControllerListarMovimentos.queryKey = '/api/v2/caixas-funcionarios/{id}/movimentos-caixa'

export const queryCaixasFuncionariosControllerListarSaldosFormasPagamentos = (pathParams, options) => queryFn(options)('/api/v2/caixas-funcionarios/{id}/saldos-formas-pagamentos', pathParams)
export const useQueryCaixasFuncionariosControllerListarSaldosFormasPagamentos = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/caixas-funcionarios/{id}/saldos-formas-pagamentos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasFuncionariosControllerListarSaldosFormasPagamentos.queryKey = '/api/v2/caixas-funcionarios/{id}/saldos-formas-pagamentos'

export const mutationCaixasFuncionariosGeraisControllerConsolidarCaixasGerais = (options) => mutationFn('post', '/api/v2/caixas-funcionarios-gerais/consolidar', {}, {}, options)
export const useMutationCaixasFuncionariosGeraisControllerConsolidarCaixasGerais = (config, options) => useMutation(mutationFn('post', '/api/v2/caixas-funcionarios-gerais/consolidar', {}, {}, options), config)

export const mutationCaixasFuncionariosGeraisControllerDesconsolidarCaixasGerais = (options) => mutationFn('post', '/api/v2/caixas-funcionarios-gerais/desconsolidar', {}, {}, options)
export const useMutationCaixasFuncionariosGeraisControllerDesconsolidarCaixasGerais = (config, options) => useMutation(mutationFn('post', '/api/v2/caixas-funcionarios-gerais/desconsolidar', {}, {}, options), config)

export const queryCaixasFuncionariosGeraisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/caixas-funcionarios-gerais/{id}', pathParams)
export const useQueryCaixasFuncionariosGeraisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/caixas-funcionarios-gerais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasFuncionariosGeraisControllerObterPorId.queryKey = '/api/v2/caixas-funcionarios-gerais/{id}'

export const queryCaixasFuncionariosGeraisControllerListar = (queryParams, options) => queryFn(options)('/api/v2/caixas-funcionarios-gerais', queryParams)
export const useQueryCaixasFuncionariosGeraisControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/caixas-funcionarios-gerais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasFuncionariosGeraisControllerListar.queryKey = '/api/v2/caixas-funcionarios-gerais'

export const mutationCategoriasControllerCadastrar = (options) => mutationFn('post', '/api/v2/categorias', {}, {}, options)
export const useMutationCategoriasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v2/categorias', {}, {}, options), config)

export const queryConfiguracoesCartoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/configuracoes-cartoes/{id}', pathParams)
export const useQueryConfiguracoesCartoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/configuracoes-cartoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesCartoesControllerObterPorId.queryKey = '/api/v2/configuracoes-cartoes/{id}'

export const mutationConfiguracoesCartoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v2/configuracoes-cartoes/{id}', pathParams, {}, options)
export const useMutationConfiguracoesCartoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/configuracoes-cartoes/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesCartoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v2/configuracoes-cartoes/{id}', pathParams, {}, options)
export const useMutationConfiguracoesCartoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/configuracoes-cartoes/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesCartoesControllerCadastrar = (options) => mutationFn('post', '/api/v2/configuracoes-cartoes', {}, {}, options)
export const useMutationConfiguracoesCartoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v2/configuracoes-cartoes', {}, {}, options), config)

export const queryConfiguracoesCartoesControllerListar = (queryParams, options) => queryFn(options)('/api/v2/configuracoes-cartoes', queryParams)
export const useQueryConfiguracoesCartoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/configuracoes-cartoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesCartoesControllerListar.queryKey = '/api/v2/configuracoes-cartoes'

export const mutationConfiguracoesCartoesControllerCadastrarConfiguracaoCartaoConta = (pathParams, options) => mutationFn('post', '/api/v2/configuracoes-cartoes/{id}/contas', pathParams, {}, options)
export const useMutationConfiguracoesCartoesControllerCadastrarConfiguracaoCartaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/configuracoes-cartoes/{id}/contas', pathParams, {}, options), config)

export const queryConfiguracoesCartoesControllerListarConfiguracaoCartaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v2/configuracoes-cartoes/{id}/contas', pathParams, queryParams)
export const useQueryConfiguracoesCartoesControllerListarConfiguracaoCartaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/configuracoes-cartoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesCartoesControllerListarConfiguracaoCartaoContas.queryKey = '/api/v2/configuracoes-cartoes/{id}/contas'

export const queryConfiguracoesCartoesControllerObterConfiguracaoCartaoContaPorId = (pathParams, options) => queryFn(options)('/api/v2/configuracoes-cartoes/{id}/contas/{contaId}', pathParams)
export const useQueryConfiguracoesCartoesControllerObterConfiguracaoCartaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/configuracoes-cartoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryConfiguracoesCartoesControllerObterConfiguracaoCartaoContaPorId.queryKey = '/api/v2/configuracoes-cartoes/{id}/contas/{contaId}'

export const mutationConfiguracoesCartoesControllerAtualizarConfiguracaoCartaoConta = (pathParams, options) => mutationFn('put', '/api/v2/configuracoes-cartoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationConfiguracoesCartoesControllerAtualizarConfiguracaoCartaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/configuracoes-cartoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryConfiguracoesCartoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v2/configuracoes-cartoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryConfiguracoesCartoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/configuracoes-cartoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesCartoesControllerListarContasDisponiveis.queryKey = '/api/v2/configuracoes-cartoes/{id}/contas-disponiveis'

export const queryConfiguracoesFinanceirasControllerObterConfiguracoesFinanceiras = (options) => queryFn(options)('/api/v2/configuracoes-financeiras')
export const useQueryConfiguracoesFinanceirasControllerObterConfiguracoesFinanceiras = (config, options) => useQuery({
  queryKey: ['/api/v2/configuracoes-financeiras'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesFinanceirasControllerObterConfiguracoesFinanceiras.queryKey = '/api/v2/configuracoes-financeiras'

export const mutationConfiguracoesFinanceirasControllerAtualizar = (options) => mutationFn('put', '/api/v2/configuracoes-financeiras', {}, {}, options)
export const useMutationConfiguracoesFinanceirasControllerAtualizar = (config, options) => useMutation(mutationFn('put', '/api/v2/configuracoes-financeiras', {}, {}, options), config)

export const queryConfiguracoesGeraisControllerObterConfiguracoesGerais = (options) => queryFn(options)('/api/v2/configuracoes-gerais')
export const useQueryConfiguracoesGeraisControllerObterConfiguracoesGerais = (config, options) => useQuery({
  queryKey: ['/api/v2/configuracoes-gerais'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesGeraisControllerObterConfiguracoesGerais.queryKey = '/api/v2/configuracoes-gerais'

export const mutationConfiguracoesGeraisControllerAtualizar = (options) => mutationFn('put', '/api/v2/configuracoes-gerais', {}, {}, options)
export const useMutationConfiguracoesGeraisControllerAtualizar = (config, options) => useMutation(mutationFn('put', '/api/v2/configuracoes-gerais', {}, {}, options), config)

export const queryConfiguracoesIntegracoesControllerObterConfiguracoesIntegracoes = (options) => queryFn(options)('/api/v2/configuracoes-integracoes')
export const useQueryConfiguracoesIntegracoesControllerObterConfiguracoesIntegracoes = (config, options) => useQuery({
  queryKey: ['/api/v2/configuracoes-integracoes'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesIntegracoesControllerObterConfiguracoesIntegracoes.queryKey = '/api/v2/configuracoes-integracoes'

export const mutationConfiguracoesIntegracoesControllerAtualizar = (options) => mutationFn('put', '/api/v2/configuracoes-integracoes', {}, {}, options)
export const useMutationConfiguracoesIntegracoesControllerAtualizar = (config, options) => useMutation(mutationFn('put', '/api/v2/configuracoes-integracoes', {}, {}, options), config)

export const mutationContasReceberControllerCadastrar = (options) => mutationFn('post', '/api/v2/contas-receber', {}, {}, options)
export const useMutationContasReceberControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v2/contas-receber', {}, {}, options), config)

export const queryContasReceberControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/contas-receber/{id}', pathParams)
export const useQueryContasReceberControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasReceberControllerObterPorId.queryKey = '/api/v2/contas-receber/{id}'

export const mutationContasReceberControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v2/contas-receber/{id}', pathParams, {}, options)
export const useMutationContasReceberControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/contas-receber/{id}', pathParams, {}, options), config)

export const mutationContasReceberControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v2/contas-receber/{id}', pathParams, {}, options)
export const useMutationContasReceberControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/contas-receber/{id}', pathParams, {}, options), config)

export const queryContasReceberControllerObterPorTabelaOrigemId = (pathParams, options) => queryFn(options)('/api/v2/contas-receber/origem/{tabelaOrigemId}', pathParams)
export const useQueryContasReceberControllerObterPorTabelaOrigemId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber/origem/{tabelaOrigemId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.tabelaOrigemId, ...config }
})
useQueryContasReceberControllerObterPorTabelaOrigemId.queryKey = '/api/v2/contas-receber/origem/{tabelaOrigemId}'

export const mutationContasReceberControllerCadastrarContaReceberItem = (pathParams, options) => mutationFn('post', '/api/v2/contas-receber/{id}/itens', pathParams, {}, options)
export const useMutationContasReceberControllerCadastrarContaReceberItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/contas-receber/{id}/itens', pathParams, {}, options), config)

export const queryContasReceberControllerListarContaReceberItens = (pathParams, queryParams, options) => queryFn(options)('/api/v2/contas-receber/{id}/itens', pathParams, queryParams)
export const useQueryContasReceberControllerListarContaReceberItens = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasReceberControllerListarContaReceberItens.queryKey = '/api/v2/contas-receber/{id}/itens'

export const queryContasReceberControllerListarContaReceberItensSimplificados = (pathParams, queryParams, options) => queryFn(options)('/api/v2/contas-receber/{id}/itens/simplificados', pathParams, queryParams)
export const useQueryContasReceberControllerListarContaReceberItensSimplificados = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber/{id}/itens/simplificados', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasReceberControllerListarContaReceberItensSimplificados.queryKey = '/api/v2/contas-receber/{id}/itens/simplificados'

export const mutationContasReceberControllerCancelarParcelasPendentes = (pathParams, options) => mutationFn('post', '/api/v2/contas-receber/{id}/cancelar-parcelas-pendentes', pathParams, {}, options)
export const useMutationContasReceberControllerCancelarParcelasPendentes = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/contas-receber/{id}/cancelar-parcelas-pendentes', pathParams, {}, options), config)

export const queryContasReceberItensControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/contas-receber-itens/{id}', pathParams)
export const useQueryContasReceberItensControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber-itens/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasReceberItensControllerObterPorId.queryKey = '/api/v2/contas-receber-itens/{id}'

export const mutationContasReceberItensControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v2/contas-receber-itens/{id}', pathParams, {}, options)
export const useMutationContasReceberItensControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/contas-receber-itens/{id}', pathParams, {}, options), config)

export const mutationContasReceberItensControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v2/contas-receber-itens/{id}', pathParams, {}, options)
export const useMutationContasReceberItensControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/contas-receber-itens/{id}', pathParams, {}, options), config)

export const queryContasReceberItensControllerListarContaReceberItemParcelas = (pathParams, queryParams, options) => queryFn(options)('/api/v2/contas-receber-itens/{id}/parcelas', pathParams, queryParams)
export const useQueryContasReceberItensControllerListarContaReceberItemParcelas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber-itens/{id}/parcelas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasReceberItensControllerListarContaReceberItemParcelas.queryKey = '/api/v2/contas-receber-itens/{id}/parcelas'

export const queryContasReceberItensControllerListarContaReceberItemParcelasOriginadasRenegociacao = (pathParams, queryParams, options) => queryFn(options)('/api/v2/contas-receber-itens/{id}/parcelas-originadas-renegociacao', pathParams, queryParams)
export const useQueryContasReceberItensControllerListarContaReceberItemParcelasOriginadasRenegociacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber-itens/{id}/parcelas-originadas-renegociacao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasReceberItensControllerListarContaReceberItemParcelasOriginadasRenegociacao.queryKey = '/api/v2/contas-receber-itens/{id}/parcelas-originadas-renegociacao'

export const mutationContasReceberItensControllerAtualizarParcela = (pathParams, options) => mutationFn('put', '/api/v2/contas-receber-itens/{id}/parcelas/{contaReceberItemParcelaId}', pathParams, {}, options)
export const useMutationContasReceberItensControllerAtualizarParcela = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/contas-receber-itens/{id}/parcelas/{contaReceberItemParcelaId}', pathParams, {}, options), config)

export const mutationContasReceberItensControllerDeletarParcela = (pathParams, options) => mutationFn('delete', '/api/v2/contas-receber-itens/{id}/parcelas/{contaReceberItemParcelaId}', pathParams, {}, options)
export const useMutationContasReceberItensControllerDeletarParcela = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/contas-receber-itens/{id}/parcelas/{contaReceberItemParcelaId}', pathParams, {}, options), config)

export const queryContasReceberItensParcelasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/contas-receber-itens-parcelas/{id}', pathParams)
export const useQueryContasReceberItensParcelasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber-itens-parcelas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasReceberItensParcelasControllerObterPorId.queryKey = '/api/v2/contas-receber-itens-parcelas/{id}'

export const queryContasReceberItensParcelasControllerListar = (queryParams, options) => queryFn(options)('/api/v2/contas-receber-itens-parcelas', queryParams)
export const useQueryContasReceberItensParcelasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber-itens-parcelas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasReceberItensParcelasControllerListar.queryKey = '/api/v2/contas-receber-itens-parcelas'

export const queryContasReceberItensParcelasControllerObterDescontoFormaPagamento = (pathParams, options) => queryFn(options)('/api/v2/contas-receber-itens-parcelas/{id}/desconto-forma-pagamento', pathParams)
export const useQueryContasReceberItensParcelasControllerObterDescontoFormaPagamento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber-itens-parcelas/{id}/desconto-forma-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasReceberItensParcelasControllerObterDescontoFormaPagamento.queryKey = '/api/v2/contas-receber-itens-parcelas/{id}/desconto-forma-pagamento'

export const mutationContasReceberItensParcelasControllerCadastrarContaReceberItemParcelaDesconto = (pathParams, options) => mutationFn('post', '/api/v2/contas-receber-itens-parcelas/{id}/descontos-configuracoes', pathParams, {}, options)
export const useMutationContasReceberItensParcelasControllerCadastrarContaReceberItemParcelaDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/contas-receber-itens-parcelas/{id}/descontos-configuracoes', pathParams, {}, options), config)

export const queryContasReceberItensParcelasControllerListarDescontosConfiguracoes = (pathParams, queryParams, options) => queryFn(options)('/api/v2/contas-receber-itens-parcelas/{id}/descontos-configuracoes', pathParams, queryParams)
export const useQueryContasReceberItensParcelasControllerListarDescontosConfiguracoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber-itens-parcelas/{id}/descontos-configuracoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasReceberItensParcelasControllerListarDescontosConfiguracoes.queryKey = '/api/v2/contas-receber-itens-parcelas/{id}/descontos-configuracoes'

export const mutationContasReceberItensParcelasControllerDeletarDesconto = (pathParams, options) => mutationFn('delete', '/api/v2/contas-receber-itens-parcelas/{id}/descontos-configuracoes/{contaReceberItemParcelaDescontoId}', pathParams, {}, options)
export const useMutationContasReceberItensParcelasControllerDeletarDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/contas-receber-itens-parcelas/{id}/descontos-configuracoes/{contaReceberItemParcelaDescontoId}', pathParams, {}, options), config)

export const queryContasReceberItensParcelasControllerObterSituacoesParcelas = (queryParams, options) => queryFn(options)('/api/v2/contas-receber-itens-parcelas/situacoes', queryParams)
export const useQueryContasReceberItensParcelasControllerObterSituacoesParcelas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber-itens-parcelas/situacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasReceberItensParcelasControllerObterSituacoesParcelas.queryKey = '/api/v2/contas-receber-itens-parcelas/situacoes'

export const queryContasReceberItensParcelasControllerCalcularValoresQuitacao = (queryParams, options) => queryFn(options)('/api/v2/contas-receber-itens-parcelas/calcular-valores-quitacao', queryParams)
export const useQueryContasReceberItensParcelasControllerCalcularValoresQuitacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber-itens-parcelas/calcular-valores-quitacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasReceberItensParcelasControllerCalcularValoresQuitacao.queryKey = '/api/v2/contas-receber-itens-parcelas/calcular-valores-quitacao'

export const mutationContasReceberItensParcelasControllerRenegociarParcela = (pathParams, options) => mutationFn('post', '/api/v2/contas-receber-itens-parcelas/{id}/renegociar', pathParams, {}, options)
export const useMutationContasReceberItensParcelasControllerRenegociarParcela = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/contas-receber-itens-parcelas/{id}/renegociar', pathParams, {}, options), config)

export const queryContasReceberItensParcelasControllerObterDetalhesParcelaRenegociada = (pathParams, options) => queryFn(options)('/api/v2/contas-receber-itens-parcelas/{id}/detalhes-renegociacao', pathParams)
export const useQueryContasReceberItensParcelasControllerObterDetalhesParcelaRenegociada = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/contas-receber-itens-parcelas/{id}/detalhes-renegociacao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasReceberItensParcelasControllerObterDetalhesParcelaRenegociada.queryKey = '/api/v2/contas-receber-itens-parcelas/{id}/detalhes-renegociacao'

export const mutationContasReceberItensParcelasControllerQuitarParcelas = (options) => mutationFn('post', '/api/v2/contas-receber-itens-parcelas/quitacao', {}, {}, options)
export const useMutationContasReceberItensParcelasControllerQuitarParcelas = (config, options) => useMutation(mutationFn('post', '/api/v2/contas-receber-itens-parcelas/quitacao', {}, {}, options), config)

export const mutationContasReceberItensParcelasControllerCadastrarBoletos = (options) => mutationFn('post', '/api/v2/contas-receber-itens-parcelas/boletos', {}, {}, options)
export const useMutationContasReceberItensParcelasControllerCadastrarBoletos = (config, options) => useMutation(mutationFn('post', '/api/v2/contas-receber-itens-parcelas/boletos', {}, {}, options), config)

export const mutationFormasPagamentoControllerCadastrar = (options) => mutationFn('post', '/api/v2/formas-de-pagamento', {}, {}, options)
export const useMutationFormasPagamentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v2/formas-de-pagamento', {}, {}, options), config)

export const queryFormasPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v2/formas-de-pagamento', queryParams)
export const useQueryFormasPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/formas-de-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFormasPagamentoControllerListar.queryKey = '/api/v2/formas-de-pagamento'

export const queryFormasPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/formas-de-pagamento/{id}', pathParams)
export const useQueryFormasPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/formas-de-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerObterPorId.queryKey = '/api/v2/formas-de-pagamento/{id}'

export const mutationFormasPagamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v2/formas-de-pagamento/{id}', pathParams, {}, options)
export const useMutationFormasPagamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/formas-de-pagamento/{id}', pathParams, {}, options), config)

export const mutationFormasPagamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v2/formas-de-pagamento/{id}', pathParams, {}, options)
export const useMutationFormasPagamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/formas-de-pagamento/{id}', pathParams, {}, options), config)

export const mutationFormasPagamentoControllerCadastrarFormaPagamentoConta = (pathParams, options) => mutationFn('post', '/api/v2/formas-de-pagamento/{id}/contas', pathParams, {}, options)
export const useMutationFormasPagamentoControllerCadastrarFormaPagamentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/formas-de-pagamento/{id}/contas', pathParams, {}, options), config)

export const queryFormasPagamentoControllerListarFormaPagamentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v2/formas-de-pagamento/{id}/contas', pathParams, queryParams)
export const useQueryFormasPagamentoControllerListarFormaPagamentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/formas-de-pagamento/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerListarFormaPagamentoContas.queryKey = '/api/v2/formas-de-pagamento/{id}/contas'

export const queryFormasPagamentoControllerObterFormaPagamentoContaPorId = (pathParams, options) => queryFn(options)('/api/v2/formas-de-pagamento/{id}/contas/{contaId}', pathParams)
export const useQueryFormasPagamentoControllerObterFormaPagamentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/formas-de-pagamento/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFormasPagamentoControllerObterFormaPagamentoContaPorId.queryKey = '/api/v2/formas-de-pagamento/{id}/contas/{contaId}'

export const mutationFormasPagamentoControllerAtualizarFormaPagamentoConta = (pathParams, options) => mutationFn('put', '/api/v2/formas-de-pagamento/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFormasPagamentoControllerAtualizarFormaPagamentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/formas-de-pagamento/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFormasPagamentoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v2/formas-de-pagamento/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFormasPagamentoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/formas-de-pagamento/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerListarContasDisponiveis.queryKey = '/api/v2/formas-de-pagamento/{id}/contas-disponiveis'

export const queryItemComposicoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/item-composicoes/{id}', pathParams)
export const useQueryItemComposicoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/item-composicoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItemComposicoesControllerObterPorId.queryKey = '/api/v2/item-composicoes/{id}'

export const mutationItemComposicoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v2/item-composicoes/{id}', pathParams, {}, options)
export const useMutationItemComposicoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/item-composicoes/{id}', pathParams, {}, options), config)

export const mutationItemComposicoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v2/item-composicoes/{id}', pathParams, {}, options)
export const useMutationItemComposicoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/item-composicoes/{id}', pathParams, {}, options), config)

export const mutationItensControllerCadastrarItemPreco = (pathParams, options) => mutationFn('post', '/api/v2/itens/{id}/precos', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemPreco = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/itens/{id}/precos', pathParams, {}, options), config)

export const queryItensControllerListarItemPrecos = (pathParams, queryParams, options) => queryFn(options)('/api/v2/itens/{id}/precos', pathParams, queryParams)
export const useQueryItensControllerListarItemPrecos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/itens/{id}/precos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarItemPrecos.queryKey = '/api/v2/itens/{id}/precos'

export const queryItensControllerObterItemPrecoPadrao = (pathParams, options) => queryFn(options)('/api/v2/itens/{id}/preco-padrao', pathParams)
export const useQueryItensControllerObterItemPrecoPadrao = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/itens/{id}/preco-padrao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterItemPrecoPadrao.queryKey = '/api/v2/itens/{id}/preco-padrao'

export const mutationItensControllerCadastrarItemComposicao = (pathParams, options) => mutationFn('post', '/api/v2/itens/{id}/item-composicoes', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemComposicao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/itens/{id}/item-composicoes', pathParams, {}, options), config)

export const queryItensControllerListarItemComposicoesPorItemId = (pathParams, queryParams, options) => queryFn(options)('/api/v2/itens/{id}/item-composicoes', pathParams, queryParams)
export const useQueryItensControllerListarItemComposicoesPorItemId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/itens/{id}/item-composicoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarItemComposicoesPorItemId.queryKey = '/api/v2/itens/{id}/item-composicoes'

export const mutationItensControllerAtualizarItensDiscriminados = (pathParams, options) => mutationFn('patch', '/api/v2/itens/{id}/itens-discriminados', pathParams, {}, options)
export const useMutationItensControllerAtualizarItensDiscriminados = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v2/itens/{id}/itens-discriminados', pathParams, {}, options), config)

export const queryItensPrecosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/itens-precos/{id}', pathParams)
export const useQueryItensPrecosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/itens-precos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerObterPorId.queryKey = '/api/v2/itens-precos/{id}'

export const mutationItensPrecosControllerAtualizarPreco = (pathParams, options) => mutationFn('put', '/api/v2/itens-precos/{id}', pathParams, {}, options)
export const useMutationItensPrecosControllerAtualizarPreco = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/itens-precos/{id}', pathParams, {}, options), config)

export const mutationItensPrecosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v2/itens-precos/{id}', pathParams, {}, options)
export const useMutationItensPrecosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/itens-precos/{id}', pathParams, {}, options), config)

export const mutationItensPrecosControllerAtualizarPrecoPadrao = (pathParams, options) => mutationFn('patch', '/api/v2/itens-precos/{id}', pathParams, {}, options)
export const useMutationItensPrecosControllerAtualizarPrecoPadrao = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v2/itens-precos/{id}', pathParams, {}, options), config)

export const queryItensPrecosControllerListarParcelas = (pathParams, queryParams, options) => queryFn(options)('/api/v2/itens-precos/{id}/parcelas', pathParams, queryParams)
export const useQueryItensPrecosControllerListarParcelas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/itens-precos/{id}/parcelas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerListarParcelas.queryKey = '/api/v2/itens-precos/{id}/parcelas'

export const mutationItensPrecosControllerAtualizarPrimeiraParcelaFixa = (pathParams, options) => mutationFn('patch', '/api/v2/itens-precos/{id}/parcelas', pathParams, {}, options)
export const useMutationItensPrecosControllerAtualizarPrimeiraParcelaFixa = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v2/itens-precos/{id}/parcelas', pathParams, {}, options), config)

export const mutationItensPrecosControllerAtualizarParcelas = (pathParams, options) => mutationFn('put', '/api/v2/itens-precos/{id}/parcelas/{itemPrecoParcelaId}', pathParams, {}, options)
export const useMutationItensPrecosControllerAtualizarParcelas = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/itens-precos/{id}/parcelas/{itemPrecoParcelaId}', pathParams, {}, options), config)

export const mutationItensPrecosControllerCadastrarItemPrecoConta = (pathParams, options) => mutationFn('post', '/api/v2/itens-precos/{id}/contas', pathParams, {}, options)
export const useMutationItensPrecosControllerCadastrarItemPrecoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/itens-precos/{id}/contas', pathParams, {}, options), config)

export const queryItensPrecosControllerListarItemPrecoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v2/itens-precos/{id}/contas', pathParams, queryParams)
export const useQueryItensPrecosControllerListarItemPrecoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/itens-precos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerListarItemPrecoContas.queryKey = '/api/v2/itens-precos/{id}/contas'

export const queryItensPrecosControllerObterItemPrecoContaPorId = (pathParams, options) => queryFn(options)('/api/v2/itens-precos/{id}/contas/{contaId}', pathParams)
export const useQueryItensPrecosControllerObterItemPrecoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/itens-precos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryItensPrecosControllerObterItemPrecoContaPorId.queryKey = '/api/v2/itens-precos/{id}/contas/{contaId}'

export const mutationItensPrecosControllerAtualizarItemPrecoConta = (pathParams, options) => mutationFn('put', '/api/v2/itens-precos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationItensPrecosControllerAtualizarItemPrecoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/itens-precos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryItensPrecosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v2/itens-precos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryItensPrecosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/itens-precos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerListarContasDisponiveis.queryKey = '/api/v2/itens-precos/{id}/contas-disponiveis'

export const queryMovimentosCaixasFuncionariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/movimentos-caixas-funcionarios/{id}', pathParams)
export const useQueryMovimentosCaixasFuncionariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/movimentos-caixas-funcionarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMovimentosCaixasFuncionariosControllerObterPorId.queryKey = '/api/v2/movimentos-caixas-funcionarios/{id}'

export const queryMovimentosCartoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/movimentos-cartoes/{id}', pathParams)
export const useQueryMovimentosCartoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/movimentos-cartoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMovimentosCartoesControllerObterPorId.queryKey = '/api/v2/movimentos-cartoes/{id}'

export const mutationMovimentosCartoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v2/movimentos-cartoes/{id}', pathParams, {}, options)
export const useMutationMovimentosCartoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/movimentos-cartoes/{id}', pathParams, {}, options), config)

export const queryMovimentosCartoesControllerListar = (queryParams, options) => queryFn(options)('/api/v2/movimentos-cartoes', queryParams)
export const useQueryMovimentosCartoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/movimentos-cartoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMovimentosCartoesControllerListar.queryKey = '/api/v2/movimentos-cartoes'

export const mutationMovimentosCartoesControllerConciliar = (pathParams, options) => mutationFn('post', '/api/v2/movimentos-cartoes/{id}/conciliar', pathParams, {}, options)
export const useMutationMovimentosCartoesControllerConciliar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/movimentos-cartoes/{id}/conciliar', pathParams, {}, options), config)

export const queryMovimentosCartoesControllerObterSituacoesParcelas = (queryParams, options) => queryFn(options)('/api/v2/movimentos-cartoes/situacoes', queryParams)
export const useQueryMovimentosCartoesControllerObterSituacoesParcelas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/movimentos-cartoes/situacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMovimentosCartoesControllerObterSituacoesParcelas.queryKey = '/api/v2/movimentos-cartoes/situacoes'

export const queryOperacoesFinanceirasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/operacoes-financeiras/{id}', pathParams)
export const useQueryOperacoesFinanceirasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/operacoes-financeiras/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOperacoesFinanceirasControllerObterPorId.queryKey = '/api/v2/operacoes-financeiras/{id}'

export const queryOperacoesFinanceirasControllerListar = (queryParams, options) => queryFn(options)('/api/v2/operacoes-financeiras', queryParams)
export const useQueryOperacoesFinanceirasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/operacoes-financeiras', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOperacoesFinanceirasControllerListar.queryKey = '/api/v2/operacoes-financeiras'

export const mutationOrcamentosVendasControllerCadastrar = (options) => mutationFn('post', '/api/v2/orcamentos-vendas', {}, {}, options)
export const useMutationOrcamentosVendasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v2/orcamentos-vendas', {}, {}, options), config)

export const queryOrcamentosVendasControllerListar = (queryParams, options) => queryFn(options)('/api/v2/orcamentos-vendas', queryParams)
export const useQueryOrcamentosVendasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/orcamentos-vendas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosVendasControllerListar.queryKey = '/api/v2/orcamentos-vendas'

export const queryOrcamentosVendasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/orcamentos-vendas/{id}', pathParams)
export const useQueryOrcamentosVendasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/orcamentos-vendas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosVendasControllerObterPorId.queryKey = '/api/v2/orcamentos-vendas/{id}'

export const mutationOrcamentosVendasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v2/orcamentos-vendas/{id}', pathParams, {}, options)
export const useMutationOrcamentosVendasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/orcamentos-vendas/{id}', pathParams, {}, options), config)

export const mutationOrcamentosVendasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v2/orcamentos-vendas/{id}', pathParams, {}, options)
export const useMutationOrcamentosVendasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/orcamentos-vendas/{id}', pathParams, {}, options), config)

export const queryOrcamentosVendasControllerObterPorTabelaOrigemId = (pathParams, options) => queryFn(options)('/api/v2/orcamentos-vendas/origem/{tabelaOrigemId}', pathParams)
export const useQueryOrcamentosVendasControllerObterPorTabelaOrigemId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/orcamentos-vendas/origem/{tabelaOrigemId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.tabelaOrigemId, ...config }
})
useQueryOrcamentosVendasControllerObterPorTabelaOrigemId.queryKey = '/api/v2/orcamentos-vendas/origem/{tabelaOrigemId}'

export const mutationOrcamentosVendasControllerEfetivar = (pathParams, options) => mutationFn('post', '/api/v2/orcamentos-vendas/{id}/efetivar', pathParams, {}, options)
export const useMutationOrcamentosVendasControllerEfetivar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/orcamentos-vendas/{id}/efetivar', pathParams, {}, options), config)

export const mutationOrcamentosVendasControllerCancelar = (pathParams, options) => mutationFn('patch', '/api/v2/orcamentos-vendas/{id}/cancelar', pathParams, {}, options)
export const useMutationOrcamentosVendasControllerCancelar = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v2/orcamentos-vendas/{id}/cancelar', pathParams, {}, options), config)

export const mutationOrcamentosVendasControllerCadastrarOrcamentoVendaItem = (pathParams, options) => mutationFn('post', '/api/v2/orcamentos-vendas/{id}/itens', pathParams, {}, options)
export const useMutationOrcamentosVendasControllerCadastrarOrcamentoVendaItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/orcamentos-vendas/{id}/itens', pathParams, {}, options), config)

export const queryOrcamentosVendasControllerListarOrcamentoVendaItens = (pathParams, queryParams, options) => queryFn(options)('/api/v2/orcamentos-vendas/{id}/itens', pathParams, queryParams)
export const useQueryOrcamentosVendasControllerListarOrcamentoVendaItens = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/orcamentos-vendas/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosVendasControllerListarOrcamentoVendaItens.queryKey = '/api/v2/orcamentos-vendas/{id}/itens'

export const queryOrcamentosVendasControllerListarOrcamentoVendaItensSimplificados = (pathParams, queryParams, options) => queryFn(options)('/api/v2/orcamentos-vendas/{id}/itens/simplificados', pathParams, queryParams)
export const useQueryOrcamentosVendasControllerListarOrcamentoVendaItensSimplificados = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/orcamentos-vendas/{id}/itens/simplificados', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosVendasControllerListarOrcamentoVendaItensSimplificados.queryKey = '/api/v2/orcamentos-vendas/{id}/itens/simplificados'

export const queryOrcamentosVendasControllerObterSituacoesOrcamentoVenda = (queryParams, options) => queryFn(options)('/api/v2/orcamentos-vendas/situacoes', queryParams)
export const useQueryOrcamentosVendasControllerObterSituacoesOrcamentoVenda = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/orcamentos-vendas/situacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosVendasControllerObterSituacoesOrcamentoVenda.queryKey = '/api/v2/orcamentos-vendas/situacoes'

export const queryOrcamentosVendasItensControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/orcamentos-vendas-itens/{id}', pathParams)
export const useQueryOrcamentosVendasItensControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/orcamentos-vendas-itens/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosVendasItensControllerObterPorId.queryKey = '/api/v2/orcamentos-vendas-itens/{id}'

export const mutationOrcamentosVendasItensControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v2/orcamentos-vendas-itens/{id}', pathParams, {}, options)
export const useMutationOrcamentosVendasItensControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/orcamentos-vendas-itens/{id}', pathParams, {}, options), config)

export const mutationOrcamentosVendasItensControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v2/orcamentos-vendas-itens/{id}', pathParams, {}, options)
export const useMutationOrcamentosVendasItensControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/orcamentos-vendas-itens/{id}', pathParams, {}, options), config)

export const queryOrcamentosVendasItensControllerListarOrcamentoVendaItemParcelas = (pathParams, queryParams, options) => queryFn(options)('/api/v2/orcamentos-vendas-itens/{id}/parcelas', pathParams, queryParams)
export const useQueryOrcamentosVendasItensControllerListarOrcamentoVendaItemParcelas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/orcamentos-vendas-itens/{id}/parcelas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosVendasItensControllerListarOrcamentoVendaItemParcelas.queryKey = '/api/v2/orcamentos-vendas-itens/{id}/parcelas'

export const mutationOrcamentosVendasItensControllerAtualizarParcela = (pathParams, options) => mutationFn('put', '/api/v2/orcamentos-vendas-itens/{id}/parcelas/{orcamentoVendaItemParcelaId}', pathParams, {}, options)
export const useMutationOrcamentosVendasItensControllerAtualizarParcela = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v2/orcamentos-vendas-itens/{id}/parcelas/{orcamentoVendaItemParcelaId}', pathParams, {}, options), config)

export const mutationOrcamentosVendasItensControllerDeletarParcela = (pathParams, options) => mutationFn('delete', '/api/v2/orcamentos-vendas-itens/{id}/parcelas/{orcamentoVendaItemParcelaId}', pathParams, {}, options)
export const useMutationOrcamentosVendasItensControllerDeletarParcela = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/orcamentos-vendas-itens/{id}/parcelas/{orcamentoVendaItemParcelaId}', pathParams, {}, options), config)

export const queryOrcamentosVendasItensParcelasControllerObterDescontoFormaPagamento = (pathParams, options) => queryFn(options)('/api/v2/orcamentos-vendas-itens-parcelas/{id}/desconto-forma-pagamento', pathParams)
export const useQueryOrcamentosVendasItensParcelasControllerObterDescontoFormaPagamento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/orcamentos-vendas-itens-parcelas/{id}/desconto-forma-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosVendasItensParcelasControllerObterDescontoFormaPagamento.queryKey = '/api/v2/orcamentos-vendas-itens-parcelas/{id}/desconto-forma-pagamento'

export const mutationOrcamentosVendasItensParcelasControllerCadastrarOrcamentoVendaItemParcelaDesconto = (pathParams, options) => mutationFn('post', '/api/v2/orcamentos-vendas-itens-parcelas/{id}/descontos-configuracoes', pathParams, {}, options)
export const useMutationOrcamentosVendasItensParcelasControllerCadastrarOrcamentoVendaItemParcelaDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v2/orcamentos-vendas-itens-parcelas/{id}/descontos-configuracoes', pathParams, {}, options), config)

export const queryOrcamentosVendasItensParcelasControllerListarDescontosConfiguracoes = (pathParams, queryParams, options) => queryFn(options)('/api/v2/orcamentos-vendas-itens-parcelas/{id}/descontos-configuracoes', pathParams, queryParams)
export const useQueryOrcamentosVendasItensParcelasControllerListarDescontosConfiguracoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/orcamentos-vendas-itens-parcelas/{id}/descontos-configuracoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosVendasItensParcelasControllerListarDescontosConfiguracoes.queryKey = '/api/v2/orcamentos-vendas-itens-parcelas/{id}/descontos-configuracoes'

export const mutationOrcamentosVendasItensParcelasControllerDeletarDesconto = (pathParams, options) => mutationFn('delete', '/api/v2/orcamentos-vendas-itens-parcelas/{id}/descontos-configuracoes/{orcamentoVendaItemParcelaDescontoId}', pathParams, {}, options)
export const useMutationOrcamentosVendasItensParcelasControllerDeletarDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v2/orcamentos-vendas-itens-parcelas/{id}/descontos-configuracoes/{orcamentoVendaItemParcelaDescontoId}', pathParams, {}, options), config)

export const queryOrigensOrcamentoVendaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/origens-orcamento-venda/{id}', pathParams)
export const useQueryOrigensOrcamentoVendaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/origens-orcamento-venda/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrigensOrcamentoVendaControllerObterPorId.queryKey = '/api/v2/origens-orcamento-venda/{id}'

export const queryOrigensOrcamentoVendaControllerListar = (queryParams, options) => queryFn(options)('/api/v2/origens-orcamento-venda', queryParams)
export const useQueryOrigensOrcamentoVendaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/origens-orcamento-venda', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrigensOrcamentoVendaControllerListar.queryKey = '/api/v2/origens-orcamento-venda'

export const queryPeriodosFechamentoCaixaControllerListar = (queryParams, options) => queryFn(options)('/api/v2/periodos-fechamento-caixa', queryParams)
export const useQueryPeriodosFechamentoCaixaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/periodos-fechamento-caixa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPeriodosFechamentoCaixaControllerListar.queryKey = '/api/v2/periodos-fechamento-caixa'

export const queryTiposConfiguracaoDescontoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v2/tipos-configuracao-desconto/{id}', pathParams)
export const useQueryTiposConfiguracaoDescontoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/tipos-configuracao-desconto/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposConfiguracaoDescontoControllerObterPorId.queryKey = '/api/v2/tipos-configuracao-desconto/{id}'

export const queryTiposConfiguracaoDescontoControllerListar = (queryParams, options) => queryFn(options)('/api/v2/tipos-configuracao-desconto', queryParams)
export const useQueryTiposConfiguracaoDescontoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/tipos-configuracao-desconto', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposConfiguracaoDescontoControllerListar.queryKey = '/api/v2/tipos-configuracao-desconto'

export const queryVendasControllerCalcularValoresAlteracaoDadosParcelas = (queryParams, options) => queryFn(options)('/api/v2/vendas/calcular-valores-alteracao-parcelas', queryParams)
export const useQueryVendasControllerCalcularValoresAlteracaoDadosParcelas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/vendas/calcular-valores-alteracao-parcelas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryVendasControllerCalcularValoresAlteracaoDadosParcelas.queryKey = '/api/v2/vendas/calcular-valores-alteracao-parcelas'

export const queryVendasControllerCalcularValoresAlteracaoDadosUnitarios = (queryParams, options) => queryFn(options)('/api/v2/vendas/calcular-valores-alteracao-dados-unitarios', queryParams)
export const useQueryVendasControllerCalcularValoresAlteracaoDadosUnitarios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/vendas/calcular-valores-alteracao-dados-unitarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryVendasControllerCalcularValoresAlteracaoDadosUnitarios.queryKey = '/api/v2/vendas/calcular-valores-alteracao-dados-unitarios'

export const queryVendasControllerCalcularValoresAlteracaoValorTotal = (queryParams, options) => queryFn(options)('/api/v2/vendas/calcular-valores-alteracao-total', queryParams)
export const useQueryVendasControllerCalcularValoresAlteracaoValorTotal = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/vendas/calcular-valores-alteracao-total', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryVendasControllerCalcularValoresAlteracaoValorTotal.queryKey = '/api/v2/vendas/calcular-valores-alteracao-total'

export const queryVendasControllerCalcularValoresAlteracaoQuantidadeParcela = (queryParams, options) => queryFn(options)('/api/v2/vendas/calcular-valores-alteracao-quantidade-parcelas', queryParams)
export const useQueryVendasControllerCalcularValoresAlteracaoQuantidadeParcela = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v2/vendas/calcular-valores-alteracao-quantidade-parcelas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryVendasControllerCalcularValoresAlteracaoQuantidadeParcela.queryKey = '/api/v2/vendas/calcular-valores-alteracao-quantidade-parcelas'

