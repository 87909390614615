/* eslint-disable */
/* tslint:disable */
import ky from 'ky'
import { useQuery, useMutation } from 'react-query'

let api = ky.create({
  "prefixUrl": "https://integracao-saude-api.staging.sponte-demo.com.br",
  "throwHttpErrors": false,
  "retry": {
    "statusCodes": [
      401
    ]
  }
})

export const getApi = () => api

export const setApi = (newApi) => {
  api = newApi
}

export const extendApi = (options) => {
  api = getApi().extend(options)
}

const requestFn = async ({ url, method, pathParams, queryParams, ...rest }) => {
  const urlPathParams = url.match(/{([^}]+)}/g)

  if (urlPathParams) {
    url = urlPathParams.reduce((acc, param) => acc.replace(param, pathParams[param.replace(/{|}/g, '')]), url)
  } else {
    queryParams = pathParams
  }

  if (url.charAt(0) === '/') {
    url = url.replace('/', '')
  }

  const response = await api(url, {
    method,
    ...rest,
    searchParams: {
      ...(rest.searchParams || {}),
      ...queryParams
    }
  })

  let data

  try {
    const contentType = (response.headers.get('content-type') || '').split('; ')[0]

    const responseType =
      {
        'application/json': 'json',
        'application/pdf': 'blob',
        'application/octet-stream': 'blob'
      }[contentType] || 'text'

    data = await response[responseType]()
  } catch (e) {
    data = e.message
  }

  if (!response.ok) {
    const error = {
      data,
      status: response.status,
      message: `Failed to fetch: ${response.status} ${response.statusText}`
    }

    throw error
  }

  return data
}

const queryFn = (options = {}) => (url, pathParams = {}, queryParams = {}) =>
  requestFn({
    url,
    method: 'get',
    pathParams,
    queryParams,
    ...options
  })

const mutationFn = (
  method,
  url,
  pathParams = {},
  queryParams = {},
  options = {}
) => (body = {}) => {
  if (Array.isArray(body)) {
    pathParams = { ...pathParams, ...(body[0] || {}) }
    queryParams = { ...queryParams, ...(body[1] || {}) }
    options = { ...options, ...(body[3] || {}) }
    body = body[2]
  }

  const request = {
    url,
    method,
    pathParams,
    queryParams,
    ...options
  }

  if (method !== 'delete') {
    try {
      request[body.toString() === '[object FormData]' ? 'body' : 'json'] = body
    } catch(e) {
    }
  }

  return requestFn(request)
}

export const queryArquivosControllerDownload = (pathParams, options) => queryFn(options)('/api/v1/arquivos/{id}/download', pathParams)
export const useQueryArquivosControllerDownload = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/{id}/download', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryArquivosControllerDownload.queryKey = '/api/v1/arquivos/{id}/download'

export const queryArquivosControllerObterUrlAssinada = (pathParams, options) => queryFn(options)('/api/v1/arquivos/{id}/signed', pathParams)
export const useQueryArquivosControllerObterUrlAssinada = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/{id}/signed', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryArquivosControllerObterUrlAssinada.queryKey = '/api/v1/arquivos/{id}/signed'

export const queryAssinaturasEletronicasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas', queryParams)
export const useQueryAssinaturasEletronicasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAssinaturasEletronicasControllerListar.queryKey = '/api/v1/assinaturas-eletronicas'

export const mutationAssinaturasEletronicasControllerCadastrar = (options) => mutationFn('post', '/api/v1/assinaturas-eletronicas', {}, {}, options)
export const useMutationAssinaturasEletronicasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/assinaturas-eletronicas', {}, {}, options), config)

export const queryAssinaturasEletronicasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas/{id}', pathParams)
export const useQueryAssinaturasEletronicasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAssinaturasEletronicasControllerObterPorId.queryKey = '/api/v1/assinaturas-eletronicas/{id}'

export const mutationAssinaturasEletronicasControllerCancelar = (pathParams, options) => mutationFn('patch', '/api/v1/assinaturas-eletronicas/{id}/cancelar', pathParams, {}, options)
export const useMutationAssinaturasEletronicasControllerCancelar = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/assinaturas-eletronicas/{id}/cancelar', pathParams, {}, options), config)

export const mutationAssinaturasEletronicasControllerSincronizar = (pathParams, options) => mutationFn('patch', '/api/v1/assinaturas-eletronicas/{id}/sync', pathParams, {}, options)
export const useMutationAssinaturasEletronicasControllerSincronizar = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/assinaturas-eletronicas/{id}/sync', pathParams, {}, options), config)

export const mutationAuthControllerAuthSoluti = (options) => mutationFn('post', '/api/v1/auth/soluti', {}, {}, options)
export const useMutationAuthControllerAuthSoluti = (config, options) => useMutation(mutationFn('post', '/api/v1/auth/soluti', {}, {}, options), config)

export const queryAuthControllerObterAuthSolutiPorUser = (options) => queryFn(options)('/api/v1/auth/soluti')
export const useQueryAuthControllerObterAuthSolutiPorUser = (config, options) => useQuery({
  queryKey: ['/api/v1/auth/soluti'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAuthControllerObterAuthSolutiPorUser.queryKey = '/api/v1/auth/soluti'

export const queryBoletosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/boletos', queryParams)
export const useQueryBoletosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/boletos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBoletosControllerListar.queryKey = '/api/v1/boletos'

export const mutationBoletosControllerCadastrar = (options) => mutationFn('post', '/api/v1/boletos', {}, {}, options)
export const useMutationBoletosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/boletos', {}, {}, options), config)

export const queryBoletosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/boletos/{id}', pathParams)
export const useQueryBoletosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/boletos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBoletosControllerObterPorId.queryKey = '/api/v1/boletos/{id}'

export const queryBoletosControllerDownloadPdf = (pathParams, options) => queryFn(options)('/api/v1/boletos/{id}/pdf', pathParams)
export const useQueryBoletosControllerDownloadPdf = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/boletos/{id}/pdf', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBoletosControllerDownloadPdf.queryKey = '/api/v1/boletos/{id}/pdf'

export const mutationBoletosControllerCancelar = (pathParams, options) => mutationFn('patch', '/api/v1/boletos/{id}/cancelamento', pathParams, {}, options)
export const useMutationBoletosControllerCancelar = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/boletos/{id}/cancelamento', pathParams, {}, options), config)

export const mutationBoletosControllerSincronizar = (pathParams, options) => mutationFn('patch', '/api/v1/boletos/{id}/sync', pathParams, {}, options)
export const useMutationBoletosControllerSincronizar = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/boletos/{id}/sync', pathParams, {}, options), config)

export const mutationBoletosControllerEnviarNotificacao = (pathParams, options) => mutationFn('patch', '/api/v1/boletos/{id}/enviar-notificacao', pathParams, {}, options)
export const useMutationBoletosControllerEnviarNotificacao = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/boletos/{id}/enviar-notificacao', pathParams, {}, options), config)

export const mutationBoletosLinxControllerReceberWebHooksZenvia = (options) => mutationFn('post', '/web-hooks/v1/boletos/linx', {}, {}, options)
export const useMutationBoletosLinxControllerReceberWebHooksZenvia = (config, options) => useMutation(mutationFn('post', '/web-hooks/v1/boletos/linx', {}, {}, options), config)

export const queryBoletosStatusControllerListar = (queryParams, options) => queryFn(options)('/api/v1/boletos-status', queryParams)
export const useQueryBoletosStatusControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/boletos-status', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBoletosStatusControllerListar.queryKey = '/api/v1/boletos-status'

export const mutationClickSignControllerReceberWebHooksClickSign = (options) => mutationFn('post', '/web-hooks/v1/click-sign', {}, {}, options)
export const useMutationClickSignControllerReceberWebHooksClickSign = (config, options) => useMutation(mutationFn('post', '/web-hooks/v1/click-sign', {}, {}, options), config)

export const mutationDocumentosFiscaisControllerExportarDocumentos = (options) => mutationFn('post', '/api/v1/documentos-fiscais', {}, {}, options)
export const useMutationDocumentosFiscaisControllerExportarDocumentos = (config, options) => useMutation(mutationFn('post', '/api/v1/documentos-fiscais', {}, {}, options), config)

export const queryLocalidadesControllerObterEnderecoPorCep = (pathParams, options) => queryFn(options)('/api/v1/localidades/busca-cep/{cep}', pathParams)
export const useQueryLocalidadesControllerObterEnderecoPorCep = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/busca-cep/{cep}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.cep, ...config }
})
useQueryLocalidadesControllerObterEnderecoPorCep.queryKey = '/api/v1/localidades/busca-cep/{cep}'

export const queryMailControllerListar = (queryParams, options) => queryFn(options)('/api/v1/mails', queryParams)
export const useQueryMailControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/mails', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMailControllerListar.queryKey = '/api/v1/mails'

export const mutationMailControllerCadastrar = (options) => mutationFn('post', '/api/v1/mails', {}, {}, options)
export const useMutationMailControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/mails', {}, {}, options), config)

export const queryMailControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/mails/{id}', pathParams)
export const useQueryMailControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/mails/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMailControllerObterPorId.queryKey = '/api/v1/mails/{id}'

export const mutationMailControllerCadastrarMultiplos = (options) => mutationFn('post', '/api/v1/mails/multiplos', {}, {}, options)
export const useMutationMailControllerCadastrarMultiplos = (config, options) => useMutation(mutationFn('post', '/api/v1/mails/multiplos', {}, {}, options), config)

export const mutationMemedControllerReceberWebHooksZenvia = (options) => mutationFn('post', '/web-hooks/v1/memed', {}, {}, options)
export const useMutationMemedControllerReceberWebHooksZenvia = (config, options) => useMutation(mutationFn('post', '/web-hooks/v1/memed', {}, {}, options), config)

export const queryMemedControllerObterPrincipiosAtivos = (queryParams, options) => queryFn(options)('/api/v1/memed/principios-ativos', queryParams)
export const useQueryMemedControllerObterPrincipiosAtivos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/memed/principios-ativos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMemedControllerObterPrincipiosAtivos.queryKey = '/api/v1/memed/principios-ativos'

export const mutationMemedControllerCadastrarAlergiaMedicamento = (pathParams, options) => mutationFn('post', '/api/v1/memed/alergias/{pessoaId}', pathParams, {}, options)
export const useMutationMemedControllerCadastrarAlergiaMedicamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/memed/alergias/{pessoaId}', pathParams, {}, options), config)

export const mutationMemedControllerDeletarAlergiaMedicamento = (pathParams, options) => mutationFn('delete', '/api/v1/memed/alergias/{id}', pathParams, {}, options)
export const useMutationMemedControllerDeletarAlergiaMedicamento = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/memed/alergias/{id}', pathParams, {}, options), config)

export const mutationMovideskControllerAtualizar = (options) => mutationFn('patch', '/api/v1/movidesk', {}, {}, options)
export const useMutationMovideskControllerAtualizar = (config, options) => useMutation(mutationFn('patch', '/api/v1/movidesk', {}, {}, options), config)

export const queryMovideskControllerAcessar = (options) => queryFn(options)('/api/v1/movidesk/acesso')
export const useQueryMovideskControllerAcessar = (config, options) => useQuery({
  queryKey: ['/api/v1/movidesk/acesso'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMovideskControllerAcessar.queryKey = '/api/v1/movidesk/acesso'

export const mutationOpenBankingControllerReceberWebHookStoneOpenBanking = (pathParams, options) => mutationFn('post', '/web-hooks/v1/open-banking/web-hook-response/tenant/{tenantId}/conta/{contaId}', pathParams, {}, options)
export const useMutationOpenBankingControllerReceberWebHookStoneOpenBanking = (pathParams, config, options) => useMutation(mutationFn('post', '/web-hooks/v1/open-banking/web-hook-response/tenant/{tenantId}/conta/{contaId}', pathParams, {}, options), config)

export const queryRelatoriosControllerReport = (pathParams, queryParams, options) => queryFn(options)('/api/v1/relatorios/{path}/{type}/{id}', pathParams, queryParams)
export const useQueryRelatoriosControllerReport = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/relatorios/{path}/{type}/{id}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.path && pathParams.type && pathParams.id, ...config }
})
useQueryRelatoriosControllerReport.queryKey = '/api/v1/relatorios/{path}/{type}/{id}'

export const mutationSendGridControllerReceberWebHooksSendGrid = (options) => mutationFn('post', '/web-hooks/v1/sendgrid', {}, {}, options)
export const useMutationSendGridControllerReceberWebHooksSendGrid = (config, options) => useMutation(mutationFn('post', '/web-hooks/v1/sendgrid', {}, {}, options), config)

export const querySmsControllerListar = (queryParams, options) => queryFn(options)('/api/v1/smses', queryParams)
export const useQuerySmsControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/smses', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySmsControllerListar.queryKey = '/api/v1/smses'

export const mutationSmsControllerCadastrar = (options) => mutationFn('post', '/api/v1/smses', {}, {}, options)
export const useMutationSmsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/smses', {}, {}, options), config)

export const querySmsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/smses/{id}', pathParams)
export const useQuerySmsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/smses/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySmsControllerObterPorId.queryKey = '/api/v1/smses/{id}'

export const mutationSmsControllerCadastrarMultiplos = (options) => mutationFn('post', '/api/v1/smses/multiplos', {}, {}, options)
export const useMutationSmsControllerCadastrarMultiplos = (config, options) => useMutation(mutationFn('post', '/api/v1/smses/multiplos', {}, {}, options), config)

export const mutationSponteNFControllerReceberWebHooksZenvia = (options) => mutationFn('post', '/web-hooks/v1/spontenf', {}, {}, options)
export const useMutationSponteNFControllerReceberWebHooksZenvia = (config, options) => useMutation(mutationFn('post', '/web-hooks/v1/spontenf', {}, {}, options), config)

export const mutationStoneCartaoControllerReceberWebHooksStoneCartaoTransacao = (options) => mutationFn('post', '/web-hooks/v1/stone-cartao/transacao', {}, {}, options)
export const useMutationStoneCartaoControllerReceberWebHooksStoneCartaoTransacao = (config, options) => useMutation(mutationFn('post', '/web-hooks/v1/stone-cartao/transacao', {}, {}, options), config)

export const mutationStoneCartaoControllerReceberWebHooksStoneCartao = (options) => mutationFn('post', '/web-hooks/v1/stone-cartao/link-pagamento', {}, {}, options)
export const useMutationStoneCartaoControllerReceberWebHooksStoneCartao = (config, options) => useMutation(mutationFn('post', '/web-hooks/v1/stone-cartao/link-pagamento', {}, {}, options), config)

export const mutationStoneCartaoControllerReceberAssinaturaWebHooksStoneCartao = (options) => mutationFn('post', '/web-hooks/v1/stone-cartao/assinatura', {}, {}, options)
export const useMutationStoneCartaoControllerReceberAssinaturaWebHooksStoneCartao = (config, options) => useMutation(mutationFn('post', '/web-hooks/v1/stone-cartao/assinatura', {}, {}, options), config)

export const mutationTermoLpgdControllerReceberWebhookApiAceite = (options) => mutationFn('post', '/web-hooks/v1/termos-lgpd', {}, {}, options)
export const useMutationTermoLpgdControllerReceberWebhookApiAceite = (config, options) => useMutation(mutationFn('post', '/web-hooks/v1/termos-lgpd', {}, {}, options), config)

export const mutationUsuariosControllerLogar = (options) => mutationFn('post', '/api/v1/usuarios/login', {}, {}, options)
export const useMutationUsuariosControllerLogar = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/login', {}, {}, options), config)

export const queryUsuariosControllerEu = (options) => queryFn(options)('/api/v1/usuarios/eu')
export const useQueryUsuariosControllerEu = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerEu.queryKey = '/api/v1/usuarios/eu'

export const queryUsuariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/{id}', pathParams)
export const useQueryUsuariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterPorId.queryKey = '/api/v1/usuarios/{id}'

export const mutationUsuariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}', pathParams, {}, options), config)

export const queryUsuariosControllerObterContasUsuario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas', pathParams, queryParams)
export const useQueryUsuariosControllerObterContasUsuario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterContasUsuario.queryKey = '/api/v1/usuarios/{id}/contas'

export const mutationUsuariosControllerCadastrarUsuarioConta = (pathParams, options) => mutationFn('post', '/api/v1/usuarios/{id}/contas', pathParams, {}, options)
export const useMutationUsuariosControllerCadastrarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/{id}/contas', pathParams, {}, options), config)

export const queryUsuariosControllerObterContasDisponiveisUsuario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryUsuariosControllerObterContasDisponiveisUsuario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterContasDisponiveisUsuario.queryKey = '/api/v1/usuarios/{id}/contas-disponiveis'

export const queryUsuariosControllerObterUsuarioContaPorUsuarioId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas/{contaId}', pathParams)
export const useQueryUsuariosControllerObterUsuarioContaPorUsuarioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryUsuariosControllerObterUsuarioContaPorUsuarioId.queryKey = '/api/v1/usuarios/{id}/contas/{contaId}'

export const mutationUsuariosControllerAtualizarUsuarioConta = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const mutationUsuariosControllerDeletarUsuarioConta = (pathParams, options) => mutationFn('delete', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUsuariosControllerDeletarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const mutationZenviaControllerReceberWebHooksZenvia = (options) => mutationFn('post', '/web-hooks/v1/zenvia', {}, {}, options)
export const useMutationZenviaControllerReceberWebHooksZenvia = (config, options) => useMutation(mutationFn('post', '/web-hooks/v1/zenvia', {}, {}, options), config)

export const mutationZoopControllerReceberWebHooksZoop = (options) => mutationFn('post', '/web-hooks/v1/zoop/boletos', {}, {}, options)
export const useMutationZoopControllerReceberWebHooksZoop = (config, options) => useMutation(mutationFn('post', '/web-hooks/v1/zoop/boletos', {}, {}, options), config)

