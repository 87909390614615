export default {
  geral: {
    atencao: '¡Atención!',
    app: 'MedPlus SP',
    planoSaude: 'Plan de salud',
    prontuario: 'Historial médico',
    paciente: 'Paciente',
    pacientes: 'Pacientes',
    tipoAgendamento: 'Tipo de horario',
    selecioneUmConvenio: 'Seleccione un acuerdo',
    selecioneUmPlanoDeSaude: 'Seleccione un plan de salud',
    selecioneUmOperadoraDeSaude: 'Seleccione un proveedor de atención médica',
    planosEConvenios: 'Planes y convenios',
    novoOrcamento: 'Nuevo presupuesto',
    procedimento: 'Procedimento',
    medico: 'Doctor',
    novoAgendamento: 'Nuevo horario',
    upAdministracao: 'HASTA / Gestión',
    atendimento: 'Servicio',
    atendimento_plural: 'Atenciones',
    dadosClinicos: 'Datos clinicos',
    selecionePaciente: 'Seleccione un paciente',
    medicos: 'Doctores',
    chamarWhats: 'Llamar por whatsapp',
    enviarMensagemViaWhatsApp: 'Enviar mensaje a través de WhatsApp',
    agendamento: 'Planificación',
    historicoNotificacoes: 'Historial de notificaciones',
    localEnvio: 'Ubicación de envío',
    meioComunicacaoUtilizado: 'Medios de comunicación utilizados',
    historicoNotificacoesSubTitulo: 'Historial detallado con este cliente',
    contatoAtravesAgenda: 'Contacto a través de la agenda',
    contatoAtravesCliente: 'Contacto a través de registro de cliente',
    remetenteEnvio: 'Remitente de envío',
    dataHoraAgendamento: 'Fecha y hora de la cita',
    semPaciente: 'Sin paciente',
    countPacientesAgendados: '{{count}} pacientes programados',
    adicionarPaciente: 'Agregar paciente',
    adicionarMedico: 'Agregar doctor',
    usaCID10: 'Utilice CID10',
    usaCIAP2: 'Utiliza CIAP2',
    atendimentosEmAberto: 'Convocatorias abiertas',
    pacientesEmEspera: 'Esperando pacientes',
    novoAtendimento: 'Nuevo servicio',
    emDilatacao: 'En expansión',
    iniciarAtendimento: 'Comienza el servicio',
    retomarAtendimento: 'Reanudar el servicio',
    verExames: 'Ver exámenes',
    convenio: 'Seguro de salud',
    triagem: 'Poner en pantalla',
    descartarAtendimento: 'Descartar el servicio',
    historicoDoPaciente: 'Historial del paciente',
    comercial: 'Relación',
    motivoComercial: 'Razón de la relación',
    historicoDeAtendimento: 'Historial de servicio',
    laudo: 'Reporte',
    laudo_plural: 'Informes',
    gerarTiss: 'Generar TISS',
    operadoraPlanoDeSaude: 'Operador del plan de salud',
    tabelaDeProcedimentos: 'Mesa de procedimiento',
    tabelaDeProcedimento: 'Mesa de procedimiento',
    grupoDeProcedimentos: 'Grupo de procedimiento',
    grupoDeProcedimento: 'Grupo de procedimiento',
    posologia: 'Dosis',
    medicamentoPosologia: 'Medicamento / Dosis',
    pacientesEmAtendimento: 'Pacientes atendidos',
    finalizarAtendimento: 'Servicio final',
    conduta: 'Conducta',
    agendarConsultaApartirDe: 'Programar cita desde',
    cid: 'CID',
    motivoDesfecho: 'Razón del resultado',
    queixaPrincipal: 'Queja principal',
    antecedentes: 'Fondo',
    tipoTempoPrevisto: 'Tipo de tiempo esperado',
    atender: 'Reunirse',
    encaixar: 'Encajar',
    prescricoes: 'Prescripciones',
    personalizarReceituario: 'Personalizar prescripción',
    mostrarNomeDoPaciente: 'Mostrar el nombre del paciente',
    mostrarNomeDoFabricante: 'Mostrar el nombre del fabricante',
    mostrarUnidades: 'Mostrar unidades',
    protocoloDeExames: 'Protocolo de examen',
    tiss: 'TISS',
    guias: 'Guías',
    faturas: 'Facturas',
    tempoMedioAtendimento: 'Tiempo medio de servicio',
    adicionarCid: 'Agregar CID',
    salaDeEspera: 'Sala de espera',
    tempoDeAtendimento: 'Tiempo de servicio',
    totalDeAgendamentos: 'Total de citas',
    agendados: 'Programado',
    atendidos: 'Asistió',
    encaixes: 'Guarniciones',
    nConsulta: '{{number}}ª Consulta',
    retornos: 'Devoluciones',
    cidsRecorrentes: 'CID recurrentes',
    editarFormula: 'Editar fórmula',
    integracoes: 'Integraciones',
    rqe: 'RQE',
    crm: 'CRM',
    convenioPlano: 'Acuerdo y plan',
    dilatacoes: 'Dilataciones',
    prescricaoOculos: 'Prescripción de gafas',
    verProntuario: 'Ver historia clínica',
    verAtendimentos: 'Ver llamadas',
    dataAtendimento: 'Fecha de servicio',
    comentario: 'Comentario',
    novoLaudo: 'Nuevo reporte',
    nota: 'Nota',
    copiarLink: 'Copiar link',
    teleconsulta: 'Teleconsulta',
    teleconsulta_plural: 'Teleconsultas',
    copiarLinkTeleconsulta: 'Copiar enlace de teleconsulta',
    linkTeleconsultaEnvioManual:
      'El paciente no tiene registrado un correo electrónico o número de teléfono para enviar el enlace de teleconsulta. Deberá enviarlo manualmente.',
    consulta_presencial: 'Consulta presencial',
    consulta_presencial_plural: 'Consultas presenciales',
    aceitoNoDia: 'Aceptado en el día',
    termoAceiteTeleconsulta: 'Plazo de aceptación de la teleconsulta',
    situacaoAgendamento: 'Estado de programación',
    numeroCartaoSUS: 'Documento SUS',
    tiposAgendamento: 'Tipos de programación',
    templateComercial: 'Plantilla de negocio',
    tipoDePlano: 'Tipo de plan',
    convenioPadrao: 'Acuerdo estándar',
    numeroDaCarteira: 'Número de billetera',
    validadeDaCarteira: 'Validez de la cartera',
    modelosProntuarioDisponiveis: 'Plantillas de registros médicos disponibles',
    modeloProntuarioAtual: 'Plantilla de historia clínica actual',
    retornoApartirDe: 'Regresar de',
    modeloProntuario: 'Plantilla de registro médico',
    opcoesMultiSelect: 'Opciones de selección múltiple',
    grupoDeDocumento: 'Grupo de documentos',
    numeroProntuario: 'Numero de historia clinica',
    exibirCarimbo: 'Sello de exhibición',
    carimbo: 'Sello',
    titulacao: 'Valoración',
    receita: 'Ingresos',
    receitaMemed: 'Receta Memed',
    receitasMemed: 'Recetas Memed',
    preAtendimento: 'servicio previo',
    nomeMedico: 'El nombre del doctor',
    horaInicial: 'Hora de inicio',
    horaFinal: 'Hora de finalización',
    perto: 'Cerrar',
    longe: 'Lejos',
    tipoVisao: 'Tipo de vista',
    salvarImprimir: 'Guardar e imprimir',
    imprimirHistorico: 'Historial de impresión',
    naoPossuiHistoricoChat: 'Sin historial de chat',
    agendamentoMultiplo: 'Programación múltiple',
    quantidadeAgendamentos: 'Numero de citas',
    ateDia: 'Hasta el día',
    agendamentoMultiploDataOcupada: 'Este tiempo ya está ocupado en la fecha {{date}}. ¿Desea continuar?',
    excluirAgendamentoMultiplo: '¿Quieres eliminar la programación múltiple?',
    este: 'Éste',
    repeticaoSemanal: 'Repetición semanal',
    calculando: 'Calculador',
    crmEspecialidade: 'CRM y especialidad',
    registroEspecialidade: 'Registro o especialidad',
    novoRegistroEspecialidade: 'Añadir registro o especialidad',
    novoCrmEspecialidade: 'Agregar CRM o especialidad',
    considerarDiasUteis: 'Considere los días hábiles en que vencen las cuotas',
    dataExclusao: 'Fecha de exclusión ',
    movimentoExclusaoAgendamentos: 'Programar movimiento de eliminación',
    horarioEncerramentoMenorQueHorarioInicio: 'La hora de finalización no puede ser inferior a la hora de inicio.',
    profissionalSaude: 'Profesional de la salud',
    dataPrimeiroVencimento: 'Primera fecha de vencimiento',
    confirmaRemocaoSecaoAtendimento:
      '¡Atención! Se han ingresado datos en esta sección que se perderán. ¿Quieres eliminarlo? ',

    feedbacks: {
      aguardandoChegadaPacientes: 'Esperando la llegada de los pacientes',
      aguardandoEntradaNovosPacientes: 'A la espera de la entrada de nuevos pacientes',
      nenhumAtendimentoPausa: 'A la espera de la entrada de nuevos pacientes',
      nenhumPacienteSalaEspera: 'No hay pacientes en la sala de espera.',
      nenhumAtendimentoRegistrado: 'Ningún servicio registrado',
      nenhumAtendimentoEmAberto: 'No hay convocatorias abiertas',
      cliqueEmIniciarAtendimento: 'Haga clic en iniciar servicio',
      cliqueEmRetomarAtendimento: 'Haga clic en reanudar el servicio',
      tempoPrevistoRetornoSucesso: 'Tiempo estimado para un retorno agregado exitoso',
      nenhumaPrescricaoRegistrada: 'Sin receta registrada',
      nenhumaFormulaRegistrada: 'No hay formula registrada',
      cidAdicionadoSucesso: '¡CID agregado exitosamente!',
      cidRemovidoSucesso: '¡CID eliminado con éxito!',
      operacaoNaoPermitidaAtendimentos: '¡Operación no permitida para llamadas!',
      pacienteEmAtendimentoOutroMedico: '¡El paciente está siendo atendido por otro médico!',
      teleconsultaNaoEncontrada: '¡Teleconsulta no encontrada!',
      pacienteSemConsultaAgendada: 'El paciente no tiene teleconsulta programada',
      teleconsultaFinalizadaSucesso: 'Teleconsulta completada con éxito',
      teleconsultaFinalizadaErro: 'Error al finalizar la teleconsulta',
      medicoOuClinicaSemHorarioDeAtendimentoNesseDia:
        'La clínica y / o el médico no tienen ningún calendario disponible para esta fecha.',
      nenhumaAssinaturaPendente: 'Sin suscripción pendiente',
      obrigatorioParaAdicionarDesconto: 'Requerido para agregar descuento',
      nenhumPlanoCadastradoNesseConvenio: 'Ningún plan registrado en este acuerdo',
      errorAbrirMemed: 'Error al abrir Memed',
      nenhumaOportunidadeEncontrada: 'No se encontraron oportunidades para este filtro.',
      aoFinalizarTeleconsultaLinkSeraInativadoMedicoPaciente:
        'Al final de esta llamada, el enlace de teleconsulta se desactivará para el médico y el paciente!',
      conexaoTeleconsultaAindaNaoFoiEstabelecidade: '¡La conexión de teleconsulta aún no se ha establecido!',
      historicoInexistente: 'Aún no hay antecedentes de este paciente.',
      imprimirHistorico: 'Historial de impresión',
      realizeUploadImagemPacienteOuNovoFiltro: '¡Cargue una imagen de paciente o un nuevo filtro!',
      linkTeleconsultaCopiadoComSucesso: 'Enlace de teleconsulta copiado al portapapeles',
      linkTeleconsultaErroAoCopiar: 'Error al copiar el enlace de teleconsulta',
      tipoUsuarioTarifado: 'Tipo de usuario facturado',
      modeloDeImpressaoNaoSelecionado: 'No tiene ninguna plantilla de impresión seleccionada o configurada.',
      teleconsultaAberta: 'Cerrar la teleconsulta abierta antes de iniciar otra cita.'
    },

    navegacao: {
      'locais-de-estoque': 'Ubicaciones de existencias',
      'central-tiss': 'Centro TISS',
      clinicas: 'Clínicas',
      procedimentos: 'Procedimientos',
      posologias: 'Dosis',
      'dados-clinicos': 'Datos clinicos',
      'situacoes-agendamento': 'Situaciones de programación',
      'categorias-agendamento': 'Tipos de programación',
      'motivos-cancelamento-do-agendamento': 'Razones de la cancelación',
      'fila-de-espera': 'Cola de espera',
      'sala-de-espera': 'Sala de esperaS',
      clinico: 'Clínico',
      tiss: 'TISS',
      'preferencias-do-tiss': 'Preferencias de TISS',
      convenio: 'Seguro de salud',
      'modelos-de-prontuario': 'Plantillas de registros médicos',
      'modelos-de-impressos': 'Plantillas de impresión',
      'motivos-desfecho-atendimento': 'Razón final',
      atendimentos: 'Atenciones',
      'preferencias-do-comercial': 'Preferencias de relación',
      comercial: 'Relación',
      'protocolos-e-exames': 'Protocolos y exámenes',
      'tabelas-de-procedimentos': 'Tablas de procedimientos',
      'grupos-de-procedimentos': 'Grupos de procedimientos',
      'preferencias-do-clinico': 'Preferencias del médico',
      'classificacoes-de-produtos': 'Calificaciones de productos',
      formulas: 'Fórmulas',
      'dashboard-do-clinico': 'Panel de control del médico',
      'modelos-de-laudo': 'Plantillas de informes',
      'versoes-do-tiss': 'Versiones TISS',
      'dashboard-do-relacionamento': 'Panel de relaciones',
      'fluxo-automacao-crm': 'Automatización',
      'notificacoes-teleconsulta': 'Notificaciones de teleconsulta',
      'templates-comerciais': 'Plantillas de negocios',
      desfechos: 'Resultados',
      'pre-atendimento': 'Servicio previo y Devoluciones',
      'servicos-contratacoes': 'Servicios y Contratos',
      'modelos-de-receituario': 'Plantillas de recetas',
      'saidas-servicos-e-itens': 'Productos: servicios y artículos',
      'documentos-fiscais': 'Documentos fiscales'
    }
  },

  cadastro: {
    clientes: {
      numeroProntuario: 'Numero de historia clinica',
      financeiro: {
        atendimento: {
          novo: 'Nuevo servicio'
        }
      },

      dadosClinicos: {
        precaucoes: 'Precauciones',
        alergiaMedicamentosa: 'Alergia a un medicamento',
        medicamentoUsoContinuo: 'Medicamentos de uso continuo',
        principioAtivo: 'Principio activo',
        informePrecaucao: 'Ingrese una precaución',
        informeMedicamento: 'Informar a una droga',
        buscarPrincipioAtivo: 'Busque un ingrediente activo',
        medicamentoCadastradoSucesso: '¡Medicina registrada con éxito!',
        medicamentoRemovidoSucesso: '¡Medicina eliminada con éxito!',
        alergiaMedicamentosaNaoEncontrada: 'No se encontró alergia a medicamentos'
      }
    },

    funcionarios: {
      especialidades: {
        titulo: 'Datos de especialidad',
        deletadoSucesso: 'Especialidad eliminada con éxito',
        criadoSucesso: 'Especialidad agregada con éxito',
        atualizadoSucesso: 'Especialidad actualizada con éxito',
        registro: 'Número de registro',
        novo: 'Nueva especialidad',
        adicionar: 'Agregar especialidad',
        editar: 'Editar especialidad',
        especialidadePadrao: 'Especialidad estándar',
        nenhumaEspecialidadeRegistrada: 'Ninguna especialidad registrada',
        especialidadeJaCadastrada: 'Especialidad ya registrada para este empleado',
        especialidadePadraoExistente:
          'La especialidad {{especialidadePadrao}} ya es predeterminado. ¿Quieres cambiar el valor predeterminado de la habilidad {{especialidadeAtual}}?',
        especialidadePadraoNecessaria: 'Es necesario establecer otra especialidad como predeterminada',

        subespecialidades: {
          titulo: 'Subespecialidad',
          novo: 'Nueva subespecialidad',
          adicionar: 'Añadir subespecialidad',
          adicionadaSucesso: 'Subespecialidad agregada con éxito',
          removidaSucesso: 'Subespecialidad eliminada con éxito'
        }
      },

      registro: {
        titulo: 'Datos de registro',
        deletadoSucesso: 'Registro removido com sucesso',
        criadoSucesso: 'Registro añadido con éxito',
        atualizadoSucesso: 'Registro atualizadoa com sucesso',
        novo: 'Nuevo registro',
        adicionar: 'Agregar registro',
        editar: 'Editar registro',
        registroJaCadastrada: 'Registro ya registrado para este empleado',
        relacionado: 'Registro relacionado'
      },

      crm: {
        titulo: 'Datos de CRM',
        deletadoSucesso: 'CRM eliminado con éxito',
        criadoSucesso: 'CRM Agregado exitosamente',
        registro: 'Número de CRM',
        novo: 'Nuevo CRM',
        adicionar: 'Agregar CRM',
        relacionado: 'CRM relacionado',
        crmPossuiVinculoComEspecialidade: 'Este registro está vinculado a una o más especialidades del empleado'
      },

      integracoes: {
        integracoes: 'Integraciones',
        detalhesDaIntegracao: 'Detalles de integración',
        sincronizadoSucesso: 'Sincronización realizada con éxito',
        status: 'Estado',
        mensagens: 'Mensajes',
        nenhumaFalhaRegistrada: 'No se registraron fallas',
        salvaSucesso: 'Integración guardada con éxito',

        memed: {
          titulo: 'Integración Memed',
          novo: 'Agregar integración de Memed',
          naoPossuiCrmParaIntegracao: 'Este empleado no tienes CRM para usar MEMED'
        },

        soluti: {
          titulo: 'Integración Soluti',
          novo: 'Agregar integración de Soluti',
          integracaoAtiva: 'Integración activa'
        }
      },

      licencaAdicional: {
        adicionarLicenca: 'Agregar licencia',
        suasLicencasTipo: 'Tus licencias de tipo',
        acabaramContratarMais: 'terminaron, ¿te gustaría contratar más?',
        adicioneLicencaPor: 'Agregar {{amount}} dejar por $t(geral:currency) {{value, currency|BRL}}',
        observacaoAcesso:
          'Nota: Para que el nuevo usuario creado pueda utilizar el calendario y la historia clínica, utilice la pestaña Acceso del registro para crear el usuario y la contraseña.',
        confirmarSalvar: 'Confirmar y guardar'
      }
    },

    preferencias: {
      documentos: {
        modelosDeImpressos: {
          titulo: 'Plantillas de impresión',
          novo: 'Nueva plantilla de impresión',
          cadastradoSucesso: 'Plantilla de impresión registrada correctamente!',
          atualizadoSucesso: '¡Plantilla de impresión actualizada correctamente!',
          removidoSucesso: '¡Plantilla de impresión eliminada correctamente!',

          imagem: {
            cadastradoSucesso: '¡Imagen registrada correctamente!',
            atualizadoSucesso: '¡Imagen actualizada correctamente!',
            removidoSucesso: '¡Imagen eliminada correctamente!'
          }
        }
      }
    }
  },

  catalogo: {
    produtos: {
      apresentacao: 'Presentación',
      formula: 'Fórmula',
      dosagem: 'Dosis',
      fracao: 'Fracción',
      registroAnvisa: 'Registro ANVISA',
      codigoAbramge: 'Código ABRAMGE',
      codigoTiss: 'Código TISS',
      tabelaTiss: 'Mesa TISS',

      usosDeProdutos: {
        usosDeProduto: 'Uso del producto',
        novo: 'Uso de nuevos productos',
        cadastradoSucesso: '¡Uso exitoso del producto registrado!'
      },

      posologias: {
        titulo: 'Dosis',
        novo: 'Nueva dosificación',
        cadastradoSucesso: 'Posología enlazada con éxito!',
        removidoSucesso: '¡Posología eliminada con éxito!'
      }
    },

    procedimentos: {
      tributacao: {
        novo: 'Nueva tributación',
        cadastradoSucesso: '¡Tributación registrada con éxito!',
        atualizadoSucesso: '¡Fiscalidad actualizada correctamente!',
        removidoSucesso: '¡Impuestos eliminados con éxito!'
      }
    },

    preferencias: {
      classificacoesDeProdutos: {
        titulo: 'Calificaciones de productos',
        novo: 'Nueva clasificación',
        cadastradoSucesso: '¡Clasificación registrada con éxito!',
        atualizadoSucesso: '¡Rango actualizado con éxito!',
        removidoSucesso: '¡Calificación eliminada con éxito!'
      },

      gruposDeProcedimentos: {
        titulo: 'Grupos de procedimientos',
        novo: 'Nuevo grupo de procedimientos',
        cadastradoSucesso: '¡Grupo de procedimientos registrado con éxito!',
        atualizadoSucesso: '¡Grupo de procedimientos actualizado correctamente!',
        removidoSucesso: '¡El grupo de procedimientos se eliminó correctamente!'
      },

      posologias: {
        titulo: 'Dosis',
        novo: 'Nueva dosificación',
        cadastradoSucesso: 'Posología registrada con éxito!',
        atualizadoSucesso: 'Posología actualizada con éxito!',
        removidoSucesso: '¡Posología eliminada con éxito!'
      },

      tabelasDeProcedimentos: {
        titulo: 'Tablas de procedimientos',
        novo: 'Nueva tabla de procedimientos',
        cadastradoSucesso: '¡Tabla de procedimientos registrada con éxito!',
        atualizadoSucesso: '¡Tabla de procedimientos actualizada correctamente!',
        removidoSucesso: '¡Mesa de procedimientos eliminada con éxito!'
      }
    }
  },

  agenda: {
    agendamento: {
      titulo: 'Planificación',
      novo: 'Nuevo horario',
      cadastradoSucesso: '¡Cita registrada con éxito!',
      atualizadoSucesso: '¡Programa actualizado correctamente!',
      removidoSucesso: '¡Programa eliminado correctamente!',

      pacientes: {
        titulo: 'Pacientes',
        novo: 'Agregar paciente',
        cadastradoSucesso: '¡Paciente agregado exitosamente!',
        atualizadoSucesso: '¡Paciente actualizado con éxito!',
        removidoSucesso: '¡Paciente eliminado con éxito!'
      },

      medicos: {
        titulo: 'Doctor',
        novo: 'Agregar doctor',
        cadastradoSucesso: '¡Médico agregado exitosamente!',
        atualizadoSucesso: 'Doctor actualizado con éxito!',
        removidoSucesso: '¡Doctor eliminado con éxito!'
      },

      equipamentos: {
        titulo: 'Equipamientos',
        novo: 'Agregar equipo',
        cadastradoSucesso: '¡Equipo agregado exitosamente!',
        atualizadoSucesso: '¡Equipo actualizado con éxito!',
        removidoSucesso: '¡Equipo eliminado con éxito!'
      },

      dilatacao: {
        listaDilatacoes: 'Lista de dilataciones',
        proxima: 'Próximo',
        ultima: 'Último',
        novaDilatacao: 'Nueva dilatación',
        editarDilatacao: 'Editar dilatación',
        colirioDilatador: 'Gotas para los ojos utilizadas',
        executor: 'Usuario',
        proximaDilatacao: 'Siguiente dilatación',
        olhoEsquerdo: 'OE',
        ambos: 'Ambos',
        olhoDireito: 'OD',
        adulto: 'Adulto',
        infantil: 'Niño',
        cadastradaSucesso: 'Dilatación registrada con éxito',
        atualizadaSucesso: 'Dilatación actualizada con éxito',
        deletadoSucesso: 'Dilatación eliminada con éxito',
        horarioProxima: 'Hora / Siguiente',
        colirioExecutor: 'Gotas para los ojos / Verdugo',
        olho: 'Ojo',
        incluaDilatacao: 'Agregue nuevas dilataciones a través de las opciones de programación del paciente'
      },

      financeiro: {
        novoOrcamentoVenda: 'Nuevo presupuesto de ventas',
        novoPedidoVenda: 'Nueva orden de venta',
        novoTitulo: 'Nuevo título',
        vendas: {
          orcamentos: {
            novo: 'Nuevo presupuesto',
            composicao: {
              cadastroErro: 'Condición Monto total diferente del Monto restante del presupuesto!',
              condicoes: {
                cadastradoSucesso: '¡Condición registrada con éxito!',
                atualizadoSucesso: '¡Condición actualizada con éxito!',
                removidoSucesso: '¡Condición eliminada con éxito!'
              }
            }
          },
          contasReceber: {
            novo: 'Nueva cuenta por cobrar'
          }
        }
      },

      tiss: {
        guias: 'Guías',
        convenioTipoGuia: 'Seguro de salud / Tipo de guía',
        valorItens: 'Valor / Elementos',
        gerarGuiasImpressao: 'Generar guías para imprimir',
        tipoGuia: 'Tipo de guía',
        cadastrarGuia: 'Guía de registro',
        necessarioEfetivarPedidoVenda:
          'Para que los artículos se impriman en la pestaña, es necesario realizar el pedido de cliente. ¿Quieres continuar e ingresar manualmente?',
        pageSadt: {
          atualizadoSucesso: 'Guía actualizada con éxito',
          cadastradoSucesso: 'Guía registrado con éxito',
          removidoSucesso: 'Guía eliminada correctamente',
          guiaConsulta: 'Guia de referencia',
          valorProcedimento: 'Valor de procedimiento',
          dadosContratado: 'Datos del contratista',
          dadosAtendimentoProcedimentoRealizado: 'Datos de servicio / Procedimientos realizados',
          guiasImpressao: '{{number}} guía (s) imprimible',
          nGuiaPrestador: 'Guía del proveedor No.',
          guiaServicoProfissionalSadt:
            'Guía de servicio profesional / Servicio Auxiliar de Diagnóstico y Terapia - SP / SADT',
          registroANS: 'Registro ANS',
          numeroGuiaPrincipal: 'Número de pestaña principal',
          dataAutorizacao: 'Fecha de autorización',
          dataValidadeSenha: 'Fecha de caducidad de la contraseña',
          numeroGuiaAtribuidoOperadora: 'Número de guía asignado por el operador',
          dadosBeneficiario: 'Datos del beneficiario',
          numeroCarteira: 'Número de billetera',
          validadeCarteira: 'Validez de la cartera',
          carteiraNacionalSaude: 'Tarjeta Sanitaria Nacional',
          atendimentoRN: 'Servicio RN',
          dadosSolicitante: 'Datos del solicitante',
          codigoOperadora: 'Código de operador',
          nomeContratado: 'Nombre del contratista',
          nomeProfissionalSolicitante: 'Nombre del profesional solicitante',
          nomeProfissionalExecutante: 'Nombre del profesional intérprete',
          conselhoProfissional: 'Consejo profesional',
          numeroConselho: 'Número de placa',
          UF: 'UF',
          codigoCBO: 'Código CBO',
          assinaturaProfissionalExecutante: 'Firma del Profesional Ejecutor',
          assinaturaProfissionalSolicitante: 'Firma del profesional solicitante',
          dadosSolicitacaoProcedimentosItensAssistenciaisSolicitados:
            'Solicitar datos / Procedimientos o elementos de asistencia solicitados',
          caraterAtendimento: 'Carácter de servicio',
          dataSolicitacao: 'Fecha de solicitud',
          indicacaoClinica: 'Indicación clínica',
          tabela: 'Tabla',
          codigoProcedimentoItemAssistencial: 'Código de procedimiento o artículo de asistencia',
          qtdeSolic: 'Cantidad Solicitud.',
          qtdeAut: 'Cant. Autor',
          dadosContratoExecutante: 'Realización de datos del contratista',
          codigoNaOperadora: 'Código de operador',
          codigoCNES: 'Código CNES',
          dadosAtendimento: 'Datos de servicio',
          tipoAtendimento: 'Tipo de servicio',
          indicacaoAcidente: 'Indicación de accidente (accidente o enfermedad relacionada)',
          tipoConsulta: 'Tipo de consulta',
          motivoEncerramentoAtendimento: 'Motivo de la terminación del servicio ',
          dadosExecucaoProcedimentoExamesRealizados: 'Datos de ejecución / Procedimientos y exámenes realizadosS',
          codigoProcedimento: 'Código de procedimiento',
          qtde: 'Cantidad.',
          via: 'Vía',
          tec: 'Tec.',
          fatorRedAcres: 'Factor rojo/Agregar.',
          valorUnitario: 'Valor unitario',
          valorTotal: 'Valor total',
          identificacaoProfissionaisExecutantes: 'Identificación del (de los) profesional (es) ejecutor (es)',
          seqRef: 'Seq.Ref',
          grauPart: 'Parte de grado.',
          codigoOperadoraCPF: 'Código en el operador / CPF',
          nomeProfissional: 'Nombre profesional',
          dataRealizacaoProcedimentosSerie: 'Fecha de ejecución de los procedimientos en serie',
          quantidadeRealizacaoProcedimentosSerie: 'Número de ejecución de procedimientos en serie',
          assinaturaBeneficiarioResponsavel: 'Firma del Beneficiario o Responsable',
          observacaoJustificativa: 'Observación / Justificación',
          totalProcedimentos: 'Procedimientos totales (R$)',
          totalTaxasAlugueis: 'Tarifas y alquileres totales (R$)',
          totalMateriais: 'Materiales totales (R$)',
          totalOPME: 'OPME total (R$)',
          totalMedicamentos: 'Total de medicamentos (R$)',
          totalGasesMedicinais: 'Gases médicos totales (R$)',
          totalGeral: 'Total general (R$)',
          assinaturaResponsavelAutorizacao: 'Firma del oficial de autorización',
          assinaturaContratado: 'Firma del contratista'
        }
      }
    },

    preferencias: {
      calendarios: {
        exibirProntuarioNaAgenda: 'Ver historial médico en el calendario'
      },

      categoriasAgendamento: {
        titulo: 'Tipos de programación',
        novo: 'Nuevo tipo de horario',
        cadastradoSucesso: '¡Tipo de horario registrado correctamente!',
        atualizadoSucesso: '¡Tipo de programa actualizado correctamente!',
        removidoSucesso: '¡El tipo de programa se eliminó correctamente!'
      },

      motivosCancelamentoDoAgendamento: {
        titulo: 'Razón de la cancelación',
        novo: 'Nueva razón',
        cadastradoSucesso: '¡Razón registrada correctamente!',
        atualizadoSucesso: 'Razón actualizada con éxito!',
        removidoSucesso: '¡Razón eliminada con éxito!'
      },

      situacoesAgendamento: {
        titulo: 'Estado de programación',
        novo: 'Nueva situación',
        cadastradoSucesso: 'Situación registrada con éxito!',
        atualizadoSucesso: '¡Estado actualizado con éxito!',
        removidoSucesso: '¡Situación eliminada con éxito!'
      },

      templatesComerciais: {
        titulo: 'Plantillas de negocios',
        novo: 'Nueva plantilla de negocio',
        cadastradoSucesso: 'Plantilla de negocio registrada correctamente!',
        atualizadoSucesso: '¡Plantilla de negocio actualizada correctamente!',
        removidoSucesso: '¡Plantilla de empresa eliminada correctamente!'
      }
    }
  },

  comercial: {
    dashboard: {
      portal: 'Portal del paciente'
    },

    oportunidades: {
      titulo: 'Oportunidades',
      novo: 'Nueva oportunidad',
      cadastradoSucesso: '¡Oportunidad registrada con éxito!',
      atualizadoSucesso: '¡Oportunidad actualizada con éxito!',
      removidoSucesso: '¡Oportunidad eliminada con éxito!',
      financeiro: {
        orcamento: {
          novo: 'Nuevo presupuesto'
        }
      }
    },

    questionarios: {
      titulo: 'Cuestionarios',
      novo: 'Nuevo cuestionario',
      cadastradoSucesso: '¡Prueba registrada correctamente!',
      atualizadoSucesso: '¡Prueba actualizada correctamente!',
      removidoSucesso: '¡Prueba eliminada correctamente!'
    }
  },

  preferencias: {
    motivosComerciais: {
      titulo: 'Razones comerciales',
      novo: 'Nuevo motivo empresarial',
      cadastradoSucesso: '¡Razón comercial registrada con éxito!',
      atualizadoSucesso: '¡Motivo comercial actualizado con éxito!',
      removidoSucesso: '¡Motivo comercial eliminado con éxito!'
    }
  },

  clinico: {
    prontuarios: {
      titulo: 'Registros médicos',
      novo: 'Nuevo historial médico',
      cadastradoSucesso: 'Registro registrado exitosamente!',
      atualizadoSucesso: 'Historial médico actualizado con éxito!',
      removidoSucesso: 'Registro médico eliminado con éxito!',

      atendimentos: {
        iniciadoSucesso: '¡El servicio se inició con éxito!',
        desejaFinalizar: '¿Quieres dar de baja el servicio?',
        desejaDescartar: '¿Por qué quieres dejar el servicio?',
        prescricoesExames: 'Recetas y exámenes',
        prescricaoDeOculos: 'Prescripción de gafas',
        prescricaoMemed: 'Receta memed',
        prescricao: 'Prescripción',
        dilatacoes: 'Dilataciones',
        adicionarSecao: 'Agregar sección',
        atendimentoRegistradoEm: 'Servicio registrado en:',
        atendimentoRealizadoEm: 'Servicio realizado en:',
        notaCadastradaSucesso: 'Nota registrada correctamente',
        teleconsulta: 'Teleconsulta',
        tempoPrevistoRetorno: 'Tiempo estimado de devolución',
        calculoTempoAtendimento: 'Cálculo del tiempo de programación en curso',

        layout: {
          atualizadoSucesso: '¡Diseño actualizado correctamente!',
          removidoSucesso: '¡Diseño eliminado correctamente!',
          nomePaciente: 'Nombre del paciente',
          itemModelo: 'Artículo modelo'
        },

        assinaturaDigital: {
          titulo: 'Firma digital',
          expirarTokenEm: 'Caducar token en',
          tokenApp: 'Ingrese el token que aparece en su aplicación Bird ID',
          assinar: 'Para firmar',
          assinarDepois: 'Firmar más tarde',
          autenticadaSucesso: '¡Token validado con éxito!',
          requisicaoAssinatura: '¡Solicitud de suscripción completada con éxito!',
          assinatura: 'Firma',
          assinaturas: 'Suscripciones',
          assinaturaPendente: 'Suscripción en proceso',
          falhaAssinar: 'No se pudo firmar',
          assinaturasPendentes: 'SSuscripciones pendientes',
          numeroProntuario: 'No. de la historia clínica',
          nenhumaAssinaturaPendente: 'Sin suscripción pendiente',
          assinado: 'Firmado'
        },

        impressao: {
          outrasInformacoes: 'Otras informaciones',
          camposDoAtendimento: 'Campos de servicio',
          avaliacao: 'Evaluación',
          conduta: 'Conducta',
          queixaPrincipal: 'Queja principal',
          plano: 'Plano',
          cid: 'CID',
          receitas: 'Ingresos',
          laudos: 'Informes',
          adendos: 'Anexos',
          anexos: 'Archivos adjuntos',
          teleConsultas: 'Teleconsultas',
          nenhumaReceitaCadastrada: '¡Ninguna receta registrada en este servicio!',
          nenhumaReceitaMemedRegistradaAtendimento: '¡Ninguna receta memed registrada en este servicio!',
          receitasMemed: {
            identificacaoEmitente: 'Identificación del emisor',
            cidadeEUf: 'Ciudad y estado',
            receituarioEspecial: 'Prescripción de control especial',
            primeiraViaFarmacia: '1 ° vía farmacia',
            segundaViaPaciente: 'Paciente de la segunda copia',
            assinatura: 'Firma',
            identificacaoComprador: 'Identificación del comprador',
            identificacaoFornecedor: 'Identificación del proveedor',
            assinaturaFarmaceutico: 'Firma del farmacéutico'
          }
        },

        laudos: {
          cadastradoSucesso: 'Informe registrado correctamente',
          atualizadoSucesso: 'Informe actualizado correctamente!',
          removidoSucesso: '¡Informe eliminado correctamente!'
        },

        documentos: {
          cadastradoSucesso: 'Documentos registrados con éxito',
          atualizadoSucesso: '¡Documentos actualizados con éxito!',
          removidoSucesso: '¡Documentos eliminados con éxito!'
        }
      }
    },
    exames: {
      examesSolicitadosDia: 'Día de exámenes solicitados',
      mesmaDataParaTodos: 'Usar la misma fecha para todos',
      uploadImagens: 'Subir imágenes',
      resultados: 'Resultados',
      solicitarNovosExames: 'Solicitar nuevos examenes',
      dataRealizacao: 'Fecha de realización',
      realizadoEm: 'Celebrado en',
      exames: 'Examenes',
      detalhesResultados: 'Detalles y resultados',
      adicionarExames: 'Añadir exámenes'
    },
    solicitacaoDeExames: {
      solicitacaoDeExames: 'Solicitud de examen',
      listagemDeExames: 'Lista de examenes',
      novaSolicitacaoDeExames: 'Nueva solicitud de examen',
      convenio: 'Seguro de salud',
      dataSolicitacao: 'Fecha de solicitud',
      selecioneExame: 'Seleccionar examen',
      procurePeloProcedimentoExame: 'Buscar procedimiento/examen',
      exameInterno: 'Examen interno',
      listagemExames: 'Lista de examen',
      nomeExame: 'Nombre del examen',
      motivoExame: 'Motivo del examen',
      status: 'Status',
      olho: 'Ojo',
      assinarDigitalmente: 'Firmar digitalmente',
      gerarSadt: 'Generar sadt ',
      qtde: 'La cantidad',
      imprimir: 'Imprimir',
      cadastradoSucesso: '¡Documento registrado con éxito!',
      atualizadoSucesso: '¡Documento actualizado con éxito!',
      removidoSucesso: '¡Documento eliminado con éxito!',
      cadastarProcedimentoIgual: 'El procedimiento/examen ya ha sido seleccionado.',
      validacoes: {
        qtdeMin: 'La cantidad debe ser mayor o igual a uno'
      }
    },

    desfechos: {
      titulo: 'Pre-servicios y Devoluciones',
      novo: 'Nuevo resultado',
      cadastradoSucesso: 'Resultado registrado con éxito!',
      atualizadoSucesso: 'Resultado actualizado con éxito!',
      removidoSucesso: '¡Resultado eliminado con éxito!',
      preAtendimento: 'Pre-servicio',
      retornos: 'Devoluciones',
      proximosAtendimentos: 'Próximas citas',
      historicoDeAtendimentos: 'Historial de servicio',
      selecionePreAtendimento: 'Seleccionar plantilla de pre-servicio',
      novaSecao: 'Nueva sección',
      agendePreAtendimento: 'Programe una cita con estado de pre-servicio',
      agendeRetornoReconsulta: 'Programar una vuelta/nueva cita',

      atendimentos: {
        titulo: 'Atenciones',
        novo: 'Nuevo servicio',
        cadastradoSucesso: '¡Servicio registrado con éxito!',
        atualizadoSucesso: '¡Servicio actualizado con éxito!',
        removidoSucesso: '¡Llamada eliminada con éxito!',

        documentos: {
          titulo: 'Documentos',
          novo: 'Nuevo documento',
          cadastradoSucesso: '¡Documento registrado con éxito!',
          atualizadoSucesso: '¡Documento actualizado con éxito!',
          removidoSucesso: '¡Documento eliminado con éxito!'
        },

        exames: {
          titulo: 'Exámenes',
          novo: 'Nuevo examen',
          cadastradoSucesso: '¡Examen registrado correctamente!',
          atualizadoSucesso: '¡Examen actualizado correctamente!',
          removidoSucesso: '¡Examen eliminado correctamente!'
        }
      }
    },

    preferencias: {
      formulas: {
        titulo: 'Fórmulas',
        novo: 'Nueva fórmula',
        cadastradoSucesso: '¡Fórmula registrada con éxito!',
        atualizadoSucesso: '¡Fórmula actualizada con éxito!',
        removidoSucesso: '¡Fórmula eliminada con éxito!'
      },

      modelosDeLaudo: {
        titulo: 'Plantillas de informes',
        novo: 'Nueva plantilla de informe',
        cadastradoSucesso: '¡Plantilla de informe registrada correctamente!',
        atualizadoSucesso: 'Plantilla de informe actualizada correctamente.',
        removidoSucesso: '¡Plantilla de informe eliminada correctamente!',
        laudo: { novo: 'Nueva plantilla de informe', nenhumCadastro: 'Ningún modelo de informe registrado' },
        conclusao: { novo: 'Nueva plantilla de finalización', nenhumCadastro: 'Sin modelo de finalización registrado' }
      },

      modelosDeProntuario: {
        titulo: 'Plantillas de registros médicos',
        novo: 'Nueva plantilla de historia clínica',

        digiteTitulo: 'Ingrese el título del registro aquí',
        digiteDescricao: 'Ingrese la descripción de la historia clínica aquí',
        default: 'Expediente médico sin título',

        cadastradoSucesso: 'Modelo de historia clínica registrada con éxito!',
        atualizadoSucesso: 'Plantilla de historia clínica actualizada correctamente.',
        removidoSucesso: '¡Plantilla de historia clínica eliminada con éxito!',

        medicos: {
          titulo: 'Doctores',
          novo: 'Agregar doctor',
          cadastradoSucesso: '¡Médico agregado exitosamente!',
          atualizadoSucesso: 'Doctor actualizado con éxito!',
          removidoSucesso: '¡Doctor eliminado con éxito!'
        },

        especialidades: {
          titulo: 'Especialidades',
          novo: 'Agregar especialidad',
          cadastradoSucesso: '¡Especialidad agregada con éxito!',
          atualizadoSucesso: '¡Especialidad actualizada con éxito!',
          removidoSucesso: '¡Especialidad eliminada con éxito!'
        },

        secoes: {
          novo: 'Nueva sección',
          cadastradoSucesso: '¡Sección registrada con éxito!',
          atualizadoSucesso: '¡Sección actualizada con éxito!',
          removidoSucesso: '¡Sección eliminada con éxito!',

          digiteTitulo: 'Ingrese el título de la sección aquí',

          campos: {
            novo: 'Nuevo campo',
            cadastradoSucesso: 'Campo registrado correctamente!',
            atualizadoSucesso: '¡Campo actualizado correctamente!',
            removidoSucesso: '¡Campo eliminado correctamente!',
            camposDisponiveis: 'Campos disponibles para composición',
            operacoes: 'Operaciones',

            termos: {
              titulo: 'Palabras clave',
              novo: 'Nuevo término',
              cadastradoSucesso: '¡Término registrado exitosamente!',
              removidoSucesso: 'Término eliminado con éxito!'
            },

            opcoesMultiSelect: {
              titulo: 'Opciones de selección múltiple',
              novo: 'Nueva opción',
              cadastradoSucesso: '¡Opción de selección múltiple registrada con éxito!',
              removidoSucesso: '¡Opción de selección múltiple eliminada con éxito!',
              nenhumaOpcaoCadastrado: 'Ninguna opción registrada'
            }
          }
        }
      },

      protocolosExames: {
        titulo: 'Protocolos y exámenes',
        novo: 'Nuevo protocolo / examen',
        cadastradoSucesso: '¡Protocolo / examen registrado con éxito!',
        atualizadoSucesso: '¡Protocolo / examen actualizado correctamente!',
        removidoSucesso: '¡Protocolo / examen eliminado con éxito!',

        especialidades: {
          titulo: 'Especialidades',
          novo: 'Nueva especialidad',
          cadastradoSucesso: 'Especialidad registrada con éxito!',
          removidoSucesso: '¡Especialidad eliminada con éxito!'
        },

        exames: {
          titulo: 'Exámenes',
          novo: 'Nuevo examen',
          editar: 'Editar examen',
          cadastradoSucesso: '¡Examen registrado correctamente!',
          atualizadoSucesso: '¡Examen actualizado correctamente!',
          removidoSucesso: '¡Examen eliminado correctamente!'
        },

        medicamentos: {
          titulo: 'Medicamentos',
          novo: 'Nueva droga',
          editar: 'Editar medicina',
          cadastradoSucesso: '¡Medicina registrada con éxito!',
          atualizadoSucesso: '¡Medicina actualizada con éxito!',
          removidoSucesso: '¡Medicina eliminada con éxito!'
        },

        profissionais: {
          titulo: 'Profesionales',
          novo: 'Nuevo profesional',
          selecione: 'Seleccione un profesional...',
          cadastradoSucesso: '¡Profesional registrado con éxito!',
          removidoSucesso: '¡Profesional eliminado con éxito!'
        }
      },

      motivosDesfechoAtendimento: {
        titulo: 'Razones finales',
        novo: 'Nueva razón final',
        cadastradoSucesso: '¡Motivo del resultado registrado con éxito!',
        atualizadoSucesso: 'Razón final actualizada con éxito!',
        removidoSucesso: '¡El motivo de la rescisión se ha eliminado correctamente!'
      },

      tiposFuncionarios: {
        novo: 'Agregar tipo',
        cadastradoSucesso: '¡Tipo de empleado registrado con éxito!'
      },

      tiposDeSala: {
        titulo: 'Tipos de habitaciones / ambiente',
        novo: 'Nuevo tipo de habitación',
        cadastradoSucesso: '¡Tipo de habitación registrado correctamente!',
        atualizadoSucesso: '¡Tipo de habitación actualizado correctamente!',
        removidoSucesso: '¡El tipo de habitación se eliminó correctamente!'
      },

      salas: {
        titulo: 'Habitaciones / ambientes',
        novo: 'Nuevo cuarto / ambiente',
        cadastradoSucesso: 'Sala / medio ambiente registrado con éxito!',
        atualizadoSucesso: 'Sala / entorno actualizado con éxito!',
        removidoSucesso: 'Sala / medio ambiente eliminado con éxito!',
        equipamentos: {
          titulo: 'Equipos',
          selecionar: 'Seleccionar equipo',
          novo: 'Nuevo equipamiento',
          editar: 'Editar equipo',
          cadastradoSucesso: '¡Equipo registrado con éxito!',
          atualizadoSucesso: '¡Equipo actualizado con éxito!',
          removidoSucesso: '¡Equipo eliminado con éxito!'
        }
      }
    }
  },

  tiss: {
    versaoDoTISS: 'Versión TISS',

    centralTiss: {
      titulo: 'Centro TISS',
      faturas: { novo: 'Nueva factura' },
      glosas: 'Brillos',
      lote: 'Lote',
      qtde: 'Cantidad',
      nGuia: 'Guía No.',
      faturado: 'Facturado',
      glosa: 'Brillo',
      valorGlosa: 'valor de brillo',
      cr: 'CR',
      repasse: 'Transferir',
      pesquisar: 'Búsqueda de pacientes, médicos, guías....',
      pesquisarGuias: 'Guías de búsqueda',
      buscar: 'Búsqueda de pacientes, médicos, guías....',
      tipoGuia: 'Tipo de pestaña',
      sequenciaDeTransacao: 'Secuencia de transacciones: - lote xml',
      numeroControleXml: 'Número de control XML',
      versaoDoArquivoXml: 'Versión del archivo xml',
      guias: {
        totalFatura: 'Total de la factura',
        totalGlosado: 'Brillo total',
        totalRecebido: 'Total recibido',
        totalTiss: 'Total TISS',
        options: {
          editarGuia: 'Pestaña Editar',
          gerarXml: 'Generar XML',
          transferir: 'Transferir',
          contaReceber: 'Cuenta por cobrar',
          contaPagar: 'Factura a pagar',
          previsaoDeRepasses: 'Provisión de transferencias'
        }
      }
    },
    preferencias: {
      convenios: {
        convenios: 'Convenios',
        novo: 'Nuevo acuerdo',
        editar: 'Editar acuerdo',
        cadastradoSucesso: 'Acuerdo registrado con éxito!',
        atualizadoSucesso: 'Acuerdo registrado con éxito!',
        removidoSucesso: '¡Pacto eliminado con éxito!',
        principaisDados: 'Datos principales del acuerdo',
        nomeCnpj: 'Nombre / CNPJ',
        registroAns: 'Registro con ANS',
        maximoRegistroAns: 'Máximo 6 caracteres',
        telefoneEmail: 'Teléfono / correo electrónico',
        tags: 'Etiquetas',
        codigoOperadora: 'Código de operador',
        maximoCodigoOperadora: 'Máximo 20 caracteres',
        inscricaoEstadual: 'Inscripción Estadual',
        dataCredenciamento: 'Fecha de acreditación',
        dataDescredenciamento: 'Fecha de desacreditación',
        retornoConsultaDias: 'Devolver consulta',
        diasRecebimento: 'Días para recibir',
        website: 'Sitio web',
        observacoes: 'Comentarios',
        tabs: {
          dadosBasicos: 'Datos básicos',
          trocaDeInformacoes: 'Intercambio de información',
          regras: 'Normas',
          webservicesTiss: 'Servicios web de TISS',
          excecoes: 'Excepciones',
          financeiro: 'Financiero',
          tributacao: 'Impuestos',
          anexos: 'Archivos adjuntos',
          situacao: 'Situación'
        },
        planos: {
          titulo: 'Planes',
          subtitulo: 'Registrar los planos del convenio',
          novo: 'Nuevo plan',
          cadastradoSucesso: '¡Plan registrado con éxito!',
          atualizadoSucesso: '¡Plan actualizado con éxito!',
          removidoSucesso: '¡Plan eliminado correctamente!'
        }
      },

      versoesDoTiss: {
        titulo: 'Versiones TISS',
        novo: 'Nueva versión de TISS',
        cadastradoSucesso: '¡Versión TISS registrada con éxito!',
        atualizadoSucesso: '¡Versión de TISS actualizada con éxito!',
        removidoSucesso: '¡Versión de TISS eliminada con éxito!'
      }
    }
  },

  servicosContratacoes: {
    faturas: {
      historicoContratacoes: {
        titulo: 'Historial de contratación',
        mesAtual: 'Mes actual',
        resumo: 'Resumen',
        movimentoUsuarios: 'Movimiento del usuario',
        quantidadeUsuarios: 'Número de usuarios',
        valorMensalidade: 'Cuota mensual',
        dataProfissionalSaude: 'Fecha / Profesional de la salud',
        emailUsuario: 'Correo electrónico / Usuario',
        nenhumaMovimentacaoEncontrada: 'No se encontraron unidades'
      }
    }
  },

  configuracoes: {
    notificacoesTeleconsulta: {
      titulo: 'Notificaciones de teleconsulta',
      notificarPorEmail: 'Notificación de correo electrónico',
      notificarPorSMS: 'Notificação por SMS',
      templateSMSAgendado: 'Plantilla de SMS programada',
      templateSMSRemarcado: 'Plantilla de SMS reprogramada',
      templateSMSCancelado: 'Plantilla de SMS cancelada',
      templateMailAgendado: 'Plantilla de correo electrónico programada',
      templateMailRemarcado: 'Plantilla de correo electrónico reprogramada',
      templateMailCancelado: 'Plantilla de correo electrónico cancelada',
      atualizadoSucesso: '¡Notificaciones de teleconsulta actualizadas con éxito!'
    },
    empresas: {
      impressos: {
        emitenteRecibos: 'Emisor de recibos'
      }
    },
    templates: {
      whatsApp: {
        titulo: 'Plantillas',
        novo: 'Nuevo Modelo',
        cadastradoSucesso: '¡Plantilla de WhatsApp registrada con éxito!',
        atualizadoSucesso: '¡Plantilla de WhatsApp actualizada con éxito!',
        removidoSucesso: '¡La plantilla de WhatsApp se eliminó con éxito!',
        escrevaMensagem: 'Escribe el mensaje',
        adicionarTags: 'Agregar etiquetas:',
        mensagemDeTexto: 'Mensaje de texto',
        caracteresRestantes: '{{value}} caracteres restantes',
        funcionalidade: 'Funcionalidad'
      }
    }
  },

  dashboard: {
    categorias: {
      ultimos12Meses: 'Últimos 12 meses',
      ultimoMes: 'El mes pasado',
      ultimaSemana: 'Ultima semana',
      ultimoDia: 'Último día',
      semAgendamentosPorStatus: 'Sin citas en los últimos 12 meses',
      agendamentosPorStatus: 'Horarios por estado (últimos 12 meses)'
    },
    resumoFinanceiro: {
      hoje: 'Resumen financiero (hoy)'
    }
  },
  financeiro: {
    preferenciasEstoque: {
      localDeEstoque: {
        titulo: 'Ubicación de existencias',
        novo: 'Nueva ubicación de existencias',
        cadastradoSucesso: '¡Ubicación de existencias registrada con éxito!',
        atualizadoSucesso: '¡Ubicación de existencias actualizada con éxito!',
        removidoSucesso: '¡Ubicación de existencias eliminada con éxito!'
      }
    },
    estoque: {
      titulo: 'Movimiento de existencias',
      localEstoque: 'Ubicación de existencias',
      nenhumCadastro: 'Ningún movimiento de existencias registrado',
      cadastradoSucesso: '¡Existencias actualizadas con éxito!',
      atualizadoSucesso: '¡Existencias actualizadas con éxito!',
      removidoSucesso: '¡Existencias eliminadas con éxito!',
      totalItens: 'Artículos totales',
      maiorSaidaItem: 'Mayor salida de artículos',
      semSaidaItem: 'Sin salida reciente',
      itemsVencidos: 'Artículos vencidos',
      nomeSituacao: 'Nombre / Situación en existencias',
      valorCompraVenda: 'Precio de compra / Venta',
      localEstoqueFinalidade: 'Ubicación de existencias / Finalidad',
      embCompraVenda: 'Emb. de compra / Venta',
      estoqueMinimo: 'Existencias minimas',
      estoqueMaximo: 'Existencias maximas',
      tipoProduto: 'Tipo de producto',
      buscarPorProduto: 'Buscar por producto',
      finalidade: 'Propósito',
      vencimentoEntreDatas: 'Vencimiento entre fechas',
      vencimentoInicial: 'Vencimiento inicial',
      vencimentoFinal: 'Vencimiento final',
      validadeEstoque: 'Valides y stock',
      validade: 'Validez',
      situacaoEstoque: 'Situación de stock',
      movimentarLocalEstoque: 'Mover ubicación de stock',
      quantidadeMovimentar: 'Cantidad a mover',
      selecionarLocal: 'Seleccionar ubicación',
      valorVenda: 'Cantidad de Venta',
      embalagemCompra: 'Compra de embalaje',
      embalagemVenda: 'Venta de Empaques',
      removerUnidade: 'Quitar unidades',
      voceEstaRemovendoUnidades: 'Está eliminando {{unit}} unidades',
      transferirUnidades: 'Unidades de transferencia',
      voceEstaTransferindoUnidadesDesseLocal: 'Está transfiriendo {{unit}} unidades desde esta ubicación',
      burcarPorProduto: 'Buscar por producto',
      abaixoEstoque: 'Por debajo del stock mínimo',
      acimaEstoque: 'Por encima del stock máximo',
      produtoVencido: 'Producto caducado!',
      vencido: 'Atrasado',
      unidadesTransferidas: 'Unidades a transferir',
      unidadesRestantes: 'Unidades restantes en la ubicación actual'
    }
  },
  catalago: {
    estoque: {
      titulo: 'Stock',
      localEstoque: 'Ubicación Stock',
      estoqueMinimo: 'Stock mínimo',
      estoqueMaximo: 'Stock máximo'
    }
  }
}
